import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 */
class TeamService {

    /**
     * Get teams
     * @param {*} teamSearch { teamid, name }
     */
    async getTeams( teamSearch ) {
        var request = {
            query: `
            query getTeams($teamSearch: TeamSearch!) {
                getTeams(teamSearch: $teamSearch) {
                    teamid
                    name
                    has_users
                }
            }
            `,
            variables: {
                teamSearch: teamSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Create a new team
     * @param {*} teamInput { name }
     */
    async createTeam( teamInput ) {
        var request = {
            query: `
            mutation createTeam($teamInput: TeamInput) {
                createTeam(teamInput: $teamInput) {
                    teamid
                    name
                    has_users
                }
            }
            `,
            variables: {
                teamInput: teamInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete a team
     * @param {*} teamSearch { roleid, name }
     */
    async deleteTeam( teamSearch ) {
        var request = {
            query: `
            mutation deleteTeam($teamSearch: TeamSearch!) {
                deleteTeam(teamSearch: $teamSearch) {
                    result
                }
            }
            `,
            variables: {
                teamSearch: teamSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update a team
     * @param {*} teamSearch { teamid, name }
     * @param {*} teamUpdate { name }
     */
    async updateTeam( teamSearch, teamUpdate ) {
        var request = {
            query: `
            mutation updateTeam($teamSearch: TeamSearch!, $teamUpdate: TeamUpdate!) {
                updateTeam(teamSearch: $teamSearch, teamUpdate: $teamUpdate) {
                    teamid
                    name
                }
            }
            `,
            variables: {
                teamSearch: teamSearch,
                teamUpdate: teamUpdate
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new TeamService();
