import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const useStyles = theme => ({});

const StyledTypography = styled(Typography)`
    font-size: 16px;
    font-weight: bold;
    color: #248EDB;
`;

/**
 * Page Subtitle
 */
class PageSubtitle extends Component {

    // Render
    // Takes props: title
    render() {
        return (
            <StyledTypography key={this.props.title} align="left">
                {this.props.title}
            </StyledTypography>
        );
    }
};

export default withStyles(useStyles)(PageSubtitle);