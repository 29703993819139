import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 */
class SpecialService {

    /**
     * Get specials
     * @param {*} specialSearch { specialid, name, short_name, type }
     */
    async getSpecials( specialSearch ) {
        var request = {
            query: `
            query getSpecials($specialSearch: SpecialSearch!) {
                getSpecials(specialSearch: $specialSearch) {
                    specialid
                    name
                    is_visible
                    is_enabled
                    allow_accounting
                    short_name
                    color_code
                    type
                    auto_accounting
                    approval_required
                    has_absences
                    has_accountings
                }
            }
            `,
            variables: {
                specialSearch: specialSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Create a new special
     * @param {*} specialInput { name, is_visible, is_enabled, allow_accounting, short_name, color_code, type, auto_accounting, approval_required }
     */
    async createSpecial( specialInput ) {
        var request = {
            query: `
            mutation createSpecial($specialInput: SpecialInput) {
                createSpecial(specialInput: $specialInput) {
                    specialid
                    name
                    is_visible
                    is_enabled
                    allow_accounting
                    short_name
                    color_code
                    type
                    auto_accounting
                    approval_required
                    has_absences
                    has_accountings
                }
            }
            `,
            variables: {
                specialInput: specialInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update a special
     * @param {*} specialSearch { specialid, name, short_name, type }
     * @param {*} specialUpdate { name, is_visible, is_enabled, allow_accounting, short_name, color_code, type, auto_accounting, approval_required }
     */
    async updateSpecial( specialSearch, specialUpdate ) {
        var request = {
            query: `
            mutation updateSpecial($specialSearch: SpecialSearch!, $specialUpdate: SpecialUpdate!) {
                updateSpecial(specialSearch: $specialSearch, specialUpdate: $specialUpdate) {
                    specialid
                    name
                    is_visible
                    is_enabled
                    allow_accounting
                    short_name
                    color_code
                    type
                    auto_accounting
                    approval_required
                    has_absences
                    has_accountings
                }
            }
            `,
            variables: {
                specialSearch: specialSearch,
                specialUpdate: specialUpdate
            }
        };

        return await ApiService.doPost( request );
    }   

    /**
     * Update a special
     * @param {*} specialSearch { specialid, name, short_name, type }
     */
    async deleteSpecial( specialSearch ) {
        var request = {
            query: `
            mutation deleteSpecial($specialSearch: SpecialSearch!) {
                deleteSpecial(specialSearch: $specialSearch) {
                    result
                }
            }
            `,
            variables: {
                specialSearch: specialSearch
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new SpecialService();
