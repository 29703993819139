import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AddButton from './AddButton'
import RefreshButton from './RefreshButton'
import SearchField from './SearchField'

const useStyles = theme => ({
    searchAddGrid: {
        display: 'flex',
        height: '80px',
        width: '100%',
        justifyContent: 'space-between',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    searchFieldDiv: {
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(1.5),
            width: '40ch',
        },
    },
});

/**
 * Search field with associated refresh and add buttons
 */
class SearchControls extends Component {
    state = {
        searchFieldEntry: ""
    }

    constructor(props) {
        super(props);

        this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind( this );
        this.handleAddButtonClick = this.handleAddButtonClick.bind( this );
        this.handleChangeSearchField = this.handleChangeSearchField.bind( this );
        this.handleClickClearSearchField = this.handleClickClearSearchField.bind( this );
    }

    // Button to clear the search field has been click
    handleClickClearSearchField( event ) {
        event.preventDefault();
        this.setState({ searchFieldEntry: "" });
        if( null != this.props.onChange) {
            this.props.onChange( "" );
        }
    }

    // Called when text is entered in the search field
    handleChangeSearchField( event ) {
        event.preventDefault();
        this.setState({ searchFieldEntry: event.target.value });
        if( null != this.props.onChange) {
            this.props.onChange( event.target.value );
        }
    }

    // Called on refresh button click
    handleRefreshButtonClick( event ) {
        event.stopPropagation();
        if( null != this.props.onRefresh) {
            this.props.onRefresh();
        }
    }

    // Called on add button click
    handleAddButtonClick( event ) {
        event.stopPropagation();
        if( null != this.props.onAdd) {
            this.props.onAdd();
        }
    }

    render() {
        const { classes } = this.props;

        const label = this.props.label;
        const helperText = this.props.helperText;
        const refreshTooltip = this.props.refreshTooltip;
        const addTooltip = this.props.addTooltip;

        let showRefreshButton = true;
        if( null != this.props.showRefreshButton ) {
            showRefreshButton = this.props.showRefreshButton;
        }

        let showAddButton = true;
        if( null != this.props.showAddButton ) {
            showAddButton = this.props.showAddButton;
        }

        return (
            <Grid container direction="row" className={classes.searchAddGrid} spacing={0} wrap='nowrap'>
                <Grid item>
                    <Grid item>
                        <div className={classes.searchFieldDiv}>
                            <SearchField 
                                label={label} 
                                id="search-field" 
                                value={this.state.searchFieldEntry} 
                                helperText={helperText}
                                onChange={this.handleChangeSearchField}
                                onClick={this.handleClickClearSearchField}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item container direction="row" spacing={1} justify="flex-end">
                    {showRefreshButton && (
                        <Grid item>
                            <RefreshButton 
                                title={refreshTooltip}
                                onClick={(event) => this.handleRefreshButtonClick(event)}
                                disable={this.props.disableRefreshButton}
                            />
                        </Grid>
                    )}
                    {showAddButton && ( 
                        <Grid item>
                            <AddButton 
                                title={addTooltip}
                                onClick={(event) => this.handleAddButtonClick(event)}
                                disable={this.props.disableAddButton}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        )
    }
};

export default withStyles(useStyles)(SearchControls);