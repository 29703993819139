import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import PageDescription from '../components/PageDescription'
import DateOnly from '../components/DateOnly';
import { toDateObject, toDateOnlyString, toJSONLocalDate, getWorkTimeSummary, getWorkTimeWarnings, removeSecondsFromTimeString, fromTimeStringToDate, invertColorMoreContrast, getAccountingsForDate, isSpecialAutoAccounting } from '../helpers.js';
import CalendarHelper from '../classes/CalendarHelper.js';
import WorkTimeButton from './WorkTimeButton';
import ModalDialog from './ModalDialog';
import ModalDialogWorkTime from './ModalDialogWorkTime';
import { config } from '../config';
import i18n from 'i18next';
import clsx from  'clsx';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const useStyles = theme => ({
    tableHeaderCell: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: "bold",
        borderStyle: "solid",
        borderWidth: 0
    },
    cellDayBase: {
        verticalAlign: "top",
        paddingLeft: "5px",
        height: "25px",
        minWidth: "45px",
        color: "#000000",
        borderColor: "#e1e1e1",
        borderStyle: "solid",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "1px",
        padding: "0px",
        paddingTop: "4px",
        margin: "0px",
        userSelect: "none",
    },
    cellTodayWeekDay: {},
    cellTodayWeekEnd: {},
    cellWeekEnd: {
        backgroundColor: "#d9d9d9",
    },
    cellWeekDay: {
        backgroundColor: "#ffffff",
    },
    cellHoliday: {
        color: "#656565",
        backgroundColor: "#fde1fc",
    },
    cellNoWorkDayWeekEnd: {
        backgroundColor: "#d9d9d9",
    },
    cellNoWorkDayWeekDay: {
        color: "#ffffff",
        backgroundColor: "#a0a0a0",
    }
});

/**
 * Table WorkTime
 */
class TableWorkTime extends Component {
    state = {
        workTimeDialog: {
            open: false,
            data: {
                userid: null,
                dateJson: "",
                workTimes: {
                    today: [],
                    yesterday: []
                },
                accountings: []
            }
        },
        workTimeNoAccountingsDialog: {
            open: false,
            dateJson: null
        }
    }

    constructor(props) {
        super(props);
        this.handleWorkTimeClick = this.handleWorkTimeClick.bind( this );
        this.handleWorkTimeDialog_Ok = this.handleWorkTimeDialog_Ok.bind( this );
        this.handleWorkTimeDialog_Cancel = this.handleWorkTimeDialog_Cancel.bind( this );
        this.handleWorkTimeDialog_Delete = this.handleWorkTimeDialog_Delete.bind( this );
        this.handleWorkTimeNoAccountingsDialog_Cancel = this.handleWorkTimeNoAccountingsDialog_Cancel.bind( this );
    }

    // Close the work time dialog
    closeWorkTimeDialog() {
        let emptyData = {
            userid: null,
            dateJson: "",
            workTimes: {
                today: [],
                yesterday: []
            },
            accountings: []
        }

        this.setState({ workTimeDialog: { open: false, data: emptyData } });
    }

    // Close worktime dialog, apply changes
    handleWorkTimeDialog_Ok( dateJson, contents ) {

        // Verify data
        if( null == contents || null == contents.data ) {
            return;
        }

        let userid = contents.data.userid;

        // Push modification call
        if( null != this.props.onModifyWorkTime ) {
            this.props.onModifyWorkTime( userid, contents.data );
        }

        this.closeWorkTimeDialog();
    }

    // Close worktime dialog, do not apply changes
    handleWorkTimeDialog_Cancel() {
        this.closeWorkTimeDialog();
    }

    // Delete the worktime from the entry
    handleWorkTimeDialog_Delete( dateJson, contents ) {

        let userid = contents.data.userid;

        // Push deleted worktimes
        if( null != this.props.onDeleteWorkTime ) {
            this.props.onDeleteWorkTime( userid, dateJson );
        }

        this.closeWorkTimeDialog();
    }

    // Close worktime_noaccountings dialog
    handleWorkTimeNoAccountingsDialog_Cancel() {
        this.setState({ workTimeNoAccountingsDialog: { open: false, dateJson: null } });
    }

    // Add to table data array
    addTableData( name, value ) {
        return { name, value };
    }

    // Get rest period
    getRestPeriod( workTimesToday, workTimesYesterday ) {
        let restPeriod = -1;

        // Check for rest period
        let momentEndTimeYesterday = null;
        let momentStartTimeToday = null;

        for( let i = 0; i < workTimesYesterday.length; i++ ) {
            if( null == momentEndTimeYesterday ) {
                momentEndTimeYesterday = moment( fromTimeStringToDate( workTimesYesterday[i].end_time ) );
            } else {
                let newEndTimeYesterday = moment( fromTimeStringToDate( workTimesYesterday[i].end_time ) );

                if( true === newEndTimeYesterday.isAfter( momentEndTimeYesterday ) ) {
                    momentEndTimeYesterday = newEndTimeYesterday;
                }
            }
        }

        for( let i = 0; i < workTimesToday.length; i++ ) {
            if( null == momentStartTimeToday ) {
                momentStartTimeToday = moment( fromTimeStringToDate( workTimesToday[i].start_time ) );
            } else {
                let newStartTimeToday = moment( fromTimeStringToDate( workTimesToday[i].end_time ) );

                if( true === newStartTimeToday.isBefore( momentStartTimeToday ) ) {
                    momentStartTimeToday = newStartTimeToday;
                }
            }
        }

        if( null != momentEndTimeYesterday && null != momentStartTimeToday ) {
            momentEndTimeYesterday.subtract(1, "days");

            const duration = moment.duration(momentStartTimeToday.diff(momentEndTimeYesterday));
            restPeriod = parseFloat( duration.asHours().toFixed(2) );
        }

        return restPeriod;
    }

    // Check if a worktime entry is available for the given date
    hasWorkTimeEntry( date, userid, worktimes ) {

        if( null == date || null == userid || null == worktimes ) {
            return false;
        }

        const dateString = toJSONLocalDate( date );

        for( let i = 0; i < worktimes.length; i++ ) {
            if( worktimes[i].userid === userid ) {
                if( worktimes[i].date === dateString ) {
                    return true;
                }
            }
        }

        return false;
    }

    // Get worktime entries for date
    getWorkTimeEntries( dateJson, userid, worktimes ) {

        if( null == dateJson || "" === dateJson || null == userid || null == worktimes ) {
            return { state: false, worktimes: [] };
        }

        let foundWorktimes = [];

        for( let i = 0; i < worktimes.length; i++ ) {
            if( worktimes[i].userid === userid ) {
                if( worktimes[i].date === dateJson ) {
                    foundWorktimes.push( worktimes[i] );
                }
            }
        }

        let state = false;
        if( foundWorktimes.length > 0 ) {
            state = true;
        }

        return { state: state, worktimes: foundWorktimes };
    }

    // Handle if someone click on the worktime button
    handleWorkTimeClick( event, userid, dateJson, defaultWorkStartTime, isDayLocked, workTimeEntriesToday, workTimeEntriesYesterday, accountingsToday ) {

        // Do not do anything if day is locked
        if( true === isDayLocked ) {
            return;
        }

        // Check if there are no accountings on this date yet, if there are none open a warning dialog
        if( null != accountingsToday && accountingsToday.length === 0 ) {
            this.setState({ workTimeNoAccountingsDialog: { open: true, dateJson: dateJson } });
            return;
        }

        let data = {
            userid: userid,
            dateJson: dateJson,
            defaultWorkStartTime: defaultWorkStartTime,
            worktimes: {
                today: workTimeEntriesToday,
                yesterday: workTimeEntriesYesterday
            },
            accountings: accountingsToday
        }

        // Open work time dialog
        this.setState({ workTimeDialog: { open: true, data: data } });
    }

    // Generate the worktime button
    generateWorkTimeButton( date, userid, isDayLocked, hasWorkTimeEntry, accountingsToday, specialAutoAccounting, worktimes, trackWorkTimeStartDate, defaultWorkStartTime ) {

        const dateJson = toJSONLocalDate( date );
        const dateObjectYesterday = moment(date).subtract(1, 'days').toDate();

        let workTimeEntriesToday = [];
        let workTimeEntriesYesterday = [];
        let workTimeWarnings = [];

        // Determine if we want to show the worktime icon on the given day and if we show it determine its color
        let showButton = true;

        // Do not show icon if current day is before worktime tracking start according to config file
        if( date < trackWorkTimeStartDate ) {
            showButton = false;
        }

        // Do not show icon if current day has an absence reason which is auto accounting
        if( true === specialAutoAccounting ) {
            showButton = false;
        }

        // Do not show icon if there are no accountings on this day yet
        if( accountingsToday.length === 0 ) {
            showButton = false;
        }

        if( true === hasWorkTimeEntry ) {

            // We have worktimes for this day, add work entry details to tooltip
            const state = this.getWorkTimeEntries( dateJson, userid, worktimes );

            // Also check if the entered work times have a warning
            const stateYesterday = this.getWorkTimeEntries( toJSONLocalDate( dateObjectYesterday ), userid, worktimes );

            if( true === stateYesterday.state ) {
                workTimeEntriesYesterday = stateYesterday.worktimes;
            }

            let workTimeData = {
                userid: userid,
                dateJson: dateJson,
                defaultWorkStartTime: defaultWorkStartTime,
                worktimes: {
                    today: state.worktimes,
                    yesterday: stateYesterday.worktimes
                },
                accountings: accountingsToday
            }

            const workTimeSummary = getWorkTimeSummary( workTimeData );
            workTimeWarnings = getWorkTimeWarnings( workTimeData, workTimeSummary );

            workTimeEntriesToday = state.worktimes;
        }

        // If we show the button also gather the work time entries for yesterday since that is necessary
        // to display warnings in case of insufficient nightly rest period, we also need the accountings for today
        if( true === showButton && false === hasWorkTimeEntry ) {

            const state = this.getWorkTimeEntries( toJSONLocalDate( dateObjectYesterday ), userid, worktimes );
            if( true === state.state ) {
                workTimeEntriesYesterday = state.worktimes;
            }
        }

        // Generate the button if necessary
        if( true === showButton ) {
            const setDate = new Date(date);
            return (
                <WorkTimeButton
                    date={setDate}
                    isDayLocked={isDayLocked}
                    hasWorkTimeEntryToday={hasWorkTimeEntry}
                    workTimeWarnings={workTimeWarnings}
                    onClick={(event) => this.handleWorkTimeClick( event, userid, dateJson, defaultWorkStartTime, isDayLocked, workTimeEntriesToday, workTimeEntriesYesterday, accountingsToday )}
                />
            )
        } else {
            return "";
        }
    }

    // Get all the series
    getAllSeries( userid, firstDayInMonth, lastDayInMonth, worktimes, contracts, absences, holidays, specials, accountings, accountingAutoLock, accountingAutoLockPeriodOfDays, specialAutoAccountingLock, defaultWorkStartTime ) {
        let allSeries = {
            seriesEditors: [],
            seriesDays: [],
            seriesStartTime: [],
            seriesEndTime: [],
            seriesBreak: [],
            seriesSumOfTimeAtWork: [],
            seriesSumOfBreaks: [],
            seriesWorkTime: [],
            seriesRestPeriod: [],
            seriesRowProps: []
        }

        /* Series row props per row
            dateJson: <date in json format>
            isToday: [true|false],
            isWeekEnd: [true|false],
            isWorkDay: [true|false],
            holiday: {
                state: [true|false],
                name: <name of holiday>
            },
            absent: {
                state: [true|false],
                specialid: <id of special>
            }
        */

        // Go through each day
        for (var date = firstDayInMonth; date <= lastDayInMonth; date.setDate(date.getDate() + 1)) {
            const dateJson = toJSONLocalDate( date );
            const dateObjectYesterday = moment(date).subtract(1, 'days').toDate();
            const dateJsonYesterday = toJSONLocalDate( dateObjectYesterday );

            let rowProps = {
                isToday: false,
                isWeekEnd: false,
                isWorkDay: false,
                holiday: {
                    state: false,
                    name: ""
                },
                absent: {
                    state: false,
                    specialid: 0
                }
            }

            let isWeekEnd = false;
            if( date.getDay() === 0 || date.getDay() === 6 ) {
                isWeekEnd = true;
            }

            const isToday = CalendarHelper.isToday( date );
            const holiday = CalendarHelper.isHoliday( holidays, date );
            const absent = CalendarHelper.isAbsent( absences, userid, date );
            const isWorkDay = CalendarHelper.isWorkDay( contracts, userid, date );
            const isDayLocked = CalendarHelper.isDayLocked( dateJson, accountingAutoLock, accountingAutoLockPeriodOfDays, specialAutoAccountingLock, contracts, userid, accountings, specials );
            const hasWorkTimeEntry = this.hasWorkTimeEntry( date, userid, worktimes );
            const accountingsToday = getAccountingsForDate( dateJson, accountings, userid );
            const specialAutoAccounting = isSpecialAutoAccounting( absent.specialid, specials );
            const trackWorkTimeStartDate = toDateObject( config.workTimes.trackStartDate );

            rowProps.dateJson = dateJson;
            rowProps.isToday = isToday;
            rowProps.isWeekEnd = isWeekEnd;
            rowProps.isWorkDay = isWorkDay;
            rowProps.holiday = holiday;
            rowProps.absent = absent;

            allSeries.seriesRowProps.push( rowProps );

            // Generate the worktime button
            let workTimeButton = this.generateWorkTimeButton( date, userid, isDayLocked, hasWorkTimeEntry, accountingsToday, specialAutoAccounting, worktimes, trackWorkTimeStartDate, defaultWorkStartTime );
            allSeries.seriesEditors.push( workTimeButton );

            let dateElement = (
                <DateOnly language={this.context.language} value={new Date( date )} showYear={false} showNameOfDay={true} align="center" />
            )
            allSeries.seriesDays.push( dateElement );

            // Search for all worktime entries of this day
            let worktimesOnDay = [];
            let worktimesYesterday = [];

            for( let i = 0; i < worktimes.length; i++ ) {
                if( worktimes[i].date === dateJson && worktimes[i].userid === userid ) {
                    if( worktimesOnDay.length > 0 ) {
                        allSeries.seriesDays.push( "" );
                        allSeries.seriesEditors.push( "" );
                        allSeries.seriesRowProps.push( rowProps );
                    }
                    worktimesOnDay.push( worktimes[i] );

                    allSeries.seriesStartTime.push( removeSecondsFromTimeString( worktimes[i].start_time ) );
                    allSeries.seriesEndTime.push( removeSecondsFromTimeString( worktimes[i].end_time ) );
                    allSeries.seriesBreak.push( worktimes[i].break );
                } else if( worktimes[i].date === dateJsonYesterday && worktimes[i].userid === userid ) {
                    worktimesYesterday.push( worktimes[i] );
                }
            }

            // Add empty data for the starttime, endtime and breaks if there was no worktime entry done on that day yet
            if( worktimesOnDay.length === 0 ) {
                allSeries.seriesStartTime.push( i18n.t("pages.reports.options.worktime.table.no_entry") );
                allSeries.seriesEndTime.push( i18n.t("pages.reports.options.worktime.table.no_entry") );
                allSeries.seriesBreak.push( i18n.t("pages.reports.options.worktime.table.no_entry") );
            }

            // Get the summary and its data
            let data = {
                worktimes: {
                    today: worktimesOnDay
                },
                accountings: []
            }
            const summary = getWorkTimeSummary( data );

            if( worktimesOnDay.length > 0 ) {
                allSeries.seriesSumOfTimeAtWork.push( summary.workTime );
                allSeries.seriesSumOfBreaks.push( summary.breaks );
                allSeries.seriesWorkTime.push( summary.workTimeNoBreaks );
            } else {
                allSeries.seriesSumOfTimeAtWork.push( i18n.t("pages.reports.options.worktime.table.not_applicable") );
                allSeries.seriesSumOfBreaks.push( i18n.t("pages.reports.options.worktime.table.not_applicable") );
                allSeries.seriesWorkTime.push( i18n.t("pages.reports.options.worktime.table.not_applicable") );
            }

            // Add the rest period
            const restPeriod = this.getRestPeriod( worktimesOnDay, worktimesYesterday );
            if( restPeriod >= 0 ) {
                allSeries.seriesRestPeriod.push( restPeriod );
            } else {
                allSeries.seriesRestPeriod.push( i18n.t("pages.reports.options.worktime.table.not_applicable") );
            }

            // Add empty entries for each additional worktime entry on the day so that we skip these lines
            for( let i = 1; i < worktimesOnDay.length; i++ ) {
                allSeries.seriesSumOfTimeAtWork.push( "" );
                allSeries.seriesSumOfBreaks.push( "" );
                allSeries.seriesWorkTime.push( "" );
                allSeries.seriesRestPeriod.push( "" );
            }
        
        }

        return allSeries;
    }

    // Get all the series from props
    getAllSeriesFromProps() {

        // We start at the first day of the given month and run till the last day
        // First day might change if we are in the month when config tells us that we started the worktime tracking
        let firstDayInMonth = new Date(this.props.year, this.props.month-1, 1);
        let lastDayInMonth = new Date(this.props.year, this.props.month, 0);

        const trackWorkTimeStartDate = toDateObject( config.workTimes.trackStartDate );
        const momentTrackWorkTimeStartDate = moment( trackWorkTimeStartDate );

        // Adjust the first day in the month if we started tracking later
        const monthRange = moment.range( firstDayInMonth, lastDayInMonth );
        if( true === monthRange.contains( momentTrackWorkTimeStartDate ) ) {
            firstDayInMonth = trackWorkTimeStartDate;
        }

        return this.getAllSeries( this.props.userid, firstDayInMonth, lastDayInMonth, this.props.worktimes, this.props.contracts, this.props.absences, 
            this.props.holidays, this.props.specials, this.props.accountings, this.props.accountingAutoLock, this.props.accountingAutoLockPeriodOfDays,
            this.props.specialAutoAccountingLock, this.props.defaultWorkStartTime );
    }

    // Render worktime table
    renderWorkTimeTable( userid, year, month, worktimes, contracts, absences, holidays, specials, accountings, accountingAutoLock, accountingAutoLockPeriodOfDays, specialAutoAccountingLock, defaultWorkStartTime ) {

        // We start at the first day of the given month and run till the last day
        // First day might change if we are in the month when config tells us that we started the worktime tracking
        let firstDayInMonth = new Date(year, month-1, 1);
        let lastDayInMonth = new Date(year, month, 0);

        const trackWorkTimeStartDate = toDateObject( config.workTimes.trackStartDate );
        const momentTrackWorkTimeStartDate = moment( trackWorkTimeStartDate );

        // Adjust the first day in the month if we started tracking later
        const monthRange = moment.range( firstDayInMonth, lastDayInMonth );
        if( true === monthRange.contains( momentTrackWorkTimeStartDate ) ) {
            firstDayInMonth = trackWorkTimeStartDate;
        }

        let tableHeader = [];

        // Assemble table content
        let tableData = [];

        // Check the case where the whole month is before we tracked any work times
        if( true === momentTrackWorkTimeStartDate.isAfter( moment( lastDayInMonth ) ) ) {

            tableHeader = [
                { id: 1, width: "100%", name: i18n.t("pages.reports.options.worktime.table.no_data") },
            ];

            tableData.push({ 
                values: [ 
                    i18n.t("pages.reports.options.worktime.no_data", { date: config.workTimes.trackStartDate }) 
                ],
                props: null
            });
        } else {

            tableHeader = [
                { id: 1, width: "4%", name: "" },
                { id: 2, width: "12%", name: i18n.t("pages.reports.options.worktime.table.date") },
                { id: 3, width: "12%", name: i18n.t("pages.reports.options.worktime.table.start_time") },
                { id: 4, width: "12%", name: i18n.t("pages.reports.options.worktime.table.end_time") },
                { id: 5, width: "12%", name: i18n.t("pages.reports.options.worktime.table.break") },
                { id: 6, width: "12%", name: i18n.t("pages.reports.options.worktime.table.sum_time_at_work") },
                { id: 7, width: "12%", name: i18n.t("pages.reports.options.worktime.table.sum_breaks") },
                { id: 8, width: "12%", name: i18n.t("pages.reports.options.worktime.table.sum_worktime") },
                { id: 9, width: "12%", name: i18n.t("pages.reports.options.worktime.table.rest_period") }
            ];

            const { seriesEditors, seriesDays, seriesStartTime, seriesEndTime, 
                seriesBreak, seriesSumOfTimeAtWork, seriesSumOfBreaks, seriesWorkTime, 
                seriesRestPeriod, seriesRowProps } = this.getAllSeries( 
                    userid, firstDayInMonth, lastDayInMonth, worktimes, contracts, 
                    absences, holidays, specials, accountings, accountingAutoLock, 
                    accountingAutoLockPeriodOfDays, specialAutoAccountingLock, defaultWorkStartTime );

            for( let i = 0; i < seriesDays.length; i++ ) {
                let tableRow = {
                    values: [
                        seriesEditors[i],
                        seriesDays[i],
                        seriesStartTime[i],
                        seriesEndTime[i],
                        seriesBreak[i],
                        seriesSumOfTimeAtWork[i],
                        seriesSumOfBreaks[i],
                        seriesWorkTime[i],
                        seriesRestPeriod[i]
                    ],
                    props: seriesRowProps[i]
                }
                tableData.push(tableRow);
            }
        }

        /* Series row props per row
            isToday: [true|false],
            isWeekEnd: [true|false],
            isWorkDay: [true|false],
            holiday: {
                state: [true|false],
                name: <name of holiday>
            },
            absent: {
                state: [true|false],
                specialid: <id of special>
            }
        */

        let tableKey = "worktime-table-detail";

        let table = (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow key={tableKey}>
                            {tableHeader.map((col, index) => (
                                <TableCell key={tableKey+"-"+index} className={this.props.classes.tableHeaderCell} width={col.width}>{col.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row, i) => {
                            // Determine the classname for the whole row to use
                            const { cellClassName, cellStyle, cellToolTip } = this.getCellPropsAndToolTip( row.props, specials );

                            return (
                                <TableRow key={tableKey+"-row-"+i}>
                                    {row.values.map((col, j) => {
                                        let tableCellContent = "";

                                        if( cellStyle != null ) {
                                            tableCellContent = (
                                                <TableCell key={tableKey+"-row-"+i+"-col-"+j} className={cellClassName} style={cellStyle}>{col}</TableCell>
                                            )
                                        } else {
                                            tableCellContent = (
                                                <TableCell key={tableKey+"-row-"+i+"-col-"+j} className={cellClassName}>{col}</TableCell>
                                            )
                                        }

                                        if( cellToolTip !== "" && j !== 0 ) {
                                            return (
                                                <Tooltip key={tableKey+"-row-"+i+"-col-"+j+"-tooltip"} title={cellToolTip}>
                                                    {tableCellContent}
                                                </Tooltip>
                                            )
                                        } else {
                                            return tableCellContent;
                                        }
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
        return table;
    }

    // Get details for a special
    getSpecialDetails( specialid, specials ) {
        if( null == specials ) {
            return { name: null, short_name: null, color_code: null };
        }

        // Handle special case for specialid zero which occurs if the user is not allowed to see the respective absence
        if( 0 === specialid ) {
            let hiddenSpecial = config.specialIfHidden;
            hiddenSpecial.name = i18n.t("values.hidden");
            return hiddenSpecial;
        }

        for( let i = 0; i < specials.length; i++ ) {
            if( specials[i].specialid === specialid ) {
                return { name: specials[i].name, short_name: specials[i].short_name, color_code: specials[i].color_code };
            }
        }

        return { name: null, short_name: null, color_code: null };
    }

    // Determine cell props and tooltip text
    getCellPropsAndToolTip( rowProps, specials ) {
        let className = "";
        let cellStyle = null;
        let cellTooltip = "";

        if( null == rowProps ) {
            let data = {
                cellClassName: className,
                cellStyle: cellStyle,
                cellToolTip: cellTooltip
            }
    
            return data;
        }

        if( true === rowProps.isToday ) {
            cellTooltip = i18n.t("values.today");
        }

        if( true === rowProps.holiday.state ) {
            if( cellTooltip.length > 0 ) {
                cellTooltip += " - ";
            }
            cellTooltip += rowProps.holiday.name;

            if( true === rowProps.isToday ) {
                if( true === rowProps.isWeekEnd ) {
                    className = clsx(this.props.classes.cellDayBase, this.props.classes.cellHoliday, this.props.classes.cellTodayWeekEnd);
                } else {
                    className = clsx(this.props.classes.cellDayBase, this.props.classes.cellHoliday, this.props.classes.cellTodayWeekDay);
                }
                
            } else {
                className = clsx(this.props.classes.cellDayBase, this.props.classes.cellHoliday);
            }
            
        } else {
            if( true === rowProps.isWeekEnd ) {
                if( cellTooltip.length > 0 ) {
                    cellTooltip += " - ";
                }
                cellTooltip += i18n.t("values.weekend");

                if( true === rowProps.isToday ) {

                    if( true === rowProps.isWorkDay ) {                    
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekEnd);
                    } else {
                        if( cellTooltip.length > 0 ) {
                            cellTooltip += " - ";
                        }
                        cellTooltip += i18n.t("values.no_work_day");
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekEnd, this.props.classes.cellNoWorkDayWeekEnd);
                    }


                } else {
                    if( true === rowProps.isWorkDay ) {
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellWeekEnd);
                    } else {
                        if( cellTooltip.length > 0 ) {
                            cellTooltip += " - ";
                        }
                        cellTooltip += i18n.t("values.no_work_day");

                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellNoWorkDayWeekEnd);
                    }
                }
            } else {
                if( true === rowProps.isToday ) {
                    if( true === rowProps.isWorkDay ) {
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekDay);
                    } else {
                        if( cellTooltip.length > 0 ) {
                            cellTooltip += " - ";
                        }
                        cellTooltip += i18n.t("values.no_work_day");
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekDay, this.props.classes.cellNoWorkDayWeekDay);
                    }

                } else {
                    if( true === rowProps.isWorkDay ) {
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellWeekDay);
                    } else {
                        if( cellTooltip.length > 0 ) {
                            cellTooltip += " - ";
                        }
                        cellTooltip += i18n.t("values.no_work_day");

                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellNoWorkDayWeekDay);
                    }
                }
            }
        }

        if( true === rowProps.absent.state ) {
            const specialDetails = this.getSpecialDetails( rowProps.absent.specialid, specials );
            let absentBackgroundColor = specialDetails.color_code;
            let absentTextColor = invertColorMoreContrast( specialDetails.color_code, true );
            
            cellTooltip = specialDetails.name;

            cellStyle = { backgroundColor: `#${absentBackgroundColor}`, color: `#${absentTextColor}` };
        }

        // Pack into return data object
        let data = {
            cellClassName: className,
            cellStyle: cellStyle,
            cellToolTip: cellTooltip
        }

        return data;
    }

    // Takes props: language, userid, year, month, worktimes, contracts, absences, holidays, specials,accountings, accountingAutoLock, accountingAutoLockPeriodOfDays, specialAutoAccountingLock, defaultWorkStartTime
    render() {
        let workTimeTable = "";
        if( null != this.props.worktimes && null != this.props.userid && null != this.props.year && 
            null != this.props.month && null != this.props.contracts && null != this.props.absences && 
            null != this.props.holidays && null != this.props.specials && null != this.props.accountings &&
            null != this.props.accountingAutoLock && null != this.props.accountingAutoLockPeriodOfDays &&
            null != this.props.specialAutoAccountingLock && null != this.props.defaultWorkStartTime ) {

            workTimeTable = this.renderWorkTimeTable( 
                this.props.userid, this.props.year, this.props.month, this.props.worktimes, this.props.contracts, 
                this.props.absences, this.props.holidays, this.props.specials, this.props.accountings, 
                this.props.accountingAutoLock, this.props.accountingAutoLockPeriodOfDays, this.props.specialAutoAccountingLock, this.props.defaultWorkStartTime );
        }

        let descText = (
            <PageDescription key="worktime-desc">
                {i18n.t("pages.reports.options.worktime.desc", { date: config.workTimes.trackStartDate })}
            </PageDescription>
        )

        return (
            <div>
                {descText}
                <br />
                {workTimeTable}
                <ModalDialog 
                    open={this.state.workTimeDialog.open}
                    title={i18n.t("pages.main.worktime.dialog.title", { date: toDateOnlyString( this.state.workTimeDialog.data.dateJson ) })}
                    descText={i18n.t("pages.main.worktime.dialog.description")}
                    buttonLeft={i18n.t("pages.main.worktime.dialog.button_left")}
                    buttonRight={i18n.t("pages.main.worktime.dialog.button_right")}
                    handleClickClose={() => this.handleWorkTimeDialog_Cancel()}
                    handleClickLeft={(contents) => this.handleWorkTimeDialog_Ok(this.state.workTimeDialog.data.dateJson, contents)}
                    handleClickRight={() => this.handleWorkTimeDialog_Cancel()}
                    handleClickDelete={(contents) => this.handleWorkTimeDialog_Delete(this.state.workTimeDialog.data.dateJson, contents)}
                    showDeleteButton={true}
                    disableDeleteButton={false}
                    buttonDeleteTooltip={i18n.t("pages.main.worktime.dialog.button_delete_tooltip")}
                    minWidth="500px"
                >
                    <ModalDialogWorkTime
                        key="worktime-dialog"
                        data={this.state.workTimeDialog.data}
                    />
                </ModalDialog>
                <ModalDialog 
                    open={this.state.workTimeNoAccountingsDialog.open}
                    title={i18n.t("pages.main.worktime.dialog_noaccountings.title", { date: toDateOnlyString( this.state.workTimeNoAccountingsDialog.dateJson ) })}
                    descText={i18n.t("pages.main.worktime.dialog_noaccountings.description")}
                    buttonRight={i18n.t("pages.main.worktime.dialog_noaccountings.button_right")}
                    handleClickClose={() => this.handleWorkTimeNoAccountingsDialog_Cancel()}
                    handleClickRight={() => this.handleWorkTimeNoAccountingsDialog_Cancel()}
                    showButtonLeft={false}
                    showDeleteButton={false}
                /> 
            </div>
        );
    }
};

export default withStyles(useStyles)(TableWorkTime);