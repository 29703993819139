import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { jsonEqual } from '../helpers.js';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { StyledChipEnabled, StyledChipDisabled } from './StyledChip'
import i18n from 'i18next';

const useStyles = theme => ({
    content: {
        flexGrow: 1,
    }
});

/**
 * Chip AutoComplete Component
 */
class ChipAutoComplete extends Component {
    state = {
        editing: false
    }

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind( this );
        this.getOptionSelected = this.getOptionSelected.bind( this );
        this.handleButtonApplyClick = this.handleButtonApplyClick.bind( this );
        this.handleButtonResetClick = this.handleButtonResetClick.bind( this );
        this.handleOnOpen = this.handleOnOpen.bind( this );
        this.handleOnBlur = this.handleOnBlur.bind( this );

        this.textFieldInputRef = React.createRef();
    }

    // Helper function to create a list of unselected entries from the selected list and all items
    createListOfSelectedItems( dataSelected, dataAll ) {
        
        // Remove all elements available in dataSelected from dataAll to create a new list of unselected data options
        let dataUnselected = dataAll.filter(
            function( dataEntry ) {
                for( let i = 0; i < dataSelected.length; i++ ) {
                    if( true === jsonEqual( dataEntry, dataSelected[i] ) ) {
                        return false;
                    }
                }
                return true;
            }
        );
        return dataUnselected;
    }

    // Handle changes
    handleChange( event, value, reason ) {
        if( reason === "select-option" || reason === "remove-option" || reason === "clear" ) {

            let dataSelected = value; // Already includes all selected entries (plus or minus selected and unselected ones)
            let dataAll = this.props.dataAll;
    
            // Remove all elements available in dataSelected from dataAll to create a new list of unselected data options
            let dataUnselected = this.createListOfSelectedItems( dataSelected, dataAll );

            if( null != this.props.dataChanged ) {
                this.props.dataChanged( dataUnselected, dataSelected );
            }

            this.setState({ editing: true });
        }
    }

    // Check values for equality
    getOptionSelected( option, value ) {
        return jsonEqual( option, value );
    }

    // Handle Apply button click on custom end adornments
    handleButtonApplyClick(event) {
        event.stopPropagation();
        this.textFieldInputRef.current.blur();

        if( null != this.props.onEndAdornmentApply ) {
            this.props.onEndAdornmentApply();
        }
        this.setState({ editing: false });
    }

    // Handle Reset button click on custom end adornments
    handleButtonResetClick(event) {
        event.stopPropagation();
        this.textFieldInputRef.current.blur();

        if( null != this.props.onEndAdornmentReset ) {
            this.props.onEndAdornmentReset();
        }
        this.setState({ editing: false });
    }

    // Called when the popup is opened
    handleOnOpen() {
        if( true === this.props.enableCustomEndAdornments ) {
            this.setState({ editing: true });
        }
    }

    // User clicked away from the input field (defocused it)
    handleOnBlur() {
        // If blurred check if we are in editing state and made any unapplied changes
        // If there are no unapplied changes go out of editing mode as if the cancel button was clicked
        if( null != this.props.getDataChanged() ) {
            if( true === this.state.editing && false === this.props.getDataChanged() ) {
                this.setState({ editing: false });
            }
        }
    }

    render() {
        // Extract locally used props and leave others
        var { 
            classes, className, enableCustomEndAdornments, getDataChanged, dataAll, onEndAdornmentApply, onEndAdornmentReset, dataChanged,
            dataSelected, textFieldLabel, textFieldPlaceholder, getOptionLabel, getOptionEnabled, ...otherProps } = this.props;

        // Check if we should show and utilize custom end adornments
        let endAdornmentProps = null;
        if( true === enableCustomEndAdornments && true === this.state.editing ) {
            endAdornmentProps = (
                <InputAdornment position='end'>
                    <IconButton
                        onClick={(event) => this.handleButtonApplyClick(event)}
                        color="primary"
                        edge="end"
                        disableRipple={true}
                        size="small"
                        style={{ visibility: "visible" }}
                        disabled={!getDataChanged()}
                    >
                        <CheckCircleRoundedIcon />
                    </IconButton>
                    <IconButton
                        onClick={(event) => this.handleButtonResetClick(event)}
                        color="primary"
                        edge="end"
                        disableRipple={true}
                        size="small"
                        style={{ visibility: "visible" }}
                    >
                        <CancelRoundedIcon />
                    </IconButton>
                </InputAdornment>
            );
        }

        // Combine both dataSelected and dataAll (which does not include dataSelected, else a warning is thrown in Autocomplete)
        let dataCombined = [];
        for( let i = 0; i < dataSelected.length; i++ ) {
            dataCombined.push( dataSelected[i] );
        }
        for( let i = 0; i < dataAll.length; i++ ) {
            dataCombined.push( dataAll[i] );
        }

        return (
            <div className={className}>
                <Autocomplete
                    multiple
                    id="selection"
                    options={dataCombined}
                    value={dataSelected}
                    onChange={this.handleChange}
                    getOptionSelected={this.getOptionSelected}
                    getOptionLabel={getOptionLabel}
                    filterSelectedOptions
                    autoHighlight={true}
                    openOnFocus={true}
                    onOpen={this.handleOnOpen}
                    onBlur={this.handleOnBlur}
                    forcePopupIcon={false}
                    disableClearable
                    {...otherProps}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={textFieldLabel}
                            placeholder={textFieldPlaceholder}
                            inputRef={this.textFieldInputRef}
                            InputProps={{
                                ...params.InputProps,
                                style: {minHeight: "60px"},
                                endAdornment: endAdornmentProps
                            }}
                        />
                    )}
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map(function(option, index) {
                            if( null != getOptionEnabled ) {
                                if( true === getOptionEnabled( option ) ) {
                                    return (
                                        <StyledChipEnabled {...getTagProps({ index })} label={getOptionLabel( option ) } />
                                    )
                                } else {
                                    return (
                                        <Tooltip key={"chip-tooltip-"+index} title={i18n.t("tooltips.user_disabled")}>
                                            <StyledChipDisabled {...getTagProps({ index })} label={getOptionLabel( option ) } />
                                        </Tooltip>
                                    )
                                }                            
                            } else {
                                return (
                                    <StyledChipEnabled {...getTagProps({ index })} label={getOptionLabel( option ) } />
                                )
                            }
                        })
                    }}
                />
            </div>
        );
    }
};

export default withStyles(useStyles)(ChipAutoComplete);