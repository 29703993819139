import React, { Component } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import { Link } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Account menu
 */
class AccountMenu extends Component {
    render() {
        return (
            <div id="account-menu">
                <ListItem button component={Link} to="/profile" className={this.props.className}>
                    <ListItemIcon><PersonIcon className={this.props.className} /></ListItemIcon>
                    <ListItemText primary={i18n.t("menus.my_profile")} className={this.props.className}/>
                </ListItem>
                <ListItem button component={Link} to="/account" className={this.props.className}>
                    <ListItemIcon><SettingsIcon className={this.props.className} /></ListItemIcon>
                    <ListItemText primary={i18n.t("menus.my_account")} className={this.props.className}/>
                </ListItem>
                <ListItem button component={Link} to="/logout" className={this.props.className}>
                    <ListItemIcon><ExitToAppIcon className={this.props.className} /></ListItemIcon>
                    <ListItemText primary={i18n.t("menus.logout")} className={this.props.className}/>
                </ListItem>
            </div>
        );
    }
};

export default withStyles(useStyles)(AccountMenu);