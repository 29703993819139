import React, { Component } from 'react';
import UserService from '../../classes/UserService'
import ContractService from '../../classes/ContractService'
import UserTypeService from '../../classes/UserTypeService'
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import AuthContext from '../../contexts/AuthContext';
import { StyledTableCell, StyledTableRow, StyledTableHead, StyledTable } from '../../components/StyledTable.js'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import PageSubtitle from '../../components/PageSubtitle'
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../components/PageTitle';
import SelectControls from '../../components/SelectControls'
import SelectWorkWeek from '../../components/SelectWorkWeek'
import CustomTextField from '../../components/CustomTextField'
import CustomDatePicker from '../../components/CustomDatePicker'
import ModalDialogDatePicker from '../../components/ModalDialogDatePicker';
import ModalDialog from '../../components/ModalDialog';
import { config } from '../../config';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import { StyledChipEnabled, StyledChipDisabled } from '../../components/StyledChip'
import CustomMultilineTextField from '../../components/CustomMultilineTextField'
import { createDateOnly } from '../../helpers.js';
import Paper from '@material-ui/core/Paper';
import i18n from 'i18next';

const useStyles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    mainGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    tableCellTeamName: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1.0),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
    },
    tablePaginationGrid: {
        height: '60px',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tablePagination: {
        '& .MuiTablePagination-toolbar': {
            height: '60px',
            minHeight: '60px',
        },
    },
    chipInput: {
        width: '100%',
        height: '100%'
    },
});

/**
 * Admin Contracts
 */
class AdminContracts extends Component {
    static contextType = AuthContext;

    // Set in constructor
    state = {}

    /**
     * Inputs Props
     * @param {*} props 
     *  admin (array)
     *  userProps (array)
     *  dataLoadChanged (function)
     *  alertChanged (function)
     */
    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleChangeTablePage = this.handleChangeTablePage.bind( this );
        this.handleChangeTableRowsPerPage = this.handleChangeTableRowsPerPage.bind( this );
        this.handleChangeSelectField = this.handleChangeSelectField.bind( this );
        this.getContractsOfUser = this.getContractsOfUser.bind( this );
        this.getContract = this.getContract.bind( this );
        this.handleAddButtonClick = this.handleAddButtonClick.bind( this );
        this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind( this );
        this.getInitialState = this.getInitialState.bind( this );
        this.verifyStartDateFieldContent = this.verifyStartDateFieldContent.bind( this );
        this.handleStartDateFieldChange = this.handleStartDateFieldChange.bind( this );
        this.verifyEndDateFieldContent = this.verifyEndDateFieldContent.bind( this );
        this.verifyHoursContent = this.verifyHoursContent.bind( this );
        this.handleEndDateFieldChange = this.handleEndDateFieldChange.bind( this );
        this.handleWorkHoursPerWeekChange = this.handleWorkHoursPerWeekChange.bind( this );
        this.handleVacationDaysPerYearChange = this.handleVacationDaysPerYearChange.bind( this );
        this.handleVacationDaysAtStartChange = this.handleVacationDaysAtStartChange.bind( this );
        this.handleWorkHoursAtStartChange = this.handleWorkHoursAtStartChange.bind( this );
        this.handleCommentFieldChange = this.handleCommentFieldChange.bind( this );
        this.verifyCommentFieldContent = this.verifyCommentFieldContent.bind( this );
        this.handleTableRowDeleteClick = this.handleTableRowDeleteClick.bind( this );
        this.getUsersWithoutActiveContract = this.getUsersWithoutActiveContract.bind( this );
        this.handleWorkDaysPerWeekChange = this.handleWorkDaysPerWeekChange.bind( this );
        this.isMostRecentContractOfUser = this.isMostRecentContractOfUser.bind( this );
        this.handleAddDialog_Ok = this.handleAddDialog_Ok.bind( this );
        this.handleAddDialog_Cancel = this.handleAddDialog_Cancel.bind( this );
        this.checkNewContractDates = this.checkNewContractDates.bind( this );
        this.hasUserContractWithNoEndDate = this.hasUserContractWithNoEndDate.bind( this );
        this.getBlockStartDate = this.getBlockStartDate.bind( this );
        this.getBlockEndDate = this.getBlockEndDate.bind( this );
        this.verifyStartDateFieldContentOfNewContract = this.verifyStartDateFieldContentOfNewContract.bind( this );

        // Set initial state
        this.state = this.getInitialState( this.props.userid );
    }

    /**
     * STATE HANDLING
     */

    // Get the initial state variables of this component
    getInitialState( selectedUserId ) {
        return {
            data: { // Raw loaded data from the backend
                contracts: null,
                users: null,
                userTypes: null
            },
            tableData: { // Additional information gathered from loaded raw data to display on the page
                userSelection: []
            },
            selectedUserId: selectedUserId,
            tablePage: 0,
            tableRowsPerPage: 10,
            addDialog: {
                open: false,
                blockStartDate: {
                    before: null,
                    beforeInclusive: false,
                    after: null,
                    afterInclusive: false
                }
            },
            deleteDialog: {
                open: false,
                contractid: null,
                userid: null,
                firstname: null,
                lastname: null
            },
        }
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState() {
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
        this.setState( this.getInitialState( this.state.selectedUserId ) );
    }

    /**
     * LINK TO PARENT COMPONENT FUNCTIONS
     */

    // Propagate information to parent component
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Propagate information to parent component
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Propagate information to parent component
    handleActionInProgressChanged( actionInProgress ) {
        if( this.props.actionInProgressChanged ) {
            this.props.actionInProgressChanged( actionInProgress );
        }
    }

    /**
     * LOAD AND PROCESS BACKEND DATA
     */

    // Called when a refresh of the table data is needed due to edits in the table
    async refreshTableData() {
        await this.loadDataFromBackend( true );
    }

    // Gather all data from the backend needed on this page and process them properly
    async loadDataFromBackend( manualRefresh ) {
        // Alert message to show
        let alert = {
            severity: "info",
            message: ""
        }

        let userData = null;

        // Gather user data
        {
            const { statusCode, statusText, resData } = await UserService.getUsers( {} );
            if( 200 === statusCode && null != resData ) {
                userData = resData.getUsers;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        let userTypeData = null;

        // Gather user type data
        {
            const { statusCode, statusText, resData } = await UserTypeService.getUserTypes( {} );
            if( 200 === statusCode && null != resData ) {
                userTypeData = resData.getUserTypes;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        let contractData = null;

        // Gather contract data
        {
            const { statusCode, statusText, resData } = await ContractService.getContracts( {} );
            if( 200 === statusCode && null != resData ) {
                contractData = resData.getContracts;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Now process the data
        await this.processData( userData, userTypeData, contractData );

        // All fine, clear the error message (only if not a manual refresh)
        if( false === manualRefresh ) {
            this.handleAlertChanged( alert );
        }
        this.handleDataLoadedChanged( true );
    }

    // Called to make additional processing of data, for example gathering info from that backend data and storing it for later use
    async processData( userData, userTypeData, contractData ) {

        // Abort if no proper data given
        if( null == userData || null == userTypeData || null == contractData ) {
            return;
        }

        // Add user type name to all user data entries
        for( let i = 0; i < userData.length; i++ ) {
            let userTypeName = "";
            for( let j = 0; j < userTypeData.length; j++ ) {
                if( userTypeData[j].typeid === userData[i].typeid ) {
                    userTypeName = userTypeData[j].name;
                    break;
                }
            }
            userData[i].userTypeName = userTypeName;
        }

        // Populate userSelection state variable
        let userSelection = [];
        for( let i = 0; i < userData.length; i++ ) {
            let fullUserEntry = userData[i].lastname + ", " + userData[i].firstname;
            if( userData[i].userTypeName != null && userData[i].userTypeName !== "" ) {
                fullUserEntry = "(" + userData[i].userTypeName + ") " + userData[i].lastname + ", " + userData[i].firstname;
            }
            userSelection.push( { id: userData[i].userid, value: fullUserEntry, firstname: userData[i].firstname, lastname: userData[i].lastname, userTypeName: userData[i].userTypeName });
        }
        userSelection.sort(function(a, b) { 
            // Sort by user type first, then by user lastname
            if( a.userTypeName > b.userTypeName ) return 1;
            if( a.userTypeName < b.userTypeName ) return -1;
            if( a.lastname > b.lastname ) return 1;
            if( a.lastname < b.lastname ) return -1;
            return 0;
        });

        // Store the data
        this.setState({ 
            data: { users: userData, userTypes: userTypeData, contracts: contractData },
            tableData: { userSelection: userSelection } 
        });
    }

    /**
     * ALERT DIALOG <ADD>
     */

    // Called to open the add dialog at the given date
    openAddDialog() {

        // Determine blocker ranges for start and end dates of this new contract entry
        // depending on the next and on the previous contract
        let contractsOfUser = this.getContractsOfUser( this.state.selectedUserId );
        contractsOfUser.sort(function(a, b) { return a.date_start < b.date_start ? 1 : -1; });
        let blockStartDate = this.getBlockStartDate( null, contractsOfUser );

        let addDialog = {
            open: true,
            blockStartDate: blockStartDate
        }
        this.setState({ addDialog: addDialog });
    }

    // Called when the user clicked "Ok" on the add user dialog
    async handleAddDialog_Ok( content ) {
        this.closeAddDialog();

        // Check data validity
        if( content == null || content.value == null ) {
            return;
        }

        const startDate = content.value;

        // Check if contract already exists
        const contracts = this.getContractsOfUser( this.state.selectedUserId );
        for( let i = 0; i < contracts.length; i++ ) {
            if( contracts[i].date_start === startDate ) {
                this.handleAlertChanged( { severity: "error", message: i18n.t("pages.admin.contracts.add.alert.does_already_exist") } );
                return;
            }
        }

        const { statusCode, statusText, resData } = await ContractService.createContract( { 
            date_start: startDate,
            date_end: null,
            work_hours_per_week: config.contractDefault.workHoursPerWeek,
            work_days_per_week: config.contractDefault.workDaysPerWeek,
            vacation_days_per_year: config.contractDefault.vacationDaysPerYear,
            userid: this.state.selectedUserId,
            vacation_days_at_start: config.contractDefault.vacationDaysAtStart,
            work_hours_at_start: config.contractDefault.workHoursAtStart,
            comment: config.contractDefault.comment
        } );

        let alert = {
            severity: "info",
            message: ""
        }

        if( 200 === statusCode && null != resData ) {
            alert.severity = "success";
            alert.message = i18n.t("pages.admin.contracts.add.alert.success");
        } else {
            alert.severity = "error";
            alert.message = `${statusText}: ${statusCode}`;
        }

        this.handleAlertChanged( alert );

        // Refresh the table data
        await this.refreshTableData();
    }

    // Called when the user clicked "Cancel" or close on the add role dialog
    handleAddDialog_Cancel() {
        this.closeAddDialog();
    }

    // Close the modify alert dialog
    closeAddDialog() {
        let addDialog = {
            open: false,
            blockStartDate: {
                before: null,
                beforeInclusive: false,
                after: null,
                afterInclusive: false
            }
        }
        this.setState({ addDialog: addDialog });
    }

    /**
     * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
     */

    // Called by react when this component has been mounted
    async componentDidMount() {
        await this.loadDataFromBackend();
    }

    // Called by react before this component unmounts
    componentWillUnmount() {
        this.resetState();
    }

    /**
     * TABLE PAGINATION EVENT HANDLERS
     */

    // Called if page changes on the table display occur
    handleChangeTablePage = (event, newPage) => {
        this.setState({tablePage: newPage});
    };
    
    // Called if the user selects a different amount of shown rows
    handleChangeTableRowsPerPage = (event) => {
        this.setState({tablePage: 0, tableRowsPerPage: +event.target.value});
    };

    /**
     * CALLBACK FUNCTIONS FOR USER SELECT FIELD
     */

    // Called when a user is selected in the search field
    handleChangeSelectField(value) {
        this.setState({ selectedUserId: value });
    }

    /**
     * CALLBACK FUNCTION FOR ADD DIALOG START DATE
     */

    // Check the start date of a completely new contract for the given user
    verifyStartDateFieldContentOfNewContract( userid, date ) {

        // Check data validity
        if( null == userid || null == date ) {
            return { state: false, msg: i18n.t("pages.admin.contracts.dialog.alert.empty") };
        }

        // Convert to comparable time stamps
        let startTime = createDateOnly( date ).getTime();
        let endTime = createDateOnly( "2999-12-31" ); // Use an infinite date to ease up comparison

        // Check: contract date range is not in conflict with another existing contract
        const contracts = this.getContractsOfUser( userid );
        for( let i = 0; i < contracts.length; i++ ) {

            // Get contract date range as a comparable timestamp
            const contractStartTime = createDateOnly( contracts[i].date_start ).getTime();
            let contractEndTime = null;
            if( null != contracts[i].date_end ) {
                contractEndTime = createDateOnly( contracts[i].date_end ).getTime();
            } else {
                contractEndTime = createDateOnly( "2999-12-31" ); // Use an infinite date to ease up comparison
            }

            // Check: start date falls within range of this contract
            if( startTime >= contractStartTime && startTime <= contractEndTime ) {
                return { state: false, msg: i18n.t("pages.admin.contracts.dialog.alert.date_conflicts_with_date_of_other_contracts") };
            }

            // Check: end date falls within range of this contract
            if( endTime >= contractStartTime && endTime <= contractEndTime ) {
                return { state: false, msg: i18n.t("pages.admin.contracts.dialog.alert.date_conflicts_with_date_of_other_contracts") };
            }

            // Check: start date to end date range conflicts with this contract
            if( startTime < contractStartTime && endTime > contractEndTime ) {
                return { state: false, msg: i18n.t("pages.admin.contracts.dialog.alert.date_conflicts_with_date_of_other_contracts") };
            }
        }

        // All fine if we reached this point
        return { state: true, msg: "" };
    }

    // Called to verify hours content
    verifyHoursContent(value) {
        // The text field is already checking for range and if the number is positive or negative
        // Here we only need to check if the fractions are matching
        if( value !== "" ) {

            let hoursPrecision = 0.25;
            if( null != this.context.config ) {
                if( null != this.context.config.accounting.hours_precision ) {
                    hoursPrecision = this.context.config.accounting.hours_precision;
                }
            }

            let result = parseFloat( value )%hoursPrecision;
            if( result !== 0 ) {
                return { state: false, msg: i18n.t("pages.admin.contracts.dialog.alert.hours_invalid_fraction", { hoursPrecision: hoursPrecision }) };
            }
        } else {

            // Field is empty, this is not allowed
            return { state: false, msg: i18n.t("pages.admin.contracts.dialog.alert.field_empty") };
        }

        // All hours are fine, the ranges are already checked by the field itself
        return { state: true, msg: "" };
    }

    /**
     * CALLBACK FUNCTIONS FOR START DATE SELECTOR
     */

    // Called to verify a new contract start date
    verifyStartDateFieldContent( contract, date ) {
        return this.checkNewContractDates( contract, date, null );
    }

    // Called to set a new contract start date
    async handleStartDateFieldChange( event, contract, newDate ) {
        event.stopPropagation();

        // Update the user
        if( null != contract && null != newDate ) {
            const { statusCode, statusText, resData } = await ContractService.updateContract( { contractid: contract.contractid }, { date_start: newDate } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.contracts.alert.success.update");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called to verify a new contract end date
    verifyEndDateFieldContent( contract, date ) {
        return this.checkNewContractDates( contract, null, date );
    }

    // Called to set a new contract end date
    async handleEndDateFieldChange( event, contract, newDate ) {
        event.stopPropagation();

        // Update the contract
        if( null != contract ) {
            const { statusCode, statusText, resData } = await ContractService.updateContract( { contractid: contract.contractid }, { date_end: newDate } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.contracts.alert.success.update");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the work hours per week changed
    async handleWorkHoursPerWeekChange( event, contract, newValue ) {
        event.stopPropagation();

        // Update the contract
        if( null != contract ) {
            const { statusCode, statusText, resData } = await ContractService.updateContract( { contractid: contract.contractid }, { work_hours_per_week: parseFloat( newValue ) } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.contracts.alert.success.update");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the vacation days per year changed
    async handleVacationDaysPerYearChange( event, contract, newValue ) {
        event.stopPropagation();

        // Update the contract
        if( null != contract ) {
            const { statusCode, statusText, resData } = await ContractService.updateContract( { contractid: contract.contractid }, { vacation_days_per_year: parseInt( newValue ) } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.contracts.alert.success.update");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the vacation days at start changed
    async handleVacationDaysAtStartChange( event, contract, newValue ) {
        event.stopPropagation();

        // Update the contract
        if( null != contract ) {
            const { statusCode, statusText, resData } = await ContractService.updateContract( { contractid: contract.contractid }, { vacation_days_at_start: parseInt( newValue ) } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.contracts.alert.success.update");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the work hours at start changed
    async handleWorkHoursAtStartChange( event, contract, newValue ) {
        event.stopPropagation();

        // Update the contract
        if( null != contract ) {
            const { statusCode, statusText, resData } = await ContractService.updateContract( { contractid: contract.contractid }, { work_hours_at_start: parseFloat( newValue ) } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.contracts.alert.success.update");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Handle when a contract comment text field is changed
    async handleCommentFieldChange(event, contract, newValue) {
        event.stopPropagation();

        // Update the contract
        if( null != contract ) {
            const { statusCode, statusText, resData } = await ContractService.updateContract( { contractid: contract.contractid }, { comment: newValue } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.contracts.alert.success.update");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Verify content of comment field
    verifyCommentFieldContent( textFieldContractId, textFieldValue ) {
        return { state: true, msg: "" }
    }

    // Called when the delete button has been clicked on a contract
    async handleTableRowDeleteClick( event, contractid ) {
        event.stopPropagation();

        // Delete the contract
        if( null != contractid ) {
            const { statusCode, statusText, resData } = await ContractService.deleteContract( { contractid: contractid } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                if( true === resData.deleteContract.result ) {
                    alert.severity = "success";
                    alert.message = i18n.t( "pages.admin.contracts.alert.success.delete" );
                } else {
                    alert.severity = "error";
                    alert.message = `${statusText}: ${statusCode}`;
                }
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when one of the checkboxes within the work week selector is clicked
    async handleWorkDaysPerWeekChange( contract, checkedDaysAsString ) {

        // Update the contract
        if( null != contract ) {
            const { statusCode, statusText, resData } = await ContractService.updateContract( { contractid: contract.contractid }, { work_days_per_week: checkedDaysAsString } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.contracts.alert.success.update");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    /**
     * TOP ROW BUTTONS
     */

    // Called if the add button is clicked
    async handleAddButtonClick() {
        this.openAddDialog();
    }

    // Called if the refresh button is clicked
    async handleRefreshButtonClick() {
        this.resetState();
        await this.refreshTableData();
    }

    /**
     * HELPERS CALLED DURING RENDER TO RETRIEVE INFO FROM PROCESSED DATA
     */

    // Check the new dates of a contract
    checkNewContractDates( contract, newStartDate, newEndDate ) {

        // Check data validity
        if( null == contract ) {
            return { state: false, msg: i18n.t("pages.admin.contracts.dialog.alert.empty") };
        }

        // Fill any null data from the given contract
        let startDate = newStartDate;
        if( null == startDate ) {
            startDate = contract.date_start;
        }
        let endDate = newEndDate;
        if( null == endDate ) {
            endDate = contract.date_end;
        }

        // At this point the end date might still be null because the contract has no end date
        // Convert to comparable time stamps
        let startTime = createDateOnly( startDate ).getTime();
        let endTime = null;
        if( null != endDate ) {
            endTime = createDateOnly( endDate ).getTime();
        } else {
            endTime = createDateOnly( "2999-12-31" ); // Use an infinite date to ease up comparison
        }

        // Check: start date has to be prior to end date
        if( startTime > endTime ) {
            return { state: false, msg: i18n.t("pages.admin.contracts.dialog.alert.date_conflicts_with_date_of_current_contract") };
        }

        // Check: contract date range is not in conflict with another existing contract
        const contracts = this.getContractsOfUser( contract.userid );
        for( let i = 0; i < contracts.length; i++ ) {
            if( contract.contractid !== contracts[i].contractid ) {

                // Get contract date range as a comparable timestamp
                const contractStartTime = createDateOnly( contracts[i].date_start ).getTime();
                let contractEndTime = null;
                if( null != contracts[i].date_end ) {
                    contractEndTime = createDateOnly( contracts[i].date_end ).getTime();
                } else {
                    contractEndTime = createDateOnly( "2999-12-31" ); // Use an infinite date to ease up comparison
                }

                // Check: start date falls within range of this contract
                if( startTime >= contractStartTime && startTime <= contractEndTime ) {
                    return { state: false, msg: i18n.t("pages.admin.contracts.dialog.alert.date_conflicts_with_date_of_other_contracts") };
                }

                // Check: end date falls within range of this contract
                if( endTime >= contractStartTime && endTime <= contractEndTime ) {
                    return { state: false, msg: i18n.t("pages.admin.contracts.dialog.alert.date_conflicts_with_date_of_other_contracts") };
                }

                // Check: start date to end date range conflicts with this contract
                if( startTime < contractStartTime && endTime > contractEndTime ) {
                    return { state: false, msg: i18n.t("pages.admin.contracts.dialog.alert.date_conflicts_with_date_of_other_contracts") };
                }
            }
        }

        // All fine if we reached this point
        return { state: true, msg: "" };
    }

    // Get all contracts of a certain user
    getContractsOfUser( userid ) {
        let contracts = [];

        if( null != this.state.data.contracts ) {
            for( let i = 0; i < this.state.data.contracts.length; i++ ) {
                if( this.state.data.contracts[i].userid === userid ) {
                    contracts.push( this.state.data.contracts[i] );
                }
            }
        }

        return contracts;
    }

    // Get a specific contract
    getContract( contractid ) {
        let contract = null;

        if( null != this.state.data.contracts ) {
            for( let i = 0; i < this.state.data.contracts.length; i++ ) {
                if( this.state.data.contracts[i].contractid === contractid ) {
                    contract = this.state.data.contracts[i];
                    break;
                }
            }
        }

        return contract;
    }

    /// Check if this is the users most recent contract in terms of start date
    isMostRecentContractOfUser( contract ) {
        let result = true;

        const startTime = createDateOnly( contract.date_start ).getTime();

        if( null != this.state.data.contracts ) {
            for( let i = 0; i < this.state.data.contracts.length; i++ ) {
                if( this.state.data.contracts[i].contractid !== contract.contractid && this.state.data.contracts[i].userid === contract.userid ) {
                    const contractStartTime = createDateOnly( this.state.data.contracts[i].date_start ).getTime();
                    if( startTime < contractStartTime ) {
                        result = false;
                        break;
                    }
                }
            }
        }

        return result;
    }

    // Check if given user has a contract with no end date
    hasUserContractWithNoEndDate( userid ) {
        let result = false;

        if( null != this.state.data.contracts ) {
            for( let i = 0; i < this.state.data.contracts.length; i++ ) {
                if( this.state.data.contracts[i].userid === userid ) {
                    if( this.state.data.contracts[i].date_end == null ) {
                        result = true;
                        break;
                    }
                }
            }
        }

        return result;
    }

    // Get all users without an active contract based on todays' date
    getUsersWithoutActiveContract() {
        let usersFound = [];

        if( null != this.state.data.users && null != this.state.data.contracts ) {
            let users = this.state.data.users;
            let contracts = this.state.data.contracts;

            const todayDateTime = createDateOnly().getTime();

            for( let i = 0; i < users.length; i++ ) {
                let userHasNoActiveContract = true;
                for( let j = 0; j < contracts.length; j++ ) {
                    if( users[i].userid === contracts[j].userid ) {
                        
                        // Contract found, check if it is active as of today
                        const contractStartDateTime = createDateOnly( contracts[j].date_start ).getTime();
                        const contractEndDateTime = createDateOnly( contracts[j].date_end ).getTime();
                        if( todayDateTime <= contractEndDateTime && todayDateTime >= contractStartDateTime ) {
                            userHasNoActiveContract = false;
                        }
                    }
                }

                if( true === userHasNoActiveContract ) {
                    usersFound.push( users[i] );
                }
            }
        }

        usersFound.sort(function(a, b) { return a.lastname > b.lastname ? 1 : -1; });
        return usersFound;
    }

    // Get blockers for start date of the given contract
    // Start before days block inclusively
    getBlockStartDate( contract, contractsOfUser ) {
        // contract object might be null if we are adding a new contract
        if( null == contractsOfUser ) {
            return { before: null, beforeInclusive: false, after: null, afterInclusive: false }
        }

        // List of contrats of user is already sorted, first element is the most recent contract according to date_start
        let before = null;
        let after = null;

        // Search for the given contract if it was given
        if( null != contract ) {
            for( let i = 0; i < contractsOfUser.length; i++ ) {
                if( contractsOfUser[i].contractid === contract.contractid ) {

                    // Found given contract, since we want to determine blockers for the start date we have to:
                    // before: Block anything which is equal or before the end date of the next contract
                    // after: Block anything which is equal or after the end date of the current contract
                    if( (i+1) < contractsOfUser.length ) {
                        // We have a next contract
                        before = contractsOfUser[i+1].date_end;
                    }
                    after = contractsOfUser[i].date_end;
                    break;
                }
            }
        } else {
            // No contract given since we are adding a new one, only take a look at the latest contract then
            if( contractsOfUser.length > 0 ) {
                before = contractsOfUser[0].date_end;
            }
        }

        return { before: before, beforeInclusive: true, after: after, afterInclusive: false }
    }

    // Get blockers for end date of the given contract
    // End after days block inclusively
    getBlockEndDate( contract, contractsOfUser ) {
        if( null == contract || null == contractsOfUser ) {
            return { before: null, beforeInclusive: false, after: null, afterInclusive: false }
        }

        // List of contrats of user is already sorted, first element is the most recent contract according to date_start
        let before = null;
        let after = null;
        for( let i = 0; i < contractsOfUser.length; i++ ) {

            // Search for the given contract
            if( contractsOfUser[i].contractid === contract.contractid ) {

                // Found given contract, since we want to determine blockers for the end date we have to:
                // before: Block anything which is equal or before the start date of the current contract
                // after: Block anything which is equal or after the start date of the previous contract
                before = contractsOfUser[i].date_start;
                if( (i-1) >= 0 && (i-1) < contractsOfUser.length ) {
                    // We have a next contract
                    after = contractsOfUser[i-1].date_start;
                }
                break;
            }
        }

        return { before: before, beforeInclusive: false, after: after, afterInclusive: true }
    }

    // Render page
    render() {
        const { classes } = this.props;

        // Only display data if it has already been loaded
        let contentTable = "";
        let usersWithoutActiveContract = "";

        // Make sure if we got the data already
        if( null != this.state.data.contracts && null != this.state.data.users ) {

            // Assemble table content
            const tableHeader = [
                    { key: 1, span: 1, align: "left", style: {}, text: i18n.t("pages.admin.contracts.table.header.date_start") },
                    { key: 3, span: 1, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.contracts.table.header.date_end") },
                    { key: 4, span: 1, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.contracts.table.header.work_hours_per_week") },
                    { key: 5, span: 1, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.contracts.table.header.work_days_per_week") },
                    { key: 6, span: 1, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.contracts.table.header.vacation_days_per_year") },
                    { key: 7, span: 1, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.contracts.table.header.vacation_days_at_start") },
                    { key: 8, span: 1, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.contracts.table.header.work_hours_at_start") },
                    { key: 9, span: 1, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.contracts.table.header.comment") },
                    { key: 10, span: 1, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.contracts.table.header.controls") }
            ];

            // Sort contracts of selected user by date
            let contractsOfUser = this.getContractsOfUser( this.state.selectedUserId );

            if( null == contractsOfUser ) {
                contractsOfUser = [];
            }
            contractsOfUser.sort(function(a, b) { return a.date_start < b.date_start ? 1 : -1; });

            contentTable = (
                <div>
                    <TableContainer style={{ minHeight: "556px" }} component={Paper}>
                        <StyledTable size="small" >
                            <StyledTableHead>
                                <StyledTableRow key={"contracts-header"}>
                                    {tableHeader.map((col) => (
                                        <StyledTableCell key={col.key} colSpan={col.span} align={col.align} style={col.style}>{col.text}</StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </StyledTableHead>
                            <TableBody>
                                {contractsOfUser.slice(this.state.tablePage * this.state.tableRowsPerPage, this.state.tablePage * this.state.tableRowsPerPage + this.state.tableRowsPerPage).map((contract) => {
                                    
                                    // Determine delete button tool tip for end date date picker
                                    let disableDeleteButton = !(this.isMostRecentContractOfUser( contract ));
                                    let tooltipDeleteButton = i18n.t("pages.admin.contracts.delete.end_date.tooltip.enabled");
                                    if( true === disableDeleteButton ) {
                                        tooltipDeleteButton = i18n.t("pages.admin.contracts.delete.end_date.tooltip.disabled");
                                    }

                                    // Determine blocker ranges for start and end dates of this contract entry
                                    // depending on the next and on the previous contract
                                    let blockStartDate = this.getBlockStartDate( contract, contractsOfUser );
                                    let blockEndDate = this.getBlockEndDate( contract, contractsOfUser );
                                    
                                    return (
                                        <StyledTableRow key={contract.contractid}>
                                            <StyledTableCell key="date_start" width="20%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CustomDatePicker 
                                                    dateValue={contract.date_start} 
                                                    verifyContent={(date) => this.verifyStartDateFieldContent(contract, date)}
                                                    allowedDate={contract.date_start}
                                                    onApply={(event, newDate) => this.handleStartDateFieldChange(event, contract, newDate)}
                                                    language={this.context.language}
                                                    title={i18n.t("pages.admin.contracts.modify.date_start.title")}
                                                    descText={i18n.t("pages.admin.contracts.modify.date_start.description")}
                                                    buttonLeft={i18n.t("pages.admin.contracts.modify.date_start.button_left")}
                                                    buttonRight={i18n.t("pages.admin.contracts.modify.date_start.button_right")}
                                                    blockBeforeDate={blockStartDate.before}
                                                    blockBeforeDateInclusive={blockStartDate.beforeInclusive}
                                                    blockAfterDate={blockStartDate.after}
                                                    blockAfterDateInclusive={blockStartDate.afterInclusive}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="date_end" width="20%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CustomDatePicker 
                                                    dateValue={contract.date_end} 
                                                    verifyContent={(date) => this.verifyEndDateFieldContent(contract, date)}
                                                    allowedDate={contract.date_end}
                                                    onApply={(event, newDate) => this.handleEndDateFieldChange(event, contract, newDate)}
                                                    language={this.context.language}
                                                    deleteIcon={<AllInclusiveIcon />}
                                                    disableDeleteButton={disableDeleteButton}
                                                    showDeleteButton={true}
                                                    buttonDeleteTooltip={tooltipDeleteButton}
                                                    title={i18n.t("pages.admin.contracts.modify.date_end.title")}
                                                    descText={i18n.t("pages.admin.contracts.modify.date_end.description")}
                                                    buttonLeft={i18n.t("pages.admin.contracts.modify.date_end.button_left")}
                                                    buttonRight={i18n.t("pages.admin.contracts.modify.date_end.button_right")}
                                                    blockBeforeDate={blockEndDate.before}
                                                    blockBeforeDateInclusive={blockEndDate.beforeInclusive}
                                                    blockAfterDate={blockEndDate.after}
                                                    blockAfterDateInclusive={blockEndDate.afterInclusive}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="working_hours" width="10%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CustomTextField
                                                    numbersOnly={true}
                                                    allowFractions={true}
                                                    minValue={0}
                                                    maxValue={168}
                                                    textFieldValue={contract.work_hours_per_week}
                                                    verifyContent={(content) => this.verifyHoursContent(content)}
                                                    onApply={(event, newValue) => this.handleWorkHoursPerWeekChange(event, contract, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="work_days_per_week" width="15%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <div align="center">
                                                    <SelectWorkWeek 
                                                        checkedDaysAsString={contract.work_days_per_week}
                                                        allDaysDisabled={false}
                                                        onChange={(index, checkedDays, checkedDaysAsString) => this.handleWorkDaysPerWeekChange(contract, checkedDaysAsString)}
                                                    />
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell key="vacation_days_per_year" width="10%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CustomTextField
                                                    numbersOnly={true}
                                                    minValue={0}
                                                    maxValue={365}
                                                    textFieldValue={contract.vacation_days_per_year}
                                                    onApply={(event, newValue) => this.handleVacationDaysPerYearChange(event, contract, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="vacation_days_at_start" width="10%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CustomTextField
                                                    numbersOnly={true}
                                                    minValue={-9999}
                                                    maxValue={9999}
                                                    allowNegativeNumbers={true}
                                                    textFieldValue={contract.vacation_days_at_start}
                                                    onApply={(event, newValue) => this.handleVacationDaysAtStartChange(event, contract, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="work_hours_at_start" width="10%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CustomTextField
                                                    numbersOnly={true}
                                                    minValue={-9999}
                                                    maxValue={9999}
                                                    allowNegativeNumbers={true}
                                                    allowFractions={true}
                                                    textFieldValue={contract.work_hours_at_start}
                                                    verifyContent={(content) => this.verifyHoursContent(content)}
                                                    onApply={(event, newValue) => this.handleWorkHoursAtStartChange(event, contract, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.tableCellTextField} key="comment" width="30%">
                                                <CustomMultilineTextField 
                                                    textFieldValue={contract.comment} 
                                                    title={i18n.t("pages.admin.contracts.modify.comment.title")}
                                                    descText={i18n.t("pages.admin.contracts.modify.comment.description")}
                                                    buttonLeft={i18n.t("pages.admin.contracts.modify.comment.button_left")}
                                                    buttonRight={i18n.t("pages.admin.contracts.modify.comment.button_right")}
                                                    onApply={(event, newValue) => this.handleCommentFieldChange(event, contract, newValue)}
                                                    verifyContent={(content) => this.verifyCommentFieldContent(contract.contractid, content)}
                                                    rows={8}
                                                    rowsMax={8}
                                                    allowEmpty={true}
                                                    required={false}
                                                    tooltip={contract.comment}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="delete" align="center" padding="checkbox" width="5%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <Tooltip title={i18n.t("pages.admin.contracts.delete.button.tooltip")}>
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.clearButton}
                                                            disabled={false}
                                                            onClick={(event) => this.handleTableRowDeleteClick(event, contract.contractid)}
                                                            style={{ height: "30px", width: "45px" }}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                    <Grid container direction="row" justify="flex-end" className={classes.tablePaginationGrid} spacing={1} wrap='nowrap'>
                        <Grid item>
                            <TablePagination
                                className={classes.tablePagination}
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={contractsOfUser.length}
                                rowsPerPage={this.state.tableRowsPerPage}
                                labelRowsPerPage={i18n.t("table.pagination.rows_per_page")}
                                page={this.state.tablePage}
                                onChangePage={this.handleChangeTablePage}
                                onChangeRowsPerPage={this.handleChangeTableRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                    <ModalDialog 
                        open={this.state.addDialog.open}
                        title={i18n.t("pages.admin.contracts.add.dialog.title")}
                        descText={i18n.t("pages.admin.contracts.add.dialog.description")}
                        buttonLeft={i18n.t("pages.admin.contracts.add.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.contracts.add.dialog.button_right")}
                        handleClickClose={() => this.handleAddDialog_Cancel()}
                        handleClickLeft={(content) => this.handleAddDialog_Ok(content)}
                        handleClickRight={() => this.handleAddDialog_Cancel()}
                    >
                        <ModalDialogDatePicker 
                            language={this.context.language}
                            label={i18n.t("pages.admin.contracts.add.dialog.date_start")}
                            verifyContent={(content) => this.verifyStartDateFieldContentOfNewContract(this.state.selectedUserId, content.date)}
                            blockBeforeDate={this.state.addDialog.blockStartDate.before}
                            blockBeforeDateInclusive={this.state.addDialog.blockStartDate.beforeInclusive}
                            blockAfterDate={this.state.addDialog.blockStartDate.after}
                            blockAfterDateInclusive={this.state.addDialog.blockStartDate.afterInclusive}
                        />
                    </ModalDialog>
                </div>
            )

            // Retrieve all users which are not having an active contract
            const allUsersWithoutActiveContract = this.getUsersWithoutActiveContract();

            let usersList = i18n.t("pages.admin.teams.unassigned_users.none");

            if( 0 < allUsersWithoutActiveContract.length ) {
                usersList = (
                    <div>
                        {allUsersWithoutActiveContract.map(function(user) {
                            let fullUserEntry = user.lastname + ", " + user.firstname;
                            if( user.userTypeName != null && user.userTypeName !== "" ) {
                                fullUserEntry = "(" + user.userTypeName + ") " + user.lastname + ", " + user.firstname;
                            }

                            if( user.account_enabled ) {
                                return (
                                    <StyledChipEnabled key={"chip-enabled-"+user.userid} label={fullUserEntry} />
                                )
                            } else {
                                return (
                                    <Tooltip key={"chip-tooltip-"+user.userid} title={i18n.t("tooltips.user_disabled")}>
                                        <StyledChipDisabled key={"chip-disabled-"+user.userid} label={fullUserEntry} />
                                    </Tooltip>
                                )
                            }
                        })}
                    </div>
                )
            }

            usersWithoutActiveContract = (
                <div>
                    <PageSubtitle title={i18n.t("pages.admin.contracts.noactivecontract_users.headline")} />
                    {usersList}
                </div>
            )
        }

        // Check if we want to enable or disable the add button depending on if the currently displayed
        // user already has a most recent contract which runs indefinitely
        let addButtonTooltip = i18n.t("pages.admin.contracts.add.button.tooltip.enabled");
        let disableAddButton = false;
        if( true === this.hasUserContractWithNoEndDate( this.state.selectedUserId ) || this.state.selectedUserId === "" ) {
            addButtonTooltip = i18n.t("pages.admin.contracts.add.button.tooltip.disabled");
            disableAddButton = true;
        }

        // If no selectable items are yet available, do not supply the selected user id to avoid a warning
        let selectedUserId = "";
        if( null != this.state.tableData.userSelection && this.state.tableData.userSelection.length > 0 ) {
            selectedUserId = this.state.selectedUserId;
        }

        return (
            <React.Fragment>
                <PageTitle title={i18n.t("pages.admin.contracts.title")} />
                <p>{i18n.t("pages.admin.contracts.desc")}</p>
                <SelectControls
                    selectedEntry={selectedUserId}
                    label={i18n.t("pages.admin.contracts.search.label")}
                    helperText={i18n.t("pages.admin.contracts.search.subtext")}
                    addTooltip={addButtonTooltip}
                    refreshTooltip={i18n.t("buttons.refresh.tooltip")}
                    items={this.state.tableData.userSelection}
                    onAdd={this.handleAddButtonClick}
                    onRefresh={this.handleRefreshButtonClick}
                    onChange={this.handleChangeSelectField}
                    disableAddButton={disableAddButton}
                    minWidth={"40ch"}
                />
                <div>{contentTable}</div>
                <div>{usersWithoutActiveContract}</div>
            </React.Fragment>
        )
    }
};

export default withStyles(useStyles)(AdminContracts);