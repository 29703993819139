import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ChartProjectOverview from '../components/ChartProjectOverview';
import ChartProjectUserDetail from './ChartProjectUserDetail';
import TableProjectUserDetail from './TableProjectUserDetail';
import Grid from '@material-ui/core/Grid';

const useStyles = theme => ({});

/**
 * Report Project - User Detail
 */
class ReportProjectUserDetail extends Component {
    state = {}

    constructor(props) {
        super(props);

        // References to call into child
        this.tableChildRef = React.createRef();
    }

    /**
     * EXPORTS
     */
    
    /**
	 * RENDER FUNCTIONS
	 */

    render() {
        // INPUTS: language, dateMin, dateMax, projectReport, userid
        let projectChart = "";
        let projectTable = "";

        if( null != this.props.projectReport ) {
            if( this.props.userid && this.props.userid !== "" ) {
                projectChart = (
                    <ChartProjectUserDetail
                        language={this.props.language}
                        dateMin={this.props.dateMin}
                        dateMax={this.props.dateMax}
                        projectReport={this.props.projectReport}
                        userid={this.props.userid}
                    />
                )
            } else {
                projectChart = (
                    <ChartProjectOverview
                        language={this.props.language}
                        dateMin={this.props.dateMin}
                        dateMax={this.props.dateMax}
                        projectReport={this.props.projectReport}
                    />
                )
            }
            projectTable = (
                <TableProjectUserDetail
                    ref={this.tableChildRef}
                    language={this.props.language}
                    dateMin={this.props.dateMin}
                    dateMax={this.props.dateMax}
                    projectReport={this.props.projectReport}
                    userid={this.props.userid}
                />
            )
        }

        return (
            <Grid item container justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={7}>
                    {projectChart}
                </Grid>
                <Grid item xs={5}>
                    {projectTable}
                </Grid>
            </Grid>
        );
    }
};

export default withStyles(useStyles)(ReportProjectUserDetail);