import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { StyledTableCell, StyledTableRow, StyledTableHead } from './StyledTable.js'
import Paper from '@material-ui/core/Paper';
import CalendarHelper from '../classes/CalendarHelper.js';
import i18n from 'i18next';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    tableContainer: {
        width: "100%",
        marginTop: theme.spacing(1),
        padding: theme.spacing(1)
    },
    table: {
        tableLayout: "fixed"
    },
    cellTitle: {
        fontWeight: 500,
        fontSize: "12px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    cellValue: {
        fontWeight: 500,
        fontSize: "12px",
        width: "100px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    }
})

/**
 * ProjectAccountingSummary to be displayed with the accounting calendar
 */
class ProjectAccountingSummary extends Component {

    // Main render function
    render() {
        /**
         * Input data: 
         * this.props.year = current year
         * this.props.month = current month
         * this.props.data = { hoursAccounted }
         */

        // Convert month index to human readable name
        const monthName = CalendarHelper.getMonthName( this.props.language, "short", this.props.month );

        // Create the table data array
        let tableData = [];
        if( null != this.props.data ) {
            tableData.push( { title: i18n.t("pages.main.accounting_project.summary.accounted_hours_month", { month: monthName, year: this.props.year }), value: this.props.data.hoursAccounted } );
        }

        return (
            <TableContainer key={"projectsummary-container"} component={Paper} className={this.props.classes.tableContainer}>
                <Table key={"projectsummary-table"} className={this.props.classes.table}>
                    <StyledTableHead key={"projectsummary-header"}>
                        <StyledTableRow key={"projectsummary-header-row-1"}>
                            <StyledTableCell key={"projectsummary-header-row-1-col-1"} colSpan={tableData.length} align="center">{i18n.t("pages.main.accounting_project.summary.headline", { month: monthName, year: this.props.year })}</StyledTableCell>
                        </StyledTableRow>
                    </StyledTableHead>
                    <TableBody key={"projectsummary-body"}>
                        <StyledTableRow key={"projectsummary-header-row-1"}>
                            {tableData.map((row, index) => {
                                return (
                                    <StyledTableCell key={"projectsummary-body-row-1-col-"+index} colSpan={1} align="left" className={this.props.classes.cellTitle}>
                                        {row.title}
                                    </StyledTableCell>
                                )
                            })}
                        </StyledTableRow>
                        <StyledTableRow key={"projectsummary-body-row-2"} >
                            {tableData.map((row, index) => {
                                return (
                                    <StyledTableCell key={"projectsummary-body-row-2-col-"+index} align="left" className={this.props.classes.cellValue}>
                                        {row.value}
                                    </StyledTableCell>
                                )
                            })}
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
};

export default withStyles(useStyles)(ProjectAccountingSummary);