import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { jsonEqual } from '../helpers.js';
import ChipAutoComplete from './ChipAutoComplete'

const useStyles = theme => ({
    content: {
        flexGrow: 1,
        width: '100%',
        height: '100%',
    }
});

/**
 * Custom chip input
 */
class CustomChipInput extends Component {
    state = {
        initialSelectedOptions: [],
        currentSelectedOptions: [],
        currentUnselectedOptions: [],
        allOptions: [],
        changedDataHasBeenApplied: false
    }

    constructor(props) {
        super(props);
        
        this.handleListDataChanged = this.handleListDataChanged.bind( this );
        this.getDataChanged = this.getDataChanged.bind( this );
        this.onEndAdornmentApply = this.onEndAdornmentApply.bind( this );
        this.onEndAdornmentReset = this.onEndAdornmentReset.bind( this );
    }

    // To check if the data has changed compared to the initial data
    getDataChanged() {
        return !( jsonEqual( this.state.initialSelectedOptions, this.state.currentSelectedOptions ) || true === this.state.changedDataHasBeenApplied );
    }

    // Called when the lists changed
    handleListDataChanged( dataUnselected, dataSelected ) {

        // Sort given unselected list, we do not sort the selected list as this might look weird to the user with items suddenly changing place
        if( null != this.props.sortOptions ) {
            dataUnselected = this.props.sortOptions( dataUnselected );
        }

        this.setState({ currentSelectedOptions: dataSelected, currentUnselectedOptions: dataUnselected, changedDataHasBeenApplied: false });
    }

    // Handle Apply button click on custom end adornments
    onEndAdornmentApply() {
        // Notify parent
        if( null != this.props.onApply ) {
            this.props.onApply( { unselectedOptions: this.state.currentUnselectedOptions, selectedOptions: this.state.currentSelectedOptions } );
        }
        this.setState({ changedDataHasBeenApplied: true });
    }

    // Handle Reset button click on custom end adornments
    onEndAdornmentReset() {
        // Reset back to initially selected options
        this.updateInitialData();
    }

    // Set all initial data
    updateInitialData() {
        let allOptions = [];
        let initialSelectedOptions = [];

        // Gather full list of options
        if( null != this.props.getAllOptions ) {
            allOptions = this.props.getAllOptions();
        }

        // Gather list selected options
        if( null != this.props.getSelectedOptions ) {
            initialSelectedOptions = this.props.getSelectedOptions();
        }

        // From both lists, build a list of all non-selected options
        let initialUnselectedOptions = [];
        if( null != allOptions && null != initialSelectedOptions ) {
            for( let i = 0; i < allOptions.length; i++ ) {

                let optionSelected = false;
                for( let j = 0; j < initialSelectedOptions.length; j++ ) {
                    if( true === jsonEqual( initialSelectedOptions[j], allOptions[i] ) ) {
                        optionSelected = true;
                        break;
                    }
                }

                if( false === optionSelected ) {
                    initialUnselectedOptions.push( allOptions[i] );
                }
            }
        }

        // Set states
        this.setState({
            initialSelectedOptions: initialSelectedOptions, 
            currentSelectedOptions: initialSelectedOptions,
            currentUnselectedOptions: initialUnselectedOptions,
            allOptions: allOptions,
            changedDataHasBeenApplied: false
        });
    }

    // Called by react when this component has been mounted
    componentDidMount() {
        this.updateInitialData();
    }

    // Render function
    render() {
        // Extract locally used props and leave others
        // None used here, forward all
        var { classes, getAllOptions, getSelectedOptions, onApply, sortOptions, ...otherProps } = this.props;

        return (
            <div className={this.props.classes.content}>
                <ChipAutoComplete
                    dataSelected={this.state.currentSelectedOptions}
                    dataAll={this.state.allOptions}
                    dataChanged={this.handleListDataChanged}
                    getDataChanged={this.getDataChanged}
                    enableCustomEndAdornments={true}
                    onEndAdornmentApply={this.onEndAdornmentApply}
                    onEndAdornmentReset={this.onEndAdornmentReset}
                    { ...otherProps }
                />
            </div>
        );
    }
};

export default withStyles(useStyles)(CustomChipInput);