import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 * Also acts as provider for the UserContext
 */
class UserService {

    /**
     * Get users
     * @param {*} userSearch { email, userid }
     */
    async getUsers( userSearch ) {
        var request = {
            query: `
            query getUsers($userSearch: UserSearch!) {
                getUsers(userSearch: $userSearch) {
                    userid
                    email
                    typeid
                    firstname
                    lastname
                    account_enabled
                    is_admin
                    language
                    last_login
                    password_change_on_next_login
                    has_contracts
                    has_absences
                    has_accountings
                    has_roles
                    has_teams
                    has_projects
                    has_tasks
                    default_work_start_time
                }
            }
            `,
            variables: {
                userSearch: userSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Create a new user
     * @param {*} userInput { email, typeid, password, firstname, lastname, account_enabled, is_admin, language }
     */
    async createUser( userInput ) {
        var request = {
            query: `
            mutation createUser($userInput: UserInput) {
                createUser(userInput: $userInput) {
                    userid
                    email
                    typeid
                    firstname
                    lastname
                    account_enabled
                    is_admin
                    language
                    last_login
                    password_change_on_next_login
                    has_contracts
                    has_absences
                    has_accountings
                    has_roles
                    has_teams
                    has_projects
                    has_tasks
                    default_work_start_time
                }
            }
            `,
            variables: {
                userInput: userInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update an user
     * @param {*} userSearch { email, userid }
     * @param {*} userUpdate { email, typeid, password, firstname, lastname, account_enabled, is_admin, language, password_change_on_next_login}
     */
    async updateUser( userSearch, userUpdate ) {
        var request = {
            query: `
            mutation updateUser($userSearch: UserSearch!, $userUpdate: UserUpdate!) {
                updateUser(userSearch: $userSearch, userUpdate: $userUpdate) {
                    userid
                    email
                    typeid
                    firstname
                    lastname
                    account_enabled
                    is_admin
                    language
                    last_login
                    password_change_on_next_login
                    has_contracts
                    has_absences
                    has_accountings
                    has_roles
                    has_teams
                    has_projects
                    has_tasks
                    default_work_start_time
                }
            }
            `,
            variables: {
                userSearch: userSearch,
                userUpdate: userUpdate
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete an user
     * @param {*} userSearch { email, userid }
     */
    async deleteUser( userSearch ) {
        var request = {
            query: `
            mutation deleteUser($userSearch: UserSearch!) {
                deleteUser(userSearch: $userSearch) {
                    result
                }
            }
            `,
            variables: {
                userSearch: userSearch
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new UserService();
