import React, { Component } from 'react';
import RoleService from '../../classes/RoleService'
import UsersToRolesService from '../../classes/UsersToRolesService'
import UserService from '../../classes/UserService'
import UserTypeService from '../../classes/UserTypeService'
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { StyledTableCell, StyledTableRow, StyledTableHead, StyledTable, StyledTableSortLabel, stableSort, getComparator } from '../../components/StyledTable.js'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../components/PageTitle';
import ModalDialog from '../../components/ModalDialog';
import ModalDialogTextField from '../../components/ModalDialogTextField';
import ModalDialogChipInput from '../../components/ModalDialogChipInput';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import CustomTextField from '../../components/CustomTextField'
import CheckboxTooltip from '../../components/CheckboxTooltip'
import SearchControls from '../../components/SearchControls'
import PageSubtitle from '../../components/PageSubtitle'
import PageDescription from '../../components/PageDescription'
import Paper from '@material-ui/core/Paper';
import { config } from '../../config';
import i18n from 'i18next';

const useStyles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    mainGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    tableCellRoleName: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1.0),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
    },
    tablePaginationGrid: {
        height: '60px',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tablePagination: {
        '& .MuiTablePagination-toolbar': {
            height: '60px',
            minHeight: '60px',
        },
    },
    chipInput: {
        width: 600,
        marginTop: theme.spacing(3.5),
    }
});

/**
 * Admin Roles
 */
class AdminRoles extends Component {
    // Set in constructor
    state = {}

    // Keys to identify the clicked checkbox column
    keys = {
        admin_users: 1,
        admin_roles: 2,
        admin_teams: 3,
        admin_projects: 4,
        admin_tasks: 5,
        admin_specials: 6,
        admin_holidays: 7,
        admin_absences: 8,
        admin_accountings: 9,
        admin_contracts: 10
    }

    /**
     * Inputs Props
     * @param {*} props 
     *  admin (array)
     *  userProps (array)
     *  dataLoadChanged (function)
     *  alertChanged (function)
     */
    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleTableRowCheckboxChange = this.handleTableRowCheckboxChange.bind( this );
        this.handleTableRowModifyClick = this.handleTableRowModifyClick.bind( this );
        this.handleTableRowDeleteClick = this.handleTableRowDeleteClick.bind( this );
        this.handleChangeTablePage = this.handleChangeTablePage.bind( this );
        this.handleChangeTableRowsPerPage = this.handleChangeTableRowsPerPage.bind( this );
        this.handleChangeSearchField = this.handleChangeSearchField.bind( this );
        this.handleAddButtonClick = this.handleAddButtonClick.bind( this );
        this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind( this );
        this.handleAddDialog_Ok = this.handleAddDialog_Ok.bind( this );
        this.handleAddDialog_Cancel = this.handleAddDialog_Cancel.bind( this );
        this.verifyAddDialog_Content = this.verifyAddDialog_Content.bind( this );
        this.handleTableRowDeleteDialog_Ok = this.handleTableRowDeleteDialog_Ok.bind( this );
        this.handleTableRowDeleteDialog_Cancel = this.handleTableRowDeleteDialog_Cancel.bind( this );
        this.handleTableRowModifyDialog_Ok = this.handleTableRowModifyDialog_Ok.bind( this );
        this.handleTableRowModifyDialog_Cancel = this.handleTableRowModifyDialog_Cancel.bind( this );
        this.getUsersForRole = this.getUsersForRole.bind( this );
        this.getUserLabel = this.getUserLabel.bind( this );
        this.getUserEnabled = this.getUserEnabled.bind( this );
        this.sortUsersForRole = this.sortUsersForRole.bind( this );
        this.getDeleteButtonDataForRole = this.getDeleteButtonDataForRole.bind( this );
        this.handleRoleNameFieldChange = this.handleRoleNameFieldChange.bind( this );
        this.verifyRoleNameFieldContent = this.verifyRoleNameFieldContent.bind( this );
        this.handleRequestSort = this.handleRequestSort.bind( this );
        this.sortTableData = this.sortTableData.bind( this );

        // Set initial state
        this.state = this.getInitialState();
    }

    /**
     * STATE HANDLING
     */

    // Get the initial state variables of this component
    getInitialState() {
        return {
            data: { // Raw loaded data from the backend
                roles: null,
                users: null,
                userTypes: null
            },
            tableData: { // Additional information gathered from loaded raw data to display on the page
                usersForRole: [], // The names of the users of that role
                deleteButtonsForRole: [] // If the delete button is enabled and which tooltip is shown for that role
            },
            searchFieldEntry: "",
            tablePage: 0,
            tableRowsPerPage: 10,
            order: "asc",
            orderBy: 0,
            addDialog: {
                open: false
            },
            deleteDialog: {
                open: false,
                roleid: null,
                rolename: null
            },
            modifyDialog: {
                open: false,
                roleid: null,
                rolename: null
            },
        }
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState() {
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
        this.setState( this.getInitialState() );
    }

    /**
     * LINK TO PARENT COMPONENT FUNCTIONS
     */

    // Propagate information to parent component
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Propagate information to parent component
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Propagate information to parent component
    handleActionInProgressChanged( actionInProgress ) {
        if( this.props.actionInProgressChanged ) {
            this.props.actionInProgressChanged( actionInProgress );
        }
    }

    /**
     * LOAD AND PROCESS BACKEND DATA
     */

    // Called when a refresh of the table data is needed due to edits in the table
    async refreshTableData() {
        await this.loadDataFromBackend( true );
    }

    // Gather all data from the backend needed on this page and process them properly
    async loadDataFromBackend( manualRefresh ) {
        // Alert message to show
        let alert = {
            severity: "info",
            message: ""
        }

        let roleData = null;
        let usersToRolesData = null;
        let userData = null;

        // Gather role data
        {
            const { statusCode, statusText, resData } = await RoleService.getRoles( {} );
            if( 200 === statusCode && null != resData ) {
                roleData = resData.getRoles;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Get user-to-role data
        {
            const { statusCode, statusText, resData } = await UsersToRolesService.getUsersToRoles( {} );
            if( 200 === statusCode && null != resData ) {
                usersToRolesData = resData.getUsersToRoles;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather user data
        {
            const { statusCode, statusText, resData } = await UserService.getUsers( {} );
            if( 200 === statusCode && null != resData ) {
                userData = resData.getUsers;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        let userTypeData = null;

        // Gather user type data
        {
            const { statusCode, statusText, resData } = await UserTypeService.getUserTypes( {} );
            if( 200 === statusCode && null != resData ) {
                userTypeData = resData.getUserTypes;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Now process the data
        await this.processData( userData, userTypeData, roleData, usersToRolesData );

        // All fine, clear the error message (only if not a manual refresh)
        if( false === manualRefresh ) {
            this.handleAlertChanged( alert );
        }
        this.handleDataLoadedChanged( true );
    }

    // Called to make additional processing of data, for example gathering info from that backend data and storing it for later use
    async processData( userData, userTypeData, roleData, usersToRolesData ) {

        // Abort if no proper data given
        if( null == userData || null == userTypeData || null == roleData || null == usersToRolesData ) {
            return;
        }

        // Add user type name to all user data entries
        for( let i = 0; i < userData.length; i++ ) {
            let userTypeName = "";
            for( let j = 0; j < userTypeData.length; j++ ) {
                if( userTypeData[j].typeid === userData[i].typeid ) {
                    userTypeName = userTypeData[j].name;
                    break;
                }
            }
            userData[i].userTypeName = userTypeName;
        }

        // Add the information to data.roles if there is currently an user assigned to this role or not
        // for enabling or disabling the delete button, doing this now speeds up table page changes later on
        let usersArray = [];
        let deleteButtonsArray = [];

        for( let i = 0; i < roleData.length; i++ ) {
            let usersFound = [];

            for (let j = 0; j < usersToRolesData.length; j++ ) {
                if( usersToRolesData[j].roleid === roleData[i].roleid ) {
                    // User at the specified role found
                    // Discover the real name of the user from the user data we retrieved from the backend
                    let user = null;

                    for( let k = 0; k < userData.length; k++ ) {
                        if( userData[k].userid === usersToRolesData[j].userid ) {
                            user = userData[k];
                            break;
                        }
                    }

                    usersFound.push( user );
                }
            }

            // Sort array by lastname
            usersFound.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.userTypeName > b.userTypeName ) return 1;
                if( a.userTypeName < b.userTypeName ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });

            // Add the processed info
            let usersElement = {
                roleid: roleData[i].roleid,
                users: usersFound
            }

            let deleteButtonElement = {
                roleid: roleData[i].roleid,
                enabled: false,
                tooltip: i18n.t("pages.admin.roles.delete.button.tooltip.disabled")
            }

            if( usersFound.length === 0 ) {
                deleteButtonElement.enabled = true;
                deleteButtonElement.tooltip = i18n.t("pages.admin.roles.delete.button.tooltip.enabled");
            }

            // Add info to the final arrays
            usersArray[i] = usersElement;
            deleteButtonsArray[i] = deleteButtonElement;
        }

        // Store the data
        this.setState({ 
            data: { users: userData, userTypes: userTypeData, roles: roleData }, 
            tableData: { usersForRole: usersArray, deleteButtonsForRole: deleteButtonsArray } 
        });
    }

    /**
     * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
     */

    // Called by react when this component has been mounted
    async componentDidMount() {
        await this.loadDataFromBackend();
    }

    // Called by react before this component unmounts
    componentWillUnmount() {
        this.resetState();
    }

    /**
     * ALERT DIALOG <ADD>
     */

    // Called to open the add dialog
    openAddDialog() {
        let addDialog = {
            open: true
        }
        this.setState({ addDialog: addDialog });
    }

    // Called when the user clicked "Ok" on the add role dialog
    // content: { name }
    async handleAddDialog_Ok( content ) {
        this.closeAddDialog();

        // Read from content
        let name = null;
        if( null != content ) {
            if( null != content.value ) {
                name = content.value;
            }
        }

        // Add the role
        if( null != name ) {
            let roleValues = {
                name: name,
                admin_users: false, 
                admin_teams: false, 
                admin_projects: false, 
                admin_roles: false, 
                admin_specials: false, 
                admin_holidays: false, 
                admin_absences: false, 
                admin_tasks: false, 
                admin_accountings: false, 
                admin_contracts: false
            }

            const { statusCode, statusText, resData } = await RoleService.createRole( roleValues );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.roles.add.alert.success", { name: name });
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the add role dialog
    handleAddDialog_Cancel() {
        this.closeAddDialog();
    }

    // Called by the RoleAdd child component to verify its content
    verifyAddDialog_Content( content ) {

        let result = {
            state: false,
            msg: i18n.t("pages.admin.roles.add.dialog.alert.role_name_empty")
        }
        
        if( null != content ) {
            if( null != content.value ) {
                if( content.value !== "" ) {
                    const { exists } = this.doesRoleExist( content.value );
                    if( true === exists ) {
                        result = {
                            state: false,
                            msg: i18n.t("pages.admin.roles.add.dialog.alert.role_does_already_exist")
                        }
                    } else {
                        result = {
                            state: true,
                            msg: ""
                        }
                    }
                }
            }
        }

        return result;
    }

    // Close the modify alert dialog
    closeAddDialog() {
        let addDialog = {
            open: false
        }
        this.setState({ addDialog: addDialog });
    }

    /**
     * ALERT DIALOG <MODIFY>
     */

    // Called to open the modify dialog
    openTableRowModifyDialog( roleid, rolename ) {
        let modifyDialog = {
            open: true,
            roleid: roleid,
            rolename: rolename
        }
        this.setState({ modifyDialog: modifyDialog });
    }

    // Called when the user clicked "Ok" on the confirm row modify dialog
    async handleTableRowModifyDialog_Ok( roleid, content ) {
        this.closeTableRowModifyDialog();

        // Check data validity
        if( content == null ) {
            return;
        }
        if( content.selectedOptions == null ) {
            return;
        }
        if( content.unselectedOptions == null ) {
            return;
        }

        // Content will include arrays in no specific order
        let usersToRoleCmd = [];

        // Get currently assigned users for role
        const users = this.getUsersForRole( roleid );

        // Check if users have been added to the role
        for( let i = 0; i < content.selectedOptions.length; i++ ) {
            var userAlreadyAssignedToRole = false;
            for( let j = 0; j < users.length; j++ ) {
                if( users[j].userid === content.selectedOptions[i].userid ) {
                    userAlreadyAssignedToRole = true;
                    break;
                }
            }

            if( false === userAlreadyAssignedToRole ) {
                // User has been added to the role
                usersToRoleCmd.push( { cmd: "add", userid: content.selectedOptions[i].userid } );
            }
        }

        // Check if users have been removed from the role
        for( let i = 0; i < content.unselectedOptions.length; i++ ) {
            var removeUserFromRole = false;
            for( let j = 0; j < users.length; j++ ) {
                if( users[j].userid === content.unselectedOptions[i].userid ) {
                    removeUserFromRole = true;
                    break;
                }
            }

            if( true === removeUserFromRole ) {
                // User has been removed from the role
                usersToRoleCmd.push( { cmd: "delete", userid: content.unselectedOptions[i].userid } );
            }
        }

        // Perform commands on the API
        let combinedResult = {
            statusCode: 200,
            statusText: ""
        }

        if( 0 < usersToRoleCmd.length ) {
        
            for( let i = 0; i < usersToRoleCmd.length; i++ ) {
                let result = {}

                if( "add" === usersToRoleCmd[i].cmd ) {
                    const { statusCode, statusText, resData } = await UsersToRolesService.addUserToRole( { roleid: roleid, userid: usersToRoleCmd[i].userid } );
                    result = {
                        statusCode: statusCode,
                        statusText: statusText,
                        resData: resData
                    }
                } else {
                    const { statusCode, statusText, resData } = await UsersToRolesService.deleteUserToRole( { roleid: roleid, userid: usersToRoleCmd[i].userid } );
                    result = {
                        statusCode: statusCode,
                        statusText: statusText,
                        resData: resData
                    }
                }

                if( 200 !== result.statusCode ) {
                    combinedResult.statusCode = result.statusCode;
                    combinedResult.statusText = result.statusText;
                }
            }

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === combinedResult.statusCode ) {
                alert.severity = "success";
                alert.message = i18n.t( "pages.admin.roles.modify.alert.modify_users_success" );
            } else {
                alert.severity = "error";
                alert.message = `${combinedResult.statusText}: ${combinedResult.statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the confirm row modify dialog
    handleTableRowModifyDialog_Cancel() {
        this.closeTableRowModifyDialog();
    }

    // Close the modify alert dialog
    closeTableRowModifyDialog() {
        let modifyDialog = {
            open: false,
            roleid: this.state.modifyDialog.roleid,
            rolename: this.state.modifyDialog.rolename
        }
        this.setState({ modifyDialog: modifyDialog });
    }

    /**
     * ALERT DIALOG <DELETE>
     */

    // Called to open the delete dialog
    openTableRowDeleteDialog( roleid, rolename ) {
        let deleteDialog = {
            open: true,
            roleid: roleid,
            rolename: rolename
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    // Called when the user clicked "Ok" on the confirm row delete dialog
    async handleTableRowDeleteDialog_Ok( roleid ) {
        this.closeTableRowDeleteDialog();

        // Delete the role
        if( null != roleid ) {
            const { statusCode, statusText, resData } = await RoleService.deleteRole( { roleid: roleid } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                if( true === resData.deleteRole.result ) {
                    alert.severity = "success";
                    alert.message = i18n.t( "pages.admin.roles.delete.alert.success" );
                } else {
                    alert.severity = "error";
                    alert.message = `${statusText}: ${statusCode}`;
                }
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the confirm row delete dialog
    async handleTableRowDeleteDialog_Cancel() {
        this.closeTableRowDeleteDialog();
    }

    // Close the delete alert dialog
    closeTableRowDeleteDialog() {
        let deleteDialog = {
            open: false,
            roleid: this.state.deleteDialog.roleid,
            rolename: this.state.deleteDialog.rolename
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    /**
     * CALLBACK FUNCTIONS FOR SEARCH FIELD
     */

    // Called when text is entered in the search field
    handleChangeSearchField(value) {
        this.setState({ searchFieldEntry: value });
    }

    /**
     * CALLBACK FUNCTIONS OF TABLE ELEMENTS
     */

    // Called when a checkbox of an existing role is clicked within the table
    async handleTableRowCheckboxChange(event, roleid, keyid) {

        // Update the role
        if( null != roleid && null != keyid ) {
            let roleUpdate;
            if( keyid === this.keys.admin_users ) {
                roleUpdate = { admin_users: event.target.checked }
            } else if( keyid === this.keys.admin_roles ) {
                roleUpdate = { admin_roles: event.target.checked }
            } else if( keyid === this.keys.admin_teams ) {
                roleUpdate = { admin_teams: event.target.checked }
            } else if( keyid === this.keys.admin_projects ) {
                roleUpdate = { admin_projects: event.target.checked }
            } else if( keyid === this.keys.admin_tasks ) {
                roleUpdate = { admin_tasks: event.target.checked }
            } else if( keyid === this.keys.admin_specials ) {
                roleUpdate = { admin_specials: event.target.checked }
            } else if( keyid === this.keys.admin_holidays ) {
                roleUpdate = { admin_holidays: event.target.checked }
            } else if( keyid === this.keys.admin_absences ) {
                roleUpdate = { admin_absences: event.target.checked }
            } else if( keyid === this.keys.admin_accountings ) {
                roleUpdate = { admin_accountings: event.target.checked }
            } else if( keyid === this.keys.admin_contracts ) {
                roleUpdate = { admin_contracts: event.target.checked }
            }

            const { statusCode, statusText, resData } = await RoleService.updateRole( { roleid: roleid }, roleUpdate );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t( "pages.admin.roles.modify.alert.update_flag_success" );
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when a delete button of an existing role is clicked within the table
    handleTableRowDeleteClick(event, roleid, rolename) {
        event.stopPropagation();
        this.openTableRowDeleteDialog( roleid, rolename );
    }

    // Called when a modify button of an existing role is clicked within the table
    handleTableRowModifyClick(event, roleid, rolename) {
        event.stopPropagation();
        this.openTableRowModifyDialog( roleid, rolename );
    }

    /**
     * TABLE PAGINATION EVENT HANDLERS
     */

    // Called if page changes on the table display occur
    handleChangeTablePage = (event, newPage) => {
        this.setState({tablePage: newPage});
    };
    
    // Called if the user selects a different amount of shown rows
    handleChangeTableRowsPerPage = (event) => {
        this.setState({tablePage: 0, tableRowsPerPage: +event.target.value});
    };

    /**
     * TOP ROW BUTTONS
     */

    // Called if the add button is clicked
    async handleAddButtonClick() {
        this.openAddDialog();
    }

    // Called if the refresh button is clicked
    async handleRefreshButtonClick() {
        this.resetState();
        await this.refreshTableData();
    }

    /**
     * TEXT FIELDS FOR ROLE NAME
     */

    // Handle when a role name text field is changed
    async handleRoleNameFieldChange(event, roleid, newValue) {
        event.stopPropagation();

        // Update the role
        if( null != roleid ) {
            const { statusCode, statusText, resData } = await RoleService.updateRole( { roleid: roleid }, { name: newValue } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.roles.modify.alert.update_name_success");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called by the custom text field displaying the role names to verify their content
    verifyRoleNameFieldContent( textFieldRoleId, textFieldValue ) {
        let result = {
            state: false,
            msg: i18n.t("pages.admin.roles.add.dialog.alert.role_name_empty")
        }
        
        if( null != textFieldValue ) {
            if( textFieldValue !== "" ) {
                const { exists, roleid } = this.doesRoleExist( textFieldValue );
                if( true === exists && textFieldRoleId !== roleid ) {
                    result = {
                        state: false,
                        msg: i18n.t("pages.admin.roles.add.dialog.alert.role_does_already_exist")
                    }
                } else {
                    result = {
                        state: true,
                        msg: ""
                    }
                }
            }
        }
        return result;
    }

    /**
     * HELPERS CALLED DURING RENDER TO RETRIEVE INFO FROM PROCESSED DATA
     */

    // Get the users for a role
    getUsersForRole( roleid ) {

        if( roleid !== null ) {
            // Retrieve info from array
            for( let i = 0; i < this.state.tableData.usersForRole.length; i++ ) {
                if( this.state.tableData.usersForRole[i].roleid === roleid ) {
                    return this.state.tableData.usersForRole[i].users;
                }
            }
        } else {
            // No role id given, return all users
            let usersFound = [];

            if( null != this.state.data.users ) {
                let users = this.state.data.users;
                for( let i = 0; i < users.length; i++ ) {
                    usersFound.push( users[i] );
                }
            }

            usersFound.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.userTypeName > b.userTypeName ) return 1;
                if( a.userTypeName < b.userTypeName ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });
            return usersFound;
        }

        // Fallback empty data
        return [];
    }

    // Get the textual representation of an user value
    getUserLabel( value ) {
        let result = "";
        if( null != value ) {
            let userTypeName = "";
            if( value.userTypeName !== "" && value.userTypeName != null ) {
                userTypeName = "(" + value.userTypeName + ") ";
            }
            result = `${userTypeName}${value.lastname}, ${value.firstname}`
        }
        return result;
    }

    // Get the color class representation of an user value
    getUserEnabled( value ) {
        if( null != value ) {
            if( value.account_enabled ) {
                return true;
            } else {
                return false
            }
        }
        return false;
    }

    // Sort given users for role data
    sortUsersForRole( data ) {
        let result = [];
        if( null != data ) {
            result = data;
            result.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.userTypeName > b.userTypeName ) return 1;
                if( a.userTypeName < b.userTypeName ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });
        }
        return result;
    }

    // Get delete button info for a role
    getDeleteButtonDataForRole( roleid ) {
        // Retrieve info from array
        for( let i = 0; i < this.state.tableData.deleteButtonsForRole.length; i++ ) {
            if( this.state.tableData.deleteButtonsForRole[i].roleid === roleid ) {
                return this.state.tableData.deleteButtonsForRole[i];
            }
        }
        // Fallback empty data
        return { roleid: roleid, enabled: false, tooltip: i18n.t("pages.admin.roles.delete.button.tooltip.disabled") };
    }

    /// Check if a certain role name already exists
    doesRoleExist( name ) {
        let result = {
            exists: false,
            roleid: 0
        }

        // Retrieve info from array
        if( null != this.state.data.roles ) {
            let roles = this.state.data.roles;

            for( let i = 0; i < roles.length; i++ ) {
                if( roles[i].name === name ) {
                    result.exists = true;
                    result.roleid = roles[i].roleid;
                    break;
                }
            }
        }

        return result;
    }

    /**
     * DATA SORT
     */

    // Handle table sort
    handleRequestSort( event, property ) {

        // Check if clicked on same property
        if( this.state.orderBy === property ) {

            // User clicked on same property
            // If already sorted in ascending order, switch to descending order
            if( this.state.order === 'asc' ) {
                this.setState({ order: 'desc', orderBy: property });
            } else {

                // If already sorted in descending order, switch to no order
                this.setState({ order: 'asc', orderBy: 0 });
            }

        } else {
            // User clicked on new property, sort new property in ascending order
            this.setState({ order: 'asc', orderBy: property });
        }
    };

    // Sort the given table data
    sortTableData( roles ) {
        // Handle case where no ordering is selected
        if( this.state.orderBy === 0 ) {
            return roles.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
        }
        return stableSort( roles, getComparator(this.state.order, this.state.orderBy) );
    }

    /**
     * MAIN RENDER FUNCTION
     */
    render() {
        const { classes } = this.props;

        // Only display data if it has already been loaded
        let contentTable = "";
        let additionalNotes = "";

        // Make sure if we got the data already
        if( null != this.state.data.roles ) {

            // Assemble table content
            const tableHeaderFirst = [
                { key: 1, span: 1, align: "center", style: {}, text: i18n.t("pages.admin.roles.table.header.primary.name") },
                { key: 2, span: 7, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.roles.table.header.primary.general") },
                { key: 3, span: 3, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.roles.table.header.primary.user") },
                { key: 4, span: 2, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.roles.table.header.primary.controls") }
            ];
            const tableHeaderSecond = [
                { key: "name", span: 1, align: "left", sortable: true, style: {}, text: i18n.t("pages.admin.roles.table.header.secondary.name") },
                { key: "admin_users", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.roles.table.header.secondary.users") },
                { key: "admin_roles", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.roles.table.header.secondary.roles") },
                { key: "admin_teams", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.roles.table.header.secondary.teams") },
                { key: "admin_projects", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.roles.table.header.secondary.projects") },
                { key: "admin_tasks", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.roles.table.header.secondary.tasks") },
                { key: "admin_specials", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.roles.table.header.secondary.specials") },
                { key: "admin_holidays", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.roles.table.header.secondary.holidays") },
                { key: "admin_absences", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.roles.table.header.secondary.absences") },
                { key: "admin_accountings", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.roles.table.header.secondary.accountings") },
                { key: "admin_contracts", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.roles.table.header.secondary.contracts") },
                { key: "controls", span: 2, align: "center", sortable: false, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.roles.table.header.secondary.controls") }
            ];

            let roles = this.state.data.roles;

            // Filter table data if any search entry has been given
            if( this.state.searchFieldEntry !== "" ) {
                roles = roles.filter( role => String(role.name.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase()));
            }

            // Sort table entries by given properties
            roles = this.sortTableData( roles );

            // Preload translations
            let tooltips = {
                users: i18n.t("pages.admin.roles.table.tooltips.users"),
                roles: i18n.t("pages.admin.roles.table.tooltips.roles"),
                teams: i18n.t("pages.admin.roles.table.tooltips.teams"),
                projects: i18n.t("pages.admin.roles.table.tooltips.projects"),
                tasks: i18n.t("pages.admin.roles.table.tooltips.tasks"),
                specials: i18n.t("pages.admin.roles.table.tooltips.specials"),
                holidays: i18n.t("pages.admin.roles.table.tooltips.holidays"),
                absences: i18n.t("pages.admin.roles.table.tooltips.absences"),
                accountings: i18n.t("pages.admin.roles.table.tooltips.accountings"),
                contracts: i18n.t("pages.admin.roles.table.tooltips.contracts"),
                modify_headline: i18n.t("pages.admin.roles.modify.button.tooltip.headline")
            }

            contentTable = (
                <div>
                    <TableContainer style={{ minHeight: "442px" }} component={Paper}>
                        <StyledTable size="small" >
                            <StyledTableHead>
                                <StyledTableRow key={"user-roles-header-first"}>
                                    {tableHeaderFirst.map((col) => (
                                        <StyledTableCell key={col.key} colSpan={col.span} align={col.align} style={col.style}>{col.text}</StyledTableCell>
                                    ))}
                                </StyledTableRow>
                                <StyledTableRow key={"user-roles-header-second"}>
                                    {tableHeaderSecond.map((col) => (
                                        <StyledTableCell key={col.key} colSpan={col.span} align={col.align} style={col.style}>
                                            <StyledTableSortLabel
                                                active={this.state.orderBy === col.key}
                                                hideSortIcon={false}
                                                direction={this.state.orderBy === col.key ? this.state.order : 'asc'}
                                                onClick={(event) => this.handleRequestSort(event, col.key)}
                                                disabled={!(col.sortable)}
                                            >
                                                {col.text}
                                            </StyledTableSortLabel>
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </StyledTableHead>
                            <TableBody>
                                {roles.slice(this.state.tablePage * this.state.tableRowsPerPage, this.state.tablePage * this.state.tableRowsPerPage + this.state.tableRowsPerPage).map((role) => {
                                    const usersForRole = this.getUsersForRole( role.roleid );
                                    return (
                                        <StyledTableRow key={role.roleid} roleid={role.roleid}>
                                            <StyledTableCell className={classes.tableCellRoleName} key="name" width="20%">
                                                <CustomTextField
                                                    maxLength={config.maxTextLengths.role.name}
                                                    textFieldValue={role.name} 
                                                    verifyContent={(content) => this.verifyRoleNameFieldContent(role.roleid, content)} 
                                                    onApply={(event, newValue) => this.handleRoleNameFieldChange(event, role.roleid, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="admin_users" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.users} checked={role.admin_users} onChange={(event) => this.handleTableRowCheckboxChange(event, role.roleid, this.keys.admin_users)} color="primary" name="switch-admin-users" />
                                            </StyledTableCell>
                                            <StyledTableCell key="admin_roles" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.roles} checked={role.admin_roles} onChange={(event) => this.handleTableRowCheckboxChange(event, role.roleid, this.keys.admin_roles)} color="primary" name="switch-admin-roles" />
                                            </StyledTableCell>
                                            <StyledTableCell key="admin_teams" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.teams} checked={role.admin_teams} onChange={(event) => this.handleTableRowCheckboxChange(event, role.roleid, this.keys.admin_teams)} color="primary" name="switch-admin-teams" />
                                            </StyledTableCell>
                                            <StyledTableCell key="admin_projects" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.projects} checked={role.admin_projects} onChange={(event) => this.handleTableRowCheckboxChange(event, role.roleid, this.keys.admin_projects)} color="primary" name="switch-admin-projects" />
                                            </StyledTableCell>
                                            <StyledTableCell key="admin_tasks" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.tasks} checked={role.admin_tasks} onChange={(event) => this.handleTableRowCheckboxChange(event, role.roleid, this.keys.admin_tasks)} color="primary" name="switch-admin-tasks" />
                                            </StyledTableCell>
                                            <StyledTableCell key="admin_specials" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.specials} checked={role.admin_specials} onChange={(event) => this.handleTableRowCheckboxChange(event, role.roleid, this.keys.admin_specials)} color="primary" name="switch-admin-specials" />
                                            </StyledTableCell>
                                            <StyledTableCell key="admin_holidays" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.holidays} checked={role.admin_holidays} onChange={(event) => this.handleTableRowCheckboxChange(event, role.roleid, this.keys.admin_holidays)} color="primary" name="switch-admin-holidays" />
                                            </StyledTableCell>
                                            <StyledTableCell key="admin_absences" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.absences} checked={role.admin_absences} onChange={(event) => this.handleTableRowCheckboxChange(event, role.roleid, this.keys.admin_absences)} color="primary" name="switch-admin-absences" />
                                            </StyledTableCell>
                                            <StyledTableCell key="admin_accountings" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.accountings} checked={role.admin_accountings} onChange={(event) => this.handleTableRowCheckboxChange(event, role.roleid, this.keys.admin_accountings)} color="primary" name="switch-admin-accountings" />
                                            </StyledTableCell>
                                            <StyledTableCell key="admin_contracts" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.contracts} checked={role.admin_contracts} onChange={(event) => this.handleTableRowCheckboxChange(event, role.roleid, this.keys.admin_contracts)} color="primary" name="switch-admin-contracts" />
                                            </StyledTableCell>
                                            <StyledTableCell key="modify" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <Tooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit" variant="button">{tooltips.modify_headline}</Typography>
                                                            <Typography color="inherit" variant="caption">
                                                                {usersForRole.map((user) => {
                                                                    let fullUserEntry = user.lastname + ", " + user.firstname;
                                                                    if( user.userTypeName != null && user.userTypeName !== "" ) {
                                                                        fullUserEntry = "(" + user.userTypeName + ") " + user.lastname + ", " + user.firstname;
                                                                    }
                                                                    return (
                                                                        <div key={user.userid}>{fullUserEntry}</div>
                                                                    )
                                                                })}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.submit}
                                                            disabled={false}
                                                            onClick={(event) => this.handleTableRowModifyClick(event, role.roleid, role.name)}
                                                            style={{ height: "30px", width: "45px" }}
                                                            value={role.roleid}
                                                        >
                                                            {usersForRole.length} <PersonIcon />
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                            <StyledTableCell key="delete" align="center" padding="checkbox" style={{ borderTopWidth: 0 }} onClick={(event) => event.stopPropagation()}>
                                                <Tooltip title={this.getDeleteButtonDataForRole(role.roleid).tooltip}>
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.clearButton}
                                                            disabled={!(this.getDeleteButtonDataForRole(role.roleid).enabled)}
                                                            onClick={(event) => this.handleTableRowDeleteClick(event, role.roleid, role.name)}
                                                            style={{ height: "30px", width: "45px" }}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                    <Grid container direction="row" justify="flex-end" className={classes.tablePaginationGrid} spacing={1} wrap='nowrap'>
                        <Grid item>
                            <TablePagination
                                className={classes.tablePagination}
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={roles.length}
                                rowsPerPage={this.state.tableRowsPerPage}
                                labelRowsPerPage={i18n.t("table.pagination.rows_per_page")}
                                page={this.state.tablePage}
                                onChangePage={this.handleChangeTablePage}
                                onChangeRowsPerPage={this.handleChangeTableRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                    <ModalDialog 
                        open={this.state.addDialog.open}
                        title={i18n.t("pages.admin.roles.add.dialog.title")}
                        descText={i18n.t("pages.admin.roles.add.dialog.description")}
                        buttonLeft={i18n.t("pages.admin.roles.add.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.roles.add.dialog.button_right")}
                        handleClickClose={() => this.handleAddDialog_Cancel()}
                        handleClickLeft={(content) => this.handleAddDialog_Ok(content)}
                        handleClickRight={() => this.handleAddDialog_Cancel()}
                    >
                        <ModalDialogTextField 
                            maxLength={config.maxTextLengths.role.name}
                            label={i18n.t("pages.admin.roles.add.dialog.field_name")} 
                            verifyContent={(content) => this.verifyAddDialog_Content(content)} 
                        />
                    </ModalDialog>
                    <ModalDialog 
                        open={this.state.modifyDialog.open}
                        title={i18n.t("pages.admin.roles.modify.dialog.title")}
                        descText={i18n.t("pages.admin.roles.modify.dialog.description", { name: this.state.modifyDialog.rolename })}
                        buttonLeft={i18n.t("pages.admin.roles.modify.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.roles.modify.dialog.button_right")}
                        handleClickClose={() => this.handleTableRowModifyDialog_Cancel()}
                        handleClickLeft={(content) => this.handleTableRowModifyDialog_Ok( this.state.modifyDialog.roleid, content )}
                        handleClickRight={() => this.handleTableRowModifyDialog_Cancel()}
                        maxWidth={"md"}
                    >
                        <ModalDialogChipInput
                            className={classes.chipInput}
                            getOptionEnabled={(value) => this.getUserEnabled(value)}
                            getOptionLabel={(value) => this.getUserLabel(value)} 
                            getAllOptions={() => this.getUsersForRole(null)} 
                            getSelectedOptions={() => this.getUsersForRole(this.state.modifyDialog.roleid)} 
                            sortOptions={(data) => this.sortUsersForRole(data)}
                            textFieldLabel={i18n.t("pages.admin.roles.modify.dialog.edit.label")}
                            noOptionsText={i18n.t("pages.admin.roles.modify.dialog.edit.no_options")}
                        />
                    </ModalDialog>
                    <ModalDialog 
                        open={this.state.deleteDialog.open}
                        title={i18n.t("pages.admin.roles.delete.dialog.title")}
                        descText={i18n.t("pages.admin.roles.delete.dialog.description", { name: this.state.deleteDialog.rolename })}
                        buttonLeft={i18n.t("pages.admin.roles.delete.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.roles.delete.dialog.button_right")}
                        handleClickClose={() => this.handleTableRowDeleteDialog_Cancel()}
                        handleClickLeft={() => this.handleTableRowDeleteDialog_Ok( this.state.deleteDialog.roleid )}
                        handleClickRight={() => this.handleTableRowDeleteDialog_Cancel()}
                    />
                </div>
            )

            additionalNotes = (
                <div>
                    <PageSubtitle title={i18n.t("pages.admin.roles.notes.headline")} />
                    <PageDescription key="notes-desc-1">
                        {i18n.t("pages.admin.roles.notes.desc1")}
                    </PageDescription>
                    <br />
                    <PageDescription key="notes-desc-2">
                        {i18n.t("pages.admin.roles.notes.desc2")}
                    </PageDescription>
                </div>
            )
        }

        return (
            <React.Fragment>
                <PageTitle title={i18n.t("pages.admin.roles.title")} />
                <p>{i18n.t("pages.admin.roles.desc")}</p>
                <SearchControls
                    label={i18n.t("pages.admin.roles.search.label")}
                    helperText={i18n.t("pages.admin.roles.search.subtext")}
                    addTooltip={i18n.t("pages.admin.roles.add.button.tooltip")}
                    refreshTooltip={i18n.t("buttons.refresh.tooltip")}
                    onAdd={this.handleAddButtonClick}
                    onRefresh={this.handleRefreshButtonClick}
                    onChange={this.handleChangeSearchField} 
                />
                <div>{contentTable}</div>
                {additionalNotes}
            </React.Fragment>
        )
    }
};

export default withStyles(useStyles)(AdminRoles);