import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AuthContext from '../contexts/AuthContext';
import { StyledDataTable, StyledSortableDataTable } from '../components/StyledTable.js'
import SelectWorkWeek from '../components/SelectWorkWeek'
import DateOnly from '../components/DateOnly'
import DateTime from '../components/DateTime'
import MultiService from '../classes/MultiService';
import PageTitle from '../components/PageTitle';
import i18n from 'i18next';

const useStyles = theme => ({
    content: {
        flexGrow: 1
    }
});

/**
 * Profile Page
 */
class ProfilePage extends Component {
    static contextType = AuthContext;
    
    state = {
        userProps: null,
        order: "asc",
        orderBy: 0 // No order, leave as added
    }

    constructor(props) {
        super(props);
        this.handleRequestSort = this.handleRequestSort.bind( this );
        this.addTableData = this.addTableData.bind( this );
    }

    // Handle table sort
    handleRequestSort( event, property ) {

        // Check if clicked on same property
        if( this.state.orderBy === property ) {

            // User clicked on same property
            // If already sorted in ascending order, switch to descending order
            if( this.state.order === 'asc' ) {
                this.setState({ order: 'desc', orderBy: property });
            } else {

                // If already sorted in descending order, switch to no order
                this.setState({ order: 'asc', orderBy: 0 });
            }

        } else {
            // User clicked on new property, sort new property in ascending order
            this.setState({ order: 'asc', orderBy: property });
        }
    };

    /**
     * DATA HANDLING
     */

    // Refresh data from backend
    async refreshDataFromBackend() {
        const { statusCode, statusText, resData } = await MultiService.getUserProps( this.context.userid );

        // Set the proper error message
        let alert = {
            severity: "info",
            message: ""
        }
        if( 200 !== statusCode || null == resData ) {
            alert.severity = "error";
            alert.message = `${statusText}: ${statusCode}`;
        }
        this.handleAlertChanged( alert );

        // Update other states
        if( 200 === statusCode && null != resData ) {
            this.setState({ userProps: resData });
        } else {
            this.setState({ userProps: null });
        }
    }

    /**
     * PUSH TO PARENT FUNCTIONS
     */

    // Push data loaded to parent component
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Push alert to parent component
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    /**
     * REACT FUNCTIONS
     */

    // Component has mounted
    async componentDidMount() {
        await this.refreshDataFromBackend();
        this.handleDataLoadedChanged( true );
    }

    // Component will unmount
    componentWillUnmount() {
        // Clear all states
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
    }

    // Add to table data array
    addTableData( name, value ) {
        return { name, value };
    }

    // Render function
    render() {
        // Only display everything if data has already been loaded
        let content = "";

        if( null != this.state.userProps ) {
            let contentUserDetails = "";
            let contentUserRoles = "";
            let contentUserTeams = "";
            let contentUserProjects = "";
            let contentUserTasks = "";
            let contentUserContracts = "";

            let stringYes = i18n.t("values.yes");
            let stringNo = i18n.t("values.no");
            let stringNone = i18n.t("values.none");

            /**
             * USER DETAILS
             */
            if( null != this.state.userProps.user ) {
                const tableHeader = [
                    { id: "name", width: "30%", name: i18n.t("pages.profile.tables.details.header.parameter") },
                    { id: "value", width: "70%", name: i18n.t("pages.profile.tables.details.header.values") }
                ]

                let userTypeName = stringNone;
                if( null != this.state.userProps.user.userTypeName && this.state.userProps.user.userTypeName !== "" ) {
                    userTypeName = this.state.userProps.user.userTypeName;
                }

                let tableData = [
                    this.addTableData( i18n.t("pages.profile.tables.details.parameter.userid"), this.state.userProps.user.userid ),
                    this.addTableData( i18n.t("pages.profile.tables.details.parameter.first_name"), this.state.userProps.user.firstname ),
                    this.addTableData( i18n.t("pages.profile.tables.details.parameter.last_name"), this.state.userProps.user.lastname ),
                    this.addTableData( i18n.t("pages.profile.tables.details.parameter.email"), this.state.userProps.user.email ),
                    this.addTableData( i18n.t("pages.profile.tables.details.parameter.language"), this.state.userProps.user.language ),
                    this.addTableData( i18n.t("pages.profile.tables.details.parameter.usertype"), userTypeName ),
                    this.addTableData( i18n.t("pages.profile.tables.details.parameter.last_login"), <DateTime language={this.context.language} value={this.state.userProps.user.last_login} /> ),
                ]

                contentUserDetails = (
                    <StyledSortableDataTable 
                        tableKey="user-details" 
                        tableHeader={tableHeader} 
                        tableData={tableData}
                        order={this.state.order}
                        orderBy={this.state.orderBy}
                        onRequestSort={this.handleRequestSort}
                    />
                )
            }

            /**
             * USER ROLES
             */
            if( null != this.state.userProps.admin ) {
                const tableHeader = [
                    { id: "name", width: "30%", name: i18n.t("pages.profile.tables.roles.header.parameter") },
                    { id: "value", width: "70%", name: i18n.t("pages.profile.tables.roles.header.values") }
                ]

                let tableData = [
                    this.addTableData( i18n.t("pages.profile.tables.roles.parameter.global_admin"), this.state.userProps.admin.global ? stringYes : stringNo ),
                    this.addTableData( i18n.t("pages.profile.tables.roles.parameter.admin_users"), this.state.userProps.admin.users ? stringYes : stringNo ),
                    this.addTableData( i18n.t("pages.profile.tables.roles.parameter.admin_roles"), this.state.userProps.admin.roles ? stringYes : stringNo ),
                    this.addTableData( i18n.t("pages.profile.tables.roles.parameter.admin_teams"), this.state.userProps.admin.teams ? stringYes : stringNo ),
                    this.addTableData( i18n.t("pages.profile.tables.roles.parameter.admin_projects"), this.state.userProps.admin.projects ? stringYes : stringNo ),
                    this.addTableData( i18n.t("pages.profile.tables.roles.parameter.admin_tasks"), this.state.userProps.admin.tasks ? stringYes : stringNo ),
                    this.addTableData( i18n.t("pages.profile.tables.roles.parameter.admin_specials"), this.state.userProps.admin.specials ? stringYes : stringNo ),
                    this.addTableData( i18n.t("pages.profile.tables.roles.parameter.admin_holidays"), this.state.userProps.admin.holidays ? stringYes : stringNo ),
                    this.addTableData( i18n.t("pages.profile.tables.roles.parameter.admin_absences"), this.state.userProps.admin.absences ? stringYes : stringNo ),
                    this.addTableData( i18n.t("pages.profile.tables.roles.parameter.admin_accountings"), this.state.userProps.admin.accountings ? stringYes : stringNo ),
                    this.addTableData( i18n.t("pages.profile.tables.roles.parameter.admin_contracts"), this.state.userProps.admin.contracts ? stringYes : stringNo )
                ]
                contentUserRoles = (
                    <StyledSortableDataTable 
                        tableKey="user-roles" 
                        tableHeader={tableHeader} 
                        tableData={tableData}
                        order={this.state.order}
                        orderBy={this.state.orderBy}
                        onRequestSort={this.handleRequestSort}
                    />
                )
            }

            /**
             * USER TEAMS
             */
            if( null != this.state.userProps.teams ) {
                const tableHeader = [
                    { id: "name", width: "30%", name: i18n.t("pages.profile.tables.teams.header.parameter") },
                    { id: "value", width: "70%", name: i18n.t("pages.profile.tables.teams.header.values") }
                ]

                let tableData = [];
                for (var team of this.state.userProps.teams ) {
                    let tableRow = this.addTableData( team.name, team.is_team_lead ? stringYes : stringNo );
                    tableData.push(tableRow);
                }
                if( 0 === this.state.userProps.teams.length ) {
                    let tableRow = this.addTableData( i18n.t("pages.profile.tables.teams.none"), "" );
                    tableData.push(tableRow);
                }
                contentUserTeams = (
                    <StyledSortableDataTable 
                        tableKey="user-teams" 
                        tableHeader={tableHeader} 
                        tableData={tableData}
                        order={this.state.order}
                        orderBy={this.state.orderBy}
                        onRequestSort={this.handleRequestSort}
                    />
                )
            }

            /**
             * USER PROJECTS
             */
            if( null != this.state.userProps.projects ) {
                const tableHeader = [
                    { id: "name", width: "30%", name: i18n.t("pages.profile.tables.projects.header.parameter") },
                    { id: "value", width: "70%", name: i18n.t("pages.profile.tables.projects.header.values") }
                ]

                let tableData = [];
                for (var project of this.state.userProps.projects ) {
                    let tableRow = this.addTableData( project.name, project.is_project_lead ? stringYes : stringNo );
                    tableData.push(tableRow);
                }
                if( 0 === this.state.userProps.projects.length ) {
                    let tableRow = this.addTableData( i18n.t("pages.profile.tables.projects.none"), "" );
                    tableData.push(tableRow);
                }
                contentUserProjects = (
                    <StyledSortableDataTable 
                        tableKey="user-projects" 
                        tableHeader={tableHeader} 
                        tableData={tableData}
                        order={this.state.order}
                        orderBy={this.state.orderBy}
                        onRequestSort={this.handleRequestSort}
                    />
                )
            }

            /**
             * USER TASKS
             */
            if( null != this.state.userProps.tasks ) {
                const tableHeader = [
                    { id: "name", width: "30%", name: i18n.t("pages.profile.tables.tasks.header.parameter") },
                    { id: "value", width: "70%", name: i18n.t("pages.profile.tables.tasks.header.values") }
                ]

                let tableData = [];
                for (var task of this.state.userProps.tasks ) {
                    let tableRow = this.addTableData( task.name, task.status );
                    tableData.push(tableRow);
                }
                if( 0 === this.state.userProps.tasks.length ) {
                    let tableRow = this.addTableData( i18n.t("pages.profile.tables.tasks.none"), "" );
                    tableData.push(tableRow);
                }
                contentUserTasks = (
                    <StyledSortableDataTable 
                        tableKey="user-tasks" 
                        tableHeader={tableHeader} 
                        tableData={tableData}
                        order={this.state.order}
                        orderBy={this.state.orderBy}
                        onRequestSort={this.handleRequestSort}
                    />
                )
            }

            /**
             * USER CONTRACTS
             */
            if( null != this.state.userProps.contracts ) {
                const tableHeader = [
                    { id: 1, width: "14%", name: i18n.t("pages.profile.tables.contracts.header.parameter") },
                    { id: 2, width: "14%", name: i18n.t("pages.profile.tables.contracts.header.values1") },
                    { id: 3, width: "14%", name: i18n.t("pages.profile.tables.contracts.header.values2") },
                    { id: 4, width: "14%", name: i18n.t("pages.profile.tables.contracts.header.values3") },
                    { id: 5, width: "14%", name: i18n.t("pages.profile.tables.contracts.header.values4") },
                    { id: 6, width: "14%", name: i18n.t("pages.profile.tables.contracts.header.values5") },
                    { id: 7, width: "14%", name: i18n.t("pages.profile.tables.contracts.header.values6") }
                ]

                // Sort properly for latest contract to appear at the top of the list
                this.state.userProps.contracts.sort(function(a, b) {
                    return new Date(a.date_start) < new Date(b.date_start) ? 1 : -1;
                })

                let tableData = [];
                
                for (var contract of this.state.userProps.contracts ) {
                    let selectWorkWeek = (
                        <SelectWorkWeek 
                            checkedDaysAsString={contract.work_days_per_week}
                            allDaysDisabled={true}
                        />
                    )

                    let dateStart = (
                        <DateOnly language={this.context.language} prefix={i18n.t("pages.profile.tables.contracts.since")} value={contract.date_start} />
                    )
                    let dateEnd = (
                        <DateOnly language={this.context.language} value={contract.date_end} />
                    )

                    let tableRow = {
                        key: dateStart,
                        values: [
                            dateEnd,
                            contract.work_hours_per_week,
                            selectWorkWeek,
                            contract.vacation_days_per_year,
                            contract.vacation_days_at_start,
                            contract.work_hours_at_start
                        ]
                    }
                    tableData.push(tableRow);
                }
                if( 0 === this.state.userProps.contracts.length ) {
                    let tableRow = {
                        key: 0,
                        values: [
                            i18n.t("pages.profile.tables.contracts.none"),
                            "",
                            "",
                            ""
                        ]
                    }
                    tableData.push(tableRow);
                }
                contentUserContracts = (
                    <StyledDataTable 
                        tableKey="user-contracts" 
                        tableHeader={tableHeader} 
                        tableData={tableData}
                    />
                )
            }

            // Assemble generated content
            content = (
                <div>
                    {contentUserDetails}
                    <br />
                    {contentUserRoles}
                    <br />
                    {contentUserTeams}
                    <br />
                    {contentUserProjects}
                    <br />
                    {contentUserTasks}
                    <br />
                    {contentUserContracts}
                    <br />
                </div>
            );
        }

        return (
            <React.Fragment>
                <PageTitle title={i18n.t("pages.profile.title")} />
                <p>{i18n.t("pages.profile.desc")}</p>
                {content}
            </React.Fragment>
        );
    }
}

export default withStyles(useStyles)(ProfilePage);