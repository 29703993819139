import React, { Component } from 'react';
import AuthContext from '../contexts/AuthContext';
import { withStyles } from '@material-ui/core/styles';
import PageTitle from '../components/PageTitle';
import TabContainer from '../components/TabContainer';
import ReportsMy from '../components/ReportsMy';
import ReportsCompany from '../components/ReportsCompany';
import ReportsTeam from '../components/ReportsTeam';
import ReportsProject from '../components/ReportsProject';
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Reports Page
 */
class ReportsPage extends Component {
    static contextType = AuthContext;

    state = {};

    constructor(props) {
        super(props);

        this.handleTabChange = this.handleTabChange.bind( this );
        this.renderTabMy = this.renderTabMy.bind( this );
        this.renderTabCompany = this.renderTabCompany.bind( this );
        this.renderTabTeam = this.renderTabTeam.bind( this );
        this.renderTabProject = this.renderTabProject.bind( this );
        this.handleDataLoadedChanged = this.handleDataLoadedChanged.bind( this );
        this.handleAlertChanged = this.handleAlertChanged.bind( this );
        this.handleActionInProgressChanged = this.handleActionInProgressChanged.bind( this );

        // Set initial state
        this.state = this.getInitialState( "", "" );
    }

    /**
     * STATE HANDLING
     */

    // Get the initial state variables of this component
    getInitialState() {
        return {
            currentTabIndex: 0,
            data: { // Raw loaded data from the backend
                admin: null
            }
        }
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState() {
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
        this.setState( this.getInitialState() );
    }

    /**
     * LINK TO PARENT COMPONENT FUNCTIONS
     */

    // Push data loaded changed to parent
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Push alert to parent
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Propagate information to parent component
    handleActionInProgressChanged( actionInProgress ) {
        if( this.props.actionInProgressChanged ) {
            this.props.actionInProgressChanged( actionInProgress );
        }
    }

    /**
     * LOAD AND PROCESS BACKEND DATA
     */

    // Gather all data from the backend
    async refreshData() {

        let adminData = null;
        // Gather admin data for the currently logged in user
        {
            const { statusCode, statusText, userAdminData } = await this.context.services.admin.getAdminData( this.context.userid );
            if( 200 === statusCode && null != userAdminData ) {
                adminData = userAdminData;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        this.setState({ 
            data: {
                ...this.state.data,
                admin: adminData
            } 
        });
    }

    /**
     * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
     */

    // Called by react when this component has been mounted
    async componentDidMount() {
        // Refresh all data for currently selected year, month and user
        await this.refreshData();
    }

    // Called by react before this component unmounts
    componentWillUnmount() {
        this.resetState();
    }

    /**
	 * HANDLERS FOR TABS
	 */

	// Called when the user selects a different tab
	handleTabChange( newValue ) {
        this.setState({ currentTabIndex: newValue });
    }

    /**
	 * RENDER FUNCTIONS
	 */

    // Render my reports
    renderTabMy() {
		return (
			<div key="my-reports-content">
                <ReportsMy 
                    language={this.context.language}
                    adminData={this.state.data.admin}
                    userid={this.context.userid}
                    dataLoadedChanged={this.handleDataLoadedChanged} 
                    alertChanged={this.handleAlertChanged} 
                    actionInProgressChanged={this.handleActionInProgressChanged}
                />
            </div>
        );
    }

    // Render company reports
    renderTabCompany() {
		return (
			<div key="company-reports-content">
                <ReportsCompany 
                    language={this.context.language}
                    adminData={this.state.data.admin}
                    dataLoadedChanged={this.handleDataLoadedChanged} 
                    alertChanged={this.handleAlertChanged} 
                    actionInProgressChanged={this.handleActionInProgressChanged}
                />
            </div>
        );
    }

    // Render team reports
    renderTabTeam() {
		return (
			<div key="team-reports-content">
                <ReportsTeam 
                    language={this.context.language}
                    adminData={this.state.data.admin}
                    dataLoadedChanged={this.handleDataLoadedChanged} 
                    alertChanged={this.handleAlertChanged} 
                    actionInProgressChanged={this.handleActionInProgressChanged}
                />
            </div>
        );
    }

    // Render project reports
    renderTabProject() {
		return (
			<div key="project-reports-content">
                <ReportsProject 
                    language={this.context.language}
                    adminData={this.state.data.admin}
                    dataLoadedChanged={this.handleDataLoadedChanged} 
                    alertChanged={this.handleAlertChanged} 
                    actionInProgressChanged={this.handleActionInProgressChanged}
                />
            </div>
        );
    }

    // React render
    render() {
		let tabHeaderArray = [];
        let tabContentArray = [];

		if( null != this.state.data.admin ) {

            // General tab render functions
            let tabRenderFunctions = [ this.renderTabMy, this.renderTabCompany, this.renderTabTeam, this.renderTabProject ];

            // Always show my own tab
            let index = 0;
            tabHeaderArray[index] = { label: i18n.t("pages.reports.tabs.my_reports"), value: 0 };
            let tabContent = tabRenderFunctions[0]();
            tabContentArray[index] = tabContent;
            index++;

            // Show different tabs depending on permissions
            if( true === this.state.data.admin.admin.global || true === this.state.data.admin.admin.absences  ) {
                    tabHeaderArray[index] = { label: i18n.t("pages.reports.tabs.company_reports"), value: 1 };
                    tabContent = tabRenderFunctions[1]();
                    tabContentArray[index] = tabContent;
                    index++;
            }

            if( true === this.state.data.admin.admin.global || true === this.state.data.admin.admin.accountings || true === this.state.data.admin.admin.absences ||
                this.state.data.admin.teamlead.length > 0 ) {
                tabHeaderArray[index] = { label: i18n.t("pages.reports.tabs.team_reports"), value: 2 };
                tabContent = tabRenderFunctions[2]();
                tabContentArray[index] = tabContent;
                index++;
            }

            if( true === this.state.data.admin.admin.global || true === this.state.data.admin.admin.accountings || true === this.state.data.admin.admin.projects ||
                this.state.data.admin.projectlead.length > 0 ) {
                    tabHeaderArray[index] = { label: i18n.t("pages.reports.tabs.project_reports"), value: 3 };
                    tabContent = tabRenderFunctions[3]();
                    tabContentArray[index] = tabContent;
                    index++;
            }
		}

        return (
			<React.Fragment>
				<div>
					<PageTitle title={i18n.t("pages.reports.title")} />
                    <p>{i18n.t("pages.reports.desc")}</p>
					<TabContainer
						tabIndex={this.state.currentTabIndex}
						tabHeader={tabHeaderArray}
						onChange={this.handleTabChange}
					>
						{tabContentArray.map((tabContent, i) => (
							<div key={"tab-content-"+i}>
								{tabContent}
							</div>
						))}
					</TabContainer>
				</div>
			</React.Fragment>
		)
    }
}

export default withStyles(useStyles)(ReportsPage);