import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import PdfTableHeader from './PdfTableHeader';
import PdfTableHeaderRow from './PdfTableHeaderRow';
import PdfTableDataRow from './PdfTableDataRow';
import PdfTableFooter from './PdfTableFooter';

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        maxWidth: 200
    },
});

const PdfTable = ({data}) => (
    <View style={styles.tableContainer}>
        {(data.header !== undefined && data.header.key !== undefined) && (
            <PdfTableHeader key={data.header.key.toString()+"-header"} data={data.header} />
        )}
        {data.headerRows.map((row) => {
            return (
                <PdfTableHeaderRow key={row.key.toString()+"-row"} data={row} />
            )
        })}
        {data.dataRows.map((row) => {
            return (
                <PdfTableDataRow key={row.key.toString()+"-row"} data={row} />
            )
        })}
        {(data.footer !== undefined && data.footer.key !== undefined) && (
            <PdfTableFooter key={data.footer.key.toString()+"-footer"} data={data.footer} />
        )}
    </View>
);

export default PdfTable
