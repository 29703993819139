import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const useStyles = theme => ({});

const StyledSubTypography = styled(Typography)`
    font-size: 14px;
`;

/**
 * Page Description
 */
class PageDescription extends Component {

    // Render
    // Takes props: title
    render() {
        return (
            <div id="description">
                <StyledSubTypography key={this.props.key} align="left">
                    {this.props.children}
                </StyledSubTypography>
            </div>
        );
    }
};

export default withStyles(useStyles)(PageDescription);