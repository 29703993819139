import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 */
class UsersToProjectsService {

    /**
     * Get users-to-projects
     * @param {*} userToProjectSearch { projectid, userid, is_project_lead }
     */
    async getUsersToProjects( userToProjectSearch ) {
        var request = {
            query: `
            query getUsersToProjects($userToProjectSearch: UserToProjectSearch!) {
                getUsersToProjects(userToProjectSearch: $userToProjectSearch) {
                    projectid
                    userid
                    is_project_lead
                }
            }
            `,
            variables: {
                userToProjectSearch: userToProjectSearch
            }
        };

        return await ApiService.doPost( request );
    }

    
    /**
     * Add user-to-project
     * @param {*} userToProjectInput { projectid, userid, is_project_lead }
     */
    async addUserToProject( userToProjectInput ) {
        var request = {
            query: `
            mutation addUserToProject($userToProjectInput: UserToProjectInput!) {
                addUserToProject(userToProjectInput: $userToProjectInput) {
                    projectid
                    userid
                    is_project_lead
                }
            }
            `,
            variables: {
                userToProjectInput: userToProjectInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete user-to-project
     * @param {*} userToProjectSearch { projectid, userid, is_project_lead }
     */
    async deleteUserToProject( userToProjectSearch ) {
        var request = {
            query: `
            mutation deleteUserToProject($userToProjectSearch: UserToProjectSearch!) {
                deleteUserToProject(userToProjectSearch: $userToProjectSearch) {
                    result
                }
            }
            `,
            variables: {
                userToProjectSearch: userToProjectSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update user-to-project
     * @param {*} userToProjectSearch { projectid, userid, is_project_lead }
     * @param {*} userToProjectUpdate { is_project_lead }
     */
    async updateUserToProject( userToProjectSearch, userToProjectUpdate ) {
        var request = {
            query: `
            mutation updateUserToProject($userToProjectSearch: UserToProjectSearch!, $userToProjectUpdate: UserToProjectUpdate!) {
                updateUserToProject(userToProjectSearch: $userToProjectSearch, userToProjectUpdate: $userToProjectUpdate) {
                    projectid
                    userid
                    is_project_lead
                }
            }
            `,
            variables: {
                userToProjectSearch: userToProjectSearch,
                userToProjectUpdate: userToProjectUpdate
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new UsersToProjectsService();
