import React, { Component } from 'react';
import UserTypeService from '../../classes/UserTypeService'
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { StyledTableCell, StyledTableRow, StyledTableHead, StyledTable, StyledTableSortLabel, stableSort, getComparator } from '../../components/StyledTable.js'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../components/PageTitle';
import ModalDialog from '../../components/ModalDialog';
import ModalDialogTextField from '../../components/ModalDialogTextField';
import CustomTextField from '../../components/CustomTextField'
import CheckboxTooltip from '../../components/CheckboxTooltip'
import SearchControls from '../../components/SearchControls'
import Paper from '@material-ui/core/Paper';
import { config } from '../../config';
import i18n from 'i18next';

const useStyles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    mainGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    searchAddGrid: {
        display: 'flex',
        height: '80px',
        width: '100%',
        justifyContent: 'space-between',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tableCellUserTypeName: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1.0),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
    },
    tablePaginationGrid: {
        height: '60px',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tablePagination: {
        '& .MuiTablePagination-toolbar': {
            height: '60px',
            minHeight: '60px',
        },
    },
    searchFieldDiv: {
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(1.5),
            width: '40ch',
        },
    },
});

/**
 * Admin User Types
 */
class AdminUserTypes extends Component {
    // Set in constructor
    state = {}

    // Keys to identify the clicked checkbox column
    keys = {
        is_visible: 1
    }

    /**
     * Inputs Props
     * @param {*} props 
     *  admin (array)
     *  userProps (array)
     *  dataLoadChanged (function)
     *  alertChanged (function)
     */
    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleTableRowCheckboxChange = this.handleTableRowCheckboxChange.bind( this );
        this.handleTableRowDeleteClick = this.handleTableRowDeleteClick.bind( this );
        this.handleChangeTablePage = this.handleChangeTablePage.bind( this );
        this.handleChangeTableRowsPerPage = this.handleChangeTableRowsPerPage.bind( this );
        this.handleChangeSearchField = this.handleChangeSearchField.bind( this );
        this.handleAddButtonClick = this.handleAddButtonClick.bind( this );
        this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind( this );
        this.handleAddDialog_Ok = this.handleAddDialog_Ok.bind( this );
        this.handleAddDialog_Cancel = this.handleAddDialog_Cancel.bind( this );
        this.verifyAddDialog_Name = this.verifyAddDialog_Name.bind( this );
        this.handleTableRowDeleteDialog_Ok = this.handleTableRowDeleteDialog_Ok.bind( this );
        this.handleTableRowDeleteDialog_Cancel = this.handleTableRowDeleteDialog_Cancel.bind( this );
        this.getDeleteButtonDataForUserType = this.getDeleteButtonDataForUserType.bind( this );
        this.handleUserTypeNameFieldChange = this.handleUserTypeNameFieldChange.bind( this );
        this.verifyUserTypeNameFieldContent = this.verifyUserTypeNameFieldContent.bind( this );
        this.handleRequestSort = this.handleRequestSort.bind( this );
        this.sortTableData = this.sortTableData.bind( this );

        // Set initial state
        this.state = this.getInitialState();
    }

    /**
     * STATE HANDLING
     */

    // Get the initial state variables of this component
    getInitialState() {
        return {
            data: { // Raw loaded data from the backend
                userTypes: null,
            },
            tableData: { // Additional information gathered from loaded raw data to display on the page
                deleteButtonsForUserType: [] // If the delete button is enabled and which tooltip is shown for that role
            },
            searchFieldEntry: "",
            tablePage: 0,
            tableRowsPerPage: 10,
            order: "asc",
            orderBy: 0,
            addDialog: {
                open: false
            },
            deleteDialog: {
                open: false,
                typeid: null,
                name: null
            }
        }
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState() {
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
        this.setState( this.getInitialState() );
    }

    /**
     * LINK TO PARENT COMPONENT FUNCTIONS
     */

    // Propagate information to parent component
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Propagate information to parent component
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Propagate information to parent component
    handleActionInProgressChanged( actionInProgress ) {
        if( this.props.actionInProgressChanged ) {
            this.props.actionInProgressChanged( actionInProgress );
        }
    }

    /**
     * LOAD AND PROCESS BACKEND DATA
     */

    // Called when a refresh of the table data is needed due to edits in the table
    async refreshTableData() {
        await this.loadDataFromBackend( true );
    }

    // Gather all data from the backend needed on this page and process them properly
    async loadDataFromBackend( manualRefresh ) {
        // Alert message to show
        let alert = {
            severity: "info",
            message: ""
        }

        let userTypeData = null;

        // Gather usertype data
        {
            const { statusCode, statusText, resData } = await UserTypeService.getUserTypes( {} );
            if( 200 === statusCode && null != resData ) {
                userTypeData = resData.getUserTypes;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Now process the data
        await this.processData( userTypeData );

        // All fine, clear the error message (only if not a manual refresh)
        if( false === manualRefresh ) {
            this.handleAlertChanged( alert );
        }
        this.handleDataLoadedChanged( true );
    }

    // Called to make additional processing of data, for example gathering info from that backend data and storing it for later use
    async processData( userTypeData ) {

        // Abort if no proper data given
        if( null == userTypeData ) {
            return;
        }

        // Get the info from userTypeData if there are currently bookings or accountings attached
        // and enable/disable the delete button accordingly
        let deleteButtonsArray = [];

        for( let i = 0; i < userTypeData.length; i++ ) {

            let deleteButtonElement = {
                typeid: userTypeData[i].typeid,
                enabled: false,
                tooltip: i18n.t("pages.admin.usertypes.delete.button.tooltip.disabled")
            }

            if( false === userTypeData[i].has_users ) {
                deleteButtonElement.enabled = true;
                deleteButtonElement.tooltip = i18n.t("pages.admin.usertypes.delete.button.tooltip.enabled");
            }

            deleteButtonsArray[i] = deleteButtonElement;
        }

        // Store the data
        this.setState({ 
            data: { userTypes: userTypeData }, 
            tableData: { deleteButtonsForUserType: deleteButtonsArray } 
        });
    }

    /**
     * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
     */

    // Called by react when this component has been mounted
    async componentDidMount() {
        await this.loadDataFromBackend();
    }

    // Called by react before this component unmounts
    componentWillUnmount() {
        this.resetState();
    }

    /**
     * ALERT DIALOG <ADD>
     */

    // Called to open the add dialog
    openAddDialog() {
        let addDialog = {
            open: true
        }
        this.setState({ addDialog: addDialog });
    }

    // Called when the user clicked "Ok" on the add role dialog
    async handleAddDialog_Ok( name ) {
        this.closeAddDialog();

        // Add the usertype
        if( null != name ) {
            let userTypeValues = {
                name: name,
                is_visible: false
            }

            const { statusCode, statusText, resData } = await UserTypeService.createUserType( userTypeValues );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.usertypes.add.alert.success", { name: name });
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the add role dialog
    handleAddDialog_Cancel() {
        this.closeAddDialog();
    }

    // Called by the add dialog to verify its name content
    verifyAddDialog_Name( content ) {

        let result = {
            state: false,
            msg: i18n.t("pages.admin.usertypes.add.dialog.alert.empty")
        }
        
        if( null != content ) {
            if( null != content.value ) {
                if( content.value !== "" ) {
                    const { exists } = this.doesUserTypeNameExist( content.value );
                    if( true === exists ) {
                        result = {
                            state: false,
                            msg: i18n.t("pages.admin.usertypes.add.dialog.alert.does_already_exist")
                        }
                    } else {
                        result = {
                            state: true,
                            msg: ""
                        }
                    }
                }
            }
        }

        return result;
    }

    // Close the add alert dialog
    closeAddDialog() {
        let addDialog = {
            open: false
        }
        this.setState({ addDialog: addDialog });
    }

    /**
     * ALERT DIALOG <DELETE>
     */

    // Called to open the delete dialog
    openTableRowDeleteDialog( typeid, name ) {
        let deleteDialog = {
            open: true,
            typeid: typeid,
            name: name
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    // Called when the user clicked "Ok" on the confirm row delete dialog
    async handleTableRowDeleteDialog_Ok( typeid ) {
        this.closeTableRowDeleteDialog();

        // Delete the role
        if( null != typeid ) {
            const { statusCode, statusText, resData } = await UserTypeService.deleteUserType( { typeid: typeid } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                if( true === resData.deleteUserType.result ) {
                    alert.severity = "success";
                    alert.message = i18n.t( "pages.admin.usertypes.delete.alert.success" );
                } else {
                    alert.severity = "error";
                    alert.message = `${statusText}: ${statusCode}`;
                }
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the confirm row delete dialog
    async handleTableRowDeleteDialog_Cancel() {
        this.closeTableRowDeleteDialog();
    }

    // Close the delete alert dialog
    closeTableRowDeleteDialog() {
        let deleteDialog = {
            open: false,
            typeid: this.state.deleteDialog.typeid,
            name: this.state.deleteDialog.name
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    /**
     * CALLBACK FUNCTIONS FOR SEARCH FIELD
     */

    // Called when text is entered in the search field
    handleChangeSearchField(value) {
        this.setState({ searchFieldEntry: value });
    }

    /**
     * CALLBACK FUNCTIONS OF TABLE ELEMENTS
     */

    // Called when a checkbox of an existing role is clicked within the table
    async handleTableRowCheckboxChange(event, typeid, keyid) {

        // Update the role
        if( null != typeid && null != keyid ) {
            let userTypeUpdate;
            if( keyid === this.keys.is_visible ) {
                userTypeUpdate = { is_visible: event.target.checked }
            }

            const { statusCode, statusText, resData } = await UserTypeService.updateUserType( { typeid: typeid }, userTypeUpdate );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t( "pages.admin.usertypes.modify.alert.success" );
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when a delete button of an existing role is clicked within the table
    handleTableRowDeleteClick(event, typeid, name) {
        event.stopPropagation();
        this.openTableRowDeleteDialog( typeid, name );
    }

    /**
     * TABLE PAGINATION EVENT HANDLERS
     */

    // Called if page changes on the table display occur
    handleChangeTablePage = (event, newPage) => {
        this.setState({tablePage: newPage});
    };
    
    // Called if the user selects a different amount of shown rows
    handleChangeTableRowsPerPage = (event) => {
        this.setState({tablePage: 0, tableRowsPerPage: +event.target.value});
    };

    /**
     * TOP ROW BUTTONS
     */

    // Called if the add button is clicked
    async handleAddButtonClick() {
        this.openAddDialog();
    }

    // Called if the refresh button is clicked
    async handleRefreshButtonClick() {
        this.resetState();
        await this.refreshTableData();
    }

    /**
     * TEXT FIELDS FOR SPECIAL NAME
     */

    // Handle when a usertype name text field is changed
    async handleUserTypeNameFieldChange(event, typeid, newValue) {
        event.stopPropagation();

        // Update the usertype
        if( null != typeid ) {
            const { statusCode, statusText, resData } = await UserTypeService.updateUserType( { typeid: typeid }, { name: newValue } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.usertypes.modify.alert.success");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called by the custom text field displaying the usertype names to verify their content
    verifyUserTypeNameFieldContent( textFieldTypeId, textFieldValue ) {
        let result = {
            state: false,
            msg: i18n.t("pages.admin.usertypes.add.dialog.alert.usertype_name_empty")
        }
        
        if( null != textFieldValue ) {
            if( textFieldValue !== "" ) {
                const { exists, typeid } = this.doesUserTypeNameExist( textFieldValue );
                if( true === exists && textFieldTypeId !== typeid ) {
                    result = {
                        state: false,
                        msg: i18n.t("pages.admin.usertypes.add.dialog.alert.usertype_does_already_exist")
                    }
                } else {
                    result = {
                        state: true,
                        msg: ""
                    }
                }
            }
        }
        return result;
    }

    /**
     * HELPERS CALLED DURING RENDER TO RETRIEVE INFO FROM PROCESSED DATA
     */

    // Get delete button info for a usertype
    getDeleteButtonDataForUserType( typeid ) {
        // Retrieve info from array
        for( let i = 0; i < this.state.tableData.deleteButtonsForUserType.length; i++ ) {
            if( this.state.tableData.deleteButtonsForUserType[i].typeid === typeid ) {
                return this.state.tableData.deleteButtonsForUserType[i];
            }
        }
        // Fallback empty data
        return { typeid: typeid, enabled: false, tooltip: i18n.t("pages.admin.usertypes.delete.button.tooltip.disabled") };
    }

    /// Check if a certain usertype name already exists
    doesUserTypeNameExist( name ) {
        let result = {
            exists: false,
            typeid: 0
        }

        // Retrieve info from array
        if( null != this.state.data.userTypes ) {
            let userTypes = this.state.data.userTypes;

            for( let i = 0; i < userTypes.length; i++ ) {
                if( userTypes[i].name === name ) {
                    result.exists = true;
                    result.typeid = userTypes[i].typeid;
                    break;
                }
            }
        }

        return result;
    }

    /**
     * DATA SORT
     */

    // Handle table sort
    handleRequestSort( event, property ) {

        // Check if clicked on same property
        if( this.state.orderBy === property ) {

            // User clicked on same property
            // If already sorted in ascending order, switch to descending order
            if( this.state.order === 'asc' ) {
                this.setState({ order: 'desc', orderBy: property });
            } else {

                // If already sorted in descending order, switch to no order
                this.setState({ order: 'asc', orderBy: 0 });
            }

        } else {
            // User clicked on new property, sort new property in ascending order
            this.setState({ order: 'asc', orderBy: property });
        }
    };

    // Sort the given table data
    sortTableData( userTypes ) {
        // Handle case where no ordering is selected
        if( this.state.orderBy === 0 ) {
            return userTypes.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
        }
        return stableSort( userTypes, getComparator(this.state.order, this.state.orderBy) );
    }

    /**
     * MAIN RENDER FUNCTION
     */
    render() {
        const { classes } = this.props;

        // Only display data if it has already been loaded
        let contentTable = "";

        // Make sure if we got the data already
        if( null != this.state.data.userTypes ) {

            // Assemble table content
            const tableHeader = [
                { key: "name", span: 1, align: "left", sortable: true, style: {}, text: i18n.t("pages.admin.usertypes.table.header.name") },
                { key: "is_visible", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.usertypes.table.header.is_visible") },
                { key: "controls", span: 2, align: "center", sortable: false, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.usertypes.table.header.controls") }
            ];

            let userTypes = this.state.data.userTypes;

            // Filter table data if any search entry has been given
            if( this.state.searchFieldEntry !== "" ) {
                userTypes = userTypes.filter( userType => String(userType.name.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase()));
            }

            // Sort table entries by given properties
            userTypes = this.sortTableData( userTypes );

            // Preload translations
            let tooltips = {
                is_visible: i18n.t("pages.admin.usertypes.table.tooltips.is_visible"),
            }

            contentTable = (
                <div>
                    <TableContainer style={{ minHeight: "442px" }} component={Paper}>
                        <StyledTable size="small" >
                            <StyledTableHead>
                                <StyledTableRow key={"usertypes-header"}>
                                    {tableHeader.map((col) => (
                                        <StyledTableCell key={col.key} colSpan={col.span} align={col.align} style={col.style}>
                                            <StyledTableSortLabel
                                                active={this.state.orderBy === col.key}
                                                hideSortIcon={false}
                                                direction={this.state.orderBy === col.key ? this.state.order : 'asc'}
                                                onClick={(event) => this.handleRequestSort(event, col.key)}
                                                disabled={!(col.sortable)}
                                            >
                                                {col.text}
                                            </StyledTableSortLabel>
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </StyledTableHead>
                            <TableBody>
                                {userTypes.slice(this.state.tablePage * this.state.tableRowsPerPage, this.state.tablePage * this.state.tableRowsPerPage + this.state.tableRowsPerPage).map((userType) => {
                                    return (
                                        <StyledTableRow key={userType.typeid} typeid={userType.typeid}>
                                            <StyledTableCell className={classes.tableCellUserTypeName} key="name" width="20%">
                                                <CustomTextField 
                                                    maxLength={config.maxTextLengths.userType.name}
                                                    textFieldValue={userType.name} 
                                                    verifyContent={(content) => this.verifyUserTypeNameFieldContent(userType.typeid, content)} 
                                                    onApply={(event, newValue) => this.handleUserTypeNameFieldChange(event, userType.typeid, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="is_visible" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.is_visible} checked={userType.is_visible} onChange={(event) => this.handleTableRowCheckboxChange(event, userType.typeid, this.keys.is_visible)} color="primary" name="switch-admin-is-visible" />
                                            </StyledTableCell>
                                            <StyledTableCell key="delete" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <Tooltip title={this.getDeleteButtonDataForUserType(userType.typeid).tooltip}>
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.clearButton}
                                                            disabled={!(this.getDeleteButtonDataForUserType(userType.typeid).enabled)}
                                                            onClick={(event) => this.handleTableRowDeleteClick(event, userType.typeid, userType.name)}
                                                            style={{ height: "30px", width: "45px" }}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                    <Grid container direction="row" justify="flex-end" className={classes.tablePaginationGrid} spacing={1} wrap='nowrap'>
                        <Grid item>
                            <TablePagination
                                className={classes.tablePagination}
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={userTypes.length}
                                rowsPerPage={this.state.tableRowsPerPage}
                                labelRowsPerPage={i18n.t("table.pagination.rows_per_page")}
                                page={this.state.tablePage}
                                onChangePage={this.handleChangeTablePage}
                                onChangeRowsPerPage={this.handleChangeTableRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                    <ModalDialog 
                        open={this.state.addDialog.open}
                        title={i18n.t("pages.admin.usertypes.add.dialog.title")}
                        descText={i18n.t("pages.admin.usertypes.add.dialog.description")}
                        buttonLeft={i18n.t("pages.admin.usertypes.add.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.usertypes.add.dialog.button_right")}
                        handleClickClose={() => this.handleAddDialog_Cancel()}
                        handleClickLeft={(contents) => this.handleAddDialog_Ok(contents.value)}
                        handleClickRight={() => this.handleAddDialog_Cancel()}
                    >
                        <ModalDialogTextField
                            maxLength={config.maxTextLengths.userType.name}
                            label={i18n.t("pages.admin.usertypes.add.dialog.field.name")} 
                            verifyContent={(content) => this.verifyAddDialog_Name(content)}
                        />
                    </ModalDialog>
                    <ModalDialog 
                        open={this.state.deleteDialog.open}
                        title={i18n.t("pages.admin.usertypes.delete.dialog.title")}
                        descText={i18n.t("pages.admin.usertypes.delete.dialog.description", { name: this.state.deleteDialog.name })}
                        buttonLeft={i18n.t("pages.admin.usertypes.delete.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.usertypes.delete.dialog.button_right")}
                        handleClickClose={() => this.handleTableRowDeleteDialog_Cancel()}
                        handleClickLeft={() => this.handleTableRowDeleteDialog_Ok( this.state.deleteDialog.typeid )}
                        handleClickRight={() => this.handleTableRowDeleteDialog_Cancel()}
                    />
                </div>
            )
        }

        return (
            <React.Fragment>
                <PageTitle title={i18n.t("pages.admin.usertypes.title")} />
                <p>{i18n.t("pages.admin.usertypes.desc")}</p>
                <SearchControls
                    label={i18n.t("pages.admin.usertypes.search.label")}
                    helperText={i18n.t("pages.admin.usertypes.search.subtext")}
                    addTooltip={i18n.t("pages.admin.usertypes.add.button.tooltip")}
                    refreshTooltip={i18n.t("buttons.refresh.tooltip")}
                    onAdd={this.handleAddButtonClick}
                    onRefresh={this.handleRefreshButtonClick}
                    onChange={this.handleChangeSearchField} 
                />
                <div>{contentTable}</div>
            </React.Fragment>
        )
    }
};

export default withStyles(useStyles)(AdminUserTypes);