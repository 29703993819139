import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = theme => ({
    paper: {
        flexGrow: 1,
        marginTop: "0px",
        marginBottom: "15px"
    },
});

const yearsInThePast = 4;
const yearsInTheFuture = 5;
const yearsToDisplay = (yearsInThePast+yearsInTheFuture)+1;

/**
 * Calendar Header
 */
class CalendarHeader extends Component {
    state = {
        value: yearsInThePast,
        currentYear: null,
        yearLabels: []
    }

    constructor(props) {
        super(props);
        
        this.renderButton = this.renderButton.bind( this );
        this.handleButtonRightClick = this.handleButtonRightClick.bind( this );
        this.handleButtonLeftClick = this.handleButtonLeftClick.bind( this );
        this.handleTabChange = this.handleTabChange.bind( this );
    }

    // Handle change clicks on the tab itself
    handleTabChange( event, newValue ) {
        if( null != this.props.onYearChanged ) {
            this.props.onYearChanged( this.state.yearLabels[newValue] );
        }
        this.setState({ value: newValue });
    }

    // Click on right scroll button
    handleButtonRightClick() {
        let newValue = this.state.value+1;

        if( newValue < 0 ) {
            newValue = 0;
        } else if( newValue >= (yearsToDisplay-1) ) {
            newValue = (yearsToDisplay-1);
        }
        if( null != this.props.onYearChanged ) {
            this.props.onYearChanged( this.state.yearLabels[newValue] );
        }
        this.setState({ value: newValue });
    }

    // Click on left scroll button
    handleButtonLeftClick() {
        let newValue = this.state.value-1;

        if( newValue < 0 ) {
            newValue = 0;
        } else if( newValue >= (yearsToDisplay-1) ) {
            newValue = (yearsToDisplay-1);
        }
        if( null != this.props.onYearChanged ) {
            this.props.onYearChanged( this.state.yearLabels[newValue] );
        }
        this.setState({ value: newValue });
    }

    // Render custom scroll buttons
    renderButton( props ) {
        if( props.direction === "left" ) {
            return (
                <Button
                    component="div"
                    className={props.className}
                    role={null}
                    tabIndex={null}
                    onClick={this.handleButtonLeftClick}
                >
                    <KeyboardArrowLeftIcon fontSize="small" />
                </Button>
            )
        } else {
            return (
                <Button
                    component="div"
                    className={props.className}
                    role={null}
                    tabIndex={null}
                    onClick={this.handleButtonRightClick}
                >
                    <KeyboardArrowRightIcon fontSize="small" />
                </Button>
            )
        }
    }

    // Called by react when this component has been mounted
    componentDidMount() {
        let currentYear = new Date().getFullYear();
        let selectedYear = new Date().getFullYear();

        if( null != this.props.year ) {
            selectedYear = this.props.year;
        }
        
        // Generate tabs, display some years plus and some years minus of the current year
        let yearLabels = [];
        for( let i = currentYear-(yearsInThePast); i <= (currentYear+(yearsInTheFuture)); i++ ) {
            yearLabels.push( i );
        }

        // Set the tab on the proper index of the selected year
        let selectedYearIndex = yearsInThePast;
        for( let i = 0; i < yearLabels.length; i++ ) {
            if( yearLabels[i] === selectedYear ) {
                selectedYearIndex = i;
                break;
            }
        }

        if( null != this.props.onYearChanged ) {
            this.props.onYearChanged( yearLabels[selectedYearIndex] );
        }

        this.setState({ value: selectedYearIndex, yearLabels: yearLabels });
    }

    render() {
        return (
            <Paper className={this.props.classes.paper}>
                <Tabs
                    value={this.state.value}
                    indicatorColor="primary"
                    variant="scrollable"
                    ScrollButtonComponent={this.renderButton}
                    onChange={this.handleTabChange}
                >
                    {this.state.yearLabels.map((year) => (
                        <Tab key={"calendar-header-"+year}label={year} />
                    ))}
                </Tabs>
            </Paper>
        );
    }
};

export default withStyles(useStyles)(CalendarHeader);