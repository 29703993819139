import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ChangeLog from '../components/ChangeLog';

const useStyles = theme => ({
    dialogTitle: {
        color: "#ffffff",
        backgroundColor: theme.palette.primary.main
    },
    dialogPaper: {
        maxHeight: "60vh"
    }
});

/**
 * MessageOfTheDay Dialog
 */
class MessageOfTheDay extends Component {

    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleClickClose = this.handleClickClose.bind( this );
    }

    // Button clicked
    handleClickClose(id) {
        if( this.props.handleClose != null ) {
            this.props.handleClose(id);
        }
    }

    render() {
        // Used this.props
        // open, title, maxWidth, dialogMessagesOfTheDay, dialogChangeLog, buttonText, handleClose
        let messageOfTheDay = null;
        if( null != this.props.dialogMessagesOfTheDay && this.props.dialogMessagesOfTheDay.length > 0 ) {
            messageOfTheDay = this.props.dialogMessagesOfTheDay[0];
        }

        let modMsg = "";
        if( null != messageOfTheDay && messageOfTheDay.msg != null && messageOfTheDay.msg !== "" ) {
            modMsg = messageOfTheDay.msg;
        }

        let modVersion = "";
        if( null != messageOfTheDay && messageOfTheDay.showChangeLogOfVersion != null && messageOfTheDay.showChangeLogOfVersion !== "" ) {
            modVersion = messageOfTheDay.showChangeLogOfVersion;
        }

        let modId = "";
        if( null != messageOfTheDay && messageOfTheDay.id != null && messageOfTheDay.id !== "" ) {
            modId = messageOfTheDay.id;
        }

        let contentChangeLog = "";
        if( null != this.props.dialogChangeLog && null != modVersion && modVersion !== "" ) {
            contentChangeLog = (
                <ChangeLog 
                    useAccordionStyle={false}
                    showOnlyVersion={modVersion}
                    changelog={this.props.dialogChangeLog}
                />
            )
        }

        let content = (
            <Dialog
                classes={{ paper: this.props.classes.dialogPaper }}
                open={this.props.open}
                onClose={this.props.handleClickClose}
                maxWidth={this.props.maxWidth}
            >
                <DialogTitle className={this.props.classes.dialogTitle}>
                    {this.props.title}
                </DialogTitle>
                <DialogContent>
                    { modMsg != null && modMsg !== "" && (
                        <DialogContentText>
                            {modMsg}
                        </DialogContentText>
                    )}
                    { contentChangeLog != null && (
                        <DialogContentText component={'span'}>
                            {contentChangeLog}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={(event) => this.handleClickClose(modId)} variant="contained" color="primary">
                        {this.props.buttonText}
                    </Button>
                </DialogActions>
            </Dialog>
        );

        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        );
    }
};

export default withStyles(useStyles)(MessageOfTheDay);