import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { invertColorMoreContrast } from '../helpers.js';
import TablePagination from '@material-ui/core/TablePagination';
import CalendarHelper from '../classes/CalendarHelper.js';
import { config } from '../config';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import i18n from 'i18next';
import clsx from  'clsx';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const useStyles = theme => ({
    container: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(3)
    },
    table: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: "0px"
    },
    cellDividerName: {
        height: "2px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        left: 0,
        position: "sticky",
        userSelect: "none",
        borderBottom: "0px solid rgb(224,224,224)",
        backgroundColor: theme.palette.common.white,
    },
    cellDividerDay: {
        height: "2px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTop: "1px solid #e1e1e1",
        borderBottomWidth: "0px",
        left: 0,
        position: "sticky",
        userSelect: "none",
        backgroundColor: theme.palette.common.white,
        zIndex: theme.zIndex.appBar + 10,
    },
    cellDividerDayToday: {
        height: "2px",
        borderTop: "1px solid #e1e1e1",
        borderBottomWidth: "0px",
        left: 0,
        position: "sticky",
        userSelect: "none",
        borderLeft: "2px solid rgb(255,0,0)",
        borderRight: "2px solid rgb(255,0,0)",
        backgroundColor: theme.palette.common.white,
        zIndex: theme.zIndex.appBar + 10,
    },
    cellDividerBottomDayToday: {
        height: "2px",
        borderTop: "2px solid #ff0000",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        left: 0,
        position: "sticky",
        userSelect: "none",
        backgroundColor: theme.palette.common.white,
        zIndex: theme.zIndex.appBar + 10,
    },
    cellDay: {
        fontWeight: 500,
        fontSize: "12px",
        width: "40px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    cellDayToday: {
        fontWeight: 500,
        fontSize: "12px",
        color: "#ff0000",
        width: "40px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottom: "2px solid #ff0000",
        userSelect: "none"
    },
    cellUserName: {
        paddingLeft: theme.spacing(1),
        fontWeight: 500,
        fontSize: "12px",
        minWidth: "275px",
        width: "275px",
        maxWidth: "275px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none",
        backgroundColor: theme.palette.common.white,
        left: 0,
        position: "sticky",
        zIndex: theme.zIndex.appBar + 2
    },
    cellDayBase: {
        verticalAlign: "top",
        height: "25px",
        color: "#656565",
        borderColor: "#e1e1e1",
        borderStyle: "solid",
        borderLeftWidth: "1px",
        borderRightWidth: "0px",
        borderTopWidth: "1px",
        borderBottomWidth: "0px",
        padding: "0px",
        paddingTop: "4px",
        margin: "0px",
        userSelect: "none"
    },
    cellTodayWeekDay: {
        color: "#000000",
        borderTop: "1px solid #e1e1e1",
        borderLeft: "2px solid #ff0000",
        borderRight: "2px solid #ff0000",
        borderBottomWidth: "0px"
    },
    cellTodayWeekEnd: {
        color: "#000000",
        borderTop: "1px solid #e1e1e1",
        borderLeftWidth: "2px solid #ff0000",
        borderRightWidth: "2px solid #ff0000",
        borderBottomWidth: "0px"
    },
    cellWeekEnd: {
        backgroundColor: "#d9d9d9",
    },
    cellWeekDay: {
        backgroundColor: "#ffffff",
    },
    cellHoliday: {
        color: "#656565",
        backgroundColor: "#fde1fc",
    },
    cellNoWorkDayWeekEnd: {
        backgroundColor: "#d9d9d9",
    },
    cellNoWorkDayWeekDay: {
        color: "#ffffff",
        backgroundColor: "#a0a0a0",
    },
    cellSelected: {
        backgroundColor: "#FACC2E",
    },
    boxAbsent: {
        width: "inherit",
        height: "15px",
        fontSize: "11px",
        fontWeight: 900
    },
    selectionTooltip: {
        fontWeight: 500,
        fontSize: "12px",
        padding: theme.spacing(1),
        backgroundColor: "#616161",
        color: "#ffffff"
    },
    tablePaginationGrid: {
        height: '60px',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tablePagination: {
        '& .MuiTablePagination-toolbar': {
            height: '60px',
            minHeight: '60px',
        },
    },
    cellStickyHead: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.white,
        minWidth: "275px",
        width: "275px",
        maxWidth: "275px",
        left: 0,
        position: "sticky",
        zIndex: theme.zIndex.appBar + 2,
        userSelect: "none",
        paddingTop: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px"
    },
    cellStickyBodyNoBorders: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.white,
        minWidth: "275px",
        width: "275px",
        maxWidth: "275px",
        left: 0,
        position: "sticky",
        zIndex: theme.zIndex.appBar + 2,
        userSelect: "none",
        paddingTop: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        borderColor: "#e1e1e1",
        borderLeftWidth: "0px",
        borderRightWidth: "0px"
    },
    cellStickyBodyWithBorders: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.white,
        minWidth: "275px",
        width: "275px",
        maxWidth: "275px",
        left: 0,
        position: "sticky",
        zIndex: theme.zIndex.appBar + 2,
        userSelect: "none",
        paddingTop: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        borderColor: "#e1e1e1",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)"
    }
});

/**
 * Absence Company Calendar View
 */
class AbsenceCompanyCalendar extends Component {
    state = {
        tablePage: 0,
        tableRowsPerPage: 10,
        mouseIsDown: false,
        selectedDateRange: {
            hasSelection: false,
            start: null,
            end: null,
            prevDate: null,
            hasAbsence: false,
            amountOfSelectedWorkDays: 0,
            userid: null
        }
    }

    constructor(props) {
        super(props);
        
        // All function bindings to make them accessible
        this.handleChangeTablePage = this.handleChangeTablePage.bind( this );
        this.handleChangeTableRowsPerPage = this.handleChangeTableRowsPerPage.bind( this );
        this.handleMouseEnter = this.handleMouseEnter.bind( this );
        this.handleMouseDown = this.handleMouseDown.bind( this );
        this.handleMouseUp = this.handleMouseUp.bind( this );
        this.isDateSelected = this.isDateSelected.bind( this );
        this.startDateSelection = this.startDateSelection.bind( this );
        this.continueDateSelection = this.continueDateSelection.bind( this );
        this.clearDateSelection = this.clearDateSelection.bind( this );
        this.filterUsers = this.filterUsers.bind( this );
    }

    /**
     * TABLE PAGINATION EVENT HANDLERS
     */

    // Called if page changes on the table display occur
    handleChangeTablePage = (event, newPage) => {
        this.setState({tablePage: newPage});
    };
    
    // Called if the user selects a different amount of shown rows
    handleChangeTableRowsPerPage = (event) => {
        this.setState({tablePage: 0, tableRowsPerPage: +event.target.value});
    };

    /**
     * MOUSE INPUT HANDLERS
     */

    // Mouse hold down on cell
    handleMouseDown( event, month, day, hasAbsence, userid ) {
        event.stopPropagation();
        this.startDateSelection( month, day, true, hasAbsence, userid );
    }

    // Mouse release on cell
    handleMouseUp( event, month, day, hasAbsence, userid ) {
        event.stopPropagation();
        if( true === this.state.selectedDateRange.hasSelection && null != this.props.onModifyAbsence ) {
            this.props.onModifyAbsence( 
                this.state.selectedDateRange.start, 
                this.state.selectedDateRange.end, 
                this.state.selectedDateRange.hasAbsence,
                this.state.selectedDateRange.userid
            );
        }
        this.clearDateSelection();
    }

    // Mouse entering a cell
    handleMouseEnter( event, month, day, hasAbsence, userid ) {
        event.stopPropagation();
        if( true === this.state.mouseIsDown ) {
            this.continueDateSelection( month, day, hasAbsence, userid );
        }
    }

    /**
     * SELECTION HELPERS
     */
    
    // Check if given date is selected
    isDateSelected( userid, date ) {
    
        if( true === this.state.selectedDateRange.hasSelection ) {
            if( date >= this.state.selectedDateRange.start && date <= this.state.selectedDateRange.end && userid === this.state.selectedDateRange.userid ) {
                return true;
            }
        }
        return false;
    }

    // Start a date selection
    startDateSelection( month, day, mouseIsDown, hasAbsence, userid ) {
        const date = new Date( this.props.year, month-1, day ).getTime();

        let selectedDateRange = {
            hasSelection: true,
            start: date,
            end: date,
            prevDate: date,
            hasAbsence: hasAbsence,
            amountOfSelectedWorkDays: this.getAmountOfWorkDays( date, date ),
            userid: userid
        }

        this.setState({ mouseIsDown: mouseIsDown, selectedDateRange: selectedDateRange });
    }

    // Continue the started date selection
    continueDateSelection( month, day, hasAbsence, userid ) {
        // Make sure start date is always lower than end date in the struct
        if( true === this.state.selectedDateRange.hasSelection ) {
            let newStart = this.state.selectedDateRange.start;
            let newEnd = this.state.selectedDateRange.end;

            let newDate = new Date( this.props.year, month-1, day ).getTime();
            if( newDate > newEnd ) {
                newEnd = newDate;
            } else if( newDate < newStart ) {
                newStart = newDate;
            } else {
                // New date is inbetween existing start and end, adjust the end or the start
                // depending on where the user dragged from
                if( this.state.selectedDateRange.prevDate > newDate ) {
                    // User dragged from the right to the left
                    newEnd = newDate;
                } else if( this.state.selectedDateRange.prevDate < newDate ) {
                    // User dragged from the left to the right
                    newStart = newDate;
                }
            }

            const hasAnyAbsence = this.state.selectedDateRange.hasAbsence || hasAbsence ;

            let selectedDateRange = {
                hasSelection: true,
                start: newStart,
                end: newEnd,
                prevDate: newDate,
                hasAbsence: hasAnyAbsence,
                amountOfSelectedWorkDays: this.getAmountOfWorkDays( newStart, newEnd ),
                userid: this.state.selectedDateRange.userid
            }
            this.setState({ selectedDateRange: selectedDateRange });
        }
    }

    // Clear date selection
    clearDateSelection() {
        let selectedDateRange = {
            hasSelection: false,
            start: null,
            end: null,
            prevDate: null,
            hasAbsence: false,
            amountOfSelectedWorkDays: 0,
            userid: null
        }
        this.setState({ mouseIsDown: false, selectedDateRange: selectedDateRange });
    }

    /**
     * HELPERS
     */

    // Filter given list of users, removing the users which have no active contract
    // in the given year and month
    filterUsers( year, month, users, contracts ) {
        let filteredUsers = [];
        
        const minSearchDate = moment(new Date( year, month, 1)).format('YYYY-MM-DD');
        const maxSearchDate = moment(new Date( year, month )).endOf('month').format('YYYY-MM-DD');
        const searchRange = moment.range( minSearchDate, maxSearchDate );

        for( let i = 0; i < users.length; i++ ) {
            let userActive = false;

            for( let j = 0; j < contracts.length; j++ ) {
                if( contracts[j].userid === users[i].userid ) {
                    
                    // Check if we are looking at a contract which was active in the given month
                    const startDate = moment(contracts[j].date_start, 'YYYY-MM-DD');
                    let endDate = moment( "2999-12-31", 'YYYY-MM-DD' );
                    if( 'date_end' in contracts[j] && null !== contracts[j].date_end ) {
                        endDate = moment(contracts[j].date_end, 'YYYY-MM-DD');
                    }
                    const contractDateRange = moment.range( startDate, endDate );

                    if( true === contractDateRange.overlaps( searchRange ) ) {
                        userActive = true;
                        break;
                    }
                }
            }

            if( true === userActive ) {
                filteredUsers.push( users[i] );
            }
        }

        return filteredUsers;
    }

    // Get the amount of work days within the given date range
    getAmountOfWorkDays( startDate, endDate ) {
        if( null == this.props.contracts ) {
            return 0;
        }

        const dateRange = moment.range( startDate, endDate );

        // Iterate through date range
        let amountOfWorkDays = 0;
        
        for( let day of dateRange.by('day') ) {
            const dateObject = day.toDate();
            const holiday = CalendarHelper.isHoliday( this.props.holidays, dateObject );
            const isWorkDay = CalendarHelper.isWorkDay( this.props.contracts, null, dateObject );
            if( false === holiday.state && true === isWorkDay ) {
                amountOfWorkDays++;
            }
        }

        return amountOfWorkDays;
    }

    // Get details for a special
    getSpecialDetails( specialid ) {
        if( null == this.props.specials ) {
            return { name: null, short_name: null, color_code: null };
        }

        // Handle special case for specialid zero which occurs if the user is not allowed to see the respective absence
        if( 0 === specialid ) {
            let hiddenSpecial = config.specialIfHidden;
            hiddenSpecial.name = i18n.t("values.hidden");
            return hiddenSpecial;
        }

        for( let i = 0; i < this.props.specials.length; i++ ) {
            if( this.props.specials[i].specialid === specialid ) {
                return { name: this.props.specials[i].name, short_name: this.props.specials[i].short_name, color_code: this.props.specials[i].color_code };
            }
        }

        return { name: null, short_name: null, color_code: null };
    }

    // Render a table day cell
    renderTableCell( monthIndex, dayIndex, isWeekEnd, isToday, userid, content ) {

        // Check if day is selected
        const dateObject = new Date( this.props.year, monthIndex, dayIndex+1 );
        const date = dateObject.getTime();

        const isDateSelected = this.isDateSelected( userid, date );
        const holiday = CalendarHelper.isHoliday( this.props.holidays, dateObject );
        const absent = CalendarHelper.isAbsent( this.props.absences, userid, dateObject );
        const isWorkDay = CalendarHelper.isWorkDay( this.props.contracts, userid, dateObject );

        let cellTooltip = "";
        if( true === isToday ) {
            cellTooltip = this.props.labelToday;
        }

        let className = "";
        if( true === holiday.state ) {
            className = clsx(this.props.classes.cellDayBase, this.props.classes.cellHoliday);
            cellTooltip = holiday.name;
        } else {
            if( true === isWeekEnd ) {
                if( cellTooltip.length > 0 ) {
                    cellTooltip += " - ";
                }
                cellTooltip = this.props.labelWeekend;
                if( true === isToday ) {
                    className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekEnd);
                } else {
                    if( true === isWorkDay ) {
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellWeekEnd);
                    } else {
                        if( cellTooltip.length > 0 ) {
                            cellTooltip += " - ";
                        }
                        cellTooltip += this.props.labelNoWorkDay;
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellNoWorkDayWeekEnd);
                    }
                }
            } else {
                if( true === isToday ) {

                    if( true === isWorkDay ) {                    
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekDay);
                    } else {
                        if( cellTooltip.length > 0 ) {
                            cellTooltip += " - ";
                        }
                        cellTooltip += this.props.labelNoWorkDay;
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekDay, this.props.classes.cellNoWorkDayWeekDay);
                    }

                } else {
                    if( true === isWorkDay ) {
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellWeekDay);
                    } else {
                        if( cellTooltip.length > 0 ) {
                            cellTooltip += " - ";
                        }
                        cellTooltip += this.props.labelNoWorkDay;
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellNoWorkDayWeekDay);
                    }
                }
            }
        }

        // Change background color of cell depending on selection
        if( true === isDateSelected && false === holiday.state && true === isWorkDay ) {
            className = clsx(this.props.classes.cellDayBase, this.props.classes.cellSelected);
        }

        let absenceBox = "";
        if( true === absent.state ) {
            const specialDetails = this.getSpecialDetails( absent.specialid );
            const textColor = invertColorMoreContrast( specialDetails.color_code, true );
            absenceBox = (
                <Paper 
                    elevation={3}
                    square={true} 
                    className={this.props.classes.boxAbsent} 
                    style={{ backgroundColor: `#${specialDetails.color_code}`, color: `#${textColor}` }}
                >
                    {specialDetails.short_name}
                </Paper>
            )
        }

        let tableCellContent = "";

        if( true === isWorkDay && false === holiday.state ) {
            tableCellContent = (
                <TableCell
                    align="center"
                    key={"cell-date-"+monthIndex+"-"+dayIndex}
                    className={className}
                    onMouseDown={(event) => this.handleMouseDown(event, monthIndex+1, dayIndex+1, absent.state, userid)}
                    onMouseUp={(event) => this.handleMouseUp(event, monthIndex+1, dayIndex+1, absent.state, userid)}
                    onMouseEnter={(event) => this.handleMouseEnter(event, monthIndex+1, dayIndex+1, absent.state, userid)}
                >
                    <div style={{ width: "100%" }}>
                        {content}
                        {absenceBox}
                    </div>
                </TableCell>
            )
        } else {
            tableCellContent = (
                <TableCell
                    align="center"
                    key={"cell-date-"+monthIndex+"-"+dayIndex}
                    className={className}
                    onMouseUp={(event) => this.handleMouseUp(event, monthIndex+1, dayIndex+1, absent.state, userid)}
                    onMouseEnter={(event) => this.handleMouseEnter(event, monthIndex+1, dayIndex+1, absent.state, userid)}
                >
                    <div style={{ width: "100%" }}>
                        {content}
                        {absenceBox}
                    </div>
                </TableCell>
            )
        }

        if( cellTooltip !== "" ) {
            return (
                <Tooltip key={"tooltip-date-"+monthIndex+"-"+dayIndex} title={cellTooltip}>
                    {tableCellContent}
                </Tooltip>
            )
        } else {
            return tableCellContent;
        }
    }

    // Render company calendar for the given month
    renderCalendar( year, month, teamUsers, otherUsers ) {

        // Get some parameters for rendering
        const todayMonth = new Date().getMonth();
        const todayDate = new Date().getDate();
        const todayYear = new Date().getFullYear();
        const weekDaysInMonth = CalendarHelper.getWeekDaysForMonth( this.props.language, "short", year, month );

        // Slice user data according to table pagination
        const slicedOtherUsers = otherUsers.slice(this.state.tablePage * this.state.tableRowsPerPage, this.state.tablePage * this.state.tableRowsPerPage + this.state.tableRowsPerPage);

        let fullCurrentUserEntry = this.props.currentUser.lastname + ", " + this.props.currentUser.firstname;
        if( this.props.currentUser.userTypeName != null && this.props.currentUser.userTypeName !== "" ) {
            fullCurrentUserEntry = "(" + this.props.currentUser.userTypeName + ") " + this.props.currentUser.lastname + ", " + this.props.currentUser.firstname;
        }

        return (
            <TableContainer component={Paper} className={this.props.classes.container}>
                <Table className={this.props.classes.table}>
                    <TableHead key={"companycalendar-header"}>
                        <TableRow key={"companycalendar-header-row-1"}>
                            <TableCell key={"companycalendar-header-row-1-col-username"} align="center" className={this.props.classes.cellUserName}/>
                            {weekDaysInMonth.map((weekDay, dayIndex) => {
                                let isToday = false;
                                if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                    isToday = true;
                                }

                                if( true === isToday ) {
                                    return (
                                        <TableCell 
                                            key={"companycalendar-header-row-1-col-"+dayIndex} 
                                            align="center"
                                            className={this.props.classes.cellDayToday}
                                        >
                                            {dayIndex+1}<br/>{weekDay.name}
                                        </TableCell>
                                    )
                                } else {
                                    return (
                                        <TableCell 
                                            key={"companycalendar-header-row-1-col-"+dayIndex} 
                                            align="center"
                                            className={this.props.classes.cellDay}
                                        >
                                            {dayIndex+1}<br/>{weekDay.name}
                                        </TableCell>
                                    )
                                }
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={"companycalendar-user-row-"+this.props.currentUser.userid}>
                            <TableCell key={"companycalendar-user-row-"+this.props.currentUser.userid+"cell-name"} align="left" className={this.props.classes.cellUserName}>
                                {fullCurrentUserEntry}
                            </TableCell>
                            {weekDaysInMonth.map((weekDay, dayIndex) => {
                                let isToday = false;
                                if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                    isToday = true;
                                }

                                let isWeekEnd = false;
                                if( weekDay.index === 0 || weekDay.index === 6 ) {
                                    isWeekEnd = true;
                                }

                                return this.renderTableCell( month, dayIndex, isWeekEnd, isToday, this.props.currentUser.userid, "" );
                            })}
                        </TableRow>
                        {(teamUsers.length > 0) && (
                            <TableRow key={"companycalendar-user-row-divider-1"}>
                                <TableCell key={"companycalendar-user-row-divider-1-cell-name"} align="left" className={this.props.classes.cellDividerName} />
                                {weekDaysInMonth.map((weekDay, dayIndex) => {
                                    let isToday = false;
                                    if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                        isToday = true;
                                    }
                                    if( true === isToday ) {
                                        return (
                                            <TableCell key={"companycalendar-user-row-divider-1-cell-day-"+dayIndex} align="left" className={this.props.classes.cellDividerDayToday} />
                                        )
                                    } else {
                                        return (
                                            <TableCell key={"companycalendar-user-row-divider-1-cell-day-"+dayIndex} align="left" className={this.props.classes.cellDividerDay} />
                                        )
                                    }
                                })}
                            </TableRow>
                        )}
                        {teamUsers.map((user) => {
                            let fullUserEntry = user.lastname + ", " + user.firstname;
                            if( user.userTypeName != null && user.userTypeName !== "" ) {
                                fullUserEntry = "(" + user.userTypeName + ") " + user.lastname + ", " + user.firstname;
                            }
                            return (
                                <TableRow key={"companycalendar-user-row-"+user.userid}>
                                    <TableCell key={"companycalendar-user-row-"+user.userid+"cell-name"} align="left" className={this.props.classes.cellUserName}>
                                        {fullUserEntry}
                                    </TableCell>
                                    {weekDaysInMonth.map((weekDay, dayIndex) => {
                                        let isToday = false;
                                        if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                            isToday = true;
                                        }

                                        let isWeekEnd = false;
                                        if( weekDay.index === 0 || weekDay.index === 6 ) {
                                            isWeekEnd = true;
                                        }

                                        return this.renderTableCell( month, dayIndex, isWeekEnd, isToday, user.userid, "" );
                                    })}
                                </TableRow>
                            )
                        })}
                        {(slicedOtherUsers.length > 0) && (
                            <TableRow key={"companycalendar-user-row-divider-2"}>
                                <TableCell key={"companycalendar-user-row-divider-2-cell-name"} align="left" className={this.props.classes.cellDividerName} />
                                {weekDaysInMonth.map((weekDay, dayIndex) => {
                                    let isToday = false;
                                    if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                        isToday = true;
                                    }
                                    if( true === isToday ) {
                                        return (
                                            <TableCell key={"companycalendar-user-row-divider-2-cell-"+dayIndex} align="left" className={this.props.classes.cellDividerDayToday} />
                                        )
                                    } else {
                                        return (
                                            <TableCell key={"companycalendar-user-row-divider-2-cell-"+dayIndex} align="left" className={this.props.classes.cellDividerDay} />
                                        )
                                    }
                                })}
                            </TableRow>
                        )}
                        {slicedOtherUsers.map((user) => {
                            let fullUserEntry = user.lastname + ", " + user.firstname;
                            if( user.userTypeName != null && user.userTypeName !== "" ) {
                                fullUserEntry = "(" + user.userTypeName + ") " + user.lastname + ", " + user.firstname;
                            }
                            return (
                                <TableRow key={"companycalendar-user-row-"+user.userid}>
                                    <TableCell key={"companycalendar-user-row-"+user.userid+"cell-name"} align="left" className={this.props.classes.cellUserName}>
                                        {fullUserEntry}
                                    </TableCell>
                                    {weekDaysInMonth.map((weekDay, dayIndex) => {
                                        let isToday = false;
                                        if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                            isToday = true;
                                        }

                                        let isWeekEnd = false;
                                        if( weekDay.index === 0 || weekDay.index === 6 ) {
                                            isWeekEnd = true;
                                        }

                                        return this.renderTableCell( month, dayIndex, isWeekEnd, isToday, user.userid, "" );
                                    })}
                                </TableRow>
                            )
                        })}
                        <TableRow key={"companycalendar-user-row-divider-3"}>
                            <TableCell key={"companycalendar-user-row-divider-3-cell-name"} align="left" className={this.props.classes.cellDividerName} />
                            {weekDaysInMonth.map((weekDay, dayIndex) => {
                                let isToday = false;
                                if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                    isToday = true;
                                }
                                if( true === isToday ) {
                                    return (
                                        <TableCell key={"companycalendar-user-row-divider-2-cell-"+dayIndex} align="left" className={this.props.classes.cellDividerBottomDayToday} />
                                    )
                                } else {
                                    return (
                                        <TableCell key={"companycalendar-user-row-divider-2-cell-"+dayIndex} align="left" className={this.props.classes.cellDividerDay} />
                                    )
                                }
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    render() {
        let teamUsers = this.props.teamUsers;
        if( null == this.props.teamUsers ){
            teamUsers = [];
        }
        let otherUsers = this.props.otherUsers;
        if( null == this.props.otherUsers ){
            otherUsers = [];
        }

        // Filter list of teamUsers and otherUsers, if an user has no active contract within the selected month
        // do not even show him/her in the list
        if( null != teamUsers ) {
            teamUsers = this.filterUsers( this.props.year, this.props.month,teamUsers, this.props.contracts );
        }
        if( null != otherUsers ) {
            otherUsers = this.filterUsers( this.props.year, this.props.month,otherUsers, this.props.contracts );
        }

        // Render the calendar for the given year and year
        const calendar = this.renderCalendar( this.props.year, this.props.month, teamUsers, otherUsers );

        return (
            <div>
                <MouseTooltip
                    visible={this.state.selectedDateRange.hasSelection}
                    offsetX={15}
                    offsetY={10}
                >
                    <Paper className={this.props.classes.selectionTooltip}>
                        {i18n.t("values.days_selected", { days: this.state.selectedDateRange.amountOfSelectedWorkDays })}
                    </Paper>
                </MouseTooltip>
                {calendar}
                {this.props.children}
                <Grid container direction="row" justify="flex-end" className={this.props.classes.tablePaginationGrid} spacing={1} wrap='nowrap'>
                    <Grid item>
                        <TablePagination
                            className={this.props.classes.tablePagination}
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={otherUsers.length}
                            rowsPerPage={this.state.tableRowsPerPage}
                            labelRowsPerPage={i18n.t("table.pagination.rows_per_page")}
                            page={this.state.tablePage}
                            onChangePage={this.handleChangeTablePage}
                            onChangeRowsPerPage={this.handleChangeTableRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
};

export default withStyles(useStyles)(AbsenceCompanyCalendar);