import React, { Component } from 'react';
import ProjectService from '../../classes/ProjectService'
import UsersToTasksService from '../../classes/UsersToTasksService'
import UsersToProjectsService from '../../classes/UsersToProjectsService'
import UserService from '../../classes/UserService'
import UserTypeService from '../../classes/UserTypeService'
import TaskService from '../../classes/TaskService'
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { StyledTableCell, StyledTableRow, StyledTableHead, StyledTable, StyledTableSortLabel, stableSort, getComparator } from '../../components/StyledTable.js'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../components/PageTitle';
import Select from '@material-ui/core/Select';
import SelectControls from '../../components/SelectControls'
import ModalDialog from '../../components/ModalDialog';
import ModalDialogTextField from '../../components/ModalDialogTextField';
import Typography from '@material-ui/core/Typography';
import CustomTextField from '../../components/CustomTextField'
import CustomChipInput from '../../components/CustomChipInput';
import { StyledChipEnabled, StyledChipDisabled } from '../../components/StyledChip'
import PageSubtitle from '../../components/PageSubtitle'
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import DateOnly from '../../components/DateOnly';
import { config } from '../../config';
import i18n from 'i18next';
import queryString from 'query-string';

const useStyles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    mainGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    tableCellTextField: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1.0),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
    },
    tablePaginationGrid: {
        height: '60px',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tablePagination: {
        '& .MuiTablePagination-toolbar': {
            height: '60px',
            minHeight: '60px',
        },
    },
    chipInput: {
        width: '100%',
        height: '100%'
    },
    link: {
        color: "#ffffff",
        "&:hover": {
            color: "#ffffff"
        },
    }
});

/**
 * Admin Tasks
 */
class AdminTasks extends Component {
    // Set in constructor
    state = {}

    /**
     * Inputs Props
     * @param {*} props 
     *  admin (array)
     *  userProps (array)
     *  dataLoadChanged (function)
     *  alertChanged (function)
     */
    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleTableRowDeleteClick = this.handleTableRowDeleteClick.bind( this );
        this.handleChangeTablePage = this.handleChangeTablePage.bind( this );
        this.handleChangeTableRowsPerPage = this.handleChangeTableRowsPerPage.bind( this );
        this.handleChangeSelectField = this.handleChangeSelectField.bind( this );
        this.handleAddButtonClick = this.handleAddButtonClick.bind( this );
        this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind( this );
        this.handleAddDialog_Ok = this.handleAddDialog_Ok.bind( this );
        this.handleAddDialog_Cancel = this.handleAddDialog_Cancel.bind( this );
        this.handleTableRowDeleteDialog_Ok = this.handleTableRowDeleteDialog_Ok.bind( this );
        this.handleTableRowDeleteDialog_Cancel = this.handleTableRowDeleteDialog_Cancel.bind( this );
        this.handleConfirmStatusChangeDialog_Ok = this.handleConfirmStatusChangeDialog_Ok.bind( this );
        this.handleConfirmStatusChangeDialog_Cancel = this.handleConfirmStatusChangeDialog_Cancel.bind( this );
        this.handleModifyTaskMembers = this.handleModifyTaskMembers.bind( this );
        this.getDeleteButtonDataForTask = this.getDeleteButtonDataForTask.bind( this );
        this.handleTaskNameFieldChange = this.handleTaskNameFieldChange.bind( this );
        this.verifyTaskNameFieldContent = this.verifyTaskNameFieldContent.bind( this );
        this.handleStatusChange = this.handleStatusChange.bind( this );
        this.getUserLabel = this.getUserLabel.bind( this );
        this.getUserEnabled = this.getUserEnabled.bind( this );
        this.sortUserData = this.sortUserData.bind( this );
        this.verifyAddDialogTaskName_Content = this.verifyAddDialogTaskName_Content.bind( this );
        this.getUsersWithoutTask = this.getUsersWithoutTask.bind( this );
        this.getTasksOfProject = this.getTasksOfProject.bind( this );
        this.doesTaskExistForProject = this.doesTaskExistForProject.bind( this );
        this.handleRequestSort = this.handleRequestSort.bind( this );
        this.sortTableData = this.sortTableData.bind( this );
        this.getProject = this.getProject.bind( this );

        // Set initial state, a project id might have been given either as a prop or an argument
        let selectedProjectId = "";
        if( null != this.props.projectid ) {
            selectedProjectId = this.props.projectid;
        }
        if( null != this.props.args ) {
            const parsedArgs = queryString.parse( this.props.args );
            if( null != parsedArgs.projectid ) {
                selectedProjectId = parsedArgs.projectid;
            }
        }

        this.state = this.getInitialState( selectedProjectId );
    }

    /**
     * STATE HANDLING
     */

    // Get the initial state variables of this component
    getInitialState( selectedProjectId ) {
        return {
            data: { // Raw loaded data from the backend
                projects: null,
                users: null,
                userTypes: null,
                tasks: null,
                usersToTasks: null,
                usersToProjects: null
            },
            tableData: { // Additional information gathered from loaded raw data to display on the page
                projectSelection: [],
                usersForTask: [], // The names of the users of that task
                usersForProject: [], // The names of the users of that project
                deleteButtonsForTask: [] // If the delete button is enabled and which tooltip is shown for that project
            },
            selectedProjectId: selectedProjectId,
            tablePage: 0,
            tableRowsPerPage: 10,
            order: "asc",
            orderBy: 0,
            addDialog: {
                open: false
            },
            deleteDialog: {
                open: false,
                taskid: null,
                name: null
            },
            confirmStatusDialog: {
                open: false,
                taskid: null,
                newStatus: null
            }
        }
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState() {
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
        this.setState( this.getInitialState( this.state.selectedProjectId ) );
    }

    /**
     * LINK TO PARENT COMPONENT FUNCTIONS
     */

    // Propagate information to parent component
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Propagate information to parent component
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Propagate information to parent component
    handleActionInProgressChanged( actionInProgress ) {
        if( this.props.actionInProgressChanged ) {
            this.props.actionInProgressChanged( actionInProgress );
        }
    }

    /**
     * LOAD AND PROCESS BACKEND DATA
     */

    // Called when a refresh of the table data is needed due to edits in the table
    async refreshTableData() {
        await this.loadDataFromBackend( true );
    }

    // Gather all data from the backend needed on this page and process them properly
    async loadDataFromBackend( manualRefresh ) {
        // Alert message to show
        let alert = {
            severity: "info",
            message: ""
        }

        let projectData = null;
        let userData = null;
        let usersToTasksData = null;
        let usersToProjectsData = null;
        let taskData = null;

        // Gather project data
        {
            const { statusCode, statusText, resData } = await ProjectService.getProjects( {} );
            if( 200 === statusCode && null != resData ) {
                projectData = resData.getProjects;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Get users to projects data
        {
            const { statusCode, statusText, resData } = await UsersToProjectsService.getUsersToProjects( {} );
            if( 200 === statusCode && null != resData ) {
                usersToProjectsData = resData.getUsersToProjects;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Get users to tasks data
        {
            const { statusCode, statusText, resData } = await UsersToTasksService.getUsersToTasks( {} );
            if( 200 === statusCode && null != resData ) {
                usersToTasksData = resData.getUsersToTasks;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather user data
        {
            const { statusCode, statusText, resData } = await UserService.getUsers( {} );
            if( 200 === statusCode && null != resData ) {
                userData = resData.getUsers;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        let userTypeData = null;

        // Gather user type data
        {
            const { statusCode, statusText, resData } = await UserTypeService.getUserTypes( {} );
            if( 200 === statusCode && null != resData ) {
                userTypeData = resData.getUserTypes;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather task data
        {
            const { statusCode, statusText, resData } = await TaskService.getTasks( {} );
            if( 200 === statusCode && null != resData ) {
                taskData = resData.getTasks;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Now process the data
        await this.processData( projectData, userData, userTypeData, taskData, usersToTasksData, usersToProjectsData );

        // All fine, clear the error message (only if not a manual refresh)
        if( false === manualRefresh ) {
            this.handleAlertChanged( alert );
        }
        this.handleDataLoadedChanged( true );
    }

    // Called to make additional processing of data, for example gathering info from that backend data and storing it for later use
    async processData( projectData, userData, userTypeData, taskData, usersToTasksData, usersToProjectsData ) {

        // Abort if no proper data given
        if( null == projectData || null == userData || null == userTypeData || null == usersToTasksData || null == taskData || null == usersToProjectsData ) {
            return;
        }

        // Add user type name to all user data entries
        for( let i = 0; i < userData.length; i++ ) {
            let userTypeName = "";
            for( let j = 0; j < userTypeData.length; j++ ) {
                if( userTypeData[j].typeid === userData[i].typeid ) {
                    userTypeName = userTypeData[j].name;
                    break;
                }
            }
            userData[i].userTypeName = userTypeName;
        }

        // Get the info from projectData if there are currently any other links attached
        // and enable/disable the delete button accordingly
        let deleteButtonsArray = [];

        for( let i = 0; i < taskData.length; i++ ) {

            let deleteButtonElement = {
                taskid: taskData[i].taskid,
                enabled: false,
                tooltip: i18n.t("pages.admin.tasks.delete.button.tooltip.disabled"),
                links: []
            }

            if( false === taskData[i].has_accountings && false === taskData[i].has_users ) {
                deleteButtonElement.enabled = true;
                deleteButtonElement.tooltip = i18n.t("pages.admin.tasks.delete.button.tooltip.enabled");
            } else {

                // Attached links detected, save them for later to display them to the user
                if( true === taskData[i].has_accountings ) { deleteButtonElement.links.push( i18n.t("pages.admin.tasks.delete.button.tooltip.links.accountings") ) };
                if( true === taskData[i].has_users ) { deleteButtonElement.links.push( i18n.t("pages.admin.tasks.delete.button.tooltip.links.users") ) };
            }

            deleteButtonsArray[i] = deleteButtonElement;
        }

        // Add the information if there is currently an user assigned to this project or not
        // for enabling or disabling the delete button, doing this now speeds up table page changes later on
        let usersForTaskArray = [];

        for( let i = 0; i < taskData.length; i++ ) {
            let membersFound = [];

            for (let j = 0; j < usersToTasksData.length; j++ ) {
                if( usersToTasksData[j].taskid === taskData[i].taskid ) {
                    // User at the specified project found
                    let user = null;

                    for( let k = 0; k < userData.length; k++ ) {
                        if( userData[k].userid === usersToTasksData[j].userid ) {
                            user = userData[k];
                            break;
                        }
                    }

                    membersFound.push( user );
                }
            }

            // Sort array by lastname
            membersFound.sort(function(a, b) { return a.lastname > b.lastname ? 1 : -1; });

            // Add the processed info
            let usersElement = {
                taskid: taskData[i].taskid,
                members: membersFound
            }

            // Add info to the final arrays
            usersForTaskArray[i] = usersElement;
        }

        // Add information which users are on which project
        let usersForProjectArray = [];

        for( let i = 0; i < projectData.length; i++ ) {
            let membersFound = [];

            for (let j = 0; j < usersToProjectsData.length; j++ ) {
                if( usersToProjectsData[j].projectid === projectData[i].projectid ) {
                    // User at the specified project found
                    let user = null;

                    for( let k = 0; k < userData.length; k++ ) {
                        if( userData[k].userid === usersToProjectsData[j].userid ) {
                            user = userData[k];
                            break;
                        }
                    }

                    membersFound.push( user );
                }
            }

            // Sort array by lastname
            membersFound.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.userTypeName > b.userTypeName ) return 1;
                if( a.userTypeName < b.userTypeName ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });

            // Add the processed info
            let usersElement = {
                projectid: projectData[i].projectid,
                members: membersFound
            }

            // Add info to the final arrays
            usersForProjectArray[i] = usersElement;
        }

        // Populate projectSelection state variable
        // If the current user is only projectlead of some of the projects filter this list accordingly
        let projectSelection = [];

        for( let i = 0; i < projectData.length; i++ ) {
            let isProjectLead = false;
            if( null != this.props.adminFlags ) {
                if( ( null != this.props.adminFlags.projects && true === this.props.adminFlags.projects ) ||
                ( null != this.props.adminFlags.tasks && true === this.props.adminFlags.tasks ) ) {
                    isProjectLead = true;
                } else if( null != this.props.adminFlags.projectlead && 0 < this.props.adminFlags.projectlead.length ) {
                    for( let j = 0; j < this.props.adminFlags.projectlead.length; j++ ) {
                        if( projectData[i].projectid === this.props.adminFlags.projectlead[j] ) {
                            isProjectLead = true;
                            break;
                        }
                    }
                } 
            }
            if( true === isProjectLead ) {
                // As a value entry use {internal_id}-{name} here since this value will show up in the select menu
                let value = `${projectData[i].internal_id} - ${projectData[i].name}`;
                projectSelection.push( { id: projectData[i].projectid, value: value });
            }
        }
        projectSelection.sort(function(a, b) { return a.value > b.value ? 1 : -1; });

        // Store the data
        this.setState({ 
            data: { projects: projectData, users: userData, userTypes: userTypeData, tasks: taskData, usersToTasks: usersToTasksData }, 
            tableData: { projectSelection: projectSelection, usersForTask: usersForTaskArray, usersForProject: usersForProjectArray, deleteButtonsForTask: deleteButtonsArray } 
        });
    }

    /**
     * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
     */

    // Called by react when this component has been mounted
    async componentDidMount() {
        await this.loadDataFromBackend();
    }

    // Called by react before this component unmounts
    componentWillUnmount() {
        this.resetState();
    }

    /**
     * ALERT DIALOG <ADD>
     */

    // Called to open the add dialog
    openAddDialog() {
        let addDialog = {
            open: true
        }
        this.setState({ addDialog: addDialog });
    }

    // Called by add dialog to verify content
    verifyAddDialogTaskName_Content( content ) {
        return this.verifyTaskNameFieldContent( this.state.selectedProjectId, 0, content.value );
    }

    // Called when the user clicked "Ok" on the add task dialog
    // content: { name }
    async handleAddDialog_Ok( content ) {
        this.closeAddDialog();

        // Check data validity
        if( content == null ) {
            return;
        }
        if( content.value == null || content.value === "" ) {
            return;
        }

        const { statusCode, statusText } = await TaskService.createTask( { 
            name: content.value,
            status: config.enums.taskStatus[0].id,
            projectid: this.state.selectedProjectId
        } ); 

        let alert = {
            severity: "info",
            message: ""
        }

        if( 200 === statusCode ) {
            alert.severity = "success";
            alert.message = i18n.t( "pages.admin.tasks.add.dialog.alert.success" );
        } else {
            alert.severity = "error";
            alert.message = `${statusText}: ${statusCode}`;
        }

        this.handleAlertChanged( alert );

        // Refresh the table data
        await this.refreshTableData();
    }

    // Called when the user clicked "Cancel" or close on the add role dialog
    handleAddDialog_Cancel() {
        this.closeAddDialog();
    }

    // Close the modify alert dialog
    closeAddDialog() {
        let addDialog = {
            open: false
        }
        this.setState({ addDialog: addDialog });
    }

    /**
     * MODIFY ASSIGNED TASKS MEMBERS
     */

    // Called when the user clicked "Ok" on the confirm row delete dialog
    async handleModifyTaskMembers( taskid, content ) {

        // Check data validity
        if( content == null ) {
            return;
        }
        if( content.selectedOptions == null ) {
            return;
        }
        if( content.unselectedOptions == null ) {
            return;
        }

        // Content will include arrays in no specific order
        let usersToTaskCmd = [];

        // Get currently assigned users for project
        const users = this.getMembersOfTask( taskid );

        // Check if users have been added to the project as a member
        for( let i = 0; i < content.selectedOptions.length; i++ ) {
            var userAlreadyAssignedToTask = false;
            for( let j = 0; j < users.length; j++ ) {
                if( users[j].userid === content.selectedOptions[i].userid ) {
                    userAlreadyAssignedToTask = true;
                    break;
                }
            }

            if( false === userAlreadyAssignedToTask ) {
                // User has been added to the project
                usersToTaskCmd.push( { cmd: "add", userid: content.selectedOptions[i].userid } );
            }
        }

        // Check if users have been removed from the project
        for( let i = 0; i < content.unselectedOptions.length; i++ ) {
            var removeUserFromTask = false;
            for( let j = 0; j < users.length; j++ ) {
                if( users[j].userid === content.unselectedOptions[i].userid ) {
                    removeUserFromTask = true;
                    break;
                }
            }

            if( true === removeUserFromTask ) {
                // User has been removed from the role
                usersToTaskCmd.push( { cmd: "delete", userid: content.unselectedOptions[i].userid } );
            }
        }

        // Perform commands on the API
        let combinedResult = {
            statusCode: 200,
            statusText: ""
        }

        if( 0 < usersToTaskCmd.length ) {
        
            for( let i = 0; i < usersToTaskCmd.length; i++ ) {
                let result = {}

                if( "add" === usersToTaskCmd[i].cmd ) {
                    const { statusCode, statusText, resData } = await UsersToTasksService.addUserToTask( { taskid: taskid, userid: usersToTaskCmd[i].userid } );
                    result = {
                        statusCode: statusCode,
                        statusText: statusText,
                        resData: resData
                    }
                } else {
                    const { statusCode, statusText, resData } = await UsersToTasksService.deleteUserToTask( { taskid: taskid, userid: usersToTaskCmd[i].userid } );
                    result = {
                        statusCode: statusCode,
                        statusText: statusText,
                        resData: resData
                    }
                }

                if( 200 !== result.statusCode ) {
                    combinedResult.statusCode = result.statusCode;
                    combinedResult.statusText = result.statusText;
                }
            }

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === combinedResult.statusCode ) {
                alert.severity = "success";
                alert.message = i18n.t( "pages.admin.tasks.modify.alert.success" );
            } else {
                alert.severity = "error";
                alert.message = `${combinedResult.statusText}: ${combinedResult.statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    /**
     * ALERT DIALOG <DELETE>
     */

    // Called to open the delete dialog
    openTableRowDeleteDialog( taskid, name, internal_id ) {
        let deleteDialog = {
            open: true,
            taskid: taskid,
            name: name,
            internal_id: internal_id
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    // Called when the user clicked "Ok" on the confirm row delete dialog
    async handleTableRowDeleteDialog_Ok( taskid ) {
        this.closeTableRowDeleteDialog();

        // Delete the project
        if( null != taskid ) {
            const { statusCode, statusText, resData } = await TaskService.deleteTask( { taskid: taskid } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                if( true === resData.deleteTask.result ) {
                    alert.severity = "success";
                    alert.message = i18n.t( "pages.admin.tasks.delete.dialog.alert.success" );
                } else {
                    alert.severity = "error";
                    alert.message = `${statusText}: ${statusCode}`;
                }
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the confirm row delete dialog
    async handleTableRowDeleteDialog_Cancel() {
        this.closeTableRowDeleteDialog();
    }

    // Close the delete alert dialog
    closeTableRowDeleteDialog() {
        let deleteDialog = {
            open: false,
            taskid: this.state.deleteDialog.taskid,
            name: this.state.deleteDialog.name
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    /**
     * ALERT DIALOG <CONFIRM STATUS CHANGE>
     */

    // Called to open the dialog
    openConfirmStatusChangeDialog( taskid, projectid, newStatus ) {
        let confirmStatusDialog = {
            open: true,
            taskid: taskid,
            projectid: projectid,
            newStatus: newStatus
        }
        this.setState({ confirmStatusDialog: confirmStatusDialog });
    }

    // Called when the user clicked "Ok" on the dialog
    async handleConfirmStatusChangeDialog_Ok( taskid, projectid, newStatus ) {
        this.closeConfirmStatusChangeDialog();

        // Update the project of the task and set it to open
        if( config.enums.taskStatus[0].id === newStatus ) {
            const projectOfTask = this.getProject( projectid );
            if( null !== projectOfTask ) {
                if( config.enums.projectStatus[0].id !== projectOfTask.status ) {

                    // Closed project found, re-open it
                    await ProjectService.updateProject( { projectid: projectid }, { status: config.enums.projectStatus[0].id } );
                }
            }
        }

        // Update the task
        if( null != taskid ) {
            const status = newStatus;

            const { statusCode, statusText, resData } = await TaskService.updateTask( { taskid: taskid }, { status: status } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.tasks.alert.success.update_status");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the confirm row delete dialog
    async handleConfirmStatusChangeDialog_Cancel() {
        this.closeConfirmStatusChangeDialog();
    }

    // Close the delete alert dialog
    closeConfirmStatusChangeDialog() {
        let confirmStatusDialog = {
            open: false,
            taskid: this.state.confirmStatusDialog.taskid,
            projectid: this.state.confirmStatusDialog.projectid,
            newStatus: this.state.confirmStatusDialog.newStatus
        }
        this.setState({ confirmStatusDialog: confirmStatusDialog });
    }

    /**
     * CALLBACK FUNCTIONS FOR SEARCH FIELD
     */

    // Called when a project is selected in the search field
    handleChangeSelectField(value) {
        this.setState({ selectedProjectId: value });
    }

    /**
     * CALLBACK FUNCTIONS OF TABLE ELEMENTS
     */

    // Called when a delete button of an existing project is clicked within the table
    handleTableRowDeleteClick(event, taskid, name) {
        event.stopPropagation();
        this.openTableRowDeleteDialog( taskid, name );
    }

    /**
     * TABLE PAGINATION EVENT HANDLERS
     */

    // Called if page changes on the table display occur
    handleChangeTablePage = (event, newPage) => {
        this.setState({tablePage: newPage});
    };
    
    // Called if the user selects a different amount of shown rows
    handleChangeTableRowsPerPage = (event) => {
        this.setState({tablePage: 0, tableRowsPerPage: +event.target.value});
    };

    /**
     * TOP ROW BUTTONS
     */

    // Called if the add button is clicked
    async handleAddButtonClick() {
        this.openAddDialog();
    }

    // Called if the refresh button is clicked
    async handleRefreshButtonClick() {
        this.resetState();
        await this.refreshTableData();
    }

    /**
     * TEXT FIELDS FOR TASK NAME
     */

    // Handle when a task name text field is changed
    async handleTaskNameFieldChange(event, taskid, newValue) {
        event.stopPropagation();

        // Update the task
        if( null != taskid ) {
            const { statusCode, statusText, resData } = await TaskService.updateTask( { taskid: taskid }, { name: newValue } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.tasks.alert.success.update_name");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called by the custom text field displaying the project names to verify their content
    verifyTaskNameFieldContent( projectid, textFieldTaskId, textFieldValue ) {
        let result = {
            state: false,
            msg: i18n.t("pages.admin.tasks.add.dialog.alert.task_name_empty")
        }
        
        if( null != textFieldValue ) {
            if( textFieldValue !== "" ) {
                const { exists, taskid } = this.doesTaskExistForProject( projectid, textFieldValue );
                if( true === exists && taskid !== textFieldTaskId ) {
                    result = {
                        state: false,
                        msg: i18n.t("pages.admin.tasks.add.dialog.alert.task_does_already_exist")
                    }
                } else {
                    result = {
                        state: true,
                        msg: ""
                    }
                }
            }
        }
        return result;
    }

    /**
     * SELECTOR FOR TASK STATUS
     */

    // Handle when a task status selector is changed
    async handleStatusChange(event, projectid, taskid) {
        event.stopPropagation();

        // Get the new desired status
        const status = event.target.value;

        // In case the user wants to set the task to "open" status check if the project is currently not open
        // in that case we show a confirm dialog first
        if( config.enums.taskStatus[0].id === status ) {

            const projectOfTask = this.getProject( projectid );
            if( null !== projectOfTask ) {
                if( config.enums.projectStatus[0].id !== projectOfTask.status ) {
                    // Project is not open, show the dialog instead of modifying the task status
                    this.openConfirmStatusChangeDialog( taskid, projectid, event.target.value );
                    return;
                }
            }
        }

        // Update the task
        if( null != taskid ) {
            const { statusCode, statusText, resData } = await TaskService.updateTask( { taskid: taskid }, { status: status } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.tasks.alert.success.update_status");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    /**
     * HELPERS CALLED DURING RENDER TO RETRIEVE INFO FROM PROCESSED DATA
     */

    // Get the textual representation of an user value
    getUserLabel( value ) {
        let result = "";
        if( null != value ) {
            let userTypeName = "";
            if( value.userTypeName !== "" && value.userTypeName != null ) {
                userTypeName = "(" + value.userTypeName + ") ";
            }
            result = `${userTypeName}${value.lastname}, ${value.firstname}`
        }
        return result;
    }

    // Get the color class representation of an user value
    getUserEnabled( value ) {
        if( null != value ) {
            if( value.account_enabled ) {
                return true;
            } else {
                return false
            }
        }
        return false;
    }

    // Sort given users for task data
    sortUserData( data ) {
        let result = [];
        if( null != data ) {
            result = data;
            result.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.userTypeName > b.userTypeName ) return 1;
                if( a.userTypeName < b.userTypeName ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });
        }
        return result;
    }

    // Get all tasks for a certain project
    getTasksOfProject( projectid ) {
        let tasks = [];

        if( null != this.state.data.tasks ) {
            for( let i = 0; i < this.state.data.tasks.length; i++ ) {
                if( this.state.data.tasks[i].projectid === projectid ) {
                    tasks.push( this.state.data.tasks[i] );
                }
            }
        }

        return tasks;
    }

    // Get a certain project
    getProject( projectid ) {
        let project = null;

        if( null != this.state.data.projects ) {
            for( let i = 0; i < this.state.data.projects.length; i++ ) {
                if( this.state.data.projects[i].projectid === projectid ) {
                    project = this.state.data.projects[i];
                    break;
                }
            }
        }

        return project;
    }

    // Check if task name already exists within given project
    doesTaskExistForProject( projectid, taskname ) {
        for( let i = 0; i < this.state.data.tasks.length; i++ ) {
            if( this.state.data.tasks[i].projectid === projectid && this.state.data.tasks[i].name === taskname ) {
                return { exists: true, taskid: this.state.data.tasks[i].taskid };
            }
        }
        return false;
    }

    // Get the members for a task
    getMembersOfTask( taskid ) {

        if( taskid !== null ) {
            // Retrieve info from array
            for( let i = 0; i < this.state.tableData.usersForTask.length; i++ ) {
                if( this.state.tableData.usersForTask[i].taskid === taskid ) {
                    return this.state.tableData.usersForTask[i].members;
                }
            }
        } else {
            // No task id given, return all users who are member of this project
            for( let i = 0; i < this.state.tableData.usersForProject.length; i++ ) {
                if( this.state.tableData.usersForProject[i].projectid === this.state.selectedProjectId ) {
                    return this.state.tableData.usersForProject[i].members;
                }
            }
        }

        // Fallback empty data
        return [];
    }

    // Get all users not assigned to any task
    getUsersWithoutTask() {
        let usersFound = [];

        if( null != this.state.data.users && null != this.state.data.usersToTasks ) {
            let users = this.state.data.users;
            let usersToTasks = this.state.data.usersToTasks;

            for( let i = 0; i < users.length; i++ ) {
                let userHasNoTask = true;
                for( let j = 0; j < usersToTasks.length; j++ ) {
                    if( users[i].userid === usersToTasks[j].userid ) {
                        userHasNoTask = false;
                        break;
                    }
                }

                if( true === userHasNoTask ) {
                    usersFound.push( users[i] );
                }
            }
        }

        usersFound.sort(function(a, b) { 
            // Sort by user type first, then by user lastname
            if( a.userTypeName > b.userTypeName ) return 1;
            if( a.userTypeName < b.userTypeName ) return -1;
            if( a.lastname > b.lastname ) return 1;
            if( a.lastname < b.lastname ) return -1;
            return 0;
        });
        return usersFound;
    }

    // Get delete button info for a task
    getDeleteButtonDataForTask( taskid ) {
        // Retrieve info from array
        for( let i = 0; i < this.state.tableData.deleteButtonsForTask.length; i++ ) {
            if( this.state.tableData.deleteButtonsForTask[i].taskid === taskid ) {
                return this.state.tableData.deleteButtonsForTask[i];
            }
        }
        // Fallback empty data
        return { taskid: taskid, enabled: false, tooltip: i18n.t("pages.admin.tasks.delete.button.tooltip.disabled"), links: [] };
    }

    /**
     * DATA SORT
     */

    // Handle table sort
    handleRequestSort( event, property ) {

        // Check if clicked on same property
        if( this.state.orderBy === property ) {

            // User clicked on same property
            // If already sorted in ascending order, switch to descending order
            if( this.state.order === 'asc' ) {
                this.setState({ order: 'desc', orderBy: property });
            } else {

                // If already sorted in descending order, switch to no order
                this.setState({ order: 'asc', orderBy: 0 });
            }

        } else {
            // User clicked on new property, sort new property in ascending order
            this.setState({ order: 'asc', orderBy: property });
        }
    };

    // Sort the given table data
    sortTableData( tasksOfProject ) {
        // Handle case where no ordering is selected
        if( this.state.orderBy === 0 ) {
            return tasksOfProject.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
        }
        return stableSort( tasksOfProject, getComparator(this.state.order, this.state.orderBy) );
    }

    /**
     * MAIN RENDER FUNCTION
     */
    render() {
        const { classes } = this.props;

        // Only display data if it has already been loaded
        let contentTable = "";
        let usersWithoutTaskContent = "";

        // Make sure if we got the data already
        if( null != this.state.data.tasks ) {

            // Assemble table content
            const tableHeader = [
                { key: "name", span: 1, align: "left", sortable: true, style: {}, text: i18n.t("pages.admin.tasks.table.header.name") },
                { key: "create_date", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.tasks.table.header.create_date") },
                { key: "status", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.tasks.table.header.status") },
                { key: "status_change_date", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.tasks.table.header.status_change_date") },
                { key: "members", span: 1, align: "center", sortable: false, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.tasks.table.header.members") },
                { key: "controls", span: 1, align: "center", sortable: false, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.tasks.table.header.controls") }
            ];

            // Sort tasks of selected project by name
            let tasksOfProject = this.getTasksOfProject( this.state.selectedProjectId );

            if( null == tasksOfProject ) {
                tasksOfProject = [];
            }

            // Sort table entries by given properties
            tasksOfProject = this.sortTableData( tasksOfProject );

            // Retrieve all members of that project no matter if on a task or not
            const allProjectMembers = this.getMembersOfTask( null );

            contentTable = (
                <div>
                    <TableContainer component={Paper}>
                        <StyledTable size="small" >
                            <StyledTableHead>
                                <StyledTableRow key={"tasks-header-first"}>
                                    {tableHeader.map((col) => (
                                        <StyledTableCell key={col.key} colSpan={col.span} align={col.align} style={col.style}>
                                            <StyledTableSortLabel
                                                active={this.state.orderBy === col.key}
                                                hideSortIcon={false}
                                                direction={this.state.orderBy === col.key ? this.state.order : 'asc'}
                                                onClick={(event) => this.handleRequestSort(event, col.key)}
                                                disabled={!(col.sortable)}
                                            >
                                                {col.text}
                                            </StyledTableSortLabel>
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </StyledTableHead>
                            <TableBody>
                                {tasksOfProject.slice(this.state.tablePage * this.state.tableRowsPerPage, this.state.tablePage * this.state.tableRowsPerPage + this.state.tableRowsPerPage).map((task) => {
                                    const membersOfTask = this.getMembersOfTask( task.taskid );
                                    const deleteButtonForTask = this.getDeleteButtonDataForTask( task.taskid );

                                    return (
                                        <StyledTableRow key={task.taskid} taskid={task.taskid}>
                                            <StyledTableCell className={classes.tableCellTextField} key="name" width="20%">
                                                <CustomTextField
                                                    maxLength={config.maxTextLengths.task.name}
                                                    textFieldValue={task.name} 
                                                    verifyContent={(content) => this.verifyTaskNameFieldContent(task.projectid, task.taskid, content)} 
                                                    onApply={(event, newValue) => this.handleTaskNameFieldChange(event, task.taskid, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="create_date" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1, minWidth: "15%" }} onClick={(event) => event.stopPropagation()}>
                                                <DateOnly language={this.context.language} value={task.create_date} align="center" variant="body1" />
                                            </StyledTableCell>
                                            <StyledTableCell key="status" align="center" padding="checkbox" width="5%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <Select
                                                    id="status"
                                                    value={task.status}
                                                    onChange={(event) => this.handleStatusChange(event, task.projectid, task.taskid)}
                                                    name="status"
                                                    >
                                                    {config.enums.taskStatus.map((entry) => (
                                                        <MenuItem key={entry.id} value={entry.id}>{i18n.t(entry.name)}</MenuItem>
                                                    ))}
                                                </Select>
                                            </StyledTableCell>
                                            <StyledTableCell key="status_change_date" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1, minWidth: "15%" }} onClick={(event) => event.stopPropagation()}>
                                                <DateOnly language={this.context.language} value={task.status_change_date} align="center" variant="body1" />
                                            </StyledTableCell>
                                            <StyledTableCell key="members" width="40%" style={{ borderStyle: "solid", borderLeftWidth: 1 }}>
                                                <CustomChipInput
                                                    className={classes.chipInput}
                                                    getOptionLabel={(value) => this.getUserLabel(value)}
                                                    getOptionEnabled={(value) => this.getUserEnabled(value)}
                                                    getAllOptions={() => allProjectMembers} 
                                                    getSelectedOptions={() => membersOfTask} 
                                                    sortOptions={(data) => this.sortUserData(data)}
                                                    noOptionsText={i18n.t("pages.admin.tasks.modify.dialog.task_members.no_options")}
                                                    onApply={(content) => this.handleModifyTaskMembers( task.taskid, content )}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="delete" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                            <Tooltip 
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit" variant="caption">{deleteButtonForTask.tooltip}</Typography>
                                                            <Typography color="inherit" variant="caption">
                                                                {deleteButtonForTask.links.map((link, index) => (
                                                                    <div key={index}>* {link}</div>
                                                                ))}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.clearButton}
                                                            disabled={!(deleteButtonForTask.enabled)}
                                                            onClick={(event) => this.handleTableRowDeleteClick(event, task.taskid, task.name)}
                                                            style={{ height: "30px", width: "45px" }}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                    <Grid container direction="row" justify="flex-end" className={classes.tablePaginationGrid} spacing={1} wrap='nowrap'>
                        <Grid item>
                            <TablePagination
                                className={classes.tablePagination}
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={tasksOfProject.length}
                                rowsPerPage={this.state.tableRowsPerPage}
                                labelRowsPerPage={i18n.t("table.pagination.rows_per_page")}
                                page={this.state.tablePage}
                                onChangePage={this.handleChangeTablePage}
                                onChangeRowsPerPage={this.handleChangeTableRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                    <ModalDialog 
                        open={this.state.addDialog.open}
                        title={i18n.t("pages.admin.tasks.add.dialog.title")}
                        descText={i18n.t("pages.admin.tasks.add.dialog.description")}
                        buttonLeft={i18n.t("pages.admin.tasks.add.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.tasks.add.dialog.button_right")}
                        handleClickClose={() => this.handleAddDialog_Cancel()}
                        handleClickLeft={(content) => this.handleAddDialog_Ok(content)}
                        handleClickRight={() => this.handleAddDialog_Cancel()}
                    >
                        <ModalDialogTextField 
                            maxLength={config.maxTextLengths.task.name}
                            label={i18n.t("pages.admin.tasks.add.dialog.field_taskname")} 
                            verifyContent={(content) => this.verifyAddDialogTaskName_Content(content)} 
                            autoFocus={false}
                        />
                    </ModalDialog>
                    <ModalDialog 
                        open={this.state.deleteDialog.open}
                        title={i18n.t("pages.admin.tasks.delete.dialog.title")}
                        descText={i18n.t("pages.admin.tasks.delete.dialog.description", { name: this.state.deleteDialog.name })}
                        buttonLeft={i18n.t("pages.admin.tasks.delete.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.tasks.delete.dialog.button_right")}
                        handleClickClose={() => this.handleTableRowDeleteDialog_Cancel()}
                        handleClickLeft={() => this.handleTableRowDeleteDialog_Ok( this.state.deleteDialog.taskid )}
                        handleClickRight={() => this.handleTableRowDeleteDialog_Cancel()}
                    />
                    <ModalDialog 
                        open={this.state.confirmStatusDialog.open}
                        title={i18n.t("pages.admin.tasks.status_open.dialog.title")}
                        descText={i18n.t("pages.admin.tasks.status_open.dialog.description")}
                        buttonLeft={i18n.t("pages.admin.tasks.status_open.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.tasks.status_open.dialog.button_right")}
                        handleClickClose={() => this.handleConfirmStatusChangeDialog_Cancel()}
                        handleClickLeft={() => this.handleConfirmStatusChangeDialog_Ok( this.state.confirmStatusDialog.taskid, this.state.confirmStatusDialog.projectid, this.state.confirmStatusDialog.newStatus )}
                        handleClickRight={() => this.handleConfirmStatusChangeDialog_Cancel()}
                    />
                </div>
            )

            // Retrieve all users which are not on any task
            const allUsersWithoutTask = this.getUsersWithoutTask();

            let usersWithoutTaskList = i18n.t("pages.admin.tasks.unassigned_users.none");

            if( 0 < allUsersWithoutTask.length ) {
                usersWithoutTaskList = (
                    <div>
                        {allUsersWithoutTask.map(function(user) {
                            let fullUserEntry = user.lastname + ", " + user.firstname;
                            if( user.userTypeName != null && user.userTypeName !== "" ) {
                                fullUserEntry = "(" + user.userTypeName + ") " + user.lastname + ", " + user.firstname;
                            }
                            if( user.account_enabled ) {
                                return (
                                    <StyledChipEnabled key={"chip-enabled-"+user.userid} label={fullUserEntry} />
                                )
                            } else {
                                return (
                                    <Tooltip key={"chip-tooltip-"+user.userid} title={i18n.t("tooltips.user_disabled")}>
                                        <StyledChipDisabled key={"chip-disabled-"+user.userid} label={fullUserEntry} />
                                    </Tooltip>
                                )
                            }
                        })}
                    </div>
                )
            }

            usersWithoutTaskContent = (
                <div>
                    <PageSubtitle title={i18n.t("pages.admin.tasks.unassigned_users.headline")} />
                    {usersWithoutTaskList}
                </div>
            )
        }

        // Enable or disable the add button depending on if a project has been selected
        let addButtonTooltip = i18n.t("pages.admin.tasks.add.button.tooltip.enabled");
        let disableAddButton = false;
        if( this.state.selectedProjectId == null || this.state.selectedProjectId === "" ) {
            addButtonTooltip = i18n.t("pages.admin.tasks.add.button.tooltip.disabled");
            disableAddButton = true;
        }

        // Some elements might be set from the parent page
        let pageTitle = i18n.t("pages.admin.tasks.title");
        if( null != this.props.pageTitle ) {
            pageTitle = this.props.pageTitle;
        }

        let pageDesc = i18n.t("pages.admin.tasks.desc");
        if( null != this.props.pageDesc ) {
            pageDesc = this.props.pageDesc;
        }

        // Handle case where projectSelection has not yet been loaded
        let selectedProjectId = this.state.selectedProjectId;
        if( null == this.state.tableData.projectSelection || 0 === this.state.tableData.projectSelection.length ) {
            selectedProjectId = "";
        }

        return (
            <React.Fragment>
                <PageTitle title={pageTitle} />
                <p>{pageDesc}</p>
                <SelectControls
                    selectedEntry={selectedProjectId}
                    label={i18n.t("pages.admin.tasks.search.label")}
                    helperText={i18n.t("pages.admin.tasks.search.subtext")}
                    addTooltip={addButtonTooltip}
                    refreshTooltip={i18n.t("buttons.refresh.tooltip")}
                    items={this.state.tableData.projectSelection}
                    onAdd={this.handleAddButtonClick}
                    onRefresh={this.handleRefreshButtonClick}
                    onChange={this.handleChangeSelectField}
                    disableAddButton={disableAddButton}
                    minWidth={"40ch"}
                />
                <div>{contentTable}</div>
                <div>{usersWithoutTaskContent}</div>
            </React.Fragment>
        )
    }
};

export default withStyles(useStyles)(AdminTasks);