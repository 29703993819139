import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 */
class UsersToTeamsService {

    /**
     * Get users-to-teams
     * @param {*} userToTeamSearch { teamid, userid, is_team_lead }
     */
    async getUsersToTeams( userToTeamSearch ) {
        var request = {
            query: `
            query getUsersToTeams($userToTeamSearch: UserToTeamSearch!) {
                getUsersToTeams(userToTeamSearch: $userToTeamSearch) {
                    teamid
                    userid
                    is_team_lead
                }
            }
            `,
            variables: {
                userToTeamSearch: userToTeamSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Add user-to-team
     * @param {*} userToTeamInput { teamid, userid, is_team_lead }
     */
    async addUserToTeam( userToTeamInput ) {
        var request = {
            query: `
            mutation addUserToTeam($userToTeamInput: UserToTeamInput!) {
                addUserToTeam(userToTeamInput: $userToTeamInput) {
                    teamid
                    userid
                    is_team_lead
                }
            }
            `,
            variables: {
                userToTeamInput: userToTeamInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete user-to-team
     * @param {*} userToTeamSearch { teamid, userid, is_team_lead }
     */
    async deleteUserToTeam( userToTeamSearch ) {
        var request = {
            query: `
            mutation deleteUserToTeam($userToTeamSearch: UserToTeamSearch!) {
                deleteUserToTeam(userToTeamSearch: $userToTeamSearch) {
                    result
                }
            }
            `,
            variables: {
                userToTeamSearch: userToTeamSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update user-to-team
     * @param {*} userToTeamSearch { teamid, userid, is_team_lead }
     * @param {*} userToTeamUpdate { is_team_lead }
     */
    async updateUserToTeam( userToTeamSearch, userToTeamUpdate ) {
        var request = {
            query: `
            mutation updateUserToTeam($userToTeamSearch: UserToTeamSearch!, $userToTeamUpdate: UserToTeamUpdate!) {
                updateUserToTeam(userToTeamSearch: $userToTeamSearch, userToTeamUpdate: $userToTeamUpdate) {
                    teamid
                    userid
                    is_team_lead
                }
            }
            `,
            variables: {
                userToTeamSearch: userToTeamSearch,
                userToTeamUpdate: userToTeamUpdate
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new UsersToTeamsService();
