import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Apply/Reset buttons
 */
class ApplyResetButtons extends Component {
    render() {
        return (
            <Grid className={this.props.className} container justify="flex-end">
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={this.props.classes.submit}
                    disabled={!this.props.applyButtonEnabled}
                    onClick={this.props.apply}
                >
                    {i18n.t("buttons.apply")}
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={this.props.classes.submit}
                    disabled={!this.props.resetButtonEnabled}
                    onClick={this.props.reset}
                >
                    {i18n.t("buttons.reset")}
                </Button>
            </Grid>
        );
    }
};

export default withStyles(useStyles)(ApplyResetButtons);