import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 * Also acts as provider for the UserContext
 */
class AbsenceService {

    /**
     * Get absences
     * @param {*} absenceSearch { date, userid, specialid }
     */
    async getAbsences( absenceSearch ) {
        var request = {
            query: `
            query getAbsences($absenceSearch: AbsenceSearch!) {
                getAbsences(absenceSearch: $absenceSearch) {
                    date
                    userid
                    specialid
                }
            }
            `,
            variables: {
                absenceSearch: absenceSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Get absences within date range
     * @param {*} absenceRangeSearch { minDate, maxDate, userid, specialid }
     */
    async getAbsencesRange( absenceRangeSearch ) {
        var request = {
            query: `
            query getAbsencesRange($absenceRangeSearch: AbsenceRangeSearch!) {
                getAbsencesRange(absenceRangeSearch: $absenceRangeSearch) {
                    date
                    userid
                    specialid
                }
            }
            `,
            variables: {
                absenceRangeSearch: absenceRangeSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Create a new absence
     * @param {*} absenceRangeSearch { minDate, maxDate, userid, specialid }
     * @param {*} absenceRangeInput { userid, specialid }
     * @param {*} absenceUpdate { specialid }
     * @param {*} skipDates { [{date}] }
     */
    async createAndUpdateAbsenceRange( absenceRangeSearch, absenceRangeInput, absenceUpdate, skipDates ) {
        var request = {
            query: `
            mutation createAndUpdateAbsenceRange($absenceRangeSearch: AbsenceRangeSearch!, $absenceRangeInput: AbsenceRangeInput!, $absenceUpdate: AbsenceUpdate!, $skipDates: [Date]!) {
                createAndUpdateAbsenceRange(absenceRangeSearch: $absenceRangeSearch, absenceRangeInput: $absenceRangeInput, absenceUpdate: $absenceUpdate, skipDates: $skipDates) {
                    date
                    userid
                    specialid
                }
            }
            `,
            variables: {
                absenceRangeSearch: absenceRangeSearch,
                absenceRangeInput: absenceRangeInput,
                absenceUpdate: absenceUpdate,
                skipDates: skipDates
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Create a new absence
     * @param {*} absenceInput { date, userid, specialid }
     */
    async createAbsence( absenceInput ) {
        var request = {
            query: `
            mutation createAbsence($absenceInput: AbsenceInput) {
                createAbsence(absenceInput: $absenceInput) {
                    date
                    userid
                    specialid
                }
            }
            `,
            variables: {
                absenceInput: absenceInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update an absence
     * @param {*} absenceSearch { date, userid, specialid }
     * @param {*} absenceUpdate { specialid }
     */
    async updateAbsence( absenceSearch, absenceUpdate ) {
        var request = {
            query: `
            mutation updateAbsence($absenceSearch: AbsenceSearch!, $absenceUpdate: AbsenceUpdate!) {
                updateAbsence(absenceSearch: $absenceSearch, absenceUpdate: $absenceUpdate) {
                    date
                    userid
                    specialid
                }
            }
            `,
            variables: {
                absenceSearch: absenceSearch,
                absenceUpdate: absenceUpdate
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete an absence
     * @param {*} absenceSearch { date, userid, specialid }
     */
    async deleteAbsence( absenceSearch ) {
        var request = {
            query: `
            mutation deleteAbsence($absenceSearch: AbsenceSearch!) {
                deleteAbsence(absenceSearch: $absenceSearch) {
                    result
                }
            }
            `,
            variables: {
                absenceSearch: absenceSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete an absence range
     * @param {*} absenceRangeSearch { minDate, maxDate, userid, specialid }
     */
    async deleteAbsenceRange( absenceRangeSearch ) {
        var request = {
            query: `
            mutation deleteAbsenceRange($absenceRangeSearch: AbsenceRangeSearch!) {
                deleteAbsenceRange(absenceRangeSearch: $absenceRangeSearch) {
                    result
                }
            }
            `,
            variables: {
                absenceRangeSearch: absenceRangeSearch
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new AbsenceService();
