import React, { Component } from 'react';
import ReportService from '../classes/ReportService';
import UserService from '../classes/UserService';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import AuthContext from '../contexts/AuthContext';
import SelectControls from '../components/SelectControls';
import ReportAbsenceCompany from '../components/ReportAbsenceCompany';
import AbsenceService from '../classes/AbsenceService';
import SpecialService from '../classes/SpecialService';
import ContractService from '../classes/ContractService';
import HolidayService from '../classes/HolidayService';
import CalendarHelper from '../classes/CalendarHelper.js';
import Grid from '@material-ui/core/Grid';
import ExportButtons from '../components/ExportButtons';
import { CSVLink } from 'react-csv';
import { withStyles } from '@material-ui/core/styles';
import i18n from 'i18next';

const useStyles = theme => ({
	mainGrid: {
		display: 'flex',
		height: '100%',
		width: '100%',
		margin: theme.spacing(0),
		justifyContent: 'space-between',
		'& > *': {
			margin: theme.spacing(0)
		},
	},
	select: {
		minWidth: '100%',
		maxWidth: '100%'
	}
});

/**
 * Reports Company
 */
class ReportsCompany extends Component {
	static contextType = AuthContext;

	state = {}

	constructor(props) {
		super(props);

		// All function bindings to make them accessible
        this.handleChangeSelectYear = this.handleChangeSelectYear.bind( this );
        this.handleChangeSelectMonth = this.handleChangeSelectMonth.bind( this );
        this.handleCsvExportClick = this.handleCsvExportClick.bind( this );

        // References to call into childs
        this.csvLinkRef = React.createRef();
        this.absenceChildRef = React.createRef();

		// Set initial state
		this.state = this.getInitialState();
	}

	/**
	 * STATE HANDLING
	 */

	// Get the initial state variables of this component
	getInitialState() {
		return {
            selectedYear: new Date().getFullYear(),
            selectedMonth: "",
            minYear: 0,
            maxYear: 0,
            selectedOption: "absences",
            absences: null,
            data: { // Raw loaded data from the backend
                admin: null,
                absenceReport: null,
                specials: null,
                contracts: null,
                holidays: null
            },
            export: {
                csv: {
                    filename: "",
                    data: []
                }
            }
		}
	}

	// Reset all internal states to initial values and also values propagated to parent
	resetState() {
		this.handleAlertChanged({ severity: "info", message: "" });
		this.handleDataLoadedChanged( false );
		this.setState( this.getInitialState() );
	}

	/**
	 * LINK TO PARENT COMPONENT FUNCTIONS
	 */

	// Push data loaded changed to parent
	handleDataLoadedChanged( dataLoaded ) {
		if( this.props.dataLoadedChanged ) {
			this.props.dataLoadedChanged( dataLoaded );
		}
	}

	// Push alert to parent
	handleAlertChanged( alert ) {
		if( this.props.alertChanged ) {
			this.props.alertChanged( alert );
		}
	}

	// Propagate information to parent component
	handleActionInProgressChanged( actionInProgress ) {
		if( this.props.actionInProgressChanged ) {
			this.props.actionInProgressChanged( actionInProgress );
		}
	}

	/**
	 * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
	 */

	// Called by react when this component has been mounted
	async componentDidMount() {
		// Refresh all data from backend
		await this.refreshData();
	}

	// Called by react before this component unmounts
	componentWillUnmount() {
		this.resetState();
	}

	/**
	 * HANDLERS 
	 */

	// Called when the user selects a new year
	handleChangeSelectYear( value ) {
		// Update the elements
        let selectedMonth = this.state.selectedMonth;
        if( value === "" ) {
            selectedMonth = "";
        }
		this.setState({ selectedYear: value, selectedMonth: selectedMonth });
	}

    // Called when the user selects a new month
	async handleChangeSelectMonth( month ) {
        await this.refreshAbsenceData( month );
	}

    // Called when the user selects a report option
	handleOptionChange( event, newAlignment ) {
        if( null !== newAlignment ) {
            this.setState({ selectedOption: newAlignment });
        }
	}

    /**
     * EXPORT FUNCTIONS
     */

    // Export to CSV
    handleCsvExportClick() {

        // Make the call to the child component
        let finalFilename = "";
        let finalData = "";
        if( "absences" === this.state.selectedOption ) {
            const { csvFilename, csvData } = this.absenceChildRef.current.onCsvExport( 
                this.state.data.absenceReport, this.state.absences, this.state.data.specials, this.state.data.contracts, this.state.data.holidays, this.state.selectedYear, this.state.selectedMonth );
            finalFilename = csvFilename;
            finalData = csvData;
        }

        // Set the state of the data and automatically click the download link
        this.setState({ 
            export: { 
                csv: {
                    filename: finalFilename,
                    data: finalData
                }
            }},
            () => {
                setTimeout(() => {
                    this.csvLinkRef.current.link.click();
                })
            }
        );
    }

	/**
	 * LOAD AND PROCESS BACKEND DATA
	 */

    // Gather project data from backend
    async refreshAbsenceData( month ) {

        let absenceData = null;

        if( null != month && month !== "" ) {

            let refreshYear = this.state.selectedYear;
            let refreshMonth = month;
            if( null == refreshYear ) {
                refreshYear = new Date().getFullYear();
            }
            if( null == refreshMonth ) {
                refreshMonth = new Date().getMonth();
            }

            const maxDayInMonth = CalendarHelper.getDaysInMonth( refreshYear, refreshMonth-1 );
            let minDate = `${refreshYear}-${refreshMonth}-01`
            let maxDate = `${refreshYear}-${refreshMonth}-${maxDayInMonth}`

            // Gather absence data
            const { statusCode, statusText, resData } = await AbsenceService.getAbsencesRange( { minDate: minDate, maxDate: maxDate } );
            if( 200 === statusCode && null != resData ) {
                absenceData = resData.getAbsencesRange;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

		this.setState({ absences: absenceData, selectedMonth: month });
    }

	// Gather all data from the backend
	async refreshData() {

		// Gather admin data for the currently logged in user
		let adminData = null;
		{
			const { statusCode, statusText, userAdminData } = await this.context.services.admin.getAdminData( this.context.userid );
			if( 200 === statusCode && null != userAdminData ) {
				adminData = userAdminData;
			} else {
				// Error, set the alert right away and abort
				this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
				return;
			}
		}

		// Gather user data
		let userData = null;
		{
			const { statusCode, statusText, resData } = await UserService.getUsers( {} );
			if( 200 === statusCode && null != resData ) {
				userData = resData.getUsers;
			} else {
				// Error, set the alert right away and abort
				this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
				return;
			}
			userData.sort(function(a, b) { return a.lastname > b.lastname ? 1 : -1; });
		}

        // Gather contract data
        let contractData = null;
        {
            const { statusCode, statusText, resData } = await ContractService.getContracts( {} );
            if( 200 === statusCode && null != resData ) {
                contractData = resData.getContracts;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather holiday data
        let holidayData = null;
		{
			const { statusCode, statusText, resData } = await HolidayService.getHolidays( {} );
			if( 200 === statusCode && null != resData ) {
				holidayData = resData.getHolidays;
			} else {
				// Error, set the alert right away and abort
				this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
				return;
			}
		}

        // Gather special data
        let specialData = null;
        {
            const { statusCode, statusText, resData } = await SpecialService.getSpecials( {} );
            if( 200 === statusCode && null != resData ) {
                specialData = resData.getSpecials;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather absence report data for all users
        let absenceReportData = [];
        if( true === adminData.admin.absences || true === adminData.admin.global ) {
            for( let i = 0; i < userData.length; i++ ) {
                const { statusCode, statusText, resData } = await ReportService.getAbsenceReport( { userid: userData[i].userid } );
                if( 200 === statusCode && null != resData ) {
                    absenceReportData.push( resData.getAbsenceReport );
                } else {
                    // Error, set the alert right away and abort
                    this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                    return;
                }
            }
        }

        // Determine min and max year to show
        let minYear = 2999;
        let maxYear = 0;
        if( absenceReportData.length > 0 ) {
            for( let i = 0; i < absenceReportData.length; i++ ) {
                for( let j = 0; j < absenceReportData[i].years.length; j++ ) {
                    if( absenceReportData[i].years[j].year < minYear ) {
                        minYear = absenceReportData[i].years[j].year;
                    }
                    if( absenceReportData[i].years[j].year > maxYear ) {
                        maxYear = absenceReportData[i].years[j].year;
                    }
                }
            }
        }

        if( minYear === 2999 ) {
            minYear = 0;
        }

        let selectedYear = this.state.selectedYear;
		if( selectedYear === 0 ) {
			selectedYear = new Date().getFullYear();
		}
        if( minYear === 2999 || maxYear === 0 ) {
            selectedYear = 0;
        }

        // Generate a combined absence report of all users
        let combinedAbsenceReport = {
            userid: 0,
            years: []
        }

        if( absenceReportData.length > 0 ) {

            // Gather data from all retrieved absence reports for each year and month
            for( let year = minYear; year <= maxYear; year++ ) {
                let yearData = {
                    year: year,
                    months: []
                };
                
                for( let month = 1; month <= 12; month++ ) {
                    let monthData = {
                        month: month,
                        absences: []
                    }

                    for( let i = 0; i < specialData.length; i++ ) {
                        let days_absent_in_month = 0;

                        for( let j = 0; j < absenceReportData.length; j++ ) {
                            for( let k = 0; k < absenceReportData[j].years.length; k++ ) {

                                if( year === absenceReportData[j].years[k].year ) {
                                    for( let l = 0; l < absenceReportData[j].years[k].months.length; l++ ) {
                                    
                                        if( month === absenceReportData[j].years[k].months[l].month ) {

                                            for( let m = 0; m < absenceReportData[j].years[k].months[l].absences.length; m++ ) {

                                                if( specialData[i].specialid === absenceReportData[j].years[k].months[l].absences[m].specialid ) {
                                                    days_absent_in_month += absenceReportData[j].years[k].months[l].absences[m].days_absent_in_month;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        monthData.absences.push({
                            specialid: specialData[i].specialid,
                            days_absent_in_month: days_absent_in_month
                        });
                    }

                    yearData.months.push( monthData );
                }

                combinedAbsenceReport.years.push( yearData );
            }

        } else {

            // Just generate an empty combined absence report
            for( let year = minYear; year <= maxYear; year++ ) {
                let yearData = {
                    year: year,
                    months: []
                };
    
                for( let month = 1; month <= 12; month++ ) {
                    let monthData = {
                        month: month,
                        absences: []
                    }
    
                    for( let i = 0; i < specialData.length; i++ ) {
                        month.absences.push({
                            specialid: specialData[i].specialid,
                            days_absent_in_month: 0
                        });
                    }

                    yearData.months.push( monthData );
                }

                combinedAbsenceReport.years.push( yearData );
            }
        }

		this.setState({
            selectedYear: selectedYear,
            minYear: minYear,
            maxYear: maxYear,
			data: { // Raw loaded data from the backend
                admin: adminData,
                absenceReport: combinedAbsenceReport,
                specials: specialData,
                contracts: contractData,
                holidays: holidayData
			}
		});

		this.handleDataLoadedChanged( true );
	}

	/**
	 * RENDER FUNCTIONS
	 */

	// Render select year menu
	renderSelectYear( selectedYear, minYear, maxYear ) {

		// Gather list of selectable years
		let selectableYears = [];

        for( let year = minYear; year <= maxYear; year++ ) {
            selectableYears.push( {
                id: year,
                value: year.toString()
            })
        }

		return (
			<SelectControls
				selectedEntry={selectedYear}
				label={i18n.t("pages.reports.select_year.label")}
				helperText={i18n.t("pages.reports.select_year.subtext")}
				showNoneValue={false}
				items={selectableYears}
				onChange={this.handleChangeSelectYear}
				showButtons={false}
			/>
		)
	}

    // Render select month menu
	renderSelectMonth( selectedMonth ) {

		// Gather list of selectable months
		let selectableMonths = [];
        const monthNames = CalendarHelper.getMonthNames( this.props.language, "long" );

        for( let i = 0; i < monthNames.length; i++ ) {
            selectableMonths.push( {
                id: (i+1),
                value: monthNames[i]
            })
        }

		return (
			<SelectControls
				selectedEntry={selectedMonth}
				label={i18n.t("pages.reports.select_month.label")}
				helperText={i18n.t("pages.reports.select_month.subtext")}
				noneValueName={i18n.t("pages.reports.select_month.all_value")}
				items={selectableMonths}
				onChange={this.handleChangeSelectMonth}
				showButtons={false}
			/>
		)
	}

    // Render option buttons
	renderOption( selectedOption ) {
		return (
			<ToggleButtonGroup 
				size="small" 
				value={selectedOption}
				exclusive
				className={this.props.classes.options}
				onChange={(event, alignment) => this.handleOptionChange(event, alignment)}
			>
                <ToggleButton value="absences">{i18n.t("pages.reports.options.absence.title")}</ToggleButton>
			</ToggleButtonGroup>
		)
	}

    render() {
		/**
		 * INPUTS
		 * adminData, language, dataLoadedChanged, alertChanged, actionInProgressChanged
		 */

        let selectYear = "";
        let selectMonth = "";
        let selectOption = "";
        let reportContent = "";

        if( null != this.state.data.absenceReport && this.state.minYear > 0 && this.state.maxYear < 2999 ) {
            selectYear = this.renderSelectYear( this.state.selectedYear, this.state.minYear, this.state.maxYear );
            selectMonth = this.renderSelectMonth( this.state.selectedMonth );
            selectOption = this.renderOption( this.state.selectedOption );

            if( "absences" === this.state.selectedOption ) {
                reportContent = (
                    <ReportAbsenceCompany
                        ref={this.absenceChildRef}
                        language={this.props.language}
                        year={this.state.selectedYear.toString()}
                        month={this.state.selectedMonth.toString()}
                        absenceReport={this.state.data.absenceReport}
                        absences={this.state.absences}
                        specials={this.state.data.specials}
                        contracts={this.state.data.contracts}
                        holidays={this.state.data.holidays}
                    />
                )
            }
        }

        // Export CSV button
        let exportCsvButtons = [];
        exportCsvButtons.push({
            showIcon: true,
            showText: false,
            tooltip: i18n.t("pages.reports.company_reports.buttons.csv")
        })

        return (
			<div>
				<Grid container className={this.props.classes.mainGrid} justify="flex-start" alignItems="flex-start" spacing={1}>
                    <Grid item container xs={12} className={this.props.classes.select} spacing={1} wrap='nowrap'>
						<Grid item>
							{selectYear}
						</Grid>
                        <Grid item>
							{selectMonth}
						</Grid>
                        <Grid item>
							{selectOption}
						</Grid>
                        <Grid item container justify="flex-end">
                            <CSVLink
                                ref={this.csvLinkRef}
                                data={this.state.export.csv.data}
                                filename={this.state.export.csv.filename}
                                className="hidden"
                                target="_blank"
                            />
                            <ExportButtons 
                                csvButtons={exportCsvButtons}
                                onCsvClick={(customData) => this.handleCsvExportClick()}
                            />
                        </Grid>
                    </Grid>
					{reportContent}
				</Grid>
			</div>
        );
    }
};

export default withStyles(useStyles)(ReportsCompany);