import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = theme => ({});

/**
 * Modal Dialog Password Component
 */
class ModalDialogPassword extends Component {
    state = {
        passwordNew: "",
        passwordConfirm: "",
        helperTextNew: "",
        helperTextConfirm: "",
        contentVerifiedNew: false,
        contentVerifiedConfirm: false
    };

    constructor(props) {
        super(props);
        this.handleChangeNew = this.handleChangeNew.bind( this );
        this.handleChangeConfirm = this.handleChangeConfirm.bind( this );
    }

    // Determine the button state
    getButtonState( valueNew, valueConfirm, contentVerifiedNew, contentVerifiedConfirm ) {
        if( valueNew !== "" && valueConfirm !== "" && true === contentVerifiedNew && true === contentVerifiedConfirm ) {
            return true;
        } else {
            return false;
        }
    }

    // General push of new data to parent
    updateControlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent ) {
        if( null != this.props.controlButtonsAndPushContent ) {
            this.props.controlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent );
        }
    }

    // New password text field changed
    handleChangeNew(event) {
        let value = event.target.value;

        if( null != this.props.maxLength ) {
            if( value.length > this.props.maxLength ) {
                return;
            }
        }

        // Verify the new value
        const { stateNew, stateConfirm, msgNew, msgConfirm } = this.props.verifyContent({ passwordNew: value, passwordConfirm: this.state.passwordConfirm });

        const buttonState = this.getButtonState( value, this.state.passwordConfirm, stateNew, stateConfirm );
        this.updateControlButtonsAndPushContent( buttonState, true, { passwordNew: value, passwordConfirm: this.state.passwordConfirm } );
        this.setState({ passwordNew: value, contentVerifiedNew: stateNew, contentVerifiedConfirm: stateConfirm, helperTextNew: msgNew, helperTextConfirm: msgConfirm });
    }

    // Confirm password text field changed
    handleChangeConfirm(event) {
        let value = event.target.value;

        if( null != this.props.maxLength ) {
            if( value.length > this.props.maxLength ) {
                return;
            }
        }

        // Verify the new value
        const { stateNew, stateConfirm, msgNew, msgConfirm } = this.props.verifyContent({ passwordNew: this.state.passwordNew, passwordConfirm: value });

        const buttonState = this.getButtonState( this.state.passwordNew, value, stateNew, stateConfirm );
        this.updateControlButtonsAndPushContent( buttonState, true, { passwordNew: this.state.passwordNew, passwordConfirm: value } );
        this.setState({ passwordConfirm: value, contentVerifiedNew: stateNew, contentVerifiedConfirm: stateConfirm, helperTextNew: msgNew, helperTextConfirm: msgConfirm });
    }

    // Called by react when this component has been mounted
    componentDidMount() {

        // Check for incoming values from the parent
        let passwordNew = "";
        if( null != this.props.passwordNew ) {
            passwordNew = this.props.passwordNew;
        }
        let passwordConfirm = "";
        if( null != this.props.passwordConfirm ) {
            passwordConfirm = this.props.passwordConfirm;
        }

        const { stateNew, stateConfirm, msgNew, msgConfirm } = this.props.verifyContent({ passwordNew: passwordNew, passwordConfirm: passwordConfirm });
        const buttonState = this.getButtonState( passwordNew, passwordConfirm, stateNew, stateConfirm );
        this.updateControlButtonsAndPushContent( buttonState, true, { passwordNew: passwordNew, passwordConfirm: passwordConfirm } );
        this.setState({ passwordNew: passwordNew, passwordConfirm: passwordConfirm, contentVerifiedNew: stateNew, contentVerifiedConfirm: stateConfirm, helperTextNew: msgNew, helperTextConfirm: msgConfirm });
    }

    // React render
    render() {
        // Used this.props
        // setLeftButtonState(bool), setRightButtonState(bool), setContent(json), verifyContent(json), label, maxLength

        return (
            <React.Fragment>
                <TextField
                    value={this.state.passwordNew}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label={this.props.labelNew}
                    name="name"
                    onChange={this.handleChangeNew}
                    autoFocus={this.props.autoFocus}
                    error={this.state.passwordNew === "" || false === this.state.contentVerifiedNew}
                    helperText={this.state.helperTextNew}
                    style={{ minHeight: "5rem" }}
                    type="password"
                />
                <TextField
                    value={this.state.passwordConfirm}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label={this.props.labelConfirm}
                    name="name"
                    onChange={this.handleChangeConfirm}
                    autoFocus={false}
                    error={this.state.passwordConfirm === "" || false === this.state.contentVerifiedConfirm}
                    helperText={this.state.helperTextConfirm}
                    style={{ minHeight: "5rem" }}
                    type="password"
                />
            </React.Fragment>
        );
    }
};

export default withStyles(useStyles)(ModalDialogPassword);