import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PageTitle from '../components/PageTitle';
import SelectYearMonth from '../components/SelectYearMonth';
import AuthContext from '../contexts/AuthContext';
import TabContainer from '../components/TabContainer';
import AbsenceService from '../classes/AbsenceService'
import SpecialService from '../classes/SpecialService'
import AccountingUser from '../components/AccountingUser';
import AccountingProject from '../components/AccountingProject';
import AccountingService from '../classes/AccountingService';
import WorkTimeService from '../classes/WorkTimeService';
import HolidayService from '../classes/HolidayService'
import ContractService from '../classes/ContractService';
import ProjectService from '../classes/ProjectService';
import ReportService from '../classes/ReportService'
import TaskService from '../classes/TaskService';
import UserService from '../classes/UserService';
import UserTypeService from '../classes/UserTypeService'
import UsersToProjectsService from '../classes/UsersToProjectsService';
import UsersToTasksService from '../classes/UsersToTasksService';
import UsersToTeamsService from '../classes/UsersToTeamsService';
import SelectControls from '../components/SelectControls'
import SelectDouble from '../components/SelectDouble'
import CalendarHelper from '../classes/CalendarHelper.js';
import { toDateObject, fromTimeStringToDate, toJSONLocalDate } from '../helpers.js';
import Grid from '@material-ui/core/Grid';
import SpecialLegend from '../components/SpecialLegend';
import UserAccountingSummary from '../components/UserAccountingSummary';
import ProjectAccountingSummary from '../components/ProjectAccountingSummary';
import _ from 'lodash';
import i18n from 'i18next';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const useStyles = theme => ({
    gridContainer: {
        display: 'flex',
        width: "100%"
    },
    gridItemSpecials: {
        width: "30%"
    },
    gridItemUserSummary: {
        width: "70%"
    },
    gridItemProjectSummary: {
        width: "30%"
    },
});

/**
 * Main Page
 */
class MainPage extends Component {
    static contextType = AuthContext;

    // Set in constructor
    state = {}

    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleYearMonthChange = this.handleYearMonthChange.bind( this );
        this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind( this );
        this.handleTabChange = this.handleTabChange.bind( this );
        this.renderTabMy = this.renderTabMy.bind( this );
        this.renderTabTeam = this.renderTabTeam.bind( this );
        this.renderTabProject = this.renderTabProject.bind( this );
        this.handleModifyAccounting = this.handleModifyAccounting.bind( this );
        this.handleModifyWorkTime = this.handleModifyWorkTime.bind( this );
        this.handleDeleteWorkTime = this.handleDeleteWorkTime.bind( this );
        this.generateUserAccountingSummary = this.generateUserAccountingSummary.bind( this );
        this.generateProjectAccountingSummary = this.generateProjectAccountingSummary.bind( this );
        this.getUserData = this.getUserData.bind( this );
        this.getProjectData = this.getProjectData.bind( this );
        this.getTasksForProject = this.getTasksForProject.bind( this );
        this.getUsersForProject = this.getUsersForProject.bind( this );
        this.handleChangeSelectUserFieldTabTeam = this.handleChangeSelectUserFieldTabTeam.bind( this );
        this.handleChangeSelectUserFieldTabProject = this.handleChangeSelectUserFieldTabProject.bind( this );
        this.handleChangeSelectProjectField = this.handleChangeSelectProjectField.bind( this );

        // Set initial state
        this.state = this.getInitialState( "", "" );
    }

    /**
     * STATE HANDLING
     */

    // Get the initial state variables of this component
    getInitialState( userIdTabTeam, userIdTabProject ) {
        return {
            currentMonth: new Date().getMonth(),
            currentYear: new Date().getFullYear(),
            currentTabIndex: 0,
            data: { // Raw loaded data from the backend
                admin: null,
                users: null,
                userTypes: null,
                projects: null,
                tasks: null,
                usersToProjects: null,
                usersToTasks: null,
                usersToTeams: null,
                absences: null,
                contracts: null,
                specials: null,
                holidays: null,
                accountings: null,
                accountingReport: null,
                worktimes: null
            },
            selectUser: {
                userIdTabTeam: userIdTabTeam,
                userIdTabProject: userIdTabProject,
                selectableUsers: []
            },
            selectProject: {
                projectId: "",
                selectableProjects: []
            }
        }
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState( userIdTabTeam, userIdTabProject ) {
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
        this.setState( this.getInitialState( userIdTabTeam, userIdTabProject ) );
    }

    /**
     * LINK TO PARENT COMPONENT FUNCTIONS
     */

    // Push data loaded changed to parent
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Push alert to parent
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Propagate information to parent component
    handleActionInProgressChanged( actionInProgress ) {
        if( this.props.actionInProgressChanged ) {
            this.props.actionInProgressChanged( actionInProgress );
        }
    }

    /**
     * LOAD AND PROCESS BACKEND DATA
     */

    // Refresh data loaded from backend due to month year change
    async refreshTableDataYearMonthChange( year, month ) {
        await this.loadDataFromBackend( false, null, null, null, year, month );
    }

    // Refresh data loaded from backend due to user or project selection change
    async refreshTableDataUserProjectSelectionChange( userid, projectid ) {

        if( this.state.currentTabIndex === 1 ) {
            await this.loadDataFromBackend( false, userid, null, projectid, null, null );
        } else if( this.state.currentTabIndex === 2 ) {
            await this.loadDataFromBackend( false, null, userid, projectid, null, null );
        }
        
    }

    // Refresh data loaded from backend due to accounting entry change
    async refreshTableDataAccountingChange() {
        await this.loadDataFromBackend( true );
    }

    // Refresh all data loaded from backend
    async refreshTableDataAll( newTabIndex) {
        await this.loadDataFromBackend( true, null, null, null, null, null, newTabIndex );
    }

    // Gather all data from the backend
    async loadDataFromBackend( refreshAllData, userIdTabTeam, userIdTabProject, projectid, year, month, newTabIndex ) {

        /**
         * PROPERLY HANDLE INPUT ARGUMENTS, SINCE SOME MIGHT NOT BE GIVEN
         * IN THAT CASE CURRENTLY SELECTED VALUES SHALL BE USED
         * ALSO REMEMBER WHICH ARGUMENTS HAVE CHANGED TO ONLY REFRESH THE NECESSARY DATA
         */

        // Determine the new tab index
        let tabIndex = this.state.currentTabIndex;
        if( null != newTabIndex ) {
            tabIndex = newTabIndex;
        }

        // Determine the selected year and month
        let yearMonthSelectionChanged = false;
        let selectedYear = this.state.currentYear;
        if( null != year && year !== "" ) {
            selectedYear = year;
            yearMonthSelectionChanged = true;
        }
        let selectedMonth = this.state.currentMonth;
        if( null != month && month !== "" ) {
            selectedMonth = month;
            yearMonthSelectionChanged = true;
        }

        // Determine the new selected userid
        let userSelectionChanged = false;
        let selectedUserId = null;
        if( 0 === tabIndex ) {
            selectedUserId = this.context.userid;
            userSelectionChanged = true;

        } else if( 1 === tabIndex ) {
            selectedUserId = this.state.selectUser.userIdTabTeam;

            if( null != userIdTabTeam ) {
                selectedUserId = userIdTabTeam;
                userSelectionChanged = true;
            }

        } else if( 2 === tabIndex ) {
            selectedUserId = this.state.selectUser.userIdTabProject;

            if( null != userIdTabProject ) {
                selectedUserId = userIdTabProject;
                userSelectionChanged = true;
            }
        }

        // Determine the new selected projectid
        let projectSelectionChanged = false;

        let selectedProjectId = this.state.selectProject.projectId;
        if( null != projectid ) {
            selectedProjectId = projectid;
            projectSelectionChanged = true;
        }
        if( null == selectedProjectId ) {
            selectedProjectId = "";
            projectSelectionChanged = true;
        }

        // Determine refresh flags for conditional data
        let updateHolidayData = false;
        let updateAccountingData = false;
        let updateContractData = false;
        let updateAccountingReportData = false;
        let updateAbsenceData = false;
        let updateWorkTimeData = false;

        // In case year and month changed
        if( true === yearMonthSelectionChanged ) {
            updateHolidayData = true;
            updateAccountingData = true;
            updateAbsenceData = true;
            updateWorkTimeData = true;
        }

        // In case the user changed
        if( true === userSelectionChanged ) {
            updateAccountingData = true;
            updateAbsenceData = true;
            updateAccountingReportData = true;
            updateContractData = true;
            updateWorkTimeData = true;
        }

        // In case the project changed
        if( true === projectSelectionChanged ) {
            updateAccountingData = true;
        }

        // If we have to refresh all data set all update flags to true
        if( true === refreshAllData ) {
            updateHolidayData = true;
            updateAccountingData = true;
            updateContractData = true;
            updateAccountingReportData = true;
            updateAbsenceData = true;
            updateWorkTimeData = true;
        }

        // There are a few cases were we need to disable the update again depending on certain circumstances

        // Only update accountingData, contractData, absenceData and accountingReportData that if:
        // * We are on tab=0 and a user has been selected
        // * We are on tab=1 (Team Accountings) and a user has been selected
        // * We are on tab=2 (Project Accountings) and a project has been selected
        if( 0 === tabIndex && "" === selectedUserId ) {
            updateAccountingData = false;
            updateContractData = false;
            updateAccountingReportData = false;
            updateAbsenceData = false;
            updateWorkTimeData = false;
        }
        if( 1 === tabIndex && "" === selectedUserId ) {
            updateAccountingData = false;
            updateContractData = false;
            updateAccountingReportData = false;
            updateAbsenceData = false;
            updateWorkTimeData = false;
        }
        if( 2 === tabIndex && "" === selectedProjectId ) {
            updateAccountingData = false;
            updateContractData = false;
            updateAccountingReportData = false;
            updateAbsenceData = false;
            updateWorkTimeData = false;
        }
        if( 2 === tabIndex ) {
            updateAccountingReportData = false;
        }

        /**
         * Build the proper min max dates for certain queries
         */
        const maxDayInMonth = CalendarHelper.getDaysInMonth( selectedYear, selectedMonth );

        let minDate = `${selectedYear}-${selectedMonth+1}-01`
        let maxDate = `${selectedYear}-${selectedMonth+1}-${maxDayInMonth}`

        if( (selectedMonth+1) < 10 ) {
            minDate = `${selectedYear}-0${selectedMonth+1}-01`
            maxDate = `${selectedYear}-0${selectedMonth+1}-${maxDayInMonth}`
        }

        /**
         * GATHER ALL DATA WHICH IS NOT YEAR, MONTH, USER OR PROJECT SELECTION DEPENDENT
         */

        let userData = null;
        let adminData = null;
        let userTypeData = null;
        let taskData = null;
        let projectData = null;
        let usersToProjectsData = null;
        let usersToTasksData = null;
        let usersToTeamsData = null;
        let specialData = null;
        let selectableUsers = [];
        let selectableProjects = [];

        if( true === refreshAllData ) {

            // Gather user data
            {
                const { statusCode, statusText, resData } = await UserService.getUsers( {} );
                if( 200 === statusCode && null != resData ) {
                    userData = resData.getUsers;
                } else {
                    // Error, set the alert right away and abort
                    this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                    return;
                }
                userData.sort(function(a, b) { return a.lastname > b.lastname ? 1 : -1; });
            }

            // Gather admin data for the currently logged in user
            {
                const { statusCode, statusText, userAdminData } = await this.context.services.admin.getAdminData( this.context.userid );
                if( 200 === statusCode && null != userAdminData ) {
                    adminData = userAdminData;
                } else {
                    // Error, set the alert right away and abort
                    this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                    return;
                }
            }

            // Gather user type data
            {
                const { statusCode, statusText, resData } = await UserTypeService.getUserTypes( {} );
                if( 200 === statusCode && null != resData ) {
                    userTypeData = resData.getUserTypes;
                } else {
                    // Error, set the alert right away and abort
                    this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                    return;
                }
            }

            // Gather task data
            {
                const { statusCode, statusText, resData } = await TaskService.getTasks( {} );
                if( 200 === statusCode && null != resData ) {
                    taskData = resData.getTasks;
                } else {
                    // Error, set the alert right away and abort
                    this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                    return;
                }
                taskData.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
            }

            // Gather project data
            {
                const { statusCode, statusText, resData } = await ProjectService.getProjects( {} );
                if( 200 === statusCode && null != resData ) {
                    projectData = resData.getProjects;
                } else {
                    // Error, set the alert right away and abort
                    this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                    return;
                }
                projectData.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
            }

            // Gather usersToProjects data
            {
                const { statusCode, statusText, resData } = await UsersToProjectsService.getUsersToProjects( {} );
                if( 200 === statusCode && null != resData ) {
                    usersToProjectsData = resData.getUsersToProjects;
                } else {
                    // Error, set the alert right away and abort
                    this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                    return;
                }
            }

            // Gather usersToTasks data
            {
                const { statusCode, statusText, resData } = await UsersToTasksService.getUsersToTasks( {} );
                if( 200 === statusCode && null != resData ) {
                    usersToTasksData = resData.getUsersToTasks;
                } else {
                    // Error, set the alert right away and abort
                    this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                    return;
                }
            }

            // Gather usersToTeams data
            {
                const { statusCode, statusText, resData } = await UsersToTeamsService.getUsersToTeams( {} );
                if( 200 === statusCode && null != resData ) {
                    usersToTeamsData = resData.getUsersToTeams;
                } else {
                    // Error, set the alert right away and abort
                    this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                    return;
                }
            }

            // Gather special data
            {
                const { statusCode, statusText, resData } = await SpecialService.getSpecials( {} );
                if( 200 === statusCode && null != resData ) {
                    specialData = resData.getSpecials;
                } else {
                    // Error, set the alert right away and abort
                    this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                    return;
                }
                specialData.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
            }

            /**
             * POSTPROCESSING
             */

            // Add user type name to all user data entries
            for( let i = 0; i < userData.length; i++ ) {
                let userTypeName = "";
                for( let j = 0; j < userTypeData.length; j++ ) {
                    if( userTypeData[j].typeid === userData[i].typeid ) {
                        userTypeName = userTypeData[j].name;
                        break;
                    }
                }
                userData[i].userTypeName = userTypeName;
            }

            // Gather list of selectable users depending on user administration access or team lead settings
            if( null != adminData ) {

                for( let i = 0; i < userData.length; i++ ) {

                    let fullUserEntry = userData[i].lastname + ", " + userData[i].firstname;
                    if( userData[i].userTypeName != null && userData[i].userTypeName !== "" ) {
                        fullUserEntry = "(" + userData[i].userTypeName + ") " + userData[i].lastname + ", " + userData[i].firstname;
                    }

                    // Add current user to list depending on permissions
                    if( userData[i].userid === this.context.userid ) {

                        // We are the current user, add us to the list
                        selectableUsers.push( { id: userData[i].userid, value: fullUserEntry, lastname: userData[i].lastname, userTypeName: userData[i].userTypeName } );

                    } else if( true === adminData.admin.accountings || true === adminData.admin.global ) {

                        // We are admin accounting user or global admin
                        selectableUsers.push( { id: userData[i].userid, value: fullUserEntry, lastname: userData[i].lastname, userTypeName: userData[i].userTypeName } );

                    } else if( 0 < adminData.teamlead.length ) {

                        // Check if the current user is team lead
                        for( let j = 0; j < usersToTeamsData.length; j++ ) {
                            if( usersToTeamsData[j].userid === userData[i].userid ) {
                                let isTeamLeadOfUser = false;

                                for( let k = 0; k < adminData.teamlead.length; k++ ) {
                                    if( adminData.teamlead[k] === usersToTeamsData[j].teamid ) {
                                        isTeamLeadOfUser = true;
                                    }
                                }

                                if( true === isTeamLeadOfUser ) {
                                    selectableUsers.push( { id: userData[i].userid, value: fullUserEntry, lastname: userData[i].lastname, userTypeName: userData[i].userTypeName } );
                                }
                            }
                        }
                    } else if( 0 < adminData.projectlead.length ) {
                        // Check if the current user is project lead
                        for( let j = 0; j < usersToProjectsData.length; j++ ) {

                            if( usersToProjectsData[j].userid === userData[i].userid ) {
                                let isProjectLeadOfUser = false;

                                for( let k = 0; k < adminData.projectlead.length; k++ ) {
                                    if( adminData.projectlead[k] === usersToProjectsData[j].projectid ) {
                                        isProjectLeadOfUser = true;
                                    }
                                }

                                if( true === isProjectLeadOfUser ) {
                                    selectableUsers.push( { id: userData[i].userid, value: fullUserEntry, lastname: userData[i].lastname, userTypeName: userData[i].userTypeName } );
                                }
                            }
                        }
                    }
                }

                // Sort user data
                selectableUsers.sort(function(a, b) { 
                    // Sort by user type first, then by user lastname
                    if( a.userTypeName > b.userTypeName ) return 1;
                    if( a.userTypeName < b.userTypeName ) return -1;
                    if( a.lastname > b.lastname ) return 1;
                    if( a.lastname < b.lastname ) return -1;
                    return 0;
                });
            }

            // Gather list of selectable projects depending on user administraction access or project lead settings
            if( null != adminData ) {
                for( let i = 0; i < projectData.length; i++ ) {

                    // As a value entry use {internal_id}-{name} here since this value will show up in the select menu
                    let value = `${projectData[i].internal_id} - ${projectData[i].name}`;

                    if( true === adminData.admin.accountings || true === adminData.admin.global ) {

                        // We are admin accounting user or global admin
                        selectableProjects.push( { id: projectData[i].projectid, value: value } );

                    } else if( 0 < adminData.projectlead.length ) {

                        // Check if the current user is the project lead
                        for( let j = 0; j < adminData.projectlead.length; j++ ) {

                            if( adminData.projectlead[j] === projectData[i].projectid ) {
                                selectableProjects.push( { id: projectData[i].projectid, value: value } );
                            }
                        }
                    }
                }

                // Sort project data
                selectableProjects.sort(function(a, b) { return a.value > b.value ? 1 : -1; });
            }
        }

        /**
         * GATHER ALL REMAINING DATA WHICH IS ONLY UPDATED DEPENDING ON CHANGED PARAMETERS
         */

        // Gather holiday data
        let holidayData = null;
        if( true === updateHolidayData ) {
            const { statusCode, statusText, resData } = await HolidayService.getHolidaysRange( { minDate: minDate, maxDate: maxDate } );
            if( 200 === statusCode && null != resData ) {
                holidayData = resData.getHolidaysRange;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather accounting report data
        let accountingReportData = null;
        if( true === updateAccountingReportData ) {
            const { statusCode, statusText, resData } = await ReportService.getAccountingReport( { userid: selectedUserId } );
            if( 200 === statusCode && null != resData ) {
                accountingReportData = resData.getAccountingReport;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather contract data
        let contractData = null;
        if( true === updateContractData ) {
            const { statusCode, statusText, resData } = await ContractService.getContracts( { userid: selectedUserId } );
            if( 200 === statusCode && null != resData ) {
                contractData = resData.getContracts;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather accounting data
        let accountingData = null;
        if( true === updateAccountingData ) {
            const { statusCode, statusText, resData } = await AccountingService.getAccountingsRange( { userid: selectedUserId, minDate: minDate, maxDate: maxDate } );
            if( 200 === statusCode && null != resData ) {
                accountingData = resData.getAccountingsRange;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather worktime data
        let workTimeData = null;
        if( true === updateWorkTimeData ) {

            // We also need to grab the worktimes of the day prior to the minDate to properly calculate warnings for the nightly rest period
            let minDateObject = toDateObject( minDate );
            const minDateObjectYesterday = moment(minDateObject).subtract(1, 'days').toDate();
            let workTimeMinDate = toJSONLocalDate( minDateObjectYesterday );

            const { statusCode, statusText, resData } = await WorkTimeService.getWorkTimesRange( { userid: selectedUserId, minDate: workTimeMinDate, maxDate: maxDate } );
            if( 200 === statusCode && null != resData ) {
                workTimeData = resData.getWorkTimesRange;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather absence data
        let absenceData = null;
        if( true === updateAbsenceData ) {
            const { statusCode, statusText, resData } = await AbsenceService.getAbsencesRange( { userid: selectedUserId, minDate: minDate, maxDate: maxDate } );
            if( 200 === statusCode && null != resData ) {
                absenceData = resData.getAbsencesRange;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        /**
         * STORE NEW DATA IN STATE DEPENDING ON WHAT HAS BEEN UPDATED
         */

        // Build the new state struct to set depending on updated data
        let newState = this.getInitialState( "", "" );
        newState.selectUser.userIdTabTeam = this.state.selectUser.userIdTabTeam;
        newState.selectUser.userIdTabProject = this.state.selectUser.userIdTabProject;

        if( 1 === tabIndex ) {
            newState.selectUser.userIdTabTeam = selectedUserId;
        } else if( 2 === tabIndex ) {
            newState.selectUser.userIdTabProject = selectedUserId;
        }

        newState.selectProject.projectId = selectedProjectId;
        newState.currentMonth = selectedMonth;
        newState.currentYear = selectedYear;
        newState.currentTabIndex = tabIndex;

        if( true === refreshAllData ) {
            // Set fresh data
            newState.data.admin = adminData;
            newState.data.users = userData;
            newState.data.userTypes = userTypeData;
            newState.data.projects = projectData;
            newState.data.tasks = taskData;
            newState.data.usersToProjects = usersToProjectsData;
            newState.data.usersToTasks = usersToTasksData;
            newState.data.usersToTeams = usersToTeamsData;
            newState.data.specials = specialData;
            newState.selectUser.selectableUsers = selectableUsers;
            newState.selectProject.selectableProjects = selectableProjects;
        } else {
            // Re-use old data
            newState.data.admin = this.state.data.admin;
            newState.data.users = this.state.data.users;
            newState.data.userTypes = this.state.data.userTypes;
            newState.data.projects = this.state.data.projects;
            newState.data.tasks = this.state.data.tasks;
            newState.data.usersToProjects = this.state.data.usersToProjects;
            newState.data.usersToTasks = this.state.data.usersToTasks;
            newState.data.usersToTeams = this.state.data.usersToTeams;
            newState.data.specials = this.state.data.specials;
            newState.selectUser.selectableUsers = this.state.selectUser.selectableUsers;
            newState.selectProject.selectableProjects = this.state.selectProject.selectableProjects;
        }

        // The following data is only refreshed conditionally
        if( null != contractData ) {
            newState.data.contracts = contractData;
        } else {
            newState.data.contracts = this.state.data.contracts;
        }

        if( null != accountingReportData ) {
            newState.data.accountingReport = accountingReportData;
        } else {
            newState.data.accountingReport = this.state.data.accountingReport;
        }

        if( null != holidayData ) {
            newState.data.holidays = holidayData;
        } else {
            newState.data.holidays = this.state.data.holidays;
        }

        if( null != accountingData ) {
            newState.data.accountings = accountingData;
        } else {
            newState.data.accountings = this.state.data.accountings;
        }

        if( null != workTimeData ) {
            newState.data.worktimes = workTimeData;
        } else {
            newState.data.worktimes = this.state.data.worktimes;
        }

        if( null != absenceData ) {
            newState.data.absences = absenceData;
        } else {
            newState.data.absences = this.state.data.absences;
        }

        // Set the new state
        this.setState({
            currentYear: newState.currentYear, 
            currentMonth: newState.currentMonth,
            currentTabIndex: newState.currentTabIndex,
            data: { 
                admin: newState.data.admin,
                users: newState.data.users,
                userTypes: newState.data.userTypes,
                tasks: newState.data.tasks,
                projects: newState.data.projects,
                usersToProjects: newState.data.usersToProjects,
                usersToTasks: newState.data.usersToTasks,
                usersToTeams: newState.data.usersToTeams,
                holidays: newState.data.holidays,
                specials: newState.data.specials,
                contracts: newState.data.contracts,
                absences: newState.data.absences,
                accountings: newState.data.accountings,
                accountingReport: newState.data.accountingReport,
                worktimes: newState.data.worktimes
            },
            selectUser: {
                userIdTabTeam: newState.selectUser.userIdTabTeam,
                userIdTabProject: newState.selectUser.userIdTabProject,
                selectableUsers: newState.selectUser.selectableUsers
            },
            selectProject: {
                projectId: newState.selectProject.projectId,
                selectableProjects: newState.selectProject.selectableProjects
            }
        });

        this.handleDataLoadedChanged( true );
    }

    /**
     * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
     */

    // Called by react when this component has been mounted
    async componentDidMount() {
        // Refresh all data for currently selected year, month and user
        await this.refreshTableDataAll( this.state.currentTabIndex );
    }

    // Called by react before this component unmounts
    componentWillUnmount() {
        this.resetState( "", "" );
    }

    /**
     * CALLBACKS FROM TOP LEVEL COMPONENT
     */

    // Called if the refresh button is clicked
    async handleRefreshButtonClick() {
        this.resetState( this.state.selectUser.userIdTabTeam, this.state.selectUser.userIdTabProject );

        // Refresh all data for currently selected year, month and user
        await this.refreshTableDataAll( this.state.currentTabIndex );
    }

    // Called when the year changes
    async handleYearMonthChange( year, month ) {
        // Refresh only year and month dependent data, current user did not change
        await this.refreshTableDataYearMonthChange( year, month );
    }

	/**
	 * HANDLERS FOR TABS
	 */

	// Called when the user selects a different tab
	async handleTabChange( newValue ) {
        this.resetState( this.state.selectUser.userIdTabTeam, this.state.selectUser.userIdTabProject );

        // Refresh all data for currently selected year, month and user
        await this.refreshTableDataAll( newValue );
	}

    /**
     * HANDLER FOR USER SELECTION
     */

    // Called when a new user is selected in the team tab
    handleChangeSelectUserFieldTabTeam( value ) {
        this.refreshTableDataUserProjectSelectionChange( value, null );
    }

    // Called when a new user is selected in the project tab
    handleChangeSelectUserFieldTabProject( value ) {
        this.refreshTableDataUserProjectSelectionChange( value, null );
    }

    // Called when a new project is selected
    handleChangeSelectProjectField( value ) {

        // Check if currently selected project user is part of the freshly selected project, if he/she is not, reset to "ALL"
        let userIdTabProject = this.state.selectUser.usedIdTabProject;

        if( "" !== this.state.selectUser.userIdTabProject ) {
            let userFound = false;

            const usersForProject = this.getUsersForProject( value );
            for( let i = 0; i < usersForProject.length; i++ ) {
                if( usersForProject[i].userid === this.state.selectUser.userIdTabProject ) {
                    userFound = true;
                    break;
                }
            }

            if( false === userFound ) {
                userIdTabProject = "";
            }
        }


        this.refreshTableDataUserProjectSelectionChange( userIdTabProject, value );
    }

    /**
     * CALLBACKS FROM TAB CONTENT
     */

    // Modify worktimes
    async handleModifyWorkTime( userid, data ) {

        // Validity checks
        if( userid == null || userid === "" || data == null ) {
            return;
        }

        /**
         * At this point three things can happen:
         * * A new worktime entry is added if it does not yet exist (no matching work time entry found)
         * * A previously existing work time entry is removed (because one with the same start_time does not exist anymore)
         * * An already existing work time entry is updated (check is based on start_time)
         * So build a list of actions which need to be executed on the database
         */

        let actions = [];

        // Get all current worktime entries for this user
        const currentWorkTimes = this.getWorkTimesForUserAtDate( userid, data.dateJson );

        // First check if existing work time entries have been deleted or modified
        for( let i = 0; i < currentWorkTimes.length; i++ ) {
            let workTimeEntryStillExists = false;

            for( let j = 0; j < data.worktimes.today.length; j++ ) {
                if( currentWorkTimes[i].start_time === data.worktimes.today[j].start_time ) {
                    // Work time entry does still exist
                    workTimeEntryStillExists = true;

                    // Check if work time entry was modified
                    if( false === _.isEqual( currentWorkTimes[i], data.worktimes.today[j] ) ) {

                        // Entry was modified
                        let action = {
                            type: "modify",
                            dateJson: data.dateJson,
                            start_time: currentWorkTimes[i].start_time,
                            modifiedData: data.worktimes.today[j]
                        }
                        actions.push( action );
                    }

                    break;
                }
            }

            if( false === workTimeEntryStillExists ) {
                let action = {
                    type: "delete",
                    dateJson: data.dateJson,
                    start_time: currentWorkTimes[i].start_time
                }
                actions.push( action );
            }
        }
        
        // Now check if new entries need to be added
        for( let i = 0; i < data.worktimes.today.length; i++ ) {
            let workTimeEntryDoesNotExist = true;

            for( let j = 0; j < currentWorkTimes.length; j++ ) {

                if( currentWorkTimes[j].start_time === data.worktimes.today[i].start_time ) {
                    workTimeEntryDoesNotExist = false;
                    break;
                }
            }

            if( true === workTimeEntryDoesNotExist ) {
                let action = {
                    type: "add",
                    dateJson: data.dateJson,
                    newData: data.worktimes.today[i]
                }
                actions.push( action );
            }
        }

        // Now execute all the actions
        for( let i = 0; i < actions.length; i++ ) {

            if( actions[i].type === "add" ) {

                let workTimeInput = {
                    userid: userid,
                    date: actions[i].dateJson,
                    start_time: actions[i].newData.start_time,
                    end_time: actions[i].newData.end_time,
                    break: actions[i].newData.break,
                }

                const { statusCode, statusText } = await WorkTimeService.createWorkTime( workTimeInput );
                let statusCodeFinal = statusCode;
                let statusTextFinal = statusText;

                let alert = {
                    severity: "info",
                    message: ""
                }
        
                if( 200 === statusCodeFinal ) {
                    alert.severity = "success";
                    alert.message = i18n.t( "pages.main.alert.success.add_worktime" );
                } else {
                    alert.severity = "error";
                    alert.message = `${statusTextFinal}: ${statusCodeFinal}`;
                }
        
                this.handleAlertChanged( alert );

            } else if( actions[i].type === "delete" ) {

                let workTimeSearch = {
                    userid: userid,
                    date: actions[i].dateJson,
                    start_time: actions[i].start_time,
                }
        
                const { statusCode, statusText } = await WorkTimeService.deleteWorkTime( workTimeSearch );
                let statusCodeFinal = statusCode;
                let statusTextFinal = statusText;

                let alert = {
                    severity: "info",
                    message: ""
                }
        
                if( 200 === statusCodeFinal ) {
                    alert.severity = "success";
                    alert.message = i18n.t( "pages.main.alert.success.modified_worktime" );
                } else {
                    alert.severity = "error";
                    alert.message = `${statusTextFinal}: ${statusCodeFinal}`;
                }
        
                this.handleAlertChanged( alert );

            } else if( actions[i].type === "modify" ) {

                let workTimeSearch = {
                    userid: userid,
                    date: actions[i].dateJson,
                    start_time: actions[i].start_time
                }

                let workTimeUpdate = {
                    start_time: actions[i].modifiedData.start_time,
                    end_time: actions[i].modifiedData.end_time,
                    break: actions[i].modifiedData.break
                }

                const { statusCode, statusText } = await WorkTimeService.updateWorkTime( workTimeSearch, workTimeUpdate );
                let statusCodeFinal = statusCode;
                let statusTextFinal = statusText;

                let alert = {
                    severity: "info",
                    message: ""
                }
        
                if( 200 === statusCodeFinal ) {
                    alert.severity = "success";
                    alert.message = i18n.t( "pages.main.alert.success.modified_worktime" );
                } else {
                    alert.severity = "error";
                    alert.message = `${statusTextFinal}: ${statusCodeFinal}`;
                }
        
                this.handleAlertChanged( alert );
            }
        }

        // Refresh all data
        await this.refreshTableDataAccountingChange();
    }

    // Delete worktimes on given date
    async handleDeleteWorkTime( userid, dateJson ) {

        // Validity checks
        if( userid == null || userid === "" || dateJson == null || dateJson === "" ) {
            return;
        }

        // Check if there are any worktimes to delete
        if( false === this.hasUserWorkTimes( userid, dateJson ) ) {
            return;
        }

        let workTimeSearch = {
            userid: userid,
            date: dateJson
        }

        const { statusCode, statusText } = await WorkTimeService.deleteWorkTime( workTimeSearch );
        let statusCodeFinal = statusCode;
        let statusTextFinal = statusText;

        let alert = {
            severity: "info",
            message: ""
        }

        if( 200 === statusCodeFinal ) {
            alert.severity = "success";
            alert.message = i18n.t( "pages.main.alert.success.modified_worktime" );
        } else {
            alert.severity = "error";
            alert.message = `${statusTextFinal}: ${statusCodeFinal}`;
        }

        this.handleAlertChanged( alert );

        // Refresh all data
        await this.refreshTableDataAccountingChange();
    }

    // Modify the given accounting
    // entry { userid, date, hours, comment, taskid, specialid }
    async handleModifyAccounting( userid, entry ) {

        // Check if accounting entry changed
        const { state, reason, oldEntry } = this.didAccountingEntryChange( userid, entry );
        if( false === state ) {
            return;
        }

        // We can either have "new" or "modified" as reason
        // Handle the case where the entry is "new" but has no hours or comment included, then we do not create it
        if( reason === "new" && ( null == entry.hours || 0 === entry.hours ) && ( null == entry.comment || "" === entry.comment ) ) {
            return;
        }

        let newAccounting = {
            date: entry.date
        }

        // Fill in accounting struct
        if( reason === "new" ) {
            // For new entries
            if( null != entry.taskid ) { newAccounting.taskid = entry.taskid }
            if( null != entry.specialid ) { newAccounting.specialid = entry.specialid }
            if( typeof entry.comment !== 'undefined' && null != entry.comment ) { newAccounting.comment = entry.comment } else { newAccounting.comment = "" }
            if( typeof entry.hours !== 'undefined' && null != entry.hours ) { newAccounting.hours = entry.hours } else { newAccounting.hours = 0 }
        } else {
            // For existing entries
            if( null != entry.taskid ) { newAccounting.taskid = entry.taskid }
            if( null != entry.specialid ) { newAccounting.specialid = entry.specialid }
            if( typeof entry.comment !== 'undefined' && null != entry.comment ) { newAccounting.comment = entry.comment }
            if( typeof entry.hours !== 'undefined' && null != entry.hours ) { newAccounting.hours = entry.hours }
        }

        let statusCodeFinal = 200;
        let statusTextFinal = "";

        if( reason === "new" ) {

            newAccounting.userid = userid;
            const { statusCode, statusText } = await AccountingService.createAccounting( newAccounting );
            statusCodeFinal = statusCode;
            statusTextFinal = statusText;

        } else if( reason === "modified" ) {

            let accountingSearch = {
                accountingid: oldEntry.accountingid
            }

            // Modified can mean that we either update an existing entry with new values or that we delete it completely
            let entryDeleted = false;

            if( null == entry.hours || 0 === entry.hours ) {
                if( null == entry.comment || "" === entry.comment ) {
                    entryDeleted = true;
                    const { statusCode, statusText } = await AccountingService.deleteAccounting( accountingSearch );
                    statusCodeFinal = statusCode;
                    statusTextFinal = statusText;
                }
            }

            if( false === entryDeleted ) {
                const { statusCode, statusText } = await AccountingService.updateAccounting( accountingSearch, newAccounting );
                statusCodeFinal = statusCode;
                statusTextFinal = statusText;
            }
        }

        let alert = {
            severity: "info",
            message: ""
        }

        if( 200 === statusCodeFinal ) {
            alert.severity = "success";
            if( reason === "new" ) {
                alert.message = i18n.t( "pages.main.alert.success.add" );
            } else {
                alert.message = i18n.t( "pages.main.alert.success.modified" );
            }
        } else {
            alert.severity = "error";
            alert.message = `${statusTextFinal}: ${statusCodeFinal}`;
        }

        this.handleAlertChanged( alert );

        // Refresh all data
        await this.refreshTableDataAccountingChange();
    }

    /**
     * HELPERS
     */

    // Check if accounting entry changed
    didAccountingEntryChange( userid, entry ) {
        if( null == entry ) {
            return { state: false, reason: "null", oldEntry: null };
        }

        for( let i = 0; i < this.state.data.accountings.length; i++ ) {
            if( this.state.data.accountings[i].userid === userid ) {
                if( this.state.data.accountings[i].date === entry.date ) {
                    if( null != entry.taskid && entry.taskid === this.state.data.accountings[i].taskid ) {
                        if( null != entry.hours && ( entry.hours !== this.state.data.accountings[i].hours ) ) {
                            return { state: true, reason: "modified", oldEntry: this.state.data.accountings[i] };
                        }
                        if( null != entry.comment && ( entry.comment !== this.state.data.accountings[i].comment ) ) {
                            return { state: true, reason: "modified", oldEntry: this.state.data.accountings[i] };
                        }
                        return { state: false, reason: "found", oldEntry: this.state.data.accountings[i] };
                    }
                    if( null != entry.specialid && entry.specialid === this.state.data.accountings[i].specialid ) {
                        if( null != entry.hours && ( entry.hours !== this.state.data.accountings[i].hours ) ) {
                            return { state: true, reason: "modified", oldEntry: this.state.data.accountings[i] };
                        }
                        if( null != entry.comment && ( entry.comment !== this.state.data.accountings[i].comment ) ) {
                            return { state: true, reason: "modified", oldEntry: this.state.data.accountings[i] };
                        }
                        return { state: false, reason: "found", oldEntry: this.state.data.accountings[i] };
                    }
                }
            }
        }

        // Entry not found, so it changed
        return { state: true, reason: "new", oldEntry: null };
    }

    // Get all elements for given user
    getAccountingsForUser( userid ) {
        let result = [];
        for( let i = 0; i < this.state.data.accountings.length; i++ ) {
            if( this.state.data.accountings[i].userid === userid ) {
                result.push( _.cloneDeep( this.state.data.accountings[i] ) );
            }
        }
        return result;
    }

    // Get all work times for a given user at a given date
    getWorkTimesForUserAtDate( userid, dateJson ) {
        let result = [];
        for( let i = 0; i < this.state.data.worktimes.length; i++ ) {
            if( this.state.data.worktimes[i].userid === userid && this.state.data.worktimes[i].date === dateJson ) {
                result.push( _.cloneDeep( this.state.data.worktimes[i] ) );
            }
        }

        // Sort just by start_time since we are only searching on a specific date
        result.sort(function(a, b) { return fromTimeStringToDate(a.start_time) > fromTimeStringToDate(b.start_time) ? 1 : -1; });
        return result;
    }

    // Get all elements for a given user
    getWorkTimesForUser( userid ) {
        let result = [];
        for( let i = 0; i < this.state.data.worktimes.length; i++ ) {
            if( this.state.data.worktimes[i].userid === userid ) {
                result.push( _.cloneDeep( this.state.data.worktimes[i] ) );
            }
        }

        // Sort by date then by time on the given date
        result.sort( 
            function(a, b) {
                if( a.date === b.date ) {
                    // We are on the same date, sort by time
                    return fromTimeStringToDate(a.start_time) > fromTimeStringToDate(b.start_time) ? 1 : -1;
                } else {
                    // We are not on the same date, sort by date
                    return toDateObject(a.date) > toDateObject(b.date) ? 1 : -1;
                }
            }
        );

        return result;
    }

    // Check if the user has any worktimes on the given date
    hasUserWorkTimes( userid, dateJson ) {
        for( let i = 0; i < this.state.data.worktimes.length; i++ ) {
            if( this.state.data.worktimes[i].userid === userid ) {
                if( this.state.data.worktimes[i].date === dateJson ) {
                    return true;
                }
            }
        }

        return false;
    }

    // Get all elements for a given project
    getAccountingsForProject( tasksForProject ) {
        let result = [];
        for( let i = 0; i < this.state.data.accountings.length; i++ ) {
            let accountingIsForProject = false;
            for( let j = 0; j < tasksForProject.length; j++ ) {
                if( this.state.data.accountings[i].taskid === tasksForProject[j].taskid ) {
                    accountingIsForProject = true;
                    break;
                }
            }

            if( true === accountingIsForProject ) {
                result.push( _.cloneDeep( this.state.data.accountings[i] ) );
            }
        }
        return result;
    }

    // Get all elements for given user
    getProjectsForUser( userid ) {
        let result = [];

        for( let i = 0; i < this.state.data.usersToProjects.length; i++ ) {
            if( this.state.data.usersToProjects[i].userid === userid ) {
                for( let j = 0; j < this.state.data.projects.length; j++ ) {
                    if( this.state.data.projects[j].projectid === this.state.data.usersToProjects[i].projectid ) {
                        result.push( this.state.data.projects[j] );
                    }
                }
            }
        }
        result.sort(function(a, b) { return a.internal_id > b.internal_id ? 1 : -1; });
        return result;
    }

    // Get all elements for given user
    getTasksForUser( userid ) {
        let result = [];
        for( let i = 0; i < this.state.data.usersToTasks.length; i++ ) {
            if( this.state.data.usersToTasks[i].userid === userid ) {
                for( let j = 0; j < this.state.data.tasks.length; j++ ) {
                    if( this.state.data.tasks[j].taskid === this.state.data.usersToTasks[i].taskid ) {
                        result.push( this.state.data.tasks[j] );
                    }
                }
            }
        }
        result.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
        return result;
    }

    // Get all elements for a given project
    getTasksForProject( projectid ) {
        let result = [];
        for( let i = 0; i < this.state.data.tasks.length; i++ ) {
            if( this.state.data.tasks[i].projectid === projectid ) {
                result.push( this.state.data.tasks[i] );
            }
        }
        result.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
        return result;
    }

    // Get all elements for a given project
    getUsersForProject( projectid ) {
        let result = [];
        for( let i = 0; i < this.state.data.usersToProjects.length; i++ ) {
            if( this.state.data.usersToProjects[i].projectid === projectid ) {

                // Found a matching user, grab his/her user data
                const userData = this.getUserData( this.state.data.usersToProjects[i].userid );
                result.push( userData );
            }
        }
        return result;
    }

    // Get absences for a single user (startDate, endDate are timestamps and optional)
    getAbsencesForUser( userid, startDate, endDate ) {
        let result = [];
        for( let i = 0; i < this.state.data.absences.length; i++ ) {
            if( this.state.data.absences[i].userid === userid ) {
                if( null != startDate || null != endDate ) {
                    const absenceDate = toDateObject( this.state.data.absences[i].date ).getTime();
                    if( null != startDate && startDate > absenceDate ) {
                        continue;
                    }
                    if( null != endDate && endDate < absenceDate ) {
                        continue;
                    }
                }
                result.push( this.state.data.absences[i] );
            }
        }
        return result;
    }

    // Get all elements for given user
    getContractsForUser( userid ) {
        let result = [];
        for( let i = 0; i < this.state.data.contracts.length; i++ ) {
            if( this.state.data.contracts[i].userid === userid ) {
                result.push( this.state.data.contracts[i] );
            }
        }
        return result;
    }

    // Get data of user
    getUserData( userid ) {
        if( null == userid || userid === "" ) {
            return null;
        }

        let userData = null;

        // Get info for the current user
        for( let i = 0; i < this.state.data.users.length; i++ ) {
            if( this.state.data.users[i].userid === userid ) {
                userData = this.state.data.users[i];
            }
        }

        return userData;
    }

    // Get data of project
    getProjectData( projectid ) {
        let projectData = null;

        // Get info for the current user
        for( let i = 0; i < this.state.data.projects.length; i++ ) {
            if( this.state.data.projects[i].projectid === projectid ) {
                projectData = this.state.data.projects[i];
            }
        }

        return projectData;
    }

    // Generate the user accounting summary for the given month and year
    generateUserAccountingSummary( year, month ) {
        let summaryForUser = {
            hoursTarget: 0,
            hoursAccounted: 0,
            hoursDiff: 0,
            hoursOvertime: 0,
            hoursAdjusted: 0
        }

        let accountingReportData = this.state.data.accountingReport;

        // Compile the necessary information from the report
        for( let i = 0; i < accountingReportData.years.length; i++ ) {
            if( year === accountingReportData.years[i].year ) {
                for( let j = 0; j < accountingReportData.years[i].months.length; j++ ) {
                    if( month === (accountingReportData.years[i].months[j].month-1) ) {
                        summaryForUser.hoursTarget = accountingReportData.years[i].months[j].hours_target_in_month;
                        summaryForUser.hoursAccounted = accountingReportData.years[i].months[j].hours_accounted_in_month;
                        summaryForUser.hoursDiff = accountingReportData.years[i].months[j].hours_remaining_in_month;
                        summaryForUser.hoursAdjusted = accountingReportData.years[i].months[j].hours_adjusted_in_month;
                        break;
                    }
                }
            }
        }

        summaryForUser.hoursOvertime = accountingReportData.hours_overtime_today;

        return summaryForUser;
    }

    // Generate the project accounting summary
    generateProjectAccountingSummary( userid, accountingData, tasksForProject ) {

        let summaryForProject = {
            hoursAccounted: 0
        }

        for( let i = 0; i < accountingData.length; i++ ) {

            // Check if accounting belongs to a task within this project
            let belongsToProject = false;
            for( let j = 0; j < tasksForProject.length; j++ ) {
                if( accountingData[i].taskid === tasksForProject[j].taskid ) {
                    belongsToProject = true;
                    break;
                }
            }

            if( true === belongsToProject ) {
                let addToSum = false;
                if( userid == null || userid === "" ) {
                    addToSum = true;
                } else if( userid === accountingData[i].userid ) {
                    addToSum = true;
                }

                if( true === addToSum ) {
                    summaryForProject.hoursAccounted += accountingData[i].hours;
                }
            }
        }
        return summaryForProject;
    }

    // Check if the given day is a work day in the given contract
    isWorkDay( workDaysPerWeek, day ) {

        const weekDayIndex = day._d.getDay();
        let workDaysPerWeekIndex = weekDayIndex-1;
        if( workDaysPerWeekIndex < 0 ) {
            workDaysPerWeekIndex = workDaysPerWeekIndex + 7;
        }

        if( "1" === workDaysPerWeek[workDaysPerWeekIndex] ) {
            return true;
        }
        return false;
    }

	/**
	 * RENDER FUNCTIONS
	 */

    // Render personal time accounting
    renderTabMy() {
        const accountingsForUser = this.getAccountingsForUser( this.context.userid );
        const workTimesForUser = this.getWorkTimesForUser( this.context.userid );
        const projectsForUser = this.getProjectsForUser( this.context.userid );
        const tasksForUser = this.getTasksForUser( this.context.userid );
        const absencesForUser = this.getAbsencesForUser( this.context.userid );
        const contractsForUser = this.getContractsForUser( this.context.userid );
        const userData = this.getUserData( this.context.userid );
        const userAccountingSummaryData = this.generateUserAccountingSummary( 
            this.state.currentYear, 
            this.state.currentMonth
        );

        // Properly pass to the accounting table if certain days are locked due to being outside of the auto lock window
        let accountingAutoLock = false;
        let accountingAutoLockPeriodOfDays = 0;
        let specialAutoAccountingLock = true;
        let maxHoursPerDay = 24;
        let hoursPrecision = 0.25;

        if( null != this.context.config ) {
            if( null != this.context.config.accounting.auto_lock ) {
                accountingAutoLock = this.context.config.accounting.auto_lock;
            }
            if( null != this.context.config.accounting.auto_lock_period_in_days ) {
                accountingAutoLockPeriodOfDays = this.context.config.accounting.auto_lock_period_in_days;
            }
            if( null != this.context.config.accounting.max_hours_per_day ) {
                maxHoursPerDay = this.context.config.accounting.max_hours_per_day;
            }
            if( null != this.context.config.accounting.hours_precision ) {
                hoursPrecision = this.context.config.accounting.hours_precision;
            }
        }

		return (
			<div key="my-accounting-content">
				<AccountingUser
					year={this.state.currentYear}
                    month={this.state.currentMonth}
					language={this.context.language}
                    currentUser={userData}
                    accountings={accountingsForUser}
                    projects={projectsForUser}
                    tasks={tasksForUser}
                    holidays={this.state.data.holidays}
                    absences={absencesForUser}
                    contracts={contractsForUser}
                    specials={this.state.data.specials}
                    labelToday={i18n.t("values.today")}
                    labelNoWorkDay={i18n.t("values.no_work_day")}
                    labelWeekend={i18n.t("values.weekend")}
                    onModifyAccounting={(userid, entry, changeType ) => this.handleModifyAccounting( userid, entry, changeType )}
                    onModifyWorkTime={(userid, data) => this.handleModifyWorkTime( userid, data )}
                    onDeleteWorkTime={(userid, dateJson) => this.handleDeleteWorkTime( userid, dateJson )}
                    accountingAutoLock={accountingAutoLock}
                    accountingAutoLockPeriodOfDays={accountingAutoLockPeriodOfDays}
                    specialAutoAccountingLock={specialAutoAccountingLock}
                    maxHoursPerDay={maxHoursPerDay}
                    hoursPrecision={hoursPrecision}
                    worktimes={workTimesForUser}
				>
                    <Grid container className={this.props.classes.gridContainer} direction="row" spacing={0} wrap='nowrap' justify="flex-start" alignItems="flex-start">
                        <Grid item className={this.props.classes.gridItemSpecials} >
                            <SpecialLegend specials={this.state.data.specials} />
                        </Grid>
                        <Grid item className={this.props.classes.gridItemUserSummary} >
                            <UserAccountingSummary 
                                year={this.state.currentYear} 
                                month={this.state.currentMonth} 
                                data={userAccountingSummaryData}
                                language={this.context.language}
                            />
                        </Grid>
                    </Grid>
                </AccountingUser>
			</div>
		)
    }

    // Render team time accounting
    renderTabTeam() {
        let accountingUserContent = "";

        // Only draw if a user is selected
        if( null != this.state.selectUser.userIdTabTeam && this.state.selectUser.userIdTabTeam !== "" ) {
            const accountingsForUser = this.getAccountingsForUser( this.state.selectUser.userIdTabTeam );
            const workTimesForUser = this.getWorkTimesForUser( this.state.selectUser.userIdTabTeam );
            const projectsForUser = this.getProjectsForUser( this.state.selectUser.userIdTabTeam );
            const tasksForUser = this.getTasksForUser( this.state.selectUser.userIdTabTeam );
            const absencesForUser = this.getAbsencesForUser( this.state.selectUser.userIdTabTeam );
            const contractsForUser = this.getContractsForUser( this.state.selectUser.userIdTabTeam );
            const userData = this.getUserData( this.state.selectUser.userIdTabTeam );
            const userAccountingSummaryData = this.generateUserAccountingSummary( 
                this.state.currentYear, 
                this.state.currentMonth
            );

            // Properly pass to the accounting table if certain days are locked due to being outside of the auto lock window
            let maxHoursPerDay = 24;
            let hoursPrecision = 0.25;

            if( null != this.context.config ) {
                if( null != this.context.config.accounting.max_hours_per_day ) {
                    maxHoursPerDay = this.context.config.accounting.max_hours_per_day;
                }
                if( null != this.context.config.accounting.hours_precision ) {
                    hoursPrecision = this.context.config.accounting.hours_precision;
                }
            }

            // There are two features in the accounting which lock certain cells
            // - Rolling auto accounting window (if configured)
            // - Specials which auto account
            // The only users who shall ever bypass these features are global admins and admin_accountings
            let accountingAutoLock = false;
            let accountingAutoLockPeriodOfDays = 0;
            let specialAutoAccountingLock = true;

            // Fill in settings from config for rolling auto account window
            if( null != this.context.config ) {
                if( null != this.context.config.accounting.auto_lock ) {
                    accountingAutoLock = this.context.config.accounting.auto_lock;
                }
                if( null != this.context.config.accounting.auto_lock_period_in_days ) {
                    accountingAutoLockPeriodOfDays = this.context.config.accounting.auto_lock_period_in_days;
                }
            }

            // Allow bypass for global admin and admin_accountings
            if( true === this.state.data.admin.admin.global || true === this.state.data.admin.admin.accountings ) {
                accountingAutoLock = false;
                specialAutoAccountingLock = false;
            }

            accountingUserContent = (
                <AccountingUser
					year={this.state.currentYear}
                    month={this.state.currentMonth}
					language={this.context.language}
                    currentUser={userData}
                    worktimes={workTimesForUser}
                    accountings={accountingsForUser}
                    projects={projectsForUser}
                    tasks={tasksForUser}
                    holidays={this.state.data.holidays}
                    absences={absencesForUser}
                    contracts={contractsForUser}
                    specials={this.state.data.specials}
                    labelToday={i18n.t("values.today")}
                    labelNoWorkDay={i18n.t("values.no_work_day")}
                    labelWeekend={i18n.t("values.weekend")}
                    onModifyAccounting={( userid, entry, changeType ) => this.handleModifyAccounting( userid, entry, changeType )}
                    onModifyWorkTime={(userid, data) => this.handleModifyWorkTime( userid, data )}
                    onDeleteWorkTime={(userid, dateJson) => this.handleDeleteWorkTime( userid, dateJson )}
                    accountingAutoLock={accountingAutoLock}
                    accountingAutoLockPeriodOfDays={accountingAutoLockPeriodOfDays}
                    specialAutoAccountingLock={specialAutoAccountingLock}
                    maxHoursPerDay={maxHoursPerDay}
                    hoursPrecision={hoursPrecision}
				>
                    <Grid container className={this.props.classes.gridContainer} direction="row" spacing={0} wrap='nowrap' justify="flex-start" alignItems="flex-start">
                        <Grid item className={this.props.classes.gridItemSpecials} >
                            <SpecialLegend specials={this.state.data.specials} />
                        </Grid>
                        <Grid item className={this.props.classes.gridItemUserSummary} >
                            <UserAccountingSummary 
                                year={this.state.currentYear} 
                                month={this.state.currentMonth} 
                                data={userAccountingSummaryData}
                                language={this.context.language}
                            />
                        </Grid>
                    </Grid>
                </AccountingUser>
            )
        }

        return (
			<div key="team-accounting-content">
                <SelectControls
                    selectedEntry={this.state.selectUser.userIdTabTeam}
                    label={i18n.t("pages.main.select_user.label")}
                    helperText={i18n.t("pages.main.select_user.subtext")}
                    refreshTooltip={i18n.t("buttons.select_user.tooltip")}
                    items={this.state.selectUser.selectableUsers}
                    onChange={this.handleChangeSelectUserFieldTabTeam}
                    showButtons={false}
                />
				{accountingUserContent}
            </div>
        )
    }

    // Render project time accounting
    renderTabProject() {
        let accountingProjectContent = "";

        // Only draw if a project is selected
        if( null !== this.state.selectProject.projectId && this.state.selectProject.projectId !== "" && null != this.state.data.accountings ) {
            const projectData = this.getProjectData( this.state.selectProject.projectId );
            const tasksForProject = this.getTasksForProject( this.state.selectProject.projectId );
            const accountingsForProject = this.getAccountingsForProject( tasksForProject );
            const userData = this.getUserData( this.state.selectUser.userIdTabProject );
            const usersForProject = this.getUsersForProject( this.state.selectProject.projectId );
            const projectAccountingSummaryData = this.generateProjectAccountingSummary( 
                this.state.selectUser.userIdTabProject,
                this.state.data.accountings,
                tasksForProject
            );

            accountingProjectContent = (
                <AccountingProject 
                    year={this.state.currentYear}
                    month={this.state.currentMonth}
                    language={this.context.language}
                    accountings={accountingsForProject}
                    project={projectData}
                    tasks={tasksForProject}
                    holidays={this.state.data.holidays}
                    user={userData}
                    projectUsers={usersForProject}
                    labelToday={i18n.t("values.today")}
                    labelWeekend={i18n.t("values.weekend")}
                >
                    <Grid container className={this.props.classes.gridContainer} direction="row" spacing={0} wrap='nowrap' justify="flex-end" alignItems="flex-start">
                        <Grid item className={this.props.classes.gridItemProjectSummary} >
                            <ProjectAccountingSummary 
                                year={this.state.currentYear} 
                                month={this.state.currentMonth} 
                                data={projectAccountingSummaryData}
                                language={this.context.language}
                            />
                        </Grid>
                    </Grid>
                </AccountingProject>
            )
        }

        // Filter selectable Users based on selected project
        let usersForProject = this.getUsersForProject( this.state.selectProject.projectId );

        // Convert selectable user list to id,value style which SelectDouble expects
        let filteredSelectableUsers = [];
        for( let i = 0; i < usersForProject.length; i++ ) {
            let fullUserEntry = usersForProject[i].lastname + ", " + usersForProject[i].firstname;
            if( usersForProject[i].userTypeName != null && usersForProject[i].userTypeName !== "" ) {
                fullUserEntry = "(" + usersForProject[i].userTypeName + ") " + usersForProject[i].lastname + ", " + usersForProject[i].firstname;
            }
            filteredSelectableUsers.push( { id: usersForProject[i].userid, value: fullUserEntry, lastname: usersForProject[i].lastname } );
        }

        // Gather properties for both select fields
        let leftSelect = {
            selectedEntry: this.state.selectProject.projectId,
            helperText: i18n.t("pages.main.select_project.subtext"),
            refreshTooltip: i18n.t("buttons.select_project.tooltip"),
            items: this.state.selectProject.selectableProjects,
            noSelectionLabel: i18n.t("values.none"),
            onChange: this.handleChangeSelectProjectField
        }
        let rightSelect = {
            selectedEntry: this.state.selectUser.userIdTabProject,
            helperText: i18n.t("pages.main.select_user.subtext"),
            refreshTooltip: i18n.t("buttons.select_user.tooltip"),
            items: filteredSelectableUsers,
            noSelectionLabel: i18n.t("values.all"),
            onChange: this.handleChangeSelectUserFieldTabProject
        }

        return (
			<div key="project-accounting-content">
                <SelectDouble
                    leftSelectProps={leftSelect}
                    rightSelectProps={rightSelect}
                />
				{accountingProjectContent}
            </div>
        )
    }

    // React render
    render() {
        let contentTabControl = (
            <SelectYearMonth 
                language={this.context.language}
                variant="year-month"
                year={this.state.currentYear}
                month={this.state.currentMonth}
                maxYear={(new Date().getFullYear())+1}
                labelToday={i18n.t("values.today")}
                onYearMonthChange={(year, month) => this.handleYearMonthChange( year, month )}
                onRefresh={() => this.handleRefreshButtonClick()}
                refreshTooltip={i18n.t("buttons.refresh.tooltip")}
            />
        )

		let tabHeaderArray = [];
        let tabContentArray = [];

		if( null != this.state.data.admin ) {

            // General tab render functions
            let tabRenderFunctions = [ this.renderTabMy, this.renderTabTeam, this.renderTabProject ];

            // Always show my own tab
            let index = 0;
            tabHeaderArray[index] = { label: i18n.t("pages.main.tabs.my_time"), value: 0 };
            let tabContent = "";
            if( 0 === this.state.currentTabIndex ) {
                tabContent = tabRenderFunctions[0]();
            }
            tabContentArray[index] = tabContent;
            index++;

            // Show different tabs depending on permissions
            if( true === this.state.data.admin.admin.global || true === this.state.data.admin.admin.accountings || 
                this.state.data.admin.teamlead.length > 0 ) {
                tabHeaderArray[index] = { label: i18n.t("pages.main.tabs.team_time"), value: 1 };
                if( 1 === this.state.currentTabIndex ) {
                    tabContent = tabRenderFunctions[1]();
                }
                tabContentArray[index] = tabContent;
                index++;
            }

            if( true === this.state.data.admin.admin.global || true === this.state.data.admin.admin.accountings ||
                this.state.data.admin.projectlead.length > 0 ) {
                    tabHeaderArray[index] = { label: i18n.t("pages.main.tabs.project_time"), value: 2 };
                    if( 2 === this.state.currentTabIndex ) {
                        tabContent = tabRenderFunctions[2]();
                    }
                    tabContentArray[index] = tabContent;
                    index++;
            }
		}

        return (
			<React.Fragment>
				<div>
					<PageTitle title={i18n.t("pages.main.title")} />
                    {contentTabControl}
					<TabContainer
						tabIndex={this.state.currentTabIndex}
						tabHeader={tabHeaderArray}
						onChange={this.handleTabChange}
					>
						{tabContentArray.map((tabContent, i) => (
							<div key={"tab-content-"+i}>
								{tabContent}
							</div>
						))}
					</TabContainer>
				</div>
			</React.Fragment>
		)
    }
}

export default withStyles(useStyles, { withTheme: true })(MainPage);