import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { StyledDataTable } from '../components/StyledTable.js'
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Table Project User Detail
 */
class TableProjectUserDetail extends Component {
    state = {}

    // Render task summary
    renderTaskSummary( projectReport ) {

        let tableHeader = [];
        tableHeader.push({ id: 1, width: "66%", name: i18n.t("pages.reports.options.project.table_detail_users.tasks") });
        tableHeader.push({ id: 2, width: "33%", name: i18n.t("pages.reports.options.project.table_detail_users.accounted_hours") });

        let tableData = [];

        // If no user has been selected just show a warning
        if( null == this.props.userid || this.props.userid === "" ) {
            tableData.push({ key: i18n.t("pages.reports.options.project.userdetail.warning"), values: [ "" ] });
        } else {

            // Show all tasks of that user
            for( let i = 0; i < projectReport.resultUsers.length; i++ ) {
                if( this.props.userid === projectReport.resultUsers[i].userid ) {

                    // Sort by task name
                    projectReport.resultUsers[i].tasks.sort(function(a, b) { 
                        if( a.name > b.name ) return 1;
                        if( a.name < b.name ) return -1;
                        return 0;
                    });

                    for( let j = 0; j < projectReport.resultUsers[i].tasks.length; j++ ) {
                        tableData.push({ key: projectReport.resultUsers[i].tasks[j].name, values: [ projectReport.resultUsers[i].tasks[j].hours_accounted_total ]});
                    }
                    break;
                }
            }

            // Add a line at the bottom showing the total number of accounted hours of the user in this project
            for( let i = 0; i < projectReport.resultUsers.length; i++ ) {
                if( this.props.userid === projectReport.resultUsers[i].userid ) {
                    tableData.push({ bold: true, key: i18n.t("pages.reports.options.project.table_detail_users.total_on_project"), values: [ projectReport.resultUsers[i].hours_accounted_total ] });
                    break;
                }
            }
        }

        let table = (
            <StyledDataTable 
                tableKey="project-task-summary" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // Takes props: language, projectReport, dateMin, dateMax, userid
    render() {
        let taskSummary = "";
        if( null != this.props.projectReport ) {
            taskSummary = this.renderTaskSummary( this.props.projectReport );
        }

        return (
            <div>
                {taskSummary}
            </div>
        );
    }
};

export default withStyles(useStyles)(TableProjectUserDetail);