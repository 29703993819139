import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PageTitle from '../../components/PageTitle';
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Admin No Access
 */
class AdminNoAccess extends Component {

    // Called by react when this component has been mounted
    componentDidMount() {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( true );
        }
    }

    // Render content
    render() {
        return (
            <React.Fragment>
                <div>
                    <PageTitle title={i18n.t("pages.admin.noaccess.title")} />
                    <p>{i18n.t("pages.admin.noaccess.desc")}</p>
                </div>
            </React.Fragment>
        )
    }
};

export default withStyles(useStyles)(AdminNoAccess);