import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CalendarHelper from '../classes/CalendarHelper.js';
import { StyledDataTable } from './StyledTable.js'
import i18n from 'i18next';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const useStyles = theme => ({});

/**
 * Table Absence
 */
class TableAbsenceMonth extends Component {
    state = {}

    // Check if a given date is a holiday
    isHoliday( searchDate, holidays ) {
        let isHoliday = false;
        let holidayName = "";

        for( let i = 0; i < holidays.length; i++ ) {
            const holidayDate = moment(holidays[i].date, 'YYYY-MM-DD');

            if( true === searchDate.isSame( holidayDate ) ) {
                isHoliday = true;
                holidayName = holidays[i].name;
                break;
            }
        }
        return { isHoliday, holidayName };
    }

    // Add to table data array
    addTableData( name, value ) {
        return { name, value };
    }

    /// Get series: booked absence days
    getSeriesAbsences( absences, specials, contracts, holidays, year, month ) {
        let series = [];

        if( null != absences && null != specials && null != contracts && null != holidays ) {

            const weekDaysForMonth = CalendarHelper.getWeekDaysForMonth( this.props.language, "short", year, month-1 );
            const maxDayInMonth = CalendarHelper.getDaysInMonth( year, month-1 );

            for( let day = 1; day <= maxDayInMonth; day++ ) {

                let seriesEntry = {
                    day: day,
                    is_holiday: false,
                    holiday_name: "",
                    is_weekend: false,
                    day_name: weekDaysForMonth[day-1].name,
                    absent_employees: 0,
                    present_employees: 0,
                    total_employees: 0
                }

                if( weekDaysForMonth[day-1].index === 0 || weekDaysForMonth[day-1].index === 6 ) {
                    seriesEntry.is_weekend = true;
                }

                // Calculate total employees on this day
                const searchDate = moment(new Date( year, month-1, day ));

                // Check if date is a holiday
                const { isHoliday, holidayName } = this.isHoliday( searchDate, holidays );
                seriesEntry.is_holiday = isHoliday;
                seriesEntry.holiday_name = holidayName;

                for( let i = 0; i < contracts.length; i++ ) {

                    const startDate = moment(contracts[i].date_start, 'YYYY-MM-DD');
                    let endDate = moment( "2999-12-31", 'YYYY-MM-DD' );
                    if( 'date_end' in contracts[i] && null !== contracts[i].date_end ) {
                        endDate = moment(contracts[i].date_end, 'YYYY-MM-DD');
                    }
                    const contractDateRange = moment.range( startDate, endDate );

                    if( true === contractDateRange.contains( searchDate ) ) {

                        // Current day is within contract of employee, check if its a work day for that employee
                        const dateObject = new Date( year, month-1, day );
                        if( true === CalendarHelper.isWorkDay( contracts, contracts[i].userid, dateObject ) ) {
                            seriesEntry.total_employees += 1;
                        }
                    }
                }

                // Calculate absent employees on this day
                for( let i = 0; i < absences.length; i++ ) {

                    const absenceDate = moment(absences[i].date);
                    if( true === absenceDate.isSame( searchDate ) ) {
                        seriesEntry.absent_employees += 1;
                    }
                }

                // Calculate present employees on this day
                seriesEntry.present_employees = seriesEntry.total_employees - seriesEntry.absent_employees;

                // Adjust if holiday
                if( true === seriesEntry.is_holiday ) {
                    seriesEntry.absent_employees = seriesEntry.total_employees;
                    seriesEntry.present_employees = 0;
                }

                series.push( seriesEntry );
            }
        }

        return series;
    }

    // Get the summary for the export
    getSummary( absences, contracts, holidays, year, month ) {
        let summary = [];

        if( null != absences && null != contracts && null != holidays ) {

            const weekDaysForMonth = CalendarHelper.getWeekDaysForMonth( this.props.language, "short", year, month-1 );
            const maxDayInMonth = CalendarHelper.getDaysInMonth( year, month-1 );

            for( let day = 1; day <= maxDayInMonth; day++ ) {

                let entry = {
                    day: day,
                    is_holiday: false,
                    holiday_name: "",
                    is_weekend: false,
                    day_name: weekDaysForMonth[day-1].name,
                    absent_employees: 0,
                    present_employees: 0,
                    total_employees: 0
                }

                if( weekDaysForMonth[day-1].index === 0 || weekDaysForMonth[day-1].index === 6 ) {
                    entry.is_weekend = true;
                }

                // Calculate total employees on this day
                const searchDate = moment(new Date( year, month-1, day ));

                // Check if date is a holiday
                const { isHoliday, holidayName } = this.isHoliday( searchDate, holidays );
                entry.is_holiday = isHoliday;
                entry.holiday_name = holidayName;

                for( let i = 0; i < contracts.length; i++ ) {

                    const startDate = moment(contracts[i].date_start, 'YYYY-MM-DD');
                    let endDate = moment( "2999-12-31", 'YYYY-MM-DD' );
                    if( 'date_end' in contracts[i] && null !== contracts[i].date_end ) {
                        endDate = moment(contracts[i].date_end, 'YYYY-MM-DD');
                    }
                    const contractDateRange = moment.range( startDate, endDate );

                    if( true === contractDateRange.contains( searchDate ) ) {

                        // Current day is within contract of employee, check if its a work day for that employee
                        const dateObject = new Date( year, month-1, day );
                        if( true === CalendarHelper.isWorkDay( contracts, contracts[i].userid, dateObject ) ) {
                            entry.total_employees += 1;
                        }
                    }
                }

                // Calculate absent employees on this day
                for( let i = 0; i < absences.length; i++ ) {

                    const absenceDate = moment(absences[i].date);
                    if( true === absenceDate.isSame( searchDate ) ) {
                        entry.absent_employees += 1;
                    }
                }

                // Calculate present employees on this day
                entry.present_employees = entry.total_employees - entry.absent_employees;

                // Adjust if holiday
                if( true === entry.is_holiday ) {
                    entry.absent_employees = entry.total_employees;
                    entry.present_employees = 0;
                }

                summary.push( entry );
            }
        }

        return summary;
    }

    // Render summary
    renderSummary( absences, specials, holidays, year, month ) {

        // If no specific year or month is selected, show a warning in this table since it does not make sense
        let tableHeader = [];
        let tableData = [];

        if( year !== "" && month !== "" ) {
            tableHeader = [
                { id: 1, width: "50%", name: i18n.t("pages.reports.options.absence.summary.headline_summary") },
                { id: 2, width: "50%", name: i18n.t("pages.reports.options.absence.summary.headline_days") }
            ];

            const searchStartDate = moment(new Date( year, month-1, 1 ));
            const searchEndDate = moment(new Date( year, month-1, 31 ));
            const searchDateRange = moment.range( searchStartDate, searchEndDate );

            // Add a line for holidays in the given month
            let sumOfDaysForHolidays = 0;
            for( let i = 0; i < holidays.length; i++ ) {
                const holidayDate = moment(holidays[i].date, 'YYYY-MM-DD');

                if( true === searchDateRange.contains( holidayDate ) ) {
                    sumOfDaysForHolidays++;
                }
            }
            tableData.push({ key: i18n.t("pages.reports.options.absence.summary.entry_holidays"), values: [ sumOfDaysForHolidays ] });

            // Add a line for each used absence reason
            if( null != specials ) {
                specials.sort(function(a, b) { return a.name > b.name ? 1 : -1; });

                for( let i = 0; i < specials.length; i++ ) {
                    let specialid = specials[i].specialid;
                    let sumOfDaysForSpecial = 0;

                    for( let j = 0; j < absences.length; j++ ) {
                        if( absences[j].specialid === specialid ) {
                            const absenceDate = moment(absences[j].date, 'YYYY-MM-DD');

                            if( true === searchDateRange.contains( absenceDate ) ) {
                                sumOfDaysForSpecial++;
                            }
                        }
                    }

                    tableData.push({ key: specials[i].name, values: [ sumOfDaysForSpecial ] });
                }
            }

        } else {
            tableHeader = [
                { id: 1, width: "50%", name: i18n.t("pages.reports.options.absence.summary.headline_summary") },
                { id: 2, width: "50%", name: "" }
            ];
            tableData.push({ key: i18n.t("pages.reports.options.absence.summary.warning"), values: [ "" ] });
        }

        let table = (
            <StyledDataTable 
                tableKey="absence-table-summary" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // Render absence table
    renderTable( absences, specials, contracts, holidays, year, month ) {

        // If no specific year is given, the table makes no sence
        if( year === "" || month === "" ) {
            return "";
        }

        const seriesAbsences = this.getSeriesAbsences( absences, specials, contracts, holidays, year, month );

        // Assemble table content
        const tableHeader = [
            { id: 1, width: "20%", name: i18n.t("pages.reports.options.absence.table_month.date") },
            { id: 2, width: "20%", name: i18n.t("pages.reports.options.absence.table_month.day") },
            { id: 3, width: "20%", name: i18n.t("pages.reports.options.absence.table_month.absent_employees") },
            { id: 4, width: "20%", name: i18n.t("pages.reports.options.absence.table_month.present_employees") },
            { id: 5, width: "20%", name: i18n.t("pages.reports.options.absence.table_month.total_employees") }
        ];

        let tableData = [];

        if( seriesAbsences.length > 0 ) {
            for( let i = 0; i < seriesAbsences.length; i++ ) {
                if( true === seriesAbsences[i].is_holiday ) {
                    let tableRow = {
                        key: i18n.t("pages.reports.options.absence.summary.dayentry_isholiday", {day: seriesAbsences[i].day, name: seriesAbsences[i].holiday_name } ),
                        values: [
                            seriesAbsences[i].day_name,
                            seriesAbsences[i].absent_employees,
                            seriesAbsences[i].present_employees,
                            seriesAbsences[i].total_employees
                        ]
                    }
    
                    tableData.push(tableRow);
                } else {
                    let tableRow = {
                        key: seriesAbsences[i].day,
                        values: [
                            seriesAbsences[i].day_name,
                            seriesAbsences[i].absent_employees,
                            seriesAbsences[i].present_employees,
                            seriesAbsences[i].total_employees
                        ]
                    }
    
                    tableData.push(tableRow);
                }
            }
        } else {
            tableData.push({ key: i18n.t("pages.reports.options.absence.table.no_data"), values: [ "" ] });
        }

        let table = (
            <StyledDataTable 
                tableKey="absence-table-detail" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // Takes props: language, absenceReport
    render() {
        let absenceSummary = "";
        let absenceTable = "";
        if( null != this.props.absences && null != this.props.specials && null != this.props.year && null != this.props.month && null != this.props.contracts ) {
            absenceSummary = this.renderSummary( this.props.absences, this.props.specials, this.props.holidays, this.props.year, this.props.month );
            absenceTable = this.renderTable( this.props.absences, this.props.specials, this.props.contracts, this.props.holidays, this.props.year, this.props.month );
        }

        return (
            <div>
                {absenceSummary}
                <br />
                {absenceTable}
            </div>
        );
    }
};

export default withStyles(useStyles)(TableAbsenceMonth);