import UserService from './UserService';
import ProjectService from './ProjectService';
import UsersToProjectsService from './UsersToProjectsService';
import UserTypeService from './UserTypeService'
import TeamService from './TeamService';
import UsersToTeamsService from './UsersToTeamsService';
import RoleService from './RoleService';
import UsersToRolesService from './UsersToRolesService';
import TaskService from './TaskService';
import UsersToTasksService from './UsersToTasksService';
import ContractService from './ContractService';
import i18n from 'i18next';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 */
class MultiService {

    /**
     * Get all projects of the specified user
     * returns { statusCode, statustext, resData }
     * With resData being an array of: [ { projectid, name, is_project_lead }]
     */
    async getUserProjects( userid ) {

        let rawData = {
            projects: null,
            usersToProjects: null
        }

        // Query project data
        {
            const { statusCode, statusText, resData } = await ProjectService.getProjects( {} );
            if( 200 === statusCode && null != resData ) {
                rawData.projects = resData.getProjects;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: null 
                }
            }
        }
        // Query for project specific data on this user
        {
            const { statusCode, statusText, resData } = await UsersToProjectsService.getUsersToProjects( { userid: userid } );
            if( 200 === statusCode && null != resData ) {
                rawData.usersToProjects = resData.getUsersToProjects;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: null 
                }
            }
        }

        // Assemble return data
        let returnData = []

        if( null != rawData.usersToProjects && null != rawData.projects ) {
            // Iterate through all projects of this user and assemble the data
            for (let i = 0; i < rawData.usersToProjects.length; i++) {
                const user_projectid = rawData.usersToProjects[i].projectid;

                let project = {
                    projectid: user_projectid,
                    name: null,
                    is_project_lead: rawData.usersToProjects[i].is_project_lead
                }

                for (let j = 0; j < rawData.projects.length; j++) {
                    if( user_projectid === rawData.projects[j].projectid ) {
                        project.name = rawData.projects[j].name;
                        break;
                    }
                }

                returnData.push(project);
            }
        } else {
            return { 
                statusCode: "999",
                statusText: i18n.t("errors.no_data_received"),
                resData: null 
            }
        }

        return {
            statusCode: 200,
            statusText: "",
            resData: returnData 
        }
    }

    
    /**
     * Get all tasks of the specified user
     * returns { statusCode, statustext, resData }
     * With resData being an array of: [ { taskid, name, status, projectid }]
     */
    async getUserTasks( userid ) {

        let rawData = {
            tasks: null,
            usersToTasks: null
        }

        // Query task data
        {
            const { statusCode, statusText, resData } = await TaskService.getTasks( {} );
            if( 200 === statusCode && null != resData ) {
                rawData.tasks = resData.getTasks;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: null 
                }
            }
        }
        // Query for task specific data on this user
        {
            const { statusCode, statusText, resData } = await UsersToTasksService.getUsersToTasks( { userid: userid } );
            if( 200 === statusCode && null != resData ) {
                rawData.usersToTasks = resData.getUsersToTasks;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: null 
                }
            }
        }

        // Assemble return data
        let returnData = []

        if( null != rawData.usersToTasks && null != rawData.tasks ) {
            // Iterate through all projects of this user and assemble the data
            for (let i = 0; i < rawData.usersToTasks.length; i++) {
                const user_taskid = rawData.usersToTasks[i].taskid;

                let task = {
                    taskid: user_taskid,
                    name: null,
                    status: null,
                    projectid: null
                }

                for (let j = 0; j < rawData.tasks.length; j++) {
                    if( user_taskid === rawData.tasks[j].taskid ) {
                        task.name = rawData.tasks[j].name;
                        task.status = rawData.tasks[j].status;
                        task.projectid = rawData.tasks[j].projectid;
                        break;
                    }
                }

                returnData.push(task);
            }
        } else {
            return { 
                statusCode: "999",
                statusText: i18n.t("errors.no_data_received"),
                resData: null 
            }
        }

        return {
            statusCode: 200,
            statusText: "",
            resData: returnData 
        }
    }

    /**
     * Get all teams of the specified user
     * returns { statusCode, statustext, resData }
     * With resData being an array of: [ { teamid, name, is_team_lead }]
     */
    async getUserTeams( userid ) {

        let rawData = {
            teams: null,
            usersToTeams: null
        }

        // Query team data
        {
            const { statusCode, statusText, resData } = await TeamService.getTeams( {} );
            if( 200 === statusCode && null != resData ) {
                rawData.teams = resData.getTeams;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: null 
                }
            }
        }
        // Query for team specific data on this user
        {
            const { statusCode, statusText, resData } = await UsersToTeamsService.getUsersToTeams( { userid: userid } );
            if( 200 === statusCode && null != resData ) {
                rawData.usersToTeams = resData.getUsersToTeams;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: null 
                }
            }
        }

        // Assemble return data
        let returnData = []

        if( null != rawData.usersToTeams && null != rawData.teams ) {
            // Iterate through all teams of this user and assemble the data
            for (let i = 0; i < rawData.usersToTeams.length; i++) {
                const user_teamid = rawData.usersToTeams[i].teamid;

                let team = {
                    teamid: user_teamid,
                    name: null,
                    is_team_lead: rawData.usersToTeams[i].is_team_lead
                }

                for (let j = 0; j < rawData.teams.length; j++) {
                    if( user_teamid === rawData.teams[j].teamid ) {
                        team.name = rawData.teams[j].name;
                        break;
                    }
                }

                returnData.push(team);
            }
        } else {
            return { 
                statusCode: "999",
                statusText: i18n.t("errors.no_data_received"),
                resData: null 
            }
        }

        return {
            statusCode: 200,
            statusText: "",
            resData: returnData 
        }
    }

    /**
     * Get all roles of the specified user
     * returns { statusCode, statustext, resData }
     * With resData being an array of: [ { roleid, name, admin_* flags }]
     */
    async getUserRoles( userid ) {

        let rawData = {
            roles: null,
            usersToRoles: null
        }
        let returnData = []

        // Query role data
        {
            const { statusCode, statusText, resData } = await RoleService.getRoles( {} );
            if( 200 === statusCode && null != resData ) {
                rawData.roles = resData.getRoles;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: null 
                }
            }
        }
        // Query for role specific data on this user
        {
            const { statusCode, statusText, resData } = await UsersToRolesService.getUsersToRoles( { userid: userid } );
            if( 200 === statusCode && null != resData ) {
                rawData.usersToRoles = resData.getUsersToRoles;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: null 
                }
            }
        }

        if( null != rawData.usersToRoles && null != rawData.roles ) {
            // Iterate through all roles of this user and assemble the data
            for (let i = 0; i < rawData.usersToRoles.length; i++) {
                const user_roleid = rawData.usersToRoles[i].roleid;

                let role = {}

                for (let j = 0; j < rawData.roles.length; j++) {
                    if( user_roleid === rawData.roles[j].roleid ) {
                        role = rawData.roles[j];
                        break;
                    }
                }

                returnData.push(role);
            }
        } else {
            return { 
                statusCode: "999",
                statusText: i18n.t("errors.no_data_received"),
                resData: null 
            }
        }

        return {
            statusCode: 200,
            statusText: "",
            resData: returnData 
        }
    }

    /**
     * Get the user's properties
     * returns { statusCode, statustext, resData }
     * With resData being a struct of:
     * {
            user: {userid, email, firstname, lastname, account_enabled, is_admin, language, last_login}
            teams: [], {teamid, name, is_team_lead}
            projects: [], {projectid, name, is_project_lead}
            roles: [], {roleid, name}
            contracts: [], {contractid, working_hours, work_days_per_week, vacation_days_per_week, non_working_days, date_start, date_end, userid, vacation_days_at_start, work_hours_at_start}
            admin { global, users, teams, projects, roles, specials, holidays, absences, tasks, accountings, contracts }
        }
     */
    async getUserProps( userid ) {

        /**
         * LOAD DATA FROM BACKEND
         */
        let returnData = {
            user: null,
            teams: null,
            projects: null,
            roles: null,
            contracts: null,
            tasks: null,
            admin: {
                global: false,
                users: false,
                teams: false,
                projects: false,
                roles: false,
                specials: false,
                holidays: false,
                absences: false,
                tasks: false,
                accountings: false,
                contracts: false
            }
        }

        // Query user data
        {
            const { statusCode, statusText, resData } = await UserService.getUsers( { userid: userid } );
            if( 200 === statusCode && null != resData ) {
                returnData.user = resData.getUsers[0];
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: null 
                }
            }
        }

        // Gather user type data
        let userTypeData = null;
        {
            const { statusCode, statusText, resData } = await UserTypeService.getUserTypes( { typeid: returnData.user.typeid } );
            if( 200 === statusCode && null != resData ) {
                userTypeData = resData.getUserTypes;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: null 
                }
            }
        }
        if( userTypeData.length > 0 ) {
            let userTypeName = "";
            for( let i = 0; i < userTypeData.length; i++ ) {
                if( userTypeData[i].typeid === returnData.user.typeid ) {
                    userTypeName = userTypeData[i].name;
                    break;
                }
            }
            returnData.user.userTypeName = userTypeName;
        }

        // Query teams data
        {
            const { statusCode, statusText, resData } = await this.getUserTeams( userid );
            if( 200 === statusCode && null != resData ) {
                returnData.teams = resData;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: null 
                }
            }
        }

        // Query projects data
        {
            const { statusCode, statusText, resData } = await this.getUserProjects( userid );
            if( 200 === statusCode && null != resData ) {
                returnData.projects = resData;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: null 
                }
            }
        }

        // Query projects data
        {
            const { statusCode, statusText, resData } = await this.getUserTasks( userid );
            if( 200 === statusCode && null != resData ) {
                returnData.tasks = resData;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: null 
                }
            }
        }

        // Query roles data
        {
            const { statusCode, statusText, resData } = await this.getUserRoles( userid );
            if( 200 === statusCode && null != resData ) {
                returnData.roles = resData;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: null 
                }
            }
        }

        // Query contracts data
        {
            const { statusCode, statusText, resData } = await ContractService.getContracts( { userid: userid } );
            if( 200 === statusCode && null != resData ) {
                returnData.contracts = resData.getContracts;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: null 
                }
            }
        }

        /**
         * PROCESS LOADED DATA
         */

        // Generate admin info
        if( null != returnData.user ) {
            returnData.admin.global = returnData.user.is_admin;
        }

        for (var roleEntry of returnData.roles ) {

            if( roleEntry.admin_users === true ) { returnData.admin.users = true; }
            if( roleEntry.admin_teams === true ) { returnData.admin.teams = true; }
            if( roleEntry.admin_projects === true ) { returnData.admin.projects = true; }
            if( roleEntry.admin_roles === true ) { returnData.admin.roles = true; }
            if( roleEntry.admin_specials === true ) { returnData.admin.specials = true; }
            if( roleEntry.admin_holidays === true ) { returnData.admin.holidays = true; }
            if( roleEntry.admin_absences === true ) { returnData.admin.absences = true; }
            if( roleEntry.admin_tasks === true ) { returnData.admin.tasks = true; }
            if( roleEntry.admin_accountings === true ) { returnData.admin.accountings = true; }
            if( roleEntry.admin_contracts === true ) { returnData.admin.contracts = true; }
        }
        
        // Return results
        return { 
            statusCode: 200,
            statusText: "",
            resData: returnData 
        }
    }
}

export default new MultiService();