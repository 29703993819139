import React, { Component } from 'react';
import SpecialService from '../../classes/SpecialService'
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { StyledTableCell, StyledTableRow, StyledTableHead, StyledTable, StyledTableSortLabel, stableSort, getComparator } from '../../components/StyledTable.js'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../components/PageTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ModalDialog from '../../components/ModalDialog';
import ModalDialogTextField from '../../components/ModalDialogTextField';
import ModalDialogColorPicker from '../../components/ModalDialogColorPicker';
import CustomTextField from '../../components/CustomTextField'
import CheckboxTooltip from '../../components/CheckboxTooltip'
import SearchControls from '../../components/SearchControls'
import Paper from '@material-ui/core/Paper';
import { config } from '../../config';
import i18n from 'i18next';

const useStyles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    mainGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    searchAddGrid: {
        display: 'flex',
        height: '80px',
        width: '100%',
        justifyContent: 'space-between',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tableCellRoleName: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1.0),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
    },
    tablePaginationGrid: {
        height: '60px',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tablePagination: {
        '& .MuiTablePagination-toolbar': {
            height: '60px',
            minHeight: '60px',
        },
    },
    searchFieldDiv: {
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(1.5),
            width: '40ch',
        },
    },
});

/**
 * Admin Specials
 */
class AdminSpecials extends Component {
    // Set in constructor
    state = {}

    // Keys to identify the clicked checkbox column
    keys = {
        is_visible: 1,
        is_enabled: 2,
        auto_accounting: 3,
        allow_accounting: 4,
        approval_required: 5
    }

    /**
     * Inputs Props
     * @param {*} props 
     *  admin (array)
     *  userProps (array)
     *  dataLoadChanged (function)
     *  alertChanged (function)
     */
    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleTableRowCheckboxChange = this.handleTableRowCheckboxChange.bind( this );
        this.handleTableRowDeleteClick = this.handleTableRowDeleteClick.bind( this );
        this.handleTableRowModifyClick = this.handleTableRowModifyClick.bind( this );
        this.handleChangeTablePage = this.handleChangeTablePage.bind( this );
        this.handleChangeTableRowsPerPage = this.handleChangeTableRowsPerPage.bind( this );
        this.handleChangeSearchField = this.handleChangeSearchField.bind( this );
        this.handleAddButtonClick = this.handleAddButtonClick.bind( this );
        this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind( this );
        this.handleAddDialog_Ok = this.handleAddDialog_Ok.bind( this );
        this.handleAddDialog_Cancel = this.handleAddDialog_Cancel.bind( this );
        this.verifyAddDialog_Name = this.verifyAddDialog_Name.bind( this );
        this.verifyAddDialog_ShortName = this.verifyAddDialog_ShortName.bind( this );
        this.handleTableRowDeleteDialog_Ok = this.handleTableRowDeleteDialog_Ok.bind( this );
        this.handleTableRowDeleteDialog_Cancel = this.handleTableRowDeleteDialog_Cancel.bind( this );
        this.getDeleteButtonDataForSpecial = this.getDeleteButtonDataForSpecial.bind( this );
        this.handleSpecialNameFieldChange = this.handleSpecialNameFieldChange.bind( this );
        this.verifySpecialNameFieldContent = this.verifySpecialNameFieldContent.bind( this );
        this.handleSpecialShortNameFieldChange = this.handleSpecialShortNameFieldChange.bind( this );
        this.verifySpecialShortNameFieldContent = this.verifySpecialShortNameFieldContent.bind( this );
        this.handleTypeChange = this.handleTypeChange.bind( this );
        this.getSpecialColorCode = this.getSpecialColorCode.bind( this );
        this.handleRequestSort = this.handleRequestSort.bind( this );
        this.sortTableData = this.sortTableData.bind( this );

        // Set initial state
        this.state = this.getInitialState();
    }

    /**
     * STATE HANDLING
     */

    // Get the initial state variables of this component
    getInitialState() {
        return {
            data: { // Raw loaded data from the backend
                specials: null,
            },
            tableData: { // Additional information gathered from loaded raw data to display on the page
                deleteButtonsForSpecial: [] // If the delete button is enabled and which tooltip is shown for that role
            },
            searchFieldEntry: "",
            tablePage: 0,
            tableRowsPerPage: 10,
            order: "asc",
            orderBy: 0,
            addDialog: {
                open: false
            },
            deleteDialog: {
                open: false,
                specialid: null,
                specialname: null
            },
            modifyDialog: {
                open: false,
                specialid: null,
                name: null,
                colorcode: null
            },
        }
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState() {
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
        this.setState( this.getInitialState() );
    }

    /**
     * LINK TO PARENT COMPONENT FUNCTIONS
     */

    // Propagate information to parent component
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Propagate information to parent component
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Propagate information to parent component
    handleActionInProgressChanged( actionInProgress ) {
        if( this.props.actionInProgressChanged ) {
            this.props.actionInProgressChanged( actionInProgress );
        }
    }

    /**
     * LOAD AND PROCESS BACKEND DATA
     */

    // Called when a refresh of the table data is needed due to edits in the table
    async refreshTableData() {
        await this.loadDataFromBackend( true );
    }

    // Gather all data from the backend needed on this page and process them properly
    async loadDataFromBackend( manualRefresh ) {
        // Alert message to show
        let alert = {
            severity: "info",
            message: ""
        }

        let specialData = null;

        // Gather special data
        {
            const { statusCode, statusText, resData } = await SpecialService.getSpecials( {} );
            if( 200 === statusCode && null != resData ) {
                specialData = resData.getSpecials;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Now process the data
        await this.processData( specialData );

        // All fine, clear the error message (only if not a manual refresh)
        if( false === manualRefresh ) {
            this.handleAlertChanged( alert );
        }
        this.handleDataLoadedChanged( true );
    }

    // Called to make additional processing of data, for example gathering info from that backend data and storing it for later use
    async processData( specialData ) {

        // Abort if no proper data given
        if( null == specialData ) {
            return;
        }

        // Get the info from specialData if there are currently bookings or accountings attached
        // and enable/disable the delete button accordingly
        let deleteButtonsArray = [];

        for( let i = 0; i < specialData.length; i++ ) {

            let deleteButtonElement = {
                specialid: specialData[i].specialid,
                enabled: false,
                tooltip: i18n.t("pages.admin.specials.delete.button.tooltip.disabled")
            }

            if( false === specialData[i].has_accountings && false === specialData[i].has_absences ) {
                deleteButtonElement.enabled = true;
                deleteButtonElement.tooltip = i18n.t("pages.admin.specials.delete.button.tooltip.enabled");
            }

            deleteButtonsArray[i] = deleteButtonElement;
        }

        // Store the data
        this.setState({ 
            data: { specials: specialData }, 
            tableData: { deleteButtonsForSpecial: deleteButtonsArray } 
        });
    }

    /**
     * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
     */

    // Called by react when this component has been mounted
    async componentDidMount() {
        await this.loadDataFromBackend();
    }

    // Called by react before this component unmounts
    componentWillUnmount() {
        this.resetState();
    }

    /**
     * ALERT DIALOG <ADD>
     */

    // Called to open the add dialog
    openAddDialog() {
        let addDialog = {
            open: true
        }
        this.setState({ addDialog: addDialog });
    }

    // Called when the user clicked "Ok" on the add role dialog
    async handleAddDialog_Ok( name, short_name ) {
        this.closeAddDialog();

        // Add the special
        if( null != name ) {
            let specialValues = {
                name: name,
                is_visible: false,
                is_enabled: false,
                allow_accounting: false,
                short_name: short_name,
                color_code: "000000",
                type: "none",
                auto_accounting: false,
                approval_required: false
            }

            const { statusCode, statusText, resData } = await SpecialService.createSpecial( specialValues );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.specials.add.alert.success", { name: name });
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the add role dialog
    handleAddDialog_Cancel() {
        this.closeAddDialog();
    }

    // Called by the add dialog to verify its name content
    verifyAddDialog_Name( content ) {

        let result = {
            state: false,
            msg: i18n.t("pages.admin.specials.add.dialog.alert.empty")
        }
        
        if( null != content ) {
            if( null != content.value ) {
                if( content.value !== "" ) {
                    const { exists } = this.doesSpecialNameExist( content.value );
                    if( true === exists ) {
                        result = {
                            state: false,
                            msg: i18n.t("pages.admin.specials.add.dialog.alert.does_already_exist")
                        }
                    } else {
                        result = {
                            state: true,
                            msg: ""
                        }
                    }
                }
            }
        }

        return result;
    }

    // Called by the add dialog to verify its short name content
    verifyAddDialog_ShortName( content ) {

        let result = {
            state: false,
            msg: i18n.t("pages.admin.specials.add.dialog.alert.empty")
        }
        
        if( null != content ) {
            if( null != content.value ) {
                if( content.value !== "" ) {
                    const { exists } = this.doesSpecialShortNameExist( content.value );
                    if( true === exists ) {
                        result = {
                            state: false,
                            msg: i18n.t("pages.admin.specials.add.dialog.alert.does_already_exist")
                        }
                    } else {
                        result = {
                            state: true,
                            msg: ""
                        }
                    }
                }
            }
        }

        return result;
    }

    // Close the modify alert dialog
    closeAddDialog() {
        let addDialog = {
            open: false
        }
        this.setState({ addDialog: addDialog });
    }
    
    /**
     * SELECTOR FOR SPECIAL TYPE
     */

    // Handle when a special type selector is changed
    async handleTypeChange(event, specialid) {
        event.stopPropagation();

        // Update the special
        if( null != specialid ) {
            const type = event.target.value;

            const { statusCode, statusText, resData } = await SpecialService.updateSpecial( { specialid: specialid }, { type: type } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t( "pages.admin.specials.modify.alert.success" );
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    /**
     * ALERT DIALOG <MODIFY>
     */

    // Called to open the modify dialog
    openTableRowModifyDialog( specialid, name, colorcode ) {
        let modifyDialog = {
            open: true,
            specialid: specialid,
            name: name,
            colorcode: colorcode
        }
        this.setState({ modifyDialog: modifyDialog });
    }

    // Called when the user clicked "Ok" on the confirm special modify dialog
    async handleTableRowModifyDialog_Ok( specialid, content ) {
        this.closeTableRowModifyDialog();

        // Check data validity
        if( content == null ) {
            return;
        }
        if( content.colorCode == null ) {
            return;
        }

        const { statusCode, statusText } = await SpecialService.updateSpecial( { specialid: specialid }, { color_code: content.colorCode } );       

        let alert = {
            severity: "info",
            message: ""
        }

        if( 200 === statusCode ) {
            alert.severity = "success";
            alert.message = i18n.t( "pages.admin.specials.modify.alert.success" );
        } else {
            alert.severity = "error";
            alert.message = `${statusText}: ${statusCode}`;
        }

        this.handleAlertChanged( alert );

        // Refresh the table data
        await this.refreshTableData();
    }

    // Called when the user clicked "Cancel" or close on the confirm special modify dialog
    handleTableRowModifyDialog_Cancel() {
        this.closeTableRowModifyDialog();
    }

    // Close the modify alert dialog
    closeTableRowModifyDialog() {
        let modifyDialog = {
            open: false,
            specialid: this.state.modifyDialog.specialid,
            name: this.state.modifyDialog.name,
            colorcode: this.state.modifyDialog.colorcode
        }
        this.setState({ modifyDialog: modifyDialog });
    }

    /**
     * ALERT DIALOG <DELETE>
     */

    // Called to open the delete dialog
    openTableRowDeleteDialog( specialid, name ) {
        let deleteDialog = {
            open: true,
            specialid: specialid,
            name: name
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    // Called when the user clicked "Ok" on the confirm row delete dialog
    async handleTableRowDeleteDialog_Ok( specialid ) {
        this.closeTableRowDeleteDialog();

        // Delete the role
        if( null != specialid ) {
            const { statusCode, statusText, resData } = await SpecialService.deleteSpecial( { specialid: specialid } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                if( true === resData.deleteSpecial.result ) {
                    alert.severity = "success";
                    alert.message = i18n.t( "pages.admin.specials.delete.alert.success" );
                } else {
                    alert.severity = "error";
                    alert.message = `${statusText}: ${statusCode}`;
                }
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the confirm row delete dialog
    async handleTableRowDeleteDialog_Cancel() {
        this.closeTableRowDeleteDialog();
    }

    // Close the delete alert dialog
    closeTableRowDeleteDialog() {
        let deleteDialog = {
            open: false,
            specialid: this.state.deleteDialog.specialid,
            name: this.state.deleteDialog.name
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    /**
     * CALLBACK FUNCTIONS FOR SEARCH FIELD
     */

    // Called when text is entered in the search field
    handleChangeSearchField(value) {
        this.setState({ searchFieldEntry: value });
    }

    /**
     * CALLBACK FUNCTIONS OF TABLE ELEMENTS
     */

    // Called when a checkbox of an existing role is clicked within the table
    async handleTableRowCheckboxChange(event, specialid, keyid) {

        // Update the role
        if( null != specialid && null != keyid ) {
            let specialUpdate;
            if( keyid === this.keys.is_visible ) {
                specialUpdate = { is_visible: event.target.checked }
            } else if( keyid === this.keys.is_enabled ) {
                specialUpdate = { is_enabled: event.target.checked }
            } else if( keyid === this.keys.allow_accounting ) {
                specialUpdate = { allow_accounting: event.target.checked }
            } else if( keyid === this.keys.auto_accounting ) {
                specialUpdate = { auto_accounting: event.target.checked }
            } else if( keyid === this.keys.approval_required ) {
                specialUpdate = { approval_required: event.target.checked }
            }

            const { statusCode, statusText, resData } = await SpecialService.updateSpecial( { specialid: specialid }, specialUpdate );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t( "pages.admin.specials.modify.alert.success" );
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when a delete button of an existing role is clicked within the table
    handleTableRowDeleteClick(event, specialid, name) {
        event.stopPropagation();
        this.openTableRowDeleteDialog( specialid, name );
    }

    // Called when a modify button of an existing special is clicked within the table
    handleTableRowModifyClick(event, specialid, colorcode) {
        event.stopPropagation();
        this.openTableRowModifyDialog( specialid, colorcode );
    }

    /**
     * TABLE PAGINATION EVENT HANDLERS
     */

    // Called if page changes on the table display occur
    handleChangeTablePage = (event, newPage) => {
        this.setState({tablePage: newPage});
    };
    
    // Called if the user selects a different amount of shown rows
    handleChangeTableRowsPerPage = (event) => {
        this.setState({tablePage: 0, tableRowsPerPage: +event.target.value});
    };

    /**
     * TOP ROW BUTTONS
     */

    // Called if the add button is clicked
    async handleAddButtonClick() {
        this.openAddDialog();
    }

    // Called if the refresh button is clicked
    async handleRefreshButtonClick() {
        this.resetState();
        await this.refreshTableData();
    }

    /**
     * TEXT FIELDS FOR SPECIAL NAME
     */

    // Handle when a special name text field is changed
    async handleSpecialNameFieldChange(event, specialid, newValue) {
        event.stopPropagation();

        // Update the special
        if( null != specialid ) {
            const { statusCode, statusText, resData } = await SpecialService.updateSpecial( { specialid: specialid }, { name: newValue } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.specials.modify.alert.success");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called by the custom text field displaying the special names to verify their content
    verifySpecialNameFieldContent( textFieldSpecialId, textFieldValue ) {
        let result = {
            state: false,
            msg: i18n.t("pages.admin.specials.add.dialog.alert.special_name_empty")
        }
        
        if( null != textFieldValue ) {
            if( textFieldValue !== "" ) {
                const { exists, specialid } = this.doesSpecialNameExist( textFieldValue );
                if( true === exists && textFieldSpecialId !== specialid ) {
                    result = {
                        state: false,
                        msg: i18n.t("pages.admin.specials.add.dialog.alert.special_does_already_exist")
                    }
                } else {
                    result = {
                        state: true,
                        msg: ""
                    }
                }
            }
        }
        return result;
    }

    /**
     * TEXT FIELDS FOR SPECIAL SHORT NAME
     */

    // Handle when a special short name text field is changed
    async handleSpecialShortNameFieldChange(event, specialid, newValue) {
        event.stopPropagation();

        // Update the special
        if( null != specialid ) {
            const { statusCode, statusText, resData } = await SpecialService.updateSpecial( { specialid: specialid }, { short_name: newValue } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.specials.modify.alert.success");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called by the custom text field displaying the special short names to verify their content
    verifySpecialShortNameFieldContent( textFieldSpecialId, textFieldValue ) {
        let result = {
            state: false,
            msg: i18n.t("pages.admin.specials.add.dialog.alert.special_short_name_empty")
        }
        
        if( null != textFieldValue ) {
            if( textFieldValue !== "" ) {
                const { exists, specialid } = this.doesSpecialShortNameExist( textFieldValue );
                if( true === exists && textFieldSpecialId !== specialid ) {
                    result = {
                        state: false,
                        msg: i18n.t("pages.admin.specials.add.dialog.alert.special_does_already_exist")
                    }
                } else {
                    result = {
                        state: true,
                        msg: ""
                    }
                }
            }
        }
        return result;
    }

    /**
     * HELPERS CALLED DURING RENDER TO RETRIEVE INFO FROM PROCESSED DATA
     */

    // Get delete button info for a special
    getDeleteButtonDataForSpecial( specialid ) {
        // Retrieve info from array
        for( let i = 0; i < this.state.tableData.deleteButtonsForSpecial.length; i++ ) {
            if( this.state.tableData.deleteButtonsForSpecial[i].specialid === specialid ) {
                return this.state.tableData.deleteButtonsForSpecial[i];
            }
        }
        // Fallback empty data
        return { specialid: specialid, enabled: false, tooltip: i18n.t("pages.admin.specials.delete.button.tooltip.disabled") };
    }

    /// Check if a certain special name already exists
    doesSpecialNameExist( name ) {
        let result = {
            exists: false,
            specialid: 0
        }

        // Retrieve info from array
        if( null != this.state.data.specials ) {
            let specials = this.state.data.specials;

            for( let i = 0; i < specials.length; i++ ) {
                if( specials[i].name === name ) {
                    result.exists = true;
                    result.specialid = specials[i].specialid;
                    break;
                }
            }
        }

        return result;
    }

    /// Check if a certain special name already exists
    doesSpecialShortNameExist( short_name ) {
        let result = {
            exists: false,
            specialid: 0
        }

        // Retrieve info from array
        if( null != this.state.data.specials ) {
            let specials = this.state.data.specials;

            for( let i = 0; i < specials.length; i++ ) {
                if( specials[i].short_name === short_name ) {
                    result.exists = true;
                    result.specialid = specials[i].specialid;
                    break;
                }
            }
        }

        return result;
    }

    // Get color code for special id
    getSpecialColorCode( specialid ) {
        // Retrieve info from array
        if( null != this.state.data.specials ) {
            let specials = this.state.data.specials;

            for( let i = 0; i < specials.length; i++ ) {
                if( specials[i].specialid === specialid ) {
                    return specials[i].color_code;
                }
            }
        }

        return null;
    }

    /**
     * DATA SORT
     */

    // Handle table sort
    handleRequestSort( event, property ) {

        // Check if clicked on same property
        if( this.state.orderBy === property ) {

            // User clicked on same property
            // If already sorted in ascending order, switch to descending order
            if( this.state.order === 'asc' ) {
                this.setState({ order: 'desc', orderBy: property });
            } else {

                // If already sorted in descending order, switch to no order
                this.setState({ order: 'asc', orderBy: 0 });
            }

        } else {
            // User clicked on new property, sort new property in ascending order
            this.setState({ order: 'asc', orderBy: property });
        }
    };

    // Sort the given table data
    sortTableData( specials ) {
        // Handle case where no ordering is selected
        if( this.state.orderBy === 0 ) {
            return specials.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
        }
        return stableSort( specials, getComparator(this.state.order, this.state.orderBy) );
    }

    /**
     * MAIN RENDER FUNCTION
     */
    render() {
        const { classes } = this.props;

        // Only display data if it has already been loaded
        let contentTable = "";

        // Make sure if we got the data already
        if( null != this.state.data.specials ) {

            // Assemble table content
            const tableHeader = [
                { key: "name", span: 1, align: "left", sortable: true, style: {}, text: i18n.t("pages.admin.specials.table.header.name") },
                { key: "short_name", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.specials.table.header.shortname") },
                { key: "is_visible", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.specials.table.header.is_visible") },
                { key: "is_enabled", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.specials.table.header.is_enabled") },
                { key: "allow_accounting", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.specials.table.header.allow_accounting") },
                { key: "auto_accounting", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.specials.table.header.auto_accounting") },
                { key: "approval_required", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.specials.table.header.approval_required") },
                { key: "color_code", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.specials.table.header.color_code") },
                { key: "type", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.specials.table.header.type") },
                { key: "controls", span: 2, align: "center", sortable: false, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.specials.table.header.controls") }
            ];

            let specials = this.state.data.specials;

            // Filter table data if any search entry has been given
            if( this.state.searchFieldEntry !== "" ) {
                specials = specials.filter( special => String(special.name.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase()));
            }

            // Sort table entries by given properties
            specials = this.sortTableData( specials );

            // Preload translations
            let tooltips = {
                is_visible: i18n.t("pages.admin.specials.table.tooltips.is_visible"),
                is_enabled: i18n.t("pages.admin.specials.table.tooltips.is_enabled"),
                allow_accounting: i18n.t("pages.admin.specials.table.tooltips.allow_accounting"),
                color_code: i18n.t("pages.admin.specials.table.tooltips.color_code"),
                auto_accounting: i18n.t("pages.admin.specials.table.tooltips.auto_accounting"),
                approval_required: i18n.t("pages.admin.specials.table.tooltips.approval_required")
            }

            contentTable = (
                <div>
                    <TableContainer style={{ minHeight: "442px" }} component={Paper}>
                        <StyledTable size="small" >
                            <StyledTableHead>
                                <StyledTableRow key={"specials-header"}>
                                    {tableHeader.map((col) => (
                                        <StyledTableCell key={col.key} colSpan={col.span} align={col.align} style={col.style}>
                                            <StyledTableSortLabel
                                                active={this.state.orderBy === col.key}
                                                hideSortIcon={false}
                                                direction={this.state.orderBy === col.key ? this.state.order : 'asc'}
                                                onClick={(event) => this.handleRequestSort(event, col.key)}
                                                disabled={!(col.sortable)}
                                            >
                                                {col.text}
                                            </StyledTableSortLabel>
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </StyledTableHead>
                            <TableBody>
                                {specials.slice(this.state.tablePage * this.state.tableRowsPerPage, this.state.tablePage * this.state.tableRowsPerPage + this.state.tableRowsPerPage).map((special) => {
                                    let colorStyle = `#${special.color_code}`
                                    return (
                                        <StyledTableRow key={special.specialid} specialid={special.specialid}>
                                            <StyledTableCell className={classes.tableCellSpecialName} key="name" width="20%">
                                                <CustomTextField 
                                                    maxLength={config.maxTextLengths.special.name}
                                                    textFieldValue={special.name} 
                                                    verifyContent={(content) => this.verifySpecialNameFieldContent(special.specialid, content)} 
                                                    onApply={(event, newValue) => this.handleSpecialNameFieldChange(event, special.specialid, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.tableCellSpecialShortName} key="shortname" width="5%" style={{ borderStyle: "solid", borderLeftWidth: 1 }}>
                                                <CustomTextField 
                                                    maxLength={config.maxTextLengths.special.shortName}
                                                    textFieldValue={special.short_name} 
                                                    verifyContent={(content) => this.verifySpecialShortNameFieldContent(special.specialid, content)} 
                                                    onApply={(event, newValue) => this.handleSpecialShortNameFieldChange(event, special.specialid, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="is_visible" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.is_visible} checked={special.is_visible} onChange={(event) => this.handleTableRowCheckboxChange(event, special.specialid, this.keys.is_visible)} color="primary" name="switch-admin-is-visible" />
                                            </StyledTableCell>
                                            <StyledTableCell key="is_enabled" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.is_enabled} checked={special.is_enabled} onChange={(event) => this.handleTableRowCheckboxChange(event, special.specialid, this.keys.is_enabled)} color="primary" name="switch-admin-is-enabled" />
                                            </StyledTableCell>
                                            <StyledTableCell key="allow_accounting" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.allow_accounting} checked={special.allow_accounting} onChange={(event) => this.handleTableRowCheckboxChange(event, special.specialid, this.keys.allow_accounting)} color="primary" name="switch-allow-accounting" />
                                            </StyledTableCell>
                                            <StyledTableCell key="auto_accounting" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.auto_accounting} checked={special.auto_accounting} onChange={(event) => this.handleTableRowCheckboxChange(event, special.specialid, this.keys.auto_accounting)} color="primary" name="switch-auto-accounting" />
                                            </StyledTableCell>
                                            <StyledTableCell key="approval_required" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip tooltip={tooltips.approval_required} checked={special.approval_required} onChange={(event) => this.handleTableRowCheckboxChange(event, special.specialid, this.keys.approval_required)} color="primary" name="switch-approval-required" />
                                            </StyledTableCell>
                                            <StyledTableCell key="color_code" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <Tooltip title={tooltips.color_code}>
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            className={classes.submit}
                                                            disabled={false}
                                                            onClick={(event) => this.handleTableRowModifyClick(event, special.specialid, special.color_code)}
                                                            style={{ height: "30px", width: "45px", backgroundColor: colorStyle}}
                                                            value={special.specialid}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                            <StyledTableCell key="type" align="center" padding="checkbox" width="5%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <Select
                                                    id="type"
                                                    value={special.type}
                                                    onChange={(event) => this.handleTypeChange(event, special.specialid)}
                                                    name="type"
                                                    >
                                                    {config.enums.specialType.map((entry) => (
                                                        <MenuItem key={entry.id} value={entry.id}>{i18n.t(entry.name)}</MenuItem>
                                                    ))}
                                                </Select>
                                            </StyledTableCell>
                                            <StyledTableCell key="delete" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <Tooltip title={this.getDeleteButtonDataForSpecial(special.specialid).tooltip}>
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.clearButton}
                                                            disabled={!(this.getDeleteButtonDataForSpecial(special.specialid).enabled)}
                                                            onClick={(event) => this.handleTableRowDeleteClick(event, special.specialid, special.name)}
                                                            style={{ height: "30px", width: "45px" }}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                    <Grid container direction="row" justify="flex-end" className={classes.tablePaginationGrid} spacing={1} wrap='nowrap'>
                        <Grid item>
                            <TablePagination
                                className={classes.tablePagination}
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={specials.length}
                                rowsPerPage={this.state.tableRowsPerPage}
                                labelRowsPerPage={i18n.t("table.pagination.rows_per_page")}
                                page={this.state.tablePage}
                                onChangePage={this.handleChangeTablePage}
                                onChangeRowsPerPage={this.handleChangeTableRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                    <ModalDialog 
                        open={this.state.addDialog.open}
                        title={i18n.t("pages.admin.specials.add.dialog.title")}
                        descText={i18n.t("pages.admin.specials.add.dialog.description")}
                        buttonLeft={i18n.t("pages.admin.specials.add.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.specials.add.dialog.button_right")}
                        handleClickClose={() => this.handleAddDialog_Cancel()}
                        handleClickLeft={(contents) => this.handleAddDialog_Ok(contents[0].value, contents[1].value)}
                        handleClickRight={() => this.handleAddDialog_Cancel()}
                    >
                        <ModalDialogTextField
                            maxLength={config.maxTextLengths.special.name}
                            label={i18n.t("pages.admin.specials.add.dialog.field.name")} 
                            verifyContent={(content) => this.verifyAddDialog_Name(content)}
                        />
                        <ModalDialogTextField 
                            label={i18n.t("pages.admin.specials.add.dialog.field.shortname")} 
                            verifyContent={(content) => this.verifyAddDialog_ShortName(content)}
                            maxLength={config.maxTextLengths.special.shortName}
                        />
                    </ModalDialog>
                    <ModalDialog 
                        open={this.state.modifyDialog.open}
                        title={i18n.t("pages.admin.specials.modify.dialog.title")}
                        descText={i18n.t("pages.admin.specials.modify.dialog.description", { name: this.state.modifyDialog.colorcode })}
                        buttonLeft={i18n.t("pages.admin.specials.modify.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.specials.modify.dialog.button_right")}
                        handleClickClose={() => this.handleTableRowModifyDialog_Cancel()}
                        handleClickLeft={(content) => this.handleTableRowModifyDialog_Ok( this.state.modifyDialog.specialid, content )}
                        handleClickRight={() => this.handleTableRowModifyDialog_Cancel()}
                        maxWidth={"md"}
                    >
                        <ModalDialogColorPicker
                            getColorCode={() => this.getSpecialColorCode( this.state.modifyDialog.specialid )} 
                        />
                    </ModalDialog>
                    <ModalDialog 
                        open={this.state.deleteDialog.open}
                        title={i18n.t("pages.admin.specials.delete.dialog.title")}
                        descText={i18n.t("pages.admin.specials.delete.dialog.description", { name: this.state.deleteDialog.specialname })}
                        buttonLeft={i18n.t("pages.admin.specials.delete.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.specials.delete.dialog.button_right")}
                        handleClickClose={() => this.handleTableRowDeleteDialog_Cancel()}
                        handleClickLeft={() => this.handleTableRowDeleteDialog_Ok( this.state.deleteDialog.specialid )}
                        handleClickRight={() => this.handleTableRowDeleteDialog_Cancel()}
                    />
                </div>
            )
        }

        return (
            <React.Fragment>
                <PageTitle title={i18n.t("pages.admin.specials.title")} />
                <p>{i18n.t("pages.admin.specials.desc")}</p>
                <SearchControls
                    label={i18n.t("pages.admin.specials.search.label")}
                    helperText={i18n.t("pages.admin.specials.search.subtext")}
                    addTooltip={i18n.t("pages.admin.specials.add.button.tooltip")}
                    refreshTooltip={i18n.t("buttons.refresh.tooltip")}
                    onAdd={this.handleAddButtonClick}
                    onRefresh={this.handleRefreshButtonClick}
                    onChange={this.handleChangeSearchField} 
                />
                <div>{contentTable}</div>
            </React.Fragment>
        )
    }
};

export default withStyles(useStyles)(AdminSpecials);