import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * About menu
 */
class AboutMenu extends Component {
    render() {
        return (
            <div id="about-menu">
                <ListItem button component={Link} to="/about" className={this.props.className}>
                    <ListItemIcon><InfoIcon className={this.props.className}/></ListItemIcon>
                    <ListItemText primary={i18n.t("menus.about")} className={this.props.className}/>
                </ListItem>
            </div>
        );
    }
};

export default withStyles(useStyles)(AboutMenu);