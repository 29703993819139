import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ReportService from '../classes/ReportService';
import ReportVacationUser from './ReportVacationUser';
import ReportAccountingUser from '../components/ReportAccountingUser';
import ReportAbsenceUser from '../components/ReportAbsenceUser';
import ReportWorkTimeUser from '../components/ReportWorkTimeUser';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import UserService from '../classes/UserService';
import SpecialService from '../classes/SpecialService';
import SelectControls from '../components/SelectControls';
import AuthContext from '../contexts/AuthContext';
import UsersToProjectsService from '../classes/UsersToProjectsService';
import UsersToTeamsService from '../classes/UsersToTeamsService';
import CalendarHelper from '../classes/CalendarHelper.js';
import UserTypeService from '../classes/UserTypeService';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExportButtons from '../components/ExportButtons';
import AbsenceService from '../classes/AbsenceService';
import HolidayService from '../classes/HolidayService';
import AccountingService from '../classes/AccountingService';
import ContractService from '../classes/ContractService';
import WorkTimeService from '../classes/WorkTimeService';
import { config } from '../config';
import { fromTimeStringToDate, toJSONLocalDate } from '../helpers.js';
import { CSVLink } from 'react-csv';
import i18n from 'i18next';
import _ from 'lodash';

const useStyles = theme => ({
	mainGrid: {
		display: 'flex',
		height: '100%',
		width: '100%',
		margin: theme.spacing(0),
		justifyContent: 'space-between',
		'& > *': {
			margin: theme.spacing(0)
		},
	},
    noAccessGridItem: {
        width: '100%'
    },
	headline: {
		minWidth: '100%',
		maxWidth: '100%'
	},
	options: {
		height: '40px'
	},
	select: {
		minWidth: '100%',
		maxWidth: '100%'
	},
	reportContent: {
		minHeight: '100%',
		minWidth: '50%',
		maxHeight: '100%',
		maxWidth: '50%'
	},
});

/**
 * Reports Team
 */
class ReportsTeam extends Component {
	static contextType = AuthContext;

	state = {}

	constructor(props) {
		super(props);

		// All function bindings to make them accessible
		this.handleChangeSelectYear = this.handleChangeSelectYear.bind( this );
        this.handleChangeSelectMonth = this.handleChangeSelectMonth.bind( this );
		this.handleOptionChange = this.handleOptionChange.bind( this );
		this.handleChangeSelectUser = this.handleChangeSelectUser.bind( this );
        this.handleCsvExportClick = this.handleCsvExportClick.bind( this );
        this.handleModifyWorkTime = this.handleModifyWorkTime.bind( this );
        this.handleDeleteWorkTime = this.handleDeleteWorkTime.bind( this );
        this.getUserData = this.getUserData.bind( this );

        // References to call into childs
        this.csvLinkRef = React.createRef();
        this.vacationChildRef = React.createRef();
        this.accountingChildRef = React.createRef();
        this.absenceChildRef = React.createRef();
        this.worktimeChildRef = React.createRef();

		// Set initial state
		this.state = this.getInitialState();
	}

	/**
	 * STATE HANDLING
	 */

	// Get the initial state variables of this component
	getInitialState() {
		return {
			selectedYear: new Date().getFullYear(),
            selectedMonth: new Date().getMonth()+1,
			selectedOption: "vacation",
			data: { // Raw loaded data from the backend
                admin: null,
				vacationReport: null,
				accountingReport: null,
				absenceReport: null,
				specials: null,
                users: null,
                worktimes: null,
                contracts: null,
                absences: null,
                holidays: null,
                accountings: null
			},
			selectUser: {
				selectedUserId: "",
				selectableUsers: []
			},
            export: {
                csv: {
                    filename: "",
                    data: []
                }
            }
		}
	}

	// Reset all internal states to initial values and also values propagated to parent
	resetState() {
		this.handleAlertChanged({ severity: "info", message: "" });
		this.handleDataLoadedChanged( false );
		this.setState( this.getInitialState() );
	}

// Get all work times for a given user at a given date
getWorkTimesForUserAtDate( userid, dateJson ) {
    let result = [];
    for( let i = 0; i < this.state.data.worktimes.length; i++ ) {
        if( this.state.data.worktimes[i].userid === userid && this.state.data.worktimes[i].date === dateJson ) {
            result.push( _.cloneDeep( this.state.data.worktimes[i] ) );
        }
    }

    // Sort just by start_time since we are only searching on a specific date
    result.sort(function(a, b) { return fromTimeStringToDate(a.start_time) > fromTimeStringToDate(b.start_time) ? 1 : -1; });
    return result;
}

// Check if the user has any worktimes on the given date
hasUserWorkTimes( userid, dateJson ) {
    for( let i = 0; i < this.state.data.worktimes.length; i++ ) {
        if( this.state.data.worktimes[i].userid === userid ) {
            if( this.state.data.worktimes[i].date === dateJson ) {
                return true;
            }
        }
    }

    return false;
}

/**
 * HANDLERS FOR MODIFY OF WORK TIME
*/
async handleModifyWorkTime( userid, data ) {

    // Validity checks
    if( userid == null || userid === "" || data == null ) {
        return;
    }

    /**
     * At this point three things can happen:
     * * A new worktime entry is added if it does not yet exist (no matching work time entry found)
     * * A previously existing work time entry is removed (because one with the same start_time does not exist anymore)
     * * An already existing work time entry is updated (check is based on start_time)
     * So build a list of actions which need to be executed on the database
     */

    let actions = [];

    // Get all current worktime entries for this user
    const currentWorkTimes = this.getWorkTimesForUserAtDate( userid, data.dateJson );

    // First check if existing work time entries have been deleted or modified
    for( let i = 0; i < currentWorkTimes.length; i++ ) {
        let workTimeEntryStillExists = false;

        for( let j = 0; j < data.worktimes.today.length; j++ ) {
            if( currentWorkTimes[i].start_time === data.worktimes.today[j].start_time ) {
                // Work time entry does still exist
                workTimeEntryStillExists = true;

                // Check if work time entry was modified
                if( false === _.isEqual( currentWorkTimes[i], data.worktimes.today[j] ) ) {

                    // Entry was modified
                    let action = {
                        type: "modify",
                        dateJson: data.dateJson,
                        start_time: currentWorkTimes[i].start_time,
                        modifiedData: data.worktimes.today[j]
                    }
                    actions.push( action );
                }

                break;
            }
        }

        if( false === workTimeEntryStillExists ) {
            let action = {
                type: "delete",
                dateJson: data.dateJson,
                start_time: currentWorkTimes[i].start_time
            }
            actions.push( action );
        }
    }
    
    // Now check if new entries need to be added
    for( let i = 0; i < data.worktimes.today.length; i++ ) {
        let workTimeEntryDoesNotExist = true;

        for( let j = 0; j < currentWorkTimes.length; j++ ) {

            if( currentWorkTimes[j].start_time === data.worktimes.today[i].start_time ) {
                workTimeEntryDoesNotExist = false;
                break;
            }
        }

        if( true === workTimeEntryDoesNotExist ) {
            let action = {
                type: "add",
                dateJson: data.dateJson,
                newData: data.worktimes.today[i]
            }
            actions.push( action );
        }
    }

    // Now execute all the actions
    for( let i = 0; i < actions.length; i++ ) {

        if( actions[i].type === "add" ) {

            let workTimeInput = {
                userid: userid,
                date: actions[i].dateJson,
                start_time: actions[i].newData.start_time,
                end_time: actions[i].newData.end_time,
                break: actions[i].newData.break,
            }

            const { statusCode, statusText } = await WorkTimeService.createWorkTime( workTimeInput );
            let statusCodeFinal = statusCode;
            let statusTextFinal = statusText;

            let alert = {
                severity: "info",
                message: ""
            }
    
            if( 200 === statusCodeFinal ) {
                alert.severity = "success";
                alert.message = i18n.t( "pages.main.alert.success.add_worktime" );
            } else {
                alert.severity = "error";
                alert.message = `${statusTextFinal}: ${statusCodeFinal}`;
            }
    
            this.handleAlertChanged( alert );

        } else if( actions[i].type === "delete" ) {

            let workTimeSearch = {
                userid: userid,
                date: actions[i].dateJson,
                start_time: actions[i].start_time,
            }
    
            const { statusCode, statusText } = await WorkTimeService.deleteWorkTime( workTimeSearch );
            let statusCodeFinal = statusCode;
            let statusTextFinal = statusText;

            let alert = {
                severity: "info",
                message: ""
            }
    
            if( 200 === statusCodeFinal ) {
                alert.severity = "success";
                alert.message = i18n.t( "pages.main.alert.success.modified_worktime" );
            } else {
                alert.severity = "error";
                alert.message = `${statusTextFinal}: ${statusCodeFinal}`;
            }
    
            this.handleAlertChanged( alert );

        } else if( actions[i].type === "modify" ) {

            let workTimeSearch = {
                userid: userid,
                date: actions[i].dateJson,
                start_time: actions[i].start_time
            }

            let workTimeUpdate = {
                start_time: actions[i].modifiedData.start_time,
                end_time: actions[i].modifiedData.end_time,
                break: actions[i].modifiedData.break
            }

            const { statusCode, statusText } = await WorkTimeService.updateWorkTime( workTimeSearch, workTimeUpdate );
            let statusCodeFinal = statusCode;
            let statusTextFinal = statusText;

            let alert = {
                severity: "info",
                message: ""
            }
    
            if( 200 === statusCodeFinal ) {
                alert.severity = "success";
                alert.message = i18n.t( "pages.main.alert.success.modified_worktime" );
            } else {
                alert.severity = "error";
                alert.message = `${statusTextFinal}: ${statusCodeFinal}`;
            }
    
            this.handleAlertChanged( alert );
        }
    }

    // Refresh worktime data
    this.handleDataLoadedChanged( false );

    let workTimeData = await this.getWorkTimeData( this.state.selectedYear, this.state.selectedMonth, this.state.selectUser.selectedUserId );

    this.handleDataLoadedChanged( true );

    // Update the elements
    let stateData = this.state.data;
    stateData.worktimes = workTimeData;

    this.setState({ data: stateData });
}

async handleDeleteWorkTime( userid, dateJson ) {

    // Validity checks
    if( userid == null || userid === "" || dateJson == null || dateJson === "" ) {
        return;
    }

    // Check if there are any worktimes to delete
    if( false === this.hasUserWorkTimes( userid, dateJson ) ) {
        return;
    }

    let workTimeSearch = {
        userid: userid,
        date: dateJson
    }

    const { statusCode, statusText } = await WorkTimeService.deleteWorkTime( workTimeSearch );
    let statusCodeFinal = statusCode;
    let statusTextFinal = statusText;

    let alert = {
        severity: "info",
        message: ""
    }

    if( 200 === statusCodeFinal ) {
        alert.severity = "success";
        alert.message = i18n.t( "pages.main.alert.success.modified_worktime" );
    } else {
        alert.severity = "error";
        alert.message = `${statusTextFinal}: ${statusCodeFinal}`;
    }

    this.handleAlertChanged( alert );

    // Refresh worktime data
    this.handleDataLoadedChanged( false );

    let workTimeData = await this.getWorkTimeData( this.state.selectedYear, this.state.selectedMonth, this.state.selectUser.selectedUserId );

    this.handleDataLoadedChanged( true );

    // Update the elements
    let data = this.state.data;
    data.worktimes = workTimeData;

    this.setState({ data: data });
}

	/**
	 * LINK TO PARENT COMPONENT FUNCTIONS
	 */

	// Push data loaded changed to parent
	handleDataLoadedChanged( dataLoaded ) {
		if( this.props.dataLoadedChanged ) {
			this.props.dataLoadedChanged( dataLoaded );
		}
	}

	// Push alert to parent
	handleAlertChanged( alert ) {
		if( this.props.alertChanged ) {
			this.props.alertChanged( alert );
		}
	}

	// Propagate information to parent component
	handleActionInProgressChanged( actionInProgress ) {
		if( this.props.actionInProgressChanged ) {
			this.props.actionInProgressChanged( actionInProgress );
		}
	}
	
	/**
	 * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
	 */

	// Called by react when this component has been mounted
	async componentDidMount() {
		// Refresh all data from backend
		await this.refreshData( "" );
	}

	// Called by react before this component unmounts
	componentWillUnmount() {
		this.resetState();
	}

	/**
	 * HANDLERS 
	 */

	// Called when the user selects a new year
	async handleChangeSelectYear( value ) {
        this.handleDataLoadedChanged( false );

        // Refresh worktime data
        let workTimeData = await this.getWorkTimeData( value, this.state.selectedMonth, this.state.selectUser.selectedUserId );
        let absenceData = await this.getAbsenceData( value, this.state.selectedMonth, this.state.selectUser.selectedUserId );
        let holidayData = await this.getHolidayData( value, this.state.selectedMonth );
        let accountingData = await this.getAccountingData( value, this.state.selectedMonth, this.state.selectUser.selectedUserId );

        this.handleDataLoadedChanged( true );

        // Update the elements
        let data = this.state.data;
        data.worktimes = workTimeData;
        data.absences = absenceData;
        data.accountings = accountingData;
        data.holidays = holidayData;

        this.setState({ selectedYear: value, data: data });
	}

    // Called when the user selects a new month
    async handleChangeSelectMonth( value ) {
        this.handleDataLoadedChanged( false );

        // Refresh worktime data
        let workTimeData = await this.getWorkTimeData( this.state.selectedYear, value, this.state.selectUser.selectedUserId );
        let absenceData = await this.getAbsenceData( this.state.selectedYear, value, this.state.selectUser.selectedUserId );
        let holidayData = await this.getHolidayData( this.state.selectedYear, value );
        let accountingData = await this.getAccountingData( this.state.selectedYear, value, this.state.selectUser.selectedUserId );

        this.handleDataLoadedChanged( true );

        // Update the elements
        let data = this.state.data;
        data.worktimes = workTimeData;
        data.absences = absenceData;
        data.accountings = accountingData;
        data.holidays = holidayData;

        this.setState({ selectedMonth: value, data: data });
    }

	// Called when a new user is selected
	async handleChangeSelectUser( value ) {
		await this.refreshData( value );
	}

	// Called when the user selects a report option
	handleOptionChange( event, newAlignment ) {
        if( null !== newAlignment ) {
            this.setState({ selectedOption: newAlignment });
        }
	}

	/**
	 * LOAD AND PROCESS BACKEND DATA
	 */

	// Gather all data from the backend
	async refreshData( userid ) {

		let selectedUserId = userid;
		if( null == selectedUserId || selectedUserId === "" ) {
			selectedUserId = this.context.userid;
		}

		// Gather user data
		let userData = null;
		{
			const { statusCode, statusText, resData } = await UserService.getUsers( {} );
			if( 200 === statusCode && null != resData ) {
				userData = resData.getUsers;
			} else {
				// Error, set the alert right away and abort
				this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
				return;
			}
			userData.sort(function(a, b) { return a.lastname > b.lastname ? 1 : -1; });
		}

		// Gather admin data for the currently logged in user
		let adminData = null;
		{
			const { statusCode, statusText, userAdminData } = await this.context.services.admin.getAdminData( this.context.userid );
			if( 200 === statusCode && null != userAdminData ) {
				adminData = userAdminData;
			} else {
				// Error, set the alert right away and abort
				this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
				return;
			}
		}

		// Gather vacation report data
		let vacationReportData = null;
		if( selectedUserId === this.context.userid || true === adminData.admin.absences || true === this.state.data.admin.admin.global ) {
			const { statusCode, statusText, resData } = await ReportService.getVacationReport( { userid: selectedUserId } );
			if( 200 === statusCode && null != resData ) {
				vacationReportData = resData.getVacationReport;
			} else {
				// Error, set the alert right away and abort
				this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
				return;
			}
		}

		// Gather accounting report data
		let accountingReportData = null;
		if( selectedUserId === this.context.userid || true === adminData.admin.accountings || true === this.state.data.admin.admin.global ) {
			const { statusCode, statusText, resData } = await ReportService.getAccountingReport( { userid: selectedUserId } );
			if( 200 === statusCode && null != resData ) {
				accountingReportData = resData.getAccountingReport;
			} else {
				// Error, set the alert right away and abort
				this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
				return;
			}
		}

        // Gather absence report data
        let absenceReportData = null;
        if( selectedUserId === this.context.userid || true === adminData.admin.accountings || true === this.state.data.admin.admin.global ) {
            const { statusCode, statusText, resData } = await ReportService.getAbsenceReport( { userid: selectedUserId } );
            if( 200 === statusCode && null != resData ) {
                absenceReportData = resData.getAbsenceReport;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather contract data
        let contractData = null;
        if( selectedUserId === this.context.userid || true === adminData.admin.accountings || true === this.state.data.admin.admin.global ) {
            const { statusCode, statusText, resData } = await ContractService.getContracts( { userid: selectedUserId } );
            if( 200 === statusCode && null != resData ) {
                contractData = resData.getContracts;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather worktime data
        let workTimeData = await this.getWorkTimeData( this.state.selectedYear, this.state.selectedMonth, selectedUserId );

        // Gather absence data
        let absenceData = await this.getAbsenceData( this.state.selectedYear, this.state.selectedMonth, selectedUserId );

        // Gather holiday data
        let holidayData = await this.getHolidayData( this.state.selectedYear, this.state.selectedMonth );

        // Gather accounting data
        let accountingData = await this.getAccountingData( this.state.selectedYear, this.state.selectedMonth, selectedUserId );

        // Gather special data
        let specialData = null;
        {
            const { statusCode, statusText, resData } = await SpecialService.getSpecials( {} );
            if( 200 === statusCode && null != resData ) {
                specialData = resData.getSpecials;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

		// Gather usersToProjects data
		let usersToProjectsData = null;
		{
			const { statusCode, statusText, resData } = await UsersToProjectsService.getUsersToProjects( {} );
			if( 200 === statusCode && null != resData ) {
				usersToProjectsData = resData.getUsersToProjects;
			} else {
				// Error, set the alert right away and abort
				this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
				return;
			}
		}

		// Gather usersToTeams data
		let usersToTeamsData = null;
		{
			const { statusCode, statusText, resData } = await UsersToTeamsService.getUsersToTeams( {} );
			if( 200 === statusCode && null != resData ) {
				usersToTeamsData = resData.getUsersToTeams;
			} else {
				// Error, set the alert right away and abort
				this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
				return;
			}
		}

        // Gather user type data
        let userTypeData = null;
        {
            const { statusCode, statusText, resData } = await UserTypeService.getUserTypes( {} );
            if( 200 === statusCode && null != resData ) {
                userTypeData = resData.getUserTypes;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Add user type name to all user data entries
        for( let i = 0; i < userData.length; i++ ) {
            let userTypeName = "";
            for( let j = 0; j < userTypeData.length; j++ ) {
                if( userTypeData[j].typeid === userData[i].typeid ) {
                    userTypeName = userTypeData[j].name;
                    break;
                }
            }
            userData[i].userTypeName = userTypeName;
        }

		// Gather list of selectable users depending on user administration access or team lead settings
		let selectableUsers = [];
		if( null != adminData ) {

			for( let i = 0; i < userData.length; i++ ) {

				let fullUserEntry = userData[i].lastname + ", " + userData[i].firstname;
				if( userData[i].userTypeName != null && userData[i].userTypeName !== "" ) {
					fullUserEntry = "(" + userData[i].userTypeName + ") " + userData[i].lastname + ", " + userData[i].firstname;
				}

				// Add current user to list depending on permissions
				if( userData[i].userid === this.context.userid ) {

					// We are the current user, add us to the list
					selectableUsers.push( { id: userData[i].userid, value: fullUserEntry, lastname: userData[i].lastname, userTypeName: userData[i].userTypeName } );

				} else if( true === adminData.admin.accountings || true === adminData.admin.absences || true === adminData.admin.global ) {

					// We are admin accounting user or global admin
					selectableUsers.push( { id: userData[i].userid, value: fullUserEntry, lastname: userData[i].lastname, userTypeName: userData[i].userTypeName } );

				} else if( 0 < adminData.teamlead.length ) {

					// Check if the current user is team lead
					for( let j = 0; j < usersToTeamsData.length; j++ ) {
						if( usersToTeamsData[j].userid === userData[i].userid ) {
							let isTeamLeadOfUser = false;

							for( let k = 0; k < adminData.teamlead.length; k++ ) {
								if( adminData.teamlead[k] === usersToTeamsData[j].teamid ) {
									isTeamLeadOfUser = true;
								}
							}

							if( true === isTeamLeadOfUser ) {
								selectableUsers.push( { id: userData[i].userid, value: fullUserEntry, lastname: userData[i].lastname, userTypeName: userData[i].userTypeName } );
							}
						}
					}
				} else if( 0 < adminData.projectlead.length ) {
					// Check if the current user is project lead
					for( let j = 0; j < usersToProjectsData.length; j++ ) {

						if( usersToProjectsData[j].userid === userData[i].userid ) {
							let isProjectLeadOfUser = false;

							for( let k = 0; k < adminData.projectlead.length; k++ ) {
								if( adminData.projectlead[k] === usersToProjectsData[j].projectid ) {
									isProjectLeadOfUser = true;
								}
							}

							if( true === isProjectLeadOfUser ) {
								selectableUsers.push( { id: userData[i].userid, value: fullUserEntry, lastname: userData[i].lastname, userTypeName: userData[i].userTypeName } );
							}
						}
					}
				}
			}

			// Sort user data
			selectableUsers.sort(function(a, b) { 
				// Sort by user type first, then by user lastname
				if( a.userTypeName > b.userTypeName ) return 1;
				if( a.userTypeName < b.userTypeName ) return -1;
				if( a.lastname > b.lastname ) return 1;
				if( a.lastname < b.lastname ) return -1;
				return 0;
			});
		}

		// Since we updated the data, also reset the selected year,
		// the selected year might not be available in the retrieved reports
		let resetYear = true;
		if( null != vacationReportData ) {
			for( let i = 0; i < vacationReportData.years.length; i++ ) {
				if( this.state.selectedYear === vacationReportData.years[i].year ) {
					resetYear = false;
					break;
				}
			}
		} else if( null != accountingReportData ) {
            for( let i = 0; i < accountingReportData.years.length; i++ ) {
				if( this.state.selectedYear === accountingReportData.years[i].year ) {
					resetYear = false;
					break;
				}
			}
        } else if( null != absenceReportData ) {
            for( let i = 0; i < absenceReportData.years.length; i++ ) {
				if( this.state.selectedYear === absenceReportData.years[i].year ) {
					resetYear = false;
					break;
				}
			}
        }

		let selectedYear = this.state.selectedYear;
		if( true === resetYear && selectedYear !== "" ) {
			selectedYear = new Date().getFullYear();
		}

		this.setState({
			selectedYear: selectedYear,
			selectUser: {
				selectedUserId: selectedUserId,
				selectableUsers: selectableUsers
			},
			data: {
                admin: adminData,
				vacationReport: vacationReportData,
				accountingReport: accountingReportData,
				absenceReport: absenceReportData,
				specials: specialData,
                users: userData,
                worktimes: workTimeData,
                contracts: contractData,
                absences: absenceData,
                accountings: accountingData,
                holidays: holidayData
			}
		});

		this.handleDataLoadedChanged( true );
	}

    // Gather all data from the backend
    async getWorkTimeData( selectedYear, selectedMonth, userid ) {
        let workTimeData = null;
        if( userid === this.context.userid || true === this.props.adminData.admin.accountings || true === this.state.data.admin.admin.global ) {
            const lastDateInMonth = new Date( selectedYear, selectedMonth, 0 );
            const lastDateInPreviousMonth = new Date( selectedYear, selectedMonth-1, 0 );

            // Get the worktimes from the last day of the previous month to properly calculate the nightly rest period of the first day of the month
            let minDate = toJSONLocalDate( lastDateInPreviousMonth );
            let maxDate = toJSONLocalDate( lastDateInMonth );

            const { statusCode, statusText, resData } = await WorkTimeService.getWorkTimesRange( { userid: userid, minDate: minDate, maxDate: maxDate } );
            if( 200 === statusCode && null != resData ) {
                workTimeData = resData.getWorkTimesRange;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }
        return workTimeData;
    }

    // Gather all data from backend
    async getAbsenceData( selectedYear, selectedMonth, userid ) {
        let absenceData = null;
        if( userid === this.context.userid || true === this.props.adminData.admin.accountings || true === this.state.data.admin.admin.global ) {
            const lastDateInMonth = new Date( selectedYear, selectedMonth, 0 );
            const lastDateInPreviousMonth = new Date( selectedYear, selectedMonth-1, 0 );

            // Get the worktimes from the last day of the previous month to properly calculate the nightly rest period of the first day of the month
            let minDate = toJSONLocalDate( lastDateInPreviousMonth );
            let maxDate = toJSONLocalDate( lastDateInMonth );

            const { statusCode, statusText, resData } = await AbsenceService.getAbsencesRange( { userid: userid, minDate: minDate, maxDate: maxDate } );
            if( 200 === statusCode && null != resData ) {
                absenceData = resData.getAbsencesRange;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }
        return absenceData;
    }

    // Gather all data from backend
    async getAccountingData( selectedYear, selectedMonth, userid ) {
        let accountingData = null;
        if( userid === this.context.userid || true === this.props.adminData.admin.accountings || true === this.state.data.admin.admin.global ) {
            const lastDateInMonth = new Date( selectedYear, selectedMonth, 0 );
            const lastDateInPreviousMonth = new Date( selectedYear, selectedMonth-1, 0 );

            // Get the worktimes from the last day of the previous month to properly calculate the nightly rest period of the first day of the month
            let minDate = toJSONLocalDate( lastDateInPreviousMonth );
            let maxDate = toJSONLocalDate( lastDateInMonth );

            const { statusCode, statusText, resData } = await AccountingService.getAccountingsRange( { userid: userid, minDate: minDate, maxDate: maxDate } );
            if( 200 === statusCode && null != resData ) {
                accountingData = resData.getAccountingsRange;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }
        return accountingData;
    }

    // Gather all data from backend
    async getHolidayData( selectedYear, selectedMonth ) {
        let holidayData = null;
        {
            const lastDateInMonth = new Date( selectedYear, selectedMonth, 0 );
            const lastDateInPreviousMonth = new Date( selectedYear, selectedMonth-1, 0 );

            // Get the worktimes from the last day of the previous month to properly calculate the nightly rest period of the first day of the month
            let minDate = toJSONLocalDate( lastDateInPreviousMonth );
            let maxDate = toJSONLocalDate( lastDateInMonth );

            const { statusCode, statusText, resData } = await HolidayService.getHolidaysRange( { minDate: minDate, maxDate: maxDate } );
            if( 200 === statusCode && null != resData ) {
                holidayData = resData.getHolidaysRange;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }
        return holidayData;
    }

    // Get data of user
    getUserData( userid ) {
        if( null == userid || userid === "" ) {
            return null;
        }

        let userData = null;

        // Get info for the current user
        for( let i = 0; i < this.state.data.users.length; i++ ) {
            if( this.state.data.users[i].userid === userid ) {
                userData = this.state.data.users[i];
            }
        }

        return userData;
    }

    /**
     * EXPORT FUNCTIONS
     */

    // Export to CSV
    async handleCsvExportClick( exportAll ) {

        // Gather information about the selected user and pass it onto the export function
        // in case we should export all, add all selectable users to the array
        let selectedUsers = [];

        if( true === exportAll ) {
            // Add all selectable users
            for( let i = 0; i < this.state.selectUser.selectableUsers.length; i++ ) {
                const user = this.getUserData( this.state.selectUser.selectableUsers[i].id );
                selectedUsers.push( user );
            }
        } else {
            // Only add the single selected user
            for( let i = 0; i < this.state.data.users.length; i++ ) {
                if( this.state.data.users[i].userid === this.state.selectUser.selectedUserId ) {
                    selectedUsers.push( this.state.data.users[i] );
                    break;
                }
            }
        }

        let reports = [];

        // Make the call to the child component
        let finalFilename = "";
        let finalData = "";
        if( "vacation" === this.state.selectedOption ) {

            if( true === exportAll ) {
                for( let i = 0; i < this.state.selectUser.selectableUsers.length; i++ ) {
                    let reportData = null;
                    const { statusCode, statusText, resData } = await ReportService.getVacationReport( { userid: this.state.selectUser.selectableUsers[i].id } );
                    if( 200 === statusCode && null != resData ) {
                        reportData = resData.getVacationReport;
                    } else {
                        // Error, set the alert right away and abort
                        this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                        return;
                    }
                    reports.push( reportData );
                }
            } else {
                reports.push( this.state.data.vacationReport );
            }

            const { csvFilename, csvData } = this.vacationChildRef.current.onCsvExport( selectedUsers, reports );
            finalFilename = csvFilename;
            finalData = csvData;

        } else if( "accounting" === this.state.selectedOption ) {

            if( true === exportAll ) {
                for( let i = 0; i < this.state.selectUser.selectableUsers.length; i++ ) {
                    let reportData = null;
                    const { statusCode, statusText, resData } = await ReportService.getAccountingReport( { userid: this.state.selectUser.selectableUsers[i].id } );
                    if( 200 === statusCode && null != resData ) {
                        reportData = resData.getAccountingReport;
                    } else {
                        // Error, set the alert right away and abort
                        this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                        return;
                    }
                    reports.push( reportData );
                }
            } else {
                reports.push( this.state.data.accountingReport );
            }

            const { csvFilename, csvData } = this.accountingChildRef.current.onCsvExport( selectedUsers, reports );
            finalFilename = csvFilename;
            finalData = csvData;

        } else if( "absence" === this.state.selectedOption ) {

            if( true === exportAll ) {
                for( let i = 0; i < this.state.selectUser.selectableUsers.length; i++ ) {
                    let reportData = null;
                    const { statusCode, statusText, resData } = await ReportService.getAbsenceReport( { userid: this.state.selectUser.selectableUsers[i].id } );
                    if( 200 === statusCode && null != resData ) {
                        reportData = resData.getAbsenceReport;
                    } else {
                        // Error, set the alert right away and abort
                        this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                        return;
                    }
                    reports.push( reportData );
                }
            } else {
                reports.push( this.state.data.absenceReport );
            }

            const { csvFilename, csvData } = this.absenceChildRef.current.onCsvExport( selectedUsers, reports );
            finalFilename = csvFilename;
            finalData = csvData;

        } else if( "worktime" === this.state.selectedOption ) {

            // There are two features in the accounting which lock certain cells
            // - Rolling auto accounting window (if configured)
            // - Specials which auto account
            // The only users who shall ever bypass these features are global admins and admin_accountings
            let accountingAutoLock = false;
            let accountingAutoLockPeriodOfDays = 0;
            let specialAutoAccountingLock = true;

            // Fill in settings from config for rolling auto account window
            if( null != this.context.config ) {
                if( null != this.context.config.accounting.auto_lock ) {
                    accountingAutoLock = this.context.config.accounting.auto_lock;
                }
                if( null != this.context.config.accounting.auto_lock_period_in_days ) {
                    accountingAutoLockPeriodOfDays = this.context.config.accounting.auto_lock_period_in_days;
                }
            }

            // Allow bypass for global admin and admin_accountings
            if( true === this.props.adminData.admin.global || true === this.props.adminData.admin.accountings ) {
                accountingAutoLock = false;
                specialAutoAccountingLock = false;
            }

            if( true === exportAll ) {

                // For all the selected users, we need to gather more data from the backend
                // all worktimes, all contracts, all absences, all accountings
                let allWorkTimes = [];
                let allContracts = [];
                let allAbsences = [];
                let allAccountings = [];

                for( let i = 0; i < this.state.selectUser.selectableUsers.length; i++ ) {

                    let workTimeData = await this.getWorkTimeData( this.state.selectedYear, this.state.selectedMonth, this.state.selectUser.selectableUsers[i].id );
                    allWorkTimes = allWorkTimes.concat( workTimeData );

                    let contractData = null;
                    const { statusCode, statusText, resData } = await ContractService.getContracts( { userid: this.state.selectUser.selectableUsers[i].id } );
                    if( 200 === statusCode && null != resData ) {
                        contractData = resData.getContracts;
                    } else {
                        // Error, set the alert right away and abort
                        this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                        return;
                    }
                    allContracts = allContracts.concat( contractData );

                    let absencesData = await this.getAbsenceData( this.state.selectedYear, this.state.selectedMonth, this.state.selectUser.selectableUsers[i].id );
                    allAbsences = allAbsences.concat( absencesData );

                    let accountingData = await this.getAccountingData( this.state.selectedYear, this.state.selectedMonth, this.state.selectUser.selectableUsers[i].id );
                    allAccountings = allAccountings.concat( accountingData );
                }

                const { csvFilename, csvData } = this.worktimeChildRef.current.onCsvExport( this.state.selectedYear.toString(), this.state.selectedMonth.toString(), 
                selectedUsers, allWorkTimes, allContracts, allAbsences, this.state.data.holidays, this.state.data.specials,
                allAccountings, accountingAutoLock, accountingAutoLockPeriodOfDays, specialAutoAccountingLock );

                finalFilename = csvFilename;
                finalData = csvData;

            } else {

                // For only the one currently selected user
                const { csvFilename, csvData } = this.worktimeChildRef.current.onCsvExport( this.state.selectedYear.toString(), this.state.selectedMonth.toString(), 
                selectedUsers, this.state.data.worktimes, this.state.data.contracts, this.state.data.absences, this.state.data.holidays, this.state.data.specials,
                this.state.data.accountings, accountingAutoLock, accountingAutoLockPeriodOfDays, specialAutoAccountingLock );

                finalFilename = csvFilename;
                finalData = csvData;
            }
        }

        // Set the state of the data and automatically click the download link
        this.setState({ 
            export: { 
                csv: {
                    filename: finalFilename,
                    data: finalData
                }
            }},
            () => {
                setTimeout(() => {
                    this.csvLinkRef.current.link.click();
                })
            }
        );
    }

	/**
	 * RENDER FUNCTIONS
	 */

	// Render select menu
	renderSelect( vacationReport, accountingReport, absenceReport, selectedYear ) {

		// Gather list of selectable years
		let selectableYears = [];

		if( null != vacationReport ) {
			for( let i = 0; i < vacationReport.years.length; i++ ) {
				selectableYears.push( {
					id: vacationReport.years[i].year,
					value: vacationReport.years[i].year.toString()
				});
			}
		} else if( null != accountingReport ) {
			for( let i = 0; i < accountingReport.years.length; i++ ) {
				selectableYears.push( {
					id: accountingReport.years[i].year,
					value: accountingReport.years[i].year.toString()
				});
			}
		} else if( null != absenceReport ) {
			for( let i = 0; i < absenceReport.years.length; i++ ) {
				selectableYears.push( {
					id: absenceReport.years[i].year,
					value: absenceReport.years[i].year.toString()
				});
			}
		}

		return (
			<SelectControls
				selectedEntry={selectedYear}
				label={i18n.t("pages.reports.select_year.label")}
				helperText={i18n.t("pages.reports.select_year.subtext")}
				noneValueName={i18n.t("pages.reports.select_year.all_value")}
				items={selectableYears}
				onChange={this.handleChangeSelectYear}
				showButtons={false}
			/>
		)
	}

    // Render select month menu
    renderSelectMonth( selectedMonth ) {

        // Gather list of selectable months
        let selectableMonths = [];
        const monthNames = CalendarHelper.getMonthNames( this.props.language, "long" );

        for( let i = 0; i < monthNames.length; i++ ) {
            selectableMonths.push( {
                id: (i+1),
                value: monthNames[i]
            })
        }

        return (
            <SelectControls
                selectedEntry={selectedMonth}
                label={i18n.t("pages.reports.select_month.label")}
                helperText={i18n.t("pages.reports.select_month.subtext")}
                showNoneValue={false}
                items={selectableMonths}
                onChange={this.handleChangeSelectMonth}
                showButtons={false}
            />
        )
    }

	// Render option buttons
	renderOption( selectedOption ) {
		return (
			<ToggleButtonGroup 
				size="small" 
				value={selectedOption}
				exclusive
				className={this.props.classes.options}
				onChange={(event, alignment) => this.handleOptionChange(event, alignment)}
			>
                <ToggleButton value="vacation">{i18n.t("pages.reports.options.vacation.title")}</ToggleButton>
                <ToggleButton value="accounting">{i18n.t("pages.reports.options.accounting.title")}</ToggleButton>
                <ToggleButton value="absence">{i18n.t("pages.reports.options.absence.title")}</ToggleButton>
                <ToggleButton value="worktime">{i18n.t("pages.reports.options.worktime.title")}</ToggleButton>
			</ToggleButtonGroup>
		)
	}

	render() {
		/**
		 * INPUTS
		 * adminData, language, dataLoadedChanged, alertChanged, actionInProgressChanged
		 */

		let selectYear = "";
        let selectMonth = "";
		let selectUser = "";
		let selectOption = "";
		let reportContent = "";

		if( null != this.state.selectUser ) {
			if( null != this.state.selectUser.selectedUserId && this.state.selectUser.selectedUserId !== "" ) {
				if( null != this.state.selectUser.selectableUsers && this.state.selectUser.selectableUsers.length > 0 ) {
					selectUser = (
						<SelectControls
							selectedEntry={this.state.selectUser.selectedUserId}
							label={i18n.t("pages.reports.select_user.label")}
							helperText={i18n.t("pages.reports.select_user.subtext")}
							refreshTooltip={i18n.t("buttons.select_user.tooltip")}
							items={this.state.selectUser.selectableUsers}
							onChange={this.handleChangeSelectUser}
							showButtons={false}
							showNoneValue={false}
						/>
					)
				}
			}
		}

		if( null != this.state.data.vacationReport || null != this.state.data.accountingReport || null != this.state.data.absenceReport ) {
			selectYear = this.renderSelect( this.state.data.vacationReport, this.state.data.accountingReport, this.state.data.absenceReport, this.state.selectedYear );
			selectOption = this.renderOption( this.state.selectedOption, this.state.data.vacationReport, this.state.data.accountingReport, this.state.data.absenceReport );

			if( "vacation" === this.state.selectedOption ) {
                if( null != this.state.data.vacationReport ) {
                    reportContent = (
                        <ReportVacationUser 
                            ref={this.vacationChildRef}
                            language={this.props.language}
                            year={this.state.selectedYear.toString()}
                            vacationReport={this.state.data.vacationReport}
                            userid={this.state.selectUser.selectedUserId}
                        />
                    )
                } else {
                    reportContent = (
                        <Grid item className={this.props.classes.noAccessGridItem}>
                            <Typography variant="body2" align="center">
                                {i18n.t("pages.reports.options.vacation.no_access")}
                            </Typography>
                        </Grid>
                    )
                }
			} else if( "accounting" === this.state.selectedOption ) {
                if( null != this.state.data.accountingReport ) {
                    reportContent = (
                        <ReportAccountingUser 
                            ref={this.accountingChildRef}
                            language={this.props.language}
                            year={this.state.selectedYear.toString()}
                            accountingReport={this.state.data.accountingReport}
                            userid={this.state.selectUser.selectedUserId}
                        />
                    )
                } else {
                    reportContent = (
                        <Grid item className={this.props.classes.noAccessGridItem}>
                            <Typography variant="body2" align="center">
                                {i18n.t("pages.reports.options.accounting.no_access")}
                            </Typography>
                        </Grid>
                    )
                }
			} else if( "absence" === this.state.selectedOption ) {
                if( null != this.state.data.absenceReport && null != this.state.data.specials ) {
                    reportContent = (
                        <ReportAbsenceUser 
                            ref={this.absenceChildRef}
                            language={this.props.language}
                            year={this.state.selectedYear.toString()}
                            absenceReport={this.state.data.absenceReport}
                            specials={this.state.data.specials}
							userid={this.state.selectUser.selectedUserId}
                        />
                    )
                } else {
                    reportContent = (
                        <Grid item className={this.props.classes.noAccessGridItem}>
                            <Typography variant="body2" align="center">
                                {i18n.t("pages.reports.options.absence.no_access")}
                            </Typography>
                        </Grid>
                    )
                }
			} else if( "worktime" === this.state.selectedOption ) {
                selectMonth = this.renderSelectMonth( this.state.selectedMonth );

                // There are two features in the accounting which lock certain cells
                // - Rolling auto accounting window (if configured)
                // - Specials which auto account
                // The only users who shall ever bypass these features are global admins and admin_accountings
                let accountingAutoLock = false;
                let accountingAutoLockPeriodOfDays = 0;
                let specialAutoAccountingLock = true;

                // Fill in settings from config for rolling auto account window
                if( null != this.context.config ) {
                    if( null != this.context.config.accounting.auto_lock ) {
                        accountingAutoLock = this.context.config.accounting.auto_lock;
                    }
                    if( null != this.context.config.accounting.auto_lock_period_in_days ) {
                        accountingAutoLockPeriodOfDays = this.context.config.accounting.auto_lock_period_in_days;
                    }
                }

                // Allow bypass for global admin and admin_accountings
                if( true === this.props.adminData.admin.global || true === this.props.adminData.admin.accountings ) {
                    accountingAutoLock = false;
                    specialAutoAccountingLock = false;
                }

                let defaultWorkStartTime = config.workTimes.defaultWorkStartTime;
                for( let i = 0; i < this.state.data.users.length; i++ ) {
                    if( this.state.data.users[i].userid === this.state.selectUser.selectedUserId && this.state.data.users[i].default_work_start_time != null ) {
                        defaultWorkStartTime = this.state.data.users[i].default_work_start_time;
                    }
                }

                reportContent = (
                    <ReportWorkTimeUser 
                        ref={this.worktimeChildRef}
                        language={this.props.language}
                        userid={this.state.selectUser.selectedUserId}
                        year={this.state.selectedYear.toString()}
                        month={this.state.selectedMonth.toString()}
                        worktimes={this.state.data.worktimes}
                        contracts={this.state.data.contracts}
                        absences={this.state.data.absences}
                        holidays={this.state.data.holidays}
                        specials={this.state.data.specials}
                        accountings={this.state.data.accountings}
                        accountingAutoLock={accountingAutoLock}
                        accountingAutoLockPeriodOfDays={accountingAutoLockPeriodOfDays}
                        specialAutoAccountingLock={specialAutoAccountingLock}
                        defaultWorkStartTime={defaultWorkStartTime}
                        onModifyWorkTime={(userid, data) => this.handleModifyWorkTime( userid, data )}
                        onDeleteWorkTime={(userid, dateJson) => this.handleDeleteWorkTime( userid, dateJson )}
                    />
                )
            }
		}

        // Export CSV button, tooltip changes depending on selected option
        let exportCsvButtons = [];
        {
            let buttonTooltip = null;
            if( "vacation" === this.state.selectedOption ) {
                buttonTooltip = i18n.t("pages.reports.team_reports.buttons.csv_selected_user");
            } else if( "accounting" === this.state.selectedOption ) {
                buttonTooltip = i18n.t("pages.reports.team_reports.buttons.csv_selected_user");
            } else if( "absence" === this.state.selectedOption ) {
                buttonTooltip = i18n.t("pages.reports.team_reports.buttons.csv_selected_user");
            } else if( "worktime" === this.state.selectedOption ) {
                buttonTooltip = i18n.t("pages.reports.team_reports.buttons.csv_selected_user_for_month");
            }

            exportCsvButtons.push({
                showIcon: true,
                showText: false,
                tooltip: buttonTooltip,
                customData: false
            })
        }
        {
            let buttonTooltip = null;
            if( "vacation" === this.state.selectedOption ) {
                buttonTooltip = i18n.t("pages.reports.team_reports.buttons.csv_all_users");
            } else if( "accounting" === this.state.selectedOption ) {
                buttonTooltip = i18n.t("pages.reports.team_reports.buttons.csv_all_users");
            } else if( "absence" === this.state.selectedOption ) {
                buttonTooltip = i18n.t("pages.reports.team_reports.buttons.csv_all_users");
            } else if( "worktime" === this.state.selectedOption ) {
                buttonTooltip = i18n.t("pages.reports.team_reports.buttons.csv_all_users_for_month");
            }

            exportCsvButtons.push({
                showIcon: true,
                showText: true,
                text: i18n.t("export.buttons.all"),
                tooltip: buttonTooltip,
                customData: true
            })
        }

		return (
			<div>
				<Grid container className={this.props.classes.mainGrid} justify="flex-start" alignItems="flex-start" spacing={1}>
					<Grid item container xs={12} className={this.props.classes.select} spacing={1} wrap='nowrap'>
                        <Grid item container xs={10} spacing={1}>
                            <Grid item>
                                {selectYear}
                            </Grid>
                            <Grid item>
                                {selectUser}
                            </Grid>
                            <Grid item>
                                {selectOption}
                            </Grid>
                            <Grid item>
                                {selectMonth}
                            </Grid>
                        </Grid>
                        <Grid item container xs={2} justify="flex-end">
                            <CSVLink
                                ref={this.csvLinkRef}
                                data={this.state.export.csv.data}
                                filename={this.state.export.csv.filename}
                                className="hidden"
                                target="_blank"
                            />
                            <ExportButtons 
                                csvButtons={exportCsvButtons}
                                onCsvClick={(customData) => this.handleCsvExportClick(customData)}
                            />
                        </Grid>
					</Grid>
					{reportContent}
				</Grid>
			</div>
		);
	}
};

export default withStyles(useStyles)(ReportsTeam);