import React, {Fragment} from 'react';
import { Text } from '@react-pdf/renderer';

const PdfTableColumn = ({data, style}) => {
    return (
        <Fragment>
            <Text style={style}>{data.value}</Text>
        </Fragment>
    )
};

export default PdfTableColumn
