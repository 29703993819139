import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CalendarHelper from '../classes/CalendarHelper.js';
import { StyledDataTable } from './StyledTable.js'
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Table Absence
 */
class TableAbsence extends Component {
    state = {}

    // Add to table data array
    addTableData( name, value ) {
        return { name, value };
    }

    /// Get series: booked absence days
    getSeriesAbsences( absenceReport, specials, year ) {
        let series = [];

        if( null != absenceReport ) {
            for( let i = 0; i < absenceReport.years.length; i++ ) {
                // Only return years based on selection
                if( year === "" || year === absenceReport.years[i].year.toString() ) {
                    for( let j = 0; j < absenceReport.years[i].months.length; j++ ) {

                        let seriesEntry = {
                            month: absenceReport.years[i].months[j].month,
                            absences: []
                        }

                        for( let k = 0; k < absenceReport.years[i].months[j].absences.length; k++ ) {

                            let absenceReason = "";
                            for( let l = 0; l < specials.length; l++ ) {
                                if( specials[l].specialid === absenceReport.years[i].months[j].absences[k].specialid ) {
                                    absenceReason = specials[l].name;
                                    break;
                                }
                            }

                            seriesEntry.absences.push( { 
                                reason: absenceReason, 
                                bookedDays: absenceReport.years[i].months[j].absences[k].days_absent_in_month 
                            });
                        }

                        seriesEntry.absences.sort(function(a, b) { return a.reason > b.reason ? 1 : -1; });
                        
                        series.push( seriesEntry );
                    }
                }
            }
        }

        return series;
    }

    // Get the summary for the export
    getSummary( absenceReport, specials ) {
        let summary = [];

        if( null != absenceReport ) {
            for( let i = 0; i < absenceReport.years.length; i++ ) {
                let summaryYear = {
                    year: absenceReport.years[i].year,
                    months: []
                }

                for( let j = 0; j < absenceReport.years[i].months.length; j++ ) {
                    let summaryMonth = {
                        month: absenceReport.years[i].months[j].month,
                        absences: []
                    }

                    for( let k = 0; k < specials.length; k++ ) {
                        let summarySpecial = {
                            special: specials[k],
                            days_absent_in_month: 0
                        }

                        for( let l = 0; l < absenceReport.years[i].months[j].absences.length; l++ ) {
                            if( absenceReport.years[i].months[j].absences[l].specialid === specials[k].specialid ) {
                                summarySpecial.days_absent_in_month = absenceReport.years[i].months[j].absences[l].days_absent_in_month;
                                break;
                            }
                        }

                        summaryMonth.absences.push( summarySpecial );
                    }

                    summaryYear.months.push( summaryMonth );
                }

                summary.push( summaryYear );
            }
        }

        return summary;
    }

    // Render summary
    renderSummary( absenceReport, specials, year ) {

        // If not specific year is selected, show a warning in this table since it does not make sense
        let tableHeader = [];
        let tableData = [];

        if( year !== "" ) {
            tableHeader = [
                { id: 1, width: "50%", name: i18n.t("pages.reports.options.absence.summary.headline_summary") },
                { id: 2, width: "50%", name: i18n.t("pages.reports.options.absence.summary.headline_days") }
            ];

            // Add a line for each used absence reason in this year
            let allAbsences = [];
            if( null != absenceReport ) {
                for( let i = 0; i < absenceReport.years.length; i++ ) {
                    if( absenceReport.years[i].year.toString() === year ) {
                        for( let j = 0; j < absenceReport.years[i].months.length; j++ ) {
                            for( let k = 0; k < absenceReport.years[i].months[j].absences.length; k++ ) {
                                allAbsences.push( absenceReport.years[i].months[j].absences[k] );
                            }
                        }
                    }
                }
            }

            if( null != specials ) {
                specials.sort(function(a, b) { return a.name > b.name ? 1 : -1; });

                for( let i = 0; i < specials.length; i++ ) {
                    let specialid = specials[i].specialid;
                    let sumOfDaysForSpecial = 0;

                    for( let j = 0; j < allAbsences.length; j++ ) {
                        if( allAbsences[j].specialid === specialid ) {
                            sumOfDaysForSpecial += allAbsences[j].days_absent_in_month;
                        }
                    }

                    tableData.push({ key: specials[i].name, values: [ sumOfDaysForSpecial ] });
                }
            }

        } else {
            tableHeader = [
                { id: 1, width: "50%", name: i18n.t("pages.reports.options.absence.summary.headline_summary") },
                { id: 2, width: "50%", name: "" }
            ];
            tableData.push({ key: i18n.t("pages.reports.options.absence.summary.warning"), values: [ "" ] });
        }

        let table = (
            <StyledDataTable 
                tableKey="absence-table-summary" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // Render absence table
    renderTable( absenceReport, specials, year ) {

        // If no specific year is given, the table makes no sence
        if( year === "" ) {
            return "";
        }

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "long" );
        const seriesAbsences = this.getSeriesAbsences( absenceReport, specials, year );

        // Assemble table content
        const tableHeader = [
            { id: 1, width: "33%", name: i18n.t("pages.reports.options.absence.table.month") },
            { id: 2, width: "33%", name: i18n.t("pages.reports.options.absence.table.reason") },
            { id: 3, width: "33%", name: i18n.t("pages.reports.options.absence.table.booked") }
        ];

        let tableData = [];

        if( seriesAbsences.length > 0 ) {
            for( let i = 0; i < monthNames.length; i++ ) {
                for( let j = 0; j < seriesAbsences[i].absences.length; j++ ) {
                    let tableRow = {
                        key: monthNames[i],
                        values: [
                            seriesAbsences[i].absences[j].reason,
                            seriesAbsences[i].absences[j].bookedDays
                        ]
                    }

                    if( j > 0 ) {
                        tableRow.key = "";
                    }

                    tableData.push(tableRow);
                }
            }
        } else {
            tableData.push({ key: i18n.t("pages.reports.options.absence.table.no_data"), values: [ "" ] });
        }

        let table = (
            <StyledDataTable 
                tableKey="absence-table-detail" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // Takes props: language, absenceReport
    render() {
        let absenceSummary = "";
        let absenceTable = "";
        if( null != this.props.absenceReport && null != this.props.specials && null != this.props.year ) {
            absenceSummary = this.renderSummary( this.props.absenceReport, this.props.specials, this.props.year );
            absenceTable = this.renderTable( this.props.absenceReport, this.props.specials, this.props.year );
        }

        return (
            <div>
                {absenceSummary}
                <br />
                {absenceTable}
            </div>
        );
    }
};

export default withStyles(useStyles)(TableAbsence);