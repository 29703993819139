import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import AuthContext from '../contexts/AuthContext';
import { config } from '../config';
import Copyright from '../components/Copyright'
import AlertMessage from '../components/AlertMessage'
import Link from '@material-ui/core/Link';
import i18n from 'i18next';

const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

/**
 * Forgot Password component
 */
class ForgotPassword extends Component {
    state = {
        email: ""
    };

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind( this );
        this.handleChange = this.handleChange.bind( this );
        this.handleBackToLogin = this.handleBackToLogin.bind( this );

        if( this.props.email != null && this.props.email !== "" ) {
            this.state.email = this.props.email;
        }

        // Set the language here initially, we need that since we are not logged in yet and do not know the users language settings
        i18n.changeLanguage(config.frontendFallbackLanguage);
    }

    // Handle change of values
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value }, () => {
            if( this.props.onChange ) {
                this.props.onChange( this.state );
            }
        })
    }

    // Handle click on back to login link
    handleBackToLogin(event) {
        event.preventDefault();
        if( this.props.onBackToLogin ) {
            this.props.onBackToLogin();
        }
    }

    // Handle submit button click
    handleSubmit(event) {
        event.preventDefault();
        if( this.props.onSubmit ) {
            this.props.onSubmit( this.state );
        }
    }

    // Check if submit button is enabled
    isSubmitButtonEnabled() {
        if( this.state.email !== "" ) {
            return true;
        }
        return false;
    }

    // React render
    render() {
        const isSubmitButtonEnabled = this.isSubmitButtonEnabled();
        const { classes } = this.props;

        // Also check if we already got a result after sending the forgot password request
        let showForgotPasswordResult = false;
        let forgotPasswordResult = {
            severity: "info",
            message: ""
        }

        if( this.props.forgotPasswordResult != null ) {
            showForgotPasswordResult = true;
            if( true === this.props.forgotPasswordResult ) {
                forgotPasswordResult = {
                    severity: "success",
                    message: i18n.t("auth.forgotpassword_success")
                }
            }
        }

        return (
            <AuthContext.Consumer>
                {context => {
                    return (
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <div className={classes.paper}>
                                <Avatar className={classes.avatar}>
                                    <LockOutlinedIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5" align="center">
                                    {config.mainTitle}<br/>{i18n.t("auth.forgotpassword")}
                                </Typography>
                                <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                                    <TextField
                                        value={this.state.email}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label={i18n.t("email")}
                                        name="email"
                                        autoComplete="email"
                                        onChange={this.handleChange}
                                        autoFocus
                                        error={this.state.email === ""}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={!isSubmitButtonEnabled}
                                    >
                                        {i18n.t("submit")}
                                    </Button>
                                </form>
                            </div>
                            <div align="center">
                                <Link href="#" onClick={this.handleBackToLogin}>
                                    {i18n.t("auth.backtologin_link")}
                                </Link>
                            </div>
                            <Copyright />
                            <AlertMessage severity="error" message={context.errorMessage} />
                            {showForgotPasswordResult && (
                                <AlertMessage severity={forgotPasswordResult.severity} message={forgotPasswordResult.message} />
                            )}
                        </Container>
                    );
                }}
            </AuthContext.Consumer>
        );
    }
}

export default withStyles(useStyles)(ForgotPassword);