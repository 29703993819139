import React, { Component } from 'react';
import AuthContext from '../contexts/AuthContext';
import Login from '../components/Login';
import ForgotPassword from '../components/ForgotPassword';
import { config } from '../config.js';

/**
 * Authentication Page (Login Page)
 */
class AuthPage extends Component {
    static contextType = AuthContext;

    state = {
        forgotPasswordClicked: false,
        forgotPasswordEmail: null,
        forgotPasswordResult: null
    }

    constructor(props) {
        super(props);
        this.onSubmitForgotPassword = this.onSubmitForgotPassword.bind( this );
    }

    // Called by login child component
    onSubmitLogin = state => {
        const email = state.email;
        const password = state.password;

        if (email.trim().length === 0 || password.trim().length === 0) {
            // Empty contents on at least one mandatory field, simply show the form again
            return;
        }

        this.context.login( email, password );
    };

    // Called by login child component
    onForgotPassword = state => {
        this.setState( { forgotPasswordClicked: true, forgotPasswordEmail: state.email });
    }

    // Called by login child component
    onBackToLogin = state => {
        this.setState( { forgotPasswordClicked: false, forgotPasswordEmail: null });
    }

    // Called by ForgotPassword child component
    async onSubmitForgotPassword(state) {
        const email = state.email;

        if (email.trim().length === 0 ) {
            // Empty contents on at least one mandatory field, simply show the form again
            return;
        }

        const result = await this.context.forgotPassword( email );
        this.setState({ forgotPasswordResult: result });

        // Return the user to login page after a short delay
        if( true === result ) {
            let tID = setTimeout(function () {
                window.location.href = "/";
                window.clearTimeout(tID);		// clear time out.
            }, 2000);
        }
    }

    // React render
    render() {
        if( false === this.state.forgotPasswordClicked ) {
            return (
                <Login onSubmit={this.onSubmitLogin} onForgotPassword={this.onForgotPassword} autoLogin={config.autoLogin}/>
            );
        } else {
            return (
                <ForgotPassword 
                    onBackToLogin={this.onBackToLogin} 
                    onSubmit={this.onSubmitForgotPassword} 
                    email={this.state.forgotPasswordEmail} 
                    forgotPasswordResult={this.state.forgotPasswordResult}
                />
            )
        }        
    }
}

export default AuthPage;