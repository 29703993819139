import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CalendarHelper from '../classes/CalendarHelper.js';
import Chart from "react-apexcharts";
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Chart Absence
 */
class ChartAbsence extends Component {
    state = {
        chart: ""
    }

    /// Get x-axis labels
    getLabels( absenceReport ) {
        let labels = [];

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "short" );

        if( null != absenceReport ) {
            for( let i = 0; i < absenceReport.years.length; i++ ) {

                // Only return years based on selection
                if( this.props.year === "" || this.props.year === absenceReport.years[i].year.toString() ) {
                    const yearString = " '" + absenceReport.years[i].year.toString().substring( 2, 4 )
                    for( let j = 0; j < absenceReport.years[i].months.length; j++ ) {

                        // Add label for start of the month
                        labels.push( monthNames[absenceReport.years[i].months[j].month-1] + yearString );
                    }
                }
            }
        }

        return labels;
    }

    /// Get series: one series per available absence type
    getSeriesAbsences( absenceReport, specials ) {
        let chartSeries = [];

        // Add an empty series per special
        for( let i = 0; i < specials.length; i++ ) {
            let series = {
                name: specials[i].name,
                id: specials[i].specialid,
                type: 'column',
                color: "#"+specials[i].color_code,
                data: []
            }
            chartSeries.push( series );
        }

        let yearCounter = 0;

        for( let i = 0; i < absenceReport.years.length; i++ ) {
            // Only return years based on selection
            if( this.props.year === "" || this.props.year === absenceReport.years[i].year.toString() ) {
                for( let j = 0; j < absenceReport.years[i].months.length; j++ ) {
                    for( let k = 0; k < absenceReport.years[i].months[j].absences.length; k++ ) {

                        const specialid = absenceReport.years[i].months[j].absences[k].specialid;
                        const daysAbsent = absenceReport.years[i].months[j].absences[k].days_absent_in_month;

                        // Add to series
                        for( let l = 0; l < chartSeries.length; l++ ) {
                            if( chartSeries[l].id === specialid ) {
                                chartSeries[l].data.push( daysAbsent );
                            }
                        }
                    }

                    // Add a zero to each series which was not present in the current month
                    for( let l = 0; l < chartSeries.length; l++ ) {
                        let targetLength = (yearCounter*12)+(j+1);
                        if( chartSeries[l].data.length < targetLength ) {
                            chartSeries[l].data.push( 0 );
                        }
                    }
                }
                yearCounter++;
            }
        }

        return chartSeries;
    }

    /// Generate the year start annotations
    getYearAnnotations( absenceReport ) {
        let annotations = [];

        // If we selected a specific year, do not add annotations
        if( this.props.year !== "" ) {
            return annotations;
        }

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "short" );

        if( null != absenceReport ) {
            for( let i = 0; i < absenceReport.years.length; i++ ) {
                const year = absenceReport.years[i].year;
                const yearCut = year.toString().substring(2, 4);
                const januaryLabel = monthNames[0] + " '" + yearCut;

                annotations.push(
                    {
                        x: januaryLabel,
                        borderColor: '#FF4560',
                        label: {
                            style: {
                                color: '#000',
                            },
                            orientation: 'vertical',
                            text: year.toString()
                        }
                    }
                )
            }
        }

        return annotations;
    }

    // Called by react when this component has been mounted
    componentDidMount() {

        // Pre-render chart since it will not change
        if( null != this.props.absenceReport && null != this.props.specials && null != this.props.year ) {
            let absenceChart = this.renderChart( this.props.absenceReport, this.props.specials );
            this.setState({ chart: absenceChart });
        }
    }

    // Called by react when this component did update
    componentDidUpdate( prevProps ) {
        if( this.props.year !== prevProps.year || this.props.userid !== prevProps.userid ) {
            // Pre-render chart since it will not change
            if( null != this.props.absenceReport && null != this.props.specials && null != this.props.year ) {
                let absenceChart = this.renderChart( this.props.absenceReport, this.props.specials );
                this.setState({ chart: absenceChart });
            }
        }
    }

    // Render chart (contracts is an optional value)
    renderChart( absenceReport, specials ) {

        const chartLabels = this.getLabels( absenceReport );
        const yearAnnotations = this.getYearAnnotations( absenceReport );

        // Get a series per absence type
        let chartSeries = this.getSeriesAbsences( absenceReport, specials );

        const chartOptions = {
            chart: {
                animations: {
                    enabled: false
                },
                type: 'line',
                id: 'absence-chart',
                toolbar: {
                    tools: {
                        download: false,
                        pan: false
                    }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                }
            },
            annotations: {
                yaxis: [],
                xaxis: yearAnnotations,
                points: []
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: 'true',
                width: 2,
            },
            grid: {},
            title: {},
            labels: chartLabels,
            xaxis: {},
            yaxis: {
                min: 0,
                axisBorder: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0
                },
                title: {
                    text: i18n.t("pages.reports.options.absence.chart.yaxis.label"),
                    rotate: -90,
                    style: {
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: '12px',
                        fontWeight: 100
                    }
                }
            },
            tooltip: {
                shared: true
            }
        }

        return (
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="line"
            />
        )
    }

    render() {
        return (
            <div>
                {this.state.chart}
            </div>
        );
    }
};

export default withStyles(useStyles)(ChartAbsence);