import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = theme => ({});

/**
 * Modal Dialog Text Field Component
 */
class ModalDialogTextField extends Component {
    state = {
        checked: false,
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind( this );
    }

    // General push of new data to parent
    updateControlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent ) {
        if( null != this.props.controlButtonsAndPushContent ) {
            this.props.controlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent );
        }
    }

    // Checkbox changed
    handleChange(event) {
        let checked = event.target.checked;

        this.updateControlButtonsAndPushContent( true, true, { checked: checked } );
        this.setState({ checked: checked });
    }

    // Called by react when this component has been mounted
    componentDidMount() {

        // Check for an incoming value from the parent
        let checked = false;
        if( null != this.props.checked ) {
            checked = this.props.checked;
        }

        this.updateControlButtonsAndPushContent( true, true, { checked: checked } );
        this.setState({ checked: checked });
    }

    // React render
    render() {
        // Used this.props
        // setLeftButtonState(bool), setRightButtonState(bool), setContent(json), verifyContent(json), label, maxLength

        return (
            <React.Fragment>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.checked}
                            onChange={this.handleChange}
                            color="primary"
                        />
                    }
                    label={this.props.label}
                />
            </React.Fragment>
        );
    }
};

export default withStyles(useStyles)(ModalDialogTextField);