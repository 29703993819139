import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { jsonEqual } from '../helpers.js';
import { SketchPicker } from 'react-color';

const useStyles = theme => ({
    content: {
        flexGrow: 1,
    }
});

/**
 * Modal Dialog Color Picker Component
 */
class ModalDialogColorPicker extends Component {
    state = {
        initialColorCode: "",
        currentColorCode: ""
    };

    constructor(props) {
        super(props);
        this.handleColorCodeChanged = this.handleColorCodeChanged.bind( this );
    }

    // Determine the button state for the parent dialog
    getButtonState( initialColorCode, currentColorCode ) {
        // If currentColorCode is different to initialColorCode, enable the left and right buttons
        if( initialColorCode !== currentColorCode ) {
            return { left: true, right: true };
        } else {
            return { left: false, right: true };
        }
    }

    // General push of new data to parent
    updateControlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent ) {
        if( null != this.props.controlButtonsAndPushContent ) {
            this.props.controlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent );
        }
    }

    // To check if the data has changed compared to the initial data
    getDataChanged() {
        return !( jsonEqual( this.state.initialSelectedOptions, this.state.currentSelectedOptions ) );
    }

    // Called when the color code changed
    handleColorCodeChanged( colorObject, event ) {

        // Build the color code from the object
        let newColorCode = "";
        if( null != colorObject ) {
            newColorCode = colorObject.hex.slice( 1 ).toUpperCase();
        }

        const { left, right } = this.getButtonState( this.state.initialColorCode, newColorCode );
        this.updateControlButtonsAndPushContent( left, right, { colorCode: newColorCode } );
        this.setState({ currentColorCode: newColorCode });
    }

    // Set all initial data
    updateInitialData() {
        let initialColorCode = null;

        if( null != this.props.getColorCode ) {
            initialColorCode = this.props.getColorCode();
        }

        // Set states
        const { left, right } = this.getButtonState( initialColorCode, initialColorCode );
        this.updateControlButtonsAndPushContent( left, right, { colorCode: initialColorCode } );
        this.setState({
            initialColorCode: initialColorCode, 
            currentColorCode: initialColorCode
        });
    }

    // Called by react when this component has been mounted
    componentDidMount() {
        this.updateInitialData();
    }

    render() {
        // Extract locally used props and leave others
        // None used here, forward all
        var { controlButtonsAndPushContent, getColorCode, ...otherProps } = this.props;

        return (
            <div className={this.props.classes.content}>
                <SketchPicker
                    color={this.state.currentColorCode}
                    onChange={this.handleColorCodeChanged}
                    disableAlpha={true}
                    {...otherProps}
                />
            </div>
        );
    }
};

export default withStyles(useStyles)(ModalDialogColorPicker);