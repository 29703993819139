import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { StyledTableCell, StyledTableRow, StyledTableHead, StyledTable, StyledTableRowHover } from '../components/StyledTable.js'

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const useStyles = theme => ({
    button: {
        margin: theme.spacing(0.5, 0),
    },
    iconRotate: {
        transform: "rotate(-180deg)"
    }
});


/**
 * Custom Transfer List
 */
class TransferList extends Component {
    state = {
        selectedIdsLeft: [],
        selectedIdsRight: []
    }

    tableId = {
        left: 0,
        right: 1
    }

    constructor(props) {
        super(props);
        this.discoverIdKey = this.discoverIdKey.bind( this );
        this.renderList = this.renderList.bind( this );
        this.isRowSelected = this.isRowSelected.bind( this );
        this.handleClickAllToRight = this.handleClickAllToRight.bind( this );
        this.handleClickAllToLeft = this.handleClickAllToLeft.bind( this );
        this.handleClickSelectedToRight = this.handleClickSelectedToRight.bind( this );
        this.handleClickSelectedToLeft = this.handleClickSelectedToLeft.bind( this );
    }

    // Search for the id key in the given data
    discoverIdKey( data ) {
        let keyId = "";

        if( null != data ) {
            if( data.length > 0 ) {
                for( var key in data[0] ) {
                    if( true === /.*id/.test(key) ) {
                        keyId = key;
                        break;
                    }
                }
            }
        }

        return keyId;
    }

    // Check if row is selected
    isRowSelected( tableId, rowId ) {
        if( tableId === this.tableId.left ) {
            return this.state.selectedIdsLeft.indexOf( rowId ) !== -1;
        } else {
            return this.state.selectedIdsRight.indexOf( rowId ) !== -1;
        }        
    }

    // Call to deselect all in both lists
    deselectAll() {
        let emptyArrayLeft = [];
        let emptyArrayRight = [];
        this.setState({ selectedIdsLeft: emptyArrayLeft, selectedIdsRight: emptyArrayRight });
    }

    // Button move-all-to-right clicked
    handleClickAllToRight( event ) {
        let leftListData = this.props.leftListData;
        let rightListData = this.props.rightListData;

        let newLeftListData = [];
        let newRightListData = [];
        newRightListData = newRightListData.concat( leftListData, rightListData );

        // Deselect everything
        this.deselectAll();

        if( null != this.props.listDataChanged ) {
            this.props.listDataChanged( newLeftListData, newRightListData );
        }
    }

    // Button move-all-to-left clicked
    handleClickAllToLeft( event ) {
        let leftListData = this.props.leftListData;
        let rightListData = this.props.rightListData;

        let newLeftListData = [];
        let newRightListData = [];
        newLeftListData = newLeftListData.concat( rightListData, leftListData );

        // Deselect everything
        this.deselectAll();

        if( null != this.props.listDataChanged ) {
            this.props.listDataChanged( newLeftListData, newRightListData );
        }
    }

    // Button move-selected-to-right clicked
    handleClickSelectedToRight( event ) {
        let leftListData = this.props.leftListData;
        let rightListData = this.props.rightListData;

        let newLeftListData = [];
        let newRightListData = [];
        newLeftListData = newLeftListData.concat( leftListData );
        newRightListData = newRightListData.concat( rightListData );

        let keyId = this.discoverIdKey( leftListData );

        // Go through left selected ids and move them to the right
        for( let i = 0; i < this.state.selectedIdsLeft.length; i++ ) {
            for( let j = 0; j < newLeftListData.length; j++ ) {
                if( this.state.selectedIdsLeft[i] === newLeftListData[j][keyId] ) {

                    // Add item to new right list
                    newRightListData.push( newLeftListData[j] );

                    // Remove item from the new left list
                    newLeftListData.splice( j, 1 );
                    break;
                }
            }
        }

        // Deselect everything
        this.deselectAll();

        if( null != this.props.listDataChanged ) {
            this.props.listDataChanged( newLeftListData, newRightListData );
        }
    }

    // Button move-selected-to-left clicked
    handleClickSelectedToLeft( event ) {
        let leftListData = this.props.leftListData;
        let rightListData = this.props.rightListData;

        let newLeftListData = [];
        let newRightListData = [];
        newLeftListData = newLeftListData.concat( leftListData );
        newRightListData = newRightListData.concat( rightListData );

        let keyId = this.discoverIdKey( leftListData );

        // Go through right selected ids and move them to the left
        for( let i = 0; i < this.state.selectedIdsRight.length; i++ ) {
            for( let j = 0; j < newRightListData.length; j++ ) {
                if( this.state.selectedIdsRight[i] === newRightListData[j][keyId] ) {

                    // Add item to new left list
                    newLeftListData.push( newRightListData[j] );

                    // Remove item from the new right list
                    newRightListData.splice( j, 1 );
                    break;
                }
            }
        }

        // Deselect everything
        this.deselectAll();

        if( null != this.props.listDataChanged ) {
            this.props.listDataChanged( newLeftListData, newRightListData );
        }
    }

    // Handle if table row is clicked
    handleTableRowClick( event, tableId, rowId ) {
        let selectedIndex = 0;
        let selectedLength = 0;
        if( tableId === this.tableId.left ) {
            selectedIndex = this.state.selectedIdsLeft.indexOf( rowId );
            selectedLength = this.state.selectedIdsLeft.length;
        } else {
            selectedIndex = this.state.selectedIdsRight.indexOf( rowId );
            selectedLength = this.state.selectedIdsRight.length;
        }
        let newSelected = [];

        if( selectedIndex === -1 ) {
            // Row not yet selected
            if( tableId === this.tableId.left ) {
                newSelected = newSelected.concat( this.state.selectedIdsLeft, rowId );
            } else {
                newSelected = newSelected.concat( this.state.selectedIdsRight, rowId );
            }
            
        } else if( selectedIndex === 0 ) {
            // Row selected as first item in the list
            if( tableId === this.tableId.left ) {
                newSelected = newSelected = newSelected.concat(this.state.selectedIdsLeft.slice(1));
            } else {
                newSelected = newSelected = newSelected.concat(this.state.selectedIdsRight.slice(1));
            }
        } else if( selectedIndex === selectedLength - 1 ) {
            if( tableId === this.tableId.left ) {
                newSelected = newSelected.concat(this.state.selectedIdsLeft.slice(0, -1));
            } else {
                newSelected = newSelected.concat(this.state.selectedIdsRight.slice(0, -1));
            }
        } else if( selectedIndex > 0 ) {
            // Row is selected as a later item in the list
            if( tableId === this.tableId.left ) {
                newSelected = newSelected.concat( this.state.selectedIdsLeft.slice(0, selectedIndex), this.state.selectedIdsLeft.slice(selectedIndex + 1), );
            } else {
                newSelected = newSelected.concat( this.state.selectedIdsRight.slice(0, selectedIndex), this.state.selectedIdsRight.slice(selectedIndex + 1), );
            }
        }

        if( tableId === this.tableId.left ) {
            this.setState({ selectedIdsLeft: newSelected });
        } else {
            this.setState({ selectedIdsRight: newSelected });
        }
    }

    // Render list
    renderList( tableId, header, data ) {
        if( data == null ) {
            data = [];
        }

        // We expect the data json object to include some key with "*id" and some key with "*name"
        let keyId = this.discoverIdKey( data );

        let content = (
            <TableContainer component={Paper} style={{ minWidth: "350px", width: "350px", maxWidth: "350px", minHeight: "275px", height: "275px", maxHeight: "275px", overflowY: "auto" }}>
                <StyledTable stickyHeader>
                    <StyledTableHead>
                        <StyledTableRow key="header">
                            <StyledTableCell key={0}>{header}</StyledTableCell>
                        </StyledTableRow>
                    </StyledTableHead>
                    <TableBody>
                        {data.map((row) => {
                            const isRowSelected = this.isRowSelected( tableId, row[keyId] );
                            let rowData = "";
                            for( var key in row ) {
                                if( key !== keyId ) {
                                    if( rowData !== "" ) {
                                        rowData += " ";
                                    }
                                    rowData += row[key];
                                }
                            }
                            return (
                                <StyledTableRowHover key={row[keyId]} onClick={(event) => this.handleTableRowClick(event, tableId, row[keyId])} selected={isRowSelected}>
                                    <StyledTableCell>{rowData}</StyledTableCell>
                                </StyledTableRowHover>
                            )
                        })}
                    </TableBody>
                </StyledTable>
            </TableContainer>
        );

        return content;
    }

    render() {
        // Used this.props
        // leftListHeader, leftListData, rightListHeader, rightListData, listDataChanged( leftListData, rightListData )
        let contentLeftList = this.renderList( this.tableId.left, this.props.leftListHeader, this.props.leftListData );
        let contentRightList = this.renderList( this.tableId.right, this.props.rightListHeader, this.props.rightListData );

        const classes = this.props.classes;

        return (
            <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item>
                    {contentLeftList}
                </Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={(event) => this.handleClickAllToRight(event)}
                    >
                        <DoubleArrowIcon />
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={(event) => this.handleClickSelectedToRight(event)}
                    >
                        <KeyboardArrowRightIcon />
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={(event) => this.handleClickSelectedToLeft(event)}
                    >
                        <KeyboardArrowLeftIcon />
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={(event) => this.handleClickAllToLeft(event)}
                    >
                        <DoubleArrowIcon className={ classes.iconRotate } />
                    </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    {contentRightList}
                </Grid>
            </Grid>
        );
    }
};

export default withStyles(useStyles)(TransferList);