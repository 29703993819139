import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 */
class HolidayService {

    /**
     * Get holidays
     * @param {*} holidaySearch { date, name }
     */
    async getHolidays( holidaySearch ) {
        var request = {
            query: `
            query getHolidays($holidaySearch: HolidaySearch!) {
                getHolidays(holidaySearch: $holidaySearch) {
                    date,
                    name
                }
            }
            `,
            variables: {
                holidaySearch: holidaySearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Get holidays within date range
     * @param {*} holidayRangeSearch { minDate, maxDate }
     */
    async getHolidaysRange( holidayRangeSearch ) {
        var request = {
            query: `
            query getHolidaysRange($holidayRangeSearch: HolidayRangeSearch!) {
                getHolidaysRange(holidayRangeSearch: $holidayRangeSearch) {
                    date
                    name
                }
            }
            `,
            variables: {
                holidayRangeSearch: holidayRangeSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Create a new holiday
     * @param {*} holidayInput { date, name }
     */
    async createHoliday( holidayInput ) {
        var request = {
            query: `
            mutation createHoliday($holidayInput: HolidayInput) {
                createHoliday(holidayInput: $holidayInput) {
                    date,
                    name
                }
            }
            `,
            variables: {
                holidayInput: holidayInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update a holiday
     * @param {*} holidaySearch { date, name }
     * @param {*} holidayUpdate { date, name }
     */
    async updateHoliday( holidaySearch, holidayUpdate ) {
        var request = {
            query: `
            mutation updateHoliday($holidaySearch: HolidaySearch!, $holidayUpdate: HolidayUpdate!) {
                updateHoliday(holidaySearch: $holidaySearch, holidayUpdate: $holidayUpdate) {
                    date,
                    name
                }
            }
            `,
            variables: {
                holidaySearch: holidaySearch,
                holidayUpdate: holidayUpdate
            }
        };

        return await ApiService.doPost( request );
    }   

    /**
     * Delete a holiday
     * @param {*} holidaySearch { date, name }
     */
    async deleteHoliday( holidaySearch ) {
        var request = {
            query: `
            mutation deleteHoliday($holidaySearch: HolidaySearch!) {
                deleteHoliday(holidaySearch: $holidaySearch) {
                    result
                }
            }
            `,
            variables: {
                holidaySearch: holidaySearch
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new HolidayService();
