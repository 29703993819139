import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
});

function tabProps(index) {
    return {
        id: `tab-${index}`,
        key: `tab-${index}`
    };
}

function tabPanelProps(index) {
    return {
        id: `tab-panel-${index}`,
        key: `tab-panel-${index}`
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-tabpanel-${index}`}
        {...other}
    >
        {value === index && (
            <Box p={3}>
                {children}
            </Box>
        )}
    </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

/**
 * TabContainer
 */
class TabContainer extends Component {
    state = {
        value: 0
    }

    constructor(props) {
        super(props);

        this.handleTabChange = this.handleTabChange.bind( this );
    }

    // Handle click on a new tab
    handleTabChange( event, newValue ) {
        this.setState({ value: newValue });

        if( null != this.props.onChange ) {
            this.props.onChange( newValue );
        }
    }

    // Called by react when this component has been mounted
    componentDidMount() {
        if( null != this.props.tabIndex ) {
            this.setState({ value: this.props.tabIndex });
        }
    }

    render() {
        const tabHeader = this.props.tabHeader;
        const tabDesc = this.props.tabDesc;

        // Ensure amount of tab description items is as long as the amount of tab headers (if given)
        if( null != tabDesc ) {
            if( tabHeader.length !== tabDesc.length ) {
                return (
                    <div className={this.props.classes.root}>
                        ERROR: Invalid tabDesc to tabHeader length, they must match
                    </div>
                )
            }
        }

        let tabDescContent = "";
        if( null != tabDesc ) {
            tabDescContent = tabDesc[this.state.value];
        }

        let childContent = "";
        if( null != this.props.children && this.props.children.length > 0 ) {
            childContent = (
                <div>
                    {this.props.children.map((child, index) => (
                        <TabPanel value={this.state.value} index={tabHeader[index].value} {...tabPanelProps(index)}>
                            {child}
                        </TabPanel>
                    ))}
                </div>
            )
        }

        return (
            <div className={this.props.classes.root}>
                {tabDescContent}
                <AppBar position="static">
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleTabChange}
                        variant="scrollable"
                        scrollButtons="off"
                    >
                        {tabHeader.map((tab, index) => {
                            return (
                                <Tab icon={tab.icon} label={tab.label} value={tab.value} {...tabProps(index)} />
                            )
                        })}
                    </Tabs>
                </AppBar>
                {childContent}
            </div>
        )
    }
};

export default withStyles(useStyles)(TabContainer);