import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { StyledTableCell, StyledTableRow, StyledTableHead, StyledTable, StyledTableSortLabel, stableSort, getComparator } from './StyledTable.js'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import ModalDialog from './ModalDialog';
import Grid from '@material-ui/core/Grid';
import CustomTextField from './CustomTextField'
import CustomDatePicker from './CustomDatePicker'
import AuthContext from '../contexts/AuthContext';
import { config } from '../config';
import i18n from 'i18next';
import _ from 'lodash'

const useStyles = theme => ({
    tableCellName: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1.0),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
    },
    tableCellDate: {
    },
    tablePaginationGrid: {
        height: '60px',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tablePagination: {
        '& .MuiTablePagination-toolbar': {
            height: '60px',
            minHeight: '60px',
        },
    },
});

/**
 * Holidays Custom View
 */
class HolidaysCustom extends Component {
    static contextType = AuthContext;

    state = {
        customHolidays: [],
        currentYear: null,
        tablePage: 0,
        tableRowsPerPage: 10,
        order: "asc",
        orderBy: 0,
        deleteDialog: {
            open: false,
            date: null,
            name: null,
            yearStart: null,
            yearEnd: null
        }
    }

    constructor(props) {
        super(props);

        this.handleChangeTablePage = this.handleChangeTablePage.bind( this );
        this.handleChangeTableRowsPerPage = this.handleChangeTableRowsPerPage.bind( this );
        this.handleTableRowDeleteClick = this.handleTableRowDeleteClick.bind( this );
        this.verifyNameFieldContent = this.verifyNameFieldContent.bind( this );
        this.handleNameFieldChange = this.handleNameFieldChange.bind( this );
        this.verifyDateFieldContent = this.verifyDateFieldContent.bind( this );
        this.verifyYearStartFieldContent = this.verifyYearStartFieldContent.bind( this );
        this.verifyYearEndFieldContent = this.verifyYearEndFieldContent.bind( this );
        this.handleDateFieldChange = this.handleDateFieldChange.bind( this );
        this.handleYearStartFieldChange = this.handleYearStartFieldChange.bind( this );
        this.handleYearEndFieldChange = this.handleYearEndFieldChange.bind( this );
        this.handleTableRowDeleteDialog_Ok = this.handleTableRowDeleteDialog_Ok.bind( this );
        this.handleTableRowDeleteDialog_Cancel = this.handleTableRowDeleteDialog_Cancel.bind( this );
        this.handleRequestSort = this.handleRequestSort.bind( this );
        this.sortTableData = this.sortTableData.bind( this );
    }

    /**
     * TABLE PAGINATION EVENT HANDLERS
     */

    // Called if page changes on the table display occur
    handleChangeTablePage = (event, newPage) => {
        this.setState({tablePage: newPage});
    };
    
    // Called if the user selects a different amount of shown rows
    handleChangeTableRowsPerPage = (event) => {
        this.setState({tablePage: 0, tableRowsPerPage: +event.target.value});
    };

    /**
     * HANDLERS
     */

    // Called when the user clicked on the delete button of a row
    handleTableRowDeleteClick( event, date, name, yearStart, yearEnd ) {
        event.stopPropagation();

        // Remove the year from the date
        const customDate = date.slice( 5 );

        this.openTableRowDeleteDialog( customDate, name, yearStart, yearEnd );
    }

    // Verify the entered new name in a row
    verifyNameFieldContent( name ) {
        if( null != this.props.verifyName ) {
            return this.props.verifyName( name );
        }
        return false;
    }

    // Update the name on a row
    handleNameFieldChange( event, date, name, yearStart, yearEnd, newName ) {
        event.stopPropagation();

        // Remove the year from the date
        const customDate = date.slice( 5 );

        if( null != this.props.onUpdateName ) {
            this.props.onUpdateName( customDate, name, yearStart, yearEnd, newName );
        }
    }

    // Verify the entered new date in a row
    verifyDateFieldContent( date ) {

        if( null != this.props.verifyDateHoliday ) {
            const result = this.props.verifyDateHoliday( date );
            if( false === result.state ) {
                return result;
            }
        }

        if( null != this.props.verifyDateCustomHoliday ) {
            const result = this.props.verifyDateCustomHoliday( date );
            if( false === result.state ) {
                return result;
            }
        }

        return { state: true, msg: "" };
    }

    verifyYearStartFieldContent( yearStart, yearEnd ) {

        // Make sure start year is smaller or equal to end year
        if( null != yearEnd ) {
            if( parseInt( yearStart ) > yearEnd ) {
                return { state: false, msg: "" };
            }
        }
        return { state: true, msg: "" };
    }

    verifyYearEndFieldContent( yearEnd, yearStart ) {

        // Make sure end year is greater or equal to start year
        if( parseInt( yearEnd ) < yearStart ) {
            return { state: false, msg: "" };
        }
        return { state: true, msg: "" };
    }

    // Update the date on a row
    handleDateFieldChange( event, date, name, yearStart, yearEnd, newDate ) {
        event.stopPropagation();

        // Remove the year from the date
        const customDate = date.slice( 5 );
        const customNewDate = newDate.slice( 5 );

        if( null != this.props.onUpdateDate ) {
            this.props.onUpdateDate( customDate, name, yearStart, yearEnd, customNewDate );
        }
    }

    // Update the start year on a row
    handleYearStartFieldChange( event, date, name, yearStart, yearEnd, newYearStart ) {
        event.stopPropagation();

        // Remove the year from the date
        const customDate = date.slice( 5 );

        if( null != this.props.onUpdateYearStart ) {
            this.props.onUpdateYearStart( customDate, name, yearStart, yearEnd, newYearStart );
        }
    }

    // Update the start year on a row
    handleYearEndFieldChange( event, date, name, yearStart, yearEnd, newYearEnd ) {
        event.stopPropagation();

        // Remove the year from the date
        const customDate = date.slice( 5 );

        if( null != this.props.onUpdateYearEnd ) {
            this.props.onUpdateYearEnd( customDate, name, yearStart, yearEnd, newYearEnd );
        }
    }

    /**
     * ALERT DIALOG <DELETE>
     */

    // Called to open the delete dialog
    openTableRowDeleteDialog( date, name, yearStart, yearEnd ) {
        let deleteDialog = {
            open: true,
            date: date,
            name: name,
            yearStart: yearStart,
            yearEnd: yearEnd
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    // Called when the user clicked "Ok" on the confirm row delete dialog
    handleTableRowDeleteDialog_Ok( date, name, yearStart, yearEnd ) {
        this.closeTableRowDeleteDialog();

        if( null != this.props.onDelete ) {
            this.props.onDelete( date, name, yearStart, yearEnd );
        }
    }

    // Called when the user clicked "Cancel" or close on the confirm row delete dialog
    async handleTableRowDeleteDialog_Cancel() {
        this.closeTableRowDeleteDialog();
    }

    // Close the delete alert dialog
    closeTableRowDeleteDialog() {
        let deleteDialog = {
            open: false,
            name: null,
            yearStart: null,
            yearEnd: null
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    // Called to refresh the custom holidays
    refreshCustomHolidays() {
        let customHolidays = [];
        
        if( null != this.props.getCustomHolidays ) {
            customHolidays = this.props.getCustomHolidays();
        }

        this.setState({ customHolidays: customHolidays });
    }

    // Called by react when this component has been mounted
    componentDidMount() {
        this.refreshCustomHolidays();
    }

    /**
     * UTILS
     */

    // Handle table sort
    handleRequestSort( event, property ) {

        // Check if clicked on same property
        if( this.state.orderBy === property ) {

            // User clicked on same property
            // If already sorted in ascending order, switch to descending order
            if( this.state.order === 'asc' ) {
                this.setState({ order: 'desc', orderBy: property });
            } else {

                // If already sorted in descending order, switch to no order
                this.setState({ order: 'asc', orderBy: 0 });
            }

        } else {
            // User clicked on new property, sort new property in ascending order
            this.setState({ order: 'asc', orderBy: property });
        }
    };

    // Sort the given table data
    sortTableData( customHolidays ) {
        // Handle case where no ordering is selected
        if( this.state.orderBy === 0 ) {
            return customHolidays.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
        }
        return stableSort( customHolidays, getComparator(this.state.order, this.state.orderBy) );
    }

    /**
     * GENERAL RENDER FUNCTION
     */

    render() {
        const { classes } = this.props;

        // Ensure that we use the holiday data, since data might get filtered afterwards
        let customHolidays = this.state.customHolidays;
        let holidays = this.props.holidays;

        if( null != this.props.getCustomHolidays ) {
            customHolidays = this.props.getCustomHolidays();
        }

        // Create a clone of the custom holidays to work on
        let cloneCustomHolidays = [];
        for( let i = 0; i < customHolidays.length; i++ ) {
            cloneCustomHolidays.push( _.cloneDeep( customHolidays[i] ) );
        }

        // Ensure that we use the holiday data, since data might get filtered afterwards
        let contentTable = "";

        if( null != cloneCustomHolidays ) {
            // Assemble table content
            const tableHeader = [
                { key: "name", span: 1, align: "left", sortable: true, style: {}, text: i18n.t("pages.admin.holidays.table_custom.header.name") },
                { key: "date", span: 1, align: "left", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.holidays.table_custom.header.date") },
                { key: "year_start", span: 1, align: "left", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.holidays.table_custom.header.year_start") },
                { key: "year_end", span: 1, align: "left", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.holidays.table_custom.header.year_end") },
                { key: "controls", span: 2, align: "center", sortable: false, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.holidays.table_custom.header.controls") }
            ];

            // Sort table entries by given properties
            cloneCustomHolidays = this.sortTableData( cloneCustomHolidays );

            // Add the year in front of the date on each custom holiday, since most classes cannot work with just mm-dd on a date
            let cloneListCustomHolidays = [];
            for( let i = 0; i < cloneCustomHolidays.length; i++ ) {
                cloneListCustomHolidays.push( _.cloneDeep( cloneCustomHolidays[i] ) );
            }
            for( let i = 0; i < cloneListCustomHolidays.length; i++ ) {
                cloneListCustomHolidays[i].date = cloneListCustomHolidays[i].year_start + "-" + cloneListCustomHolidays[i].date;
            }

            contentTable = (
                <div>
                    <TableContainer style={{ minHeight: "406px" }} component={Paper}>
                        <StyledTable size="small" >
                            <StyledTableHead>
                                <StyledTableRow key={"calendar-list-header"}>
                                    {tableHeader.map((col) => (
                                        <StyledTableCell key={col.key} colSpan={col.span} align={col.align} style={col.style}>
                                            <StyledTableSortLabel
                                                active={this.state.orderBy === col.key}
                                                hideSortIcon={false}
                                                direction={this.state.orderBy === col.key ? this.state.order : 'asc'}
                                                onClick={(event) => this.handleRequestSort(event, col.key)}
                                                disabled={!(col.sortable)}
                                            >
                                                {col.text}
                                            </StyledTableSortLabel>    
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </StyledTableHead>
                            <TableBody>
                                {cloneListCustomHolidays.slice(this.state.tablePage * this.state.tableRowsPerPage, this.state.tablePage * this.state.tableRowsPerPage + this.state.tableRowsPerPage).map((customHoliday,index) => {
                                    // Create a combined list of all holidays to display them in the dialog as proper blocker
                                    // only add the custom holidays which are really active in the currently selected year
                                    let allHolidays = [];
                                    for( let i = 0; i < cloneCustomHolidays.length; i++ ) {
                                        if( cloneCustomHolidays[i].year_start <= customHoliday.year_start ) {
                                            if( cloneCustomHolidays[i].year_end == null || cloneCustomHolidays[i].year_end >= customHoliday.year_start ) {
                                                let tmp = _.cloneDeep( cloneCustomHolidays[i] );
                                                tmp.date = tmp.date.slice(5);
                                                tmp.date = customHoliday.year_start + "-" + tmp.date;
                                                allHolidays.push( tmp );
                                            }
                                        }
                                    }
                                    for( let i = 0; i < holidays.length; i++ ) {
                                        allHolidays.push( holidays[i] );
                                    }
                                    
                                    return (
                                        <StyledTableRow key={"customholiday-"+customHoliday.name+"-"+customHoliday.date+"-"+customHoliday.year_start} date={customHoliday.date}>
                                            <StyledTableCell className={classes.tableCellName} key="name" width="40%">
                                                <CustomTextField
                                                    maxLength={config.maxTextLengths.holiday.name}
                                                    textFieldValue={customHoliday.name} 
                                                    verifyContent={(name) => this.verifyNameFieldContent(name)} 
                                                    onApply={(event, newValue) => this.handleNameFieldChange(event, customHoliday.date, customHoliday.name, customHoliday.year_start, customHoliday.year_end, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.tableCellDate} key="date" width="40%" style={{ borderStyle: "solid", borderLeftWidth: 1 }}>
                                                <CustomDatePicker 
                                                    dateValue={customHoliday.date} 
                                                    verifyContent={(date) => this.verifyDateFieldContent(date)}
                                                    blockedDates={(allHolidays)}
                                                    allowedDate={customHoliday.date}
                                                    onApply={(event, newDate) => this.handleDateFieldChange(event, customHoliday.date, customHoliday.name, customHoliday.year_start, customHoliday.year_end, newDate)}
                                                    language={this.context.language}
                                                    title={i18n.t("pages.admin.holidays.modify_custom.dialog.title")}
                                                    descText={i18n.t("pages.admin.holidays.modify_custom.dialog.description")}
                                                    buttonLeft={i18n.t("pages.admin.holidays.modify_custom.dialog.button_left")}
                                                    buttonRight={i18n.t("pages.admin.holidays.modify_custom.dialog.button_right")}
                                                    disableToolbar={true}
                                                    noYear={true}
                                                    limitToCurrentYear={true}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.tableCellName} key="year_start" width="40%">
                                                <CustomTextField
                                                    numbersOnly={true}
                                                    minValue={0}
                                                    maxValue={2100}
                                                    textFieldValue={customHoliday.year_start} 
                                                    verifyContent={(yearStart) => this.verifyYearStartFieldContent(yearStart, customHoliday.year_end)} 
                                                    onApply={(event, newValue) => this.handleYearStartFieldChange(event, customHoliday.date, customHoliday.name, customHoliday.year_start, customHoliday.year_end, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.tableCellName} key="year_end" width="40%">
                                                <CustomTextField
                                                    numbersOnly={true}
                                                    minValue={0}
                                                    maxValue={2100}
                                                    textFieldValue={customHoliday.year_end}
                                                    verifyContent={(yearEnd) => this.verifyYearEndFieldContent(yearEnd, customHoliday.year_start)} 
                                                    onApply={(event, newValue) => this.handleYearEndFieldChange(event, customHoliday.date, customHoliday.name, customHoliday.year_start, customHoliday.year_end, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="delete" align="center" padding="checkbox" width="20%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <Tooltip title={i18n.t("pages.admin.holidays.table_custom.tooltips.delete")}>
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.clearButton}
                                                            onClick={(event) => this.handleTableRowDeleteClick(event, customHoliday.date, customHoliday.name, customHoliday.year_start, customHoliday.year_end)}
                                                            style={{ height: "30px", width: "45px" }}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                    <Grid container direction="row" justify="flex-end" className={classes.tablePaginationGrid} spacing={1} wrap='nowrap'>
                        <Grid item>
                            <TablePagination
                                className={classes.tablePagination}
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={cloneListCustomHolidays.length}
                                rowsPerPage={this.state.tableRowsPerPage}
                                labelRowsPerPage={i18n.t("table.pagination.rows_per_page")}
                                page={this.state.tablePage}
                                onChangePage={this.handleChangeTablePage}
                                onChangeRowsPerPage={this.handleChangeTableRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                    <ModalDialog 
                        open={this.state.deleteDialog.open}
                        title={i18n.t("pages.admin.holidays.delete.dialog.title")}
                        descText={i18n.t("pages.admin.holidays.delete.dialog.description", { name: this.state.deleteDialog.name })}
                        buttonLeft={i18n.t("pages.admin.holidays.delete.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.holidays.delete.dialog.button_right")}
                        handleClickClose={() => this.handleTableRowDeleteDialog_Cancel()}
                        handleClickLeft={() => this.handleTableRowDeleteDialog_Ok( this.state.deleteDialog.date, this.state.deleteDialog.name, this.state.deleteDialog.yearStart, this.state.deleteDialog.yearEnd )}
                        handleClickRight={() => this.handleTableRowDeleteDialog_Cancel()}
                    />
                </div>
            )
        }

        return (
            <div>
                {contentTable}
            </div>
        );
    }
};

export default withStyles(useStyles)(HolidaysCustom);