import React, { Component } from 'react';
import AuthContext from '../contexts/AuthContext';
import { withStyles } from '@material-ui/core/styles';
import { config } from '../config';
import { StyledSortableDataTable } from '../components/StyledTable.js'
import PageTitle from '../components/PageTitle';
import ChangeLog from '../components/ChangeLog';
import { changelog } from '../changelog';
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * About Page
 */
class AboutPage extends Component {
    static contextType = AuthContext;

    state = {
        order: "asc",
        orderBy: 0 // No order, leave as added
    };

    constructor(props) {
        super(props);
        this.handleRequestSort = this.handleRequestSort.bind( this );
        this.addTableData = this.addTableData.bind( this );
    }

    // Handle table sort
    handleRequestSort( event, property ) {

        // Check if clicked on same property
        if( this.state.orderBy === property ) {

            // User clicked on same property
            // If already sorted in ascending order, switch to descending order
            if( this.state.order === 'asc' ) {
                this.setState({ order: 'desc', orderBy: property });
            } else {

                // If already sorted in descending order, switch to no order
                this.setState({ order: 'asc', orderBy: 0 });
            }

        } else {
            // User clicked on new property, sort new property in ascending order
            this.setState({ order: 'asc', orderBy: property });
        }
    };

    // Data loaded changed - push to parent
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Alert changed - push to parent
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Called when the component has been mounted
    componentDidMount() {
        // No data to load
        this.handleDataLoadedChanged( true );
        this.handleAlertChanged({ severity: "info", message: "" });
    }

    // Add to table data array
    addTableData( name, value ) {
        return { name, value };
    }

    // React render
    render() {

        // Assemble main info table
        let contentTable = null;
        {
            const tableHeader = [
                { id: "name", width: "40%", name: i18n.t("pages.about.table.header.parameter") },
                { id: "value", width: "60%", name: i18n.t("pages.about.table.header.value") }
            ]
            const tableData = [
                this.addTableData( i18n.t("pages.about.table.parameter.server"), window.location.hostname + ":" + window.location.port ),
                this.addTableData( i18n.t("pages.about.table.parameter.frontend_buildinfo"), config.buildInfo.version + ";" + config.buildInfo.timestamp + ";" + config.buildInfo.type + ";" + config.buildInfo.tag + ";" + config.buildInfo.cfg ),
                this.addTableData( i18n.t("pages.about.table.parameter.frontend_language"), this.context.language ),
                this.addTableData( i18n.t("pages.about.table.parameter.backend_version"), config.apiUrl ),
                this.addTableData( i18n.t("pages.about.table.parameter.userid"), this.context.userid ),
                this.addTableData( i18n.t("pages.about.table.parameter.current_timestamp"), new Date(Math.floor(Date.now() / 1000)*1000).toUTCString() ),
                this.addTableData( i18n.t("pages.about.table.parameter.access_expiration"), this.context.accessTokenExpiresIn ),
                this.addTableData( i18n.t("pages.about.table.parameter.refresh_expiration"), this.context.refreshTokenExpiresIn )
            ]
            contentTable = (
                <StyledSortableDataTable 
                    tableKey="about" 
                    tableHeader={tableHeader} 
                    tableData={tableData}
                    order={this.state.order}
                    orderBy={this.state.orderBy}
                    onRequestSort={this.handleRequestSort}
                />
            )
        }

        // Assemble backend config table
        let configTable = null;
        if( null !== this.context.config ) {
            const tableHeader = [
                { id: "name", width: "40%", name: i18n.t("pages.about.table.header.parameter") },
                { id: "value", width: "60%", name: i18n.t("pages.about.table.header.value") }
            ]
            const tableData = [
                this.addTableData( i18n.t("pages.about.table.parameter.accounting.auto_lock"), this.context.config.accounting.auto_lock ? i18n.t("values.yes") : i18n.t("values.no") ),
                this.addTableData( i18n.t("pages.about.table.parameter.accounting.auto_lock_period_in_days"), this.context.config.accounting.auto_lock_period_in_days ),
                this.addTableData( i18n.t("pages.about.table.parameter.accounting.max_hours_per_day"), this.context.config.accounting.max_hours_per_day ),
                this.addTableData( i18n.t("pages.about.table.parameter.accounting.hours_precision"), this.context.config.accounting.hours_precision )
            ]
            configTable = (
                <StyledSortableDataTable 
                    tableKey="about" 
                    tableHeader={tableHeader} 
                    tableData={tableData}
                    order={this.state.order}
                    orderBy={this.state.orderBy}
                    onRequestSort={this.handleRequestSort}
                />
            )
        }

        // Assemble change log
        let contentChangeLog = (
            <ChangeLog
                changelog={changelog}
            />
        )
        
        return (
            <React.Fragment>
                <PageTitle title={i18n.t("pages.about.title")} />
                <p>{i18n.t("pages.about.desc")}</p>
                {contentTable}
                <br />
                {configTable}
                <br />
                {contentChangeLog}
            </React.Fragment>
        );
    }
}

export default withStyles(useStyles)(AboutPage);