import ApiService from './ApiService';
import i18n from 'i18next';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 */
class ContractService {

    /**
     * Get teams
     * @param {*} contractSearch { contractid, date_start, date_end, userid }
     */
    async getContracts( contractSearch ) {
        var request = {
            query: `
            query getContracts($contractSearch: ContractSearch!) {
                getContracts(contractSearch: $contractSearch) {
                    contractid
                    work_hours_per_week
                    work_days_per_week
                    vacation_days_per_year
                    date_start
                    date_end
                    userid
                    vacation_days_at_start
                    work_hours_at_start
                    comment
                }
            }
            `,
            variables: {
                contractSearch: contractSearch
            }
        };

        return await ApiService.doPost( request );
    }


    /**
     * Create a new contract
     * @param {*} contractInput { work_hours_per_week, work_days_per_week, vacation_days_per_year, date_start, date_end, userid, vacation_days_at_start, work_hours_at_start, comment }
     */
    async createContract( contractInput ) {
        var request = {
            query: `
            mutation createContract($contractInput: ContractInput) {
                createContract(contractInput: $contractInput) {
                    contractid
                    work_hours_per_week
                    work_days_per_week
                    vacation_days_per_year
                    date_start
                    date_end
                    userid
                    vacation_days_at_start
                    work_hours_at_start
                    comment
                }
            }
            `,
            variables: {
                contractInput: contractInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update a contract
     * @param {*} contractSearch { contractid, date_start, date_end, userid }
     * @param {*} contractUpdate { work_hours_per_week, work_days_per_week, vacation_days_per_year, date_start, date_end, userid, vacation_days_at_start, work_hours_at_start, comment }
     */
    async updateContract( contractSearch, contractUpdate ) {
        var request = {
            query: `
            mutation updateContract($contractSearch: ContractSearch!, $contractUpdate: ContractUpdate!) {
                updateContract(contractSearch: $contractSearch, contractUpdate: $contractUpdate) {
                    contractid
                    work_hours_per_week
                    work_days_per_week
                    vacation_days_per_year
                    date_start
                    date_end
                    userid
                    vacation_days_at_start
                    work_hours_at_start
                    comment
                }
            }
            `,
            variables: {
                contractSearch: contractSearch,
                contractUpdate: contractUpdate
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete a contract
     * @param {*} contractSearch { contractid, date_start, date_end, userid }
     */
    async deleteContract( contractSearch ) {
        var request = {
            query: `
            mutation deleteContract($contractSearch: ContractSearch!) {
                deleteContract(contractSearch: $contractSearch) {
                    result
                }
            }
            `,
            variables: {
                contractSearch: contractSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Convert the given bit mask of non working days to a human readable representation
     * @param {*} work_days_per_week 
     */
    convertNonWorkingDaysToString( work_days_per_week ) {

        if( null == work_days_per_week ) {
            return i18n.t("errors.conversion");
        }

        // Check string is at least 7 characters long
        if( work_days_per_week.length !== 7 ) {
            return i18n.t("errors.conversion");
        }

        // Count amount of work days
        let amountOfWorkDays = 0;
        let readableWorkDays = "";

        let weekdays = [
            i18n.t("days.short.monday"),
            i18n.t("days.short.tuesday"),
            i18n.t("days.short.wednesday"),
            i18n.t("days.short.thursday"),
            i18n.t("days.short.friday"),
            i18n.t("days.short.saturday"),
            i18n.t("days.short.sunday")
        ]

        for (let i = 0; i < work_days_per_week.length; i++) {
            if( work_days_per_week[i] === "1" ) {
                amountOfWorkDays++;
                if( readableWorkDays.length > 0 ) {
                    readableWorkDays += ", ";
                }
                readableWorkDays += weekdays[i];
            }
        }

        let result = `${amountOfWorkDays} (${readableWorkDays})`
        return result;
    }
}

export default new ContractService();
