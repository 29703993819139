import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

const useStyles = theme => ({});

const StyledTypography = styled(Typography)`
    font-size: 20px;
    color: #248EDB;
`;

/**
 * Page Title
 */
class PageTitle extends Component {

    // Render
    // Takes props: title, breadcrumbs []
    // If title is separated by "/" it is interpreted as breadcrumbs.
    render() {

        let breadcrumbs = [];
        if( null != this.props.breadcrumbs ) {
            breadcrumbs = this.props.breadcrumbs;
        }

        let titleArray = this.props.title.split( "/" );

        return (
            <Breadcrumbs separator="›" style={{ fontFamily:'inherit', fontSize:'20px', color:'#248EDB', marginBottom:'10px'}}>
                {breadcrumbs.map((crumb, i) => (
                    <StyledTypography key={i} align="left">
                        {crumb}
                    </StyledTypography>
                ))}
                {titleArray.map((crumb, i) => (
                    <StyledTypography key={i+breadcrumbs.length} align="left">
                        {crumb}
                    </StyledTypography>
                ))}
            </Breadcrumbs>
        );
    }
};

export default withStyles(useStyles)(PageTitle);