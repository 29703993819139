import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import RefreshButton from './RefreshButton'
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CalendarHelper from '../classes/CalendarHelper.js';

const useStyles = theme => ({
    mainGrid: {
        display: 'flex',
        height: '60px',
        width: '100%',
        margin: theme.spacing(0),
        justifyContent: 'space-between',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    arrowButton: {
        minHeight: '35px',
        minWidth: '25px',
        maxHeight: '35px',
        maxWidth: '25px'
    },
    arrowIcon: {
        height: '25px',
        width: '25px'
    },
    yearButton: {
        minHeight: '35px',
        minWidth: '55px',
        maxHeight: '35px',
        maxWidth: '55px'
    },
    monthButton: {
        minHeight: '35px',
        minWidth: '45px',
        maxHeight: '35px',
        maxWidth: '45px'
    },
    todayButton: {
        minHeight: '35px',
        maxHeight: '35px'
    }
});

/**
 * Select year and month field with associated refresh button
 */
class SelectYearMonth extends Component {
    state = {
        currentMonth: null,
        currentYear: null,
        months: []
    }

    constructor(props) {
        super(props);

        this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind( this );
        this.handleTodayButtonClick = this.handleTodayButtonClick.bind( this );
        this.handleButtonYearClick = this.handleButtonYearClick.bind( this );
        this.handleButtonMonthClick = this.handleButtonMonthClick.bind( this );
        this.renderYearControlElements = this.renderYearControlElements.bind( this );
        this.renderMonthControlElements = this.renderMonthControlElements.bind( this );
        this.changeYearAndMonth = this.changeYearAndMonth.bind( this );

        // Populate array of month names
        this.state.months = CalendarHelper.getMonthNames( this.props.language, "short" );

        // Set selected month and year if given else select the current year and month
        if( null != this.props.month ) {
            this.state.currentMonth = this.props.month;
        } else {
            this.state.currentMonth = new Date().getMonth();
        }
        if( null != this.props.year ) {
            this.state.currentYear = this.props.year;
        } else {
            this.state.currentYear = new Date().getFullYear();
        }
    }

    /**
     * HELPERS
     */
    
    // Change year and month
    changeYearAndMonth( year, month ) {
        let finalMonth = month;
        let finalYear = year;

        if( month < 0 ) {
            finalMonth = 11;
            finalYear = finalYear-1;
        } else if( month > 11 ) {
            finalMonth = 0;
            finalYear = finalYear+1;
        }

        if( null != this.props.onYearMonthChange ) {
            this.props.onYearMonthChange( finalYear, finalMonth );
        }
        this.setState({ currentMonth: finalMonth, currentYear: finalYear });
    }

    /**
     * BUTTON HANDLERS
     */

    // Called on refresh button click
    handleRefreshButtonClick( event ) {
        event.stopPropagation();
        if( null != this.props.onRefresh) {
            this.props.onRefresh();
        }
    }

    // Called on today button click
    handleTodayButtonClick( event ) {
        event.stopPropagation();
        
        let todayMonth = new Date().getMonth();
        let todayYear = new Date().getFullYear();
        this.changeYearAndMonth( todayYear, todayMonth );
    }

    // Any year button clicked
    handleButtonYearClick( event, year ) {
        event.stopPropagation();
        this.changeYearAndMonth( year, this.state.currentMonth );
    }

    // Any month button clicked
    handleButtonMonthClick( event, month ) {
        event.stopPropagation();
        this.changeYearAndMonth( this.state.currentYear, month );
    }

    /**
     * RENDER FUNCTIONS
     */

    // Render year control
    renderYearControlElements( currentYear, maxYear ) {
        // Determine years to display
        const prevYear = currentYear-1;
        const nextYear = currentYear+1;

        let nextYearButtonDisabled = false;
        if ( nextYear > maxYear ) {
            nextYearButtonDisabled = true;
        }

        return ( 
            <Grid item container direction="row" justify="flex-start" alignItems="flex-start" spacing={0}>
                <Grid item>
                    <Button key="year-left" size="small" className={this.props.classes.arrowButton} variant="contained" onClick={(event) => this.handleButtonYearClick(event, currentYear-1)} autoFocus={false}>
                        <KeyboardArrowLeftIcon className={this.props.classes.arrowIcon} />
                    </Button>
                </Grid>
                <Grid item>
                    <Button key="year-prev" className={this.props.classes.yearButton} variant="contained" onClick={(event) => this.handleButtonYearClick(event, prevYear)} autoFocus={false}>
                        {prevYear}
                    </Button>
                </Grid>
                <Grid item>
                    <Button key="year-current" className={this.props.classes.yearButton} variant="contained" color="primary" onClick={(event) => this.handleButtonYearClick(event, currentYear)} autoFocus={false}>
                        {currentYear}
                    </Button>
                </Grid>
                <Grid item>
                    <Button key="year-next" className={this.props.classes.yearButton} variant="contained" disabled={nextYearButtonDisabled} onClick={(event) => this.handleButtonYearClick(event, nextYear)} autoFocus={false}>
                        {nextYear}
                    </Button>
                </Grid>
                <Grid item>
                    <Button key="year-right" size="small" className={this.props.classes.arrowButton} variant="contained" disabled={nextYearButtonDisabled} onClick={(event) => this.handleButtonYearClick(event, currentYear+1)} autoFocus={false}>
                        <KeyboardArrowRightIcon className={this.props.classes.arrowIcon} />
                    </Button>
                </Grid>
            </Grid>
        );
    }

    // Render month control
    renderMonthControlElements( currentMonth, currentYear, maxYear ) {
        // Prepare array of months to render
        let monthProps = [];
        for( let i = 0; i < this.state.months.length; i++ ) {
            const name = this.state.months[i];
            let color = null;
            if( i === currentMonth ) {
                color = "primary";
            }
            let clickTargetMonth = i;

            monthProps.push( { name: name, color: color, clickTargetMonth: clickTargetMonth } );
        }

        const nextYear = currentYear+1;
        let nextYearButtonDisabled = false;
        if ( nextYear > maxYear && currentMonth === 11 ) {
            nextYearButtonDisabled = true;
        }
        
        return ( 
            <Grid item container direction="row" justify="flex-start" alignItems="flex-start" spacing={0}>
                <Grid item>
                    <Button key="month-left" size="small" className={this.props.classes.arrowButton} variant="contained" onClick={(event) => this.handleButtonMonthClick(event, currentMonth-1)} autoFocus={false}>
                        <KeyboardArrowLeftIcon className={this.props.classes.arrowIcon} />
                    </Button>
                </Grid>
                {monthProps.map((month) => {
                    let color = month.color;
                    if( null === color ) {
                        color = "default";
                    }
                    return (
                        <Grid item key={"grid-item-button-month-control"+month.clickTargetMonth} >
                            <Button
                                key={"button-month-control-"+month.clickTargetMonth}
                                className={this.props.classes.monthButton} 
                                variant="contained"
                                color={color}
                                onClick={(event) => this.handleButtonMonthClick(event, month.clickTargetMonth)} 
                                autoFocus={false}
                            >
                                {month.name}
                            </Button>
                        </Grid>
                    )
                })}
                <Grid item>
                    <Button key="month-right" size="small" className={this.props.classes.arrowButton} variant="contained" disabled={nextYearButtonDisabled} onClick={(event) => this.handleButtonMonthClick(event, currentMonth+1)} autoFocus={false}>
                        <KeyboardArrowRightIcon className={this.props.classes.arrowIcon} />
                    </Button>
                </Grid>
            </Grid>
        );
    }

    // Main render function
    render() {
        const { classes } = this.props;

        let refreshTooltip = "";
        if( null != this.props.refreshTooltip ) {
            refreshTooltip = this.props.refreshTooltip;
        }

        let todayTooltip = "";
        if( null != this.props.todayTooltip ) {
            todayTooltip = this.props.todayTooltip;
        }

        let showTodayButton = true;
        if( null != this.props.showTodayButton ) {
            showTodayButton = this.props.showTodayButton;
        }

        let showRefreshButton = true;
        if( null != this.props.showRefreshButton ) {
            showRefreshButton = this.props.showRefreshButton;
        }

        let maxYear = this.state.currentYear+1;
        if( null != this.props.maxYear ) {
            maxYear = this.props.maxYear;
        }

        // Read the variant and render accordingly
        let variant = "year-month";
        if( null != this.props.variant ) {
            variant = this.props.variant;
        }

        const yearControlElements = this.renderYearControlElements( this.state.currentYear, maxYear );
        const monthControlElements = this.renderMonthControlElements( this.state.currentMonth, this.state.currentYear, maxYear );

        return (
            <Grid container direction="row" className={classes.mainGrid} justify="flex-start" alignItems="flex-start" spacing={0} wrap='nowrap'>
                <Grid item container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                    {(showTodayButton) && (
                        <Grid item>
                            <Tooltip title={todayTooltip}>
                                <Button 
                                    key="today" size="small" 
                                    className={this.props.classes.todayButton} 
                                    variant="contained" 
                                    onClick={(event) => this.handleTodayButtonClick(event)} 
                                    autoFocus={false}
                                >
                                    {this.props.labelToday}
                                </Button>
                            </Tooltip>
                        </Grid>
                    )}
                    <Grid item>
                        {yearControlElements}
                    </Grid>
                    {(variant === "year-month") && (
                        <Grid item>
                            {monthControlElements}
                        </Grid>
                    )}
                </Grid>
                <Grid item container direction="row" justify="flex-end" spacing={1} style={{ width: "60px"}}>
                    <Grid item >
                        {(showRefreshButton) && (
                            <RefreshButton 
                                title={refreshTooltip}
                                onClick={(event) => this.handleRefreshButtonClick(event)}
                                disabled={this.props.disableRefreshButton}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
};

export default withStyles(useStyles)(SelectYearMonth);