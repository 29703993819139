import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AdminAdjustments from './admin/AdminAdjustments';
import AdminUsers from './admin/AdminUsers';
import AdminTeams from './admin/AdminTeams';
import AdminProjects from './admin/AdminProjects';
import AdminTasks from './admin/AdminTasks';
import AdminRoles from './admin/AdminRoles';
import AdminHolidays from './admin/AdminHolidays';
import AdminSpecials from './admin/AdminSpecials';
import AdminContracts from './admin/AdminContracts';
import AdminUserTypes from './admin/AdminUserTypes';
import AdminNoAccess from './admin/AdminNoAccess';
import AuthContext from '../contexts/AuthContext';

const useStyles = theme => ({});

/**
 * Admin Page
 */
class AdminPage extends Component {
    static contextType = AuthContext;

    state = {
        adminFlagsLoaded: false,
        adminFlags: {
            global: false,
            users: false,
            roles: false,
            teams: false,
            projects: false,
            tasks: false,
            holidays: false,
            specials: false,
            contracts: false,
            accountings: false
        }
    }

    constructor(props) {
        super(props);
        this.handleSubpageAlertChanged = this.handleSubpageAlertChanged.bind( this );
        this.handleSubpageDataLoadedChanged = this.handleSubpageDataLoadedChanged.bind( this );
        this.handleSubpageActionInProgressChanged = this.handleSubpageActionInProgressChanged.bind( this );
    }

    /**
     * OWN HANDLERS
     */

    // Data loaded flag changed
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Alert changed
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    /**
     * CALLS FROM SUBPAGES
     */

    // Called by subpage when their data load changed
    handleSubpageDataLoadedChanged( dataLoaded ) {
        this.handleDataLoadedChanged( dataLoaded );
    }

    // Called by subpage when their alert changed
    handleSubpageAlertChanged( alert ) {
        this.handleAlertChanged( alert );
    }

    // Called by subpage when their action in progress changed
    handleSubpageActionInProgressChanged( actionInProgress ) {
        if( this.props.actionInProgressChanged ) {
            this.props.actionInProgressChanged( actionInProgress );
        }
    }

    /**
     * REACT CALLS
     */

    // Will mount after login
    async componentDidMount() {

        // Get the admin data for the users
        let adminFlags = this.state.adminFlags;

        const { statusCode, statusText, userAdminData } = await this.context.services.admin.getAdminData( this.context.userid );
        if( 200 === statusCode && null != userAdminData ) {
            
            // Gather which items to show from data
            if( true === userAdminData.admin.global ) {
                adminFlags = {
                    global: true,
                    users: true,
                    roles: true,
                    teams: true,
                    projects: true,
                    tasks: true,
                    holidays: true,
                    specials: true,
                    contracts: true,
                    accountings: true
                }
            } else {

                adminFlags.users = userAdminData.admin.users;
                adminFlags.roles = userAdminData.admin.roles;
                adminFlags.teams = userAdminData.admin.teams;
                adminFlags.projects = userAdminData.admin.projects;
                adminFlags.tasks = userAdminData.admin.tasks;
                adminFlags.holidays = userAdminData.admin.holidays;
                adminFlags.specials = userAdminData.admin.specials;
                adminFlags.contracts = userAdminData.admin.contracts;
                adminFlags.accountings = userAdminData.admin.accountings;

                // If any of these is true, we also need to show the header item
                adminFlags.header = adminFlags.users || adminFlags.roles || adminFlags.teams || adminFlags.tasks
                    || adminFlags.projects || adminFlags.holidays || adminFlags.specials || adminFlags.contracts || adminFlags.accountings;
            }

            // Clear error
            this.handleAlertChanged( { severity: "info", message: "" } );

        } else {
            // Error occured
            this.handleAlertChanged( { severity: "error", message: `${statusText}: ${statusCode}` } );
        }

        // Set the new state of the component
        this.setState({ adminFlagsLoaded: true, adminFlags: adminFlags });
    }

    // When unmounted
    componentWillUnmount() {

        // Clear all status data
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
        this.setState({
            adminFlagsLoaded: false,
            adminFlags: {
                global: false,
                users: false,
                roles: false,
                teams: false,
                projects: false,
                tasks: false,
                holidays: false,
                specials: false,
                contracts: false,
                accountings: false
            }
        });
    }

    // Render the component
    render() {
        // Display the proper subpage based on the arguments
        // Args might contain additional information depending on subpage
        // in the format ?<subpage>&<additionalstuff>
        let args = this.props.args;
        let subpageArgs = "";
        if( -1 !== this.props.args.indexOf( "&" ) ) {
            // We have subpage arguments
            args = this.props.args.substring( 0, this.props.args.indexOf("&") );
            subpageArgs = this.props.args.substring( this.props.args.indexOf("&") );
        }
        
        let contentSubpage = "";

        if( true === this.state.adminFlagsLoaded ) {
            
            // Only display the specific subpage if access is really granted
            if( args === "?accountings" && true === this.state.adminFlags.accountings ) { contentSubpage = ( <AdminAdjustments args={subpageArgs} userid={this.context.userid} adminFlags={this.state.adminFlags} dataLoadedChanged={this.handleSubpageDataLoadedChanged} alertChanged={this.handleSubpageAlertChanged} actionInProgressChanged={this.handleSubpageActionInProgressChanged} /> ) }
            else if( args === "?users" && true === this.state.adminFlags.users ) { contentSubpage = ( <AdminUsers args={subpageArgs} userid={this.context.userid} adminFlags={this.state.adminFlags} dataLoadedChanged={this.handleSubpageDataLoadedChanged} alertChanged={this.handleSubpageAlertChanged} actionInProgressChanged={this.handleSubpageActionInProgressChanged} /> ) }
            else if( args === "?teams" && true === this.state.adminFlags.teams ) { contentSubpage = ( <AdminTeams args={subpageArgs} userid={this.context.userid} adminFlags={this.state.adminFlags} dataLoadedChanged={this.handleSubpageDataLoadedChanged} alertChanged={this.handleSubpageAlertChanged} actionInProgressChanged={this.handleSubpageActionInProgressChanged} /> ) }
            else if( args === "?projects" && true === this.state.adminFlags.projects ) { contentSubpage = ( <AdminProjects args={subpageArgs} userid={this.context.userid} adminFlags={this.state.adminFlags} dataLoadedChanged={this.handleSubpageDataLoadedChanged} alertChanged={this.handleSubpageAlertChanged} actionInProgressChanged={this.handleSubpageActionInProgressChanged} /> ) }
            else if( args === "?tasks" && true === this.state.adminFlags.tasks ) { contentSubpage = ( <AdminTasks args={subpageArgs} userid={this.context.userid} adminFlags={this.state.adminFlags} dataLoadedChanged={this.handleSubpageDataLoadedChanged} alertChanged={this.handleSubpageAlertChanged} actionInProgressChanged={this.handleSubpageActionInProgressChanged} /> ) }
            else if( args === "?roles" && true === this.state.adminFlags.roles ) { contentSubpage = ( <AdminRoles args={subpageArgs} userid={this.context.userid} adminFlags={this.state.adminFlags} dataLoadedChanged={this.handleSubpageDataLoadedChanged} alertChanged={this.handleSubpageAlertChanged} actionInProgressChanged={this.handleSubpageActionInProgressChanged} /> ) }
            else if( args === "?holidays" && true === this.state.adminFlags.holidays ) { contentSubpage = ( <AdminHolidays args={subpageArgs} userid={this.context.userid} adminFlags={this.state.adminFlags} dataLoadedChanged={this.handleSubpageDataLoadedChanged} alertChanged={this.handleSubpageAlertChanged} actionInProgressChanged={this.handleSubpageActionInProgressChanged} /> ) }
            else if( args === "?specials" && true === this.state.adminFlags.specials ) { contentSubpage = ( <AdminSpecials args={subpageArgs} userid={this.context.userid} adminFlags={this.state.adminFlags} dataLoadedChanged={this.handleSubpageDataLoadedChanged} alertChanged={this.handleSubpageAlertChanged} actionInProgressChanged={this.handleSubpageActionInProgressChanged} /> ) }
            else if( args === "?contracts" && true === this.state.adminFlags.contracts ) { contentSubpage = ( <AdminContracts args={subpageArgs} userid={this.context.userid} adminFlags={this.state.adminFlags} dataLoadedChanged={this.handleSubpageDataLoadedChanged} alertChanged={this.handleSubpageAlertChanged} actionInProgressChanged={this.handleSubpageActionInProgressChanged} /> ) }
            else if( args === "?usertypes" && true === this.state.adminFlags.users ) { contentSubpage = ( <AdminUserTypes args={subpageArgs} userid={this.context.userid} adminFlags={this.state.adminFlags} dataLoadedChanged={this.handleSubpageDataLoadedChanged} alertChanged={this.handleSubpageAlertChanged} actionInProgressChanged={this.handleSubpageActionInProgressChanged} /> ) }
            else {
                // No access to any page
                contentSubpage = ( <AdminNoAccess args={subpageArgs} dataLoadedChanged={this.handleSubpageDataLoadedChanged} alertChanged={this.handleSubpageAlertChanged} actionInProgressChanged={this.handleSubpageActionInProgressChanged} /> );
            }
        }

        // Assemble content
        return (
            <React.Fragment>
                {contentSubpage}
            </React.Fragment>
        );
    }
}

export default withStyles(useStyles)(AdminPage);