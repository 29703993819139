import React, { Component } from 'react';
import TeamService from '../../classes/TeamService'
import UsersToTeamsService from '../../classes/UsersToTeamsService'
import UserTypeService from '../../classes/UserTypeService'
import UserService from '../../classes/UserService'
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { StyledTableCell, StyledTableRow, StyledTableHead, StyledTable, StyledTableSortLabel, stableSort, getComparator } from '../../components/StyledTable.js'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../components/PageTitle';
import PageSubtitle from '../../components/PageSubtitle'
import PageDescription from '../../components/PageDescription'
import ModalDialog from '../../components/ModalDialog';
import ModalDialogTextField from '../../components/ModalDialogTextField';
import CustomChipInput from '../../components/CustomChipInput';
import CustomTextField from '../../components/CustomTextField'
import SearchControls from '../../components/SearchControls'
import { StyledChipEnabled, StyledChipDisabled } from '../../components/StyledChip'
import Paper from '@material-ui/core/Paper';
import { config } from '../../config';
import i18n from 'i18next';

const useStyles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    mainGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    tableCellTeamName: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1.0),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
    },
    tablePaginationGrid: {
        height: '60px',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tablePagination: {
        '& .MuiTablePagination-toolbar': {
            height: '60px',
            minHeight: '60px',
        },
    },
    chipInput: {
        width: '100%',
        height: '100%'
    },
});

/**
 * Admin Teams
 */
class AdminTeams extends Component {
    // Set in constructor
    state = {}

    /**
     * Inputs Props
     * @param {*} props 
     *  admin (array)
     *  userProps (array)
     *  dataLoadChanged (function)
     *  alertChanged (function)
     */
    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleTableRowDeleteClick = this.handleTableRowDeleteClick.bind( this );
        this.handleChangeTablePage = this.handleChangeTablePage.bind( this );
        this.handleChangeTableRowsPerPage = this.handleChangeTableRowsPerPage.bind( this );
        this.handleChangeSearchField = this.handleChangeSearchField.bind( this );
        this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind( this );
        this.handleAddButtonClick = this.handleAddButtonClick.bind( this );
        this.handleAddDialog_Ok = this.handleAddDialog_Ok.bind( this );
        this.handleAddDialog_Cancel = this.handleAddDialog_Cancel.bind( this );
        this.verifyAddDialog_Content = this.verifyAddDialog_Content.bind( this );
        this.handleTableRowDeleteDialog_Ok = this.handleTableRowDeleteDialog_Ok.bind( this );
        this.handleTableRowDeleteDialog_Cancel = this.handleTableRowDeleteDialog_Cancel.bind( this );
        this.handleModifyTeamMembers = this.handleModifyTeamMembers.bind( this );
        this.handleModifyTeamLeads = this.handleModifyTeamLeads.bind( this );
        this.getTeamLeadsForTeam = this.getTeamLeadsForTeam.bind( this );
        this.getUsersForTeam = this.getUsersForTeam.bind( this );
        this.getUsersWithoutTeam = this.getUsersWithoutTeam.bind( this );
        this.sortUserData = this.sortUserData.bind( this );
        this.getDeleteButtonDataForTeam = this.getDeleteButtonDataForTeam.bind( this );
        this.handleTeamNameFieldChange = this.handleTeamNameFieldChange.bind( this );
        this.verifyTeamNameFieldContent = this.verifyTeamNameFieldContent.bind( this );
        this.getUserLabel = this.getUserLabel.bind( this );
        this.getUserEnabled = this.getUserEnabled.bind( this );
        this.handleRequestSort = this.handleRequestSort.bind( this );
        this.sortTableData = this.sortTableData.bind( this );

        // Set initial state
        this.state = this.getInitialState();
    }

    /**
     * STATE HANDLING
     */

    // Get the initial state variables of this component
    getInitialState() {
        return {
            data: { // Raw loaded data from the backend
                teams: null,
                users: null,
                userTypes: null,
                usersToTeams: null
            },
            tableData: { // Additional information gathered from loaded raw data to display on the page
                usersForTeam: [], // The users of that team
                deleteButtonsForTeam: [] // If the delete button is enabled and which tooltip is shown for that team
            },
            searchFieldEntry: "",
            tablePage: 0,
            tableRowsPerPage: 10,
            order: "asc",
            orderBy: 0,
            addDialog: {
                open: false
            },
            deleteDialog: {
                open: false,
                teamid: null,
                teamname: null
            },
        }
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState() {
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
        this.setState( this.getInitialState() );
    }

    /**
     * LINK TO PARENT COMPONENT FUNCTIONS
     */

    // Propagate information to parent component
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Propagate information to parent component
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Propagate information to parent component
    handleActionInProgressChanged( actionInProgress ) {
        if( this.props.actionInProgressChanged ) {
            this.props.actionInProgressChanged( actionInProgress );
        }
    }

    /**
     * LOAD AND PROCESS BACKEND DATA
     */

    // Called when a refresh of the table data is needed due to edits in the table
    async refreshTableData() {
        await this.loadDataFromBackend( true );
    }

    // Gather all data from the backend needed on this page and process them properly
    async loadDataFromBackend( manualRefresh ) {
        // Alert message to show
        let alert = {
            severity: "info",
            message: ""
        }

        let teamData = null;
        let usersToTeamsData = null;
        let userData = null;

        // Gather team data
        {
            const { statusCode, statusText, resData } = await TeamService.getTeams( {} );
            if( 200 === statusCode && null != resData ) {
                teamData = resData.getTeams;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Get user-to-team data
        {
            const { statusCode, statusText, resData } = await UsersToTeamsService.getUsersToTeams( {} );
            if( 200 === statusCode && null != resData ) {
                usersToTeamsData = resData.getUsersToTeams;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather user data
        {
            const { statusCode, statusText, resData } = await UserService.getUsers( {} );
            if( 200 === statusCode && null != resData ) {
                userData = resData.getUsers;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        let userTypeData = null;

        // Gather user type data
        {
            const { statusCode, statusText, resData } = await UserTypeService.getUserTypes( {} );
            if( 200 === statusCode && null != resData ) {
                userTypeData = resData.getUserTypes;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Now process the data
        await this.processData( userData, userTypeData, teamData, usersToTeamsData );

        // All fine, clear the error message (only if not a manual refresh)
        if( false === manualRefresh ) {
            this.handleAlertChanged( alert );
        }
        this.handleDataLoadedChanged( true );
    }

    // Called to make additional processing of data, for example gathering info from that backend data and storing it for later use
    async processData( userData, userTypeData, teamData, usersToTeamsData ) {

        // Abort if no proper data given
        if( null == userData || null == userTypeData || null == teamData || null == usersToTeamsData ) {
            return;
        }

        // Add user type name to all user data entries
        for( let i = 0; i < userData.length; i++ ) {
            let userTypeName = "";
            for( let j = 0; j < userTypeData.length; j++ ) {
                if( userTypeData[j].typeid === userData[i].typeid ) {
                    userTypeName = userTypeData[j].name;
                    break;
                }
            }
            userData[i].userTypeName = userTypeName;
        }

        // Add the information to data.teams if there is currently an user assigned to this team or not
        // for enabling or disabling the delete button, doing this now speeds up table page changes later on
        let usersArray = [];
        let deleteButtonsArray = [];

        for( let i = 0; i < teamData.length; i++ ) {
            let usersFound = [];
            let teamLeadsFound = [];

            for (let j = 0; j < usersToTeamsData.length; j++ ) {
                if( usersToTeamsData[j].teamid === teamData[i].teamid ) {
                    // User at the specified team found
                    // Discover the real name of the user from the user data we retrieved from the backend
                    let user = null;
                    let user_isTeamLead = false;

                        for( let k = 0; k < userData.length; k++ ) {
                            if( userData[k].userid === usersToTeamsData[j].userid ) {
                                user = userData[k];
                                user_isTeamLead = usersToTeamsData[j].is_team_lead;
                                break;
                            }
                        }

                    // User is part of team
                    usersFound.push( user );

                    // User is teamlead of team
                    if( true === user_isTeamLead ) {
                        teamLeadsFound.push( user );
                    }
                }
            }

            // Sort array by lastname
            usersFound.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.userTypeName > b.userTypeName ) return 1;
                if( a.userTypeName < b.userTypeName ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });
            teamLeadsFound.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.userTypeName > b.userTypeName ) return 1;
                if( a.userTypeName < b.userTypeName ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });

            // Add the processed info
            let usersElement = {
                teamid: teamData[i].teamid,
                users: usersFound,
                teamLeads: teamLeadsFound
            }

            let deleteButtonElement = {
                teamid: teamData[i].teamid,
                enabled: false,
                tooltip: i18n.t("pages.admin.teams.delete.button.tooltip.disabled")
            }

            if( usersFound.length === 0 ) {
                deleteButtonElement.enabled = true;
                deleteButtonElement.tooltip = i18n.t("pages.admin.teams.delete.button.tooltip.enabled");
            }

            // Add info to the final arrays
            usersArray[i] = usersElement;
            deleteButtonsArray[i] = deleteButtonElement;
        }

        // Store the data
        this.setState({ 
            data: { users: userData, userTypes: userTypeData, teams: teamData, usersToTeams: usersToTeamsData }, 
            tableData: { usersForTeam: usersArray, deleteButtonsForTeam: deleteButtonsArray } 
        });
    }

    /**
     * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
     */

    // Called by react when this component has been mounted
    async componentDidMount() {
        await this.loadDataFromBackend();
    }

    // Called by react before this component unmounts
    componentWillUnmount() {
        this.resetState();
    }

    /**
     * ALERT DIALOG <ADD>
     */

    // Called to open the add dialog
    openAddDialog() {
        let addDialog = {
            open: true
        }
        this.setState({ addDialog: addDialog });
    }

    // Called when the user clicked "Ok" on the add team dialog
    // content: { name }
    async handleAddDialog_Ok( content ) {
        this.closeAddDialog();

        // Read from content
        let name = null;
        if( null != content ) {
            if( null != content.value ) {
                name = content.value;
            }
        }

        // Add the team
        if( null != name ) {
            let teamValues = {
                name: name,
            }

            const { statusCode, statusText, resData } = await TeamService.createTeam( teamValues );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.teams.add.alert.success", { name: name });
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the add team dialog
    handleAddDialog_Cancel() {
        this.closeAddDialog();
    }

    // Called by the TeamAdd child component to verify its content
    verifyAddDialog_Content( content ) {

        let result = {
            state: false,
            msg: i18n.t("pages.admin.teams.add.dialog.alert.team_name_empty")
        }
        
        if( null != content ) {
            if( null != content.value ) {
                if( content.value !== "" ) {
                    const { exists } = this.doesTeamExist( content.value );
                    if( true === exists ) {
                        result = {
                            state: false,
                            msg: i18n.t("pages.admin.teams.add.dialog.alert.team_does_already_exist")
                        }
                    } else {
                        result = {
                            state: true,
                            msg: ""
                        }
                    }
                }
            }
        }

        return result;
    }

    // Close the add alert dialog
    closeAddDialog() {
        let addDialog = {
            open: false
        }
        this.setState({ addDialog: addDialog });
    }

    /**
     * MODIFY TEAM MEMBERS / TEAM LEADS
     */

    // Called when the user clicked "Apply" in the team leads chip input
    async handleModifyTeamLeads( teamid, content ) {
        // Check data validity
        if( content == null ) {
            return;
        }
        if( content.selectedOptions == null ) {
            return;
        }
        if( content.unselectedOptions == null ) {
            return;
        }

        // Content will include arrays in no specific order
        let usersToTeamLeadCmd = [];

        // Get currently assigned users for team who are team leads
        const teamLeads = this.getTeamLeadsForTeam( teamid );

        // Check if users have been added to the team
        for( let i = 0; i < content.selectedOptions.length; i++ ) {
            var userAlreadyAssignedToTeamAsTeamLead = false;
            for( let j = 0; j < teamLeads.length; j++ ) {
                if( teamLeads[j].userid === content.selectedOptions[i].userid ) {
                    userAlreadyAssignedToTeamAsTeamLead = true;
                    break;
                }
            }

            if( false === userAlreadyAssignedToTeamAsTeamLead ) {
                // User has been added as a team lead
                usersToTeamLeadCmd.push( { userid: content.selectedOptions[i].userid, isTeamLead: true } );
            }
        }

        // Check if users have been removed from the team
        for( let i = 0; i < content.unselectedOptions.length; i++ ) {
            var removeUserFromTeamAsTeamLead = false;
            for( let j = 0; j < teamLeads.length; j++ ) {
                if( teamLeads[j].userid === content.unselectedOptions[i].userid ) {
                    removeUserFromTeamAsTeamLead = true;
                    break;
                }
            }

            if( true === removeUserFromTeamAsTeamLead ) {
                // User has been removed from the team as a team lead
                usersToTeamLeadCmd.push( { userid: content.unselectedOptions[i].userid, isTeamLead: false } );
            }
        }

        // Perform commands on the API
        let combinedResult = {
            statusCode: 200,
            statusText: ""
        }

        if( 0 < usersToTeamLeadCmd.length ) {

            for( let i = 0; i < usersToTeamLeadCmd.length; i++ ) {
                let result = {}

                const { statusCode, statusText, resData } = await UsersToTeamsService.updateUserToTeam( { teamid: teamid, userid: usersToTeamLeadCmd[i].userid }, { is_team_lead: usersToTeamLeadCmd[i].isTeamLead } );
                result = {
                    statusCode: statusCode,
                    statusText: statusText,
                    resData: resData
                }

                if( 200 !== result.statusCode ) {
                    combinedResult.statusCode = result.statusCode;
                    combinedResult.statusText = result.statusText;
                }
            }

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === combinedResult.statusCode ) {
                alert.severity = "success";
                alert.message = i18n.t( "pages.admin.teams.modify.alert.modify_users_success" );
            } else {
                alert.severity = "error";
                alert.message = `${combinedResult.statusText}: ${combinedResult.statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            this.resetState(); // Full reset necessary to also update the other column of chip input fields
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Apply" in the team members chip input
    async handleModifyTeamMembers( teamid, content ) {

        // Check data validity
        if( content == null ) {
            return;
        }
        if( content.selectedOptions == null ) {
            return;
        }
        if( content.unselectedOptions == null ) {
            return;
        }

        // Content will include arrays in no specific order
        let usersToTeamCmd = [];

        // Get currently assigned users for team
        const users = this.getUsersForTeam( teamid );

        // Check if users have been added to the team
        for( let i = 0; i < content.selectedOptions.length; i++ ) {
            let userAlreadyAssignedToTeam = false;
            for( let j = 0; j < users.length; j++ ) {
                if( users[j].userid === content.selectedOptions[i].userid ) {
                    userAlreadyAssignedToTeam = true;
                    break;
                }
            }

            if( false === userAlreadyAssignedToTeam ) {
                // User has been added to the team
                usersToTeamCmd.push( { cmd: "add", userid: content.selectedOptions[i].userid } );
            }
        }

        // Check if users have been removed from the team
        for( let i = 0; i < content.unselectedOptions.length; i++ ) {
            let removeUserFromTeam = false;
            for( let j = 0; j < users.length; j++ ) {
                if( users[j].userid === content.unselectedOptions[i].userid ) {
                    removeUserFromTeam = true;
                    break;
                }
            }

            if( true === removeUserFromTeam ) {
                // User has been removed from the team
                usersToTeamCmd.push( { cmd: "delete", userid: content.unselectedOptions[i].userid } );
            }
        }

        // Perform commands on the API
        let combinedResult = {
            statusCode: 200,
            statusText: ""
        }

        if( 0 < usersToTeamCmd.length ) {

            for( let i = 0; i < usersToTeamCmd.length; i++ ) {
                let result = {}

                if( "add" === usersToTeamCmd[i].cmd ) {
                    const { statusCode, statusText, resData } = await UsersToTeamsService.addUserToTeam( { teamid: teamid, userid: usersToTeamCmd[i].userid, is_team_lead: false } );
                    result = {
                        statusCode: statusCode,
                        statusText: statusText,
                        resData: resData
                    }
                } else {
                    const { statusCode, statusText, resData } = await UsersToTeamsService.deleteUserToTeam( { teamid: teamid, userid: usersToTeamCmd[i].userid } );
                    result = {
                        statusCode: statusCode,
                        statusText: statusText,
                        resData: resData
                    }
                }

                if( 200 !== result.statusCode ) {
                    combinedResult.statusCode = result.statusCode;
                    combinedResult.statusText = result.statusText;
                }
            }

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === combinedResult.statusCode ) {
                alert.severity = "success";
                alert.message = i18n.t( "pages.admin.teams.modify.alert.modify_users_success" );
            } else {
                alert.severity = "error";
                alert.message = `${combinedResult.statusText}: ${combinedResult.statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            this.resetState(); // Full reset necessary to also update the other column of chip input fields
            await this.refreshTableData();
        }
    }

    /**
     * ALERT DIALOG <DELETE>
     */

    // Called to open the delete dialog
    openTableRowDeleteDialog( teamid, teamname ) {
        let deleteDialog = {
            open: true,
            teamid: teamid,
            teamname: teamname
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    // Called when the user clicked "Ok" on the confirm row delete dialog
    async handleTableRowDeleteDialog_Ok( teamid ) {
        this.closeTableRowDeleteDialog();

        // Delete the team
        if( null != teamid ) {
            const { statusCode, statusText, resData } = await TeamService.deleteTeam( { teamid: teamid } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                if( true === resData.deleteTeam.result ) {
                    alert.severity = "success";
                    alert.message = i18n.t( "pages.admin.teams.delete.alert.success" );
                } else {
                    alert.severity = "error";
                    alert.message = `${statusText}: ${statusCode}`;
                }
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the confirm row delete dialog
    async handleTableRowDeleteDialog_Cancel() {
        this.closeTableRowDeleteDialog();
    }

    // Close the delete alert dialog
    closeTableRowDeleteDialog() {
        let deleteDialog = {
            open: false,
            teamid: this.state.deleteDialog.teamid,
            teamname: this.state.deleteDialog.teamname
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    /**
     * CALLBACK FUNCTIONS FOR SEARCH FIELD
     */

    // Called when text is entered in the search field
    handleChangeSearchField(value) {
        this.setState({ searchFieldEntry: value });
    }

    /**
     * CALLBACK FUNCTIONS OF TABLE ELEMENTS
     */

    // Called when a delete button of an existing team is clicked within the table
    handleTableRowDeleteClick(event, teamid, teamname) {
        event.stopPropagation();
        this.openTableRowDeleteDialog( teamid, teamname );
    }

    /**
     * TABLE PAGINATION EVENT HANDLERS
     */

    // Called if page changes on the table display occur
    handleChangeTablePage = (event, newPage) => {
        this.setState({tablePage: newPage});
    };
    
    // Called if the user selects a different amount of shown rows
    handleChangeTableRowsPerPage = (event) => {
        this.setState({tablePage: 0, tableRowsPerPage: +event.target.value});
    };

    /**
     * TOP ROW BUTTONS
     */

    // Called if the add button is clicked
    handleAddButtonClick() {
        this.openAddDialog();
    }

    // Called if the refresh button is clicked
    async handleRefreshButtonClick() {
        this.resetState();
        await this.refreshTableData();
    }

    /**
     * TEXT FIELDS FOR TEAM NAME
     */

    // Handle when a team name text field is changed
    async handleTeamNameFieldChange(event, teamid, newValue) {
        event.stopPropagation();

        // Update the team
        if( null != teamid ) {
            const { statusCode, statusText, resData } = await TeamService.updateTeam( { teamid: teamid }, { name: newValue } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t( "pages.admin.teams.modify.alert.update_name_success" );
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called by the custom text field displaying the team names to verify their content
    verifyTeamNameFieldContent( textFieldTeamId, textFieldValue ) {
        let result = {
            state: false,
            msg: i18n.t("pages.admin.teams.add.dialog.alert.team_name_empty")
        }
        
        if( null != textFieldValue ) {
            if( textFieldValue !== "" ) {
                const { exists, teamid } = this.doesTeamExist( textFieldValue );
                if( true === exists && textFieldTeamId !== teamid ) {
                    result = {
                        state: false,
                        msg: i18n.t("pages.admin.teams.add.dialog.alert.team_does_already_exist")
                    }
                } else {
                    result = {
                        state: true,
                        msg: ""
                    }
                }
            }
        }
        return result;
    }

    /**
     * HELPERS CALLED DURING RENDER TO RETRIEVE INFO FROM PROCESSED DATA
     */

    // Get the team leads for a team
    getTeamLeadsForTeam( teamid ) {

        if( teamid != null ) {
            // Retrieve info from array
            for( let i = 0; i < this.state.tableData.usersForTeam.length; i++ ) {
                if( this.state.tableData.usersForTeam[i].teamid === teamid ) {
                    return this.state.tableData.usersForTeam[i].teamLeads;
                }
            }
        }

        // Fallback empty data
        return [];
    }

    // Get the users for a team
    getUsersForTeam( teamid ) {

        if( teamid !== null ) {
            // Retrieve info from array
            for( let i = 0; i < this.state.tableData.usersForTeam.length; i++ ) {
                if( this.state.tableData.usersForTeam[i].teamid === teamid ) {
                    return this.state.tableData.usersForTeam[i].users;
                }
            }
        } else {
            // No team id given, return all users
            let usersFound = [];

            if( null != this.state.data.users ) {
                let users = this.state.data.users;
                for( let i = 0; i < users.length; i++ ) {
                    usersFound.push( users[i] );
                }
            }

            usersFound.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.userTypeName > b.userTypeName ) return 1;
                if( a.userTypeName < b.userTypeName ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });
            return usersFound;
        }

        // Fallback empty data
        return [];
    }

    // Get all users who are not assigned to any team
    getUsersWithoutTeam() {
        let usersFound = [];

        if( null != this.state.data.users && null != this.state.data.usersToTeams ) {
            let users = this.state.data.users;
            let usersToTeams = this.state.data.usersToTeams;

            for( let i = 0; i < users.length; i++ ) {
                let userHasNoTeam = true;
                for( let j = 0; j < usersToTeams.length; j++ ) {
                    if( users[i].userid === usersToTeams[j].userid ) {
                        userHasNoTeam = false;
                        break;
                    }
                }

                if( true === userHasNoTeam ) {
                    usersFound.push( users[i] );
                }
            }
        }

        usersFound.sort(function(a, b) { 
            // Sort by user type first, then by user lastname
            if( a.userTypeName > b.userTypeName ) return 1;
            if( a.userTypeName < b.userTypeName ) return -1;
            if( a.lastname > b.lastname ) return 1;
            if( a.lastname < b.lastname ) return -1;
            return 0;
        });
        return usersFound;
    }

    // Get delete button info for a team
    getDeleteButtonDataForTeam( teamid ) {
        // Retrieve info from array
        for( let i = 0; i < this.state.tableData.deleteButtonsForTeam.length; i++ ) {
            if( this.state.tableData.deleteButtonsForTeam[i].teamid === teamid ) {
                return this.state.tableData.deleteButtonsForTeam[i];
            }
        }
        // Fallback empty data
        return { teamid: teamid, enabled: false, tooltip: i18n.t("pages.admin.teams.delete.button.tooltip.disabled") };
    }

    /// Check if a certain team name already exists
    doesTeamExist( name ) {
        let result = {
            exists: false,
            teamid: 0
        }

        // Retrieve info from array
        if( null != this.state.data.teams ) {
            let teams = this.state.data.teams;

            for( let i = 0; i < teams.length; i++ ) {
                if( teams[i].name === name ) {
                    result.exists = true;
                    result.teamid = teams[i].teamid;
                    break;
                }
            }
        }

        return result;
    }

    // Get the textual representation of an user value
    getUserLabel( value ) {
        let result = "";
        if( null != value ) {
            let userTypeName = "";
            if( value.userTypeName !== "" && value.userTypeName != null ) {
                userTypeName = "(" + value.userTypeName + ") ";
            }
            result = `${userTypeName}${value.lastname}, ${value.firstname}`
        }
        return result;
    }

    // Get the color class representation of an user value
    getUserEnabled( value ) {
        if( null != value ) {
            if( value.account_enabled ) {
                return true;
            } else {
                return false
            }
        }
        return false;
    }

    // Sort given users for team data
    sortUserData( data ) {
        let result = [];
        if( null != data ) {
            result = data;
            result.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.userTypeName > b.userTypeName ) return 1;
                if( a.userTypeName < b.userTypeName ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });
        }
        return result;
    }

    /**
     * DATA SORT
     */

    // Handle table sort
    handleRequestSort( event, property ) {

        // Check if clicked on same property
        if( this.state.orderBy === property ) {

            // User clicked on same property
            // If already sorted in ascending order, switch to descending order
            if( this.state.order === 'asc' ) {
                this.setState({ order: 'desc', orderBy: property });
            } else {

                // If already sorted in descending order, switch to no order
                this.setState({ order: 'asc', orderBy: 0 });
            }

        } else {
            // User clicked on new property, sort new property in ascending order
            this.setState({ order: 'asc', orderBy: property });
        }
    };

    // Sort the given table data
    sortTableData( teams ) {
        // Handle case where no ordering is selected
        if( this.state.orderBy === 0 ) {
            return teams.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
        }
        return stableSort( teams, getComparator(this.state.order, this.state.orderBy) );
    }

    /**
     * MAIN RENDER FUNCTION
     */
    render() {
        const { classes } = this.props;

        // Only display data if it has already been loaded
        let contentTable = "";
        let usersWithoutTeamContent = "";
        let additionalNotes = "";

        // Make sure if we got the data already
        if( null != this.state.data.teams ) {

            // Assemble table content
            const tableHeader = [
                { key: "name", span: 1, align: "left", sortable: true, style: {}, text: i18n.t("pages.admin.teams.table.header.name") },
                { key: "members", span: 1, align: "center", sortable: false, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.teams.table.header.members") },
                { key: "leads", span: 1, align: "center", sortable: false, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.teams.table.header.leads") },
                { key: "controls", span: 2, align: "center", sortable: false, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.teams.table.header.controls") }
            ];

            let teams = this.state.data.teams;

            // Filter table data if any search entry has been given
            if( this.state.searchFieldEntry !== "" ) {
                teams = teams.filter( team => String(team.name.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase()));
            }

            // Sort table entries by given properties
            teams = this.sortTableData( teams );

            // Retrieve all users no matter if on a team or not
            const allUsers = this.getUsersForTeam( null );

            contentTable = (
                <div>
                    <TableContainer component={Paper}>
                        <StyledTable size="small" >
                            <StyledTableHead>
                                <StyledTableRow key={"user-teams-header"}>
                                    {tableHeader.map((col) => (
                                        <StyledTableCell key={col.key} colSpan={col.span} align={col.align} style={col.style}>
                                            <StyledTableSortLabel
                                            active={this.state.orderBy === col.key}
                                            hideSortIcon={false}
                                            direction={this.state.orderBy === col.key ? this.state.order : 'asc'}
                                            onClick={(event) => this.handleRequestSort(event, col.key)}
                                            disabled={!(col.sortable)}
                                            >
                                                {col.text}
                                            </StyledTableSortLabel>
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </StyledTableHead>
                            <TableBody>
                                {teams.slice(this.state.tablePage * this.state.tableRowsPerPage, this.state.tablePage * this.state.tableRowsPerPage + this.state.tableRowsPerPage).map((team) => {
                                    const usersForTeam = this.getUsersForTeam( team.teamid );
                                    const teamLeadsForTeam = this.getTeamLeadsForTeam( team.teamid );
                                    return (
                                        <StyledTableRow key={team.teamid} teamid={team.teamid}>
                                            <StyledTableCell className={classes.tableCellTeamName} key="name" width="20%">
                                                <CustomTextField
                                                    maxLength={config.maxTextLengths.team.name}
                                                    textFieldValue={team.name} 
                                                    verifyContent={(content) => this.verifyTeamNameFieldContent(team.teamid, content)} 
                                                    onApply={(event, newValue) => this.handleTeamNameFieldChange(event, team.teamid, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.tableCellTeamMembers} key="members" width="40%">
                                                <CustomChipInput
                                                    className={classes.chipInput}
                                                    getOptionLabel={(value) => this.getUserLabel(value)}
                                                    getOptionEnabled={(value) => this.getUserEnabled(value)}
                                                    getAllOptions={() => allUsers} 
                                                    getSelectedOptions={() => usersForTeam} 
                                                    sortOptions={(data) => this.sortUserData(data)}
                                                    noOptionsText={i18n.t("pages.admin.teams.modify.dialog.team_members.no_options")}
                                                    onApply={(content) => this.handleModifyTeamMembers( team.teamid, content )}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.tableCellTeamLeads} key="leads" width="40%">
                                                <CustomChipInput 
                                                    className={classes.chipInput}
                                                    getOptionLabel={(value) => this.getUserLabel(value)}
                                                    getOptionEnabled={(value) => this.getUserEnabled(value)}
                                                    getAllOptions={() => usersForTeam} 
                                                    getSelectedOptions={() => teamLeadsForTeam} 
                                                    sortOptions={(data) => this.sortUserData(data)}
                                                    noOptionsText={i18n.t("pages.admin.teams.modify.dialog.team_leads.no_options")}
                                                    onApply={(content) => this.handleModifyTeamLeads( team.teamid, content )}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell width="20%" key="delete" align="center" padding="checkbox" style={{ borderTopWidth: 0 }} onClick={(event) => event.stopPropagation()}>
                                                <Tooltip title={this.getDeleteButtonDataForTeam(team.teamid).tooltip}>
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.clearButton}
                                                            disabled={!(this.getDeleteButtonDataForTeam(team.teamid).enabled)}
                                                            onClick={(event) => this.handleTableRowDeleteClick(event, team.teamid, team.name)}
                                                            style={{ height: "30px", width: "45px" }}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                    <Grid container direction="row" justify="flex-end" className={classes.tablePaginationGrid} spacing={1} wrap='nowrap'>
                        <Grid item>
                            <TablePagination
                                className={classes.tablePagination}
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={teams.length}
                                rowsPerPage={this.state.tableRowsPerPage}
                                labelRowsPerPage={i18n.t("table.pagination.rows_per_page")}
                                page={this.state.tablePage}
                                onChangePage={this.handleChangeTablePage}
                                onChangeRowsPerPage={this.handleChangeTableRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                    <ModalDialog 
                        open={this.state.addDialog.open}
                        title={i18n.t("pages.admin.teams.add.dialog.title")}
                        descText={i18n.t("pages.admin.teams.add.dialog.description")}
                        buttonLeft={i18n.t("pages.admin.teams.add.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.teams.add.dialog.button_right")}
                        handleClickClose={() => this.handleAddDialog_Cancel()}
                        handleClickLeft={(content) => this.handleAddDialog_Ok(content)}
                        handleClickRight={() => this.handleAddDialog_Cancel()}
                    >
                        <ModalDialogTextField
                            maxLength={config.maxTextLengths.team.name}
                            label={i18n.t("pages.admin.teams.add.dialog.field_name")} 
                            verifyContent={(content) => this.verifyAddDialog_Content(content)} 
                        />
                    </ModalDialog>
                    <ModalDialog 
                        open={this.state.deleteDialog.open}
                        title={i18n.t("pages.admin.teams.delete.dialog.title")}
                        descText={i18n.t("pages.admin.teams.delete.dialog.description", { name: this.state.deleteDialog.teamname })}
                        buttonLeft={i18n.t("pages.admin.teams.delete.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.teams.delete.dialog.button_right")}
                        handleClickClose={() => this.handleTableRowDeleteDialog_Cancel()}
                        handleClickLeft={() => this.handleTableRowDeleteDialog_Ok( this.state.deleteDialog.teamid )}
                        handleClickRight={() => this.handleTableRowDeleteDialog_Cancel()}
                    />
                </div>
            )

            // Retrieve all users which are not on any team
            const allUsersWithoutTeam = this.getUsersWithoutTeam();

            let usersWithoutTeamList = i18n.t("pages.admin.teams.unassigned_users.none");

            if( 0 < allUsersWithoutTeam.length ) {
                usersWithoutTeamList = (
                    <div>
                        {allUsersWithoutTeam.map(function(user) {
                            let fullUserEntry = user.lastname + ", " + user.firstname;
                            if( user.userTypeName != null && user.userTypeName !== "" ) {
                                fullUserEntry = "(" + user.userTypeName + ") " + user.lastname + ", " + user.firstname;
                            }

                            if( user.account_enabled ) {
                                return (
                                    <StyledChipEnabled key={"chip-enabled-"+user.userid} label={fullUserEntry} />
                                )
                            } else {
                                return (
                                    <Tooltip key={"chip-tooltip-"+user.userid} title={i18n.t("tooltips.user_disabled")}>
                                        <StyledChipDisabled key={"chip-disabled-"+user.userid} label={fullUserEntry} />
                                    </Tooltip>
                                )
                            }
                        })}
                    </div>
                )
            }

            usersWithoutTeamContent = (
                <div>
                    <PageSubtitle title={i18n.t("pages.admin.teams.unassigned_users.headline")} />
                    {usersWithoutTeamList}
                </div>
            )

            additionalNotes = (
                <div>
                    <PageSubtitle title={i18n.t("pages.admin.teams.notes.headline")} />
                    <PageDescription key="notes-desc-1">
                        {i18n.t("pages.admin.teams.notes.desc1")}
                    </PageDescription>
                </div>
            )
        }

        return (
            <React.Fragment>
                <PageTitle title={i18n.t("pages.admin.teams.title")} />
                <p>{i18n.t("pages.admin.teams.desc")}</p>
                <SearchControls
                    label={i18n.t("pages.admin.teams.search.label")}
                    helperText={i18n.t("pages.admin.teams.search.subtext")}
                    addTooltip={i18n.t("pages.admin.teams.add.button.tooltip")}
                    refreshTooltip={i18n.t("buttons.refresh.tooltip")}
                    onAdd={this.handleAddButtonClick}
                    onRefresh={this.handleRefreshButtonClick}
                    onChange={this.handleChangeSearchField} 
                />
                <div>{contentTable}</div>
                <div>{usersWithoutTeamContent}</div>
                <br />
                {additionalNotes}
            </React.Fragment>
        )
    }
};

export default withStyles(useStyles, { withTheme: true })(AdminTeams);