import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import Fab from '@material-ui/core/Fab';

const RefreshButton = React.memo(({ title, ...other }) => {
    return (
        <Tooltip title={title}>
            <div>
                <Fab color="primary" size="medium" {...other}>
                    <RefreshIcon />
                </Fab>
            </div>
        </Tooltip>
    )
});

export default RefreshButton;

