import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 */
class CustomHolidayService {

    /**
     * Get custom holidays
     * @param {*} customHolidaySearch { date, name, year_start, year_end }
     */
    async getCustomHolidays( customHolidaySearch ) {
        var request = {
            query: `
            query getCustomHolidays($customHolidaySearch: CustomHolidaySearch!) {
                getCustomHolidays(customHolidaySearch: $customHolidaySearch) {
                    date,
                    name,
                    year_start,
                    year_end
                }
            }
            `,
            variables: {
                customHolidaySearch: customHolidaySearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Create a new custom holiday
     * @param {*} customHolidayInput { date, name, year_start, year_end }
     */
    async createCustomHoliday( customHolidayInput ) {
        var request = {
            query: `
            mutation createCustomHoliday($customHolidayInput: CustomHolidayInput) {
                createCustomHoliday(customHolidayInput: $customHolidayInput) {
                    date,
                    name,
                    year_start,
                    year_end
                }
            }
            `,
            variables: {
                customHolidayInput: customHolidayInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update a custom holiday
     * @param {*} customHolidaySearch { date, name, year_start, year_end }
     * @param {*} customHolidayUpdate { date, name, year_start, year_end }
     */
    async updateCustomHoliday( customHolidaySearch, customHolidayUpdate ) {
        var request = {
            query: `
            mutation updateCustomHoliday($customHolidaySearch: CustomHolidaySearch!, $customHolidayUpdate: CustomHolidayUpdate!) {
                updateCustomHoliday(customHolidaySearch: $customHolidaySearch, customHolidayUpdate: $customHolidayUpdate) {
                    date,
                    name,
                    year_start,
                    year_end
                }
            }
            `,
            variables: {
                customHolidaySearch: customHolidaySearch,
                customHolidayUpdate: customHolidayUpdate
            }
        };

        return await ApiService.doPost( request );
    }   

    /**
     * Delete a custom holiday
     * @param {*} customHolidaySearch { date, name, year_start, year_end }
     */
    async deleteCustomHoliday( customHolidaySearch ) {
        var request = {
            query: `
            mutation deleteCustomHoliday($customHolidaySearch: CustomHolidaySearch!) {
                deleteCustomHoliday(customHolidaySearch: $customHolidaySearch) {
                    result
                }
            }
            `,
            variables: {
                customHolidaySearch: customHolidaySearch
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new CustomHolidayService();
