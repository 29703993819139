import i18n from 'i18next';
import { config } from './config';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

// Invert color hex
export function invertColor( hex ) {
    return (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()
}

// Helper for invertColorMoreContrast()
function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

// Invert color with more contracts
export function invertColorMoreContrast( hex, bw ) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '000000'
            : 'FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return padZero(r) + padZero(g) + padZero(b);
}

// Do a deep comparions of two JSON objects
export function jsonEqual( a, b ) {
    return JSON.stringify(a) === JSON.stringify(b);
}

// Create a Date() object with given date without time setting
export function createDateOnly( dateString ) {
    if( dateString == null ) {
        return removeTimeFromDate( new Date() );
    }
    return removeTimeFromDate( new Date( dateString ) );
}

// Convert Date() object to proper SQL string keeping timezone settings in mind
export function toJSONLocalDate( dateObject ) {

    if( null != dateObject ) {
        var local = new Date(dateObject);
        local.setMinutes(dateObject.getMinutes() - dateObject.getTimezoneOffset());
        return local.toJSON().slice(0, 10);
    }

    return "";
}

// Remove the set time from the given Date() object and set it to 00:00:00
export function removeTimeFromDate( dateObject ) {
    if( null != dateObject ) {
        return new Date( dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate(), 0, 0, 0 );
    }
    return dateObject;
}

// Convert date string (yyyy-mm-dd) or timestamp to Date() object
export function toDateObject( date ) {
    if( date == null ) {
        return null;
    }

    if( typeof date === "string" || date instanceof String ) {
        if( date !== "" ) {
            const dateArray = date.split("-");
            return new Date( dateArray[0], dateArray[1]-1, dateArray[2] );
        }
        return null;
    }

    // Assuming regular timestamp
    return new Date( date );
}

// Convert date string (yyyy-mm-dd) to properly readable format (date only)
export function toDateOnlyString( date, language ) {

    // If a value has been given format it according to the set language
    if( null != date && date !== "" ) {
        return new Intl.DateTimeFormat(language, {
            year: "numeric", 
            month: "short", 
            day: "2-digit" 
        }).format( new Date( date ) );
    }

    return "";
}

// Convert date string (yyyy-mm-dd) to properly readable format (date only) without year
export function toDateOnlyStringNoYear( date, language ) {

    // If a value has been given format it according to the set language
    if( null != date && date !== "" ) {
        return new Intl.DateTimeFormat(language, {
            month: "short", 
            day: "2-digit" 
        }).format( new Date( date ) );
    }

    return "";
}

// Round date object's time to nearest quarter hour
export function roundDateObjectToQuarter( date ) {
    if( null != date ) {
        var dateToReturn = new Date(date);

        dateToReturn.setMilliseconds(Math.round(dateToReturn.getMilliseconds() / 1000) * 1000);
        dateToReturn.setSeconds(Math.round(dateToReturn.getSeconds() / 60) * 60);
        dateToReturn.setMinutes(Math.round(dateToReturn.getMinutes() / 15) * 15);
        return dateToReturn;
    }
    return null;
}

// Convert date object's time to hh:mm:ss time format as a string
export function fromDateToTimeString( date ) {
    var returnString = "";

    if( null != date ) {

        let hours = date.getHours();
        let mins = date.getMinutes();
        let secs = date.getSeconds();

        let hoursString = hours;
        let minsString = mins;
        let secsString = secs;

        if( hours < 10 ) { hoursString = "0" + hoursString; }
        if( mins < 10 ) { minsString = "0" + minsString; }
        if( secs < 10 ) { secsString = "0" + secsString; }

        returnString = hoursString + ":" + minsString + ":" + secsString;
    }

    return returnString;
}

// Convert date object's time to hh:mm time format as a string, omitting seconds
export function fromDateToTimeStringNoSeconds( date ) {
    var returnString = "";

    if( null != date ) {

        let hours = date.getHours();
        let mins = date.getMinutes();

        let hoursString = hours;
        let minsString = mins;

        if( hours < 10 ) { hoursString = "0" + hoursString; }
        if( mins < 10 ) { minsString = "0" + minsString; }

        returnString = hoursString + ":" + minsString;
    }

    return returnString;
}

// Remove the seconds from a hh:mm:ss string to only get hh:mm
export function removeSecondsFromTimeString( time ) {
    var returnTime = null;

    if( null != time && time !== "" ) {
        const timeArray = time.split(":");
        returnTime = timeArray[0] + ":" + timeArray[1];
    }

    return returnTime;
}

// Convert time string hh:mm:ss or hh:mm to date object
export function fromTimeStringToDate( time ) {
    var returnDate = null;

    if( null != time && time !== "" ) {
        returnDate = new Date();

        const timeArray = time.split(":");
        if( timeArray.length === 3 ) {
            returnDate.setHours( timeArray[0], timeArray[1], timeArray[2] );
        } else if( timeArray.length === 2 ) {
            returnDate.setHours( timeArray[0], timeArray[1], 0 );
        }
    }

    return returnDate;
}

// Round time string hh:mm to given minutes
export function roundTimeString( time, minutes ) {
    let momentTime = moment( fromTimeStringToDate( time ) );
    momentTime.minutes(Math.ceil( momentTime.minutes() / minutes ) * minutes);
    return momentTime.format('HH:mm'); 
}

// Get the worktime summary
export function getWorkTimeSummary( data ) {
    let summary = {
        workTime: 0,
        breaks: 0,
        workTimeNoBreaks: 0,
        accountedTime: 0
    }

    if( null != data ) {
        let sumOfWorkTime = 0;
        for( let i = 0; i < data.worktimes.today.length; i++ ) {
            let momentStartTime = moment( fromTimeStringToDate( data.worktimes.today[i].start_time ) );
            let momentEndTime = moment( fromTimeStringToDate( data.worktimes.today[i].end_time ) );

            const duration = moment.duration(momentEndTime.diff(momentStartTime));
            sumOfWorkTime += parseFloat( duration.asHours().toFixed(2) );
        }
        summary.workTime = sumOfWorkTime;

        // Accumulate sum of breaks
        let sumOfBreaksInEntry = 0;
        let sumOfBreaksBetweenEntries = 0;

        // Take the breaks which are defined in the specific work time entry
        for( let i = 0; i < data.worktimes.today.length; i++ ) {
            sumOfBreaksInEntry += data.worktimes.today[i].break;
        }

        // Also consider breaks between two work time entries on the same date
        for( let i = 0; i < data.worktimes.today.length; i++ ) {
            // Check if we have a follow up entry for today after the current one
            if( (i+1) < data.worktimes.today.length ) {
                // We have a follow up entry, check how much time passed between the end_time of the current entry and the start_time of the next
                let momentCurrentEndTime = moment( fromTimeStringToDate( data.worktimes.today[i].end_time ) );
                let momentNextStartTime = moment( fromTimeStringToDate( data.worktimes.today[i+1].start_time ) );

                const duration = moment.duration(momentNextStartTime.diff(momentCurrentEndTime));
                const breakBetweenEntries = parseFloat( duration.asHours().toFixed(2) );
                if( breakBetweenEntries > 0 ) {
                    sumOfBreaksBetweenEntries += breakBetweenEntries;
                }
            }
        }

        summary.breaks = sumOfBreaksInEntry + sumOfBreaksBetweenEntries;
        summary.workTimeNoBreaks = summary.workTime - sumOfBreaksInEntry;

        let sumOfAccountings = 0;
        for( let i = 0; i < data.accountings.length; i++ ) {
            sumOfAccountings += data.accountings[i].hours;
        }
        summary.accountedTime = sumOfAccountings;
    }

    return summary;
}

// Check if given special does auto accounting
export function isSpecialAutoAccounting( specialid, specials ) {

    if( null == specials ) {
        return true;
    }

    for( let i = 0; i < specials.length; i++ ) {
        if( specials[i].specialid === specialid ) {
            return specials[i].auto_accounting;
        }
    }
    return false;
}

// Get all accounting entries for a given day, priority of original and modified entries are already taken into account here
export function getAccountingsForDate( dateJson, accountings, userid ) {
    let accountingsForDate = [];

    if( null == accountings || null == dateJson || dateJson === "" ) {
        return accountingsForDate;
    }

    // Go through all accountings
    if( null == userid || userid === "" ) {

        // No userid given, just search for date
        for( let i = 0; i < accountings.length; i++ ) {
            if( accountings[i].date === dateJson ) {
                accountingsForDate.push( accountings[i] );
            }
        }
    } else {

        // Userid given, search for date and userid
        for( let i = 0; i < accountings.length; i++ ) {
            if( accountings[i].date === dateJson ) {
                if( userid === accountings[i].userid ) {
                    accountingsForDate.push( accountings[i] );
                }
            }
        }
        
    }

    return accountingsForDate;
}

// Get the worktime warnings
export function getWorkTimeWarnings( data, summary ) {
    let warnings = [];

    // Check for properly set start and end times
    for( let i = 0; i < data.worktimes.today.length; i++ ) {
        if( data.worktimes.today[i].start_time == null || data.worktimes.today[i].start_time === "" ) {
            warnings.push( i18n.t("pages.main.worktime.dialog.warnings.start_time_missing", { entryid: (i+1) }) );
        }
        if( data.worktimes.today[i].end_time == null || data.worktimes.today[i].end_time === "" ) {
            warnings.push( i18n.t("pages.main.worktime.dialog.warnings.end_time_missing", { entryid: (i+1) }) );
        }
    }

    // Check for overlapping start and end times
    for( let i = 0; i < data.worktimes.today.length; i++ ) {
        const workTimeRange = moment.range( fromTimeStringToDate( data.worktimes.today[i].start_time ), fromTimeStringToDate( data.worktimes.today[i].end_time ) );

        for( let j = (i+1); j < data.worktimes.today.length; j++ ) {
            const workTimeRangeCheck = moment.range( fromTimeStringToDate( data.worktimes.today[j].start_time ), fromTimeStringToDate( data.worktimes.today[j].end_time ) );

            if( true === workTimeRange.overlaps(workTimeRangeCheck) ) {
                let overlapEntries = (i+1) + ", " + (j+1);
                warnings.push( i18n.t("pages.main.worktime.dialog.warnings.times_overlapping", { entryids: overlapEntries }) );
            }
        }
    }

    // Check for start time being after end time
    for( let i = 0; i < data.worktimes.today.length; i++ ) {
        const momentStartTime = moment( fromTimeStringToDate( data.worktimes.today[i].start_time ) );
        const momentEndTime = moment( fromTimeStringToDate( data.worktimes.today[i].end_time ) );

        if( true === momentStartTime.isAfter( momentEndTime ) ) {
            warnings.push( i18n.t("pages.main.worktime.dialog.warnings.start_time_after_end_time", { entryid: (i+1) }) );
        }
    }

    // Check for break times
    if( summary.accountedTime >= 6 && summary.accountedTime <= 9 ) {
        if( summary.breaks < config.workTimes.break_short ) {
            warnings.push( i18n.t("pages.main.worktime.dialog.warnings.insufficient_break_times_short") );
        }
    } else if( summary.accountedTime > 9 ) {
        if( summary.breaks < config.workTimes.break_long ) {
            warnings.push( i18n.t("pages.main.worktime.dialog.warnings.insufficient_break_times_long") );
        }
    }

    // Check for rest period
    let momentEndTimeYesterday = null;
    let momentStartTimeToday = null;

    for( let i = 0; i < data.worktimes.yesterday.length; i++ ) {
        if( null == momentEndTimeYesterday ) {
            momentEndTimeYesterday = moment( fromTimeStringToDate( data.worktimes.yesterday[i].end_time ) );
        } else {
            let newEndTimeYesterday = moment( fromTimeStringToDate( data.worktimes.yesterday[i].end_time ) );

            if( true === newEndTimeYesterday.isAfter( momentEndTimeYesterday ) ) {
                momentEndTimeYesterday = newEndTimeYesterday;
            }
        }
    }

    for( let i = 0; i < data.worktimes.today.length; i++ ) {
        if( null == momentStartTimeToday ) {
            momentStartTimeToday = moment( fromTimeStringToDate( data.worktimes.today[i].start_time ) );
        } else {
            let newStartTimeToday = moment( fromTimeStringToDate( data.worktimes.today[i].end_time ) );

            if( true === newStartTimeToday.isBefore( momentStartTimeToday ) ) {
                momentStartTimeToday = newStartTimeToday;
            }
        }
    }

    if( null != momentEndTimeYesterday ) {
        momentEndTimeYesterday.subtract(1, "days");

        const duration = moment.duration(momentStartTimeToday.diff(momentEndTimeYesterday));
        if( duration.asHours() < config.workTimes.minimum_rest_period ) {
            warnings.push( i18n.t("pages.main.worktime.dialog.warnings.insufficient_rest_period") );
        }
    }

    let specifiedWorkTime = summary.workTimeNoBreaks;
    if( specifiedWorkTime < summary.accountedTime ) {
        warnings.push( i18n.t("pages.main.worktime.dialog.warnings.accounted_time_mismatch_greater") );
    } else if( specifiedWorkTime > summary.accountedTime ) {
        warnings.push( i18n.t("pages.main.worktime.dialog.warnings.accounted_time_mismatch_shorter") );
    }

    return warnings;
}