import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

const CheckboxTooltip = React.memo(({ tooltip, checked, onChange, color, name, disabled }) => {
    return (
        <Tooltip title={tooltip}>
            <div>
                <Checkbox checked={checked} onChange={onChange} color={color} name={name} disabled={disabled} />
            </div>
        </Tooltip>
    )
});

export default CheckboxTooltip;