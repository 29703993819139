import React, { Component } from 'react';
import UserService from '../../classes/UserService'
import UserTypeService from '../../classes/UserTypeService'
import ContractService from '../../classes/ContractService'
import AdjustmentService from '../../classes/AdjustmentService'
import SpecialService from '../../classes/SpecialService'
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import AuthContext from '../../contexts/AuthContext';
import { StyledTableCell, StyledTableRow, StyledTableHead, StyledTable, StyledTableSortLabel, stableSort, getComparator } from '../../components/StyledTable.js'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import SelectControls from '../../components/SelectControls'
import PageTitle from '../../components/PageTitle';
import ModalDialog from '../../components/ModalDialog';
import CustomDatePicker from '../../components/CustomDatePicker'
import CustomMultilineTextField from '../../components/CustomMultilineTextField'
import ModalDialogDatePicker from '../../components/ModalDialogDatePicker';
import ModalDialogTextField from '../../components/ModalDialogTextField';
import CustomTextField from '../../components/CustomTextField'
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { createDateOnly } from '../../helpers.js';
import { config } from '../../config';
import i18n from 'i18next';

const useStyles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    noSpecialTypeMessage: {
        fontSize: "18px",
        flexBasis: "10%",
        flexShrink: 0
    },
    mainGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    tableCellUserName: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1.0),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
    },
    tablePaginationGrid: {
        height: '60px',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tablePagination: {
        '& .MuiTablePagination-toolbar': {
            height: '60px',
            minHeight: '60px',
        },
    },
    tableCellTextField: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1.0),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
    }
});

/**
 * Admin Adjustments
 */
class AdminAdjustments extends Component {
    static contextType = AuthContext;

    // Set in constructor
    state = {}

    /**
     * Inputs Props
     * @param {*} props 
     *  admin (array)
     *  userProps (array)
     *  dataLoadChanged (function)
     *  alertChanged (function)
     */
    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleAddButtonClick = this.handleAddButtonClick.bind( this );
        this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind( this );
        this.handleChangeSelectField = this.handleChangeSelectField.bind( this );
        this.getInitialState = this.getInitialState.bind( this );
        this.getAdjustmentsOfUser = this.getAdjustmentsOfUser.bind( this );
        this.getContractsOfUser = this.getContractsOfUser.bind( this );
        this.getBlockDate = this.getBlockDate.bind( this );

        this.verifyDateContent = this.verifyDateContent.bind( this );
        this.handleDateChange = this.handleDateChange.bind( this );
        this.verifyHoursContent = this.verifyHoursContent.bind( this );
        this.handleHoursChange = this.handleHoursChange.bind( this );
        this.verifyDaysContent = this.verifyDaysContent.bind( this );
        this.handleDaysChange = this.handleDaysChange.bind( this );
        this.verifyCommentContent = this.verifyCommentContent.bind( this );
        this.handleCommentChange = this.handleCommentChange.bind( this );

        this.handleDelete = this.handleDelete.bind( this );
        this.handleDeleteDialog_Ok = this.handleDeleteDialog_Ok.bind( this );
        this.handleDeleteDialog_Cancel = this.handleDeleteDialog_Cancel.bind( this );
        
        this.handleAddDialog_Ok = this.handleAddDialog_Ok.bind( this );
        this.handleAddDialog_Cancel = this.handleAddDialog_Cancel.bind( this );
        
        this.handleChangeTablePage = this.handleChangeTablePage.bind( this );
        this.handleChangeTableRowsPerPage = this.handleChangeTableRowsPerPage.bind( this );
        
        this.handleRequestSort = this.handleRequestSort.bind( this );
        this.sortTableData = this.sortTableData.bind( this );

        // Set initial state
        this.state = this.getInitialState( this.props.userid );
    }

    /**
     * STATE HANDLING
     */

    // Get the initial state variables of this component
    getInitialState( selectedUserId ) {
        return {
            data: {
                users: null,
                userTypes: null,
                specials: null,
                contracts: null,
                adjustments: null
            },
            tableData: { // Additional information gathered from loaded raw data to display on the page
                userSelection: []
            },
            selectedUserId: selectedUserId,
            tablePage: 0,
            tableRowsPerPage: 10,
            order: "asc",
            orderBy: 0,
            addDialog: {
                open: false,
                blockDate: {
                    before: null,
                    beforeInclusive: false,
                    after: null,
                    afterInclusive: false
                }
            },
            deleteDialog: {
                open: false,
                adjustment: null
            },
        }
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState() {
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
        this.setState( this.getInitialState( this.state.selectedUserId ) );
    }

    /**
     * LINK TO PARENT COMPONENT FUNCTIONS
     */

    // Propagate information to parent component
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Propagate information to parent component
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Propagate information to parent component
    handleActionInProgressChanged( actionInProgress ) {
        if( this.props.actionInProgressChanged ) {
            this.props.actionInProgressChanged( actionInProgress );
        }
    }

    /**
     * LOAD AND PROCESS BACKEND DATA
     */

    // Called when a refresh of the table data is needed due to edits in the table
    async refreshTableData() {
        await this.loadDataFromBackend( true );
    }

    // Gather all data from the backend needed on this page and process them properly
    async loadDataFromBackend( manualRefresh ) {

        // Alert message to show
        let alert = {
            severity: "info",
            message: ""
        }

        let userData = null;

        // Gather user data
        {
            const { statusCode, statusText, resData } = await UserService.getUsers( {} );
            if( 200 === statusCode && null != resData ) {
                userData = resData.getUsers;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        let userTypeData = null;

        // Gather user type data
        {
            const { statusCode, statusText, resData } = await UserTypeService.getUserTypes( {} );
            if( 200 === statusCode && null != resData ) {
                userTypeData = resData.getUserTypes;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        let contractData = null;

        // Gather contract data
        {
            const { statusCode, statusText, resData } = await ContractService.getContracts( {} );
            if( 200 === statusCode && null != resData ) {
                contractData = resData.getContracts;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        let adjustmentData = null;

        // Gather adjustment data
        {
            const { statusCode, statusText, resData } = await AdjustmentService.getAdjustments( {} );
            if( 200 === statusCode && null != resData ) {
                adjustmentData = resData.getAdjustments;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        let specialData = null;

        // Gather special data
        {
            const { statusCode, statusText, resData } = await SpecialService.getSpecials( {} );
            if( 200 === statusCode && null != resData ) {
                specialData = resData.getSpecials;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Now process the data
        await this.processData( userData, userTypeData, specialData, contractData, adjustmentData );

        // All fine, clear the error message (only if not a manual refresh)
        if( false === manualRefresh ) {
            this.handleAlertChanged( alert );
        }
        this.handleDataLoadedChanged( true );
    }

    // Called to make additional processing of data, for example gathering info from that backend data and storing it for later use
    async processData( userData, userTypeData, specialData, contractData, adjustmentData ) {

        // Abort if no proper data given
        if( null == userData || null == userTypeData || null == specialData || null == contractData || null == adjustmentData ) {
            return;
        }

        // Add user type name to all user data entries
        for( let i = 0; i < userData.length; i++ ) {
            let userTypeName = "";
            for( let j = 0; j < userTypeData.length; j++ ) {
                if( userTypeData[j].typeid === userData[i].typeid ) {
                    userTypeName = userTypeData[j].name;
                    break;
                }
            }
            userData[i].userTypeName = userTypeName;
        }

        // Populate userSelection state variable
        let userSelection = [];
        for( let i = 0; i < userData.length; i++ ) {
            let fullUserEntry = userData[i].lastname + ", " + userData[i].firstname;
            if( userData[i].userTypeName != null && userData[i].userTypeName !== "" ) {
                fullUserEntry = "(" + userData[i].userTypeName + ") " + userData[i].lastname + ", " + userData[i].firstname;
            }
            userSelection.push( { id: userData[i].userid, value: fullUserEntry, firstname: userData[i].firstname, lastname: userData[i].lastname, userTypeName: userData[i].userTypeName });
        }
        userSelection.sort(function(a, b) { 
            // Sort by user type first, then by user lastname
            if( a.userTypeName > b.userTypeName ) return 1;
            if( a.userTypeName < b.userTypeName ) return -1;
            if( a.lastname > b.lastname ) return 1;
            if( a.lastname < b.lastname ) return -1;
            return 0;
        });

        // Store the data
        this.setState({ 
            data: { users: userData, userTypes: userTypeData, specials: specialData, contracts: contractData, adjustments: adjustmentData },
            tableData: { userSelection: userSelection }
        });
    }

    /**
     * HELPERS
     */

    // Get all adjustments of a certain user
    getAdjustmentsOfUser( userid ) {
        let adjustments = [];

        // Add any adjustments which were stored in vacation_days_at_start within the user contract
        if( null != this.state.data.contracts ) {
            for( let i = 0; i < this.state.data.contracts.length; i++ ) {
                if( this.state.data.contracts[i].userid === userid ) {
                    if( this.state.data.contracts[i].work_hours_at_start !== 0 ) {
                        let initialAdjustment = {
                            adjustmentid: this.state.data.contracts[i].contractid,
                            work_hours: this.state.data.contracts[i].work_hours_at_start,
                            vacation_days: this.state.data.contracts[i].vacation_days_at_start,
                            comment: i18n.t("pages.admin.adjustments.table.adjustment_at_start.comment"),
                            userid: userid,
                            date: this.state.data.contracts[i].date_start,
                            is_editable: false
                        }
                        adjustments.push( initialAdjustment );
                    }
                }
            }
        }

        // Add any adjustments which happened after the initial work hours value at the start of the contract
        if( null != this.state.data.adjustments ) {
            for( let i = 0; i < this.state.data.adjustments.length; i++ ) {
                if( this.state.data.adjustments[i].userid === userid ) {
                    let entry = {
                        adjustmentid: this.state.data.adjustments[i].adjustmentid,
                        work_hours: this.state.data.adjustments[i].work_hours,
                        vacation_days: this.state.data.adjustments[i].vacation_days,
                        comment: this.state.data.adjustments[i].comment,
                        userid: this.state.data.adjustments[i].userid,
                        date: this.state.data.adjustments[i].date,
                        is_editable: true
                    }
                    adjustments.push( entry );
                }
            }
        }

        return adjustments;
    }

    // Get all contracts of a certain user
    getContractsOfUser( userid ) {
        let contracts = [];

        if( null != this.state.data.contracts ) {
            for( let i = 0; i < this.state.data.contracts.length; i++ ) {
                if( this.state.data.contracts[i].userid === userid ) {
                    contracts.push( this.state.data.contracts[i] );
                }
            }
        }

        return contracts;
    }

    // Get blockers for date before the earliest contract
    getBlockDate( contractsOfUser ) {
        // contract object might be null if we are adding a new contract
        if( null == contractsOfUser ) {
            return { before: null, beforeInclusive: false, after: null, afterInclusive: false }
        }

        // List of contracts of user is already sorted, first element is the most recent contract according to date_start
        let before = null;
        let after = null;

        // Get the start date of the first/oldest contract and the end date of the last contract
        contractsOfUser.sort(function(a, b) { return a.date_start > b.date_start ? 1 : -1; });

        if( contractsOfUser.length > 0 ) {
            before = contractsOfUser[0].date_start;
            after = contractsOfUser[contractsOfUser.length-1].date_end;
        } else {
            // In case the user has no contracts, block everything
            before = "2999-12-31";
            after = "1900-01-01";
        }

        return { before: before, beforeInclusive: false, after: after, afterInclusive: false }
    }

    /**
     * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
     */

    // Called by react when this component has been mounted
    async componentDidMount() {
        await this.loadDataFromBackend();
    }

    // Called by react before this component unmounts
    componentWillUnmount() {
        this.resetState();
    }

    /**
     * CALLBACK FUNCTIONS FOR USER SELECT FIELD
     */

    // Called when a user is selected in the search field
    handleChangeSelectField(value) {
        this.setState({ selectedUserId: value });
    }

    /**
     * TABLE PAGINATION EVENT HANDLERS
     */

    // Called if page changes on the table display occur
    handleChangeTablePage = (event, newPage) => {
        this.setState({tablePage: newPage});
    };
    
    // Called if the user selects a different amount of shown rows
    handleChangeTableRowsPerPage = (event) => {
        this.setState({tablePage: 0, tableRowsPerPage: +event.target.value});
    };

    /**
     * CALLBACK FUNCTION FOR TABLE CLICKS
     */

    // Called to verify date content
    verifyDateContent(userid, date) {

        // Check data validity
        if( null == userid || null == date ) {
            return { state: false, msg: i18n.t("pages.admin.adjustments.add.dialog.alert.empty") };
        }

        // Block any dates outside of an active contract of the user
        let contractsOfUser = this.getContractsOfUser( this.state.selectedUserId );
        contractsOfUser.sort(function(a, b) { return a.date_start < b.date_start ? 1 : -1; });
        let blockDate = this.getBlockDate( contractsOfUser );

        // Convert to comparable time stamps
        let selectedTimestamp = createDateOnly( date ).getTime();
        let blockBeforeTimestamp = createDateOnly( blockDate.before );
        let blockAfterTimestamp = createDateOnly( "2999-12-31" );
        if( null != blockDate.after ) {
            blockAfterTimestamp = createDateOnly( blockDate.after );
        }
        
        if( selectedTimestamp < blockBeforeTimestamp ) {
            return { state: false, msg: i18n.t("pages.admin.adjustments.add.dialog.alert.date_outside_of_contract") };
        } else if( selectedTimestamp > blockAfterTimestamp ) {
            return { state: false, msg: i18n.t("pages.admin.adjustments.add.dialog.alert.date_outside_of_contract") };
        }

        // Also block days where an adjustment for that user already exists
        const adjustmentsForUser = this.getAdjustmentsOfUser( this.state.selectedUserId );
        for( let i = 0; i < adjustmentsForUser.length; i++ ) {
            if( date === adjustmentsForUser[i].date ) {
                return { state: false, msg: i18n.t("pages.admin.adjustments.add.dialog.alert.date_adjustment_already_exists") };
            }
        }

        // All dates are fine
        return { state: true, msg: "" };
    }

    // Change the date
    async handleDateChange(event, adjustment, newDate) {
        event.stopPropagation();

        // Check data validity
        if( adjustment == null || newDate == null || newDate === "" ) {
            return;
        }

        // Update the adjustment entry
        const { statusCode, statusText, resData } = await AdjustmentService.updateAdjustment( 
            {
                adjustmentid: adjustment.adjustmentid
            },
            { 
                date: newDate
            } 
        );

        let alert = {
            severity: "info",
            message: ""
        }

        if( 200 === statusCode && null != resData ) {
            alert.severity = "success";
            alert.message = i18n.t("pages.admin.adjustments.modify.alert.success");
        } else {
            alert.severity = "error";
            alert.message = `${statusText}: ${statusCode}`;
        }

        this.handleAlertChanged( alert );

        // Refresh the table data
        await this.refreshTableData();
    }

    // Called to verify hours content
    verifyHoursContent(value) {
        // The text field is already checking for range and if the number is positive or negative
        // Here we only need to check if the fractions are matching
        if( value !== "" ) {

            let hoursPrecision = 0.25;
            if( null != this.context.config ) {
                if( null != this.context.config.accounting.hours_precision ) {
                    hoursPrecision = this.context.config.accounting.hours_precision;
                }
            }

            let result = parseFloat( value )%hoursPrecision;
            if( result !== 0 ) {
                return { state: false, msg: i18n.t("pages.admin.adjustments.add.dialog.alert.hours_invalid_fraction", { hoursPrecision: hoursPrecision }) };
            }
        } else {

            // Field is empty, this is not allowed
            return { state: false, msg: i18n.t("pages.admin.adjustments.add.dialog.alert.work_hours_empty") };
        }

        // All hours are fine, the ranges are already checked by the field itself
        return { state: true, msg: "" };
    }

    // Change the hours
    async handleHoursChange(event, adjustment, newValue) {
        event.stopPropagation();

        // Check data validity
        if( adjustment == null || newValue == null || newValue === "" ) {
            return;
        }

        const { statusCode, statusText, resData } = await AdjustmentService.updateAdjustment( 
            {
                adjustmentid: adjustment.adjustmentid
            },
            { 
                work_hours: parseFloat( newValue )
            } 
        );

        let alert = {
            severity: "info",
            message: ""
        }

        if( 200 === statusCode && null != resData ) {
            alert.severity = "success";
            alert.message = i18n.t("pages.admin.adjustments.modify.alert.success");
        } else {
            alert.severity = "error";
            alert.message = `${statusText}: ${statusCode}`;
        }

        this.handleAlertChanged( alert );

        // Refresh the table data
        await this.refreshTableData();
    }

    // Called to verify days content
    verifyDaysContent(value) {
        // The text field is already checking for range and if the number is positive or negative
        // Here we only need to check if the fractions are matching
        if( value === "" ) {

            // Field is empty, this is not allowed
            return { state: false, msg: i18n.t("pages.admin.adjustments.add.dialog.alert.vacation_days_empty") };
        }

        // All hours are fine, the ranges are already checked by the field itself
        return { state: true, msg: "" };
    }

    // Change the days
    async handleDaysChange(event, adjustment, newValue) {
        event.stopPropagation();

        // Check data validity
        if( adjustment == null || newValue == null || newValue === "" ) {
            return;
        }

        const { statusCode, statusText, resData } = await AdjustmentService.updateAdjustment( 
            {
                adjustmentid: adjustment.adjustmentid
            },
            { 
                vacation_days: parseInt( newValue )
            } 
        );

        let alert = {
            severity: "info",
            message: ""
        }

        if( 200 === statusCode && null != resData ) {
            alert.severity = "success";
            alert.message = i18n.t("pages.admin.adjustments.modify.alert.success");
        } else {
            alert.severity = "error";
            alert.message = `${statusText}: ${statusCode}`;
        }

        this.handleAlertChanged( alert );

        // Refresh the table data
        await this.refreshTableData();
    }

    // Called to verify comment content
    verifyCommentContent(content) {
        // All comments are fine, the length is already checked by the field itself
        return { state: true, msg: "" };
    }

    // Change the comment
    async handleCommentChange(event, adjustment, newValue) {
        event.stopPropagation();

        // Check data validity
        if( adjustment == null || newValue == null ) {
            return;
        }

        const { statusCode, statusText, resData } = await AdjustmentService.updateAdjustment( 
            {
                adjustmentid: adjustment.adjustmentid
            },
            { 
                comment: newValue
            } 
        );

        let alert = {
            severity: "info",
            message: ""
        }

        if( 200 === statusCode && null != resData ) {
            alert.severity = "success";
            alert.message = i18n.t("pages.admin.adjustments.modify.alert.success");
        } else {
            alert.severity = "error";
            alert.message = `${statusText}: ${statusCode}`;
        }

        this.handleAlertChanged( alert );

        // Refresh the table data
        await this.refreshTableData();
    }

    // Delete the given entry
    handleDelete(event, adjustment) {
        event.stopPropagation();
        this.openDeleteDialog( adjustment );
    }

    /**
     * DELETE DIALOG
     */

    // Called to open the confirm delete dialog
    openDeleteDialog( adjustment ) {
        let deleteDialog = {
            open: true,
            adjustment: adjustment
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    // Close the confirm delete dialog
    closeDeleteDialog() {
        let deleteDialog = {
            open: false,
            adjustment: null
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    // Delete dialog O pressed
    async handleDeleteDialog_Ok( adjustment ) {
        this.closeDeleteDialog();

        // Delete the adjustment
        if( null != adjustment ) {
            const { statusCode, statusText, resData } = await AdjustmentService.deleteAdjustment( { adjustmentid: adjustment.adjustmentid } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                if( true === resData.deleteAdjustment.result ) {
                    alert.severity = "success";
                    alert.message = i18n.t( "pages.admin.adjustments.delete.alert.success" );
                } else {
                    alert.severity = "error";
                    alert.message = `${statusText}: ${statusCode}`;
                }
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Delete dialog Cancel pressed
    handleDeleteDialog_Cancel() {
        this.closeDeleteDialog();
    }

    /**
     * ADD DIALOG
     */

    // Called to open the add dialog at the given date
    openAddDialog() {

        // Block any dates outside of an active contract of the user
        let contractsOfUser = this.getContractsOfUser( this.state.selectedUserId );
        contractsOfUser.sort(function(a, b) { return a.date_start < b.date_start ? 1 : -1; });
        let blockDate = this.getBlockDate( contractsOfUser );

        let addDialog = {
            open: true,
            blockDate: blockDate
        }
        this.setState({ addDialog: addDialog });
    }

    // Close the modify alert dialog
    closeAddDialog() {
        let addDialog = {
            open: false,
            blockDate: {
                before: null,
                beforeInclusive: false,
                after: null,
                afterInclusive: false
            }
        }
        this.setState({ addDialog: addDialog });
    }

    // Add dialog OK pressed
    async handleAddDialog_Ok(content) {
        this.closeAddDialog();

        // Check data validity
        if( content == null || content[0].value == null || content[1].value == null || content[2].value == null || content[3].value == null ) {
            return;
        }

        const date = content[0].value;
        const hours = parseFloat( content[1].value );
        const days = parseInt( content[2].value );
        const comment = content[3].value;

        const { statusCode, statusText, resData } = await AdjustmentService.createAdjustment( {
            vacation_days: days,
            work_hours: hours,
            comment: comment,
            userid: this.state.selectedUserId,
            date: date
        } );

        let alert = {
            severity: "info",
            message: ""
        }

        if( 200 === statusCode && null != resData ) {
            alert.severity = "success";
            alert.message = i18n.t("pages.admin.adjustments.add.alert.success");
        } else {
            alert.severity = "error";
            alert.message = `${statusText}: ${statusCode}`;
        }

        this.handleAlertChanged( alert );

        // Refresh the table data
        await this.refreshTableData();
    }

    // Add dialog Cancel pressed
    handleAddDialog_Cancel() {
        this.closeAddDialog();
    }

    /**
     * TOP ROW BUTTONS
     */

    // Called if the add button is clicked
    async handleAddButtonClick() {
        this.openAddDialog();
    }

    // Called if the refresh button is clicked
    async handleRefreshButtonClick() {
        this.resetState();
        await this.refreshTableData();
    }

    /**
     * DATA SORT
     */

    // Handle table sort
    handleRequestSort( event, property ) {

        // Check if clicked on same property
        if( this.state.orderBy === property ) {

            // User clicked on same property
            // If already sorted in ascending order, switch to descending order
            if( this.state.order === 'asc' ) {
                this.setState({ order: 'desc', orderBy: property });
            } else {

                // If already sorted in descending order, switch to no order
                this.setState({ order: 'asc', orderBy: 0 });
            }

        } else {
            // User clicked on new property, sort new property in ascending order
            this.setState({ order: 'asc', orderBy: property });
        }
    };

    // Sort the given table data
    sortTableData( adjustments ) {
        // Handle case where no ordering is selected
        if( this.state.orderBy === 0 ) {
            return adjustments.sort(function(a, b) { return a.date > b.date ? 1 : -1; });
        }
        return stableSort( adjustments, getComparator(this.state.order, this.state.orderBy) );
    }

    /**
     * RENDER FUNCTIONS
     */

    // Render the content table
    renderContentTable() {
        const { classes } = this.props;

        // Assemble table content
        const tableHeader = [
            { key: 1, span: 1, align: "left", style: {}, text: i18n.t("pages.admin.adjustments.table.header.date") },
            { key: 2, span: 1, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.adjustments.table.header.hours") },
            { key: 3, span: 1, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.adjustments.table.header.days") },
            { key: 4, span: 1, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.adjustments.table.header.comment") },
            { key: 5, span: 1, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.adjustments.table.header.controls") }
        ];

        // Get day adjustments of selected user
        let adjustmentsOfUserToSort = this.getAdjustmentsOfUser( this.state.selectedUserId );

        // Sort table entries by given properties
        const adjustmentsOfUser = this.sortTableData( adjustmentsOfUserToSort );

        // Sort contracts of selected user by date
        let contractsOfUser = this.getContractsOfUser( this.state.selectedUserId );

        if( null == contractsOfUser ) {
            contractsOfUser = [];
        }
        contractsOfUser.sort(function(a, b) { return a.date_start < b.date_start ? 1 : -1; });

        return (
            <div>
                <TableContainer style={{ minHeight: "556px" }} component={Paper}>
                    <StyledTable size="small" >
                        <StyledTableHead>
                            <StyledTableRow key={"adjustments-header"}>
                                    {tableHeader.map((col) => (
                                        <StyledTableCell 
                                            key={col.key} 
                                            colSpan={col.span} 
                                            align={col.align} 
                                            style={col.style}
                                        >
                                            <StyledTableSortLabel
                                                active={this.state.orderBy === col.key}
                                                hideSortIcon={false}
                                                direction={this.state.orderBy === col.key ? this.state.order : 'asc'}
                                                onClick={(event) => this.handleRequestSort(event, col.key)}
                                                disabled={!(col.sortable)}
                                            >
                                                {col.text}
                                            </StyledTableSortLabel>
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                            {adjustmentsOfUser.slice(this.state.tablePage * this.state.tableRowsPerPage, this.state.tablePage * this.state.tableRowsPerPage + this.state.tableRowsPerPage).map((adjustment) => {
                                
                                // Block any dates which are before an active contract of the user and after
                                let blockDate = this.getBlockDate( contractsOfUser );

                                let modifyDisabled = false;
                                let deleteButtonTooltip = i18n.t("pages.admin.adjustments.delete.button.tooltip.enabled");
                                if( false === adjustment.is_editable ) {
                                    modifyDisabled = true;
                                    deleteButtonTooltip = i18n.t("pages.admin.adjustments.delete.button.tooltip.disabled");
                                }

                                return (
                                    <StyledTableRow key={adjustment.adjustmentid}>
                                        <StyledTableCell key="date" width="20%"style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                            <CustomDatePicker 
                                                dateValue={adjustment.date} 
                                                verifyContent={(date) => this.verifyDateContent(this.state.selectedUserId, date)}
                                                allowedDate={adjustment.date}
                                                onApply={(event, newDate) => this.handleDateChange(event, adjustment, newDate)}
                                                language={this.context.language}
                                                title={i18n.t("pages.admin.adjustments.modify.date.title")}
                                                descText={i18n.t("pages.admin.adjustments.modify.date.description")}
                                                buttonLeft={i18n.t("pages.admin.adjustments.modify.date.button_left")}
                                                buttonRight={i18n.t("pages.admin.adjustments.modify.date.button_right")}
                                                blockBeforeDate={blockDate.before}
                                                blockBeforeDateInclusive={blockDate.beforeInclusive}
                                                blockAfterDate={blockDate.after}
                                                blockAfterDateInclusive={blockDate.afterInclusive}
                                                disabled={modifyDisabled}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell key="hours" width="15%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                            <CustomTextField
                                                numbersOnly={true}
                                                minValue={-9999}
                                                maxValue={9999}
                                                allowNegativeNumbers={true}
                                                allowFractions={true}
                                                textFieldValue={adjustment.work_hours}
                                                verifyContent={(content) => this.verifyHoursContent(content)}
                                                onApply={(event, newValue) => this.handleHoursChange(event, adjustment, newValue)}
                                                disabled={modifyDisabled}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell key="days" width="15%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                            <CustomTextField
                                                numbersOnly={true}
                                                minValue={-9999}
                                                maxValue={9999}
                                                allowNegativeNumbers={true}
                                                allowFractions={false}
                                                textFieldValue={adjustment.vacation_days}
                                                verifyContent={(content) => this.verifyDaysContent(content)}
                                                onApply={(event, newValue) => this.handleDaysChange(event, adjustment, newValue)}
                                                disabled={modifyDisabled}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell className={classes.tableCellTextField} key="comment" width="50%" style={{ borderStyle: "solid", borderLeftWidth: 1 }}>
                                            <CustomMultilineTextField 
                                                textFieldValue={adjustment.comment} 
                                                title={i18n.t("pages.admin.adjustments.modify.comment.title")}
                                                descText={i18n.t("pages.admin.adjustments.modify.comment.description")}
                                                buttonLeft={i18n.t("pages.admin.adjustments.modify.comment.button_left")}
                                                buttonRight={i18n.t("pages.admin.adjustments.modify.comment.button_right")}
                                                onApply={(event, newValue) => this.handleCommentChange(event, adjustment, newValue)}
                                                verifyContent={(content) => this.verifyCommentContent(content)}
                                                rows={8}
                                                rowsMax={8}
                                                allowEmpty={true}
                                                required={false}
                                                disabled={modifyDisabled}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell key="delete" align="center" padding="checkbox" width="15%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                            <Tooltip title={deleteButtonTooltip}>
                                                <div>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.clearButton}
                                                        disabled={modifyDisabled}
                                                        onClick={(event) => this.handleDelete(event, adjustment)}
                                                        style={{ height: "30px", width: "45px" }}
                                                    >
                                                        <DeleteIcon />
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </StyledTable>
                </TableContainer>
                <Grid container direction="row" justify="flex-end" className={classes.tablePaginationGrid} spacing={1} wrap='nowrap'>
                    <Grid item>
                        <TablePagination
                            className={classes.tablePagination}
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={adjustmentsOfUser.length}
                            rowsPerPage={this.state.tableRowsPerPage}
                            labelRowsPerPage={i18n.t("table.pagination.rows_per_page")}
                            page={this.state.tablePage}
                            onChangePage={this.handleChangeTablePage}
                            onChangeRowsPerPage={this.handleChangeTableRowsPerPage}
                        />
                    </Grid>
                </Grid>
                <ModalDialog 
                    open={this.state.addDialog.open}
                    title={i18n.t("pages.admin.adjustments.add.dialog.title")}
                    descText={i18n.t("pages.admin.adjustments.add.dialog.description")}
                    buttonLeft={i18n.t("pages.admin.adjustments.add.dialog.button_left")}
                    buttonRight={i18n.t("pages.admin.adjustments.add.dialog.button_right")}
                    handleClickClose={() => this.handleAddDialog_Cancel()}
                    handleClickLeft={(content) => this.handleAddDialog_Ok(content)}
                    handleClickRight={() => this.handleAddDialog_Cancel()}
                >
                    <ModalDialogDatePicker 
                        language={this.context.language}
                        label={i18n.t("pages.admin.adjustments.add.dialog.date")}
                        verifyContent={(content) => this.verifyDateContent(this.state.selectedUserId, content.date)}
                        blockBeforeDate={this.state.addDialog.blockDate.before}
                        blockBeforeDateInclusive={this.state.addDialog.blockDate.beforeInclusive}
                        blockAfterDate={this.state.addDialog.blockDate.after}
                        blockAfterDateInclusive={this.state.addDialog.blockDate.afterInclusive}
                    />
                    <ModalDialogTextField 
                        label={i18n.t("pages.admin.adjustments.add.dialog.work_hours")}
                        verifyContent={(content) => this.verifyHoursContent(content.value)}
                        autoFocus={false}
                        numbersOnly={true}
                        minValue={-9999}
                        maxValue={9999}
                        allowFractions={true}
                        allowNegativeNumbers={true}
                    />
                    <ModalDialogTextField 
                        label={i18n.t("pages.admin.adjustments.add.dialog.vacation_days")}
                        verifyContent={(content) => this.verifyDaysContent(content.value)}
                        autoFocus={false}
                        numbersOnly={true}
                        minValue={-9999}
                        maxValue={9999}
                        allowFractions={false}
                        allowNegativeNumbers={true}
                    />
                    <ModalDialogTextField 
                        label={i18n.t("pages.admin.adjustments.add.dialog.comment")}
                        verifyContent={(content) => this.verifyCommentContent(content.value)}
                        autoFocus={false}
                        multiline={true}
                        rows={6}
                        rowsMax={6}
                        allowEmpty={true}
                        required={false}
                        maxLength={config.maxTextLengths.accounting.comment}
                    />
                </ModalDialog>
                <ModalDialog 
                    open={this.state.deleteDialog.open}
                    title={i18n.t("pages.admin.adjustments.delete.dialog.title")}
                    descText={i18n.t("pages.admin.adjustments.delete.dialog.description")}
                    buttonLeft={i18n.t("pages.admin.adjustments.delete.dialog.button_left")}
                    buttonRight={i18n.t("pages.admin.adjustments.delete.dialog.button_right")}
                    handleClickClose={() => this.handleDeleteDialog_Cancel()}
                    handleClickLeft={() => this.handleDeleteDialog_Ok( this.state.deleteDialog.adjustment )}
                    handleClickRight={() => this.handleDeleteDialog_Cancel()}
                />
            </div>
        )
    }

    // Render page
    render() {
        const { classes } = this.props;

        let contentTable = "";

        if( null != this.state.data.users && null != this.state.data.userTypes && null != this.state.data.specials ) {
            
            // Check if we have no user selected yet
            if( "" === this.state.selectedUserId ) {
                contentTable = (
                    <Typography key="no-user-message" align="center" className={classes.noSpecialTypeMessage}>
                        {i18n.t("pages.admin.adjustments.no_user_selected")}
                    </Typography>
                )
            } else {
                contentTable = this.renderContentTable();
            }
        }

        // Check if we want to enable or disable the add button depending on if a user has been selected
        let addButtonTooltip = i18n.t("pages.admin.adjustments.add.button.tooltip.enabled");
        let disableAddButton = false;
        if( null == this.state.selectedUserId || "" === this.state.selectedUserId ) {
            addButtonTooltip = i18n.t("pages.admin.adjustments.add.button.tooltip.disabled.user");
            disableAddButton = true;
        }

        // If no selectable items are yet available, do not supply the selected user id to avoid a warning
        let selectedUserId = "";
        if( null != this.state.tableData.userSelection && this.state.tableData.userSelection.length > 0 ) {
            selectedUserId = this.state.selectedUserId;
        }

        return (
            <React.Fragment>
                <PageTitle title={i18n.t("pages.admin.adjustments.title")} />
                <p>{i18n.t("pages.admin.adjustments.desc")}</p>
                <SelectControls
                    selectedEntry={selectedUserId}
                    label={i18n.t("pages.admin.adjustments.search.label")}
                    helperText={i18n.t("pages.admin.adjustments.search.subtext")}
                    addTooltip={addButtonTooltip}
                    refreshTooltip={i18n.t("buttons.refresh.tooltip")}
                    items={this.state.tableData.userSelection}
                    onAdd={this.handleAddButtonClick}
                    onRefresh={this.handleRefreshButtonClick}
                    onChange={this.handleChangeSelectField}
                    disableAddButton={disableAddButton}
                    minWidth={"40ch"}
                />
                <div>{contentTable}</div>
            </React.Fragment>
        )
    }
};

export default withStyles(useStyles)(AdminAdjustments);