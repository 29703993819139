import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CalendarHelper from '../classes/CalendarHelper.js';
import Chart from "react-apexcharts";
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Chart Project Task Detail
 */
class ChartProjectTaskDetail extends Component {
    state = {
        chart: ""
    }

    /// Get x-axis labels
    getLabels( projectReport ) {
        let labels = [];

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "short" );

        if( null != projectReport ) {
            for( let i = 0; i < projectReport.resultProject.years.length; i++ ) {

                const yearString = " '" + projectReport.resultProject.years[i].year.toString().substring( 2, 4 )
                for( let j = 0; j < projectReport.resultProject.years[i].months.length; j++ ) {

                    // Add label for the month
                    labels.push( monthNames[projectReport.resultProject.years[i].months[j].month-1] + yearString );
                }
            }
        }

        return labels;
    }

    /// Get series: accounted hours in month
    getSeriesAccountedHours( projectReport ) {
        let chartSeries = [];

        if( null != projectReport ) {

            let series = {
                name: i18n.t("pages.reports.options.project.chart_taskdetail.series.sum_in_month.name"),
                id: 0,
                type: 'line',
                data: []
            }
            chartSeries.push( series );

            // Sort user data
            projectReport.resultUsers.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.type > b.type ) return 1;
                if( a.type < b.type ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });

            // Add an empty series per user
            for( let i = 0; i < projectReport.resultUsers.length; i++ ) {

                // Only add the user if he/she accounted hours on the selected task in the report
                for( let j = 0; j < projectReport.resultUsers[i].tasks.length; j++ ) {
                    if( this.props.taskid === projectReport.resultUsers[i].tasks[j].taskid ) {
                        if( 0 < projectReport.resultUsers[i].tasks[j].hours_accounted_total ) {
                            let userName = "";
                            if( "" !== projectReport.resultUsers[i].type ) {
                                userName = "(" + projectReport.resultUsers[i].type + ") ";
                            }
                            userName = userName + "" + projectReport.resultUsers[i].lastname + ", " + projectReport.resultUsers[i].firstname;
        
                            let series = {
                                name: userName,
                                id: projectReport.resultUsers[i].userid,
                                type: 'column',
                                data: []
                            }
                            chartSeries.push( series );
                        }
                    }
                }
            }

            const thisYear = new Date().getFullYear();
            const thisMonth = new Date().getMonth();

            // Handle the general summary of accounted hours series on that specific task
            for( let i = 0; i < projectReport.resultTasks.length; i++ ) {
                if( projectReport.resultTasks[i].taskid === this.props.taskid ) {
                    
                    for( let j = 0; j < projectReport.resultTasks[i].years.length; j++ ) {
                        for( let k = 0; k < projectReport.resultTasks[i].years[j].months.length; k++ ) {
                            let accountedHours = projectReport.resultTasks[i].years[j].months[k].hours_accounted_in_month;

                            // Only add if the current year and month is not past today's year and the past month
                            // in that case only add an empty string, else that looks awkward into the future
                            if( thisYear > projectReport.resultTasks[i].years[j].year || ( thisYear === projectReport.resultTasks[i].years[j].year && thisMonth >= j )) {
                                chartSeries[0].data.push( accountedHours );
                            } else {
                                chartSeries[0].data.push( 0 );
                            }
                        }
                    }
                }
            }

            // Handle the series for each user
            for( let i = 0; i < projectReport.resultUsers.length; i++ ) {

                // Skip user if he/she accounted no hours on this task
                for( let j = 0; j < projectReport.resultUsers[i].tasks.length; j++ ) {
                    if( this.props.taskid === projectReport.resultUsers[i].tasks[j].taskid ) {
                        if( 0 === projectReport.resultUsers[i].tasks[j].hours_accounted_total ) {
                            continue;
                        }
                    }
                }

                // Go through all years for this user
                for( let j = 0; j < projectReport.resultUsers[i].years.length; j++ ) {
                    // Go through all months for this user
                    for( let k = 0; k < projectReport.resultUsers[i].years[j].months.length; k++ ) {
                        // Go through all tasks of this user in this year and month
                        for( let l = 0; l < projectReport.resultUsers[i].years[j].months[k].tasks.length; l++) {
                            if( this.props.taskid === projectReport.resultUsers[i].years[j].months[k].tasks[l].taskid ) {
                                let accountedHours = 0;

                                // Only add if the current year and month is not past today's year and the past month
                                // in that case only add an empty string, else that looks awkward into the future
                                if( thisYear > projectReport.resultUsers[i].years[j].year || ( thisYear === projectReport.resultUsers[i].years[j].year && thisMonth >= j )) {
                                    accountedHours = projectReport.resultUsers[i].years[j].months[k].tasks[l].hours_accounted_in_month;
                                }

                                // Enter the accounted hours into the proper series for this user
                                for( let seriesIndex = 1; seriesIndex < chartSeries.length; seriesIndex++ ) {
                                    if( projectReport.resultUsers[i].userid === chartSeries[seriesIndex].id ) {
                                        chartSeries[seriesIndex].data.push( accountedHours );
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return chartSeries;
    }

    /// Generate the year start annotations
    getYearAnnotations( projectReport ) {
        let annotations = [];

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "short" );

        if( null != projectReport ) {
            for( let i = 0; i < projectReport.resultProject.years.length; i++ ) {
                const year = projectReport.resultProject.years[i].year;
                const yearCut = year.toString().substring(2, 4);
                const januaryLabel = monthNames[0] + " '" + yearCut;

                annotations.push(
                    {
                        x: januaryLabel,
                        borderColor: '#FF4560',
                        label: {
                            style: {
                                color: '#000',
                            },
                            orientation: 'vertical',
                            text: year.toString()
                        }
                    }
                )
            }
        }

        return annotations;
    }

    // Called by react when this component has been mounted
    componentDidMount() {

        // Pre-render chart since it will not change
        if( null !== this.props.projectReport && null !== this.props.dateMin && null !== this.props.dateMax && null !== this.props.taskid ) {
            let accountingChart = this.renderAccountingChart( this.props.projectReport );
            this.setState({ chart: accountingChart });
        }
    }

    // Called by react when this component did update
    componentDidUpdate( prevProps ) {

        if( this.props.dateMin !== prevProps.dateMin || this.props.dateMax !== prevProps.dateMax || this.props.taskid !== prevProps.taskid ) {
            // Pre-render chart since it will not change
            if( null !== this.props.projectReport && null !== this.props.dateMin && null !== this.props.dateMax && null !== this.props.taskid ) {
                let accountingChart = this.renderAccountingChart( this.props.projectReport );
                this.setState({ chart: accountingChart });
            }
        }
    }

    // Render
    renderAccountingChart( projectReport ) {

        const chartLabels = this.getLabels( projectReport );
        let chartSeries = this.getSeriesAccountedHours( projectReport );
        const yearAnnotations = this.getYearAnnotations( projectReport );

        const chartOptions = {
            chart: {
                animations: {
                    enabled: false
                },
                type: 'line',
                id: 'absence-chart',
                toolbar: {
                    tools: {
                        download: false,
                        pan: false
                    }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                }
            },
            annotations: {
                yaxis: [],
                xaxis: yearAnnotations,
                points: []
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: 'true',
                width: 2,
            },
            grid: {},
            title: {},
            labels: chartLabels,
            xaxis: {},
            yaxis: {
                min: 0,
                axisBorder: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0
                },
                title: {
                    text: "NEW",
                    rotate: -90,
                    style: {
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: '12px',
                        fontWeight: 100
                    }
                }
            },
            tooltip: {
                shared: true
            }
        }

        return (
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="line"
            />
        )
    }

    render() {
        return (
            <div>
                {this.state.chart}
            </div>
        );
    }
};

export default withStyles(useStyles)(ChartProjectTaskDetail);