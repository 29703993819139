import { toDateObject, createDateOnly, isSpecialAutoAccounting, getAccountingsForDate } from '../helpers.js';
import { isLeapYear } from "date-fns";

/**
 * Utility functions for calendars
 */
class CalendarHelper {

    // Check if given date is today
    isToday( date ) {
        const today = new Date()
        return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
    }

    // Check if given date is holiday
    isHoliday( holidays, date ) {
        if( null == holidays ) {
            return { state: false, name: null };
        }

        for( let i = 0; i < holidays.length; i++ ) {
            let holidayDate = toDateObject( holidays[i].date ).getTime();
            if( date.getTime() === holidayDate ) {
                return { state: true, name: holidays[i].name };
            }
        }

        return { state: false, name: null };
    }

    // Check if given date is a work day for the user
    isWorkDay( contracts, userid, date ) {
        if( null == contracts ) {
            return false;
        }

        // weekDayIndex runs from Sun(0) to Sat(6)
        // but our work_days_per_week on the contract runs from Mo(0) to Sun(6)
        const weekDayIndex = date.getDay();
        let workDaysPerWeekIndex = weekDayIndex-1;
        if( workDaysPerWeekIndex < 0 ) {
            workDaysPerWeekIndex = workDaysPerWeekIndex + 7;
        }

        for( let i = 0; i < contracts.length; i++ ) {
            if( userid == null || contracts[i].userid === userid ) {
                let startDate = toDateObject( contracts[i].date_start ).getTime();
                let endDate = createDateOnly( "2999-12-31" );
                if( null != contracts[i].date_end ) {
                    endDate = toDateObject( contracts[i].date_end ).getTime();
                }
                if( date.getTime() >= startDate && date.getTime() <= endDate ) {
                    if( "1" === contracts[i].work_days_per_week[workDaysPerWeekIndex] ) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        }

        return false;
    }

    // Check if given data is within contract range of the user
    isWithinContract( contracts, userid, date ) {
        if( null == contracts ) {
            return false;
        }

        for( let i = 0; i < contracts.length; i++ ) {
            if( userid == null || contracts[i].userid === userid ) {
                let startDate = toDateObject( contracts[i].date_start ).getTime();
                let endDate = createDateOnly( "2999-12-31" );
                if( null != contracts[i].date_end ) {
                    endDate = toDateObject( contracts[i].date_end ).getTime();
                }

                if( date.getTime() >= startDate && date.getTime() <= endDate ) {
                    return true;
                }
            }
        }

        return false;
    }

    // Check if given date has an absences
    isAbsent( absences, userid, date ) {
        if( null == absences ) {
            return { state: false, specialid: null };
        }

        for( let i = 0; i < absences.length; i++ ) {
            let holidayDate = toDateObject( absences[i].date ).getTime();
            if( userid == null || absences[i].userid === userid ) {
                if( date.getTime() === holidayDate ) {
                    // Specialid might be null in case the user does not have the rights to access the specific absence reason of this user
                    let specialid = 0;
                    if( null != absences[i].specialid ) {
                        specialid = absences[i].specialid;
                    }
                    return { state: true, specialid: specialid };
                }
            }
        }

        return { state: false, specialid: null };
    }

    // Get the localized name for a specific month
    getMonthName( language, props, monthIndex ) {
        let objDate = new Date();
        objDate.setDate(1);
        objDate.setMonth(monthIndex);
        return new Intl.DateTimeFormat( language, { month: props }).format( objDate );
    }
    
    // Get an array with localized month names
    getMonthNames( language, props ) {
        // Populate array of month names
        let months = [];

        let objDate = new Date();
        objDate.setDate(1);
        for( let i = 0; i < 12; i++ ) {
            objDate.setMonth(i);
            let monthString = new Intl.DateTimeFormat( language, { month: props }).format( objDate );
            months.push( monthString );
        }

        return months;
    }

    // Get an array of localized weekday names and indexes for the given month
    // Returns array of { index (for Date(), Sunday is zero), name }
    getWeekDaysForMonth( language, props, year, month ) {
        // Populate array of week day names
        let weekdays = [];

        let objDate = new Date( year, month, 2 );
        const daysInMonth = this.getDaysInMonth( year, month );
        for( let i = 1; i <= daysInMonth; i++ ) {
            objDate.setDate(i);

            const weekDayIndex = objDate.getDay();
            const weekDayName = new Intl.DateTimeFormat(language, { weekday: props }).format( objDate );
            
            weekdays.push( { index: weekDayIndex, name: weekDayName } );
        }

        return weekdays;
    }

    // Get days in all months for the given year
    getDaysInMonths( year ) {
        // Populate array of days in month
        let daysInMonths = [];
        for( let i = 0; i < 12; i++ ) {
            daysInMonths.push( new Date( year, i, 0 ).getDate() );
        }
        return daysInMonths;
    }

    // Get the amount of days in a year
    getDaysInYear( year ) {
        return isLeapYear( new Date( year, 1, 1 ) ) ? 366 : 365;
    }

    // Get days in a given month for the given year
    getDaysInMonth( year, month ) {
        // Day 0 is the last day in the previous month, so add one to the month
        return new Date( year, month+1, 0 ).getDate();
    }

    // Check if this day is locked due to:
    // * the rolling auto accounting window 
    // * the special on this day is auto accounting
    // * the day is outside of the user's contract
    isDayLocked( dateJson, accountingAutoLock, accountingAutoLockPeriodOfDays, specialAutoAccountingLock, contracts, userid, accountings, specials ) {

        // Determine the final day lock state
        let dayIsLocked = false;

        // Check rolling accounting window if feature is enabled
        if( true === accountingAutoLock ) {
            if( 0 < accountingAutoLockPeriodOfDays ) {

                // We have a lock period and the rolling lock feature is enabled
                var todayDate = new Date();
                var accountingDate = new Date( dateJson );

                var diffInTime = todayDate.getTime() - accountingDate.getTime();
                var diffInDays = diffInTime / ( 1000 * 3600 * 24 );

                if( diffInDays > accountingAutoLockPeriodOfDays ) {
                    // Day is locked, we surpassed the auto lock period
                    dayIsLocked = true;
                }
            }
        }

        // If day is already locked we can bypass any further checks
        if( true === dayIsLocked ) {
            return true;
        }

        // Check if special on day is auto accounting and day is locked due to that
        if( true === specialAutoAccountingLock ) {
            const accountingsForDate = getAccountingsForDate( dateJson, accountings );
            for( let j = 0; j < accountingsForDate.length; j++ ) {
                
                // Check if there is an accounting on this date pointing to an auto-accounting specialid
                if( null != accountingsForDate[j].specialid ) {
                    if( true === isSpecialAutoAccounting( accountingsForDate[j].specialid, specials ) ) {
                        dayIsLocked = true;
                        break;
                    }
                }
            }
        }

        // If day is already locked we can bypass any further checks
        if( true === dayIsLocked ) {
            return true;
        }

        // Check if day is outside of the users contract
        let dateObject = toDateObject( dateJson );
        if( false === this.isWithinContract( contracts, userid, dateObject ) ) {
            dayIsLocked = true;
        }

        return dayIsLocked;
    }
}

export default new CalendarHelper();
