import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { StyledDataTable } from '../components/StyledTable.js'
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Table Project Task Detail
 */
class TableProjectTaskDetail extends Component {
    state = {}

    // Render task details
    renderTaskDetails( projectReport ) {

        // Fill in table header
        let tableHeader = [];
        tableHeader.push({ id: 1, width: "66%", name: i18n.t("pages.reports.options.project.table_details.details") });
        tableHeader.push({ id: 2, width: "33%", name: i18n.t("pages.reports.options.project.table_details.value") });

        // Fill in table data
        let tableData = [];

        // If no task has been selected just show a warning
        if( null == this.props.taskid || this.props.taskid === "" ) {
            tableData.push({ key: i18n.t("pages.reports.options.project.taskdetail.warning"), values: [ "" ] });
        } else {

            for( let i = 0; i < projectReport.resultTasks.length; i++ ) {
                if( projectReport.resultTasks[i].taskid === this.props.taskid ) {

                    // Show all details, convert dates to proper format
                    {
                        let valueString = i18n.t("values.none");
                        if( null != projectReport.resultTasks[i].create_date && projectReport.resultTasks[i].create_date !== "" ) {
                            valueString = new Intl.DateTimeFormat(this.props.language, {
                                year: "numeric", 
                                month: "short", 
                                day: "2-digit" 
                            }).format( new Date( projectReport.resultTasks[i].create_date ) );
                        }
                        tableData.push({ key: i18n.t("pages.reports.options.project.table_details.create_date"), values: [ valueString ]});
                    }
                    {
                        let valueString = i18n.t("values.none");
                        if( null != projectReport.resultTasks[i].status_change_date && projectReport.resultTasks[i].status_change_date !== "" ) {
                            valueString = new Intl.DateTimeFormat(this.props.language, {
                                year: "numeric", 
                                month: "short", 
                                day: "2-digit" 
                            }).format( new Date( projectReport.resultTasks[i].status_change_date ) );
                        }
                        tableData.push({ key: i18n.t("pages.reports.options.project.table_details.status_change_date"), values: [ valueString ]});
                    }
                }
            }
        }

        let table = (
            <StyledDataTable 
                tableKey="project-details" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // Render task summary
    renderTaskSummary( projectReport ) {

        let tableHeader = [];
        tableHeader.push({ id: 1, width: "66%", name: i18n.t("pages.reports.options.project.table_detail_tasks.user") });
        tableHeader.push({ id: 2, width: "33%", name: i18n.t("pages.reports.options.project.table_detail_tasks.accounted_hours") });

        let tableData = [];

        // If no task has been selected just show a warning
        if( null == this.props.taskid || this.props.taskid === "" ) {
            tableData.push({ key: i18n.t("pages.reports.options.project.taskdetail.warning"), values: [ "" ] });
        } else {

            // Sort user data
            projectReport.resultUsers.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.type > b.type ) return 1;
                if( a.type < b.type ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });

            // Show all accounted hours per user on the selected task
            for( let i = 0; i < projectReport.resultUsers.length; i++ ) {
                for( let j = 0; j < projectReport.resultUsers[i].tasks.length; j++ ) {
                    if( projectReport.resultUsers[i].tasks[j].taskid === this.props.taskid ) {
                        if( 0 < projectReport.resultUsers[i].tasks[j].hours_accounted_total ) {

                            let userName = "";
                            if( "" !== projectReport.resultUsers[i].type ) {
                                userName = "(" + projectReport.resultUsers[i].type + ") ";
                            }
                            userName = userName + "" + projectReport.resultUsers[i].lastname + ", " + projectReport.resultUsers[i].firstname;

                            tableData.push({ key: userName, values: [ projectReport.resultUsers[i].tasks[j].hours_accounted_total ]});
                            break;
                        }
                    }
                }
            }

            // Add a line at the bottom showing the total number of accounted hours on the task in this project
            for( let i = 0; i < projectReport.resultTasks.length; i++ ) {
                if( projectReport.resultTasks[i].taskid === this.props.taskid ) {
                    tableData.push({ bold: true, key: i18n.t("pages.reports.options.project.table_detail_tasks.total_on_project"), values: [ projectReport.resultTasks[i].hours_accounted_total ] });
                    break;
                }
            }
        }

        let table = (
            <StyledDataTable 
                tableKey="project-task-summary" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // Takes props: language, projectReport, dateMin, dateMax, taskid
    render() {
        let taskDetails = "";
        let taskSummary = "";
        if( null != this.props.projectReport ) {
            taskDetails = this.renderTaskDetails( this.props.projectReport );
            taskSummary = this.renderTaskSummary( this.props.projectReport );
        }

        return (
            <div>
                {taskDetails}
                <br />
                {taskSummary}
            </div>
        );
    }
};

export default withStyles(useStyles)(TableProjectTaskDetail);