import React, { Component, Fragment } from 'react';
import CalendarHelper from '../../classes/CalendarHelper.js';
import PdfTable from './PdfTable'
import { View, StyleSheet } from '@react-pdf/renderer';
import i18n from 'i18next';

const pdfStyles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF'
    },
    titleSection: {
        textAlign: 'center',
        color: '#000000',
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    titleHeaderText: {
        margin: 10,
        fontSize: '20'
    },
    titleSubText: {
        margin: 5,
        fontSize: '12'
    },
    titleTimestampText: {
        margin: 5,
        fontSize: '10'
    },
    summarySection: {
        textAlign: 'center',
        color: '#000000',
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    detailSection: {
        textAlign: 'center',
        color: '#000000',
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
});

/**
 * PDF-compatible Vacation Table
 */
class PdfTableVacation extends Component {

    // Get user summary data for given year
    getSummary( vacationReport, year ) {

        // Oldest year is at the beginning of the array
        let daysFromLastYear = 0;
        let daysFromContract = 0;
        let daysBooked = 0;
        let daysAvailable = 0;

        if( null != vacationReport ) {
            for( let i = 0; i < vacationReport.years.length; i++ ) {
                if( year > vacationReport.years[i].year.toString() ) {
                    daysFromLastYear += vacationReport.years[i].days_remaining_in_year;
                } else if( year === vacationReport.years[i].year.toString() ) {
                    daysFromContract = vacationReport.years[i].days_available_in_year;
                    daysBooked = vacationReport.years[i].days_booked_in_year;
                    daysAvailable = vacationReport.years[i].days_remaining_in_year;
                    break;
                }
            }
        }

        let daysTotal = daysFromLastYear + daysFromContract;
        daysAvailable += daysFromLastYear;
        
        return {
            daysFromLastYear: daysFromLastYear,
            daysFromContract: daysFromContract,
            daysTotal: daysTotal,
            daysBooked: daysBooked,
            daysAvailable: daysAvailable
        }
    }

    // Render summary
    renderSummary( vacationReport, year ) {

        // If not specific year is selected, show a warning in this table since it does not make sense
        let data = {
            header: {},
            headerRows: [],
            dataRows: [],
            footer: {}
        }
        
        if( year !== "" ) {

            let headerRow = {
                key: "table-vacation-summary-header-"+year,
                cols: [
                    { key: "name", style: { width: "75%" }, value: i18n.t("pages.reports.options.vacation.summary.headline_summary") },
                    { key: "value", style: { width: "25%" }, value: i18n.t("pages.reports.options.vacation.summary.headline_days") }
                ]
            }
            data.headerRows.push( headerRow );

            const summary = this.getSummary( vacationReport, year );

            let dataRow = {
                key: "table-vacation-summary-data-"+year+"-fromlastyear",
                cols: [
                    { key: "name", style: { width: "75%" }, value: i18n.t("pages.reports.options.vacation.summary.from_last_year", { year: year-1 }) },
                    { key: "value", style: { width: "25%" }, value: summary.daysFromLastYear }
                ]
            }
            data.dataRows.push( dataRow );

            dataRow = {
                key: "table-vacation-summary-data-"+year+"-fromcontracts",
                cols: [
                    { key: "name", style: { width: "75%" }, value: i18n.t("pages.reports.options.vacation.summary.from_contracts", { year: year }) },
                    { key: "value", style: { width: "25%" }, value: summary.daysFromContract }
                ]
            }
            data.dataRows.push( dataRow );

            dataRow = {
                key: "table-vacation-summary-data-"+year+"-summarytotal",
                cols: [
                    { key: "name", style: { width: "75%" }, value: i18n.t("pages.reports.options.vacation.summary.total", { year: year }) },
                    { key: "value", style: { width: "25%" }, value: summary.daysTotal }
                ]
            }
            data.dataRows.push( dataRow );

            dataRow = {
                key: "table-vacation-summary-data-"+year+"-summarybooked",
                cols: [
                    { key: "name", style: { width: "75%" }, value: i18n.t("pages.reports.options.vacation.summary.booked") },
                    { key: "value", style: { width: "25%" }, value: summary.daysBooked }
                ]
            }
            data.dataRows.push( dataRow );

            dataRow = {
                key: "table-vacation-summary-data-"+year+"-summaryavailable",
                cols: [
                    { key: "name", style: { width: "75%" }, value: i18n.t("pages.reports.options.vacation.summary.available") },
                    { key: "value", style: { width: "25%" }, value: summary.daysAvailable }
                ]
            }
            data.dataRows.push( dataRow );

        } else {

            let headerRow = {
                key: "table-vacation-summary-header-"+year,
                cols: [
                    { key: "name", style: { width: "99%" }, value: i18n.t("pages.reports.options.vacation.summary.headline_summary") },
                    { key: "value", style: { width: "1%" }, value: "" }
                ]
            }
            data.headerRows.push( headerRow );

            let dataRow = {
                key: "table-vacation-summary-data-"+year,
                cols: [
                    { key: "name", style: { width: "99%" }, value: i18n.t("pages.reports.options.vacation.summary.warning") },
                    { key: "value", style: { width: "1%" }, value: "" }
                ]
            }

            data.dataRows.push( dataRow );
        }

        return data;
    }

    /// Get series: booked vacation days
    getSeriesBookedDays( vacationReport, year ) {
        let series = [];

        if( null != vacationReport ) {
            for( let i = 0; i < vacationReport.years.length; i++ ) {
                // Only return years based on selection
                if( year === "" || year === vacationReport.years[i].year.toString() ) {
                    for( let j = 0; j < vacationReport.years[i].months.length; j++ ) {

                        // Add booked in month at the mid of the month
                        series.push( vacationReport.years[i].months[j].days_booked_in_month );
                    }
                }
            }
        }

        return series;
    }

    // Render detail
    renderDetail( vacationReport, year ) {
        // If no specific year is given, the table makes no sence
        if( year === "" ) {
            return "";
        }

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "long" );
        const seriesBookedDays = this.getSeriesBookedDays( vacationReport, year );

        // Assemble table content
        let data = {
            header: {},
            headerRows: [],
            dataRows: [],
            footer: {}
        }

        let headerRow = {
            key: "table-vacation-detail-header-"+year,
            cols: [
                { key: "name", style: { width: "50%" }, value: i18n.t("pages.reports.options.vacation.table.month") },
                { key: "value", style: { width: "50%" }, value: i18n.t("pages.reports.options.vacation.table.booked") }
            ]
        }
        data.headerRows.push( headerRow );

        for( let i = 0; i < monthNames.length; i++ ) {
            let dataRow = {
                key: "table-vacation-detail-data-"+year+"-booked-"+i,
                cols: [
                    { key: "name", style: { width: "75%" }, value: monthNames[i] },
                    { key: "value", style: { width: "25%" }, value: seriesBookedDays[i] }
                ]
            }
            data.dataRows.push( dataRow );
        }

        return data;
    }

    // Supplied props: language, year, vacationReport
    render() {

        // Final data to return
        let dataSummary = [];
        let dataDetail = [];

        // Render the summary info for the year
        dataSummary.push( this.renderSummary( this.props.vacationReport, this.props.year.toString() ) );
        dataDetail.push( this.renderDetail( this.props.vacationReport, this.props.year.toString() ) );

        return (
            <View style={pdfStyles.summarySection}>
                {dataSummary.map((summaryData, i) => {
                    return (
                        <Fragment key={"fragment-table-vacation-"+i}>
                            <PdfTable key={"table-vacation-summary-"+i} data={summaryData} />
                            <PdfTable key={"table-vacation-detail-"+i} data={dataDetail[i]} />
                        </Fragment>
                    )
                })}
            </View>
        );
    }
}

export default PdfTableVacation
