import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CalendarHelper from '../classes/CalendarHelper.js';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';
import { getAccountingsForDate } from '../helpers.js';
import clsx from  'clsx';
import { config } from '../config';
import i18n from 'i18next';

const useStyles = theme => ({
    container: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(3)
    },
    table: {
        width: "100%",
        tableLayout: "fixed",
        borderCollapse: "separate",
    },
    cellDay: {
        fontWeight: 500,
        fontSize: "12px",
        width: "40px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    cellDayToday: {
        fontWeight: 500,
        fontSize: "12px",
        color: "#ff0000",
        width: "40px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottom: "2px solid #ff0000",
        userSelect: "none"
    },
    cellSumHeader: {
        fontWeight: 500,
        fontSize: "12px",
        minWidth: "45px",
        width: "45px",
        maxWidth: "45px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    cellSumHeaderDay: {
        fontWeight: 500,
        fontSize: "12px",
        width: "40px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    cellSumHeaderDayToday: {
        fontWeight: 500,
        fontSize: "12px",
        width: "40px",
        borderLeft: "2px solid #ff0000",
        borderRight: "2px solid #ff0000",
        borderTopWidth: "0px",
        borderBottom: "0px",
        userSelect: "none"
    },
	cellSumBody: {
        fontWeight: 500,
        fontSize: "12px",
        minWidth: "45px",
        width: "45px",
        maxWidth: "45px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    cellProjectTaskNameHeader: {
        fontWeight: 500,
        fontSize: "12px",
        minWidth: "230px",
        width: "230px",
        maxWidth: "230px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none",
        paddingLeft: theme.spacing(2)
    },
    cellProjectTaskNameBody: {
        fontWeight: 500,
        fontSize: "12px",
        minWidth: "230px",
        width: "230px",
        maxWidth: "230px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none",
        paddingLeft: theme.spacing(2)
    },
    icon: {
        color: theme.palette.primary.main
    },
    statusIcon: {
        color: theme.palette.primary.main,
        width: "12px",
        height: "12px",
        marginBottom: "2px"
    },
    taskName: {
        marginLeft: "20px"
    },
    cellDayBase: {
        verticalAlign: "top",
        height: "25px",
        color: "#656565",
        borderColor: "#e1e1e1",
        borderStyle: "solid",
        borderLeftWidth: "1px",
        borderRightWidth: "0px",
        borderTopWidth: "1px",
        borderBottomWidth: "0px",
        padding: "0px",
        paddingTop: "4px",
        margin: "0px",
        userSelect: "none",
        fontWeight: 500,
        fontSize: "12px",
    },
    cellTodayWeekDay: {
        color: "#000000",
        borderTop: "1px solid #e1e1e1",
        borderLeft: "2px solid #ff0000",
        borderRight: "2px solid #ff0000",
        borderBottomWidth: "0px"
    },
    cellTodayWeekEnd: {
        color: "#000000",
        borderTop: "1px solid #e1e1e1",
        borderLeftWidth: "2px solid #ff0000",
        borderRightWidth: "2px solid #ff0000",
        borderBottomWidth: "0px"
    },
    cellWeekEnd: {
        backgroundColor: "#d9d9d9",
    },
    cellWeekDay: {
        backgroundColor: "#ffffff",
    },
    cellHoliday: {
        color: "#656565",
        backgroundColor: "#fde1fc",
    },
    cellNoWorkDayWeekEnd: {
        backgroundColor: "#d9d9d9",
    },
    cellNoWorkDayWeekDay: {
        color: "#ffffff",
        backgroundColor: "#a0a0a0",
    },
    cellSelected: {
        backgroundColor: "#FACC2E",
    },
    inputHours: {
        fontWeight: 500,
        fontSize: "12px",
        marginLeft: "5px",
        marginRight: "5px"
    },
    cellStickyHead: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.white,
        minWidth: "275px",
        width: "275px",
        maxWidth: "275px",
        left: 0,
        position: "sticky",
        zIndex: theme.zIndex.appBar + 2,
        userSelect: "none",
        paddingTop: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px"
    },
    cellStickyBodyNoBorders: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.white,
        minWidth: "275px",
        width: "275px",
        maxWidth: "275px",
        left: 0,
        position: "sticky",
        zIndex: theme.zIndex.appBar + 2,
        userSelect: "none",
        paddingTop: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        borderColor: "#e1e1e1",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
    },
    cellDividerDay: {
        height: "2px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTop: "1px solid #e1e1e1",
        borderBottomWidth: "0px",
        left: 0,
        position: "sticky",
        userSelect: "none",
        backgroundColor: theme.palette.common.white,
        zIndex: theme.zIndex.appBar + 10,
    },
    cellDividerDayToday: {
        height: "2px",
        borderTop: "1px solid #e1e1e1",
        borderBottomWidth: "0px",
        left: 0,
        position: "sticky",
        userSelect: "none",
        borderLeft: "2px solid rgb(255,0,0)",
        borderRight: "2px solid rgb(255,0,0)",
        backgroundColor: theme.palette.common.white,
        zIndex: theme.zIndex.appBar + 10,
    },
    cellDividerBottomDayToday: {
        height: "2px",
        borderTop: "2px solid #ff0000",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        left: 0,
        position: "sticky",
        userSelect: "none",
        backgroundColor: theme.palette.common.white,
        zIndex: theme.zIndex.appBar + 10,
    },
    cellDividerName: {
        height: "2px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        left: 0,
        position: "sticky",
        userSelect: "none",
        borderBottom: "0px solid rgb(224,224,224)",
        backgroundColor: theme.palette.common.white,
    }
});

/**
 * Accountings Project
 */
class AccountingProject extends Component {

    constructor(props) {
        super(props);

        // Bind functions
        this.renderAccountings = this.renderAccountings.bind( this );
        this.calculateSumForDaysInMonth = this.calculateSumForDaysInMonth.bind( this );
        this.calculateSumForTaskInMonth = this.calculateSumForTaskInMonth.bind( this );
        this.calculateSumForTaskForDate = this.calculateSumForTaskForDate.bind( this );
    }

    /**
     * OTHER HELPERS
     */

    // Calculate the sum of accounted hours for each day in the month
    calculateSumForDaysInMonth( weekDaysInMonth, dateJsonBase, userid ) {
        if( null == weekDaysInMonth || null == dateJsonBase ) {
            return;
        }

        let sumForDaysInMonth = [];

        // Prefil array
        for( let i = 0; i < weekDaysInMonth.length; i++ ) {
            let dateJson = `${dateJsonBase}-`
            if( i+1 < 10 ) {
                dateJson += `0${i+1}`
            } else {
                dateJson += `${i+1}`
            }

            let sumForDay = 0;

            const accountingsForDate = getAccountingsForDate( dateJson, this.props.accountings, userid );
            for( let j = 0; j < accountingsForDate.length; j++ ) {
                if( null != accountingsForDate[j].hours ) {
                    sumForDay += accountingsForDate[j].hours;
                }
            }

            sumForDaysInMonth.push( sumForDay );
        }

        return sumForDaysInMonth;
    }

    // Calculate sum of accounted hours in month for task
	calculateSumForTaskInMonth( taskid, userid ) {
		if( null == taskid ) {
			return 0;
		}

		let sum = 0;

		for( let i = 0; i < this.props.accountings.length; i++ ) {
			if( null != this.props.accountings[i].taskid && taskid === this.props.accountings[i].taskid ) {

                // Also check for matching userid if given
                if( null == userid || userid === "" ) {
                    sum += this.props.accountings[i].hours;
                } else {
                    if( userid === this.props.accountings[i].userid ) {
                        sum += this.props.accountings[i].hours;
                    }
                }
			}
		}

		return sum;
	}

    // Get the name of a certain project user
    getProjectUser( userid ) {
        let userData = null;

        if( null != this.props.projectUsers && null != userid && userid !== "" ) {
            for( let i = 0; i < this.props.projectUsers.length; i++ ) {
                if( this.props.projectUsers[i].userid === userid ) {
                    userData = this.props.projectUsers[i];
                    break;
                }
            }
        }

        return userData;
    }

    /**
     * HELPERS TO GET ACCOUNTING ENTRIES
     */

    // Get sum of accountings for given task on given day
    calculateSumForTaskForDate( dateJson, taskid, userid ) {
        if( null == this.props.accountings || 0 === this.props.accountings.length ) {
            return { hours: 0, accountedUsers: [], comment: "" };
        }

        let sumHours = 0;
        let accountedUsers = [];

        for( let i = 0; i < this.props.accountings.length; i++ ) {
            if( this.props.accountings[i].date === dateJson ) {
                if( null != taskid && this.props.accountings[i].taskid === taskid ) {

                    let foundAccounting = false;

                    // Also check for matching userid if given
                    if( null == userid || userid === "" ) {
                        foundAccounting = true;
                    } else {
                        if( userid === this.props.accountings[i].userid ) {
                            foundAccounting = true;
                        }
                    }

                    if( true === foundAccounting ) {
                        sumHours += this.props.accountings[i].hours;

                        // Also get the project user data who accounted here
                        const userData = this.getProjectUser( this.props.accountings[i].userid );
                        if( null != userData ) {
                            accountedUsers.push( userData );
                        }
                    }
                }
            }
        }

        accountedUsers.sort(function(a, b) { return a.lastname > b.lastname ? 1 : -1; });

        return { hours: sumHours, accountedUsers: accountedUsers, comment: "" };
    }

    /**
     * RENDERING
     */

    // Render the accounted users into a tooltip
    renderAccountedUsers( accountedUsers ) {
        return (
            <React.Fragment>
                <Typography color="inherit" variant="caption">{i18n.t("pages.main.accounting_project.tooltip.headline")}</Typography>
                {accountedUsers.map((user, userIndex) => {
                    let fullUserEntry = user.lastname + ", " + user.firstname;
                    if( user.userTypeName != null && user.userTypeName !== "" ) {
                        fullUserEntry = "(" + user.userTypeName + ") " + user.lastname + ", " + user.firstname;
                    }
                    return (
                        <div key={userIndex}>* {fullUserEntry}</div>
                    )
                })}
            </React.Fragment>
        )
    }

    // Render a table day cell
    renderTableCell( year, monthIndex, dayIndex, isWeekEnd, isToday, taskid, content ) {

        // Check if day is selected
        const dateObject = new Date( this.props.year, monthIndex, dayIndex+1 );
        const holiday = CalendarHelper.isHoliday( this.props.holidays, dateObject );

        let className = "";
        let dayComment = "";
        if( true === holiday.state ) {
            className = clsx(this.props.classes.cellDayBase, this.props.classes.cellHoliday);
            dayComment = holiday.name;
        } else {
            if( true === isWeekEnd ) {
                dayComment = this.props.labelWeekend;
                if( true === isToday ) {
                    className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekEnd);
                    dayComment = this.props.labelToday;
                } else {
                    className = clsx(this.props.classes.cellDayBase, this.props.classes.cellWeekEnd);
                }
            } else {
                if( true === isToday ) {
                    className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekDay);
                    dayComment = this.props.labelToday;
                } else {
                    className = clsx(this.props.classes.cellDayBase, this.props.classes.cellWeekDay);
                }
            }
        }

        // Generate the final tooltip
        let cellTooltip = "";

        // Add the content of the dayComment
        let dayTooltip = "";
        if( dayComment !== "" ) {
            dayTooltip = (
                <React.Fragment>
                    <Typography color="inherit" variant="caption">{dayComment}</Typography><br/>
                </React.Fragment>
            )
        }

		// Add the content comment to the tooltip if one was given
        let accountedUserTooltip = "";
		if( null != content.accountedUsers && content.accountedUsers.length > 0 ) {
            accountedUserTooltip = this.renderAccountedUsers( content.accountedUsers );
		}

        if( dayTooltip !== "" || accountedUserTooltip !== "" ) {
            cellTooltip = (
                <React.Fragment>
                    {dayTooltip}
                    {accountedUserTooltip}
                </React.Fragment>
            )
        }

		let contentHours = "";
		if( null != content && null !== content.hours && 0 !== content.hours ) {
			contentHours = content.hours;
		}

        let tableCellContent = (
            <TableCell
                align="center"
                key={"cell-date-"+taskid+"-"+year+"-"+monthIndex+"-"+dayIndex}
                className={className}
            >
                <div style={{ width: "100%" }}>
                    {contentHours}
                </div>
            </TableCell>
        )

        if( cellTooltip !== "" ) {
            return (
                <Tooltip key={"tooltip-date-"+taskid+"-"+year+"-"+monthIndex+"-"+dayIndex} title={cellTooltip}>
                    {tableCellContent}
                </Tooltip>
            )
        } else {
            return tableCellContent;
        }
    }
    
    // Render accountings for year and month
    renderAccountings( year, month ) {

        // Get some parameters for rendering
        const todayMonth = new Date().getMonth();
        const todayDate = new Date().getDate();
        const todayYear = new Date().getFullYear();
        const weekDaysInMonth = CalendarHelper.getWeekDaysForMonth( this.props.language, "short", year, month );

        // Start string of json date
        let dateJsonBase = `${year}-`;
        if( (month+1) < 10 ) {
            dateJsonBase += `0${month+1}`;
        } else {
            dateJsonBase += `${month+1}`;
        }

        // Check if we have a selected user
        let userid = "";
        if( null != this.props.user ) {
            userid = this.props.user.userid;
        }

        // Calculate sum of accounted hours per day
        const sumForDaysInMonth = this.calculateSumForDaysInMonth( weekDaysInMonth, dateJsonBase, userid );

        return (
            <TableContainer component={Paper} className={this.props.classes.container}>
                <Table className={this.props.classes.table}>
                    <TableHead key={"projectaccountings-header"}>
                        <TableRow key={"projectaccountings-header-row"}>
                            <TableCell className={this.props.classes.cellStickyHead}>
                                <TableCell key={"projectaccountings-header-row-col-name"} align="center" className={this.props.classes.cellProjectTaskNameHeader} />
                                <TableCell key={"projectaccountings-header-row-col-sum"} align="center" className={this.props.classes.cellSumHeader} />
                            </TableCell>
                            {weekDaysInMonth.map((weekDay, dayIndex) => {
                                let isToday = false;
                                if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                    isToday = true;
                                }

                                if( true === isToday ) {
                                    return (
                                        <TableCell 
                                            key={"projectaccountings-header-row-col-"+dayIndex} 
                                            align="center"
                                            className={this.props.classes.cellDayToday}
                                        >
                                            {dayIndex+1}<br/>{weekDay.name}
                                        </TableCell>
                                    )
                                } else {
                                    return (
                                        <TableCell 
                                            key={"projectaccountings-header-row-col-"+dayIndex} 
                                            align="center"
                                            className={this.props.classes.cellDay}
                                        >
                                            {dayIndex+1}<br/>{weekDay.name}
                                        </TableCell>
                                    )
                                }
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={"projectaccountings-day-summary-row"}>
                            <TableCell className={this.props.classes.cellStickyHead}>
                                <TableCell key={"projectaccountings-day-summary-col-name"} align="left" className={this.props.classes.cellProjectTaskNameHeader} />
                                <TableCell key={"projectaccountings-day-summary-col-sum"} align="center" className={this.props.classes.cellSumHeader}>
                                    &sum;
                                </TableCell>
                            </TableCell>
                            {sumForDaysInMonth.map((daySum, dayIndex) => {
                                let isToday = false;
                                if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                    isToday = true;
                                }

                                if( true === isToday ) {
                                    return (
                                        <TableCell 
                                            key={"projectaccountings-day-summary-col-"+dayIndex} 
                                            align="center"
                                            className={this.props.classes.cellSumHeaderDayToday}
                                        >
                                            {daySum}
                                        </TableCell>
                                    )
                                } else {
                                    return (
                                        <TableCell 
                                            key={"projectaccountings-day-summary-col-"+dayIndex} 
                                            align="center"
                                            className={this.props.classes.cellSumHeaderDay}
                                        >
                                            {daySum}
                                        </TableCell>
                                    )
                                }
                            })}
                        </TableRow>
                        {this.props.tasks.map((task) => {

                            const sumForTaskInMonth = this.calculateSumForTaskInMonth( task.taskid, userid );
                            let taskStatusIcon = "";
                            if( config.enums.taskStatus[0].id !== task.status ) {
                                taskStatusIcon = (
                                    <Tooltip key={"projectaccountings-task-row-"+task.taskid+"-taskclosed"} title={i18n.t("pages.main.tooltip.task_closed")}>
                                        <BlockIcon className={this.props.classes.statusIcon} />
                                    </Tooltip>
                                )
                            }

                            return (
                                <TableRow key={"projectaccountings-task-row-"+task.taskid}>
                                    <TableCell className={this.props.classes.cellStickyBodyNoBorders}>
                                        <TableCell key={"projectaccountings-task-row-"+task.taskid+"-cell-name"} align="left" className={this.props.classes.cellProjectTaskNameBody}>
                                            {task.name} {taskStatusIcon}
                                        </TableCell>
                                        <TableCell key={"projectaccountings-task-row-"+task.taskid+"-cell-sum"} align="center" className={this.props.classes.cellSumBody}>
                                            {sumForTaskInMonth}
                                        </TableCell>
                                    </TableCell>
                                    {weekDaysInMonth.map((weekDay, dayIndex) => {
                                        let isToday = false;
                                        if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                            isToday = true;
                                        }

                                        let isWeekEnd = false;
                                        if( weekDay.index === 0 || weekDay.index === 6 ) {
                                            isWeekEnd = true;
                                        }

                                        // Gather content of this cell
                                        let dateJson = `${dateJsonBase}-`
                                        if( dayIndex+1 < 10 ) {
                                            dateJson += `0${dayIndex+1}`
                                        } else {
                                            dateJson += `${dayIndex+1}`
                                        }

                                        const content = this.calculateSumForTaskForDate( dateJson, task.taskid, userid );

                                        return this.renderTableCell( year, month, dayIndex, isWeekEnd, isToday, task.taskid, content );
                                    })}
                                </TableRow>
                            )
                        })}
                        <TableRow key={"projectaccountings-bottom-row-divider"}>
                            <TableCell key={"projectaccountings-bottom-row-divider-cell-name"} align="left" className={this.props.classes.cellDividerName} />
                            {weekDaysInMonth.map((weekDay, dayIndex) => {
                                let isToday = false;
                                if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                    isToday = true;
                                }
                                if( true === isToday ) {
                                    return (
                                        <TableCell key={"projectaccountings-bottom-row-divider-cell-"+dayIndex} align="left" className={this.props.classes.cellDividerBottomDayToday} />
                                    )
                                } else {
                                    return (
                                        <TableCell key={"projectaccountings-bottom-row-divider-cell-"+dayIndex} align="left" className={this.props.classes.cellDividerDay} />
                                    )
                                }
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    render() {
        // Render the accountings for the given year and month
        const accountings = this.renderAccountings( this.props.year, this.props.month );

        return (
            <div>
                {accountings}
                {this.props.children}
            </div>
        );
    }
};

export default withStyles(useStyles)(AccountingProject);