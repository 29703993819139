import ApiService from './ApiService';
import AdminService from './AdminService';
import AuthContext from '../contexts/AuthContext';
import React, { Component } from 'react';
import { config } from '../config.js';
import i18n from 'i18next';

/**
 * Utility authentication service, also acts as Provider for the AuthContext
 */
class AuthService extends Component {
    state = {
        services: {
            admin: null
        },
        userid: null,
        language: null,
        email: null,
        accessTokenExpiresIn: null,
        refreshTokenExpiresIn: null,
        passwordChangeOnNextLogin: null,
        config: null,
        errorMessage: '',
        successMessage: ''
    };

    constructor(props) {
        super(props);

        // Make our service available to the outside-non-react world
        window.AuthService = this;

        // Do binding due to async await
        this.resetPassword = this.resetPassword.bind( this );
        this.forgotPassword = this.forgotPassword.bind( this );
        this.login = this.login.bind( this );
        this.refresh = this.refresh.bind( this );
        this.refreshIfNeeded = this.refreshIfNeeded.bind( this );
        this.updatePassword = this.updatePassword.bind( this );

        // Try to get the authentication details from the local storage
        if( localStorage.getItem('auth') ) {
            var auth = JSON.parse( localStorage.getItem('auth') );
            if( auth.userid ) {
                this.state.userid = auth.userid;
            }
            if( auth.language ) {
                this.state.language = auth.language;
            }
            if( auth.accessTokenExpiresIn ) {
                this.state.accessTokenExpiresIn = auth.accessTokenExpiresIn;
            }
            if( auth.refreshTokenExpiresIn ) {
                this.state.refreshTokenExpiresIn = auth.refreshTokenExpiresIn;
            }
            if( auth.config ) {
                this.state.config = auth.config;
            }
        }

        // Check if the tokens read from the session storage are still valid, if not discard them
        if( false === this.isAuthenticated() ) {
            this.state.userid = null;
            this.state.language = null;
            this.state.email = null;
            this.state.accessTokenExpiresIn = null;
            this.state.refreshTokenExpiresIn = null;
            this.state.passwordChangeOnNextLogin = null;
            this.state.config = null;
            this.state.errorMessage = '';
            this.state.successMessage = '';
            localStorage.removeItem( 'authTimestamp' );
            localStorage.removeItem( 'auth' );
        }

        // Store services
        this.state.services.admin = AdminService;

        // Change the language
        i18n.changeLanguage(this.state.language);
    }

    /// Parse the expiration time, expects argument in the format xxy (so for example 60s or 1h)
    parseExpTimeInSec = ( expiresIn ) => {
        let expTimeInSec;
        var unit = expiresIn.slice(-1);
        if( unit === 's' ) {
            expTimeInSec = parseInt( expiresIn.slice(0, -1) );
        } else if( unit === 'm' ) {
            expTimeInSec = parseInt( expiresIn.slice(0, -1) ) * 60;
        } else if( unit === 'h' ) {
            expTimeInSec = parseInt( expiresIn.slice(0, -1) ) * 3600;
        } else if( unit === 'd' ) {
            expTimeInSec = parseInt( expiresIn.slice(0, -1) ) * 3600 * 24;
        } else {
            expTimeInSec = parseInt( expiresIn.slice(0, -1) );
        }
        return expTimeInSec;
    }

    /// Calculate how many seconds are still left before token expires
    calcRemainingExpTimeInSec = ( authTimestamp, expiresIn ) => {
        var curTimestamp = Math.floor(Date.now() / 1000);
        var expTimeInSec = this.parseExpTimeInSec( expiresIn );
        var expTimestamp = parseInt( authTimestamp ) + expTimeInSec;
        return ( expTimestamp - curTimestamp );
    }

    /// Send a reset password request to the backend
    async resetPassword( resetToken, password ) {
        let request = {
            query: `
                query doResetPassword($resetToken: String!, $password: String!) {
                    doResetPassword(resetToken: $resetToken, password: $password) {
                        result
                    }
                }
            `,
            variables: {
                resetToken: resetToken,
                password: password
            }
        };

        const { statusCode, statusText, resData } = await ApiService.doPost( request, false );

        if( statusCode !== 200 && statusCode !== 201 ) {
            this.authErrorOccured( `${i18n.t("errors.password_reset_failed")}: ${statusText}` );
        } else {
            if( resData ) {
                // Successful request to reset password, check if the result was also successful
                if( false === resData.doResetPassword.result ) {
                    this.authErrorOccured( `${i18n.t("errors.password_reset_failed")}` );
                } else {
                    this.authErrorOccured( "" );
                }
                return resData.doResetPassword.result;
            }
        }
        return false;
    }

    /// Send a forgot password request to the backend
    async forgotPassword(email) {
        let request = {
            query: `
                query requestResetPassword($email: String!) {
                    requestResetPassword(email: $email) {
                        message
                        result
                    }
                }
            `,
            variables: {
                email: email
            }
        };

        const { statusCode, statusText, resData } = await ApiService.doPost( request, false );

        if( statusCode !== 200 && statusCode !== 201 ) {
            this.authErrorOccured( `${i18n.t("errors.password_reset_failed")}: ${statusText}` );
        } else {
            if( resData ) {
                // Successful request to reset password, check if the result was also successful
                if( false === resData.requestResetPassword.result ) {
                    this.authErrorOccured( `${i18n.t("errors.password_reset_failed")}: ${resData.requestResetPassword.message}` );
                } else {
                    this.authErrorOccured( "" );
                }
                return resData.requestResetPassword.result;
            }
        }
        return false;
    }

    /// Send a update password request to the backend
    async updatePassword( password ) {

        let userSearch = {
            userid: this.state.userid
        }
        let userUpdate = {
            password: password,
            password_change_on_next_login: false
        }

        let request = {
            query: `
                mutation updateUser($userSearch: UserSearch!, $userUpdate: UserUpdate!) {
                    updateUser(userSearch: $userSearch, userUpdate: $userUpdate) {
                        userid
                    }
                }
            `,
            variables: {
                userSearch: userSearch,
                userUpdate: userUpdate
            }
        };

        const { statusCode, statusText, resData } = await ApiService.doPost( request, false );

        let success = false;

        if( statusCode !== 200 && statusCode !== 201 ) {
            this.authErrorOccured( `${i18n.t("errors.password_update_failed")}: ${statusText}` );
        } else {
            if( null == resData ) {
                this.authErrorOccured( `${i18n.t("errors.password_update_failed")}` );
            } else {
                this.authSuccessOccured( i18n.t("auth.forcepassword_success") );

                // Perform logout
                this.logout();

                success = true;
            }
        }
        return success;
    }

    /// Perform the login with the given credentials
    async login(email, password) {
        let request = {
            query: `
            query login($email: String!, $password: String!) {
                login(email: $email, password: $password) {
                    userid
                    language
                    accessToken
                    accessTokenExpiresIn
                    refreshToken
                    refreshTokenExpiresIn
                    passwordChangeOnNextLogin
                    config {
                        accounting {
                            auto_lock
                            auto_lock_period_in_days
                            max_hours_per_day
                            hours_precision
                        }
                    }
                }
            }
            `,
            variables: {
                email: email,
                password: password
            }
        };

        const { statusCode, statusText, resData } = await ApiService.doPost( request, false );

        if( statusCode !== 200 && statusCode !== 201 ) {
            this.authErrorOccured( statusText );
        } else {
            if( resData ) {
                // Successful login
                if (resData.login.accessToken) {
                    var authTimestamp = Math.floor(Date.now() / 1000);
                    localStorage.setItem( 'authTimestamp', authTimestamp);
                    localStorage.setItem( 'auth', JSON.stringify( resData.login ));
                    i18n.changeLanguage(resData.login.language);
                    this.setState({ 
                        authTimestamp: authTimestamp, 
                        userid: resData.login.userid,
                        language: resData.login.language,
                        email: email,
                        accessToken: resData.login.accessToken, 
                        accessTokenExpiresIn: resData.login.accessTokenExpiresIn, 
                        refreshToken: resData.login.refreshToken, 
                        refreshTokenExpiresIn: resData.login.refreshTokenExpiresIn,
                        passwordChangeOnNextLogin: resData.login.passwordChangeOnNextLogin,
                        config: resData.login.config,
                        errorMessage: '',
                        successMessage: ''
                    });
                }
                return resData;
            }
        }
    }

    /// Refresh the access token only if needed
    async refreshIfNeeded() {
        var refreshIt = false;

        // Check if the auth token is still valid
        if( this.isAccessTokenStillValid() ) {

            // Try to get the authentication details from the local storage
            let authTimestamp = null;
            let accessTokenExpiresIn = null;

            if( localStorage.getItem('authTimestamp') ) {
                authTimestamp = parseInt( localStorage.getItem('authTimestamp') );
            }
            if( localStorage.getItem('auth') ) {
                var auth = JSON.parse( localStorage.getItem('auth') );
                if( auth.accessTokenExpiresIn ) {
                    accessTokenExpiresIn = auth.accessTokenExpiresIn;
                }
            }

            // It is still valid, check how much time is left before it expires
            var remainingExpTime = this.calcRemainingExpTimeInSec( authTimestamp, accessTokenExpiresIn );
            if( remainingExpTime <= config.secondsBeforeTokenRefresh ) {
                // We want to refresh it
                refreshIt = true;
            }
        } else {
            // Token not valid anymore, refresh it anyway
            refreshIt = true;
        }

        if( true === refreshIt ) {
            await this.refresh();
        }
    }
    
    /// Perform a refresh, needs a still valid refreshToken
    async refresh() {
        // Check if refresh token is not valid anymore
        if( !this.isRefreshTokenStillValid() ) {
            this.logout();
            return false;
        }

        // Try to get the authentication details from the local storage
        let refreshToken = null;

        if( localStorage.getItem('auth') ) {
            var auth = JSON.parse( localStorage.getItem('auth') );
            if( auth.refreshToken ) {
                refreshToken = auth.refreshToken;
            }
        }

        // Perform the refresh
        let request = {
            query: `
            query refresh($refreshToken: String!) {
                refresh(refreshToken: $refreshToken) {
                    userid
                    language
                    accessToken
                    accessTokenExpiresIn
                    refreshToken
                    refreshTokenExpiresIn
                    passwordChangeOnNextLogin
                    config {
                        accounting {
                            auto_lock
                            auto_lock_period_in_days
                            max_hours_per_day
                            hours_precision
                        }
                    }
                }
            }
            `,
            variables: {
                refreshToken: refreshToken
            }
        };

        const { statusCode, statusText, resData } = await ApiService.doPost( request, false );
        
        if( statusCode !== 200 && statusCode !== 201 ) {
            this.authErrorOccured( `Refresh failed: ${statusText}` );
        } else {
            if( resData ) {
                // Successful refresh
                if (resData.refresh.accessToken) {
                    var authTimestamp = Math.floor(Date.now() / 1000);
                    localStorage.setItem( 'authTimestamp', authTimestamp);
                    localStorage.setItem( 'auth', JSON.stringify( resData.refresh ));
                    i18n.changeLanguage(resData.refresh.language);
                    this.setState({ 
                        authTimestamp: authTimestamp, 
                        userid: resData.refresh.userid,
                        language: resData.refresh.language,
                        accessToken: resData.refresh.accessToken, 
                        accessTokenExpiresIn: resData.refresh.accessTokenExpiresIn, 
                        refreshToken: resData.refresh.refreshToken, 
                        refreshTokenExpiresIn: resData.refresh.refreshTokenExpiresIn,
                        passwordChangeOnNextLogin: resData.refresh.passwordChangeOnNextLogin,
                        config: resData.refresh.config,
                        errorMessage: '',
                        successMessage: ''
                    });
                }
                return resData;
            }
        }
    }

    /// Perform a logout
    logout = () => {
        this.setState({ authTimestamp: null, userid: null, email: null, accessToken: null, accessTokenExpiresIn: null, refreshToken: null, refreshTokenExpiresIn: null, errorMessage: null });
        localStorage.removeItem( 'authTimestamp' );
        localStorage.removeItem( 'auth' );
    }

    /// Call if authentication error occurs with a proper error message
    authErrorOccured = (errorMessage) => {
        this.setState({ authTimestamp: null, userid: null, email: null, accessToken: null, accessTokenExpiresIn: null, refreshToken: null, refreshTokenExpiresIn: null, errorMessage: errorMessage, successMessage: null });
        localStorage.removeItem( 'authTimestamp' );
        localStorage.removeItem( 'auth' );
    }

    // Call if success occured
    authSuccessOccured = (successMessage) => {
        this.setState({ successMessage: successMessage });
    }

    /// Check if accessToken is still valid
    isAccessTokenStillValid = () => {

        // Try to get the authentication details from the local storage
        let authTimestamp = null;
        let accessToken = null;
        let accessTokenExpiresIn = null;

        if( localStorage.getItem('authTimestamp') ) {
            authTimestamp = parseInt( localStorage.getItem('authTimestamp') );
        }
        if( localStorage.getItem('auth') ) {
            var auth = JSON.parse( localStorage.getItem('auth') );
            if( auth.accessToken ) {
                accessToken = auth.accessToken;
            }
            if( auth.accessTokenExpiresIn ) {
                accessTokenExpiresIn = auth.accessTokenExpiresIn;
            }
        }

        if( authTimestamp && accessToken && accessTokenExpiresIn ) {
            var remainingExpTime = this.calcRemainingExpTimeInSec( authTimestamp, accessTokenExpiresIn );
            if( remainingExpTime > 0 ) {
                return true;
            }
        }

        return false;
    };

    /// Check if refresh token is still valid
    isRefreshTokenStillValid = () => {

        // Try to get the authentication details from the local storage
        let authTimestamp = null;
        let refreshToken = null;
        let refreshTokenExpiresIn = null;

        if( localStorage.getItem('authTimestamp') ) {
            authTimestamp = parseInt( localStorage.getItem('authTimestamp') );
        }
        if( localStorage.getItem('auth') ) {
            var auth = JSON.parse( localStorage.getItem('auth') );
            if( auth.refreshToken ) {
                refreshToken = auth.refreshToken;
            }
            if( auth.refreshTokenExpiresIn ) {
                refreshTokenExpiresIn = auth.refreshTokenExpiresIn;
            }
        }

        if( authTimestamp && refreshToken && refreshTokenExpiresIn ) {
            var remainingExpTime = this.calcRemainingExpTimeInSec( authTimestamp, refreshTokenExpiresIn );
            if( remainingExpTime > 0 ) {
                return true;
            }
        }

        return false;
    };

    /// Check if user is currently still authenticated, either via the accessToken or the refreshToken
    isAuthenticated = () => {
        // Here we check for both since we can renew the accessToken as long as we have a proper refreshToken
        if( this.isAccessTokenStillValid() || this.isRefreshTokenStillValid() ) {
            return true;
        }
        return false;
    }

    /// Remember the language
    setLanguage = (language) => {
        i18n.changeLanguage(language);
        this.setState({ language: language });
    }

    /// Give related AuthContext rendering
    render() {
        return (
            <AuthContext.Provider
                value={{
                    services: this.state.services,
                    userid: this.state.userid,
                    language: this.state.language,
                    email: this.state.email,
                    accessTokenExpiresIn: this.state.accessTokenExpiresIn,
                    refreshTokenExpiresIn: this.state.refreshTokenExpiresIn,
                    passwordChangeOnNextLogin: this.state.passwordChangeOnNextLogin,
                    config: this.state.config,
                    errorMessage: this.state.errorMessage,
                    successMessage: this.state.successMessage,
                    forgotPassword: this.forgotPassword,
                    resetPassword: this.resetPassword,
                    updatePassword: this.updatePassword,
                    login: this.login,
                    refresh: this.refresh,
                    logout: this.logout,
                    authSuccessOccured: this.authSuccessOccured,
                    authErrorOccured: this.authErrorOccured,
                    isAccessTokenStillValid: this.isAccessTokenStillValid,
                    isRefreshTokenStillValid: this.isRefreshTokenStillValid,
                    isAuthenticated: this.isAuthenticated,
                    setLanguage: this.setLanguage
                }}
                >
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export default AuthService;