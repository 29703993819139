import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AdminNoAccess from './admin/AdminNoAccess';
import AdminTasks from './admin/AdminTasks';
import AuthContext from '../contexts/AuthContext';
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Tasks Page
 */
class TasksPage extends Component {
    static contextType = AuthContext;

    state = {
        adminFlagsLoaded: false,
        adminFlags: {
            projects: false,
            tasks: false,
            projectlead: []
        }
    }

    constructor(props) {
        super(props);
        this.handleSubpageAlertChanged = this.handleSubpageAlertChanged.bind( this );
        this.handleSubpageDataLoadedChanged = this.handleSubpageDataLoadedChanged.bind( this );
        this.handleSubpageActionInProgressChanged = this.handleSubpageActionInProgressChanged.bind( this );
    }

    /**
     * OWN HANDLERS
     */

    // Push data loaded changed to parent
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Push alert to parent
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Propagate information to parent component
    handleActionInProgressChanged( actionInProgress ) {
        if( this.props.actionInProgressChanged ) {
            this.props.actionInProgressChanged( actionInProgress );
        }
    }

    /**
     * CALLS FROM SUBPAGES
     */

    // Called by subpage when their data load changed
    handleSubpageDataLoadedChanged( dataLoaded ) {
        this.handleDataLoadedChanged( dataLoaded );
    }

    // Called by subpage when their alert changed
    handleSubpageAlertChanged( alert ) {
        this.handleAlertChanged( alert );
    }

    // Called by subpage when their action in progress changed
    handleSubpageActionInProgressChanged( actionInProgress ) {
        this.handleActionInProgressChanged( actionInProgress );
    }

    /**
     * REACT CALLS
     */

    // Called when the component has mounted
    async componentDidMount() {

        // Get the admin data for the users
        let adminFlags = this.state.adminFlags;

        const { statusCode, statusText, userAdminData } = await this.context.services.admin.getAdminData( this.context.userid );
        if( 200 === statusCode && null != userAdminData ) {
            
            // Gather which items to show from data
            if( true === userAdminData.admin.global ) {
                adminFlags = {
                    projects: true,
                    tasks: true,
                    projectlead: []
                }
            } else {

                adminFlags.projects = userAdminData.admin.projects;
                adminFlags.tasks = userAdminData.admin.tasks;
                adminFlags.projectlead = userAdminData.projectlead;
            }

            // Clear error
            this.handleAlertChanged( { severity: "info", message: "" } );

        } else {
            // Error occured
            this.handleAlertChanged( { severity: "error", message: `${statusText}: ${statusCode}` } );
        }

        // Set the new state of the component
        this.setState({ adminFlagsLoaded: true, adminFlags: adminFlags });
    }

    // React render
    render() {
        let contentSubpage = "";

        if( true === this.state.adminFlagsLoaded ) {

            if( true === this.state.adminFlags.projects || true === this.state.adminFlags.tasks || 0 < this.state.adminFlags.projectlead.length ) {
                contentSubpage = (
                    <AdminTasks
                        args={this.props.args}
                        pageTitle={i18n.t("pages.tasks.title")}
                        pageDesc={i18n.t("pages.tasks.desc")}
                        adminFlags={this.state.adminFlags}
                        dataLoadedChanged={this.handleSubpageDataLoadedChanged} 
                        alertChanged={this.handleSubpageAlertChanged} 
                        actionInProgressChanged={this.handleSubpageActionInProgressChanged}
                    />
                )
            } else {
                contentSubpage = ( 
                    <AdminNoAccess 
                        dataLoadedChanged={this.handleSubpageDataLoadedChanged} 
                        alertChanged={this.handleSubpageAlertChanged} 
                        actionInProgressChanged={this.handleSubpageActionInProgressChanged} 
                    /> 
                );
            }
        }
        
        return <React.Fragment>{contentSubpage}</React.Fragment>;
    }
}

export default withStyles(useStyles)(TasksPage);