import axios from 'axios';
import { config } from '../config.js';
import i18n from 'i18next';

/**
 * Utility service offering access to the API
 */
class ApiService {

    /**
     * Get the current auth header
     */
    getAuthHeader() {
        var accessToken = null;
    
        if( localStorage.getItem('auth') ) {
            var auth = JSON.parse( localStorage.getItem('auth') );
            if( auth.accessToken ) {
                accessToken = auth.accessToken;
            }
        }
    
        if( accessToken ) {
            return { Authorization: `Bearer ${accessToken}` };
        }
    }

    /**
     * Send the given POST request towards the aPI
     * @param {*} request
     * @param {*} checkForRefresh
     * @return { statusCode, statusText, resData }
     */
    async doPost( request, checkForRefresh=false ) {
        if( true === checkForRefresh ) {
            await window.AuthService.refreshIfNeeded()
        }

        try {
            var response = await axios.post( config.apiUrl, request, {
                validateStatus: function(status) {
                    return status < 500;
                },
                headers: this.getAuthHeader(),
                withCredentials: true
            } );

            // Check if query was successful or not
            if( response.status !== 200 && response.status !== 201 ) {

                // Check if we can extract an error message to be displayed
                let errorMsg = "";
                if( null != response.data.errors ) {
                    if( response.data.errors.length > 0 ) {
                        errorMsg = response.data.errors[0].msg;
                    }
                }

                let statusText = response.statusText;
                if( errorMsg !== "" ) {
                    statusText = `${response.statusText}: ${errorMsg}`
                }

                return {
                    statusCode: response.status,
                    statusText: statusText,
                    resData: null
                }
            }

            // POST was successful
            return {
                statusCode: response.status,
                statusText: response.statusText,
                resData: response.data.data
            }

        } catch (err) {
            console.log( err );
            return { 
                statusCode: 500,
                statusText: i18n.t("errors.exception_occured"),
                resData: null 
            }
        }
    }
}

export default new ApiService();