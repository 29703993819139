import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = theme => ({});

/**
 * In progress circular
 */
class InProgress extends Component {
    render() {
        return (
            <Box display="flex" m={3} alignItems="center" justifyContent="center">
                <CircularProgress />
            </Box>
        );
    }
};

export default withStyles(useStyles)(InProgress);