import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 * Also acts as provider for the UserContext
 */
class UserTypeService {

    /**
     * Get user types
     * @param {*} userTypeSearch { typeid, name, is_visible }
     */
    async getUserTypes( userTypeSearch ) {
        var request = {
            query: `
            query getUserTypes($userTypeSearch: UserTypeSearch!) {
                getUserTypes(userTypeSearch: $userTypeSearch) {
                    typeid
                    name
                    is_visible
                    has_users
                }
            }
            `,
            variables: {
                userTypeSearch: userTypeSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Create a new user type
     * @param {*} userTypeInput { typeid, name, is_visible }
     */
    async createUserType( userTypeInput ) {
        var request = {
            query: `
            mutation createUserType($userTypeInput: UserTypeInput) {
                createUserType(userTypeInput: $userTypeInput) {
                    typeid
                    name
                    is_visible
                    has_users
                }
            }
            `,
            variables: {
                userTypeInput: userTypeInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update an user type
     * @param {*} userTypeSearch { typeid, name }
     * @param {*} userTypeUpdate { typeid, name is_visible }
     */
    async updateUserType( userTypeSearch, userTypeUpdate ) {
        var request = {
            query: `
            mutation updateUserType($userTypeSearch: UserTypeSearch!, $userTypeUpdate: UserTypeUpdate!) {
                updateUserType(userTypeSearch: $userTypeSearch, userTypeUpdate: $userTypeUpdate) {
                    typeid
                    name
                    is_visible
                    has_users
                }
            }
            `,
            variables: {
                userTypeSearch: userTypeSearch,
                userTypeUpdate: userTypeUpdate
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete an user type
     * @param {*} userTypeSearch { typeid, name }
     */
    async deleteUserType( userTypeSearch ) {
        var request = {
            query: `
            mutation deleteUserType($userTypeSearch: UserTypeSearch!) {
                deleteUserType(userTypeSearch: $userTypeSearch) {
                    result
                }
            }
            `,
            variables: {
                userTypeSearch: userTypeSearch
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new UserTypeService();
