import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const StyledChipEnabled = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        margin: theme.spacing(0.5),
    },
    deleteIcon: {
        color: "#beddf4",
        '&:hover': {
            color: "#fff"
        }
    }
}))(Chip);

const StyledChipDisabled = withStyles((theme) => ({
    root: {
        backgroundColor: '#64b5f6',
        color: "#fff",
        margin: theme.spacing(0.5)
    },
    deleteIcon: {
        color: "#beddf4",
        '&:hover': {
            color: "#fff"
        }
    }
}))(Chip);

export {
    StyledChipEnabled,
    StyledChipDisabled
}