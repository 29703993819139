import React, { Component } from 'react';
import UserService from '../../classes/UserService'
import UserTypeService from '../../classes/UserTypeService'
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import AuthContext from '../../contexts/AuthContext';
import { StyledTableCell, StyledTableRow, StyledTableHead, StyledTable, StyledTableSortLabel, stableSort, getComparator } from '../../components/StyledTable.js'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../components/PageTitle';
import ModalDialog from '../../components/ModalDialog';
import ModalDialogTextField from '../../components/ModalDialogTextField';
import ModalDialogPassword from '../../components/ModalDialogPassword';
import ModalDialogCheckbox from '../../components/ModalDialogCheckbox';
import Select from '@material-ui/core/Select';
import LockIcon from '@material-ui/icons/Lock';
import CustomTextField from '../../components/CustomTextField'
import CheckboxTooltip from '../../components/CheckboxTooltip'
import SearchControls from '../../components/SearchControls'
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import DateTime from '../../components/DateTime'
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import { Link } from "react-router-dom";
import { config } from '../../config';
import i18n from 'i18next';
import _ from 'lodash'

const useStyles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    mainGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    tableCellUserName: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1.0),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
    },
    tablePaginationGrid: {
        height: '60px',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tablePagination: {
        '& .MuiTablePagination-toolbar': {
            height: '60px',
            minHeight: '60px',
        },
    },
    chipInput: {
        width: 600,
        marginTop: theme.spacing(3.5),
    },
    link: {
        color: "#ffffff",
        "&:hover": {
            color: "#ffffff"
        },
    }
});

/**
 * Admin Users
 */
class AdminUsers extends Component {
    static contextType = AuthContext;

    // Set in constructor
    state = {}

    // Keys to identify the clicked checkbox column
    keys = {
        account_enabled: 1,
        is_admin: 2
    }

    /**
     * Inputs Props
     * @param {*} props 
     *  admin (array)
     *  userProps (array)
     *  dataLoadChanged (function)
     *  alertChanged (function)
     */
    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleChangeTablePage = this.handleChangeTablePage.bind( this );
        this.handleChangeTableRowsPerPage = this.handleChangeTableRowsPerPage.bind( this );
        this.handleChangeSearchField = this.handleChangeSearchField.bind( this );
        this.getDeleteButtonDataForUser = this.getDeleteButtonDataForUser.bind( this );
        this.handleNameFieldChange = this.handleNameFieldChange.bind( this );
        this.verifyNameFieldContent = this.verifyNameFieldContent.bind( this );
        this.handleEmailFieldChange = this.handleEmailFieldChange.bind( this );
        this.verifyEmailFieldContent = this.verifyEmailFieldContent.bind( this );
        this.handleAddButtonClick = this.handleAddButtonClick.bind( this );
        this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind( this );
        this.handleAddDialog_Ok = this.handleAddDialog_Ok.bind( this );
        this.handleAddDialog_Cancel = this.handleAddDialog_Cancel.bind( this );
        this.verifyAddDialogName_Content = this.verifyAddDialogName_Content.bind( this );
        this.verifyAddDialogEmail_Content = this.verifyAddDialogEmail_Content.bind( this );
        this.verifyAddDialogPassword_Content = this.verifyAddDialogPassword_Content.bind( this );
        this.handleLanguageChange = this.handleLanguageChange.bind( this );
        this.handleTableRowCheckboxChange = this.handleTableRowCheckboxChange.bind( this );
        this.handleUserTypeChange = this.handleUserTypeChange.bind( this );
        this.handleTableRowModifyDialog_Cancel = this.handleTableRowModifyDialog_Cancel.bind( this );
        this.handleTableRowModifyDialog_Ok = this.handleTableRowModifyDialog_Ok.bind( this );
        this.verifyModifyDialogPassword_Content = this.verifyModifyDialogPassword_Content.bind( this );
        this.handleTableRowModifyClick = this.handleTableRowModifyClick.bind( this );
        this.handleTableRowDeleteClick = this.handleTableRowDeleteClick.bind( this );
        this.handleRequestSort = this.handleRequestSort.bind( this );
        this.sortTableData = this.sortTableData.bind( this );

        // Set initial state
        this.state = this.getInitialState();
    }

    /**
     * STATE HANDLING
     */

    // Get the initial state variables of this component
    getInitialState() {
        return {
            data: { // Raw loaded data from the backend
                users: null,
                userTypes: null
            },
            tableData: { // Additional information gathered from loaded raw data to display on the page
                deleteButtonsForUser: [] // If the delete button is enabled and which tooltip is shown for that user
            },
            searchFieldEntry: "",
            tablePage: 0,
            tableRowsPerPage: 10,
            order: "asc",
            orderBy: 0,
            addDialog: {
                open: false
            },
            deleteDialog: {
                open: false,
                userid: null,
                firstname: null,
                lastname: null
            },
            modifyDialog: {
                open: false,
                userid: null
            },
        }
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState() {
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
        this.setState( this.getInitialState() );
    }

    /**
     * LINK TO PARENT COMPONENT FUNCTIONS
     */

    // Propagate information to parent component
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Propagate information to parent component
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Propagate information to parent component
    handleActionInProgressChanged( actionInProgress ) {
        if( this.props.actionInProgressChanged ) {
            this.props.actionInProgressChanged( actionInProgress );
        }
    }

    /**
     * LOAD AND PROCESS BACKEND DATA
     */

    // Called when a refresh of the table data is needed due to edits in the table
    async refreshTableData() {
        await this.loadDataFromBackend( true );
    }

    // Gather all data from the backend needed on this page and process them properly
    async loadDataFromBackend( manualRefresh ) {
        // Alert message to show
        let alert = {
            severity: "info",
            message: ""
        }

        let userData = null;
        let userTypeData = null;

        // Gather user data
        {
            const { statusCode, statusText, resData } = await UserService.getUsers( {} );
            if( 200 === statusCode && null != resData ) {
                userData = resData.getUsers;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather user type data
        {
            const { statusCode, statusText, resData } = await UserTypeService.getUserTypes( {} );
            if( 200 === statusCode && null != resData ) {
                userTypeData = resData.getUserTypes;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Now process the data
        await this.processData( userData, userTypeData );

        // All fine, clear the error message (only if not a manual refresh)
        if( false === manualRefresh ) {
            this.handleAlertChanged( alert );
        }
        this.handleDataLoadedChanged( true );
    }

    // Called to make additional processing of data, for example gathering info from that backend data and storing it for later use
    async processData( userData, userTypeData ) {

        // Abort if no proper data given
        if( null == userData || null == userTypeData ) {
            return;
        }

        // Get the info from userData if there are currently any other links attached
        // and enable/disable the delete button accordingly
        let deleteButtonsArray = [];

        for( let i = 0; i < userData.length; i++ ) {

            let deleteButtonElement = {
                userid: userData[i].userid,
                enabled: false,
                tooltip: i18n.t("pages.admin.users.delete.button.tooltip.disabled"),
                links: []
            }

            if( false === userData[i].has_contracts && false === userData[i].has_absences &&
                false === userData[i].has_accountings && false === userData[i].has_roles &&
                false === userData[i].has_teams && false === userData[i].has_projects &&
                false === userData[i].has_tasks ) {
                deleteButtonElement.enabled = true;
                deleteButtonElement.tooltip = i18n.t("pages.admin.users.delete.button.tooltip.enabled");
            } else {

                // Attached links detected, save them for later to display them to the user
                if( true === userData[i].has_contracts ) { deleteButtonElement.links.push( i18n.t("pages.admin.users.delete.button.tooltip.links.contracts") ) };
                if( true === userData[i].has_absences ) { deleteButtonElement.links.push( i18n.t("pages.admin.users.delete.button.tooltip.links.absences") ) };
                if( true === userData[i].has_accountings ) { deleteButtonElement.links.push( i18n.t("pages.admin.users.delete.button.tooltip.links.accountings") ) };
                if( true === userData[i].has_roles ) { deleteButtonElement.links.push( i18n.t("pages.admin.users.delete.button.tooltip.links.roles") ) };
                if( true === userData[i].has_teams ) { deleteButtonElement.links.push( i18n.t("pages.admin.users.delete.button.tooltip.links.teams") ) };
                if( true === userData[i].has_projects ) { deleteButtonElement.links.push( i18n.t("pages.admin.users.delete.button.tooltip.links.projects") ) };
                if( true === userData[i].has_tasks ) { deleteButtonElement.links.push( i18n.t("pages.admin.users.delete.button.tooltip.links.tasks") ) };
            }

            deleteButtonsArray[i] = deleteButtonElement;
        }

        // Store the data
        this.setState({ 
            data: { users: userData, userTypes: userTypeData },
            tableData: { deleteButtonsForUser: deleteButtonsArray } 
        });
    }

    /**
     * CALLBACKS FOR TABLE ELEMENTS
     */

    // Called when a checkbox of an existing user is clicked within the table
    async handleTableRowCheckboxChange(event, userid, keyid) {

        // Update the role
        if( null != userid && null != keyid ) {
            let userUpdate;
            if( keyid === this.keys.account_enabled ) {
                userUpdate = { account_enabled: event.target.checked }
            } else if( keyid === this.keys.is_admin ) {
                userUpdate = { is_admin: event.target.checked }
            }

            const { statusCode, statusText, resData } = await UserService.updateUser( { userid: userid }, userUpdate );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t( "pages.admin.users.alert.success.update_flag" );
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    /**
     * TEXT FIELDS FOR FIRST NAME
     */

    // Handle when a name text field is changed
    async handleNameFieldChange(event, userid, newValue) {
        event.stopPropagation();

        // Name comes in in a certain format <lastname>, <firstnames>
        // The last whitespace in the name defines the end of the lastname area
        let firstname = "";
        let lastname = "";
        if( newValue != null && newValue !== "" ) {
            let nameArray = newValue.split( "," );
            lastname = nameArray[0].trim();
            firstname= nameArray[1].trim();
        }

        // Update the user
        if( null != userid ) {
            const { statusCode, statusText, resData } = await UserService.updateUser( { userid: userid }, { firstname: firstname, lastname: lastname } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.users.alert.success.update_name");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called by the custom text field displaying the names to verify their content
    verifyNameFieldContent( textFieldUserId, textFieldValue, checkAddUserFormat, checkModifyUserFormat ) {
        let result = {
            state: false,
            msg: i18n.t("pages.admin.users.alert.error.name_empty")
        }
        
        if( null != textFieldValue ) {
            if( textFieldValue !== "" ) {
                /* eslint-disable */
                // Check for any weird characters (no comma allowed during add, but required during modify)
                var addUserFormat = /[`!@#$%^&*+\-=\[\]{};':"\\|,.<>\/?~]/;
                var modifyUserFormat = /[`!@#$%^&*+\-=\[\]{};':"\\|.<>\/?~]/;
                var modifyUserFormatComma = /[,]/;
                if( true === checkAddUserFormat && true === addUserFormat.test(textFieldValue) ) { 
                    result = {
                        state: false,
                        msg: i18n.t("pages.admin.users.alert.error.name_invalid")
                    }
                } else if( true === checkModifyUserFormat && true === modifyUserFormat.test(textFieldValue) ) {
                    result = {
                        state: false,
                        msg: i18n.t("pages.admin.users.alert.error.name_invalid")
                    }
                } else if( true === checkModifyUserFormat && false === modifyUserFormatComma.test(textFieldValue) ) {
                    result = {
                        state: false,
                        msg: i18n.t("pages.admin.users.alert.error.name_invalid")
                    }
                } else {

                    // Check if user with the given name already exists
                    const { exists, userid } = this.doesUserExist( textFieldValue, null );
                    if( true === exists && textFieldUserId !== userid ) {
                        result = {
                            state: false,
                            msg: i18n.t("pages.admin.users.alert.error.user_does_already_exist")
                        }
                    } else {
                        result = {
                            state: true,
                            msg: ""
                        }
                    }
                }
            }
        }
        return result;
    }

    /**
     * TEXT FIELDS FOR EMAIL
     */

    // Handle when a email text field is changed
    async handleEmailFieldChange(event, userid, newValue) {
        event.stopPropagation();

        // Update the user
        if( null != userid ) {
            const { statusCode, statusText, resData } = await UserService.updateUser( { userid: userid }, { email: newValue } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.users.alert.success.update_email");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called by the custom text field displaying the email to verify their content
    verifyEmailFieldContent( textFieldUserId, textFieldValue ) {
        let result = {
            state: false,
            msg: i18n.t("pages.admin.users.alert.error.email_empty")
        }
        
        if( null != textFieldValue ) {
            if( textFieldValue !== "" ) {
                const { exists, userid } = this.doesUserExist( null, textFieldValue );
                if( true === exists && textFieldUserId !== userid ) {
                    result = {
                        state: false,
                        msg: i18n.t("pages.admin.users.alert.error.user_does_already_exist")
                    }
                } else {
                    result = {
                        state: true,
                        msg: ""
                    }
                }

                // Verify email format
                if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(textFieldValue)) { 
                    result = {
                        state: false,
                        msg: i18n.t("pages.admin.users.alert.error.email_invalid")
                    }
                }
            }
        }
        return result;
    }


    /**
     * ALERT DIALOG <MODIFY PASSWORD>
     */

    // Called to open the modify dialog
    openTableRowModifyDialog( userid ) {
        let modifyDialog = {
            open: true,
            userid: userid
        }
        this.setState({ modifyDialog: modifyDialog });
    }

    // Called when user clicked on modify in row
    handleTableRowModifyClick( event, userid ) {
        event.stopPropagation();
        this.openTableRowModifyDialog( userid );
    }

    // Called when the user clicked "Ok" on the confirm modify dialog
    async handleTableRowModifyDialog_Ok( userid, content ) {
        this.closeTableRowModifyDialog();

        // Check data validity
        if( content == null || content.length < 2 ) {
            return;
        }

        if( content[0].passwordNew == null || content[0].passwordNew === "" ) {
            return;
        }
        if( content[1].checked == null ) {
            return;
        }

        const { statusCode, statusText } = await UserService.updateUser( { userid: userid }, { password: content[0].passwordNew, password_change_on_next_login: content[1].checked } ); 

        let alert = {
            severity: "info",
            message: ""
        }

        if( 200 === statusCode ) {
            alert.severity = "success";
            alert.message = i18n.t( "pages.admin.users.alert.success.update_password" );
        } else {
            alert.severity = "error";
            alert.message = `${statusText}: ${statusCode}`;
        }

        this.handleAlertChanged( alert );

        // Refresh the table data
        await this.refreshTableData();
    }

    // Verify contents
    verifyModifyDialogPassword_Content( content ) {
        let result = {
            stateNew: false,
            stateConfirm: false,
            msgNew: i18n.t("pages.admin.users.alert.error.new_password_empty"),
            msgConfirm: i18n.t("pages.admin.users.alert.error.confirm_password_empty")
        }

        // Check validty
        if( null != content && null != content.passwordNew && null != content.passwordConfirm ) {

            if( content.passwordNew === "" ) {
                result.msgNew = i18n.t("pages.admin.users.alert.error.new_password_empty");
            } else if ( content.passwordNew.length < config.userPassword.minLength ) {
                result.msgNew = i18n.t("pages.admin.users.alert.error.password_min_length", { minLength: config.userPassword.minLength });
            } else {
                result.stateNew = true;
                result.msgNew = "";
            }

            if( content.passwordConfirm === "" ) {
                result.msgConfirm = i18n.t("pages.admin.users.alert.error.confirm_password_empty");
            } else if ( content.passwordConfirm.length < config.userPassword.minLength ) {
                result.msgConfirm = i18n.t("pages.admin.users.alert.error.password_min_length", { minLength: config.userPassword.minLength });
            } else {
                result.stateConfirm = true;
                result.msgConfirm = "";
            }

            if( true === result.stateNew && true === result.stateConfirm ) {
                if( content.passwordNew !== content.passwordConfirm ) {
                    result.stateNew = false;
                    result.msgNew = i18n.t("pages.admin.users.alert.error.passwords_no_match");
                    result.stateConfirm = false;
                    result.msgConfirm = i18n.t("pages.admin.users.alert.error.passwords_no_match");
                }
            }
        }
        return result;
    }


    // Called when the user clicked "Cancel" or close on the confirm special modify dialog
    handleTableRowModifyDialog_Cancel() {
        this.closeTableRowModifyDialog();
    }

    // Close the modify alert dialog
    closeTableRowModifyDialog() {
        let modifyDialog = {
            open: false,
            userid: this.state.modifyDialog.userid
        }
        this.setState({ modifyDialog: modifyDialog });
    }

    // Called when a delete button of an existing user is clicked within the table
    handleTableRowDeleteClick(event, userid, firstname, lastname) {
        event.stopPropagation();
        this.openTableRowDeleteDialog( userid, firstname, lastname );
    }

    /**
     * ALERT DIALOG <ADD>
     */

    // Called to open the add dialog
    openAddDialog() {
        let addDialog = {
            open: true
        }
        this.setState({ addDialog: addDialog });
    }

    // Called when the user clicked "Ok" on the add user dialog
    async handleAddDialog_Ok( content ) {
        this.closeAddDialog();

        // Check data validity
        if( content == null || content.length < 4 ) {
            return;
        }

        if( content[0].value == null || content[0].value === "" ) {
            return;
        }
        if( content[1].value == null || content[1].value === "" ) {
            return;
        }
        if( content[2].passwordNew == null || content[2].passwordNew === "" ) {
            return;
        }
        if( content[3].checked == null ) {
            return;
        }

        // Name comes in in a certain format <firstname> <lastname>
        // The last whitespace in the name defines the end of the firstname area
        let firstname = "";
        let lastname = "";
        {
            let nameArray = content[0].value.split( " " );
            lastname = nameArray[nameArray.length-1];
            for( let i = 0; i < nameArray.length-1; i++ ) {
                if( firstname !== "" ) {
                    firstname = `${firstname} ${nameArray[i]}`;
                } else {
                    firstname = nameArray[i];
                }
            }
        }

        const { statusCode, statusText } = await UserService.createUser( { 
            email: content[1].value,
            firstname: firstname, 
            lastname: lastname, 
            password: content[2].passwordNew,
            account_enabled: true,
            is_admin: false,
            language: config.frontendFallbackLanguage,
            password_change_on_next_login: content[3].checked 
        } );

        let alert = {
            severity: "info",
            message: ""
        }

        if( 200 === statusCode ) {
            alert.severity = "success";
            alert.message = i18n.t( "pages.admin.users.alert.success.add" );
        } else {
            alert.severity = "error";
            alert.message = `${statusText}: ${statusCode}`;
        }

        this.handleAlertChanged( alert );

        // Refresh the table data
        await this.refreshTableData();
    }

    // Called when the user clicked "Cancel" or close on the add role dialog
    handleAddDialog_Cancel() {
        this.closeAddDialog();
    }

    // Called by the UserAdd child component to verify its content
    verifyAddDialogName_Content( content ) {
        return this.verifyNameFieldContent( 0, content.value, true, false );
    }

    // Called by the UserAdd child component to verify its content
    verifyAddDialogEmail_Content( content ) {
        return this.verifyEmailFieldContent( 0, content.value );
    }

    // Called by the UserAdd child component to verify its content
    verifyAddDialogPassword_Content( content ) {
        return this.verifyModifyDialogPassword_Content( content );
    }

    // Close the modify alert dialog
    closeAddDialog() {
        let addDialog = {
            open: false
        }
        this.setState({ addDialog: addDialog });
    }

    /**
     * ALERT DIALOG <DELETE>
     */

    // Called to open the delete dialog
    openTableRowDeleteDialog( userid, firstname, lastname ) {
        let deleteDialog = {
            open: true,
            userid: userid,
            firstname: firstname,
            lastname: lastname
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    // Called when the user clicked "Ok" on the confirm row delete dialog
    async handleTableRowDeleteDialog_Ok( userid ) {
        this.closeTableRowDeleteDialog();

        // Delete the role
        if( null != userid ) {
            const { statusCode, statusText, resData } = await UserService.deleteUser( { userid: userid } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                if( true === resData.deleteUser.result ) {
                    alert.severity = "success";
                    alert.message = i18n.t( "pages.admin.users.alert.success.delete" );
                } else {
                    alert.severity = "error";
                    alert.message = `${statusText}: ${statusCode}`;
                }
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the confirm row delete dialog
    async handleTableRowDeleteDialog_Cancel() {
        this.closeTableRowDeleteDialog();
    }

    // Close the delete alert dialog
    closeTableRowDeleteDialog() {
        let deleteDialog = {
            open: false,
            userid: this.state.deleteDialog.userid,
            firstname: this.state.deleteDialog.firstname,
            lastname: this.state.deleteDialog.lastname
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    /**
     * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
     */

    // Called by react when this component has been mounted
    async componentDidMount() {
        await this.loadDataFromBackend();
    }

    // Called by react before this component unmounts
    componentWillUnmount() {
        this.resetState();
    }

    /**
     * CALLBACK FUNCTIONS FOR SEARCH FIELD
     */

    // Called when text is entered in the search field
    handleChangeSearchField(value) {
        this.setState({ searchFieldEntry: value });
    }

    /**
     * TABLE PAGINATION EVENT HANDLERS
     */

    // Called if page changes on the table display occur
    handleChangeTablePage = (event, newPage) => {
        this.setState({tablePage: newPage});
    };
    
    // Called if the user selects a different amount of shown rows
    handleChangeTableRowsPerPage = (event) => {
        this.setState({tablePage: 0, tableRowsPerPage: +event.target.value});
    };

    /**
     * LANGUAGE CHANGE
     */
    async handleLanguageChange(event, userid) {
        event.stopPropagation();

        // Update the user
        if( null != userid ) {
            const language = event.target.value;

            const { statusCode, statusText, resData } = await UserService.updateUser( { userid: userid }, { language: language } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.users.alert.success.update_language");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    /**
     * USER TYPE CHANGE
     */
    async handleUserTypeChange(event, userid) {
            event.stopPropagation();
    
            // Update the user
            if( null != userid ) {
                const typeid = event.target.value;
    
                const { statusCode, statusText, resData } = await UserService.updateUser( { userid: userid }, { typeid: typeid } );
    
                let alert = {
                    severity: "info",
                    message: ""
                }
    
                if( 200 === statusCode && null != resData ) {
                    alert.severity = "success";
                    alert.message = i18n.t("pages.admin.users.alert.success.update_usertype");
                } else {
                    alert.severity = "error";
                    alert.message = `${statusText}: ${statusCode}`;
                }
    
                this.handleAlertChanged( alert );
    
                // Refresh the table data
                await this.refreshTableData();
            }
        }

    /**
     * TOP ROW BUTTONS
     */

    // Called if the add button is clicked
    async handleAddButtonClick() {
        this.openAddDialog();
    }

    // Called if the refresh button is clicked
    async handleRefreshButtonClick() {
        this.resetState();
        await this.refreshTableData();
    }

    /**
     * HELPERS CALLED DURING RENDER TO RETRIEVE INFO FROM PROCESSED DATA
     */

    // Get delete button info for a role
    getDeleteButtonDataForUser( userid ) {
        // Retrieve info from array
        for( let i = 0; i < this.state.tableData.deleteButtonsForUser.length; i++ ) {
            if( this.state.tableData.deleteButtonsForUser[i].userid === userid ) {
                return this.state.tableData.deleteButtonsForUser[i];
            }
        }
        // Fallback empty data
        return { userid: userid, enabled: false, tooltip: i18n.t("pages.admin.users.delete.button.tooltip.disabled"), links: [] };
    }

    /// Check if a certain name and/or email already exists, expects the fullname to be given in the syntax <firstname> <lastname> without any comma
    doesUserExist( fullname, email ) {
        let result = {
            exists: false,
            userid: 0
        }

        // Retrieve info from array
        if( null != this.state.data.users ) {
            let users = this.state.data.users;

            for( let i = 0; i < users.length; i++ ) {

                // Check for email if given
                let emailMatch = false;
                if( null !== email ) {
                    if( users[i].email === email ) {
                        emailMatch = true;
                    }
                } else {
                    emailMatch = true;
                }

                // Check for name if given
                let nameMatch = false;
                if( null !== fullname ) {
                    const userFullname = `${users[i].firstname} ${users[i].lastname}`;
                    if( userFullname === fullname ) {
                        nameMatch = true;
                    }
                } else {
                    nameMatch = true;
                }

                if( true === emailMatch && true === nameMatch ) {
                    result.exists = true;
                    result.userid = users[i].userid;
                    break;
                }
            }
        }

        return result;
    }

    /**
     * DATA SORT
     */

    // Handle table sort
    handleRequestSort( event, property ) {

        // Check if clicked on same property
        if( this.state.orderBy === property ) {

            // User clicked on same property
            // If already sorted in ascending order, switch to descending order
            if( this.state.order === 'asc' ) {
                this.setState({ order: 'desc', orderBy: property });
            } else {

                // If already sorted in descending order, switch to no order
                this.setState({ order: 'asc', orderBy: 0 });
            }

        } else {
            // User clicked on new property, sort new property in ascending order
            this.setState({ order: 'asc', orderBy: property });
        }
    };

    // Sort the given table data
    sortTableData( users ) {
        // Handle case where no ordering is selected
        if( this.state.orderBy === 0 ) {
            return users.sort(function(a, b) { return a.lastname > b.lastname ? 1 : -1; });
        }
        return stableSort( users, getComparator(this.state.order, this.state.orderBy) );
    }

    // Render page
    render() {
        const { classes } = this.props;

        // Only display data if it has already been loaded
        let contentTable = "";

        // Make sure if we got the data already
        if( null != this.state.data.users && null != this.state.data.userTypes ) {

            // Assemble table content
            const tableHeader = [
                { key: "lastname", span: 1, align: "left", sortable: true, style: {}, text: i18n.t("pages.admin.users.table.header.fullname") },
                { key: "email", span: 1, align: "left", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.users.table.header.email") },
                { key: "account_enabled", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "5px" }, text: i18n.t("pages.admin.users.table.header.account_enabled") },
                { key: "is_admin", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "5px" }, text: i18n.t("pages.admin.users.table.header.is_admin") },
                { key: "typeid", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "5px" }, text: i18n.t("pages.admin.users.table.header.type") },
                { key: "language", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "5px" }, text: i18n.t("pages.admin.users.table.header.language") },
                { key: "last_login", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "5px" }, text: i18n.t("pages.admin.users.table.header.last_login") },
                { key: "password", span: 1, align: "center", sortable: false, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "5px" }, text: i18n.t("pages.admin.users.table.header.password") },
                { key: "controls", span: 1, align: "center", sortable: false, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.users.table.header.controls") }
            ];

            let users = this.state.data.users;

            // Filter table data if any search entry has been given
            if( this.state.searchFieldEntry !== "" ) {
                users = users.filter( user => ( 
                    String(user.lastname.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase()) || 
                    String(user.firstname.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase()) || 
                    String(user.email.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase())
                ));
            }

            // Replace typeid field in users array with the final usertype name to allow for a proper sort
            let usersToSort = [];
            for( let i = 0; i < users.length; i++ ) {
                let user = _.cloneDeep( users[i] );

                let userTypeName = i18n.t("pages.admin.users.table.no_usertype");
                for( let j = 0; j < this.state.data.userTypes.length; j++ ) {
                    if( user.typeid === this.state.data.userTypes[j].typeid ) {
                        userTypeName = this.state.data.userTypes[j].name;
                    }
                }

                user.typeid = userTypeName;
                usersToSort.push( user );
            }

            // Sort table entries by given properties
            const usersSorted = this.sortTableData( usersToSort );

            // Replace usertype name again with the proper typeid after the sort
            let usersSortedForTable = [];

            for( let i = 0; i < usersSorted.length; i++ ) {
                let user = _.cloneDeep( usersSorted[i] );

                let userTypeId = null;
                for( let j = 0; j < this.state.data.userTypes.length; j++ ) {
                    if( user.typeid === this.state.data.userTypes[j].name ) {
                        userTypeId = this.state.data.userTypes[j].typeid;
                    }
                }

                user.typeid = userTypeId;
                usersSortedForTable.push( user );
            }

            // Preload translations
            let tooltips = {
                account_enabled: i18n.t("pages.admin.users.table.tooltips.account_enabled"),
                is_admin: i18n.t("pages.admin.users.table.tooltips.is_admin"),
                password: i18n.t("pages.admin.users.table.tooltips.password"),
                no_usertype_defined: i18n.t("pages.admin.users.table.tooltips.no_usertype_defined"),
            }

            // Determine the base link to the user types
            const linkToUserTypes = "/admin?usertypes";

            contentTable = (
                <div>
                    <TableContainer style={{ minHeight: "430px" }} component={Paper}>
                        <StyledTable size="small" >
                            <StyledTableHead>
                                <StyledTableRow key={"users-header"}>
                                    {tableHeader.map((col) => (
                                        <StyledTableCell 
                                            key={col.key} 
                                            colSpan={col.span} 
                                            align={col.align} 
                                            style={col.style}
                                        >
                                            <StyledTableSortLabel
                                                active={this.state.orderBy === col.key}
                                                hideSortIcon={false}
                                                direction={this.state.orderBy === col.key ? this.state.order : 'asc'}
                                                onClick={(event) => this.handleRequestSort(event, col.key)}
                                                disabled={!(col.sortable)}
                                            >
                                                {col.text}
                                            </StyledTableSortLabel>
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </StyledTableHead>
                            <TableBody>
                                {usersSortedForTable.slice(this.state.tablePage * this.state.tableRowsPerPage, this.state.tablePage * this.state.tableRowsPerPage + this.state.tableRowsPerPage).map((user) => {
                                    const deleteButtonForUser = this.getDeleteButtonDataForUser(user.userid);

                                    // Disable elements if the user entry is for an admin user and the active user is no admin user
                                    let disableElements = false;
                                    if( true === user.is_admin && false === this.props.adminFlags.global ) {
                                        disableElements = true;
                                    }

                                    // Create the select box for the usertype, if no type is available only an icon is shown with an associated helper text
                                    let userTypeSelect = (
                                        <Tooltip title={tooltips.no_usertype_defined}>
                                            <div>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.link}
                                                    component={Link}
                                                    to={linkToUserTypes}
                                                    style={{ height: "30px", width: "45px" }}
                                                >
                                                    <AddIcon />
                                                </Button>
                                            </div>
                                        </Tooltip>
                                    )

                                    if( this.state.data.userTypes.length > 0 ) {
                                        let userTypeId = user.typeid;
                                        if( null == user.typeid ) {
                                            userTypeId = 0;
                                        }
                                        userTypeSelect = (
                                            <Select
                                                id="usertype"
                                                value={userTypeId}
                                                onChange={(event) => this.handleUserTypeChange(event, user.userid)}
                                                name="usertype"
                                            >
                                                <MenuItem key={0} value={0}>{i18n.t("pages.admin.users.table.no_usertype")}</MenuItem>
                                                {this.state.data.userTypes.map((entry) => (
                                                    <MenuItem key={entry.typeid} value={entry.typeid}>{entry.name}</MenuItem>
                                                ))}
                                            </Select>
                                        )
                                    }

                                    return (
                                        <StyledTableRow key={user.userid}>
                                            <StyledTableCell className={classes.tableCellUserName} key="lastname" width="20%">
                                                <CustomTextField
                                                    maxLength={config.maxTextLengths.user.name}
                                                    textFieldValue={user.lastname+", "+user.firstname} 
                                                    verifyContent={(content) => this.verifyNameFieldContent(user.userid, content, false, true)} 
                                                    onApply={(event, newValue) => this.handleNameFieldChange(event, user.userid, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.tableCellUserName} key="email" width="20%">
                                                <CustomTextField 
                                                    maxLength={config.maxTextLengths.user.email}
                                                    textFieldValue={user.email} 
                                                    verifyContent={(content) => this.verifyEmailFieldContent(user.userid, content)} 
                                                    onApply={(event, newValue) => this.handleEmailFieldChange(event, user.userid, newValue)}
                                                    disabled={disableElements}
                                                    forceLowerCase={true}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="account_enabled" align="center" padding="checkbox" width="5%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip 
                                                    tooltip={tooltips.account_enabled} 
                                                    checked={user.account_enabled} 
                                                    onChange={(event) => this.handleTableRowCheckboxChange(event, user.userid, this.keys.account_enabled)} 
                                                    color="primary" 
                                                    name="switch-account-enabled"
                                                    disabled={disableElements}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="is_admin" align="center" padding="checkbox" width="5%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <CheckboxTooltip 
                                                    tooltip={tooltips.is_admin} 
                                                    checked={user.is_admin} 
                                                    onChange={(event) => this.handleTableRowCheckboxChange(event, user.userid, this.keys.is_admin)} 
                                                    color="primary" 
                                                    name="switch-is-admin"
                                                    disabled={disableElements} 
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="usertype" align="center" padding="checkbox" width="5%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                {userTypeSelect}
                                            </StyledTableCell>
                                            <StyledTableCell key="language" align="center" padding="checkbox" width="5%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <Select
                                                    id="language"
                                                    value={user.language}
                                                    onChange={(event) => this.handleLanguageChange(event, user.userid)}
                                                    name="language"
                                                    >
                                                    {config.frontendLanguages.map((entry) => (
                                                        <MenuItem key={entry.id} value={entry.id}>{entry.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </StyledTableCell>
                                            <StyledTableCell key="last_login" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1, minWidth: "15%" }} onClick={(event) => event.stopPropagation()}>
                                                <DateTime language={this.context.language} value={user.last_login} align="center" variant="body1" />
                                            </StyledTableCell>
                                            <StyledTableCell key="password" align="center" padding="checkbox" width="5%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <Tooltip title={tooltips.password}>
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.clearButton}
                                                            onClick={(event) => this.handleTableRowModifyClick(event, user.userid)}
                                                            style={{ height: "30px", width: "45px" }}
                                                            disabled={disableElements}
                                                        >
                                                            <LockIcon />
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                            <StyledTableCell key="delete" align="center" padding="checkbox" width="5%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <Tooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit" variant="caption">{deleteButtonForUser.tooltip}</Typography>
                                                            <Typography color="inherit" variant="caption">
                                                                {deleteButtonForUser.links.map((link, index) => (
                                                                    <div key={index}>* {link}</div>
                                                                ))}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.clearButton}
                                                            disabled={(!(deleteButtonForUser.enabled)) || disableElements}
                                                            onClick={(event) => this.handleTableRowDeleteClick(event, user.userid, user.firstname, user.lastname)}
                                                            style={{ height: "30px", width: "45px" }}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                    <Grid container direction="row" justify="flex-end" className={classes.tablePaginationGrid} spacing={1} wrap='nowrap'>
                        <Grid item>
                            <TablePagination
                                className={classes.tablePagination}
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={users.length}
                                rowsPerPage={this.state.tableRowsPerPage}
                                labelRowsPerPage={i18n.t("table.pagination.rows_per_page")}
                                page={this.state.tablePage}
                                onChangePage={this.handleChangeTablePage}
                                onChangeRowsPerPage={this.handleChangeTableRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                    <ModalDialog 
                        open={this.state.addDialog.open}
                        title={i18n.t("pages.admin.users.add.dialog.title")}
                        descText={i18n.t("pages.admin.users.add.dialog.description")}
                        buttonLeft={i18n.t("pages.admin.users.add.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.users.add.dialog.button_right")}
                        handleClickClose={() => this.handleAddDialog_Cancel()}
                        handleClickLeft={(content) => this.handleAddDialog_Ok(content)}
                        handleClickRight={() => this.handleAddDialog_Cancel()}
                    >
                        <ModalDialogTextField 
                            label={i18n.t("pages.admin.users.add.dialog.field_name_username")} 
                            verifyContent={(content) => this.verifyAddDialogName_Content(content)} 
                            autoFocus={false}
                            maxLength={config.maxTextLengths.user.name}
                        />
                        <ModalDialogTextField 
                            label={i18n.t("pages.admin.users.add.dialog.field_name_email")} 
                            verifyContent={(content) => this.verifyAddDialogEmail_Content(content)}
                            autoFocus={false}
                            maxLength={config.maxTextLengths.user.email}
                            forceLowerCase={true}
                        />
                        <ModalDialogPassword
                            labelNew={i18n.t("pages.admin.users.add.dialog.field_name_password_new")} 
                            labelConfirm={i18n.t("pages.admin.users.add.dialog.field_name_password_confirm")} 
                            verifyContent={this.verifyAddDialogPassword_Content}
                            autoFocus={false}
                        />
                        <ModalDialogCheckbox
                            label={i18n.t("pages.admin.users.add.dialog.field_name_force_password_change")}
                        />
                    </ModalDialog>
                    <ModalDialog 
                        open={this.state.modifyDialog.open}
                        title={i18n.t("pages.admin.users.modify.dialog.title")}
                        descText={i18n.t("pages.admin.users.modify.dialog.description")}
                        buttonLeft={i18n.t("pages.admin.users.modify.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.users.modify.dialog.button_right")}
                        handleClickClose={() => this.handleTableRowModifyDialog_Cancel()}
                        handleClickLeft={(content) => this.handleTableRowModifyDialog_Ok( this.state.modifyDialog.userid, content )}
                        handleClickRight={() => this.handleTableRowModifyDialog_Cancel()}
                        maxWidth={"xs"}
                    >
                        <ModalDialogPassword
                            labelNew={i18n.t("pages.admin.users.modify.dialog.field_name_password_new")} 
                            labelConfirm={i18n.t("pages.admin.users.modify.dialog.field_name_password_confirm")} 
                            verifyContent={this.verifyModifyDialogPassword_Content}
                            autoFocus={false}
                        />
                        <ModalDialogCheckbox
                            label={i18n.t("pages.admin.users.modify.dialog.field_name_force_password_change")}
                        />
                    </ModalDialog>
                    <ModalDialog 
                        open={this.state.deleteDialog.open}
                        title={i18n.t("pages.admin.users.delete.dialog.title")}
                        descText={i18n.t("pages.admin.users.delete.dialog.description", { firstname: this.state.deleteDialog.firstname, lastname: this.state.deleteDialog.lastname })}
                        buttonLeft={i18n.t("pages.admin.users.delete.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.users.delete.dialog.button_right")}
                        handleClickClose={() => this.handleTableRowDeleteDialog_Cancel()}
                        handleClickLeft={() => this.handleTableRowDeleteDialog_Ok( this.state.deleteDialog.userid )}
                        handleClickRight={() => this.handleTableRowDeleteDialog_Cancel()}
                    />
                </div>
            )
        }

        return (
            <React.Fragment>
                <PageTitle title={i18n.t("pages.admin.users.title")} />
                <p>{i18n.t("pages.admin.users.desc")}</p>
                <SearchControls
                    label={i18n.t("pages.admin.users.search.label")}
                    helperText={i18n.t("pages.admin.users.search.subtext")}
                    addTooltip={i18n.t("pages.admin.users.add.button.tooltip")}
                    refreshTooltip={i18n.t("buttons.refresh.tooltip")}
                    onAdd={this.handleAddButtonClick}
                    onRefresh={this.handleRefreshButtonClick}
                    onChange={this.handleChangeSearchField} 
                />
                <div>{contentTable}</div>
            </React.Fragment>
        )
    }
};

export default withStyles(useStyles)(AdminUsers);