import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AuthPage from './pages/AuthPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ForcePasswordPage from './pages/ForcePasswordPage';
import ContainerPage from './pages/ContainerPage';
import MainBar from './components/navigation/MainBar';
import MessageOfTheDay from './components/MessageOfTheDay';
import AuthContext from './contexts/AuthContext';
import MainDrawer from './components/navigation/MainDrawer';
import { changelog } from './changelog';
import { messageoftheday } from './messageoftheday';
import { config } from './config';
import i18n from 'i18next';
import './App.css';

const useStyles = theme => ({
    mainContent: {
        paddingTop: "69px",
        background: theme.palette.background.default
    },
});

const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);
    return (
        React.createElement(component, finalProps)
    );
}

const PropsRoute = ({ component, ...rest }) => {
    return (
        <Route {...rest} render={routeProps => {
            return renderMergedProps(component, routeProps, rest);
        }}/>
    );
}

/**
 * Main App object which gets rendered, also contains the main router functionality
 * as well as all pages to navigate to and all main navigation elements
 */
class AppMain extends Component {
    state = {
        openDrawer: true,
        openMessageOfTheDay: true,
        alert: {
            severity: "info",
            message: ""
        },
    }

    constructor(props) {
        super(props);

        // Check if we should show the latest message of the day or if the user has already seen it
        if( localStorage.getItem('modId') ) {
            var modId = localStorage.getItem('modId');
            if( messageoftheday.length > 0 ) {
                if( messageoftheday[0].id === modId ) {
                    this.state.openMessageOfTheDay = false;
                }
            }
        }

        this.subpageAlertChanged = this.subpageAlertChanged.bind( this );
        this.handleCloseMessageOfTheDay = this.handleCloseMessageOfTheDay.bind( this );
    }

    // Subpage notification: alert changed
    subpageAlertChanged( alert ) {
        this.setState( { alert: alert } );

        if( alert.message === "" ) {
            this.setState({ alert: alert });
        } else {
            // Since we have an alert message to display, make sure to remove it after a few seconds
            this.setState({ alert: alert });
            setTimeout(() => {
                this.setState({ alert: { severity: "info", message: "" } });
            }, 3000 );
        }
    }

    // Close the message of the day dialog
    handleCloseMessageOfTheDay( id ) {

        // Remember that the user has already seen this message
        localStorage.setItem( 'modId', id );

        this.setState({ openMessageOfTheDay: false });
    }

    // Called when the left hand drawer is toggled open and close
    onToggleDrawer = () => {
        this.setState( { openDrawer: !this.state.openDrawer } );
    };

    render() {
        const { classes } = this.props;

        // Set main target URL if only "/" is provided, only used for autologin config
        let targetBaseUrl = config.targetBaseUrl;
        if( true === config.autoLogin.enabled ) {
            targetBaseUrl = config.autoLogin.targetBaseUrl;
        }

        // Memorize certain elements due to performance reasons
        const MainBarMemo = React.memo( MainBar );

        // Assemble content to show when authenticated
        let contentAuthenticated = (
            <React.Fragment>
                <MainBarMemo onToggleDrawer={this.onToggleDrawer} pageTitle={config.mainTitle} alert={this.state.alert} />
                <MainDrawer open={this.state.openDrawer} onToggleDrawer={this.onToggleDrawer} />
                <div className={classes.mainContent}>
                    <Switch>
                        <Redirect from="/" to={targetBaseUrl} exact />
                        <Redirect from="/auth" to={targetBaseUrl} exact />
                        <PropsRoute path='/main' component={ContainerPage} isDrawerOpen={this.state.openDrawer} alertChanged={this.subpageAlertChanged} />
                        <PropsRoute path='/profile' component={ContainerPage} isDrawerOpen={this.state.openDrawer} alertChanged={this.subpageAlertChanged} />
                        <PropsRoute path='/account' component={ContainerPage} isDrawerOpen={this.state.openDrawer} alertChanged={this.subpageAlertChanged} />
                        <PropsRoute path='/logout' component={ContainerPage} isDrawerOpen={this.state.openDrawer} alertChanged={this.subpageAlertChanged} />
                        <PropsRoute path='/about' component={ContainerPage} isDrawerOpen={this.state.openDrawer} alertChanged={this.subpageAlertChanged} />
                        <PropsRoute path='/absence' component={ContainerPage} isDrawerOpen={this.state.openDrawer} alertChanged={this.subpageAlertChanged} />
                        <PropsRoute path='/projects' component={ContainerPage} isDrawerOpen={this.state.openDrawer} alertChanged={this.subpageAlertChanged} />
                        <PropsRoute path='/tasks' component={ContainerPage} isDrawerOpen={this.state.openDrawer} alertChanged={this.subpageAlertChanged} />
                        <PropsRoute path='/reports' component={ContainerPage} isDrawerOpen={this.state.openDrawer} alertChanged={this.subpageAlertChanged} />
                        <PropsRoute path='/admin*' component={ContainerPage} isDrawerOpen={this.state.openDrawer} alertChanged={this.subpageAlertChanged} />
                    </Switch>
                </div>
            </React.Fragment>
        );

        // Assemble content to show when not authenticated
        let contentNotAuthenticated = (
            <div className={classes.mainContent}>
                <Switch>
                    <PropsRoute path='/auth' component={AuthPage} />
                    <PropsRoute path='/resetpassword' component={ResetPasswordPage} />
                    <Redirect to="/auth" exact />
                </Switch>
            </div>
        );

        // Assemble content in case user is forced to change password on next login
        let contentForcePasswordChange = (
            <div className={classes.mainContent}>
                <Switch>
                    <PropsRoute path='/forcepassword' component={ForcePasswordPage} />
                    <Redirect to="/forcepassword" exact />
                </Switch>
            </div>
        );

        return (
            <AuthContext.Consumer>
                {authContext => {
                    const isAuthenticated = authContext.isAuthenticated();
                    return (
                        <React.Fragment>
                            {(isAuthenticated && authContext.passwordChangeOnNextLogin) && (
                                <React.Fragment>
                                    {contentForcePasswordChange}
                                </React.Fragment>
                            )}
                            {(isAuthenticated && !authContext.passwordChangeOnNextLogin) && (
                                <React.Fragment>
                                    {contentAuthenticated}
                                    {(null != messageoftheday && messageoftheday.length > 0) && (
                                        <MessageOfTheDay 
                                            open={this.state.openMessageOfTheDay}
                                            title={i18n.t("message_of_the_day.title")}
                                            maxWidth="md"
                                            dialogMessagesOfTheDay={messageoftheday}
                                            dialogChangeLog={changelog}
                                            buttonText={i18n.t("message_of_the_day.button_close")}
                                            handleClose={this.handleCloseMessageOfTheDay}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                            {!isAuthenticated && (
                                <React.Fragment>
                                    {contentNotAuthenticated}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    );
                }}
            </AuthContext.Consumer>
        )
    }
}

export default withStyles(useStyles)(AppMain);