import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ChartAbsence from './ChartAbsence';
import TableAbsence from './TableAbsence';
import ChartAbsenceMonth from './ChartAbsenceMonth';
import TableAbsenceMonth from './TableAbsenceMonth';
import Grid from '@material-ui/core/Grid';
import CalendarHelper from '../classes/CalendarHelper.js';
import { toJSONLocalDate } from '../helpers.js';
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Report Absence Company
 */
class ReportAbsenceCompany extends Component {
    state = {}

    constructor(props) {
        super(props);

        // References to call into child
        this.tableChildRef = React.createRef();
    }

    /**
     * EXPORTS
     */

    // Export to CSV
    onCsvExport( absenceReport, absences, specials, contracts, holidays, year, month ) {

        // ### If year is given but no month:
        // Header:
        // Report Company Absences for <year>,<date>,<time>
        // Month,[per Absence Reason: <R> days in month]
        // Then one line per month containing the given information

        // ### If year and month are given:
        // Header:
        // Report Company Absences for <year>-<month>,<date>,<time>
        // Date,Day,Is Weekend,Is Holiday,Holiday Name,Absent Employees,Present Employees,Total Employees
        // Then one line per day containing the given information

        let csvData = [];
        let csvFilename = "";

        if( null == month || month === "" ) {

            // Only year has been given
            const monthNames = CalendarHelper.getMonthNames( this.props.language, "short" );
            const today = new Date();
            const dateString = toJSONLocalDate( today );
            const timeString = today.getHours().toString() + ":" + today.getMinutes().toString() + ":" + today.getSeconds().toString();

            const range = year;
            csvFilename = i18n.t("pages.reports.options.absence.export_company.filename", { range: range });

            csvData.push( [ i18n.t("pages.reports.options.absence.export_company.headline", { range: range }), dateString, timeString ] );

            let headerSecond = [];
            headerSecond.push( i18n.t("pages.reports.options.absence.export_company.month") );

            this.props.specials.sort(function(a, b) { return a.name > b.name ? 1 : -1; });

            for( let i = 0; i < this.props.specials.length; i++ ) {
                headerSecond.push( i18n.t("pages.reports.options.absence.export_company.booked", { name: this.props.specials[i].name } ) );
            }
            csvData.push( headerSecond );

            const summary = this.tableChildRef.current.getSummary( absenceReport, this.props.specials );

            // Go through all months
            for( let i = 0; i < monthNames.length; i++ ) {

                // Get summary from child table for each year
                for( let j = 0; j < summary.length; j++ ) {
                    if( year !== summary[j].year ) {
                        continue;
                    }
        
                    // Convert summary into csv
                    let entry = [];
                    entry.push( monthNames[i] );

                    for( let k = 0; k < summary[j].months.length; k++ ) {
                        if( summary[j].months[k].month !== (i+1) ) {
                            continue;
                        }
                        summary[j].months[k].absences.sort(function(a, b) { return a.special.name > b.special.name ? 1 : -1; });
                        for( let l = 0; l < summary[j].months[k].absences.length; l++ ) {
                            entry.push( summary[j].months[k].absences[l].days_absent_in_month );
                        }
                    }
                    
                    csvData.push( entry );
                }
            }

        } else {

            // Month and year have been given
            const today = new Date();
            const dateString = toJSONLocalDate( today );
            const timeString = today.getHours().toString() + ":" + today.getMinutes().toString() + ":" + today.getSeconds().toString();

            let monthString = month;
            if( month < 10 ) { monthString = `0${month}`; }

            const range = `${year}-${monthString}`;
            csvFilename = i18n.t("pages.reports.options.absence.export_company.filename", { range: range });

            csvData.push( [ i18n.t("pages.reports.options.absence.export_company.headline", { range: range }), dateString, timeString ] );

            let headerSecond = [];
            headerSecond.push( i18n.t("pages.reports.options.absence.export_company.date") );
            headerSecond.push( i18n.t("pages.reports.options.absence.export_company.day") );
            headerSecond.push( i18n.t("pages.reports.options.absence.export_company.is_weekend") );
            headerSecond.push( i18n.t("pages.reports.options.absence.export_company.is_holiday") );
            headerSecond.push( i18n.t("pages.reports.options.absence.export_company.holiday_name") );
            headerSecond.push( i18n.t("pages.reports.options.absence.export_company.absent_employees") );
            headerSecond.push( i18n.t("pages.reports.options.absence.export_company.present_employees") );
            headerSecond.push( i18n.t("pages.reports.options.absence.export_company.total_employees") );

            csvData.push( headerSecond );

            const summary = this.tableChildRef.current.getSummary( absences, contracts, holidays, year, month );

            // Go through all days
            for( let i = 0; i < summary.length; i++ ) {
                let entry = [];

                const date = new Date( year, month-1, i+1 );
                const dateString = toJSONLocalDate( date );

                entry.push( dateString );
                entry.push( summary[i].day_name );
                if( true === summary[i].is_weekend ) {
                    entry.push( "true" );
                } else {
                    entry.push( "false" );
                }
                if( true === summary[i].is_holiday ) {
                    entry.push( "true" );
                } else {
                    entry.push( "false" );
                }
                entry.push( summary[i].holiday_name );
                entry.push( summary[i].absent_employees );
                entry.push( summary[i].present_employees );
                entry.push( summary[i].total_employees );

                csvData.push( entry );
            }
        }
        
        return { csvFilename, csvData };
    }

    /**
	 * RENDER FUNCTIONS
	 */

    render() {
        // INPUTS: language, year, month, absences, absenceReport, specials, contracts, holidays
        let absenceChart = "";
        let absenceTable = "";
        
        if( "" !== this.props.year && "" === this.props.month && null != this.props.absenceReport && null != this.props.specials && null != this.props.contracts ) {
            absenceChart = (
                <ChartAbsence 
                    language={this.props.language}
                    year={this.props.year}
                    absenceReport={this.props.absenceReport}
                    specials={this.props.specials}
                    userid={this.props.userid}
                />
            )
            absenceTable = (
                <TableAbsence
                    ref={this.tableChildRef}
                    language={this.props.language}
                    year={this.props.year}
                    specials={this.props.specials}
                    absenceReport={this.props.absenceReport}
                    contracts={this.props.contracts}
                />
            )
        } else if( "" !== this.props.month && null != this.props.absences && null != this.props.specials && null != this.props.contracts ) {
            absenceChart = (
                <ChartAbsenceMonth
                    language={this.props.language}
                    year={this.props.year}
                    month={this.props.month}
                    absences={this.props.absences}
                    specials={this.props.specials}
                    contracts={this.props.contracts}
                    holidays={this.props.holidays}
                    userid={0}
                />
            )
            absenceTable = (
                <TableAbsenceMonth
                    ref={this.tableChildRef}
                    language={this.props.language}
                    year={this.props.year}
                    month={this.props.month}
                    specials={this.props.specials}
                    absences={this.props.absences}
                    contracts={this.props.contracts}
                    holidays={this.props.holidays}
                />
            )
        }

        return (
            <Grid item container justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={6}>
                    {absenceChart}
                </Grid>
                <Grid item xs={6}>
                    {absenceTable}
                </Grid>
            </Grid>
        );
    }
};

export default withStyles(useStyles)(ReportAbsenceCompany);