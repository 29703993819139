import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import i18n from 'i18next';

const useStyles = theme => ({
    formControl: {
        marginTop: theme.spacing(1.75),
        marginBottom: theme.spacing(1.5),
        minWidth: '40ch',
        width: "100%"
    }
});

/**
 * Modal Dialog Select Component
 */
class ModalDialogSelect extends Component {
    state = {
        value: i18n.t("values.none"),
        contentVerified: false
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind( this );

        if( null != this.props.value ) {
            this.state.value = this.props.value;
        }
    }

    // Determine the button state
    getButtonState( value, contentVerified ) {
        let required = true;
        if( null != this.props.required ) {
            required = this.props.required;
        }

        if( ( !required || value !== "" ) && true === contentVerified ) {
            return true;
        } else {
            return false;
        }
    }

    // General push of new data to parent
    updateControlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent ) {
        if( null != this.props.controlButtonsAndPushContent ) {
            this.props.controlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent );
        }
    }

    // Name text field changed
    handleChange(event) {
        let value = event.target.value;

        const buttonState = this.getButtonState( value, true );
        this.updateControlButtonsAndPushContent( buttonState, true, { value: value } );
        this.setState({ value: value, contentVerified: true });
    }

    // Called by react when this component has been mounted
    componentDidMount() {

        // Check for an incoming value from the parent
        let value = "";
        if( null != this.props.value ) {
            value = this.props.value;
        }

        const buttonState = this.getButtonState( value, true );
        this.updateControlButtonsAndPushContent( buttonState, true, { value: value } );
        this.setState({ value: value, contentVerified: true });
    }

    // React render
    render() {
        // Used this.props
        // setLeftButtonState(bool), setRightButtonState(bool), setContent(json), verifyContent(json), label

        const label = this.props.label;
        const helperText = this.props.helperText;

        let selectedValue = this.state.value;
        if( this.state.value === "None" ) {
            selectedValue = "";
        }
    
        return (
            <React.Fragment>
                <FormControl variant="outlined" size="small" className={this.props.classes.formControl}>
                    <InputLabel>
                        {label}
                    </InputLabel>
                    <Select
                        value={selectedValue}
                        onChange={this.handleChange}
                        label={label}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                    >
                        <MenuItem value="">
                            <em>{i18n.t("values.none")}</em>
                        </MenuItem>
                        {this.props.items.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{helperText}</FormHelperText>
                </FormControl>
            </React.Fragment>
        );
    }
};

export default withStyles(useStyles)(ModalDialogSelect);