import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';

const useMenuStyles = theme => ({
    paper: {
        border: theme.mainBar.subMenuBorder
    },
});

const StyledMenu = withStyles(useMenuStyles)((props) => (
    <Menu
        elevation={3}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

export {
    StyledMenu
}