import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import BackspaceIcon from '@material-ui/icons/Backspace';

const SearchField = React.memo(({ label, id, value, helperText, onChange, onClick }) => {
    return (
        <TextField label={label} id={id} value={value} type="text" variant="outlined" size="small" helperText={helperText} 
            onChange={onChange}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton
                            onClick={onClick}
                            edge="end"
                        >
                            <BackspaceIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
});

export default SearchField;

