import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 * Also acts as provider for the UserContext
 */
class ReportService {

    /**
     * Get vacation report
     * @param {*} vacationReportSearch { userid }
     */
    async getVacationReport( vacationReportSearch ) {
        var request = {
            query: `
            query getVacationReport($vacationReportSearch: VacationReportSearch!) {
                getVacationReport(vacationReportSearch: $vacationReportSearch) {
                    userid
                    years {
                        year
                        days_available_in_year
                        days_booked_in_year
                        days_remaining_in_year
                        days_adjusted_in_year
                        months {
                            month
                            days_booked_in_month
                            days_adjusted_in_month
                        }
                    }
                }
            }
            `,
            variables: {
                vacationReportSearch: vacationReportSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Get accounting report
     * @param {*} accountingReportSearch { userid }
     */
    async getAccountingReport( accountingReportSearch ) {
        var request = {
            query: `
            query getAccountingReport($accountingReportSearch: AccountingReportSearch!) {
                getAccountingReport(accountingReportSearch: $accountingReportSearch) {
                    userid
                    hours_overtime_today
                    years {
                        year
                        hours_target_in_year
                        hours_accounted_in_year
                        hours_remaining_in_year
                        hours_adjusted_in_year
                        months {
                            month
                            hours_target_in_month
                            hours_accounted_in_month
                            hours_remaining_in_month
                            hours_adjusted_in_month
                        }
                    }
                }
            }
            `,
            variables: {
                accountingReportSearch: accountingReportSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Get absence report
     * @param {*} absenceReportSearch { userid }
     */
    async getAbsenceReport( absenceReportSearch ) {
        var request = {
            query: `
            query getAbsenceReport($absenceReportSearch: AbsenceReportSearch!) {
                getAbsenceReport(absenceReportSearch: $absenceReportSearch) {
                    userid
                    years {
                        year
                        months {
                            month
                            absences {
                                specialid
                                days_absent_in_month
                            }
                        }
                    }
                }
            }
            `,
            variables: {
                absenceReportSearch: absenceReportSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Get project report
     * @param {*} projectReportSearch { projectid }
     */
    async getProjectReport( projectReportSearch ) {
        var request = {
            query: `
            query getProjectReport($projectReportSearch: ProjectReportSearch!) {
                getProjectReport(projectReportSearch: $projectReportSearch) {
                    searchValues {
                        projectid
                        taskid
                        userid
                        minDate
                        maxDate
                    }
                    resultProject {
                        hours_accounted_total
                        minDate
                        maxDate
                        create_date
                        status_change_date
                        years {
                            year
                            hours_accounted_in_year
                            months {
                                month
                                hours_accounted_in_month
                            }
                        }
                    }
                    resultTasks {
                        taskid
                        name
                        hours_accounted_total
                        create_date
                        status_change_date
                        years {
                            year
                            hours_accounted_in_year
                            months {
                                month
                                hours_accounted_in_month
                            }
                        }
                    }
                    resultUsers {
                        userid
                        firstname
                        lastname
                        type
                        hours_accounted_total
                        years {
                            year
                            hours_accounted_in_year
                            months {
                                month
                                hours_accounted_in_month
                                tasks {
                                    taskid
                                    name
                                    hours_accounted_in_month
                                }
                            }
                        }
                        tasks {
                            taskid
                            name
                            hours_accounted_total
                        }
                    }
                }
            }
            `,
            variables: {
                projectReportSearch: projectReportSearch
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new ReportService();
