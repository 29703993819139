import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 */
class WorkTimeService {

    /**
     * Get entries
     * @param {*} workTimeSearch { worktimeid, userid, date, start_time, end_time, break }
     */
    async getWorkTimes( workTimeSearch ) {
        var request = {
            query: `
            query getWorkTimes($workTimeSearch: WorkTimeSearch!) {
                getWorkTimes(workTimeSearch: $workTimeSearch) {
                    worktimeid
                    userid
                    date
                    start_time
                    end_time
                    break
                }
            }
            `,
            variables: {
                workTimeSearch: workTimeSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Get entries within date range
     * @param {*} workTimeRangeSearch { minDate, maxDate, userid }
     */
    async getWorkTimesRange( workTimeRangeSearch ) {
        var request = {
            query: `
            query getWorkTimesRange($workTimeRangeSearch: WorkTimeRangeSearch!) {
                getWorkTimesRange(workTimeRangeSearch: $workTimeRangeSearch) {
                    worktimeid
                    userid
                    date
                    start_time
                    end_time
                    break
                }
            }
            `,
            variables: {
                workTimeRangeSearch: workTimeRangeSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Create a new entry
     * @param {*} workTimeInput { userid, date, start_time, end_time, break }
     */
    async createWorkTime( workTimeInput ) {
        var request = {
            query: `
            mutation createWorkTime($workTimeInput: WorkTimeInput) {
                createWorkTime(workTimeInput: $workTimeInput) {
                    worktimeid
                    userid
                    date
                    start_time
                    end_time
                    break
                }
            }
            `,
            variables: {
                workTimeInput: workTimeInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update an entry
     * @param {*} workTimeSearch { worktimeid, userid, date, start_time, end_time, break }
     * @param {*} workTimeUpdate { start_time, end_time, break }
     */
    async updateWorkTime( workTimeSearch, workTimeUpdate ) {
        var request = {
            query: `
            mutation updateWorkTime($workTimeSearch: WorkTimeSearch!, $workTimeUpdate: WorkTimeUpdate!) {
                updateWorkTime(workTimeSearch: $workTimeSearch, workTimeUpdate: $workTimeUpdate) {
                    worktimeid
                    userid
                    date
                    start_time
                    end_time
                    break
                }
            }
            `,
            variables: {
                workTimeSearch: workTimeSearch,
                workTimeUpdate: workTimeUpdate
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete an entry
     * @param {*} workTimeSearch { worktimeid, userid, date, start_time, end_time, break }
     */
    async deleteWorkTime( workTimeSearch ) {
        var request = {
            query: `
            mutation deleteWorkTime($workTimeSearch: WorkTimeSearch!) {
                deleteWorkTime(workTimeSearch: $workTimeSearch) {
                    result
                }
            }
            `,
            variables: {
                workTimeSearch: workTimeSearch
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new WorkTimeService();
