import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PageTitle from '../components/PageTitle';
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Invalid Page
 */
class InvalidPage extends Component {
    // Push data loaded changed to parent
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Push alert to parent
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Called when the component has mounted
    async componentDidMount() {
        // No data to load
        this.handleDataLoadedChanged( true );
        this.handleAlertChanged({ severity: "info", message: "" });
    }

    // React render
    render() {
        let content = (
            <div>
                <PageTitle title={i18n.t("pages.invalid.title")} />
                <p>{i18n.t("pages.invalid.desc")}</p>
            </div>
        );
        
        return <React.Fragment>{content}</React.Fragment>;
    }
};

export default withStyles(useStyles)(InvalidPage);