import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AddButton from './AddButton'
import RefreshButton from './RefreshButton'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import i18n from 'i18next';

const useStyles = theme => ({
    selectAddGrid: {
        display: 'flex',
        height: '80px',
        width: '100%',
        justifyContent: 'space-between',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    formControl: {
        marginBottom: theme.spacing(1.5),
        minWidth: '20ch'
    }
});

/**
 * Select field with associated refresh and add buttons
 */
class SelectControls extends Component {
    state = {}

    constructor(props) {
        super(props);

        this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind( this );
        this.handleAddButtonClick = this.handleAddButtonClick.bind( this );
        this.handleChangeSelectField = this.handleChangeSelectField.bind( this );
    }

    // Called when new selection in the select field
    handleChangeSelectField( event ) {
        event.preventDefault();
        if( null != this.props.onChange) {
            this.props.onChange( event.target.value );
        }
    }

    // Called on refresh button click
    handleRefreshButtonClick( event ) {
        event.stopPropagation();
        if( null != this.props.onRefresh) {
            this.props.onRefresh();
        }
    }

    // Called on add button click
    handleAddButtonClick( event ) {
        event.stopPropagation();
        if( null != this.props.onAdd) {
            this.props.onAdd();
        }
    }

    render() {
        const { classes } = this.props;

        const label = this.props.label;
        const helperText = this.props.helperText;
        const refreshTooltip = this.props.refreshTooltip;
        const addTooltip = this.props.addTooltip;

        let items = this.props.items;
        if( null == items ) {
            items = [];
        }

        let noneValueName = i18n.t("values.none");
        if( null != this.props.noneValueName ) {
            noneValueName = this.props.noneValueName;
        }

        let showNoneValue = true;
        if( null != this.props.showNoneValue ) {
            showNoneValue = this.props.showNoneValue;
        }

        let showButtons = true;
        if( null != this.props.showButtons ) {
            showButtons = this.props.showButtons;
        }

        let showAddButton = true;
        if( null != this.props.showAddButton ) {
            showAddButton = this.props.showAddButton;
        }
        let showRefreshButton = true;
        if( null != this.props.showRefreshButton ) {
            showRefreshButton = this.props.showRefreshButton;
        }
        return (
            <Grid container direction="row" className={classes.selectAddGrid} spacing={0} wrap='nowrap'>
                <Grid item>
                    <Grid item>
                        <FormControl variant="outlined" size="small" className={classes.formControl} style={{minWidth: this.props.minWidth}}>
                            <InputLabel>
                                {label}
                            </InputLabel>
                            <Select
                                displayEmpty={this.props.displayEmpty}
                                value={this.props.selectedEntry}
                                onChange={this.handleChangeSelectField}
                                label={label}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                {showNoneValue && (
                                    <MenuItem value="">
                                        <em>{noneValueName}</em>
                                    </MenuItem>
                                )}
                                {items.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{helperText}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                {showButtons && (
                    <Grid item container direction="row" spacing={1} justify="flex-end">
                        {showRefreshButton && (
                            <Grid item>
                                <RefreshButton 
                                    title={refreshTooltip}
                                    onClick={(event) => this.handleRefreshButtonClick(event)}
                                    disabled={this.props.disableRefreshButton}
                                />
                            </Grid>
                        )}
                        {showAddButton && ( 
                            <Grid item>
                                <AddButton 
                                    title={addTooltip}
                                    onClick={(event) => this.handleAddButtonClick(event)}
                                    disabled={this.props.disableAddButton}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        )
    }
};

export default withStyles(useStyles)(SelectControls);