import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

const useStyles = theme => ({
    root: {
        fontWeight: "500",
        marginTop: "0px",
        marginBottom: "0px",
        minHeight: "35px"
    }
});

/**
 * Alert message
 */
class AlertMessage extends Component {
    render() {
        let content = "";

        // Gather props two different ways for caller convenience
        let severity = "";
        let message = "";

        if( null != this.props.severity ) {
            severity = this.props.severity;
        }
        if( null != this.props.message ) {
            message = this.props.message;
        }
        if( null != this.props.alert ) {
            severity = this.props.alert.severity;
            message = this.props.alert.message;
        }

        if( null != message && message.length > 0 ) {
            content = (
                <Alert className={this.props.classes.root} variant="filled" severity={severity}>{message}</Alert>
            )
        }

        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        );
    }
};

export default withStyles(useStyles)(AlertMessage);