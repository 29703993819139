import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import PdfTableColumn from './PdfTableColumn';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        backgroundColor: "#FFFFFF",
        margin: 5
    },
    col: {
        color: "#000000",
        fontSize: "10",
        textAlign: "left"
    }
});

const PdfTableDataRow = ({data}) => {
    return (
        <View style={styles.row} key={data.key.toString()}>
            {data.cols.map((column) => {
                return (
                    <PdfTableColumn style={[styles.col, column.style]} key={data.key.toString()+"-"+column.key.toString()+"-col"} data={column}/>
                )
            })}
        </View>
    )
};

export default PdfTableDataRow
