import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import AuthContext from '../contexts/AuthContext';
import { config } from '../config';
import Copyright from '../components/Copyright'
import AlertMessage from '../components/AlertMessage'
import Link from '@material-ui/core/Link';
import i18n from 'i18next';

const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

/**
 * Main Login component
 */
class Login extends Component {
    state = {
        email: "",
        password: ""
    };

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind( this );
        this.handleChange = this.handleChange.bind( this );
        this.handleForgotPassword = this.handleForgotPassword.bind( this );

        if( this.props.autoLogin.enabled === true && this.props.autoLogin.email !== "" && this.props.autoLogin.password !== "" ) {
            this.state.email = this.props.autoLogin.email;
            this.state.password = this.props.autoLogin.password;
        }

        // Set the language here initially, we need that since we are not logged in yet and do not know the users language settings
        i18n.changeLanguage(config.frontendFallbackLanguage);
    }

    // Handle change of values
    handleChange(event) {
        // Enforce lower case on email
        let value = event.target.value;
        if( event.target.name === "email" ) {
            value = value.toLowerCase();
        }

        this.setState({ [event.target.name]: value }, () => {
            if( this.props.onChange ) {
                this.props.onChange( this.state );
            }
        })
    }

    // Handle submit button click
    handleSubmit(event) {
        event.preventDefault();
        if( this.props.onSubmit ) {
            this.props.onSubmit( this.state );
        }
    }

    // Handle forgot password click
    handleForgotPassword(event) {
        event.preventDefault();
        if( this.props.onForgotPassword ) {
            this.props.onForgotPassword( this.state );
        }
    }

    // Check if login button is enabled
    isLoginButtonEnabled() {
        if( this.state.email !== "" && this.state.password !== "" ) {
            return true;
        }
        return false;
    }

    // React render
    render() {
        const isLoginButtonEnabled = this.isLoginButtonEnabled();
        const { classes } = this.props;
        return (
            <AuthContext.Consumer>
                {context => {
                    return (
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <div className={classes.paper}>
                                <Avatar className={classes.avatar}>
                                    <LockOutlinedIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    {config.mainTitle} {i18n.t("auth.login")}
                                </Typography>
                                <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                                    <TextField
                                        value={this.state.email}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label={i18n.t("email")}
                                        name="email"
                                        autoComplete="email"
                                        onChange={this.handleChange}
                                        autoFocus
                                        error={this.state.email === ""}
                                    />
                                    <TextField
                                        value={this.state.password}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label={i18n.t("password")}
                                        type="password"
                                        id="password"
                                        onChange={this.handleChange}
                                        autoComplete="current-password"
                                        error={this.state.password === ""}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={!isLoginButtonEnabled}
                                    >
                                        {i18n.t("login")}
                                    </Button>
                                </form>
                            </div>
                            <div align="center">
                                <Link href="#" onClick={this.handleForgotPassword}>
                                    {i18n.t("auth.forgotpassword_link")}
                                </Link>
                            </div>
                            <Copyright />
                            <AlertMessage severity="error" message={context.errorMessage} />
                            <AlertMessage severity="success" message={context.successMessage} />
                        </Container>
                    );
                }}
            </AuthContext.Consumer>
        );
    }
}

export default withStyles(useStyles)(Login);