import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import HistoryIcon from '@material-ui/icons/History';
import Fab from '@material-ui/core/Fab';

const ResetButton = React.memo(({ title, ...other }) => {
    return (
        <Tooltip title={title}>
            <div>
                <Fab color="primary" size="medium" {...other}>
                    <HistoryIcon />
                </Fab>
            </div>
        </Tooltip>
    )
});

export default ResetButton;