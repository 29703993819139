import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AuthContext from '../contexts/AuthContext';
import PageTitle from '../components/PageTitle';
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Logout Page
 */
class LogoutPage extends Component {
    static contextType = AuthContext;

    // Push data loaded to parent
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Push alert to parent
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Called when component mounted
    async componentDidMount() {
        // No data to load
        this.handleDataLoadedChanged( true );
        this.handleAlertChanged({ severity: "info", message: "" });

        // Perform logout
        this.context.logout();
    }

    // React render
    render() {
        let content = (
            <div>
                <PageTitle title={i18n.t("pages.title.logout")} />
                <p>{i18n.t("pages.logout.desc")}</p>
            </div>
        );
        
        return <React.Fragment>{content}</React.Fragment>;
    }
}

export default withStyles(useStyles)(LogoutPage);