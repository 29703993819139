import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { getDateLocale } from '../config.js';
import { toJSONLocalDate, removeTimeFromDate, toDateObject } from '../helpers.js';
import i18n from 'i18next';

const useStyles = theme => ({
    helperTextBox: {
        marginLeft: "14px",
        minHeight: "18px"
    },
    datePicker: {
        height: "50px",
        width: "100%"
    },
    specialDateWrapperNotSelected: {
        background: "#88bbdf",
        borderRadius: "10%",
        marginLeft: "2px",
        marginRight: "2px",
        marginTop: "0px",
        marginBottom: "0px",
        width: "36px",
        height: "36px",
    },
    specialDateWrapperSelected: {
        background: theme.palette.primary.main,
        borderRadius: "10%",
        marginLeft: "2px",
        marginRight: "2px",
        marginTop: "0px",
        marginBottom: "0px",
        width: "36px",
        height: "36px",
    },
    specialDateButtonNotSelected: {
        width: "36px",
        height: "36px",
        fontWeight: 500,
        fontSize: "0.875rem",
        color: "#ffffff",
        borderRadius: "10%",
    },
    invalidDateWrapperNotSelected: {
        background: "#ff0000",
        borderRadius: "10%",
        marginLeft: "2px",
        marginRight: "2px",
        marginTop: "0px",
        marginBottom: "0px",
        width: "36px",
        height: "36px",
    },
    invalidDateWrapperSelected: {
        background: "#b00000",
        borderRadius: "10%",
        marginLeft: "2px",
        marginRight: "2px",
        marginTop: "0px",
        marginBottom: "0px",
        width: "36px",
        height: "36px",
    },
    dateButton: {
        width: "36px",
        height: "36px",
        fontWeight: 500,
        fontSize: "0.875rem",
        color: "#ffffff",
        borderRadius: "10%",
    }
});

/**
 * Modal Dialog Keyboard Date Picker Component
 */
class ModalDialogKeyboardDatePicker extends Component {
    state = {
        value: removeTimeFromDate( new Date() ),
        contentVerified: false,
        helperText: ""
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind( this );
        this.verifyDate = this.verifyDate.bind( this );
    }

    // Determine the button state
    getButtonState( date, contentVerified ) {
        let allowEmptyDate = false;
        if( null != this.props.allowEmptyDate ) {
            allowEmptyDate = this.props.allowEmptyDate;
        }

        if( ( date !== null || true === allowEmptyDate ) && true === contentVerified ) {
            return true;
        } else {
            return false;
        }
    }

    // Verify date object
    verifyDate( dateObject ) {
        let dateString = toJSONLocalDate( dateObject );
        
        // If we got a special allowed date from the parent, check against that first
        if( null != this.props.allowedDate ) {
            if( dateString === this.props.allowedDate ) {
                return { state: true, msg: "" };
            }
        }

        if( null != this.props.verifyContent ) {
            return this.props.verifyContent({ date: dateString });
        }

        return { state: true, msg: "" };
    }

    // General push of new data to parent
    updateControlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent ) {
        if( null != this.props.controlButtonsAndPushContent ) {
            this.props.controlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent );
        }
    }

    // Name text field changed
    handleChange( date ) {

        // Handle invalid date
        if( date != null && isNaN( date.getTime() ) ) {
            const buttonState = this.getButtonState( null, false );
            this.updateControlButtonsAndPushContent( buttonState, true, { value: "" } );
            this.setState({ value: date, contentVerified: false, helperText: "" });
            return;
        }

        // Handle "empty date" case
        let allowEmptyDate = false;
        if( null != this.props.allowEmptyDate ) {
            allowEmptyDate = this.props.allowEmptyDate;
        }
        if( null == date ) {
            const buttonState = this.getButtonState( null, allowEmptyDate );
            this.updateControlButtonsAndPushContent( buttonState, true, { value: "" } );
            this.setState({ value: removeTimeFromDate( new Date() ), contentVerified: true, helperText: "" });
            return;
        }     

        // Given date has current time, we do not care about that
        let dateOnly = removeTimeFromDate( date );

        // Verify the new date
        const { state, msg } = this.verifyDate( dateOnly );
        
        const buttonState = this.getButtonState( dateOnly, state );
        this.updateControlButtonsAndPushContent( buttonState, true, { value: toJSONLocalDate( dateOnly ) } );
        this.setState({ value: dateOnly, contentVerified: state, helperText: msg });
    }

    // Check if a day is in the blocker list
    isBlocked( date ) {
        let result = false;

        if( null != this.props.blockedDates ) {
            let dateString = toJSONLocalDate( date );

            // Go through the list of blocked dates
            for( let blockedDate of this.props.blockedDates ) {
                if( blockedDate.date === dateString ) {
                    result = true;
                    break;
                }
            }
        }

        return result;
    }

    // Custom day renderer
    renderDay( day, selectedDate, isInCurrentMonth, dayComponent ) {

        // Color the date red if the date to be rendered is the selectedDate and its not valid
        let colorDay = false;
        let divClassName = "";
        let dayClassName = "";

        if( day.getTime() === selectedDate.getTime() && selectedDate.getTime() === this.state.value.getTime() && false === this.state.contentVerified ) {
            colorDay = true;
            divClassName = this.props.classes.invalidDateWrapperSelected;
            dayClassName = this.props.classes.dateButton;
        } else if( true === this.isBlocked( day ) ) {
            colorDay = true;
            divClassName = this.props.classes.invalidDateWrapperNotSelected;
            dayClassName = this.props.classes.dateButton;
        }

        // Check if the selected day is the special allowed date, if yes do not mark it as red
        if( null != this.props.allowedDate ) {
            let dateString = toJSONLocalDate( day );
            if( dateString === this.props.allowedDate ) {
                if( day.getTime() === selectedDate.getTime() ) {
                    colorDay = true;
                    divClassName = this.props.classes.specialDateWrapperSelected;
                    dayClassName = this.props.classes.dateButton;
                } else {
                    colorDay = true;
                    divClassName = this.props.classes.specialDateWrapperNotSelected;
                    dayClassName = this.props.classes.specialDateButtonNotSelected;
                }
            }
        }

        // Check if we did not apply any custom color at this point
        if( day.getTime() === selectedDate.getTime() && false === colorDay && true === isInCurrentMonth ) {
            colorDay = true;
            divClassName = this.props.classes.specialDateWrapperSelected;
            dayClassName = this.props.classes.dateButton;
        }

        if( true === colorDay && true === isInCurrentMonth ) {
            return( 
                <div className={divClassName}>
                    <IconButton className={dayClassName}>
                        <span> {day.getDate()} </span>
                    </IconButton>
                </div>
            )
        }

        return dayComponent;
    }

    // Called by react when this component has been mounted
    componentDidMount() {
        let date = removeTimeFromDate( new Date() );

        // Check for an incoming date to use, it has the format yyyy-mm-dd
        if( null != this.props.value ) {
            if( "" === this.props.value ) {
                date = null;
            } else {
                date = toDateObject( this.props.value );
            }
        }
        
        const { state, msg } = this.verifyDate( date );
        const buttonState = this.getButtonState( date, state );
        this.updateControlButtonsAndPushContent( buttonState, true, { value: toJSONLocalDate( date ) } );
        this.setState({ value: date, contentVerified: state, helperText: msg });
    }

    // React render
    render() {
        // Used this.props
        // setLeftButtonState(bool), setRightButtonState(bool), setContent(json), verifyContent(json), label, maxLength
        let locale = null;
        if( null != this.props.language ) {
            locale = getDateLocale( this.props.language );
        }

        return (
            <React.Fragment>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                    <KeyboardDatePicker
                        className={this.props.classes.datePicker}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format="yyyy-MM-dd"
                        margin="normal"
                        label={this.props.label}
                        value={this.state.value}
                        onChange={this.handleChange}
                        mask="____-__-__"
                        maskChar="_"
                        invalidDateMessage={i18n.t("errors.invalid_date")}
                        renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => this.renderDay(day, selectedDate, isInCurrentMonth, dayComponent)}
                    />
                </MuiPickersUtilsProvider>
                <Box m={0} className={this.props.classes.helperTextBox} />
            </React.Fragment>
        );
    }
};

export default withStyles(useStyles)(ModalDialogKeyboardDatePicker);