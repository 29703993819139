import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 */
class UsersToRolesService {

    /**
     * Get users-to-roles
     * @param {*} userToRoleSearch { roleid, userid }
     */
    async getUsersToRoles( userToRoleSearch ) {
        var request = {
            query: `
            query getUsersToRoles($userToRoleSearch: UserToRoleSearch!) {
                getUsersToRoles(userToRoleSearch: $userToRoleSearch) {
                    roleid
                    userid
                }
            }
            `,
            variables: {
                userToRoleSearch: userToRoleSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Add user-to-role
     * @param {*} userToRoleInput { roleid, userid }
     */
    async addUserToRole( userToRoleInput ) {
        var request = {
            query: `
            mutation addUserToRole($userToRoleInput: UserToRoleInput!) {
                addUserToRole(userToRoleInput: $userToRoleInput) {
                    roleid
                    userid
                }
            }
            `,
            variables: {
                userToRoleInput: userToRoleInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete user-to-role
     * @param {*} userToRoleSearch { roleid, userid }
     */
    async deleteUserToRole( userToRoleSearch ) {
        var request = {
            query: `
            mutation deleteUserToRole($userToRoleSearch: UserToRoleSearch!) {
                deleteUserToRole(userToRoleSearch: $userToRoleSearch) {
                    result
                }
            }
            `,
            variables: {
                userToRoleSearch: userToRoleSearch
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new UsersToRolesService();
