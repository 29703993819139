import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 */
class UsersToTasksService {

    /**
     * Get users-to-tasks
     * @param {*} userToTaskSearch { taskid, userid }
     */
    async getUsersToTasks( userToTaskSearch ) {
        var request = {
            query: `
            query getUsersToTasks($userToTaskSearch: UserToTaskSearch!) {
                getUsersToTasks(userToTaskSearch: $userToTaskSearch) {
                    taskid
                    userid
                }
            }
            `,
            variables: {
                userToTaskSearch: userToTaskSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Add user-to-task
     * @param {*} userToTaskInput { taskid, userid }
     */
    async addUserToTask( userToTaskInput ) {
        var request = {
            query: `
            mutation addUserToTask($userToTaskInput: UserToTaskInput!) {
                addUserToTask(userToTaskInput: $userToTaskInput) {
                    taskid
                    userid
                }
            }
            `,
            variables: {
                userToTaskInput: userToTaskInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete user-to-task
     * @param {*} userToTaskSearch { taskid, userid }
     */
    async deleteUserToTask( userToTaskSearch ) {
        var request = {
            query: `
            mutation deleteUserToTask($userToTaskSearch: UserToTaskSearch!) {
                deleteUserToTask(userToTaskSearch: $userToTaskSearch) {
                    result
                }
            }
            `,
            variables: {
                userToTaskSearch: userToTaskSearch
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new UsersToTasksService();
