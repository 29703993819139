import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import HolidayService from '../../classes/HolidayService'
import CustomHolidayService from '../../classes/CustomHolidayService'
import PageTitle from '../../components/PageTitle';
import TabContainer from '../../components/TabContainer';
import SearchControls from '../../components/SearchControls'
import HolidaysCalendar from '../../components/HolidaysCalendar'
import HolidaysList from '../../components/HolidaysList'
import HolidaysGenerate from '../../components/HolidaysGenerate'
import HolidaysCustom from '../../components/HolidaysCustom'
import ModalDialog from '../../components/ModalDialog';
import ModalDialogTextField from '../../components/ModalDialogTextField';
import ModalDialogDatePicker from '../../components/ModalDialogDatePicker';
import ModalDialogCheckbox from '../../components/ModalDialogCheckbox';
import AuthContext from '../../contexts/AuthContext';
import i18n from 'i18next';
import { config } from '../../config';
import { removeTimeFromDate, toJSONLocalDate } from '../../helpers.js';
import _ from 'lodash'

const useStyles = theme => ({
	searchAddGrid: {
		display: 'flex',
		height: '80px',
		width: '100%',
		justifyContent: 'space-between',
		'& > *': {
			margin: theme.spacing(0)
		},
	},
	searchFieldDiv: {
		'& .MuiTextField-root': {
			marginBottom: theme.spacing(1.5),
			width: '40ch',
		},
	},
});

/**
 * Admin Holidays
 */
class AdminHolidays extends Component {
	static contextType = AuthContext;

	// Set in constructor
	state = {}

	constructor(props) {
		super(props);

		this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind( this );
		this.handleAddButtonClick = this.handleAddButtonClick.bind( this );
		this.handleChangeSearchField = this.handleChangeSearchField.bind( this );

		this.handleAddCustomDialog_Ok = this.handleAddCustomDialog_Ok.bind( this );
		this.handleAddCustomDialog_Cancel = this.handleAddCustomDialog_Cancel.bind( this );
		this.verifyCustomDialog_Name = this.verifyCustomDialog_Name.bind( this );
		this.verifyCustomDialog_Date = this.verifyCustomDialog_Date.bind( this );

		this.handleModifyDialog_Ok = this.handleModifyDialog_Ok.bind( this );
		this.handleModifyDialog_Cancel = this.handleModifyDialog_Cancel.bind( this );
		this.handleModifyDialog_Delete = this.handleModifyDialog_Delete.bind( this );

		this.getHolidays = this.getHolidays.bind( this );
		this.doesHolidayExist = this.doesHolidayExist.bind( this );

        this.getCustomHolidays = this.getCustomHolidays.bind( this );
        this.doesCustomHolidayExist = this.doesCustomHolidayExist.bind( this );

		this.renderTabCalendar = this.renderTabCalendar.bind( this );
		this.renderTabList = this.renderTabList.bind( this );
		this.renderTabGenerate = this.renderTabGenerate.bind( this );
        this.renderTabCustom = this.renderTabCustom.bind( this );

		this.handleTabCalendarClick = this.handleTabCalendarClick.bind( this );
		this.handleTabListUpdateName = this.handleTabListUpdateName.bind( this );
		this.handleTabListUpdateDate = this.handleTabListUpdateDate.bind( this );
		this.handleTabListDelete = this.handleTabListDelete.bind( this );

		this.handleTabCustomDelete = this.handleTabCustomDelete.bind( this );
		this.handleTabCustomUpdateName = this.handleTabCustomUpdateName.bind( this );
		this.handleTabCustomUpdateDate = this.handleTabCustomUpdateDate.bind( this );
		this.handleTabCustomUpdateYearStart = this.handleTabCustomUpdateYearStart.bind( this );
		this.handleTabCustomUpdateYearEnd = this.handleTabCustomUpdateYearEnd.bind( this );

		this.handleTabChange = this.handleTabChange.bind( this );
		this.handleTabYearChanged = this.handleTabYearChanged.bind( this );
		this.handleImportHolidays = this.handleImportHolidays.bind( this );

		this.verifyList_Name = this.verifyList_Name.bind( this );
		this.verifyList_Date = this.verifyList_Date.bind( this );
        this.verifyList_CustomDate = this.verifyList_CustomDate.bind( this );

		this.createHoliday = this.createHoliday.bind( this );
		this.updateHoliday = this.updateHoliday.bind( this );
		this.deleteHoliday = this.deleteHoliday.bind( this );

		this.createCustomHoliday = this.createCustomHoliday.bind( this );
		this.updateCustomHoliday = this.updateCustomHoliday.bind( this );
		this.deleteCustomHoliday = this.deleteCustomHoliday.bind( this );

		// Set initial state
		this.state = this.getInitialState();
	}

	/**
	 * STATE HANDLING
	 */

	// Get the initial state variables of this component
	getInitialState( keepSomeValues ) {
		if( true === keepSomeValues ) {
			return {
				currentTabIndex: this.state.currentTabIndex,
				currentYear: this.state.currentYear,
				data: { // Raw loaded data from the backend
					holidays: null,
                    customHolidays: null
				},
				searchFieldEntry: "",
				addCustomDialog: {
					open: false,
					date: null,
                    allHolidays: []
				},
				modifyDialog: {
					open: false,
					date: null,
					name: "",
					holidays: []
				},
			}
		}
		return {
			currentTabIndex: 0,
			currentYear: new Date().getFullYear(),
			data: { // Raw loaded data from the backend
				holidays: null,
                customHolidays: null
			},
			searchFieldEntry: "",
			addCustomDialog: {
				open: false,
				date: null,
                allHolidays: []
			},
			modifyDialog: {
				open: false,
				date: null,
				name: "",
				holidays: []
			},
		}
	}

	// Reset all internal states to initial values and also values propagated to parent
	resetState() {
		this.handleAlertChanged({ severity: "info", message: "" });
		this.handleDataLoadedChanged( false );
		this.setState( this.getInitialState( true ) );
	}

	/**
	 * LINK TO PARENT COMPONENT FUNCTIONS
	 */

	// Propagate information to parent component
	handleDataLoadedChanged( dataLoaded ) {
		if( this.props.dataLoadedChanged ) {
			this.props.dataLoadedChanged( dataLoaded );
		}
	}

	// Propagate information to parent component
	handleAlertChanged( alert ) {
		if( this.props.alertChanged ) {
			this.props.alertChanged( alert );
		}
	}

	// Propagate information to parent component
	handleActionInProgressChanged( actionInProgress ) {
		if( this.props.actionInProgressChanged ) {
			this.props.actionInProgressChanged( actionInProgress );
		}
	}

	/**
	 * LOAD AND PROCESS BACKEND DATA
	 */

	// Called when a refresh of the table data is needed due to edits in the table
	async refreshTableData() {
		await this.loadDataFromBackend( true );
	}

	// Gather all data from the backend needed on this page and process them properly
	async loadDataFromBackend( manualRefresh ) {
		// Alert message to show
		let alert = {
			severity: "info",
			message: ""
		}

		let holidayData = null;

		// Gather holiday data
		{
			const { statusCode, statusText, resData } = await HolidayService.getHolidays( {} );
			if( 200 === statusCode && null != resData ) {
				holidayData = resData.getHolidays;
			} else {
				// Error, set the alert right away and abort
				this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
				return;
			}
		}

        let customHolidayData = null;

        // Gather custom holiday data
		{
			const { statusCode, statusText, resData } = await CustomHolidayService.getCustomHolidays( {} );
			if( 200 === statusCode && null != resData ) {
				customHolidayData = resData.getCustomHolidays;
			} else {
				// Error, set the alert right away and abort
				this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
				return;
			}
		}

		// Now process the data
		await this.processData( holidayData, customHolidayData );

		// All fine, clear the error message (only if not a manual refresh)
		if( false === manualRefresh ) {
			this.handleAlertChanged( alert );
		}
		this.handleDataLoadedChanged( true );
	}

	// Called to make additional processing of data, for example gathering info from that backend data and storing it for later use
	async processData( holidayData, customHolidayData ) {

		// Abort if no proper data given
		if( null == holidayData || null == customHolidayData ) {
			return;
		}

		// Store the data
		this.setState({ 
			data: { 
                holidays: holidayData,
                customHolidays: customHolidayData
            }
		});
	}

	// Create a new holiday in the backend
	async createHoliday( name, date, batchProcess ) {

		// Check data validity
		if( name == null || date == null ) {
			return;
		}

		// Add the holiday
		let holidayValues = {
			name: name,
			date: date
		}

		const { statusCode, statusText, resData } = await HolidayService.createHoliday( holidayValues );

		let alert = {
			severity: "info",
			message: ""
		}

		if( 200 === statusCode && null != resData ) {
			alert.severity = "success";
			alert.message = i18n.t("pages.admin.holidays.add.alert.success", { name: name });
		} else {
			alert.severity = "error";
			alert.message = `${statusText}: ${statusCode}`;
		}

		this.handleAlertChanged( alert );

		// Refresh the table data
		if( false === batchProcess ) {
			await this.refreshTableData();
		}
	}

	// Update a holiday in the backend
	async updateHoliday( searchDate, name, date, batchProcess ) {
		
		// Check data validity
		if( searchDate == null ) {
			return;
		}
		if( name == null && date == null ) {
			return;
		}

		// Modify the holiday
		let searchValues = {
			date: searchDate
		}
		let newValues = {}
		if( name != null && date != null ) {
			newValues = {
				name: name,
				date: date
			}
		} else if( name != null ) {
			newValues = {
				name: name
			}
		} else if( date != null ) {
			newValues = {
				date: date
			}
		}

		const { statusCode, statusText, resData } = await HolidayService.updateHoliday( searchValues, newValues );

		let alert = {
			severity: "info",
			message: ""
		}

		if( 200 === statusCode && null != resData ) {
			alert.severity = "success";
			alert.message = i18n.t("pages.admin.holidays.modify.alert.success", { name: name });
		} else {
			alert.severity = "error";
			alert.message = `${statusText}: ${statusCode}`;
		}

		this.handleAlertChanged( alert );

		// Refresh the table data
		if( false === batchProcess ) {
			await this.refreshTableData();
		}
	}

	// Delete a holiday from the backend
	async deleteHoliday( date, batchProcess ) {
		// Check data validity
		if( date == null ) {
			return;
		}

		// Delete the holiday
		const { statusCode, statusText, resData } = await HolidayService.deleteHoliday( { date: date } );

		let alert = {
			severity: "info",
			message: ""
		}

		if( 200 === statusCode && null != resData ) {
			if( true === resData.deleteHoliday.result ) {
				alert.severity = "success";
				alert.message = i18n.t( "pages.admin.holidays.delete.alert.success", { name: date } );
			} else {
				alert.severity = "error";
				alert.message = `${statusText}: ${statusCode}`;
			}
		} else {
			alert.severity = "error";
			alert.message = `${statusText}: ${statusCode}`;
		}

        this.handleAlertChanged( alert );

		// Refresh the table data
		if( false === batchProcess ) {
			await this.refreshTableData();
		}
	}

	// Create a new custom holiday in the backend
	async createCustomHoliday( name, date, yearStart, yearEnd, batchProcess ) {

		// Check data validity
		if( name == null || date == null || yearStart == null ) {
			return;
		}

		// Add the holiday
		let holidayValues = {
			name: name,
			date: date,
			year_start: yearStart,
			year_end: yearEnd
		}

		const { statusCode, statusText, resData } = await CustomHolidayService.createCustomHoliday( holidayValues );

		let alert = {
			severity: "info",
			message: ""
		}

		if( 200 === statusCode && null != resData ) {
			alert.severity = "success";
			alert.message = i18n.t("pages.admin.holidays.add.alert.success", { name: name });
		} else {
			alert.severity = "error";
			alert.message = `${statusText}: ${statusCode}`;
		}

		this.handleAlertChanged( alert );

		// Refresh the table data
		if( false === batchProcess ) {
			await this.refreshTableData();
		}
	}

	// Update a custom holiday in the backend
	async updateCustomHoliday( searchDate, name, yearStart, yearEnd, updates, batchProcess ) {
		
		// Check data validity
		if( searchDate == null || updates == null ) {
			return;
		}
		
		// Modify the holiday
		let searchValues = {
            name: name, 
			date: searchDate,
            year_start: yearStart,
            year_end: yearEnd
		}
		
		const { statusCode, statusText, resData } = await CustomHolidayService.updateCustomHoliday( searchValues, updates );

		let alert = {
			severity: "info",
			message: ""
		}

		if( 200 === statusCode && null != resData ) {
			alert.severity = "success";
			alert.message = i18n.t("pages.admin.holidays.modify.alert.success", { name: name });
		} else {
			alert.severity = "error";
			alert.message = `${statusText}: ${statusCode}`;
		}

		this.handleAlertChanged( alert );

		// Refresh the table data
		if( false === batchProcess ) {
			await this.refreshTableData();
		}
	}

	// Delete a custom holiday from the backend
	async deleteCustomHoliday( date, name, yearStart, yearEnd, batchProcess ) {
		// Check data validity
		if( date == null ) {
			return;
		}

		// Delete the holiday
		const { statusCode, statusText, resData } = await CustomHolidayService.deleteCustomHoliday( { date: date, name: name, year_start: yearStart, year_end: yearEnd } );

		let alert = {
			severity: "info",
			message: ""
		}

		if( 200 === statusCode && null != resData ) {
			if( true === resData.deleteCustomHoliday.result ) {
				alert.severity = "success";
				alert.message = i18n.t( "pages.admin.holidays.delete.alert.success", { name: name } );
			} else {
				alert.severity = "error";
				alert.message = `${statusText}: ${statusCode}`;
			}
		} else {
			alert.severity = "error";
			alert.message = `${statusText}: ${statusCode}`;
		}

		this.handleAlertChanged( alert );

		// Refresh the table data
		if( false === batchProcess ) {
			await this.refreshTableData();
		}
	}

	/**
	 * CALLBACK FUNCTIONS FOR SEARCH CONTROLS
	 */

	// Called when text is entered in the search field
	handleChangeSearchField(value) {
		this.setState({ searchFieldEntry: value });
	}

	// Called if the refresh button is clicked
	async handleRefreshButtonClick() {
		this.resetState();
		await this.refreshTableData();
	}

	// Called if the add button is clicked
	async handleAddButtonClick() {
		// Open on current day in selected year
        const dateObj = new Date();
        dateObj.setFullYear( this.state.currentYear );
		let date = removeTimeFromDate( dateObj );

		this.openAddCustomDialog( toJSONLocalDate( date ), this.state.data.customHolidays, this.state.data.holidays );
	}

	/**
	 * ALERT DIALOG <ADD CUSTOM>
	 */

	// Called to open the add custom dialog
	openAddCustomDialog( date, customHolidays, holidays ) {

        // Combine both regular and custom holidays on the selected year into one array
        let allHolidays = [...holidays];
        
        // The custom holidays added depend on the selected year and their active range
        const dateYear = parseInt( date.substring( 0, 4 ) );
        for( const customHoliday of customHolidays ) {
            if( dateYear >= customHoliday.year_start ) {
                if( customHoliday.year_end == null || dateYear <= customHoliday.year_end ) {
                    let newCustomHoliday = _.cloneDeep( customHoliday );
                    newCustomHoliday.date = this.state.currentYear + "-" + newCustomHoliday.date;
                    allHolidays.push( newCustomHoliday );
                }
            }
        }

		let addCustomDialog = {
			open: true,
			date: date,
			allHolidays: allHolidays
		}
		this.setState({ addCustomDialog: addCustomDialog });
	}

	// Called when the user clicked "Ok" on the add custom dialog
	handleAddCustomDialog_Ok( name, date, isRecurring ) {
		this.closeAddCustomDialog();

		// Grab default values for this holiday from the date
		let yearStart = parseInt( date.substring( 0, 4 ) );
		let yearEnd = parseInt( date.substring( 0, 4 ) );
        if( true === isRecurring ) {
            yearEnd = null;
        }

        // We are getting the full date here yyyy-mm-dd but only need to provide mm-dd
        let customDate = date.slice( 5 );

		this.createCustomHoliday( name, customDate, yearStart, yearEnd, false );
	}

	// Called when the user clicked "Cancel" or close on the add custom dialog
	handleAddCustomDialog_Cancel() {
		this.closeAddCustomDialog();
	}

	// Called by the dialog to verify its name content
	verifyCustomDialog_Name( content ) {

		let result = {
			state: false,
			msg: i18n.t("pages.admin.holidays.dialog.alert.empty")
		}
		
		if( null != content ) {
			if( null != content.value ) {
				if( content.value !== "" ) {
					result = {
						state: true,
						msg: ""
					}
				}
			}
		}

		return result;
	}

	// Called by the dialog to verify its date content
	verifyCustomDialog_Date( content ) {

		let result = {
			state: false,
			msg: i18n.t("pages.admin.holidays.dialog.alert.empty")
		}
		if( null != content ) {
			if( null != content.date ) {
				if( content.date !== "" ) {
					const { exists } = this.doesCustomHolidayExist( content.date );
					if( true === exists ) {
						result = {
							state: false,
							msg: i18n.t("pages.admin.holidays.dialog.alert.does_already_exist")
						}
					} else {

						// Also check for regular holidays
						if( true === this.doesHolidayExist( content.date ) ) {
							result = {
								state: false,
								msg: i18n.t("pages.admin.holidays.dialog.alert.does_already_exist")
							}
						} else {
							result = {
								state: true,
								msg: ""
							}
						}
					}
				}
			}
		}

		return result;
	}

	// Close the modify alert dialog
	closeAddCustomDialog() {
		let addCustomDialog = {
			open: false,
			date: null,
			allHolidays: []
		}
		this.setState({ addCustomDialog: addCustomDialog });
	}

	/**
	 * ALERT DIALOG <MODIFY>
	 */

	// Called to open the modify dialog
	openModifyDialog( date, name, holidays ) {
		let modifyDialog = {
			open: true,
			date: date,
			name: name,
			holidays: holidays
        }
		this.setState({ modifyDialog: modifyDialog });
	}

	// Called when the user clicked "Ok" on the modify dialog
	handleModifyDialog_Ok( initialDate, name, date ) {
		this.closeModifyDialog();
		this.updateHoliday( initialDate, name, date, false );
	}

	// Called when the user clicked "delete" on the modify dialog
	handleModifyDialog_Delete( date ) {
		this.closeModifyDialog();
		this.deleteHoliday( date, false );
	}

	// Called when the user clicked "Cancel" or close on the modify dialog
	handleModifyDialog_Cancel() {
		this.closeModifyDialog();
	}

	// Close the modify alert dialog
	closeModifyDialog() {
		let modifyDialog = {
			open: false,
			date: null,
			name: null,
			holidays: []
		}
		this.setState({ modifyDialog: modifyDialog });
	}

	/**
	 * LIST VIEW
	 */

	// Called by the list to verify its name content
	verifyList_Name( name ) {

		let result = {
			state: false,
			msg: i18n.t("pages.admin.holidays.dialog.alert.empty")
		}
		
		if( null != name ) {
			if( name !== "" ) {
				result = {
					state: true,
					msg: ""
				}
			}
		}

		return result;
	}

	// Called by the list to verify its date content
	verifyList_Date( date ) {

		let result = {
			state: false,
			msg: i18n.t("pages.admin.holidays.dialog.alert.empty")
		}
		if( null != date ) {
			if( date !== "" ) {
				const { exists } = this.doesHolidayExist( date );
				if( true === exists ) {
					result = {
						state: false,
						msg: i18n.t("pages.admin.holidays.dialog.alert.does_already_exist")
					}
				} else {
					result = {
						state: true,
						msg: ""
					}
				}
			}
		}

		return result;
	}

	// Called by the list to verify its date content
	verifyList_CustomDate( date ) {

		let result = {
			state: false,
			msg: i18n.t("pages.admin.holidays.dialog.alert.empty")
		}
		if( null != date ) {
			if( date !== "" ) {
				const { exists } = this.doesCustomHolidayExist( date );
				if( true === exists ) {
					result = {
						state: false,
						msg: i18n.t("pages.admin.holidays.dialog.alert.does_already_exist")
					}
				} else {
					result = {
						state: true,
						msg: ""
					}
				}
			}
		}

		return result;
	}

	/**
	 * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
	 */

	// Called by react when this component has been mounted
	async componentDidMount() {
		await this.loadDataFromBackend();
	}

	// Called by react before this component unmounts
	componentWillUnmount() {
		this.resetState();
	}

	/**
	 * GETTERS FOR HOLIDAYS
	 */

	getHolidays( searchDate ) {
		let result = [];

		if( null == searchDate ) {
			// Return all
			result = this.state.data.holidays;
		} else {
			result = this.state.data.holidays.filter( holiday => String(holiday.date).includes(searchDate) );
		}

		// Filter final result by text field
		if( this.state.searchFieldEntry !== "" ) {
			if( true === /^\d+$/.test(this.state.searchFieldEntry) ) {
				// Filter by date
				result = result.filter( holiday => String(holiday.date).includes(this.state.searchFieldEntry));
			} else {
				// Filter by name
				result = result.filter( holiday => String(holiday.name.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase()));
			}
		}

		return result;
	}

	getCustomHolidays() {
		let result = this.state.data.customHolidays;

		// Filter final result by text field
		if( this.state.searchFieldEntry !== "" ) {
			if( true === /^\d+$/.test(this.state.searchFieldEntry) ) {
				// Filter by date
				result = result.filter( customHoliday => String(customHoliday.date).includes(this.state.searchFieldEntry));
			} else {
				// Filter by name
				result = result.filter( customHoliday => String(customHoliday.name.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase()));
			}
		}

		return result;
	}

	/**
	 * HELPERS CALLED DURING RENDER TO RETRIEVE INFO FROM PROCESSED DATA
	 */

	/// Check if a holiday already exists
	doesHolidayExist( date ) {
		let result = {
			exists: false,
		}

		// Check all regular holidays
		if( null != this.state.data.holidays ) {
			let holidays = this.state.data.holidays;

			for( let i = 0; i < holidays.length; i++ ) {
				if( holidays[i].date === date ) {
					result.exists = true;
					break;
				}
			}
		}

		return result;
	}

    /// Check if a custom holiday already exists
    doesCustomHolidayExist( date ) {
		let result = {
			exists: false,
		}

        // Extract the year from the date string and convert the date string to mm-dd
        // since that is the way the custom holidays are saved
        let checkYear = parseInt( date.substring( 0, 4 ) );
        let checkDate = date.slice( 5 );

		// Check all regular holidays
		if( null != this.state.data.customHolidays ) {
			let customHolidays = this.state.data.customHolidays;

			for( let i = 0; i < customHolidays.length; i++ ) {
                if( customHolidays[i].year_start <= checkYear ) {
                    if( customHolidays[i].year_end == null || customHolidays[i].year_end >= checkYear ) {
                        if( customHolidays[i].date === checkDate ) {
                            result.exists = true;
                            break;
                        }
                    }
                }
			}
		}

		return result;
    }

	/**
	 * HANDLERS FOR TABS
	 */

	// Called when the user selects a different tab
	handleTabChange( newValue ) {
		this.setState({ currentTabIndex: newValue });
	}

	// Called when the user selects a different year
	handleTabYearChanged( newYear ) {
		this.setState({ currentYear: newYear });
	}

	// Called when the user clicks on a day on the calendar tab
	handleTabCalendarClick( dateString, holidays ) {

		// If holidays is empty we open the add dialog, if its not empty we open the modify dialog
		if( 0 === holidays.length ) {
			// Open on given date to add a new holiday
			this.openAddCustomDialog( dateString, this.state.data.customHolidays, this.state.data.holidays );
		} else {
			// Open on given date to modify an existing holiday
			// Retrieve the name of the holiday
			let holidays = this.getHolidays( dateString );
			if( holidays.length > 0 ) {
				let name = holidays[0].name;
				this.openModifyDialog( dateString, name, this.state.data.holidays )
			}
		}
	}

	// Called when the user clicks delete on a row in the calendar tab
	handleTabListDelete( date ) {
		this.deleteHoliday( date, false );
	}

	// Called when the user updates a row in the calendar tab
	handleTabListUpdateName( initialDate, name, newName ) {
		this.updateHoliday( initialDate, newName, null, false );
	}

	// Called when the user updates a row in the calendar tab
	handleTabListUpdateDate( initialDate, name, newDate ) {
		this.updateHoliday( initialDate, null, newDate, false );
	}

	// Called when the user clicks delete on a row in the custom holiday tab
	handleTabCustomDelete( date, name, yearStart, yearEnd ) {
		this.deleteCustomHoliday( date, name, yearStart, yearEnd, false );
	}

	// Called when the user updates a row in the custom holiday tab
	handleTabCustomUpdateName( initialDate, name, yearStart, yearEnd, newName ) {
		let updates = {
			name: newName
		}
		this.updateCustomHoliday( initialDate, name, yearStart, yearEnd, updates, false );
	}

	// Called when the user updates a row in the custom holiday tab
	handleTabCustomUpdateDate( initialDate, name, yearStart, yearEnd, newDate ) {
		let updates = {
			date: newDate
		}
		this.updateCustomHoliday( initialDate, name, yearStart, yearEnd, updates, false );
	}

	// Called when the user updates a row in the custom holiday tab
	handleTabCustomUpdateYearStart( initialDate, name, yearStart, yearEnd, newYearStart ) {
		let updates = {
			year_start: parseInt( newYearStart )
		}
		this.updateCustomHoliday( initialDate, name, yearStart, yearEnd, updates, false );
	}

	// Called when the user updates a row in the custom holiday tab
	handleTabCustomUpdateYearEnd( initialDate, name, yearStart, yearEnd, newYearEnd ) {
		let updates = {
			year_end: parseInt( newYearEnd )
		}

		// Clear year_end to null in case its empty
		if( newYearEnd === "" ) {
			updates.year_end = null;
		}

		this.updateCustomHoliday( initialDate, name, yearStart, yearEnd, updates, false );
	}

	// Import an array of holidays from the holiday generqation
	async handleImportHolidays( year, holidays, clearAllBeforeImport, overwriteAlreadyPresent ) {

		// Check input validity
		if( null == year || null == holidays || holidays.length === 0 ) {
			return false;
		}

		this.handleActionInProgressChanged( true );
		let anyBackendCmdExecuted = false;

		// Clear all entries in the given year
		if( true === clearAllBeforeImport && null != this.state.data.holidays ) {

			for( let i = 0; i < this.state.data.holidays.length; i++ ) {
				const dateArray = this.state.data.holidays[i].date.split("-");
				if( dateArray[0] === year ) {
					anyBackendCmdExecuted = true;
					await this.deleteHoliday( this.state.data.holidays[i].date, true );
				}
			}
		}

		// Perform the import, adding new holidays and updating existing ones on the same day depending on overwrite flag
		for( let i = 0; i < holidays.length; i++ ) {

			// Check if holiday on that day already exists (only if we did not clear everything anyway)
			let createHoliday = true;

			if( false === clearAllBeforeImport ) {
				const { exists } = this.doesHolidayExist( holidays[i].date );
				if( true === exists ) {
	
					// Another holiday exists on this day already, check the overwrite flag
					if( true === overwriteAlreadyPresent) {
						anyBackendCmdExecuted = true;
						await this.updateHoliday( holidays[i].date, holidays[i].name, null, true );
					}

					createHoliday = false;
				}
			}

			if( true === createHoliday ) {
				
				// No holiday exists on this day
				anyBackendCmdExecuted = true;
				await this.createHoliday( holidays[i].name, holidays[i].date, true );
			}
		}

		// If no backend command was executed (might happen due to import settings) give the user a response message
		// to show that something happened
		if( false === anyBackendCmdExecuted ) {
			this.handleAlertChanged({ severity: "success", message: i18n.t("pages.admin.holidays.generate.alert.success") });
		}

		this.handleActionInProgressChanged( false );

		// Done, now we can refresh the table data
		await this.refreshTableData();

		return true;
	}

	/**
	 * RENDER FUNCTIONS
	 */

	// Render the tab
	renderTabCalendar() {
		return (
			<div key="calendar-content">
				<HolidaysCalendar
					year={this.state.currentYear}
					language={this.context.language}
					getHolidays={this.getHolidays}
					onEventClick={this.handleTabCalendarClick}
					onYearChanged={this.handleTabYearChanged}
				/>
			</div>
		)
	}

	// Render the tab
	renderTabList() {
		return (
			<div key="list-content">
				<HolidaysList
					year={this.state.currentYear}
					language={this.context.language}
					getHolidays={this.getHolidays}
					onDelete={this.handleTabListDelete}
					onUpdateName={this.handleTabListUpdateName}
					onUpdateDate={this.handleTabListUpdateDate}
					verifyName={(content) => this.verifyList_Name(content)}
					verifyDate={(content) => this.verifyList_Date(content)}
					onYearChanged={this.handleTabYearChanged}
				/>
			</div>
		)
	}

	// Render the tab
	renderTabGenerate() {
		return (
			<div key="generate-content">
				<HolidaysGenerate
					year={this.state.currentYear}
					language={this.context.language}
					getHolidays={this.getHolidays}
					onYearChanged={this.handleTabYearChanged}
					onImport={this.handleImportHolidays}
				/>
			</div>
		)
	}

    // Render the tab
	renderTabCustom() {
		return (
			<div key="custom-content">
				<HolidaysCustom
                    year={this.state.currentYear}
					language={this.context.language}
                    holidays={this.state.data.holidays}
                    getCustomHolidays={this.getCustomHolidays}
                    verifyName={(content) => this.verifyList_Name(content)}
					verifyDateHoliday={(content) => this.verifyList_Date(content)}
					verifyDateCustomHoliday={(content) => this.verifyList_CustomDate(content)}
					onDelete={this.handleTabCustomDelete}
					onUpdateName={this.handleTabCustomUpdateName}
					onUpdateDate={this.handleTabCustomUpdateDate}
					onUpdateYearStart={this.handleTabCustomUpdateYearStart}
					onUpdateYearEnd={this.handleTabCustomUpdateYearEnd}
				/>
			</div>
		)
	}

	// Render all
	render() {
		let tabDescArray = [];
		tabDescArray[0] = i18n.t("pages.admin.holidays.desc.calendar");
		tabDescArray[1] = i18n.t("pages.admin.holidays.desc.list");
		tabDescArray[2] = i18n.t("pages.admin.holidays.desc.generate");
        tabDescArray[3] = i18n.t("pages.admin.holidays.desc.custom");

		let tabHeaderArray = [];
		tabHeaderArray[0] = { label: i18n.t("pages.admin.holidays.tabs.calendar"), value: 0 };
		tabHeaderArray[1] = { label: i18n.t("pages.admin.holidays.tabs.list"), value: 1 };
		tabHeaderArray[2] = { label: i18n.t("pages.admin.holidays.tabs.generate"), value: 2 };
		tabHeaderArray[3] = { label: i18n.t("pages.admin.holidays.tabs.custom"), value: 3 };

		let tabContentArray = [];

		// Fill in description of active tab (we need to do this here instead of later to avoid weird optical bouncing on reload)
		let contentDesc = tabDescArray[ this.state.currentTabIndex ];

		if( null != this.state.data.holidays ) {

			// Render tab content
			let tabRenderFunctions = [ this.renderTabCalendar, this.renderTabList, this.renderTabGenerate, this.renderTabCustom ];

			for( let i = 0; i < tabRenderFunctions.length; i++ ) {
				const tabContent = tabRenderFunctions[i]();
				tabContentArray[i] = tabContent;
			}
		}
		
		return (
			<React.Fragment>
				<div>
					<PageTitle title={i18n.t("pages.admin.holidays.title")} />
					<p>{contentDesc}</p>
					<SearchControls
						label={i18n.t("pages.admin.holidays.search.label")}
						helperText={i18n.t("pages.admin.holidays.search.subtext")}
						addTooltip={i18n.t("pages.admin.holidays.add.button.tooltip")}
						refreshTooltip={i18n.t("buttons.refresh.tooltip")}
						onAdd={this.handleAddButtonClick}
						onRefresh={this.handleRefreshButtonClick}
						onChange={this.handleChangeSearchField} 
					/>
					<TabContainer
						tabIndex={this.state.currentTabIndex}
						tabHeader={tabHeaderArray}
						onChange={this.handleTabChange}
					>
						{tabContentArray.map((tabContent, i) => (
							<div key={"tab-content-"+i}>
								{tabContent}
							</div>
						))}
					</TabContainer>
					<ModalDialog 
						open={this.state.addCustomDialog.open}
						title={i18n.t("pages.admin.holidays.add.dialog.title")}
						descText={i18n.t("pages.admin.holidays.add.dialog.description")}
						buttonLeft={i18n.t("pages.admin.holidays.add.dialog.button_left")}
						buttonRight={i18n.t("pages.admin.holidays.add.dialog.button_right")}
						handleClickClose={() => this.handleAddCustomDialog_Cancel()}
						handleClickLeft={(contents) => this.handleAddCustomDialog_Ok(contents[0].value, contents[1].value, contents[2].checked)}
						handleClickRight={() => this.handleAddCustomDialog_Cancel()}
					>
						<ModalDialogTextField 
							label={i18n.t("pages.admin.holidays.add.dialog.field.name")} 
							verifyContent={(content) => this.verifyCustomDialog_Name(content)}
							maxLength={config.maxTextLengths.holiday.name}
						/>
						<ModalDialogDatePicker 
							language={this.context.language}
							verifyContent={(content) => this.verifyCustomDialog_Date(content)}
							value={this.state.addCustomDialog.date}
                            blockedDates={(this.state.addCustomDialog.allHolidays)}
                            disableToolbar={true}
							limitToCurrentYear={true}
						/>
                        <ModalDialogCheckbox
                            checked={true}
                            label={i18n.t("pages.admin.holidays.add.dialog.field.recurring")}
                        />
					</ModalDialog>
					<ModalDialog 
						open={this.state.modifyDialog.open}
						title={i18n.t("pages.admin.holidays.modify.dialog.title")}
						descText={i18n.t("pages.admin.holidays.modify.dialog.description")}
						buttonLeft={i18n.t("pages.admin.holidays.modify.dialog.button_left")}
						buttonRight={i18n.t("pages.admin.holidays.modify.dialog.button_right")}
						showDeleteButton={true}
						buttonDeleteTooltip={i18n.t("pages.admin.holidays.delete.dialog.button_delete_tooltip")}
						handleClickClose={() => this.handleModifyDialog_Cancel()}
						handleClickLeft={(contents) => this.handleModifyDialog_Ok(this.state.modifyDialog.date, contents[0].value, contents[1].value)}
						handleClickRight={() => this.handleModifyDialog_Cancel()}
						handleClickDelete={() => this.handleModifyDialog_Delete(this.state.modifyDialog.date)}
					>
						<ModalDialogTextField 
							label={i18n.t("pages.admin.holidays.modify.dialog.field.name")} 
							verifyContent={(content) => this.verifyDialog_Name(content)}
							value={this.state.modifyDialog.name}
                            maxLength={config.maxTextLengths.holiday.name}
						/>
						<ModalDialogDatePicker 
							language={this.context.language}
							verifyContent={(content) => this.verifyDialog_Date(content)}
							value={this.state.modifyDialog.date}
							blockedDates={(this.state.modifyDialog.holidays)}
                            allowedDate={(this.state.modifyDialog.date)}
                            disableToolbar={true}
						/>
					</ModalDialog>
				</div>
			</React.Fragment>
		)
	}
};

export default withStyles(useStyles)(AdminHolidays);