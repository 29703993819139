import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PageTitle from '../components/PageTitle';
import SelectYearMonth from '../components/SelectYearMonth';
import AbsenceService from '../classes/AbsenceService'
import SpecialService from '../classes/SpecialService'
import UsersToTeamsService from '../classes/UsersToTeamsService'
import UserTypeService from '../classes/UserTypeService'
import HolidayService from '../classes/HolidayService'
import ContractService from '../classes/ContractService';
import ReportService from '../classes/ReportService';
import UserService from '../classes/UserService'
import AuthContext from '../contexts/AuthContext';
import TabContainer from '../components/TabContainer';
import ModalDialog from '../components/ModalDialog';
import CalendarHelper from '../classes/CalendarHelper.js';
import Grid from '@material-ui/core/Grid';
import ModalDialogKeyboardDatePicker from '../components/ModalDialogKeyboardDatePicker';
import Typography from '@material-ui/core/Typography';
import ModalDialogSelect from '../components/ModalDialogSelect';
import { toJSONLocalDate, toDateObject, invertColorMoreContrast } from '../helpers.js';
import AbsenceMyCalendar from '../components/AbsenceMyCalendar'
import AbsenceCompanyCalendar from '../components/AbsenceCompanyCalendar'
import SpecialLegend from '../components/SpecialLegend'
import UserAbsenceSummary from '../components/UserAbsenceSummary'
import Paper from '@material-ui/core/Paper';
import i18n from 'i18next';
import { config } from '../config';

const useStyles = theme => ({
    tableDiv: {
        marginTop: theme.spacing(4),
    },
    specialItemBox: {
        width: "50px",
        height: "18px",
    },
    specialItemBoxText: {
        fontWeight: 500,
        fontSize: "12px",
    },
    gridContainer: {
        display: 'flex',
        width: "100%"
    },
    gridItemSpecials: {
        width: "30%"
    },
    gridItemSummary: {
        width: "70%"
    },
});

/**
 * Absence Page
 */
class AbsencePage extends Component {
    static contextType = AuthContext;

    // Set in constructor
    state = {}

    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleYearMonthChange = this.handleYearMonthChange.bind( this );
        this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind( this );
        this.getAbsencesForUser = this.getAbsencesForUser.bind( this );
        this.getContractsForUser = this.getContractsForUser.bind( this );
        this.getSpecialDetails = this.getSpecialDetails.bind( this );
        this.handleTabChange = this.handleTabChange.bind( this );
        this.renderTabAbsenceMy = this.renderTabAbsenceMy.bind( this );
        this.renderTabAbsenceCompany = this.renderTabAbsenceCompany.bind( this );
        this.handleModifyAbsence = this.handleModifyAbsence.bind( this );
        this.handleAddModifyDialog_Ok = this.handleAddModifyDialog_Ok.bind( this );
        this.handleAddModifyDialog_Cancel = this.handleAddModifyDialog_Cancel.bind( this );
        this.handleAddModifyDialog_Delete = this.handleAddModifyDialog_Delete.bind( this );
        this.getSelectItemsForSpecials = this.getSelectItemsForSpecials.bind( this );
        this.createAndUpdateAbsenceRange = this.createAndUpdateAbsenceRange.bind( this );
        this.deleteAbsenceRange = this.deleteAbsenceRange.bind( this );
        this.getSpecialIdForVacations = this.getSpecialIdForVacations.bind( this );
        this.getAmountOfBookedVacations = this.getAmountOfBookedVacations.bind( this );
        this.getUserAbsenceSummaryData = this.getUserAbsenceSummaryData.bind( this );

        // Set initial state
        this.state = this.getInitialState();
    }

    /**
     * STATE HANDLING
     */

    // Get the initial state variables of this component
    getInitialState( currentTabIndex ) {
        let tabIndex = 0;
        if( null != currentTabIndex ) {
            tabIndex = currentTabIndex;
        }
        return {
            currentTabIndex: tabIndex,
            data: { // Raw loaded data from the backend
                absences: null,
                specials: null,
                users: null,
                userTypes: null,
                holidays: null,
                contracts: null,
                admin: null,
                usersToTeams: null,
                vacationReport: null,
                accountingReport: null
            },
            users: {
                current: null,
                team: null,
                other: null
            },
            currentMonth: new Date().getMonth(),
            currentYear: new Date().getFullYear(),
            addModifyDialog: {
                userid: null,
                firstname: null,
                lastname: null,
                open: false,
                startDate: null,
                endDate: null,
                selectedSpecial: null,
                showDeleteButton: null
            },
            denyDialog: {
                open: false
            }
        }
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState( currentTabIndex ) {
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
        this.setState( this.getInitialState( currentTabIndex ) );
    }

    /**
     * LINK TO PARENT COMPONENT FUNCTIONS
     */

    // Push data loaded changed to parent
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Push alert to parent
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Propagate information to parent component
    handleActionInProgressChanged( actionInProgress ) {
        if( this.props.actionInProgressChanged ) {
            this.props.actionInProgressChanged( actionInProgress );
        }
    }

    /**
     * LOAD AND PROCESS BACKEND DATA
     */

    // Called when a refresh of the table data is needed due to edits in the table
    async refreshTableData( refreshOnlyAbsences, year, month ) {
        await this.loadDataFromBackend( true, refreshOnlyAbsences, year, month );
    }

    // Gather all data from the backend needed on this page and process them properly
    async loadDataFromBackend( manualRefresh, refreshOnlyAbsences, year, month ) {

        // Alert message to show
        let alert = {
            severity: "info",
            message: ""
        }

        // Determine refresh data range either depending on state variables or on arguments given to this function
        let refreshYear = year;
        let refreshMonth = month;
        if( null == refreshYear ) {
            refreshYear = this.state.currentYear;
        }
        if( null == refreshMonth ) {
            refreshMonth = this.state.currentMonth;
        }
        let minDate = `${refreshYear}-01-01`
        let maxDate = `${refreshYear}-12-31`

        // Gather absence data
        let absenceData = null;
        {
            const { statusCode, statusText, resData } = await AbsenceService.getAbsencesRange( { minDate: minDate, maxDate: maxDate } );
            if( 200 === statusCode && null != resData ) {
                absenceData = resData.getAbsencesRange;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather user data
        let userData = null;
        let userTypeData = null;
        let currentUser = null;
        let teamUsers = [];
        let otherUsers = [];
        let usersToTeamsData = null;

        if( false === refreshOnlyAbsences || null == refreshOnlyAbsences ) {
            {
                const { statusCode, statusText, resData } = await UserService.getUsers( {} );
                if( 200 === statusCode && null != resData ) {
                    userData = resData.getUsers;
                } else {
                    // Error, set the alert right away and abort
                    this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                    return;
                }
            }
            {
                const { statusCode, statusText, resData } = await UserTypeService.getUserTypes( {} );
                if( 200 === statusCode && null != resData ) {
                    userTypeData = resData.getUserTypes;
                } else {
                    // Error, set the alert right away and abort
                    this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                    return;
                }
            }

            // Add user type name to all user data entries
            for( let i = 0; i < userData.length; i++ ) {
                let userTypeName = "";
                for( let j = 0; j < userTypeData.length; j++ ) {
                    if( userTypeData[j].typeid === userData[i].typeid ) {
                        userTypeName = userTypeData[j].name;
                        break;
                    }
                }
                userData[i].userTypeName = userTypeName;
            }

            // Generate user entries depending on current user

            // Get user-to-team data
            {
                const { statusCode, statusText, resData } = await UsersToTeamsService.getUsersToTeams( {} );
                if( 200 === statusCode && null != resData ) {
                    usersToTeamsData = resData.getUsersToTeams;
                } else {
                    // Error, set the alert right away and abort
                    this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                    return;
                }
            }

            // Get info for the current user
            for( let i = 0; i < userData.length; i++ ) {
                if( userData[i].userid === this.context.userid ) {
                    currentUser = userData[i];
                }
            }

            if( null != currentUser ) {
                // Gather team ids of current user
                let currentUserTeamIds = [];

                for( let i = 0; i < usersToTeamsData.length; i++ ) {
                    if( usersToTeamsData[i].userid === currentUser.userid ) {
                        currentUserTeamIds.push( usersToTeamsData[i].teamid );
                    }
                }

                // Gather all team members of the current user
                let teamMembers = [];
                for( let i = 0; i < usersToTeamsData.length; i++ ) {
                    if( usersToTeamsData[i].userid !== currentUser.userid && -1 !== currentUserTeamIds.indexOf( usersToTeamsData[i].teamid ) ) {
                        teamMembers.push( usersToTeamsData[i].userid );
                    }
                }

                for( let i = 0; i < userData.length; i++ ) {
                    if( userData[i].userid !== this.context.userid ) {
                        if( -1 === teamMembers.indexOf( userData[i].userid ) ) {
                            otherUsers.push( userData[i] );
                        } else {
                            teamUsers.push( userData[i] );
                        }
                    }
                }
            }

            // Sort discovered users
            if( null != teamUsers ) {
                teamUsers.sort(function(a, b) { 
                    // Sort by user type first, then by user lastname
                    if( a.userTypeName > b.userTypeName ) return 1;
                    if( a.userTypeName < b.userTypeName ) return -1;
                    if( a.lastname > b.lastname ) return 1;
                    if( a.lastname < b.lastname ) return -1;
                    return 0;
                });
            }
            if( null != otherUsers ) {
                otherUsers.sort(function(a, b) { 
                    // Sort by user type first, then by user lastname
                    if( a.userTypeName > b.userTypeName ) return 1;
                    if( a.userTypeName < b.userTypeName ) return -1;
                    if( a.lastname > b.lastname ) return 1;
                    if( a.lastname < b.lastname ) return -1;
                    return 0;
                });
            }
        }

        // Gather holiday data
        let holidayData = null;
        {
            const { statusCode, statusText, resData } = await HolidayService.getHolidaysRange( { minDate: minDate, maxDate: maxDate } );
            if( 200 === statusCode && null != resData ) {
                holidayData = resData.getHolidaysRange;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather vacation report data
        let vacationReportData = null;
        {
            const { statusCode, statusText, resData } = await ReportService.getVacationReport( { userid: this.context.userid } );
            if( 200 === statusCode && null != resData ) {
                vacationReportData = resData.getVacationReport;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather accounting report data
        let accountingReportData = null;
        {
            const { statusCode, statusText, resData } = await ReportService.getAccountingReport( { userid: this.context.userid } );
            if( 200 === statusCode && null != resData ) {
                accountingReportData = resData.getAccountingReport;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Query contracts data
        let contractData = null;
        if( false === refreshOnlyAbsences || null == refreshOnlyAbsences ) {
            const { statusCode, statusText, resData } = await ContractService.getContracts( {} );
            if( 200 === statusCode && null != resData ) {
                contractData = resData.getContracts;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather special data
        let specialData = null;
        if( false === refreshOnlyAbsences || null == refreshOnlyAbsences ) {
            const { statusCode, statusText, resData } = await SpecialService.getSpecials( {} );
            if( 200 === statusCode && null != resData ) {
                specialData = resData.getSpecials;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather admin data
        let adminData = null;
        if( false === refreshOnlyAbsences || null == refreshOnlyAbsences ) {
            const { statusCode, statusText, userAdminData } = await this.context.services.admin.getAdminData( this.context.userid );
            if( 200 === statusCode && null != userAdminData ) {
                adminData = userAdminData;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Store the data
        if( false === refreshOnlyAbsences || null == refreshOnlyAbsences ) {
            this.setState({
                currentYear: refreshYear, 
                currentMonth: refreshMonth, 
                data: { 
                    contracts: contractData, 
                    absences: absenceData, 
                    specials: specialData, 
                    users: userData,
                    userTypeData: userTypeData,
                    holidays: holidayData,
                    admin: adminData,
                    usersToTeams: usersToTeamsData,
                    vacationReport: vacationReportData,
                    accountingReport: accountingReportData
                },
                users: {
                    current: currentUser,
                    team: teamUsers,
                    other: otherUsers
                }
            });
        } else {
            this.setState({ 
                currentYear: refreshYear, 
                currentMonth: refreshMonth, 
                data: {
                    ...this.state.data,
                    absences: absenceData,
                    holidays: holidayData
                } 
            });
        }

        // All fine, clear the error message (only if not a manual refresh)
        if( false === manualRefresh ) {
            this.handleAlertChanged( alert );
        }
        this.handleDataLoadedChanged( true );
    }

    // Get the special id associated with vacations
    getSpecialIdForVacations( specialData ) {
        if( null == specialData ) {
            return null;
        }

        for( let i = 0; i < specialData.length; i++ ) {
            if( specialData[i].type === config.specialTypeLinkedToVacations ) {
                return specialData[i].specialid;
            }
        }

        return null;
    }

    // Get the booked specials of that user in that given year
    async getAmountOfBookedVacations( userid, specialid, year ) {
        if( null == userid || null == specialid || null == year ) {
            return null;
        }

        const minDate = `${year}-01-01`;
        const maxDate = `${year}-12-31`;

        let absenceData = null;
        {
            const { statusCode, resData } = await AbsenceService.getAbsencesRange( { minDate: minDate, maxDate: maxDate, userid: userid, specialid: specialid } );
            if( 200 === statusCode && null != resData ) {
                absenceData = resData.getAbsencesRange;
            } else {
                return null;
            }
        }

        return absenceData.length;
    }

    /**
     * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
     */

    // Called by react when this component has been mounted
    async componentDidMount() {
        await this.loadDataFromBackend( false, false );
    }

    // Called by react before this component unmounts
    componentWillUnmount() {
        this.resetState();
    }

    /**
     * CALLBACKS FROM TOP LEVEL COMPONENT
     */

    // Called if the refresh button is clicked
    async handleRefreshButtonClick() {
        // Reset state but stay at current tab
        this.resetState( this.state.currentTabIndex );
        
        // Refresh all data
        await this.refreshTableData( false );
    }

    // Called when the year changes
    async handleYearMonthChange( year, month ) {
        // Refresh only certain data, new year and month is set to state by this function
        await this.refreshTableData( true, year, month );
    }

    /**
     * ALERT DIALOG <DENY>
     */

    // Open the dialog
    openDenyDialog() {
        this.setState({ denyDialog: { open: true } });
    }

    // Close the modify alert dialog
    closeDenyDialog() {
        this.setState({ denyDialog: { open: false } });
    }

    /**
     * ALERT DIALOG <ADD-MODIFY>
     */

    // Called to open the dialog
    openAddModifyDialog( userid, startDate, endDate, selectedSpecial ) {
        const { firstname, lastname } = this.getUserName( userid );

        let showDeleteButton = false;
        if( null != selectedSpecial ) {
            showDeleteButton = true;
        }

        let addModifyDialog = {
            userid: userid,
            firstname: firstname,
            lastname: lastname,
            open: true,
            startDate: toJSONLocalDate( new Date( startDate ) ),
            endDate: toJSONLocalDate( new Date( endDate ) ),
            selectedSpecial: selectedSpecial,
            showDeleteButton: showDeleteButton
        }
        this.setState({ addModifyDialog: addModifyDialog });
    }

    // Called when the user clicked "Ok" on the add user dialog
    async handleAddModifyDialog_Ok( userid, content, origStartDate, origEndDate, origSelectedSpecial ) {
        this.closeAddModifyDialog();

        // Verify input data
        if( null == content || content.length < 3 ) {
            return;
        }

        // Grab data
        let startDate = content[0].value;
        let endDate = content[1].value;
        let specialid = content[2].value;

        if( null == startDate || null == endDate || null == specialid ) {
            return;
        }

        /**
         * DELETE EXISTING ABSENCE IF UPDATING
         */

        // Check if we have an original start date and original end date as well as an originally selected special
        if( origStartDate !== "" && origEndDate !== "" && origSelectedSpecial !== "" ) {
            
            // Before creating and updating the new absence data, delete the original one first

            // Make sure start/end date are sorted
            let origStartDateObject = toDateObject( origStartDate );
            let origEndDateObject = toDateObject( origEndDate );
            if( origStartDateObject.getTime() > origEndDateObject.getTime() ) {
                let tmp = origStartDateObject;
                origStartDateObject = origEndDateObject;
                origEndDateObject = tmp;
            }

            // Go through days from start to end and add all absences accordingly
            const fullResult = await this.deleteAbsenceRange( userid, toJSONLocalDate( origStartDateObject ), toJSONLocalDate( origEndDateObject ) );

            // Show error and abort
            if( false === fullResult.result ) {
                // Forward alert
                this.handleAlertChanged( fullResult.alert );

                // Refresh the table data
                await this.refreshTableData( false );
            }
        }

        /**
         * CREATE OR UPDATE THE NEW ABSENCE
         */
        
        // Make sure start/end date are sorted
        let startDateObject = toDateObject( startDate );
        let endDateObject = toDateObject( endDate );
        if( startDateObject.getTime() > endDateObject.getTime() ) {
            let tmp = startDateObject;
            startDateObject = endDateObject;
            endDateObject = tmp;
        }

        // Check which dates to skip in the given range since they are holidays or non-working days
        let skipDates = [];

        // Make deep copy and only create absences for the days which are workdays and no holidays
        // Special case: In state.data.holidays we only loaded the holidays of the currently displayed year
        // in case the start and/or end date of the new absence are within another year we need to load additional holidays
        const { statusCode, statusText, resData } = await HolidayService.getHolidaysRange( { minDate: startDate, maxDate: endDate } );
        if( 200 === statusCode && null != resData ) {
            let holidayData = resData.getHolidaysRange;

            for( let d = new Date( startDateObject ); d <= endDateObject; d.setDate( d.getDate() + 1 ) ) {
                const isHoliday = CalendarHelper.isHoliday( holidayData, d );
                const isWorkDay = CalendarHelper.isWorkDay( this.state.data.contracts, userid, d );
    
                if( true === isHoliday.state || false === isWorkDay ) {
                    skipDates.push( { date: toJSONLocalDate( d ) } );
                }
            }
    
            // Go through days from start to end and add all absences accordingly
            let fullResult = await this.createAndUpdateAbsenceRange( userid, toJSONLocalDate( startDateObject ), toJSONLocalDate( endDateObject ), specialid, skipDates );
    
            // Set proper success alert if applicable
            if( true === fullResult.result ) {
                fullResult.alert.severity = "success";
                fullResult.alert.message = i18n.t( "pages.absence.addmodify.alert.success" );
            }
    
            // Forward alert
            this.handleAlertChanged( fullResult.alert );

        } else {
            // Error, set the alert right away and abort
            this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
        }

        // Refresh the table data
        await this.refreshTableData( false );
    }

    // Called when the user clicked "Delete" on the dialog
    async handleAddModifyDialog_Delete( userid, content ) {
        this.closeAddModifyDialog();
        
        // Verify input data
        if( null == content || content.length < 3 ) {
            return;
        }

        // Grab data
        let startDate = content[0].value;
        let endDate = content[1].value;
        let specialid = content[2].value;

        if( null == startDate || null == endDate || null == specialid ) {
            return;
        }

        // Make sure start/end date are sorted
        let startDateObject = toDateObject( startDate );
        let endDateObject = toDateObject( endDate );
        if( startDateObject.getTime() > endDateObject.getTime() ) {
            let tmp = startDateObject;
            startDateObject = endDateObject;
            endDateObject = tmp;
        }

        // Go through days from start to end and add all absences accordingly
        const fullResult = await this.deleteAbsenceRange( userid, toJSONLocalDate( startDateObject ), toJSONLocalDate( endDateObject ) );

        // Set proper success alert if applicable
        if( true === fullResult.result ) {
            fullResult.alert.severity = "success";
            fullResult.alert.message = i18n.t( "pages.absence.delete.alert.success" );
        }

        // Forward alert
        this.handleAlertChanged( fullResult.alert );

        // Refresh the table data
        await this.refreshTableData( false );
    }

    // Called when the user clicked "Cancel" or close on the dialog
    handleAddModifyDialog_Cancel() {
        this.closeAddModifyDialog();
    }

    // Close the modify alert dialog
    closeAddModifyDialog() {
        this.setState({ addModifyDialog: { ...this.state.addModifyDialog, open: false } });
    }

    /**
     * CALLBACKS FROM MY CALENDAR
     */

    // Create/update an absence for the current user
    handleModifyAbsence( userid, startDate, endDate, hasAbsence ) {

        // Check first if the current user is even allowed to modify the selected absence
        const isUserAllowedToModify = this.isUserAllowedToModifyAbsence( this.context.userid, userid );

        if( true === isUserAllowedToModify ) {
            let selectedSpecial = null;
            if( true === hasAbsence ) {
                // The date range includes an existing absence, pick the first one
                const existingAbsences = this.getAbsencesForUser( userid, startDate, endDate );
                if( existingAbsences.length > 0 ) {
                    selectedSpecial = existingAbsences[0].specialid;
                }
            }

            this.openAddModifyDialog( userid, startDate, endDate, selectedSpecial );

        } else {

            this.openDenyDialog();
        }
    }

    /**
     * HELPERS
     */

    // Check user permissions
    isUserAllowedToModifyAbsence( modifyingUserId, affectedUserId ) {
        if( null == modifyingUserId || null == affectedUserId ) {
            return false;
        }

        // Check if the user tries to modify his/her own absence
        if( modifyingUserId === affectedUserId ) {
            return true;
        }

        if( null == this.state.data.admin ) {
            return false;
        }

        // Check for big admin flags
        if( true === this.state.data.admin.admin.global || true === this.state.data.admin.admin.absences ) {
            return true;
        }

        // Check for team lead
        if( null == this.state.data.usersToTeams ) {
            return false;
        }

        let affectedUserTeams = [];
        
        for( let i = 0; i < this.state.data.usersToTeams.length; i++ ) {
            if( this.state.data.usersToTeams[i].userid === affectedUserId ) {
                affectedUserTeams.push( this.state.data.usersToTeams[i].teamid );
            }
        }

        for( let i = 0; i < this.state.data.admin.teamlead.length; i++ ) {
            if( -1 !== affectedUserTeams.indexOf( this.state.data.admin.teamlead[i] ) ) {
                return true;
            }
        }

        return false;
    }

    // Create and update an absence range
    async createAndUpdateAbsenceRange( userid, startDate, endDate, specialid, skipDates ) {
        if( null == userid || null == startDate || null == endDate || null == specialid || null == skipDates ) {
            return { result: false, alert: { severity: "error", message: i18n.t( "pages.absence.alert.error.no_input_data" ) } };
        }

        const { statusCode, statusText, resData } = await AbsenceService.createAndUpdateAbsenceRange( 
            { userid: userid, minDate: startDate, maxDate: endDate },
            { userid: userid, specialid: specialid },
            { specialid: specialid },
            skipDates );

        let alert = {
            severity: "info",
            message: ""
        }
        let result = false;

        if( 200 === statusCode && null != resData ) {
            if( null != resData.createAndUpdateAbsenceRange ) {
                result = true;
                alert.severity = "success";
                alert.message = i18n.t( "pages.absence.addmodify.alert.success" );
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }
        } else {
            alert.severity = "error";
            alert.message = `${statusText}: ${statusCode}`;
        }

        return { result: result, alert: alert };
    }

    // Delete an absence range
    async deleteAbsenceRange( userid, startDate, endDate ) {
        if( null == userid || null == startDate || null == endDate ) {
            return { result: false, alert: { severity: "error", message: i18n.t( "pages.absence.alert.error.no_input_data" ) } };
        }

        // Delete the specified absence
        const { statusCode, statusText, resData } = await AbsenceService.deleteAbsenceRange( { userid: userid, minDate: startDate, maxDate: endDate } );

        let alert = {
            severity: "info",
            message: ""
        }
        let result = false;

        if( 200 === statusCode && null != resData ) {
            if( true === resData.deleteAbsenceRange.result ) {
                result = true;
                alert.severity = "success";
                alert.message = i18n.t( "pages.absence.delete.alert.success" );
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }
        } else {
            alert.severity = "error";
            alert.message = `${statusText}: ${statusCode}`;
        }

        return { result: result, alert: alert };
    }

    // Get username
    getUserName( userid ) {
        if( null == this.state.data.users ) {
            return { firstname: null, lastname: null };
        }

        for( let i = 0; i < this.state.data.users.length; i++ ) {
            if( this.state.data.users[i].userid === userid ) {
                return { firstname: this.state.data.users[i].firstname, lastname: this.state.data.users[i].lastname }
            }
        }

        return { firstname: null, lastname: null };
    }

    // Get absences for a single user (startDate, endDate are timestamps and optional)
    getAbsencesForUser( userid, startDate, endDate ) {
        let result = [];
        for( let i = 0; i < this.state.data.absences.length; i++ ) {
            if( this.state.data.absences[i].userid === userid ) {
                if( null != startDate || null != endDate ) {
                    const absenceDate = toDateObject( this.state.data.absences[i].date ).getTime();
                    if( null != startDate && startDate > absenceDate ) {
                        continue;
                    }
                    if( null != endDate && endDate < absenceDate ) {
                        continue;
                    }
                }
                result.push( this.state.data.absences[i] );
            }
        }
        return result;
    }

    // Get contracts for a single user
    getContractsForUser( userid ) {
        let result = [];
        for( let i = 0; i < this.state.data.contracts.length; i++ ) {
            if( this.state.data.contracts[i].userid === userid ) {
                result.push( this.state.data.contracts[i] );
            }
        }
        return result;
    }

    // Get details for a special
    getSpecialDetails( specialid ) {
        if( null == this.state.data.specials ) {
            return { name: null, short_name: null, color_code: null };
        }

        for( let i = 0; i < this.state.data.specials.length; i++ ) {
            if( this.state.data.specials[i].specialid === specialid ) {
                return { 
                    name: this.state.data.specials[i].name, 
                    short_name: this.state.data.specials[i].short_name, 
                    color_code: this.state.data.specials[i].color_code 
                };
            }
        }

        // Not found
        return { name: null, short_name: null, color_code: null };
    }

    // Get select items for specials
    getSelectItemsForSpecials( affectedUserId ) {
        let selectItems = [];
        if( null == this.state.data.specials ) {
            return selectItems;
        }

        for( let i = 0; i < this.state.data.specials.length; i++ ) {
            // Skip disabled items
            if( false === this.state.data.specials[i].is_enabled ) {
                continue;
            }

            // Skip items which allow approval and the user is not global admin or admin_specials and we are not the users team lead
            if( true === this.state.data.specials[i].approval_required ) {
                if( false === this.state.data.admin.admin.global && false === this.state.data.admin.admin.absences ) {

                    // Check if the user modify this users absenses is his/her team lead
                    if( null == this.state.data.usersToTeams ) {
                        continue;
                    }

                    let isTeamLeadOfUser = false;
                    let affectedUserTeams = [];
                    
                    for( let i = 0; i < this.state.data.usersToTeams.length; i++ ) {
                        if( this.state.data.usersToTeams[i].userid === affectedUserId ) {
                            affectedUserTeams.push( this.state.data.usersToTeams[i].teamid );
                        }
                    }

                    for( let i = 0; i < this.state.data.admin.teamlead.length; i++ ) {
                        if( -1 !== affectedUserTeams.indexOf( this.state.data.admin.teamlead[i] ) ) {
                            // Modifying user is the users' team lead
                            isTeamLeadOfUser = true;
                        }
                    }
                    
                    if( false === isTeamLeadOfUser ) {
                        continue;
                    }
                }
            }

            const textColor = invertColorMoreContrast( this.state.data.specials[i].color_code, true );
            let specialObject = (
                <div>
                    <Grid container direction="row" spacing={2} justify="flex-start" alignItems="center" >
                        <Grid item>
                            <Paper 
                                elevation={3}
                                square={true} 
                                className={this.props.classes.specialItemBox} 
                                style={{ backgroundColor: `#${this.state.data.specials[i].color_code}`, color: `#${textColor}` }}
                            >
                                <Typography align="center" className={this.props.classes.specialItemBoxText}>
                                    {this.state.data.specials[i].short_name}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item>
                            {this.state.data.specials[i].name}
                        </Grid>
                    </Grid>
                </div>
            )
            selectItems.push( { id: this.state.data.specials[i].specialid, value: specialObject } );
        }

        // Not found
        return selectItems;
    }

    // Get user summary data for given year
    getUserAbsenceSummaryData( year ) {

        // Oldest year is at the beginning of the array
        let daysFromLastYear = 0;
        let daysFromContract = 0;
        let daysAdjusted = 0;
        let daysBooked = 0;
        let daysAvailable = 0;
        let hoursOvertime = 0;

        if( null != this.state.data.vacationReport ) {
            for( let i = 0; i < this.state.data.vacationReport.years.length; i++ ) {
                if( year > this.state.data.vacationReport.years[i].year ) {
                    daysFromLastYear += this.state.data.vacationReport.years[i].days_remaining_in_year;
                } else if( year === this.state.data.vacationReport.years[i].year ) {
                    daysFromContract = this.state.data.vacationReport.years[i].days_available_in_year;
                    daysBooked = this.state.data.vacationReport.years[i].days_booked_in_year;
                    daysAvailable = this.state.data.vacationReport.years[i].days_remaining_in_year;
                    daysAdjusted = this.state.data.vacationReport.years[i].days_adjusted_in_year;
                    break;
                }
            }
        }

        if( null != this.state.data.accountingReport ) {
            hoursOvertime = this.state.data.accountingReport.hours_overtime_today;
        }

        let daysTotal = daysFromLastYear + daysFromContract;
        daysTotal += daysAdjusted;
        daysAvailable += daysFromLastYear;
        
        return {
            daysFromLastYear: daysFromLastYear,
            daysFromContract: daysFromContract,
            daysAdjusted: daysAdjusted,
            daysTotal: daysTotal,
            daysBooked: daysBooked,
            daysAvailable: daysAvailable,
            hoursOvertime: hoursOvertime
        }
    }

	/**
	 * HANDLERS FOR TABS
	 */

	// Called when the user selects a different tab
	handleTabChange( newValue ) {
		this.setState({ currentTabIndex: newValue });
	}
    
	/**
	 * RENDER FUNCTIONS
	 */

	// Render the tab
	renderTabAbsenceMy() {
        const absencesForUser = this.getAbsencesForUser( this.context.userid );
        const contractsForUser = this.getContractsForUser( this.context.userid );
        const userAbsenceSummaryData = this.getUserAbsenceSummaryData( this.state.currentYear );

		return (
			<div key="my-absence-content">
				<AbsenceMyCalendar
					year={this.state.currentYear}
					language={this.context.language}
                    holidays={this.state.data.holidays}
                    absences={absencesForUser}
                    contracts={contractsForUser}
                    specials={this.state.data.specials}
                    labelToday={i18n.t("values.today")}
                    labelNoWorkDay={i18n.t("values.no_work_day")}
                    labelWeekend={i18n.t("values.weekend")}
                    onModifyAbsence={(startDate, endDate, hasAbsence) => this.handleModifyAbsence( this.context.userid, startDate, endDate, hasAbsence )}
				>
                    <Grid container className={this.props.classes.gridContainer} direction="row" spacing={0} wrap='nowrap' justify="flex-start" alignItems="flex-start">
                        <Grid item className={this.props.classes.gridItemSpecials} >
                            <SpecialLegend specials={this.state.data.specials} />
                        </Grid>
                        <Grid item className={this.props.classes.gridItemSummary} >
                            <UserAbsenceSummary year={this.state.currentYear} data={userAbsenceSummaryData} />
                        </Grid>
                    </Grid>
                </AbsenceMyCalendar>
			</div>
		)
	}

	// Render the tab
	renderTabAbsenceCompany() {
        const userAbsenceSummaryData = this.getUserAbsenceSummaryData( this.state.currentYear );
        
		return (
			<div key="company-absence-content">
				<AbsenceCompanyCalendar
					year={this.state.currentYear}
                    month={this.state.currentMonth}
					language={this.context.language}
                    currentUser={this.state.users.current}
                    teamUsers={this.state.users.team}
                    otherUsers={this.state.users.other}
                    holidays={this.state.data.holidays}
                    contracts={this.state.data.contracts}
                    absences={this.state.data.absences}
                    specials={this.state.data.specials}
                    labelToday={i18n.t("values.today")}
                    labelNoWorkDay={i18n.t("values.no_work_day")}
                    labelWeekend={i18n.t("values.weekend")}
                    onModifyAbsence={(startDate, endDate, hasAbsence, userid) => this.handleModifyAbsence( userid, startDate, endDate, hasAbsence )}
				>
                    <Grid container className={this.props.classes.gridContainer} direction="row" spacing={0} wrap='nowrap' justify="flex-start" alignItems="flex-start">
                        <Grid item className={this.props.classes.gridItemSpecials} >
                            <SpecialLegend specials={this.state.data.specials} />
                        </Grid>
                        <Grid item className={this.props.classes.gridItemSummary} >
                            <UserAbsenceSummary year={this.state.currentYear} data={userAbsenceSummaryData} />
                        </Grid>
                    </Grid>
                </AbsenceCompanyCalendar>
			</div>
		)
	}

    // React render
    render() {
        let tabControlArray = [];
		tabControlArray[0] = (
            <SelectYearMonth 
                language={this.context.language}
                variant="year-only"
                year={this.state.currentYear}
                month={this.state.currentMonth}
                maxYear={(new Date().getFullYear())+1}
                labelToday={i18n.t("values.today")}
                onYearMonthChange={(year, month) => this.handleYearMonthChange( year, month )}
                onRefresh={() => this.handleRefreshButtonClick()}
                refreshTooltip={i18n.t("buttons.refresh.tooltip")}
            />
        )
		tabControlArray[1] = (
            <SelectYearMonth 
                language={this.context.language}
                variant="year-month"
                year={this.state.currentYear}
                month={this.state.currentMonth}
                maxYear={(new Date().getFullYear())+1}
                labelToday={i18n.t("values.today")}
                onYearMonthChange={(year, month) => this.handleYearMonthChange( year, month )}
                onRefresh={() => this.handleRefreshButtonClick()}
                refreshTooltip={i18n.t("buttons.refresh.tooltip")}
            />
        )

		let tabHeaderArray = [];
		tabHeaderArray[0] = { label: i18n.t("pages.absence.tabs.my_calendar"), value: 0 };
		tabHeaderArray[1] = { label: i18n.t("pages.absence.tabs.company_calendar"), value: 1 };

		let tabContentArray = [];

		// Fill in description of active tab (we need to do this here instead of later to avoid weird optical bouncing on reload)
		let contentTabControl = tabControlArray[ this.state.currentTabIndex ];

		if( null != this.state.data.absences ) {

			// Render tab content
			let tabRenderFunctions = [ this.renderTabAbsenceMy, this.renderTabAbsenceCompany ];

			for( let i = 0; i < tabRenderFunctions.length; i++ ) {
				const tabContent = tabRenderFunctions[i]();
				tabContentArray[i] = tabContent;
			}
		}

        // Create the dialogs
        let affectedUserId = -1
        if( true === this.state.addModifyDialog.open ) {
            affectedUserId = this.state.addModifyDialog.userid
        }
        let selectSpecialItems = this.getSelectItemsForSpecials( affectedUserId );

        // Handle special case for modify dialog
        // An already present absence item in the calendar might get disabled later on
        // causing the disabled item to be the selectedSpecial but it won't appear in the selectSpecialItems list
        let selectedSpecial = this.state.addModifyDialog.selectedSpecial;
        let isSelectedSpecialAvailable = false;
        for( let i = 0; i < selectSpecialItems.length; i++ ) {
            if( selectSpecialItems[i].id === selectedSpecial ) {
                isSelectedSpecialAvailable = true;
            }
        }
        if( false === isSelectedSpecialAvailable ) {
            selectedSpecial = "";
        }

        let contentAddModifyDialog = (
            <ModalDialog 
                open={this.state.addModifyDialog.open}
                title={i18n.t("pages.absence.addmodify.dialog.title")}
                descText={i18n.t("pages.absence.addmodify.dialog.description", { firstname: this.state.addModifyDialog.firstname, lastname: this.state.addModifyDialog.lastname } )}
                buttonLeft={i18n.t("pages.absence.addmodify.dialog.button_left")}
                buttonRight={i18n.t("pages.absence.addmodify.dialog.button_right")}
                showDeleteButton={this.state.addModifyDialog.showDeleteButton}
                buttonDeleteTooltip={i18n.t("pages.absence.addmodify.dialog.button_delete_tooltip")}
                handleClickClose={() => this.handleAddModifyDialog_Cancel()}
                handleClickLeft={(content) => this.handleAddModifyDialog_Ok(this.state.addModifyDialog.userid, content, this.state.addModifyDialog.startDate, this.state.addModifyDialog.endDate, selectedSpecial)}
                handleClickRight={() => this.handleAddModifyDialog_Cancel()}
                handleClickDelete={(content) => this.handleAddModifyDialog_Delete(this.state.addModifyDialog.userid, content)}
            >
                <ModalDialogKeyboardDatePicker 
					language={this.context.language}
					value={this.state.addModifyDialog.startDate}
                    disableToolbar={true}
                    allowEmptyDate={false}
                    label={i18n.t("pages.absence.addmodify.dialog.field_startdate")}
				/>
                <ModalDialogKeyboardDatePicker 
					language={this.context.language}
					value={this.state.addModifyDialog.endDate}
                    disableToolbar={true}
                    allowEmptyDate={false}
                    label={i18n.t("pages.absence.addmodify.dialog.field_enddate")}
				/>
                <ModalDialogSelect
                    label={i18n.t("pages.absence.addmodify.dialog.field_absenceoption")}
                    value={selectedSpecial}
                    items={selectSpecialItems}
                />
            </ModalDialog>
        );

        let contentDenyDialog = ( 
            <ModalDialog 
                open={this.state.denyDialog.open}
                title={i18n.t("pages.absence.deny.dialog.title")}
                descText={i18n.t("pages.absence.deny.dialog.description")}
                buttonRight={i18n.t("pages.absence.deny.dialog.button_right")}
                showButtonLeft={false}
                handleClickClose={() => this.closeDenyDialog()}
                handleClickRight={() => this.closeDenyDialog()}
            />
        );
		
		return (
			<React.Fragment>
				<div>
					<PageTitle title={i18n.t("pages.absence.title")} />
                    {contentTabControl}
					<TabContainer
						tabIndex={this.state.currentTabIndex}
						tabHeader={tabHeaderArray}
						onChange={this.handleTabChange}
					>
						{tabContentArray.map((tabContent, i) => (
							<div key={"tab-content-"+i}>
								{tabContent}
							</div>
						))}
					</TabContainer>
                    {contentAddModifyDialog}
                    {contentDenyDialog}
				</div>
			</React.Fragment>
		)
    }
}

export default withStyles(useStyles)(AbsencePage);