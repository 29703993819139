import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Date only display
 */
class DateOnly extends Component {
    render() {
        // Build the final value string
        let valueString = i18n.t("values.none");

        // If a value has been given format it according to the set language
        if( null != this.props.value && this.props.value !== "" ) {

            let formatProps = {
                year: "numeric", 
                month: "short", 
                day: "2-digit"
            }

            let showYear = true;
            if( this.props.showYear != null && this.props.showYear === false ) {
                showYear = false;
            }

            let showNameOfDay = false;
            if( this.props.showNameOfDay != null && this.props.showNameOfDay === true ) {
                showNameOfDay = true;
            }

            if( false === showYear ) {
                delete formatProps.year;
            }

            if( true === showNameOfDay ) {
                formatProps.weekday = "short";
            }
            

            valueString = new Intl.DateTimeFormat(this.props.language, formatProps).format( new Date( this.props.value ) );
        }

        // Add the prefix if one has been given and if also a value has been given
        let fullString = "";
        if( null != this.props.prefix && null != this.props.value && this.props.value !== "" ) {
            fullString = this.props.prefix;
            fullString += " ";
        }
        fullString += valueString;

        // Variant
        let variant = "inherit";
        if( null != this.props.variant ) {
            variant = this.props.variant;
        }

        return (
            <Typography align={this.props.align} variant={variant}>
                {fullString}
            </Typography>
        );
    }
};

export default withStyles(useStyles)(DateOnly);