import React, { Component } from 'react';
import AuthContext from '../contexts/AuthContext';
import { config } from '../config.js';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Copyright from '../components/Copyright'
import AlertMessage from '../components/AlertMessage'
import i18n from 'i18next';
import Container from '@material-ui/core/Container';

const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    error: {
        margin: theme.spacing(2)
    }
});

/**
 * Force Password Page
 */
class ForcePasswordPage extends Component {
    static contextType = AuthContext;

    state = {
        password: "",
        passwordConfirm: ""
    }

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind( this );
        this.handleChange = this.handleChange.bind( this );

        // Set the language here initially, we need that since we are not logged in yet and do not know the users language settings
        i18n.changeLanguage(config.frontendFallbackLanguage);
    }

    // Handle change of values
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    // Handle submit button click
    async handleSubmit(event) {
        event.preventDefault();
        
        const password = this.state.password;

        if( password.trim().length === 0 ) {
            // Empty contents on at least one mandatory field, simply show the form again
            return;
        }

        // Change the password
        const success = await this.context.updatePassword( password );

        // Return the user to login page after a short delay
        if( true === success ) {
            let tID = setTimeout(function () {
                window.location.href = "/";
                window.clearTimeout(tID);		// clear time out.
            }, 2000);
        }
    }

    // Check if submit button is enabled
    isSubmitButtonEnabled() {
        if( this.state.password !== "" && this.state.passwordConfirm !== "" && 
            this.state.password === this.state.passwordConfirm &&
            this.state.password.length >= config.userPassword.minLength && this.state.passwordConfirm.length >= config.userPassword.minLength ) {
            return true;
        }
        return false;
    }

    // Get the helper text of the password field depending on what has been entered there
    getNewPasswordHelperText() {
        if( this.state.password === "" ) {
            return i18n.t("reset_password.errors.new_password_empty");
        }
        if( this.state.password.length < config.userPassword.minLength ) {
            return i18n.t("reset_password.errors.password_min_length", { minLength: config.userPassword.minLength });
        }
        if( this.state.password !== this.state.passwordConfirm ) {
            return i18n.t("reset_password.errors.passwords_no_match");
        }
        return " ";
    }

    // Get the helper text of the password field depending on what has been entered there
    getConfirmPasswordHelperText() {
        if( this.state.passwordConfirm === "" ) {
            return i18n.t("reset_password.errors.confirm_password_empty");
        }
        if( this.state.passwordConfirm.length < config.userPassword.minLength ) {
            return i18n.t("reset_password.errors.password_min_length", { minLength: config.userPassword.minLength });
        }
        if( this.state.password !== this.state.passwordConfirm ) {
            return i18n.t("reset_password.errors.passwords_no_match");
        }
        return " ";
    }

    // React render
    render() {
        const isSubmitButtonEnabled = this.isSubmitButtonEnabled();
        const { classes } = this.props;

        return (
            <AuthContext.Consumer>
                {context => {
                    return (
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <div className={classes.paper}>
                                <Avatar className={classes.avatar}>
                                    <LockOutlinedIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5" align="center">
                                    {config.mainTitle}<br/>{i18n.t("force_password.title")}
                                </Typography>
                                <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                                    <TextField
                                        value={this.state.password}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label={i18n.t("force_password.password_new")}
                                        type="password"
                                        id="password"
                                        onChange={this.handleChange}
                                        helperText={this.getNewPasswordHelperText()}
                                        error={this.state.password === "" || this.state.password !== this.state.passwordConfirm || this.state.password.length < config.userPassword.minLength}
                                    />
                                    <TextField
                                        value={this.state.passwordConfirm}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="passwordConfirm"
                                        label={i18n.t("force_password.password_confirm")}
                                        type="password"
                                        id="passwordConfirm"
                                        onChange={this.handleChange}
                                        helperText={this.getConfirmPasswordHelperText()}
                                        error={this.state.passwordConfirm === "" || this.state.password !== this.state.passwordConfirm || this.state.passwordConfirm.length < config.userPassword.minLength }
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={!isSubmitButtonEnabled}
                                    >
                                        {i18n.t("submit")}
                                    </Button>
                                </form>
                            </div>
                            <Copyright />
                            <AlertMessage severity="error" message={context.errorMessage} />
                            <AlertMessage severity="success" message={context.successMessage} />
                        </Container>
                    );
                }}
            </AuthContext.Consumer>
        );     
    }
}

export default withStyles(useStyles)(ForcePasswordPage);