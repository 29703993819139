import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SearchControls from '../components/SearchControls';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import { StyledTableCell, StyledTableRow, StyledTableHead, StyledTable, StyledTableSortLabel, stableSort, getComparator } from '../components/StyledTable.js';
import { toDateObject } from '../helpers.js';
import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@material-ui/core/Grid';
import i18n from 'i18next';

const useStyles = theme => ({
    tablePaginationGrid: {
        height: '60px',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tablePagination: {
        '& .MuiTablePagination-toolbar': {
            height: '60px',
            minHeight: '60px',
        },
    },
    tableCell: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1.0),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5)
    },
});

/**
 * Table Project Accounting
 */
class TableProjectAccounting extends Component {
    state = {}

    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleChangeTablePage = this.handleChangeTablePage.bind( this );
        this.handleChangeTableRowsPerPage = this.handleChangeTableRowsPerPage.bind( this );
        this.handleChangeSearchField = this.handleChangeSearchField.bind( this );
        this.handleRequestSort = this.handleRequestSort.bind( this );

        // Set initial state
        this.state = this.getInitialState();
    }

    /**
     * STATE HANDLING
     */

    // Get the initial state variables of this component
    getInitialState() {
        return {
            searchFieldEntry: "",
            tablePage: 0,
            tableRowsPerPage: 10,
            order: "asc",
            orderBy: 0
        }
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState() {
        this.setState( this.getInitialState() );
    }

    /**
     * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
     */

    // Called by react before this component unmounts
    componentWillUnmount() {
        this.resetState();
    }

    /**
     * CALLBACK FUNCTIONS FOR SEARCH FIELD
     */

    // Called when text is entered in the search field
    handleChangeSearchField(value) {
        this.setState({ searchFieldEntry: value });
    }

    /**
     * DATA SORT
     */

    // Handle table sort
    handleRequestSort( event, property ) {

        // Check if clicked on same property
        if( this.state.orderBy === property ) {

            // User clicked on same property
            // If already sorted in ascending order, switch to descending order
            if( this.state.order === 'asc' ) {
                this.setState({ order: 'desc', orderBy: property });
            } else {

                // If already sorted in descending order, switch to no order
                this.setState({ order: 'asc', orderBy: 0 });
            }

        } else {
            // User clicked on new property, sort new property in ascending order
            this.setState({ order: 'asc', orderBy: property });
        }
    };

    // Sort the given table data
    sortTableData( entries ) {
        // Handle case where no ordering is selected
        if( this.state.orderBy === 0 ) {
            return entries.sort( 
                function(a, b) {
                    if( a.date === b.date ) {
                        // We are on the same date, sort by taskname
                        return a.taskname > b.taskname ? 1 : -1;
                    } else {
                        // We are not on the same date, sort by date
                        return toDateObject(a.date) > toDateObject(b.date) ? 1 : -1;
                    }
                }
            );
        }
        return stableSort( entries, getComparator(this.state.order, this.state.orderBy) );
    }

    /**
     * TABLE PAGINATION EVENT HANDLERS
     */

    // Called if page changes on the table display occur
    handleChangeTablePage = (event, newPage) => {
        this.setState({tablePage: newPage});
    };
    
    // Called if the user selects a different amount of shown rows
    handleChangeTableRowsPerPage = (event) => {
        this.setState({tablePage: 0, tableRowsPerPage: +event.target.value});
    };

    /**
     * GETTERS
     */

    // Get the full username including the user type for the given userid
    getUserNameForId( userid ) {
        let username = "";

        if( null != this.props.users ) {
            for( let i = 0; i < this.props.users.length; i++ ) {
                if( this.props.users[i].userid === userid ) {
                    username = this.props.users[i].lastname+", "+this.props.users[i].firstname;
                    if( this.props.users[i].userTypeName != null && this.props.users[i].userTypeName !== "" ) {
                        username = "(" + this.props.users[i].userTypeName + ") " + this.props.users[i].lastname + ", " + this.props.users[i].firstname;
                    }

                    break;
                }
            }
        }

        return username;
    }

    // Get the taskname for the given taskid
    getTaskNameForId( taskid ) {
        let taskname = "";

        if( null != this.props.tasks ) {
            for( let i = 0; i < this.props.tasks.length; i++ ) {
                if( this.props.tasks[i].taskid === taskid ) {
                    taskname = this.props.tasks[i].name;
                    break;
                }
            }
        }

        return taskname;
    }

    // Get the table entries depending on current selections by the user
    getTableEntries() {
        let entries = [];

        // Available props
        // language, accountings, users, tasks, minDate, maxDate, selectedProject, selectedTask (optional), selectedUser (optional)
        if( null != this.props.accountings ) {
            for( let i = 0; i < this.props.accountings.length; i++ ) {

                if( null == this.props.selectedTask || this.props.accountings[i].taskid === this.props.selectedTask ) {
                    if( null == this.props.selectedUser || this.props.accountings[i].userid === this.props.selectedUser ) {
                        
                        // Found a fitting accounting, create the entry
                        let entry = {
                            accountingid: this.props.accountings[i].accountingid,
                            date: this.props.accountings[i].date,
                            taskname: this.getTaskNameForId( this.props.accountings[i].taskid ),
                            username: this.getUserNameForId( this.props.accountings[i].userid ),
                            hours: this.props.accountings[i].hours,
                            comment: this.props.accountings[i].comment
                        }

                        entries.push( entry );
                    }
                }
            }
        }

        // Already do a basic sort of the entries
        return entries.sort( 
            function(a, b) {
                if( a.date === b.date ) {
                    // We are on the same date, sort by taskname
                    return a.taskname > b.taskname ? 1 : -1;
                } else {
                    // We are not on the same date, sort by date
                    return toDateObject(a.date) > toDateObject(b.date) ? 1 : -1;
                }
            }
        );
    }

    /**
     * RENDERERS
     */

    // Render accounting table
    renderAccountingTable() {
        
        // Assemble table content
        const tableHeader = [
            { key: "date", span: 1, align: "left", sortable: true, style: {}, text: i18n.t("pages.reports.project_reports.accountings.table.header.date") },
            { key: "taskname", span: 1, align: "left", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.reports.project_reports.accountings.table.header.task") },
            { key: "username", span: 1, align: "left", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "5px" }, text: i18n.t("pages.reports.project_reports.accountings.table.header.user") },
            { key: "hours", span: 1, align: "left", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "5px" }, text: i18n.t("pages.reports.project_reports.accountings.table.header.hours") },
            { key: "comment", span: 1, align: "left", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "5px" }, text: i18n.t("pages.reports.project_reports.accountings.table.header.comment") },
        ];

        /**
         * Each entry has the following fields:
         * * accountingid (uuid)
         * * date (json format)
         * * taskname (string)
         * * username (already combined string)
         * * hours (float)
         * * comment (string)
         */
        let tableEntries = this.getTableEntries();

        // Filter table data if any search entry has been given
        if( this.state.searchFieldEntry !== "" ) {
            tableEntries = tableEntries.filter( entry => ( 
                String(entry.date.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase()) || 
                String(entry.taskname.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase()) || 
                String(entry.username.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase()) || 
                String(entry.hours).includes(this.state.searchFieldEntry.toLowerCase()) || 
                String(entry.comment.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase())
            ));
        }

        // Sort the entries depending on selection
        const tableEntriesSorted = this.sortTableData( tableEntries );

        return (
            <div>
                <TableContainer style={{ minHeight: "350px" }} component={Paper}>
                    <StyledTable size="small" >
                        <StyledTableHead>
                            <StyledTableRow key={"project-accountings-header"}>
                                {tableHeader.map((col) => (
                                    <StyledTableCell 
                                        key={col.key} 
                                        colSpan={col.span} 
                                        align={col.align} 
                                        style={col.style}
                                    >
                                        <StyledTableSortLabel
                                            active={this.state.orderBy === col.key}
                                            hideSortIcon={false}
                                            direction={this.state.orderBy === col.key ? this.state.order : 'asc'}
                                            onClick={(event) => this.handleRequestSort(event, col.key)}
                                            disabled={!(col.sortable)}
                                        >
                                            {col.text}
                                        </StyledTableSortLabel>
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                            {tableEntriesSorted.slice(this.state.tablePage * this.state.tableRowsPerPage, this.state.tablePage * this.state.tableRowsPerPage + this.state.tableRowsPerPage).map((entry) => {
                                return (
                                    <StyledTableRow key={entry.accountingid}>
                                        <StyledTableCell className={this.props.classes.tableCell} key="date" width="5%">
                                            {entry.date}
                                        </StyledTableCell>
                                        <StyledTableCell className={this.props.classes.tableCell} key="taskname" width="10%">
                                            {entry.taskname}
                                        </StyledTableCell>
                                        <StyledTableCell className={this.props.classes.tableCell} key="username" width="20%">
                                            {entry.username}
                                        </StyledTableCell>
                                        <StyledTableCell className={this.props.classes.tableCell} key="hours" width="10%">
                                            {entry.hours}
                                        </StyledTableCell>
                                        <StyledTableCell className={this.props.classes.tableCell} key="comment" width="55%">
                                            {entry.comment}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </StyledTable>
                </TableContainer>
                <Grid container direction="row" justify="flex-end" className={this.props.classes.tablePaginationGrid} spacing={1} wrap='nowrap'>
                    <Grid item>
                        <TablePagination
                            className={this.props.classes.tablePagination}
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={tableEntries.length}
                            rowsPerPage={this.state.tableRowsPerPage}
                            labelRowsPerPage={i18n.t("table.pagination.rows_per_page")}
                            page={this.state.tablePage}
                            onChangePage={this.handleChangeTablePage}
                            onChangeRowsPerPage={this.handleChangeTableRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }

    // Takes props: language, accountings, users, userTypes, tasks, minDate, maxDate, selectedProject, selectedTask (optional), selectedUser (optional)
    render() {
        let accountingTable = null;
        let searchControls = null;

        if( null != this.props.accountings && null != this.props.minDate && null != this.props.maxDate ) {
            if( this.props.accountings.length > 0 ) {
                accountingTable = this.renderAccountingTable();
                searchControls = (
                    <SearchControls
                        label={i18n.t("pages.reports.project_reports.search.label")}
                        helperText={i18n.t("pages.reports.project_reports.search.subtext")}
                        showAddButton={false}
                        showRefreshButton={false}
                        onChange={this.handleChangeSearchField} 
                    />
                )
            }
        }

        return (
            <Grid item container justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12}>
                    {searchControls}
                </Grid>
                <Grid item xs={12}>
                    {accountingTable}
                </Grid>
            </Grid>
        );
    }
};

export default withStyles(useStyles)(TableProjectAccounting);