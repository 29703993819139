import React, { Component } from 'react';
import ProjectService from '../classes/ProjectService';
import ReportService from '../classes/ReportService';
import UsersToTasksService from '../classes/UsersToTasksService';
import UsersToProjectsService from '../classes/UsersToProjectsService';
import UserService from '../classes/UserService';
import UserTypeService from '../classes/UserTypeService';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import TaskService from '../classes/TaskService';
import AuthContext from '../contexts/AuthContext';
import SelectControls from '../components/SelectControls';
import ResetButton from '../components/ResetButton';
import AccountingService from '../classes/AccountingService';
import ReportProjectOverview from '../components/ReportProjectOverview';
import ReportProjectTaskDetail from '../components/ReportProjectTaskDetail';
import ReportProjectUserDetail from '../components/ReportProjectUserDetail';
import TableProjectAccounting from '../components/TableProjectAccounting';
import ExportButtons from '../components/ExportButtons';
import { CSVLink } from 'react-csv';
import Grid from '@material-ui/core/Grid';
import { toJSONLocalDate } from '../helpers.js';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { getDateLocale } from '../config.js';
import { withStyles } from '@material-ui/core/styles';
import i18n from 'i18next';

const useStyles = theme => ({
	mainGrid: {
		display: 'flex',
		height: '100%',
		width: '100%',
		margin: theme.spacing(0),
		justifyContent: 'space-between',
		'& > *': {
			margin: theme.spacing(0)
		},
	},
	select: {
		minWidth: '100%',
		maxWidth: '100%'
	},
    datePicker: {
        '& .MuiOutlinedInput-root': {
            height: '40px',
            maxWidth: '20ch'
        }
    },
    link: {
        color: "#ffffff",
        "&:hover": {
            color: "#ffffff"
        },
    }
});

/**
 * Reports Project
 */
class ReportsProject extends Component {
	static contextType = AuthContext;

	state = {}

	constructor(props) {
		super(props);

		// All function bindings to make them accessible
        this.handleChangeSelectProject = this.handleChangeSelectProject.bind( this );
        this.handleChangeSelectTask = this.handleChangeSelectTask.bind( this );
        this.handleChangeSelectUser = this.handleChangeSelectUser.bind( this );
        this.handleChangeSelectDateMin = this.handleChangeSelectDateMin.bind( this );
        this.handleChangeSelectDateMax = this.handleChangeSelectDateMax.bind( this );
        this.handleResetDateButtonClick = this.handleResetDateButtonClick.bind( this );
		this.handleOptionChange = this.handleOptionChange.bind( this );
        this.handleCsvExportClick = this.handleCsvExportClick.bind( this );
        this.getTasksOfProject = this.getTasksOfProject.bind( this );

        // References to call childs
        this.tableProjectAccountingChildRef = React.createRef();
        this.csvLinkRef = React.createRef();

		// Set initial state
		this.state = this.getInitialState();
	}

	/**
	 * STATE HANDLING
	 */

	// Get the initial state variables of this component
	getInitialState() {
		return {
            selectedDate: {
                min: null,
                max: null
            },
            selectedProject: null,
            selectedTask: null,
            selectedUser: null,
            selectableProjects: [],
            selectedOption: "overview",
            projectReport: null,
            projectAccountings: null,
			data: { // Raw loaded data from the backend
                admin: null,
                projects: null,
                tasks: null,
                usersToTasks: null,
                usersToProjects: null,
                users: null,
                userTypes: null
			},
            export: {
                csv: {
                    filename: "",
                    data: []
                }
            }
		}
	}

	// Reset all internal states to initial values and also values propagated to parent
	resetState() {
		this.handleAlertChanged({ severity: "info", message: "" });
		this.handleDataLoadedChanged( false );
		this.setState( this.getInitialState() );
	}

	/**
	 * LINK TO PARENT COMPONENT FUNCTIONS
	 */

	// Push data loaded changed to parent
	handleDataLoadedChanged( dataLoaded ) {
		if( this.props.dataLoadedChanged ) {
			this.props.dataLoadedChanged( dataLoaded );
		}
	}

	// Push alert to parent
	handleAlertChanged( alert ) {
		if( this.props.alertChanged ) {
			this.props.alertChanged( alert );
		}
	}

	// Propagate information to parent component
	handleActionInProgressChanged( actionInProgress ) {
		if( this.props.actionInProgressChanged ) {
			this.props.actionInProgressChanged( actionInProgress );
		}
	}

	/**
	 * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
	 */

	// Called by react when this component has been mounted
	async componentDidMount() {
		// Refresh all data from backend
		await this.refreshData();
	}

	// Called by react before this component unmounts
	componentWillUnmount() {
		this.resetState();
	}

	/**
	 * HANDLERS 
	 */

	// Called when the user selects a new project
	async handleChangeSelectProject( projectid ) {
        await this.refreshProjectData( projectid, null, null );
	}

	// Called when the user selects a new task
	handleChangeSelectTask( taskid ) {
		this.setState({ selectedTask: taskid });
	}

    // Called when the user selects a new user
	handleChangeSelectUser( userid ) {
		this.setState({ selectedUser: userid });
	}

	// Called when the user selects a report option
	handleOptionChange( event, option ) {
        if( null !== option ) {
            this.setState({ selectedOption: option });
        }
	}

    // Called when user clicks the reset date button
    async handleResetDateButtonClick( event ) {
        if( null != this.state.projectReport ) {
            await this.refreshProjectData( this.state.selectedProject, null, null );
        }
    }

    // New start date selected
    async handleChangeSelectDateMin( value ) {
        const newDateMin = toJSONLocalDate( value );
        await this.refreshProjectData( this.state.selectedProject, newDateMin, this.state.selectedDate.max );
    }

    // New end date selected
    async handleChangeSelectDateMax( value ) {
        const newDateMax = toJSONLocalDate( value );
        await this.refreshProjectData( this.state.selectedProject, this.state.selectedDate.min, newDateMax );
    }

	/**
	 * LOAD AND PROCESS BACKEND DATA
	 */

    // Gather project data from backend
    async refreshProjectData( projectid, searchDateMin, searchDateMax ) {
        this.handleDataLoadedChanged( false );

        this.setState({ projectReport: null });

        let projectReportData = null;
        if( null != projectid ) {
            // Gather project report data            
            const { statusCode, statusText, resData } = await ReportService.getProjectReport( { projectid: projectid, minDate: searchDateMin, maxDate: searchDateMax } );
            if( 200 === statusCode && null != resData ) {
                projectReportData = resData.getProjectReport;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Use the proper selected date data
        let selectedDateData = {
            min: projectReportData.resultProject.minDate,
            max: projectReportData.resultProject.maxDate
        }
        if( null !== projectReportData.searchValues.minDate ) {
            selectedDateData.min = projectReportData.searchValues.minDate;
        }
        if( null !== projectReportData.searchValues.maxDate ) {
            selectedDateData.max = projectReportData.searchValues.maxDate;
        }

        // Get all the accountings for the project
        let projectAccountings = null;
        {
            const { statusCode, statusText, resData } = await AccountingService.getAccountingsForProject( { minDate: selectedDateData.min, maxDate: selectedDateData.max, projectid: projectid } );
            if( 200 === statusCode && null != resData ) {
                projectAccountings = resData.getAccountingsForProject;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
            }
        }

		this.setState({ selectedDate: selectedDateData, projectReport: projectReportData, selectedProject: projectid, projectAccountings: projectAccountings });

        this.handleDataLoadedChanged( true );
    }

	// Gather all data from the backend
	async refreshData() {
        this.handleDataLoadedChanged( false );

        // Gather project data
        let projectData = null;
        {
            const { statusCode, statusText, resData } = await ProjectService.getProjects( {} );
            if( 200 === statusCode && null != resData ) {
                projectData = resData.getProjects;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
            projectData.sort(function(a, b) { return a.internal_id > b.internal_id ? 1 : -1; });
        }

        // Gather task data
        let taskData = null;
        {
            const { statusCode, statusText, resData } = await TaskService.getTasks( {} );
            if( 200 === statusCode && null != resData ) {
                taskData = resData.getTasks;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
            taskData.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
        }

        // Get users to tasks data
        let usersToTasksData = null;
        {
            const { statusCode, statusText, resData } = await UsersToTasksService.getUsersToTasks( {} );
            if( 200 === statusCode && null != resData ) {
                usersToTasksData = resData.getUsersToTasks;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Get users to projects data
        let usersToProjectsData = null;
        {
            const { statusCode, statusText, resData } = await UsersToProjectsService.getUsersToProjects( {} );
            if( 200 === statusCode && null != resData ) {
                usersToProjectsData = resData.getUsersToProjects;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather user data
        let userData = null;
        {
            const { statusCode, statusText, resData } = await UserService.getUsers( {} );
            if( 200 === statusCode && null != resData ) {
                userData = resData.getUsers;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
            userData.sort(function(a, b) { return a.lastname > b.lastname ? 1 : -1; });
        }

        // Gather user type data
        let userTypeData = null;
        {
            const { statusCode, statusText, resData } = await UserTypeService.getUserTypes( {} );
            if( 200 === statusCode && null != resData ) {
                userTypeData = resData.getUserTypes;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

		// Gather admin data for the currently logged in user
		let adminData = null;
		{
			const { statusCode, statusText, userAdminData } = await this.context.services.admin.getAdminData( this.context.userid );
			if( 200 === statusCode && null != userAdminData ) {
				adminData = userAdminData;
			} else {
				// Error, set the alert right away and abort
				this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
				return;
			}
		}

        // Add user type name to all user data entries
        for( let i = 0; i < userData.length; i++ ) {
            let userTypeName = "";
            for( let j = 0; j < userTypeData.length; j++ ) {
                if( userTypeData[j].typeid === userData[i].typeid ) {
                    userTypeName = userTypeData[j].name;
                    break;
                }
            }
            userData[i].userTypeName = userTypeName;
        }

        // Gather list of selectable projects depending on user administraction access or project lead settings
        let selectableProjects = [];
        if( null != adminData ) {
            for( let i = 0; i < projectData.length; i++ ) {

                // As a value entry use {internal_id}-{name} here since this value will show up in the select menu
                let value = `${projectData[i].internal_id} - ${projectData[i].name} (${projectData[i].status})`;

                if( true === adminData.admin.accountings || true === adminData.admin.global ) {

                    // We are admin accounting user or global admin
                    selectableProjects.push( { id: projectData[i].projectid, value: value } );

                } else if( 0 < adminData.projectlead.length ) {

                    // Check if the current user is the project lead
                    for( let j = 0; j < adminData.projectlead.length; j++ ) {

                        if( adminData.projectlead[j] === projectData[i].projectid ) {
                            selectableProjects.push( { id: projectData[i].projectid, value: value } );
                        }
                    }
                }
            }

            // Sort project data
            selectableProjects.sort(function(a, b) { return a.value > b.value ? 1 : -1; });
        }

		this.setState({
            selectableProjects: selectableProjects,
			data: { // Raw loaded data from the backend
                admin: adminData,
                projects: projectData,
                tasks: taskData,
                usersToTasks: usersToTasksData,
                usersToProjects: usersToProjectsData,
                users: userData,
                userTypes: userTypeData
			}
		});

		this.handleDataLoadedChanged( true );
	}

    /**
     * GETTERS
     */

    // Get all tasks for a certain project
    getTasksOfProject( projectid ) {
        let tasks = [];

        if( null != this.state.data.tasks ) {
            for( let i = 0; i < this.state.data.tasks.length; i++ ) {
                if( this.state.data.tasks[i].projectid === projectid ) {
                    tasks.push( this.state.data.tasks[i] );
                }
            }
        }

        // Sort by task name
        tasks.sort(function(a, b) { 
            if( a.name > b.name ) return 1;
            if( a.name < b.name ) return -1;
            return 0;
        });

        return tasks;
    }

    // Get all users for a certain project
    getUsersOfProject( projectid ) {
        let users = [];

        if( null != this.state.data.usersToProjects ) {
            for( let i = 0; i < this.state.data.usersToProjects.length; i++ ) {
                if( this.state.data.usersToProjects[i].projectid === projectid ) {

                    for( let j = 0; j < this.state.data.users.length; j++ ) {
                        if( this.state.data.users[j].userid === this.state.data.usersToProjects[i].userid ) {
                            users.push( this.state.data.users[j] );

                            if( this.state.data.users[j].typeid != null ) {
                                for( let k = 0; k < this.state.data.userTypes.length; k++ ) {
                                    if( this.state.data.userTypes[k].typeid === this.state.data.users[j].typeid ) {
                                        users[users.length-1].type = this.state.data.userTypes[k].name;
                                    }
                                }
                            } else {
                                users[users.length-1].type = "";
                            }
                        }
                    }
                }
            }
        }

        // Sort user data
        users.sort(function(a, b) { 
            // Sort by user type first, then by user lastname
            if( a.type > b.type ) return 1;
            if( a.type < b.type ) return -1;
            if( a.lastname > b.lastname ) return 1;
            if( a.lastname < b.lastname ) return -1;
            return 0;
        });

        return users;
    }

    /**
     * EXPORT FUNCTIONS
     */
    async handleCsvExportClick( type ) {
        if( type === "summary" ) {
            await this.handleCsvExportClickSummary();
        } else if( type === "hours" ) {
            await this.handleCsvExportClickHours();
        }
    }

    // Export Hours to CSV
    async handleCsvExportClickHours() {

        // Gather main project details
        let projectName = "";
        let projectInternalId = "";
        for( let i = 0; i < this.state.data.projects.length; i++ ) {
            if( this.state.selectedProject === this.state.data.projects[i].projectid ) {
                projectName = this.state.data.projects[i].name;
                projectInternalId = this.state.data.projects[i].internal_id;
                break;
            }
        }

        // Query our table for the accounting entries
        const tableEntries = this.tableProjectAccountingChildRef.current.getTableEntries();

        // Assemble CSV file name
        let csvFilename = i18n.t("pages.reports.options.project.export.hours.csv.filename", {
            internalid: projectName,
            name: projectInternalId
        });

        // Assemble CSV data
        let csvData = [];

        /**
         *  Main Header
         *  Report Project - Hours,<Project Internal ID>,<Project Name>,<Selected Start Date>,<Selected End Date>
         */

        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.hours.headline"), 
            projectInternalId, 
            projectName, 
            this.state.selectedDate.min, 
            this.state.selectedDate.max 
        ] );

        /**
         *  Sub Header
         *  Date,Task,User,Hours,Comment
         */

        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.hours.table_headlines.date"), 
            i18n.t("pages.reports.options.project.export.hours.table_headlines.task"), 
            i18n.t("pages.reports.options.project.export.hours.table_headlines.user"), 
            i18n.t("pages.reports.options.project.export.hours.table_headlines.hours"), 
            i18n.t("pages.reports.options.project.export.hours.table_headlines.comment")
        ] );

        // Now one line per table entry
        for( let i = 0; i < tableEntries.length; i++ ) {
            csvData.push([
                tableEntries[i].date,
                tableEntries[i].taskname,
                tableEntries[i].username,
                tableEntries[i].hours,
                tableEntries[i].comment
            ]);
        }

        // Set the state of the data and automatically click the download link
        this.setState({ 
            export: { 
                csv: {
                    filename: csvFilename,
                    data: csvData
                }
            }},
            () => {
                setTimeout(() => {
                    this.csvLinkRef.current.link.click();
                })
            }
        );
    }

    // Export Summary to CSV
    async handleCsvExportClickSummary() {

        // We need the project report to export something
        if( null == this.state.projectReport && null != this.state.data.projects && null != this.state.selectedProject && "" !== this.state.selectedProject ) {
            return;
        }

        // We export multiple tables to one CSV file

        // Gather main project details
        let projectName = "";
        let projectInternalId = "";
        for( let i = 0; i < this.state.data.projects.length; i++ ) {
            if( this.state.selectedProject === this.state.data.projects[i].projectid ) {
                projectName = this.state.data.projects[i].name;
                projectInternalId = this.state.data.projects[i].internal_id;
                break;
            }
        }

        // Assemble CSV file name
        let csvFilename = i18n.t("pages.reports.options.project.export.summary.csv.filename", {
            internalid: projectName,
            name: projectInternalId
        });

        // Assemble CSV data
        let csvData = [];

        // Sort tasks by name
        this.state.projectReport.resultTasks.sort(function(a, b) { 
            if( a.name > b.name ) return 1;
            if( a.name < b.name ) return -1;
            return 0;
        });

        // Sort users by name
        this.state.projectReport.resultUsers.sort(function(a, b) { 
            // Sort by user type first, then by user lastname
            if( a.type > b.type ) return 1;
            if( a.type < b.type ) return -1;
            if( a.lastname > b.lastname ) return 1;
            if( a.lastname < b.lastname ) return -1;
            return 0;
        });

        /**
            Main Header
            Report Project - Summary,<Project Internal ID>,<Project Name>,<Selected Start Date>,<Selected End Date>
         */

        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.headline"), 
            projectInternalId, 
            projectName, 
            this.state.selectedDate.min, 
            this.state.selectedDate.max 
        ] );

        /*
            Project Summary - Accounted Hours Total
        */
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.table_headlines.project_summary_hours_total"), 
            this.state.projectReport.resultProject.hours_accounted_total 
        ] );

        /*
            Project Summary - Accounted Hours per Year
            Year,Accounted Hours
        */
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.table_headlines.project_summary_hours_per_year") 
        ] );
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.year"), 
            i18n.t("pages.reports.options.project.export.summary.accounted_hours") 
        ] );

        for( let i = 0; i < this.state.projectReport.resultProject.years.length; i++ ) {
            let year = this.state.projectReport.resultProject.years[i].year;
            let hoursAccounted = this.state.projectReport.resultProject.years[i].hours_accounted_in_year;

            csvData.push( [ 
                year, 
                hoursAccounted 
            ] );
        }

        /*
            Project Summary - Accounted Hours per Month
            Year,Month,Accounted Hours
        */
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.table_headlines.project_summary_hours_per_year") 
        ] );
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.year"), 
            i18n.t("pages.reports.options.project.export.summary.month"), 
            i18n.t("pages.reports.options.project.export.summary.accounted_hours") 
        ] );

        for( let i = 0; i < this.state.projectReport.resultProject.years.length; i++ ) {
            let year = this.state.projectReport.resultProject.years[i].year;
            for( let j = 0; j < this.state.projectReport.resultProject.years[i].months.length; j++ ) {
                let month = this.state.projectReport.resultProject.years[i].months[j].month;
                let hoursAccounted = this.state.projectReport.resultProject.years[i].months[j].hours_accounted_in_month;

                csvData.push( [ 
                    year,
                    month,
                    hoursAccounted 
                ] );
            }
        }

        /*
            Task Summary - Total
            Task,Accounted Hours
        */
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.table_headlines.task_summary_hours_total") 
        ] );
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.task"), 
            i18n.t("pages.reports.options.project.export.summary.accounted_hours") 
        ] );

        for( let i = 0; i < this.state.projectReport.resultTasks.length; i++ ) {
            let taskName = this.state.projectReport.resultTasks[i].name;
            let hoursAccounted = this.state.projectReport.resultTasks[i].hours_accounted_total;

            csvData.push( [ 
                taskName,
                hoursAccounted 
            ] );
        }

        /*
            Task Summary - per Year
            Task,Year,Accounted Hours
        */
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.table_headlines.task_summary_hours_per_year") 
        ] );
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.task"), 
            i18n.t("pages.reports.options.project.export.summary.year"), 
            i18n.t("pages.reports.options.project.export.summary.accounted_hours") 
        ] );

        for( let i = 0; i < this.state.projectReport.resultTasks.length; i++ ) {
            let taskName = this.state.projectReport.resultTasks[i].name;

            for( let j = 0; j < this.state.projectReport.resultTasks[i].years.length; j++ ) {
                let year = this.state.projectReport.resultTasks[i].years[j].year;
                let hoursAccounted = this.state.projectReport.resultTasks[i].years[j].hours_accounted_in_year;

                csvData.push( [ 
                    taskName,
                    year,
                    hoursAccounted 
                ] );
            }
        }

        /*
            Task Summary - per Month
            Task,Year,Month,Accounted Hours
        */
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.table_headlines.task_summary_hours_per_month") 
        ] );
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.task"), 
            i18n.t("pages.reports.options.project.export.summary.year"), 
            i18n.t("pages.reports.options.project.export.summary.month"), 
            i18n.t("pages.reports.options.project.export.summary.accounted_hours") 
        ] );

        for( let i = 0; i < this.state.projectReport.resultTasks.length; i++ ) {
            let taskName = this.state.projectReport.resultTasks[i].name;

            for( let j = 0; j < this.state.projectReport.resultTasks[i].years.length; j++ ) {
                let year = this.state.projectReport.resultTasks[i].years[j].year;

                for ( let k = 0; k < this.state.projectReport.resultTasks[i].years[j].months.length; k++ ) {
                    let month = this.state.projectReport.resultTasks[i].years[j].months[k].month;
                    let hoursAccounted = this.state.projectReport.resultTasks[i].years[j].months[k].hours_accounted_in_month;

                    csvData.push( [ 
                        taskName,
                        year,
                        month,
                        hoursAccounted 
                    ] );
                }
            }
        }

        /*
            User Summary - Total
            User,Accounted Hours
        */
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.table_headlines.user_summary_hours_total") 
        ] );
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.user"), 
            i18n.t("pages.reports.options.project.export.summary.accounted_hours") 
        ] );

        for( let i = 0; i < this.state.projectReport.resultUsers.length; i++ ) {

            let userName = "";
            if( "" !== this.state.projectReport.resultUsers[i].type ) {
                userName = "(" + this.state.projectReport.resultUsers[i].type + ") ";
            }
            userName = userName + "" + this.state.projectReport.resultUsers[i].lastname + ", " + this.state.projectReport.resultUsers[i].firstname;

            let hoursAccounted = this.state.projectReport.resultUsers[i].hours_accounted_total;

            csvData.push( [ 
                userName,
                hoursAccounted 
            ] );
        }

        /*
            User Summary - per Year
            User,Year,Accounted Hours
        */
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.table_headlines.user_summary_hours_per_year") 
        ] );
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.user"), 
            i18n.t("pages.reports.options.project.export.summary.year"), 
            i18n.t("pages.reports.options.project.export.summary.accounted_hours") 
        ] );

        for( let i = 0; i < this.state.projectReport.resultUsers.length; i++ ) {
            let userName = "";
            if( "" !== this.state.projectReport.resultUsers[i].type ) {
                userName = "(" + this.state.projectReport.resultUsers[i].type + ") ";
            }
            userName = userName + "" + this.state.projectReport.resultUsers[i].lastname + ", " + this.state.projectReport.resultUsers[i].firstname;

            for( let j = 0; j < this.state.projectReport.resultUsers[i].years.length; j++ ) {
                let year = this.state.projectReport.resultUsers[i].years[j].year;
                let hoursAccounted = this.state.projectReport.resultUsers[i].years[j].hours_accounted_in_year;

                csvData.push( [ 
                    userName,
                    year,
                    hoursAccounted 
                ] );
            }
        }

        /*
            User Summary - per Month
            User,Year,Month,Accounted Hours
        */
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.table_headlines.user_summary_hours_per_month") 
        ] );
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.user"), 
            i18n.t("pages.reports.options.project.export.summary.year"), 
            i18n.t("pages.reports.options.project.export.summary.month"), 
            i18n.t("pages.reports.options.project.export.summary.accounted_hours") 
        ] );

        for( let i = 0; i < this.state.projectReport.resultUsers.length; i++ ) {
            let userName = "";
            if( "" !== this.state.projectReport.resultUsers[i].type ) {
                userName = "(" + this.state.projectReport.resultUsers[i].type + ") ";
            }
            userName = userName + "" + this.state.projectReport.resultUsers[i].lastname + ", " + this.state.projectReport.resultUsers[i].firstname;

            for( let j = 0; j < this.state.projectReport.resultUsers[i].years.length; j++ ) {
                let year = this.state.projectReport.resultUsers[i].years[j].year;

                for ( let k = 0; k < this.state.projectReport.resultUsers[i].years[j].months.length; k++ ) {
                    let month = this.state.projectReport.resultUsers[i].years[j].months[k].month;
                    let hoursAccounted = this.state.projectReport.resultUsers[i].years[j].months[k].hours_accounted_in_month;

                    csvData.push( [ 
                        userName,
                        year,
                        month,
                        hoursAccounted 
                    ] );
                }
            }
        }

        /*
            User Task Summary - Total
            User,Task,Accounted Hours
        */
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.table_headlines.usertask_summary_hours_total") 
        ] );
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.user"),
            i18n.t("pages.reports.options.project.export.summary.task"), 
            i18n.t("pages.reports.options.project.export.summary.accounted_hours") 
        ] );

        for( let i = 0; i < this.state.projectReport.resultUsers.length; i++ ) {

            let userName = "";
            if( "" !== this.state.projectReport.resultUsers[i].type ) {
                userName = "(" + this.state.projectReport.resultUsers[i].type + ") ";
            }
            userName = userName + "" + this.state.projectReport.resultUsers[i].lastname + ", " + this.state.projectReport.resultUsers[i].firstname;

            // Sort tasks by name
            this.state.projectReport.resultUsers[i].tasks.sort(function(a, b) { 
                if( a.name > b.name ) return 1;
                if( a.name < b.name ) return -1;
                return 0;
            });

            for( let j = 0; j < this.state.projectReport.resultUsers[i].tasks.length; j++ ) {
                let taskName = this.state.projectReport.resultUsers[i].tasks[j].name;
                let hoursAccounted = this.state.projectReport.resultUsers[i].tasks[j].hours_accounted_total;

                csvData.push( [ 
                    userName,
                    taskName,
                    hoursAccounted 
                ] );
            }
        }

        /*
            User Task Summary - per Year
            User,Task,Year,Accounted Hours
        */
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.table_headlines.usertask_summary_hours_per_year") 
        ] );
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.user"),
            i18n.t("pages.reports.options.project.export.summary.year"),
            i18n.t("pages.reports.options.project.export.summary.task"), 
            i18n.t("pages.reports.options.project.export.summary.accounted_hours") 
        ] );

        for( let i = 0; i < this.state.projectReport.resultUsers.length; i++ ) {

            let userName = "";
            if( "" !== this.state.projectReport.resultUsers[i].type ) {
                userName = "(" + this.state.projectReport.resultUsers[i].type + ") ";
            }
            userName = userName + "" + this.state.projectReport.resultUsers[i].lastname + ", " + this.state.projectReport.resultUsers[i].firstname;

            for( let j = 0; j < this.state.projectReport.resultUsers[i].years.length; j++ ) {
                let year = this.state.projectReport.resultUsers[i].years[j].year;

                for( let k = 0; k < this.state.projectReport.resultTasks.length; k++ ) {
                    let taskName = this.state.projectReport.resultTasks[k].name;
                    let taskId = this.state.projectReport.resultTasks[k].taskid;
                    let hoursAccounted = 0;

                    for( let l = 0; l < this.state.projectReport.resultUsers[i].years[j].months.length; l++ ) {

                        for( let m = 0; m < this.state.projectReport.resultUsers[i].years[j].months[l].tasks.length; m++ ) {
                            if( this.state.projectReport.resultUsers[i].years[j].months[l].tasks[m].taskid === taskId ) {
                                hoursAccounted += this.state.projectReport.resultUsers[i].years[j].months[l].tasks[m].hours_accounted_in_month;
                            }
                        }
                    }

                    csvData.push( [ 
                        userName,
                        year,
                        taskName,
                        hoursAccounted 
                    ] );
                }
            }
        }

        /*
            User Task Summary - per Month
            User,Task,Year,Month,Accounted Hours
        */
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.table_headlines.usertask_summary_hours_per_month") 
        ] );
        csvData.push( [ 
            i18n.t("pages.reports.options.project.export.summary.user"),
            i18n.t("pages.reports.options.project.export.summary.year"), 
            i18n.t("pages.reports.options.project.export.summary.month"), 
            i18n.t("pages.reports.options.project.export.summary.task"), 
            i18n.t("pages.reports.options.project.export.summary.accounted_hours") 
        ] );

        for( let i = 0; i < this.state.projectReport.resultUsers.length; i++ ) {

            let userName = "";
            if( "" !== this.state.projectReport.resultUsers[i].type ) {
                userName = "(" + this.state.projectReport.resultUsers[i].type + ") ";
            }
            userName = userName + "" + this.state.projectReport.resultUsers[i].lastname + ", " + this.state.projectReport.resultUsers[i].firstname;

            for( let j = 0; j < this.state.projectReport.resultUsers[i].years.length; j++ ) {
                let year = this.state.projectReport.resultUsers[i].years[j].year;

                for( let k = 0; k < this.state.projectReport.resultUsers[i].years[j].months.length; k++ ) {
                    let month = this.state.projectReport.resultUsers[i].years[j].months[k].month;

                    // Sort tasks by name
                    this.state.projectReport.resultUsers[i].years[j].months[k].tasks.sort(function(a, b) { 
                        if( a.name > b.name ) return 1;
                        if( a.name < b.name ) return -1;
                        return 0;
                    });

                    for( let l = 0; l < this.state.projectReport.resultUsers[i].years[j].months[k].tasks.length; l++ ) {
                        let taskName = this.state.projectReport.resultUsers[i].years[j].months[k].tasks[l].name;
                        let hoursAccounted = this.state.projectReport.resultUsers[i].years[j].months[k].tasks[l].hours_accounted_in_month;

                        csvData.push( [ 
                            userName,
                            year,
                            month,
                            taskName,
                            hoursAccounted 
                        ] );
                    }
                }
            }
        }

        // Set the state of the data and automatically click the download link
        this.setState({ 
            export: { 
                csv: {
                    filename: csvFilename,
                    data: csvData
                }
            }},
            () => {
                setTimeout(() => {
                    this.csvLinkRef.current.link.click();
                })
            }
        );
    }

	/**
	 * RENDER FUNCTIONS
	 */

    renderSelectDate( projectReport, selectedDateMin, selectedDateMax ) {
        let locale = null;
        if( null != this.props.language ) {
            locale = getDateLocale( this.props.language );
        }

        let minDate = selectedDateMin;
        let maxDate = selectedDateMax;
        if( selectedDateMin == null ) {
            minDate = projectReport.resultProject.minDate;
        }
        if( selectedDateMax == null ) {
            maxDate = projectReport.resultProject.maxDate;
        }

        let dateMin = new Date( minDate );
        let dateMax = new Date( maxDate );
        let dateMinProject = new Date( projectReport.resultProject.minDate );
        let dateMaxProject = new Date( projectReport.resultProject.maxDate );

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                <DatePicker
                    autoOk="true"
                    variant="inline"
                    label={i18n.t("pages.reports.select_date_min.label")}
                    helperText={i18n.t("pages.reports.select_date_min.subtext")}
                    inputVariant="outlined"
                    openTo="date"
                    value={dateMin}
                    onChange={this.handleChangeSelectDateMin}
                    minDate={dateMinProject}
                    maxDate={dateMaxProject}
                    format="MMM dd, yyyy"
                    className={this.props.classes.datePicker}
                />
                <DatePicker
                    autoOk="true"
                    variant="inline"
                    label={i18n.t("pages.reports.select_date_max.label")}
                    helperText={i18n.t("pages.reports.select_date_max.subtext")}
                    inputVariant="outlined"
                    openTo="date"
                    value={dateMax}
                    onChange={this.handleChangeSelectDateMax}
                    minDate={dateMinProject}
                    maxDate={dateMaxProject}
                    format="MMM dd, yyyy"
                    className={this.props.classes.datePicker}
                />
            </MuiPickersUtilsProvider>
        )
    }

    // Render option buttons
    renderOption( selectedOption ) {
        return (
            <ToggleButtonGroup 
                size="small" 
                value={selectedOption}
                exclusive
                className={this.props.classes.options}
                onChange={(event, alignment) => this.handleOptionChange(event, alignment)}
            >
                <ToggleButton value="overview">{i18n.t("pages.reports.options.project.overview.title")}</ToggleButton>
                <ToggleButton value="taskdetail">{i18n.t("pages.reports.options.project.taskdetail.title")}</ToggleButton>
                <ToggleButton value="userdetail">{i18n.t("pages.reports.options.project.userdetail.title")}</ToggleButton>
            </ToggleButtonGroup>
        )
    }

    // Render accounting details table
    renderAccountingDetails() {

        let selectedTask = this.state.selectedTask;
        let selectedUser = this.state.selectedUser;

        if( "overview" === this.state.selectedOption ) {
            selectedTask = null;
            selectedUser = null;
        } else if( "taskdetail" === this.state.selectedOption ) {
            selectedUser = null;
        } else if( "userdetail" === this.state.selectedOption ) {
            selectedTask = null;
        }

        return (
            <TableProjectAccounting
                ref={this.tableProjectAccountingChildRef}
                accountings={this.state.projectAccountings}
                users={this.state.data.users}
                tasks={this.state.data.tasks}
                userTypes={this.state.data.userTypes}
                minDate={this.state.selectedDate.min}
                maxDate={this.state.selectedDate.max}
                selectedProject={this.state.selectedProject}
                selectedTask={selectedTask}
                selectedUser={selectedUser}
            />
        )
    }

    render() {
		/**
		 * INPUTS
		 * adminData, language, dataLoadedChanged, alertChanged, actionInProgressChanged
		 */

        let selectDate = "";
        let resetDate = "";
        let selectProject = "";
        let selectTask = "";
        let selectUser = "";
        let selectOption = "";
        let reportContent = "";
        let selectedTask = "";
        let selectedUser = "";
        let renderCsvLink = "";
        let renderExportButtons = "";
        let accountingDetails = "";

        if( null != this.state.selectedTask ) { selectedTask = this.state.selectedTask; }
        if( null != this.state.selectedUser ) { selectedUser = this.state.selectedUser; }

        let selectableTasks = [];
        let selectableUsers = [];

        if( null != this.state.selectableProjects && this.state.selectableProjects.length > 0 ) {
            let selectedProject = "";
            if( null != this.state.selectedProject ) { selectedProject = this.state.selectedProject; }

            selectProject = (
                <SelectControls
                    selectedEntry={selectedProject}
                    helperText={i18n.t("pages.reports.select_project.subtext")}
                    items={this.state.selectableProjects}
                    onChange={this.handleChangeSelectProject}
                    showButtons={false}
                    showNoneValue={true}
                    displayEmpty={true}
                    noneValueName={i18n.t("values.none")}
                />
            )

            let tasksOfProject = this.getTasksOfProject( this.state.selectedProject );
            if( null == tasksOfProject ) {
                tasksOfProject = [];
            }

            for( let i = 0; i < tasksOfProject.length; i++ ) {
                let value = `${tasksOfProject[i].name} (${tasksOfProject[i].status})`;
                selectableTasks.push( { id: tasksOfProject[i].taskid, value: value } );
            }

            let usersOfProject = this.getUsersOfProject( this.state.selectedProject );
            if( null == usersOfProject ) {
                usersOfProject = [];
            }

            for( let i = 0; i < usersOfProject.length; i++ ) {
                let value = "";
                if( usersOfProject[i].typeid != null ) {
                    value = `(${usersOfProject[i].type}) `
                }
                value = value + `${usersOfProject[i].lastname}, ${usersOfProject[i].firstname}`;
                selectableUsers.push( { id: usersOfProject[i].userid, value: value } );
            }
        }

        if( null != this.state.projectReport ) {
            selectDate = this.renderSelectDate( this.state.projectReport, this.state.selectedDate.min, this.state.selectedDate.max );
            resetDate = (
                <ResetButton 
                    title={i18n.t("pages.reports.options.project.reset_date.tooltip")}
                    onClick={(event) => this.handleResetDateButtonClick(event)}
                    disabled={false}
                />
            )
            selectOption = this.renderOption( this.state.selectedOption, this.state.projectReport );

            if( "overview" === this.state.selectedOption ) {
                reportContent = (
                    <ReportProjectOverview 
                        dateMin={this.state.selectedDate.min}
                        dateMax={this.state.selectedDate.max}
                        language={this.props.language}
                        projectReport={this.state.projectReport}
                    />
                )
            } else if( "taskdetail" === this.state.selectedOption ) {
                selectTask = (
                    <SelectControls
                        selectedEntry={selectedTask}
                        helperText={i18n.t("pages.reports.select_task.subtext")}
                        items={selectableTasks}
                        onChange={this.handleChangeSelectTask}
                        showButtons={false}
                        showNoneValue={true}
                        displayEmpty={true}
                        noneValueName={i18n.t("values.all")}
                    />
                )
                reportContent = (
                    <ReportProjectTaskDetail 
                        dateMin={this.state.selectedDate.min}
                        dateMax={this.state.selectedDate.max}
                        language={this.props.language}
                        projectReport={this.state.projectReport}
                        taskid={this.state.selectedTask}
                    />
                )
            } else if( "userdetail" === this.state.selectedOption ) {
                selectTask = (
                    <SelectControls
                        selectedEntry={selectedUser}
                        helperText={i18n.t("pages.reports.select_user.subtext")}
                        items={selectableUsers}
                        onChange={this.handleChangeSelectUser}
                        showButtons={false}
                        showNoneValue={true}
                        displayEmpty={true}
                        noneValueName={i18n.t("values.all")}
                    />
                )
                reportContent = (
                    <ReportProjectUserDetail 
                        dateMin={this.state.selectedDate.min}
                        dateMax={this.state.selectedDate.max}
                        language={this.props.language}
                        projectReport={this.state.projectReport}
                        userid={this.state.selectedUser}
                    />
                )
            }
            
            renderCsvLink = (
                <CSVLink
                    ref={this.csvLinkRef}
                    data={this.state.export.csv.data}
                    filename={this.state.export.csv.filename}
                    className="hidden"
                    target="_blank"
                />
            )

            // Export CSV button, tooltip changes depending on selected option
            let exportCsvButtons = [];

            exportCsvButtons.push({
                showIcon: true,
                showText: true,
                text: i18n.t("pages.reports.project_reports.buttons.csv_summary_button"),
                tooltip: i18n.t("pages.reports.project_reports.buttons.csv_summary_tooltip"),
                customData: "summary"
            })

            exportCsvButtons.push({
                showIcon: true,
                showText: true,
                text: i18n.t("pages.reports.project_reports.buttons.csv_hours_button"),
                tooltip: i18n.t("pages.reports.project_reports.buttons.csv_hours_tooltip"),
                customData: "hours"
            })

            renderExportButtons = (
                <ExportButtons 
                    csvButtons={exportCsvButtons}
                    onCsvClick={(customData) => this.handleCsvExportClick(customData)}
                />
            )

            if( null != this.state.projectAccountings ) {
                accountingDetails = this.renderAccountingDetails();
            }
        }

        return (
			<div>
				<Grid container className={this.props.classes.mainGrid} justify="flex-start" alignItems="flex-start" spacing={1}>
					<Grid item container xs={12} className={this.props.classes.select} spacing={1} wrap='nowrap'>
                        <Grid item container xs={10} spacing={1}>
                            <Grid item>
                                {selectProject}
                            </Grid>
                            <Grid item>
                                {selectDate}
                            </Grid>
                            <Grid item>
                                {resetDate}
                            </Grid>
                            <Grid item>
                                {selectOption}
                            </Grid>
                            <Grid item>
                                {selectTask}
                            </Grid>
                            <Grid item>
                                {selectUser}
                            </Grid>
                        </Grid>
                        <Grid item container xs={2} justify="flex-end">
                            {renderCsvLink}
                            {renderExportButtons}
                        </Grid>
					</Grid>
					{reportContent}
                    {accountingDetails}
				</Grid>
			</div>
        );
    }
};

export default withStyles(useStyles)(ReportsProject);