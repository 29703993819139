import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 */
class TaskService {

    /**
     * Get tasks
     * @param {*} taskSearch { taskid, name, status, projectid }
     */
    async getTasks( taskSearch ) {
        var request = {
            query: `
            query getTasks($taskSearch: TaskSearch!) {
                getTasks(taskSearch: $taskSearch) {
                    taskid
                    name
                    status
                    projectid
                    has_accountings
                    has_users
                    create_date
                    status_change_date
                }
            }
            `,
            variables: {
                taskSearch: taskSearch
            }
        };

        return await ApiService.doPost( request );
    }
    
    /**
     * Create a new task
     * @param {*} taskInput { taskid, name, status, projectid }
     */
    async createTask( taskInput ) {
        var request = {
            query: `
            mutation createTask($taskInput: TaskInput) {
                createTask(taskInput: $taskInput) {
                    taskid
                    name
                    status
                    projectid
                    has_accountings
                    has_users
                    create_date
                    status_change_date
                }
            }
            `,
            variables: {
                taskInput: taskInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete a task
     * @param {*} taskSearch { taskid, name, status, taskid }
     */
    async deleteTask( taskSearch ) {
        var request = {
            query: `
            mutation deleteTask($taskSearch: TaskSearch!) {
                deleteTask(taskSearch: $taskSearch) {
                    result
                }
            }
            `,
            variables: {
                taskSearch: taskSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update a task
     * @param {*} taskSearch { taskid, name, status, projectid }
     * @param {*} taskUpdate { name, status, projectid }
     */
    async updateTask( taskSearch, taskUpdate ) {
        var request = {
            query: `
            mutation updateTask($taskSearch: TaskSearch!, $taskUpdate: TaskUpdate!) {
                updateTask(taskSearch: $taskSearch, taskUpdate: $taskUpdate) {
                    taskid
                    name
                    status
                    projectid
                    has_accountings
                    has_users
                    create_date
                    status_change_date
                }
            }
            `,
            variables: {
                taskSearch: taskSearch,
                taskUpdate: taskUpdate
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new TaskService();
