import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ModalDialog from './ModalDialog';
import ModalDialogTextField from './ModalDialogTextField';
import Tooltip from '@material-ui/core/Tooltip';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = theme => ({
    textFieldDiv: {
        flexGrow: 1,
        width: '100%',
        height: '25px',
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(1.5),
            minWidth: '20ch',
        },
    },
    textField: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
        '& .MuiOutlinedInput-root': {
            height: '30px',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-disabled fieldset': {
                borderColor: 'transparent',
            },
            // Do not set color here, that breaks the error color if the value is not verified
            /*'&:hover fieldset': {
                borderColor: '#a6a6a6',
            },*/
            /*'&.Mui-focused fieldset': {
                borderWidth: "1px",
                borderColor: theme.palette.primary.main,
            },*/
        },
    },
    textFieldBlurred: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
        '& .MuiOutlinedInput-root': {
            height: '30px',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    editIcon: {
        width: 30,
    }
});

// Proper SVG based edit icon
function EditIcon(props) {
    return (
        <SvgIcon viewBox="0 0 122.88 122.88" {...props}>
            <path d="M61.44 0c33.93 0 61.44 27.51 61.44 61.44 0 33.93-27.51 61.44-61.44 61.44S0 95.37 0 61.44C0 27.51 27.51 0 61.44 0zm-8.88 84c-1.82.61-3.68 1.17-5.5 1.77-1.82.61-3.64 1.21-5.5 1.82-4.34 1.4-6.71 2.19-7.23 2.33-.51.14-.19-1.86.89-6.06l3.45-13.19 26.01-27.04 13.85 13.33L52.56 84zm25.92-50.16c-.65-.61-1.4-.93-2.24-.89-.84 0-1.59.33-2.19.98l-4.94 5.13 13.85 13.38 4.99-5.22c.61-.61.84-1.4.84-2.24 0-.84-.33-1.63-.93-2.19l-9.38-8.95z" fillRule="evenodd" clipRule="evenodd"/>
        </SvgIcon>
    );
}

/**
 * Custom multiline text field
 */
class CustomMultilineField extends Component {
    state = {
        editing: false,
        textFieldValue: "",
        initialTextFieldValue: "",
        contentVerified: true,
        helperText: "",
        enableApplyButton: false,
        modifyDialog: {
            open: false,
            date: null,
            name: ""
        },
        dialogJustClosed: false,
        blurred: false
    }

    constructor(props) {
        super(props);

        if( null != this.props.textFieldValue ) {
            this.state.textFieldValue = this.props.textFieldValue;
            this.state.initialTextFieldValue = this.props.textFieldValue;
        }

        this.handleTextClick = this.handleTextClick.bind( this );
        this.handleTextOnFocus = this.handleTextOnFocus.bind( this );
        this.handleTextOnBlur = this.handleTextOnBlur.bind( this );

        this.handleButtonEditClick = this.handleButtonEditClick.bind( this );
        this.handleButtonApplyClick = this.handleButtonApplyClick.bind( this );
        this.handleButtonCancelClick = this.handleButtonCancelClick.bind( this );

        this.handleModifyDialog_Ok = this.handleModifyDialog_Ok.bind( this );
        this.handleModifyDialog_Cancel = this.handleModifyDialog_Cancel.bind( this );
        this.handleModifyDialog_Delete = this.handleModifyDialog_Delete.bind( this );
        this.verifyDialog_Text = this.verifyDialog_Text.bind( this );

        this.textFieldInput = React.createRef();
        this.editButtonInput = React.createRef();
    }

    /**
     * DIALOG
     */

    // Called to open the modify dialog
    openModifyDialog( textFieldValue ) {
        let modifyDialog = {
            open: true,
            text: textFieldValue
        }
        this.setState({ modifyDialog: modifyDialog });
    }

    // Called when the user clicked "Ok" on the modify dialog
    handleModifyDialog_Ok( initialText, contents ) {
        this.closeModifyDialog();

        if( null != contents && null != contents.value ) {
            let enableApplyButton = true;
            if( contents.value === this.state.initialTextFieldValue ) {
                enableApplyButton = false;
            }
            this.setState({ textFieldValue: contents.value, contentVerified: true, enableApplyButton: enableApplyButton });
        }
    }

    // Called when the user clicked "Cancel" or close on the modify dialog
    handleModifyDialog_Cancel() {
        this.closeModifyDialog();
    }

    // Called when the user clicks the delete button
    handleModifyDialog_Delete() {
        this.closeModifyDialog();
        this.setState({ textFieldValue: null, contentVerified: true, enableApplyButton: true });
        if( null != this.props.onDelete ) {
            return this.props.onDelete();
        }
    }

    // Called by the dialog to verify its text content
    verifyDialog_Text( content ) {
        if( null != this.props.verifyContent && null != content ) {
            return this.props.verifyContent( content.value );
        }
        return { state: true, msg: "" };
    }

    // Close the modify alert dialog
    closeModifyDialog() {
        let modifyDialog = {
            open: false,
            text: null
        }
        this.setState({ modifyDialog: modifyDialog, dialogJustClosed: true });
    }

    /**
     * TEXTFIELD
     */

    // Text has been clicked
    handleTextClick(event) {
        event.stopPropagation();
        if( true === this.props.disabled ) {
            return;
        }
        this.textFieldInput.current.selectionStart = 1;
        this.textFieldInput.current.selectionEnd = 1;
        this.openModifyDialog( this.state.textFieldValue );
        this.setState({ editing: true });
    }

    // User clicked away from the text field (defocused it)
    handleTextOnBlur(event) {

        // Ignore blur if modify dialog is open
        if( true === this.state.modifyDialog.open ) {
            return;
        }

        // If blurred check if we are in editing state and made any unapplied changes
        // If there are no unapplied changes go out of editing mode as if the cancel button was clicked
        if( true === this.state.editing && this.state.textFieldValue === this.state.initialTextFieldValue ) {
            this.setState({ editing: false, contentVerified: true, textFieldValue: this.state.initialTextFieldValue });
        }
        this.setState({ blurred: true });
    }

    // Received focus on the text edit field
    handleTextOnFocus(event) {
        event.stopPropagation();

        if( false === this.state.dialogJustClosed ) {
            this.setState({ editing: true, blurred: false });

        } else {
            this.setState({ dialogJustClosed: false, blurred: false });

            // If blurred check if we are in editing state and made any unapplied changes
            // If there are no unapplied changes go out of editing mode as if the cancel button was clicked
            if( true === this.state.editing && this.state.textFieldValue === this.state.initialTextFieldValue ) {
                this.setState({ editing: false, contentVerified: true, textFieldValue: this.state.initialTextFieldValue });
            }
            this.textFieldInput.current.blur();
        }
    }

    /**
     * BUTTONS
     */

    // Clicked the edit button
    handleButtonEditClick(event) {
        event.stopPropagation();
        this.textFieldInput.current.focus();
        this.textFieldInput.current.selectionStart = 1;
        this.textFieldInput.current.selectionEnd = 1;
        this.openModifyDialog( this.state.textFieldValue, [] );
        this.setState({ editing: true, blurred: false });
    }

    // Clicked the Apply button
    handleButtonApplyClick(event) {
        event.stopPropagation();
        this.textFieldInput.current.blur();
        this.textFieldInput.current.selectionStart = 1;
        this.textFieldInput.current.selectionEnd = 1;

        // Transmit final text if its different
        if( null != this.props.onApply && this.state.textFieldValue !== this.state.initialTextFieldValue ) {
            this.props.onApply( event, this.state.textFieldValue );
        }

        this.setState({ editing: false, initialTextFieldValue: this.state.textFieldValue });
    }

    // Clicked the cancel button
    handleButtonCancelClick(event) {
        event.stopPropagation();
        this.textFieldInput.current.selectionStart = 1;
        this.textFieldInput.current.selectionEnd = 1;

        // Same as deselect or cancel, reset the text
        this.setState({ editing: false, contentVerified: true, textFieldValue: this.state.initialTextFieldValue });
    }

    // Render me
    render() {
        // Used this props
        // onApply(event, value), verifyContent(value), maxLength, tooltip
        const { classes } = this.props;

        // Gather the currently shown edit buttons
        let buttonProps = {
            shrink: "true",
            readOnly: true
        }

        if( false === this.state.editing ) {
            buttonProps.endAdornment = (
                <InputAdornment position='end'>
                    <IconButton
                        className={classes.editIcon}
                        onClick={(event) => this.handleButtonEditClick(event)}
                        color="primary"
                        edge="end"
                        disableRipple={true}
                        size="small"
                        style={{ visibility: "visible" }}
                        disabled={this.props.disabled}
                    >
                        <EditIcon fontSize="small"/>
                    </IconButton>
                </InputAdornment>
            );
        } else if( true === this.state.editing ) {
            buttonProps.endAdornment = (
                <InputAdornment position='end'>
                    <IconButton
                        onClick={(event) => this.handleButtonApplyClick(event)}
                        color="primary"
                        edge="end"
                        disableRipple={true}
                        size="small"
                        style={{ visibility: "visible" }}
                        disabled={!(this.state.contentVerified && this.state.enableApplyButton)}
                        ref={this.editButtonInput}
                    >
                        <CheckCircleRoundedIcon />
                    </IconButton>
                    <IconButton
                        onClick={(event) => this.handleButtonCancelClick(event)}
                        color="primary"
                        edge="end"
                        disableRipple={true}
                        size="small"
                        style={{ visibility: "visible" }}
                    >
                        <CancelRoundedIcon />
                    </IconButton>
                </InputAdornment>
            );
        }

        // Set different text field classes depending on if we have been blurred
        // to not show a border around the text field even if we have already been blurred
        let textFieldClass = classes.textField;
        if( true === this.state.blurred ) {
            textFieldClass = classes.textFieldBlurred;
        }

        let required = true;
        if( null != this.props.required ) {
            required = this.props.required;
        }

        let allowEmpty = false;
        if( null != this.props.allowEmpty ) {
            allowEmpty = this.props.allowEmpty;
        }

        let textFieldTooltip = "";
        if( null != this.props.tooltip) {
            textFieldTooltip = this.props.tooltip;
        }

        // Render the rest
        return (
            <div className={classes.textFieldDiv}>
                <Tooltip title={textFieldTooltip}>
                    <TextField
                        label=""
                        value={this.state.textFieldValue}
                        type="text"
                        variant="outlined"
                        size="small"
                        helperText=""
                        fullWidth
                        className={textFieldClass}
                        InputProps={buttonProps}
                        inputRef={this.textFieldInput}
                        onClick={(event) => this.handleTextClick(event)}
                        onFocus={(event) => this.handleTextOnFocus(event)}
                        onBlur={(event) => this.handleTextOnBlur(event)}
                        onKeyPress={(event) => {
                            if( event.key === "Enter" && true === this.state.contentVerified ) {
                                this.handleButtonApplyClick(event);
                            }
                        }}
                        error={false === this.state.contentVerified}
                        required={required}
                        disabled={this.props.disabled}
                    />
                </Tooltip>
                <ModalDialog 
                    open={this.state.modifyDialog.open}
                    title={this.props.title}
                    descText={this.props.descText}
                    buttonLeft={this.props.buttonLeft}
                    buttonRight={this.props.buttonRight}
                    handleClickClose={() => this.handleModifyDialog_Cancel()}
                    handleClickLeft={(contents) => this.handleModifyDialog_Ok(this.state.modifyDialog.date, contents)}
                    handleClickRight={() => this.handleModifyDialog_Cancel()}
                    handleClickDelete={() => this.handleModifyDialog_Delete()}
                    showDeleteButton={this.props.showDeleteButton}
                    disableDeleteButton={this.props.disableDeleteButton}
                    buttonDeleteTooltip={this.props.buttonDeleteTooltip}
                    deleteIcon={this.props.deleteIcon}
                >
                    <ModalDialogTextField 
                        value={this.state.textFieldValue}
                        label={this.props.label} 
                        verifyContent={(content) => this.verifyDialog_Text(content)} 
                        autoFocus={false}
                        multiline={true}
                        rows={this.props.rows}
                        rowsMax={this.props.rowsMax}
                        margin="none"
                        required={required}
                        allowEmpty={allowEmpty}
                    />
                </ModalDialog>
            </div>
        );
    }
};

export default withStyles(useStyles)(CustomMultilineField);