import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { config } from '../config';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Copyright notice
 */
class Copyright extends Component {
    render() {
        return (
            <Box m={3}>
                <Typography variant="body2" color="textSecondary" align="center">
                    {i18n.t("copyright")}{' © '}
                    <Link color="inherit" href={config.copyright.url}>
                        {config.copyright.companyName}
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </Box>
        );
    }
};

export default withStyles(useStyles)(Copyright);