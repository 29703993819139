import React, { Component } from 'react';
import ProjectService from '../../classes/ProjectService'
import UsersToProjectsService from '../../classes/UsersToProjectsService'
import UserService from '../../classes/UserService'
import UserTypeService from '../../classes/UserTypeService'
import TaskService from '../../classes/TaskService'
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { StyledTableCell, StyledTableRow, StyledTableHead, StyledTable, StyledTableSortLabel, stableSort, getComparator } from '../../components/StyledTable.js'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../components/PageTitle';
import Select from '@material-ui/core/Select';
import ModalDialog from '../../components/ModalDialog';
import ModalDialogChipInput from '../../components/ModalDialogChipInput';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ModalDialogTextField from '../../components/ModalDialogTextField';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import CustomTextField from '../../components/CustomTextField'
import CustomMultilineTextField from '../../components/CustomMultilineTextField'
import { StyledChipEnabled, StyledChipDisabled } from '../../components/StyledChip'
import SearchControls from '../../components/SearchControls'
import PageSubtitle from '../../components/PageSubtitle'
import PageDescription from '../../components/PageDescription'
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import DateOnly from '../../components/DateOnly';
import { Link } from "react-router-dom";
import { config } from '../../config';
import i18n from 'i18next';
import _ from 'lodash'

const useStyles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    mainGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    tableCellTextField: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1.0),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
    },
    tablePaginationGrid: {
        height: '60px',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tablePagination: {
        '& .MuiTablePagination-toolbar': {
            height: '60px',
            minHeight: '60px',
        },
    },
    chipInput: {
        width: 600,
        marginTop: theme.spacing(3.5),
    },
    link: {
        color: "#ffffff",
        "&:hover": {
            color: "#ffffff"
        },
    }
});

/**
 * Admin Projects
 */
class AdminProjects extends Component {
    // Set in constructor
    state = {}

    /**
     * Inputs Props
     * @param {*} props 
     *  admin (array)
     *  userProps (array)
     *  dataLoadChanged (function)
     *  alertChanged (function)
     */
    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleTableRowDeleteClick = this.handleTableRowDeleteClick.bind( this );
        this.handleTableRowModifyMembersClick = this.handleTableRowModifyMembersClick.bind( this );
        this.handleTableRowModifyLeadsClick = this.handleTableRowModifyLeadsClick.bind( this );
        this.handleChangeTablePage = this.handleChangeTablePage.bind( this );
        this.handleChangeTableRowsPerPage = this.handleChangeTableRowsPerPage.bind( this );
        this.handleChangeSearchField = this.handleChangeSearchField.bind( this );
        this.handleAddButtonClick = this.handleAddButtonClick.bind( this );
        this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind( this );
        this.handleAddDialog_Ok = this.handleAddDialog_Ok.bind( this );
        this.handleAddDialog_Cancel = this.handleAddDialog_Cancel.bind( this );
        this.handleTableRowDeleteDialog_Ok = this.handleTableRowDeleteDialog_Ok.bind( this );
        this.handleTableRowDeleteDialog_Cancel = this.handleTableRowDeleteDialog_Cancel.bind( this );
        this.handleConfirmStatusChangeDialog_Ok = this.handleConfirmStatusChangeDialog_Ok.bind( this );
        this.handleConfirmStatusChangeDialog_Cancel = this.handleConfirmStatusChangeDialog_Cancel.bind( this );
        this.handleTableRowModifyMembersDialog_Ok = this.handleTableRowModifyMembersDialog_Ok.bind( this );
        this.handleTableRowModifyMembersDialog_Cancel = this.handleTableRowModifyMembersDialog_Cancel.bind( this );
        this.handleTableRowModifyLeadsDialog_Ok = this.handleTableRowModifyLeadsDialog_Ok.bind( this );
        this.handleTableRowModifyLeadsDialog_Cancel = this.handleTableRowModifyLeadsDialog_Cancel.bind( this );
        this.getDeleteButtonDataForProject = this.getDeleteButtonDataForProject.bind( this );
        this.handleProjectNameFieldChange = this.handleProjectNameFieldChange.bind( this );
        this.verifyProjectNameFieldContent = this.verifyProjectNameFieldContent.bind( this );
        this.handleInternalIdFieldChange = this.handleInternalIdFieldChange.bind( this );
        this.verifyInternalIdFieldContent = this.verifyInternalIdFieldContent.bind( this );
        this.handleDescriptionFieldChange = this.handleDescriptionFieldChange.bind( this );
        this.verifyDescriptionFieldContent = this.verifyDescriptionFieldContent.bind( this );
        this.handleStatusChange = this.handleStatusChange.bind( this );
        this.getMembersOfProject = this.getMembersOfProject.bind( this );
        this.getLeadsOfProject = this.getLeadsOfProject.bind( this );
        this.getTasksOfProject = this.getTasksOfProject.bind( this );
        this.getUserLabel = this.getUserLabel.bind( this );
        this.getUserEnabled = this.getUserEnabled.bind( this );
        this.sortUsersOfProject = this.sortUsersOfProject.bind( this );
        this.verifyAddDialogProjectName_Content = this.verifyAddDialogProjectName_Content.bind( this );
        this.verifyAddDialogInternalId_Content = this.verifyAddDialogInternalId_Content.bind( this );
        this.verifyAddDialogDescription_Content = this.verifyAddDialogDescription_Content.bind( this );
        this.getUsersWithoutProject = this.getUsersWithoutProject.bind( this );
        this.handleRequestSort = this.handleRequestSort.bind( this );
        this.sortTableData = this.sortTableData.bind( this );

        // Set initial state
        this.state = this.getInitialState();
    }

    /**
     * STATE HANDLING
     */

    // Get the initial state variables of this component
    getInitialState() {
        return {
            data: { // Raw loaded data from the backend
                projects: null,
                users: null,
                userTypes: null,
                tasks: null,
                usersToProjects: null
            },
            tableData: { // Additional information gathered from loaded raw data to display on the page
                usersForProject: [], // The names of the users of that project
                tasksForProject: [], // The tasks of each project
                deleteButtonsForProject: [] // If the delete button is enabled and which tooltip is shown for that project
            },
            searchFieldEntry: "",
            tablePage: 0,
            tableRowsPerPage: 10,
            order: "asc",
            orderBy: 0,
            addDialog: {
                open: false
            },
            deleteDialog: {
                open: false,
                projectid: null,
                name: null,
                internal_id: null
            },
            modifyDialogLeads: {
                open: false,
                projectid: null,
                name: null,
                internal_id: null
            },
            modifyDialogMembers: {
                open: false,
                projectid: null,
                name: null,
                internal_id: null
            },
            confirmStatusDialog: {
                open: false,
                projectid: null,
                newStatus: null
            }
        }
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState() {
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
        this.setState( this.getInitialState() );
    }

    /**
     * LINK TO PARENT COMPONENT FUNCTIONS
     */

    // Propagate information to parent component
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Propagate information to parent component
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Propagate information to parent component
    handleActionInProgressChanged( actionInProgress ) {
        if( this.props.actionInProgressChanged ) {
            this.props.actionInProgressChanged( actionInProgress );
        }
    }

    /**
     * LOAD AND PROCESS BACKEND DATA
     */

    // Called when a refresh of the table data is needed due to edits in the table
    async refreshTableData() {
        await this.loadDataFromBackend( true );
    }

    // Gather all data from the backend needed on this page and process them properly
    async loadDataFromBackend( manualRefresh ) {
        // Alert message to show
        let alert = {
            severity: "info",
            message: ""
        }

        let projectData = null;
        let userData = null;
        let usersToProjectsData = null;
        let taskData = null;

        // Gather project data
        {
            const { statusCode, statusText, resData } = await ProjectService.getProjects( {} );
            if( 200 === statusCode && null != resData ) {
                projectData = resData.getProjects;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Get users to projects data
        {
            const { statusCode, statusText, resData } = await UsersToProjectsService.getUsersToProjects( {} );
            if( 200 === statusCode && null != resData ) {
                usersToProjectsData = resData.getUsersToProjects;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather user data
        {
            const { statusCode, statusText, resData } = await UserService.getUsers( {} );
            if( 200 === statusCode && null != resData ) {
                userData = resData.getUsers;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        let userTypeData = null;

        // Gather user type data
        {
            const { statusCode, statusText, resData } = await UserTypeService.getUserTypes( {} );
            if( 200 === statusCode && null != resData ) {
                userTypeData = resData.getUserTypes;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather task data
        {
            const { statusCode, statusText, resData } = await TaskService.getTasks( {} );
            if( 200 === statusCode && null != resData ) {
                taskData = resData.getTasks;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Now process the data
        await this.processData( projectData, userData, userTypeData, usersToProjectsData, taskData );

        // All fine, clear the error message (only if not a manual refresh)
        if( false === manualRefresh ) {
            this.handleAlertChanged( alert );
        }
        this.handleDataLoadedChanged( true );
    }

    // Called to make additional processing of data, for example gathering info from that backend data and storing it for later use
    async processData( projectData, userData, userTypeData, usersToProjectsData, taskData ) {

        // Abort if no proper data given
        if( null == projectData || null == userData || null == userTypeData || null == usersToProjectsData || null == taskData ) {
            return;
        }

        // Add user type name to all user data entries
        for( let i = 0; i < userData.length; i++ ) {
            let userTypeName = "";
            for( let j = 0; j < userTypeData.length; j++ ) {
                if( userTypeData[j].typeid === userData[i].typeid ) {
                    userTypeName = userTypeData[j].name;
                    break;
                }
            }
            userData[i].userTypeName = userTypeName;
        }

        // Get the info from projectData if there are currently any other links attached
        // and enable/disable the delete button accordingly
        let deleteButtonsArray = [];

        for( let i = 0; i < projectData.length; i++ ) {

            let deleteButtonElement = {
                projectid: projectData[i].projectid,
                enabled: false,
                tooltip: i18n.t("pages.admin.projects.delete.button.tooltip.disabled"),
                links: []
            }

            if( false === projectData[i].has_tasks && false === projectData[i].has_users ) {
                deleteButtonElement.enabled = true;
                deleteButtonElement.tooltip = i18n.t("pages.admin.projects.delete.button.tooltip.enabled");
            } else {

                // Attached links detected, save them for later to display them to the user
                if( true === projectData[i].has_tasks ) { deleteButtonElement.links.push( i18n.t("pages.admin.projects.delete.button.tooltip.links.tasks") ) };
                if( true === projectData[i].has_users ) { deleteButtonElement.links.push( i18n.t("pages.admin.projects.delete.button.tooltip.links.users") ) };
            }

            deleteButtonsArray[i] = deleteButtonElement;
        }

        // Add the information to data.projects if there is currently an user assigned to this project or not
        // for enabling or disabling the delete button, doing this now speeds up table page changes later on
        let usersArray = [];
        let tasksArray = [];

        for( let i = 0; i < projectData.length; i++ ) {
            let membersFound = [];
            let leadsFound = [];

            for (let j = 0; j < usersToProjectsData.length; j++ ) {
                if( usersToProjectsData[j].projectid === projectData[i].projectid ) {
                    // User at the specified project found
                    let user = null;
                    let user_isProjectLead = false;

                    for( let k = 0; k < userData.length; k++ ) {
                        if( userData[k].userid === usersToProjectsData[j].userid ) {
                            user = userData[k];
                            user_isProjectLead = usersToProjectsData[j].is_project_lead;
                            break;
                        }
                    }

                    membersFound.push( user );

                    // User is teamlead of team
                    if( true === user_isProjectLead ) {
                        leadsFound.push( user );
                    }
                }
            }

            // Sort array by lastname
            membersFound.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.userTypeName > b.userTypeName ) return 1;
                if( a.userTypeName < b.userTypeName ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });
            leadsFound.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.userTypeName > b.userTypeName ) return 1;
                if( a.userTypeName < b.userTypeName ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });

            // Add the processed info
            let usersElement = {
                projectid: projectData[i].projectid,
                members: membersFound,
                leads: leadsFound
            }

            // Add info to the final arrays
            usersArray[i] = usersElement;
        }

        for( let i = 0; i < projectData.length; i++ ) {
            let tasksFound = [];

            for (let j = 0; j < taskData.length; j++ ) {
                if( taskData[j].projectid === projectData[i].projectid ) {
                    tasksFound.push( taskData[j] );
                }
            }

            // Sort array by lastname
            tasksFound.sort(function(a, b) { return a.name > b.name ? 1 : -1; });

            // Add the processed info
            let tasksElement = {
                projectid: projectData[i].projectid,
                tasks: tasksFound
            }

            // Add info to the final arrays
            tasksArray[i] = tasksElement;
        }

        // Store the data
        this.setState({ 
            data: { projects: projectData, users: userData, userTypes: userTypeData, tasks: taskData, usersToProjects: usersToProjectsData }, 
            tableData: { usersForProject: usersArray, tasksForProject: tasksArray, deleteButtonsForProject: deleteButtonsArray } 
        });
    }

    /**
     * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
     */

    // Called by react when this component has been mounted
    async componentDidMount() {
        await this.loadDataFromBackend();
    }

    // Called by react before this component unmounts
    componentWillUnmount() {
        this.resetState();
    }

    /**
     * ALERT DIALOG <ADD>
     */

    // Called to open the add dialog
    openAddDialog() {
        let addDialog = {
            open: true
        }
        this.setState({ addDialog: addDialog });
    }

    // Called by add dialog to verify content
    verifyAddDialogProjectName_Content( content ) {
        return this.verifyProjectNameFieldContent( 0, content.value );
    }

    // Called by add dialog to verify content
    verifyAddDialogInternalId_Content( content ) {
        return this.verifyInternalIdFieldContent( 0, content.value );
    }

    // Called by add dialog to verify content
    verifyAddDialogDescription_Content( content ) {
        return { state: true, msg: "" }
    }

    // Called when the user clicked "Ok" on the add project dialog
    // content: { name }
    async handleAddDialog_Ok( content ) {
        this.closeAddDialog();

        // Check data validity
        if( content == null || content.length < 3 ) {
            return;
        }

        if( content[0].value == null || content[0].value === "" ) {
            return;
        }
        if( content[1].value == null || content[1].value === "" ) {
            return;
        }
        // Description can be empty
        if( content[2].value == null ) {
            return;
        }

        const { statusCode, statusText } = await ProjectService.createProject( { 
            name: content[0].value,
            internal_id: content[1].value,
            description: content[2].value,
            status: config.enums.projectStatus[0].id
        } ); 

        let alert = {
            severity: "info",
            message: ""
        }

        if( 200 === statusCode ) {
            alert.severity = "success";
            alert.message = i18n.t( "pages.admin.projects.add.dialog.alert.success" );
        } else {
            alert.severity = "error";
            alert.message = `${statusText}: ${statusCode}`;
        }

        this.handleAlertChanged( alert );

        // Refresh the table data
        await this.refreshTableData();
    }

    // Called when the user clicked "Cancel" or close on the add project dialog
    handleAddDialog_Cancel() {
        this.closeAddDialog();
    }

    // Close the modify alert dialog
    closeAddDialog() {
        let addDialog = {
            open: false
        }
        this.setState({ addDialog: addDialog });
    }

    /**
     * ALERT DIALOG <MODIFY MEMBERS>
     */

    // Called to open the modify dialog
    openTableRowModifyMembersDialog( projectid, name, internal_id ) {
        let modifyDialogMembers = {
            open: true,
            projectid: projectid,
            name: name,
            internal_id: internal_id
        }
        this.setState({ modifyDialogMembers: modifyDialogMembers });
    }

    // Called when the user clicked "Ok" on the confirm row delete dialog
    async handleTableRowModifyMembersDialog_Ok( projectid, content ) {
        this.closeTableRowModifyMembersDialog();

        // Check data validity
        if( content == null ) {
            return;
        }
        if( content.selectedOptions == null ) {
            return;
        }
        if( content.unselectedOptions == null ) {
            return;
        }

        // Content will include arrays in no specific order
        let usersToProjectCmd = [];

        // Get currently assigned users for project
        const users = this.getMembersOfProject( projectid );

        // Check if users have been added to the project as a member
        for( let i = 0; i < content.selectedOptions.length; i++ ) {
            var userAlreadyAssignedToProject = false;
            for( let j = 0; j < users.length; j++ ) {
                if( users[j].userid === content.selectedOptions[i].userid ) {
                    userAlreadyAssignedToProject = true;
                    break;
                }
            }

            if( false === userAlreadyAssignedToProject ) {
                // User has been added to the project
                usersToProjectCmd.push( { cmd: "add", userid: content.selectedOptions[i].userid } );
            }
        }

        // Check if users have been removed from the project
        for( let i = 0; i < content.unselectedOptions.length; i++ ) {
            var removeUserFromProject = false;
            for( let j = 0; j < users.length; j++ ) {
                if( users[j].userid === content.unselectedOptions[i].userid ) {
                    removeUserFromProject = true;
                    break;
                }
            }

            if( true === removeUserFromProject ) {
                // User has been removed from the project
                usersToProjectCmd.push( { cmd: "delete", userid: content.unselectedOptions[i].userid } );
            }
        }

        // Perform commands on the API
        let combinedResult = {
            statusCode: 200,
            statusText: ""
        }

        if( 0 < usersToProjectCmd.length ) {
        
            for( let i = 0; i < usersToProjectCmd.length; i++ ) {
                let result = {}

                if( "add" === usersToProjectCmd[i].cmd ) {
                    const { statusCode, statusText, resData } = await UsersToProjectsService.addUserToProject( { projectid: projectid, userid: usersToProjectCmd[i].userid, is_project_lead: false } );
                    result = {
                        statusCode: statusCode,
                        statusText: statusText,
                        resData: resData
                    }
                } else {
                    const { statusCode, statusText, resData } = await UsersToProjectsService.deleteUserToProject( { projectid: projectid, userid: usersToProjectCmd[i].userid } );
                    result = {
                        statusCode: statusCode,
                        statusText: statusText,
                        resData: resData
                    }
                }

                if( 200 !== result.statusCode ) {
                    combinedResult.statusCode = result.statusCode;
                    combinedResult.statusText = result.statusText;
                }
            }

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === combinedResult.statusCode ) {
                alert.severity = "success";
                alert.message = i18n.t( "pages.admin.projects.modify.alert.success" );
            } else {
                alert.severity = "error";
                alert.message = `${combinedResult.statusText}: ${combinedResult.statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the confirm row modify dialog
    handleTableRowModifyMembersDialog_Cancel() {
        this.closeTableRowModifyMembersDialog();
    }

    // Close the modify alert dialog
    closeTableRowModifyMembersDialog() {
        let modifyDialogMembers = {
            open: false,
            projectid: this.state.modifyDialogMembers.projectid,
            name: this.state.modifyDialogMembers.name,
            internal_id: this.state.modifyDialogMembers.internal_id
        }
        this.setState({ modifyDialogMembers: modifyDialogMembers });
    }

    /**
     * ALERT DIALOG <MODIFY LEADS>
     */

    // Called to open the modify dialog
    openTableRowModifyLeadsDialog( projectid, name, internal_id ) {
        let modifyDialogLeads = {
            open: true,
            projectid: projectid,
            name: name,
            internal_id: internal_id
        }
        this.setState({ modifyDialogLeads: modifyDialogLeads });
    }

    // Called when the user clicked "Ok" on the confirm row delete dialog
    async handleTableRowModifyLeadsDialog_Ok( projectid, content ) {
        this.closeTableRowModifyLeadsDialog();

        // Check data validity
        if( content == null ) {
            return;
        }
        if( content.selectedOptions == null ) {
            return;
        }
        if( content.unselectedOptions == null ) {
            return;
        }

        // Content will include arrays in no specific order
        let usersToProjectCmd = [];

        // Get currently assigned users for project
        const users = this.getLeadsOfProject( projectid );

        // Check if users have been added to the project as a member
        for( let i = 0; i < content.selectedOptions.length; i++ ) {
            var userAlreadyAssignedToProject = false;
            for( let j = 0; j < users.length; j++ ) {
                if( users[j].userid === content.selectedOptions[i].userid ) {
                    userAlreadyAssignedToProject = true;
                    break;
                }
            }

            if( false === userAlreadyAssignedToProject ) {
                // User has been added to the project
                usersToProjectCmd.push( { cmd: "add", userid: content.selectedOptions[i].userid } );
            }
        }

        // Check if users have been removed from the project
        for( let i = 0; i < content.unselectedOptions.length; i++ ) {
            var removeUserFromProject = false;
            for( let j = 0; j < users.length; j++ ) {
                if( users[j].userid === content.unselectedOptions[i].userid ) {
                    removeUserFromProject = true;
                    break;
                }
            }

            if( true === removeUserFromProject ) {
                // User has been removed from the project
                usersToProjectCmd.push( { cmd: "delete", userid: content.unselectedOptions[i].userid } );
            }
        }

        // Perform commands on the API
        let combinedResult = {
            statusCode: 200,
            statusText: ""
        }

        if( 0 < usersToProjectCmd.length ) {
        
            for( let i = 0; i < usersToProjectCmd.length; i++ ) {
                let result = {}

                if( "add" === usersToProjectCmd[i].cmd ) {
                    const { statusCode, statusText, resData } = await UsersToProjectsService.updateUserToProject( { projectid: projectid, userid: usersToProjectCmd[i].userid }, { is_project_lead: true } );
                    result = {
                        statusCode: statusCode,
                        statusText: statusText,
                        resData: resData
                    }
                } else {
                    const { statusCode, statusText, resData } = await UsersToProjectsService.updateUserToProject( { projectid: projectid, userid: usersToProjectCmd[i].userid }, { is_project_lead: false } );
                    result = {
                        statusCode: statusCode,
                        statusText: statusText,
                        resData: resData
                    }
                }

                if( 200 !== result.statusCode ) {
                    combinedResult.statusCode = result.statusCode;
                    combinedResult.statusText = result.statusText;
                }
            }

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === combinedResult.statusCode ) {
                alert.severity = "success";
                alert.message = i18n.t( "pages.admin.projects.modify.alert.success" );
            } else {
                alert.severity = "error";
                alert.message = `${combinedResult.statusText}: ${combinedResult.statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the confirm row modify dialog
    async handleTableRowModifyLeadsDialog_Cancel() {
        this.closeTableRowModifyLeadsDialog();
    }

    // Close the modify alert dialog
    closeTableRowModifyLeadsDialog() {
        let modifyDialogLeads = {
            open: false,
            projectid: this.state.modifyDialogLeads.projectid,
            name: this.state.modifyDialogLeads.name,
            internal_id: this.state.modifyDialogLeads.internal_id
        }
        this.setState({ modifyDialogLeads: modifyDialogLeads });
    }

    /**
     * ALERT DIALOG <DELETE>
     */

    // Called to open the delete dialog
    openTableRowDeleteDialog( projectid, name, internal_id ) {
        let deleteDialog = {
            open: true,
            projectid: projectid,
            name: name,
            internal_id: internal_id
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    // Called when the user clicked "Ok" on the confirm row delete dialog
    async handleTableRowDeleteDialog_Ok( projectid ) {
        this.closeTableRowDeleteDialog();

        // Delete the project
        if( null != projectid ) {
            const { statusCode, statusText, resData } = await ProjectService.deleteProject( { projectid: projectid } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                if( true === resData.deleteProject.result ) {
                    alert.severity = "success";
                    alert.message = i18n.t( "pages.admin.projects.delete.dialog.alert.success" );
                } else {
                    alert.severity = "error";
                    alert.message = `${statusText}: ${statusCode}`;
                }
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the confirm row delete dialog
    async handleTableRowDeleteDialog_Cancel() {
        this.closeTableRowDeleteDialog();
    }

    // Close the delete alert dialog
    closeTableRowDeleteDialog() {
        let deleteDialog = {
            open: false,
            projectid: this.state.deleteDialog.projectid,
            name: this.state.deleteDialog.name,
            internal_id: this.state.deleteDialog.internal_id
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    /**
     * ALERT DIALOG <CONFIRM STATUS CHANGE>
     */

    // Called to open the dialog
    openConfirmStatusChangeDialog( projectid, newStatus ) {
        let confirmStatusDialog = {
            open: true,
            projectid: projectid,
            newStatus: newStatus
        }
        this.setState({ confirmStatusDialog: confirmStatusDialog });
    }

    // Called when the user clicked "Ok" on the dialog
    async handleConfirmStatusChangeDialog_Ok( projectid, newStatus ) {
        this.closeConfirmStatusChangeDialog();

        // Update any open tasks of the project and set them to closed
        if( config.enums.projectStatus[0].id !== newStatus ) {
            const tasksOfProject = this.getTasksOfProject( projectid );
            for( let i = 0; i < tasksOfProject.length; i++ ) {
                if( config.enums.taskStatus[0].id === tasksOfProject[i].status ) {

                    // Open task found, close it
                    await TaskService.updateTask( { taskid: tasksOfProject[i].taskid }, { status: config.enums.taskStatus[1].id } );
                }
            }
        }

        // Update the project
        if( null != projectid ) {
            const status = newStatus;

            const { statusCode, statusText, resData } = await ProjectService.updateProject( { projectid: projectid }, { status: status } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.projects.alert.success.update_status");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called when the user clicked "Cancel" or close on the confirm row delete dialog
    async handleConfirmStatusChangeDialog_Cancel() {
        this.closeConfirmStatusChangeDialog();
    }

    // Close the delete alert dialog
    closeConfirmStatusChangeDialog() {
        let confirmStatusDialog = {
            open: false,
            projectid: this.state.confirmStatusDialog.projectid,
            newStatus: this.state.confirmStatusDialog.newStatus
        }
        this.setState({ confirmStatusDialog: confirmStatusDialog });
    }

    /**
     * CALLBACK FUNCTIONS FOR SEARCH FIELD
     */

    // Called when text is entered in the search field
    handleChangeSearchField(value) {
        this.setState({ searchFieldEntry: value });
    }

    /**
     * CALLBACK FUNCTIONS OF TABLE ELEMENTS
     */

    // Called when a delete button of an existing project is clicked within the table
    handleTableRowDeleteClick(event, projectid, name, internal_id) {
        event.stopPropagation();
        this.openTableRowDeleteDialog( projectid, name, internal_id );
    }

    // Called when a modify button of an existing project is clicked within the table
    handleTableRowModifyMembersClick(event, projectid, name, internal_id) {
        event.stopPropagation();
        this.openTableRowModifyMembersDialog( projectid, name, internal_id );
    }

    // Called when a modify button of an existing project is clicked within the table
    handleTableRowModifyLeadsClick(event, projectid, name, internal_id) {
        event.stopPropagation();
        this.openTableRowModifyLeadsDialog( projectid, name, internal_id );
    }

    /**
     * TABLE PAGINATION EVENT HANDLERS
     */

    // Called if page changes on the table display occur
    handleChangeTablePage = (event, newPage) => {
        this.setState({tablePage: newPage});
    };
    
    // Called if the user selects a different amount of shown rows
    handleChangeTableRowsPerPage = (event) => {
        this.setState({tablePage: 0, tableRowsPerPage: +event.target.value});
    };

    /**
     * TOP ROW BUTTONS
     */

    // Called if the add button is clicked
    async handleAddButtonClick() {
        this.openAddDialog();
    }

    // Called if the refresh button is clicked
    async handleRefreshButtonClick() {
        this.resetState();
        await this.refreshTableData();
    }

    /**
     * TEXT FIELDS FOR PROJECT NAME
     */

    // Handle when a project name text field is changed
    async handleProjectNameFieldChange(event, projectid, newValue) {
        event.stopPropagation();

        // Update the project
        if( null != projectid ) {
            const { statusCode, statusText, resData } = await ProjectService.updateProject( { projectid: projectid }, { name: newValue } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.projects.alert.success.update_name");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called by the custom text field displaying the project names to verify their content
    verifyProjectNameFieldContent( textFieldProjectId, textFieldValue ) {
        let result = {
            state: false,
            msg: i18n.t("pages.admin.projects.add.dialog.alert.project_name_empty")
        }
        
        if( null != textFieldValue ) {
            if( textFieldValue !== "" ) {
                const { exists, projectid } = this.doesProjectNameExist( textFieldValue );
                if( true === exists && textFieldProjectId !== projectid ) {
                    result = {
                        state: false,
                        msg: i18n.t("pages.admin.projects.add.dialog.alert.project_does_already_exist")
                    }
                } else {
                    result = {
                        state: true,
                        msg: ""
                    }
                }
            }
        }
        return result;
    }

    /**
     * TEXT FIELDS FOR PROJECT INTERNAL ID
     */

    // Handle when a project internal id text field is changed
    async handleInternalIdFieldChange(event, projectid, newValue) {
        event.stopPropagation();

        // Update the project
        if( null != projectid ) {
            const { statusCode, statusText, resData } = await ProjectService.updateProject( { projectid: projectid }, { internal_id: newValue } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.projects.alert.success.update_internal_id");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Called by the custom text field displaying the project names to verify their content
    verifyInternalIdFieldContent( textFieldProjectId, textFieldValue ) {
        let result = {
            state: false,
            msg: i18n.t("pages.admin.projects.add.dialog.alert.project_internalid_empty")
        }
        
        if( null != textFieldValue ) {
            if( textFieldValue !== "" ) {
                const { exists, projectid } = this.doesInternalIdExist( textFieldValue );
                if( true === exists && textFieldProjectId !== projectid ) {
                    result = {
                        state: false,
                        msg: i18n.t("pages.admin.projects.add.dialog.alert.project_does_already_exist")
                    }
                } else {
                    result = {
                        state: true,
                        msg: ""
                    }
                }
            }
        }
        return result;
    }

    /**
     * TEXT FIELDS FOR PROJECT DESCRIPTION
     */

    // Handle when a project description text field is changed
    async handleDescriptionFieldChange(event, projectid, newValue) {
        event.stopPropagation();

        // Update the project
        if( null != projectid ) {
            const { statusCode, statusText, resData } = await ProjectService.updateProject( { projectid: projectid }, { description: newValue } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.projects.alert.success.update_description");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    // Verify content of description field
    verifyDescriptionFieldContent( textFieldProjectId, textFieldValue ) {
        return { state: true, msg: "" }
    }

    /**
     * SELECTOR FOR PROJECT STATUS
     */

    // Handle when a project status selector is changed
    async handleStatusChange(event, projectid) {
        event.stopPropagation();

        // Get the new desired status
        const status = event.target.value;

        // In case the user wants to set the project to something else than "open" status check if the project has any tasks which are still open
        // in that case we show a confirm dialog first
        if( config.enums.projectStatus[0].id !== status ) {
            const tasksOfProject = this.getTasksOfProject( projectid );
            for( let i = 0; i < tasksOfProject.length; i++ ) {
                if( config.enums.taskStatus[0].id === tasksOfProject[i].status ) {

                    // Open task found, show the dialog instead of modifying the project status
                    this.openConfirmStatusChangeDialog( projectid, event.target.value );
                    return;
                }
            }
        }

        // Update the project (no tasks need to be updated, since the project has no open tasks)
        if( null != projectid ) {
            const { statusCode, statusText, resData } = await ProjectService.updateProject( { projectid: projectid }, { status: status } );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.admin.projects.alert.success.update_status");
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );

            // Refresh the table data
            await this.refreshTableData();
        }
    }

    /**
     * HELPERS CALLED DURING RENDER TO RETRIEVE INFO FROM PROCESSED DATA
     */

    // Get the textual representation of an user value
    getUserLabel( value ) {
        let result = "";
        if( null != value ) {
            let userTypeName = "";
            if( value.userTypeName !== "" && value.userTypeName != null ) {
                userTypeName = "(" + value.userTypeName + ") ";
            }
            result = `${userTypeName}${value.lastname}, ${value.firstname}`
        }
        return result;
    }

    // Get the color class representation of an user value
    getUserEnabled( value ) {
        if( null != value ) {
            if( value.account_enabled ) {
                return true;
            } else {
                return false
            }
        }
        return false;
    }

    // Sort given users for project data
    sortUsersOfProject( data ) {
        let result = [];
        if( null != data ) {
            result = data;
            result.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.userTypeName > b.userTypeName ) return 1;
                if( a.userTypeName < b.userTypeName ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });
        }
        return result;
    }

    // Get the leads for a project
    getLeadsOfProject( projectid ) {

        if( projectid != null ) {
            // Retrieve info from array
            for( let i = 0; i < this.state.tableData.usersForProject.length; i++ ) {
                if( this.state.tableData.usersForProject[i].projectid === projectid ) {
                    return this.state.tableData.usersForProject[i].leads;
                }
            }
        }

        // Fallback empty data
        return [];
    }

    // Get the members for a project
    getMembersOfProject( projectid ) {

        if( projectid !== null ) {
            // Retrieve info from array
            for( let i = 0; i < this.state.tableData.usersForProject.length; i++ ) {
                if( this.state.tableData.usersForProject[i].projectid === projectid ) {
                    return this.state.tableData.usersForProject[i].members;
                }
            }
        } else {
            // No project id given, return all users
            let usersFound = [];

            if( null != this.state.data.users ) {
                let users = this.state.data.users;
                for( let i = 0; i < users.length; i++ ) {
                    usersFound.push( users[i] );
                }
            }

            usersFound.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.userTypeName > b.userTypeName ) return 1;
                if( a.userTypeName < b.userTypeName ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });
            return usersFound;
        }

        // Fallback empty data
        return [];
    }

    // Get the tasks for a project
    getTasksOfProject( projectid ) {

        if( projectid !== null ) {
            // Retrieve info from array
            for( let i = 0; i < this.state.tableData.tasksForProject.length; i++ ) {
                if( this.state.tableData.tasksForProject[i].projectid === projectid ) {
                    return this.state.tableData.tasksForProject[i].tasks;
                }
            }
        } else {
            // No project id given, return all tasks
            let tasksFound = [];

            if( null != this.state.data.tasks ) {
                let tasks = this.state.data.tasks;
                for( let i = 0; i < tasks.length; i++ ) {
                    tasksFound.push( tasks[i] );
                }
            }

            tasksFound.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
            return tasksFound;
        }

        // Fallback empty data
        return [];
    }

    // Get all users not assigned to any project
    getUsersWithoutProject() {
        let usersFound = [];

        if( null != this.state.data.users && null != this.state.data.usersToProjects ) {
            let users = this.state.data.users;
            let usersToProjects = this.state.data.usersToProjects;

            for( let i = 0; i < users.length; i++ ) {
                let userHasNoProject = true;
                for( let j = 0; j < usersToProjects.length; j++ ) {
                    if( users[i].userid === usersToProjects[j].userid ) {
                        userHasNoProject = false;
                        break;
                    }
                }

                if( true === userHasNoProject ) {
                    usersFound.push( users[i] );
                }
            }
        }

        usersFound.sort(function(a, b) { 
            // Sort by user type first, then by user lastname
            if( a.userTypeName > b.userTypeName ) return 1;
            if( a.userTypeName < b.userTypeName ) return -1;
            if( a.lastname > b.lastname ) return 1;
            if( a.lastname < b.lastname ) return -1;
            return 0;
        });
        return usersFound;
    }

    // Get delete button info for a project
    getDeleteButtonDataForProject( projectid ) {
        // Retrieve info from array
        for( let i = 0; i < this.state.tableData.deleteButtonsForProject.length; i++ ) {
            if( this.state.tableData.deleteButtonsForProject[i].projectid === projectid ) {
                return this.state.tableData.deleteButtonsForProject[i];
            }
        }
        // Fallback empty data
        return { projectid: projectid, enabled: false, tooltip: i18n.t("pages.admin.projects.delete.button.tooltip.disabled") };
    }

    // Check if a certain project name already exists
    doesProjectNameExist( name ) {
        let result = {
            exists: false,
            projectid: 0
        }

        // Retrieve info from array
        if( null != this.state.data.projects ) {
            let projects = this.state.data.projects;

            for( let i = 0; i < projects.length; i++ ) {
                if( projects[i].name === name ) {
                    result.exists = true;
                    result.projectid = projects[i].projectid;
                    break;
                }
            }
        }

        return result;
    }

    // Check if a certain internal id already exists
    doesInternalIdExist( internal_id ) {
        let result = {
            exists: false,
            projectid: 0
        }

        // Retrieve info from array
        if( null != this.state.data.projects ) {
            let projects = this.state.data.projects;

            for( let i = 0; i < projects.length; i++ ) {
                if( projects[i].internal_id === internal_id ) {
                    result.exists = true;
                    result.projectid = projects[i].projectid;
                    break;
                }
            }
        }

        return result;
    }

    /**
     * DATA SORT
     */

    // Handle table sort
    handleRequestSort( event, property ) {

        // Check if clicked on same property
        if( this.state.orderBy === property ) {

            // User clicked on same property
            // If already sorted in ascending order, switch to descending order
            if( this.state.order === 'asc' ) {
                this.setState({ order: 'desc', orderBy: property });
            } else {

                // If already sorted in descending order, switch to no order
                this.setState({ order: 'asc', orderBy: 0 });
            }

        } else {
            // User clicked on new property, sort new property in ascending order
            this.setState({ order: 'asc', orderBy: property });
        }
    };

    // Sort the given table data
    sortTableData( projects ) {
        // Handle case where no ordering is selected
        if( this.state.orderBy === 0 ) {
            return projects.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
        }
        return stableSort( projects, getComparator(this.state.order, this.state.orderBy) );
    }

    /**
     * MAIN RENDER FUNCTION
     */
    render() {
        const { classes } = this.props;

        // Only display data if it has already been loaded
        let contentTable = "";
        let additionalNotes = "";
        let usersWithoutProjectContent = "";

        // Make sure if we got the data already
        if( null != this.state.data.projects ) {

            // Assemble table content
            const tableHeader = [
                { key: "name", span: 1, align: "left", sortable: true, style: {}, text: i18n.t("pages.admin.projects.table.header.name") },
                { key: "internal_id", span: 1, align: "left", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.projects.table.header.internal_id") },
                { key: "description", span: 1, align: "left", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.projects.table.header.description") },
                { key: "create_date", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.projects.table.header.create_date") },
                { key: "status", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.projects.table.header.status") },
                { key: "status_change_date", span: 1, align: "center", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.projects.table.header.status_change_date") },
                { key: "tasks", span: 1, align: "center", sortable: false, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "0px" }, text: i18n.t("pages.admin.projects.table.header.tasks") },
                { key: "controls", span: 3, align: "center", sortable: false, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.projects.table.header.controls") }
            ];

            let projects = _.cloneDeep( this.state.data.projects );

            // Filter out projects if the user is only project lead of some of these projects
            if( null != this.props.adminFlags ) {
                if( null == this.props.adminFlags.projects || false === this.props.adminFlags.projects ) {
                    if( null == this.props.adminFlags.projectlead || 0 === this.props.adminFlags.projectlead.length ) {
                        projects = [];
                    } else {

                        // Go through all projects which this user is the lead for
                        let filteredProjects = [];
                        for( let i = 0; i < this.props.adminFlags.projectlead.length; i++ ) {
                            for( let j = 0; j < projects.length; j++ ) {
                                if( projects[j].projectid === this.props.adminFlags.projectlead[i] ) {
                                    filteredProjects.push( projects[j] );
                                }
                            }
                        }

                        projects = filteredProjects;
                    }
                }
            }

            // Filter table data if any search entry has been given
            if( this.state.searchFieldEntry !== "" ) {
                projects = projects.filter( project => ( 
                    String(project.name.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase()) || 
                    String(project.internal_id).includes(this.state.searchFieldEntry)
                ));
            }

            // Sort table entries by given properties
            projects = this.sortTableData( projects );

            // Preload translations
            let tooltips = {
                members: i18n.t("pages.admin.projects.modify.members.button.tooltip.headline"),
                leads: i18n.t("pages.admin.projects.modify.leads.button.tooltip.headline"),
                tasks: i18n.t("pages.admin.projects.table.tooltips.tasks")
            }

            // Determine if we show the delete button column
            let showDeleteButtons = true;
            if( null != this.props.adminFlags ) {
                if( null == this.props.adminFlags.projects || false === this.props.adminFlags.projects ) {
                    showDeleteButtons = false;
                }
            }

            // Determine the base link to the tasks page
            let baseLinkToTasks = "/admin?tasks&projectid=";
            if( null != this.props.adminFlags ) {
                if( null == this.props.adminFlags.projects || false === this.props.adminFlags.projects ) {
                    baseLinkToTasks = "/tasks?projectid=";
                }
            }

            contentTable = (
                <div>
                    <TableContainer style={{ minHeight: "406px" }} component={Paper}>
                        <StyledTable size="small" >
                            <StyledTableHead>
                                <StyledTableRow key={"projects-header-first"}>
                                    {tableHeader.map((col) => (
                                        <StyledTableCell key={col.key} colSpan={col.span} align={col.align} style={col.style}>
                                            <StyledTableSortLabel
                                                active={this.state.orderBy === col.key}
                                                hideSortIcon={false}
                                                direction={this.state.orderBy === col.key ? this.state.order : 'asc'}
                                                onClick={(event) => this.handleRequestSort(event, col.key)}
                                                disabled={!(col.sortable)}
                                            >
                                                {col.text}
                                            </StyledTableSortLabel>
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </StyledTableHead>
                            <TableBody>
                                {projects.slice(this.state.tablePage * this.state.tableRowsPerPage, this.state.tablePage * this.state.tableRowsPerPage + this.state.tableRowsPerPage).map((project) => {
                                    const membersOfProject = this.getMembersOfProject( project.projectid );
                                    const leadsOfProject = this.getLeadsOfProject( project.projectid );
                                    const tasksOfProject = this.getTasksOfProject( project.projectid );
                                    const deleteButtonForProject = this.getDeleteButtonDataForProject( project.projectid );
                                    const linkToTasks = `${baseLinkToTasks}${project.projectid}`

                                    let disableTaskButton = false;
                                    if( false === this.props.adminFlags.tasks ) {
                                        disableTaskButton = true;
                                    }

                                    return (
                                        <StyledTableRow key={project.projectid} projectid={project.projectid}>
                                            <StyledTableCell className={classes.tableCellTextField} key="name" width="20%">
                                                <CustomTextField
                                                    maxLength={config.maxTextLengths.project.name}
                                                    textFieldValue={project.name} 
                                                    verifyContent={(content) => this.verifyProjectNameFieldContent(project.projectid, content)} 
                                                    onApply={(event, newValue) => this.handleProjectNameFieldChange(event, project.projectid, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.tableCellTextField} key="internal_id" width="20%">
                                                <CustomTextField
                                                    maxLength={config.maxTextLengths.project.internalId}
                                                    textFieldValue={project.internal_id} 
                                                    verifyContent={(content) => this.verifyInternalIdFieldContent(project.projectid, content)} 
                                                    onApply={(event, newValue) => this.handleInternalIdFieldChange(event, project.projectid, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.tableCellTextField} key="description" width="20%">
                                                <CustomMultilineTextField 
                                                    textFieldValue={project.description} 
                                                    title={i18n.t("pages.admin.projects.modify.description.dialog.title")}
                                                    descText={i18n.t("pages.admin.projects.modify.description.dialog.description")}
                                                    buttonLeft={i18n.t("pages.admin.projects.modify.description.dialog.button_left")}
                                                    buttonRight={i18n.t("pages.admin.projects.modify.description.dialog.button_right")}
                                                    onApply={(event, newValue) => this.handleDescriptionFieldChange(event, project.projectid, newValue)}
                                                    verifyContent={(content) => this.verifyDescriptionFieldContent(project.projectid, content)}
                                                    rows={8}
                                                    rowsMax={8}
                                                    allowEmpty={true}
                                                    required={false}
                                                    tooltip={project.description}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="create_date" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1, minWidth: "15%" }} onClick={(event) => event.stopPropagation()}>
                                                <DateOnly language={this.context.language} value={project.create_date} align="center" variant="body1" />
                                            </StyledTableCell>
                                            <StyledTableCell key="status" align="center" padding="checkbox" width="5%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <Select
                                                    id="status"
                                                    value={project.status}
                                                    onChange={(event) => this.handleStatusChange(event, project.projectid)}
                                                    name="status"
                                                    >
                                                    {config.enums.projectStatus.map((entry) => (
                                                        <MenuItem key={entry.id} value={entry.id}>{i18n.t(entry.name)}</MenuItem>
                                                    ))}
                                                </Select>
                                            </StyledTableCell>
                                            <StyledTableCell key="status_change_date" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1, minWidth: "15%" }} onClick={(event) => event.stopPropagation()}>
                                                <DateOnly language={this.context.language} value={project.status_change_date} align="center" variant="body1" />
                                            </StyledTableCell>
                                            <StyledTableCell key="tasks" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <Tooltip title={tooltips.tasks}>
                                                    <div>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.link}
                                                            component={Link}
                                                            to={linkToTasks}
                                                            style={{ height: "30px", width: "45px" }}
                                                            disabled={disableTaskButton}
                                                        >
                                                            {tasksOfProject.length} <FormatListBulletedIcon />
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                            <StyledTableCell key="modify-members" align="center" padding="checkbox" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <Tooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit" variant="button">{tooltips.members}</Typography>
                                                            <Typography color="inherit" variant="caption">
                                                                {membersOfProject.map((user) => {
                                                                    let fullUserEntry = user.lastname + ", " + user.firstname;
                                                                    if( user.userTypeName != null && user.userTypeName !== "" ) {
                                                                        fullUserEntry = "(" + user.userTypeName + ") " + user.lastname + ", " + user.firstname;
                                                                    }
                                                                    return (
                                                                        <div key={user.userid}>{fullUserEntry}</div>
                                                                    )
                                                                })}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.submit}
                                                            disabled={false}
                                                            onClick={(event) => this.handleTableRowModifyMembersClick(event, project.projectid, project.name, project.internal_id)}
                                                            style={{ height: "30px", width: "45px" }}
                                                            value={project.projectid}
                                                        >
                                                            {membersOfProject.length} <PersonIcon />
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                            <StyledTableCell key="modify-leads" align="center" padding="checkbox" style={{ borderTopWidth: 0 }} onClick={(event) => event.stopPropagation()}>
                                                <Tooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit" variant="button">{tooltips.leads}</Typography>
                                                            <Typography color="inherit" variant="caption">
                                                                {leadsOfProject.map((user) => {
                                                                    let fullUserEntry = user.lastname + ", " + user.firstname;
                                                                    if( user.userTypeName != null && user.userTypeName !== "" ) {
                                                                        fullUserEntry = "(" + user.userTypeName + ") " + user.lastname + ", " + user.firstname;
                                                                    }
                                                                    return (
                                                                        <div key={user.userid}>{fullUserEntry}</div>
                                                                    )
                                                                })}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.submit}
                                                            disabled={false}
                                                            onClick={(event) => this.handleTableRowModifyLeadsClick(event, project.projectid, project.name, project.internal_id)}
                                                            style={{ height: "30px", width: "45px" }}
                                                            value={project.projectid}
                                                        >
                                                            {leadsOfProject.length} <PersonIcon />
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                            <StyledTableCell key="delete" align="center" padding="checkbox" style={{ borderTopWidth: 0 }} onClick={(event) => event.stopPropagation()}>
                                            {showDeleteButtons && (
                                                <Tooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit" variant="caption">{deleteButtonForProject.tooltip}</Typography>
                                                            <Typography color="inherit" variant="caption">
                                                                {deleteButtonForProject.links.map((link, index) => (
                                                                    <div key={index}>* {link}</div>
                                                                ))}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.clearButton}
                                                            disabled={!(deleteButtonForProject.enabled)}
                                                            onClick={(event) => this.handleTableRowDeleteClick(event, project.projectid, project.name, project.internal_id)}
                                                            style={{ height: "30px", width: "45px" }}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                    <Grid container direction="row" justify="flex-end" className={classes.tablePaginationGrid} spacing={1} wrap='nowrap'>
                        <Grid item>
                            <TablePagination
                                className={classes.tablePagination}
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={projects.length}
                                rowsPerPage={this.state.tableRowsPerPage}
                                labelRowsPerPage={i18n.t("table.pagination.rows_per_page")}
                                page={this.state.tablePage}
                                onChangePage={this.handleChangeTablePage}
                                onChangeRowsPerPage={this.handleChangeTableRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                    <ModalDialog 
                        open={this.state.addDialog.open}
                        title={i18n.t("pages.admin.projects.add.dialog.title")}
                        descText={i18n.t("pages.admin.projects.add.dialog.description")}
                        buttonLeft={i18n.t("pages.admin.projects.add.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.projects.add.dialog.button_right")}
                        handleClickClose={() => this.handleAddDialog_Cancel()}
                        handleClickLeft={(content) => this.handleAddDialog_Ok(content)}
                        handleClickRight={() => this.handleAddDialog_Cancel()}
                    >
                        <ModalDialogTextField
                            maxLength={config.maxTextLengths.project.name}
                            label={i18n.t("pages.admin.projects.add.dialog.field_projectname")} 
                            verifyContent={(content) => this.verifyAddDialogProjectName_Content(content)} 
                            autoFocus={false}
                        />
                        <ModalDialogTextField
                            maxLength={config.maxTextLengths.project.internalId}
                            label={i18n.t("pages.admin.projects.add.dialog.field_internalid")} 
                            verifyContent={(content) => this.verifyAddDialogInternalId_Content(content)}
                            autoFocus={false}
                        />
                        <ModalDialogTextField 
                            label={i18n.t("pages.admin.projects.add.dialog.field_description")}
                            verifyContent={(content) => this.verifyAddDialogDescription_Content(content)} 
                            autoFocus={false}
                            multiline={true}
                            rows={8}
                            rowsMax={8}
                            allowEmpty={true}
                            required={false}
                        />
                    </ModalDialog>
                    <ModalDialog 
                        open={this.state.modifyDialogMembers.open}
                        title={i18n.t("pages.admin.projects.modify.members.dialog.title")}
                        descText={i18n.t("pages.admin.projects.modify.members.dialog.description", { name: this.state.modifyDialogMembers.name })}
                        buttonLeft={i18n.t("pages.admin.projects.modify.members.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.projects.modify.members.dialog.button_right")}
                        handleClickClose={() => this.handleTableRowModifyMembersDialog_Cancel()}
                        handleClickLeft={(content) => this.handleTableRowModifyMembersDialog_Ok( this.state.modifyDialogMembers.projectid, content )}
                        handleClickRight={() => this.handleTableRowModifyMembersDialog_Cancel()}
                        maxWidth={"md"}
                    >
                        <ModalDialogChipInput
                            className={classes.chipInput}
                            getOptionEnabled={(value) => this.getUserEnabled(value)}
                            getOptionLabel={(value) => this.getUserLabel(value)} 
                            getAllOptions={() => this.getMembersOfProject(null)} 
                            getSelectedOptions={() => this.getMembersOfProject(this.state.modifyDialogMembers.projectid)} 
                            sortOptions={(data) => this.sortUsersOfProject(data)}
                            textFieldLabel={i18n.t("pages.admin.projects.modify.members.dialog.edit.label")}
                            noOptionsText={i18n.t("pages.admin.projects.modify.members.dialog.edit.no_options")}
                        />
                    </ModalDialog>
                    <ModalDialog 
                        open={this.state.modifyDialogLeads.open}
                        title={i18n.t("pages.admin.projects.modify.leads.dialog.title")}
                        descText={i18n.t("pages.admin.projects.modify.leads.dialog.description", { name: this.state.modifyDialogLeads.name })}
                        buttonLeft={i18n.t("pages.admin.projects.modify.leads.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.projects.modify.leads.dialog.button_right")}
                        handleClickClose={() => this.handleTableRowModifyLeadsDialog_Cancel()}
                        handleClickLeft={(content) => this.handleTableRowModifyLeadsDialog_Ok( this.state.modifyDialogLeads.projectid, content )}
                        handleClickRight={() => this.handleTableRowModifyLeadsDialog_Cancel()}
                        maxWidth={"md"}
                    >
                        <ModalDialogChipInput
                            className={classes.chipInput}
                            getOptionEnabled={(value) => this.getUserEnabled(value)}
                            getOptionLabel={(value) => this.getUserLabel(value)} 
                            getAllOptions={() => this.getMembersOfProject(this.state.modifyDialogLeads.projectid)} 
                            getSelectedOptions={() => this.getLeadsOfProject(this.state.modifyDialogLeads.projectid)} 
                            sortOptions={(data) => this.sortUsersOfProject(data)}
                            textFieldLabel={i18n.t("pages.admin.projects.modify.leads.dialog.edit.label")}
                            noOptionsText={i18n.t("pages.admin.projects.modify.leads.dialog.edit.no_options")}
                        />
                    </ModalDialog>
                    <ModalDialog 
                        open={this.state.deleteDialog.open}
                        title={i18n.t("pages.admin.projects.delete.dialog.title")}
                        descText={i18n.t("pages.admin.projects.delete.dialog.description", { name: this.state.deleteDialog.name })}
                        buttonLeft={i18n.t("pages.admin.projects.delete.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.projects.delete.dialog.button_right")}
                        handleClickClose={() => this.handleTableRowDeleteDialog_Cancel()}
                        handleClickLeft={() => this.handleTableRowDeleteDialog_Ok( this.state.deleteDialog.projectid )}
                        handleClickRight={() => this.handleTableRowDeleteDialog_Cancel()}
                    />
                    <ModalDialog 
                        open={this.state.confirmStatusDialog.open}
                        title={i18n.t("pages.admin.projects.status_closed_done.dialog.title")}
                        descText={i18n.t("pages.admin.projects.status_closed_done.dialog.description")}
                        buttonLeft={i18n.t("pages.admin.projects.status_closed_done.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.projects.status_closed_done.dialog.button_right")}
                        handleClickClose={() => this.handleConfirmStatusChangeDialog_Cancel()}
                        handleClickLeft={() => this.handleConfirmStatusChangeDialog_Ok( this.state.confirmStatusDialog.projectid, this.state.confirmStatusDialog.newStatus )}
                        handleClickRight={() => this.handleConfirmStatusChangeDialog_Cancel()}
                    />
                </div>
            )

            // Retrieve all users which are not on any project
            const allUsersWithoutProject = this.getUsersWithoutProject();

            let usersWithoutProjectList = i18n.t("pages.admin.projects.unassigned_users.none");

            if( 0 < allUsersWithoutProject.length ) {
                usersWithoutProjectList = (
                    <div>
                        {allUsersWithoutProject.map(function(user) {
                            let fullUserEntry = user.lastname + ", " + user.firstname;
                            if( user.userTypeName != null && user.userTypeName !== "" ) {
                                fullUserEntry = "(" + user.userTypeName + ") " + user.lastname + ", " + user.firstname;
                            }

                            if( user.account_enabled ) {
                                return (
                                    <StyledChipEnabled key={"chip-enabled-"+user.userid} label={fullUserEntry} />
                                )
                            } else {
                                return (
                                    <Tooltip key={"chip-tooltip-"+user.userid} title={i18n.t("tooltips.user_disabled")}>
                                        <StyledChipDisabled key={"chip-disabled-"+user.userid} label={fullUserEntry} />
                                    </Tooltip>
                                )
                            }
                        })}
                    </div>
                )
            }

            usersWithoutProjectContent = (
                <div>
                    <PageSubtitle title={i18n.t("pages.admin.projects.unassigned_users.headline")} />
                    {usersWithoutProjectList}
                </div>
            )

            additionalNotes = (
                <div>
                    <PageSubtitle title={i18n.t("pages.admin.projects.notes.headline")} />
                    <PageDescription key="notes-desc-1">
                        {i18n.t("pages.admin.projects.notes.desc1")}
                    </PageDescription>
                </div>
            )
        }

        // Some elements might be set from the parent page
        let pageTitle = i18n.t("pages.admin.projects.title");
        if( null != this.props.pageTitle ) {
            pageTitle = this.props.pageTitle;
        }

        let pageDesc = i18n.t("pages.admin.projects.desc");
        if( null != this.props.pageDesc ) {
            pageDesc = this.props.pageDesc;
        }

        let showAddButton = true;
        if( null != this.props.adminFlags ) {
            if( null == this.props.adminFlags.projects || false === this.props.adminFlags.projects ) {
                showAddButton = false;
            }
        }

        return (
            <React.Fragment>
                <PageTitle title={pageTitle} />
                <p>{pageDesc}</p>
                <SearchControls
                    label={i18n.t("pages.admin.projects.search.label")}
                    helperText={i18n.t("pages.admin.projects.search.subtext")}
                    addTooltip={i18n.t("pages.admin.projects.add.button.tooltip")}
                    refreshTooltip={i18n.t("buttons.refresh.tooltip")}
                    onAdd={this.handleAddButtonClick}
                    onRefresh={this.handleRefreshButtonClick}
                    onChange={this.handleChangeSearchField}
                    showAddButton={showAddButton}
                />
                <div>{contentTable}</div>
                <div>{usersWithoutProjectContent}</div>
                <br />
                {additionalNotes}
            </React.Fragment>
        )
    }
};

export default withStyles(useStyles)(AdminProjects);