import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Calendar from 'rc-year-calendar';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import CalendarHeader from './CalendarHeader'
import { toJSONLocalDate, jsonEqual, toDateObject } from '../helpers.js';

const useStyles = theme => ({
    popover: {
        pointerEvents: 'none',

    },
    popoverPaper: {
        padding: theme.spacing(1),
        backgroundColor: "#707070",
    },
    popoverText: {
        fontSize: "14px",
        color: "#fff"
    }
});

/**
 * Holidays Calendar View
 */
class HolidaysCalendar extends Component {
    state = {
        initialHolidays: [],
        dataSource: [],
        currentYear: null,
        tooltip: {
            open: false,
            anchor: null,
            text: ""
        }
    }

    constructor(props) {
        super(props);
        
        this.handleYearChanged = this.handleYearChanged.bind( this );
        this.dataSourceRenderer = this.dataSourceRenderer.bind( this );
        this.handleDayEnter = this.handleDayEnter.bind( this );
        this.handleDayLeave = this.handleDayLeave.bind( this );
        this.handleDayClick = this.handleDayClick.bind( this );
        this.handleRenderEnd = this.handleRenderEnd.bind( this );
    }

    // Convert given holidays to data source expected by the calendar object
    // { id: int, name: string, location: string, startDate: date, endDate: date}
    convertToDataSource( holidays ) {
        let result = [];

        if( null != holidays ) {
            for ( const [i, holiday] of holidays.entries() ) {

                let dateObject = toDateObject( holiday.date );

                // Correct month by one, since Date() expects the month to start at zero
                let date = {
                    id: i,
                    name: holiday.name,
                    startDate: dateObject,
                    endDate: dateObject,
                }

                result.push( date );
            }
        }

        return result;
    }

    // Convert given calendar events back to holidays
    // { date: "yyyy-mm-dd" name: string, }
    convertToHolidays( events ) {
        let result = [];

        if( null != events ) {
            for ( let event of events ) {
                let date = event.startDate.toJSON().split("T")[0];
                let holiday = {
                    date: date,
                    name: event.name
                }
                result.push( holiday );
            }
        }

        return result;
    }

    // Called to refresh the holidays
    refreshHolidays( currentYear ) {
        let dataSource = [];
        let holidays = [];
    
        if( null != this.props.getHolidays ) {
            holidays = this.props.getHolidays( currentYear );
            dataSource = this.convertToDataSource( holidays );
        }

        this.setState({ dataSource: dataSource, initialHolidays: holidays, currentYear: currentYear });
    }

    // Called when the displayed year changed
    handleYearChanged( currentYear ) {
        this.refreshHolidays( currentYear );

        if( null != this.props.onYearChanged ) {
            this.props.onYearChanged( currentYear );
        }
    }

    // Called on render end of calendar
    handleRenderEnd( currentYear ) {
        let dataSource = this.state.dataSource;
        if( null != this.props.getHolidays ) {
            let newHolidays = this.props.getHolidays( this.state.currentYear );
            if( !jsonEqual( newHolidays, this.state.initialHolidays ) ) {
                dataSource = this.convertToDataSource( newHolidays );
                this.setState({ dataSource: dataSource, initialHolidays: newHolidays });
            }
        }
    }

    // Custom renderer for the data source events
    dataSourceRenderer( element, date, event ) {
        element.style.background = "#248EDB";
        element.style.color = "#FFFFFF";
        return element;
    }

    // Called when mouse enters a day
    handleDayEnter( event ) {

        // Get info from object
        const events = event.events;

        // Only open tooltip when we have a set holiday on this day
        if( events.length > 0 ) {
            this.setState({ tooltip: { anchor: event.explicitOriginalTarget, open: true, text: events[0].name } });
        }
    }

    // Called when mouse leaves a day
    handleDayLeave() {
        if( true === this.state.tooltip.open ) {
            this.setState({ tooltip: { anchor: null, open: false, text: "" } });
        }
    }

    // Called when the user clicks on a day
    handleDayClick( event ) {

        // Get info from object
        const events = event.events;
        const date = event.date;
        let dateString = toJSONLocalDate( date );

        let holidays = [];
        if( events.length > 0 ) {
            // Convert events on that day to holiday objects before pushing them
            holidays = this.convertToHolidays( events );
        }

        // Push info to parent
        if( null != this.props.onEventClick ) {
            this.props.onEventClick( dateString, holidays );
        }
    }

    // Called by react when this component has been mounted
    componentDidMount() {
        let currentYear = new Date().getFullYear();
        if( null != this.props.year ) {
            currentYear = this.props.year;
        }
        this.refreshHolidays( currentYear );
    }

    render() {
        // Ensure that we use the proper data source, since data might get filtered afterwards
        let dataSource = this.state.dataSource;
        if( null != this.props.getHolidays ) {
            let newHolidays = this.props.getHolidays( this.state.currentYear );
            if( !jsonEqual( newHolidays, this.state.initialHolidays ) ) {
                dataSource = this.convertToDataSource( newHolidays );
            }
        }

        return (
            <div>
                <CalendarHeader
                    year={this.props.year}
                    onYearChanged={this.handleYearChanged}
                />
                <Calendar
                    className={this.props.classes.calendar}
                    language={this.props.language}
                    enableRagenSelection={false}
                    enableContextMenu={true}
                    displayWeekNumber={false}
                    dataSource={dataSource}
                    // eslint-disable-next-line
                    style="custom"
                    customDataSourceRenderer={this.dataSourceRenderer}
                    onDayEnter={this.handleDayEnter}
                    onDayLeave={this.handleDayLeave}
                    onDayClick={this.handleDayClick}
                    onRenderEnd={this.handleRenderEnd}
                    displayHeader={false}
                    year={this.state.currentYear}
                />
                <Popover
                    id="mouse-over-popover"
                    className={this.props.classes.popover}
                    classes={{
                        paper: this.props.classes.popoverPaper,
                    }}
                    open={this.state.tooltip.open}
                    anchorEl={this.state.tooltip.anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    disableRestoreFocus
                >
                    <Typography className={this.props.classes.popoverText}>
                        {this.state.tooltip.text}
                    </Typography>
                </Popover>
            </div>
        );
    }
};

export default withStyles(useStyles)(HolidaysCalendar);