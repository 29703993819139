import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CalendarHelper from '../classes/CalendarHelper.js';
import { StyledDataTable } from './StyledTable.js'
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Table Vacation
 */
class TableVacation extends Component {
    state = {}

    // Add to table data array
    addTableData( name, value ) {
        return { name, value };
    }

    // Get user summary data for given year
    getSummary( vacationReport, year ) {

        // Oldest year is at the beginning of the array
        let daysFromLastYear = 0;
        let daysFromContract = 0;
        let daysAdjusted = 0;
        let daysBooked = 0;
        let daysAvailable = 0;

        if( null != vacationReport ) {
            for( let i = 0; i < vacationReport.years.length; i++ ) {
                if( year > vacationReport.years[i].year.toString() ) {
                    daysFromLastYear += vacationReport.years[i].days_remaining_in_year;
                } else if( year === vacationReport.years[i].year.toString() ) {
                    daysFromContract = vacationReport.years[i].days_available_in_year;
                    daysBooked = vacationReport.years[i].days_booked_in_year;
                    daysAvailable = vacationReport.years[i].days_remaining_in_year;
                    daysAdjusted = vacationReport.years[i].days_adjusted_in_year;
                    break;
                }
            }
        }

        let daysTotal = daysFromLastYear + daysFromContract;
        daysTotal += daysAdjusted;
        daysAvailable += daysFromLastYear;
        
        return {
            daysFromLastYear: daysFromLastYear,
            daysFromContract: daysFromContract,
            daysAdjusted: daysAdjusted,
            daysTotal: daysTotal,
            daysBooked: daysBooked,
            daysAvailable: daysAvailable
        }
    }

    /// Get series: booked vacation days
    getSeriesBookedDays( vacationReport, year ) {
        let series = [];

        if( null != vacationReport ) {
            for( let i = 0; i < vacationReport.years.length; i++ ) {
                // Only return years based on selection
                if( year === "" || year === vacationReport.years[i].year.toString() ) {
                    for( let j = 0; j < vacationReport.years[i].months.length; j++ ) {

                        // Add booked in month at the mid of the month
                        series.push( vacationReport.years[i].months[j].days_booked_in_month );
                    }
                }
            }
        }

        return series;
    }

    /// Get series: adjusted vacation days
    getSeriesAdjustedDays( vacationReport, year ) {
        let series = [];

        if( null != vacationReport ) {
            for( let i = 0; i < vacationReport.years.length; i++ ) {
                // Only return years based on selection
                if( year === "" || year === vacationReport.years[i].year.toString() ) {
                    for( let j = 0; j < vacationReport.years[i].months.length; j++ ) {

                        // Add booked in month at the mid of the month
                        series.push( vacationReport.years[i].months[j].days_adjusted_in_month );
                    }
                }
            }
        }

        return series;
    }

    // Render summary
    renderSummary( vacationReport, year ) {

        // If not specific year is selected, show a warning in this table since it does not make sense
        let tableHeader = [];
        let tableData = [];

        if( year !== "" ) {
            tableHeader = [
                { id: 1, width: "50%", name: i18n.t("pages.reports.options.vacation.summary.headline_summary") },
                { id: 2, width: "50%", name: i18n.t("pages.reports.options.vacation.summary.headline_days") }
            ];

            const summary = this.getSummary( vacationReport, year );

            tableData.push({ key: i18n.t("pages.reports.options.vacation.summary.from_last_year", { year: this.props.year-1 }), values: [ summary.daysFromLastYear ] });
            tableData.push({ key: i18n.t("pages.reports.options.vacation.summary.from_contracts", { year: this.props.year }), values: [ summary.daysFromContract ] });
            tableData.push({ key: i18n.t("pages.reports.options.vacation.summary.adjusted", { year: this.props.year }), values: [ summary.daysAdjusted ] });
            tableData.push({ key: i18n.t("pages.reports.options.vacation.summary.total", { year: this.props.year }), values: [ summary.daysTotal ] });
            tableData.push({ key: i18n.t("pages.reports.options.vacation.summary.booked"), values: [ summary.daysBooked ] });
            tableData.push({ key: i18n.t("pages.reports.options.vacation.summary.available"), values: [ summary.daysAvailable ] });
        } else {
            tableHeader = [
                { id: 1, width: "50%", name: i18n.t("pages.reports.options.vacation.summary.headline_summary") },
                { id: 2, width: "50%", name: "" }
            ];
            tableData.push({ key: i18n.t("pages.reports.options.vacation.summary.warning"), values: [ "" ] });
        }

        let table = (
            <StyledDataTable 
                tableKey="vacation-table-summary" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // Render vacation table
    renderTable( vacationReport, year ) {

        // If no specific year is given, the table makes no sence
        if( year === "" ) {
            return "";
        }

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "long" );
        const seriesBookedDays = this.getSeriesBookedDays( vacationReport, year );
        const seriesAdjustedDays = this.getSeriesAdjustedDays( vacationReport, year );

        // Assemble table content
        const tableHeader = [
            { id: 1, width: "33%", name: i18n.t("pages.reports.options.vacation.table.month") },
            { id: 2, width: "33%", name: i18n.t("pages.reports.options.vacation.table.booked") },
            { id: 2, width: "33%", name: i18n.t("pages.reports.options.vacation.table.adjusted") }
        ];

        let tableData = [];

        for( let i = 0; i < monthNames.length; i++ ) {
            let tableRow = {
                key: monthNames[i],
                values: [
                    seriesBookedDays[i],
                    seriesAdjustedDays[i]
                ]
            }
            tableData.push(tableRow);
        }

        let table = (
            <StyledDataTable 
                tableKey="absence-table-detail" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // Takes props: language, vacationReport
    render() {
        let absenceSummary = "";
        let absenceTable = "";
        if( null != this.props.vacationReport && null != this.props.year ) {
            absenceSummary = this.renderSummary( this.props.vacationReport, this.props.year );
            absenceTable = this.renderTable( this.props.vacationReport, this.props.year );
        }

        return (
            <div>
                {absenceSummary}
                <br />
                {absenceTable}
            </div>
        );
    }
};

export default withStyles(useStyles)(TableVacation);