import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AuthContext from '../contexts/AuthContext';
import ReportService from '../classes/ReportService';
import ReportVacationUser from './ReportVacationUser';
import ReportAccountingUser from '../components/ReportAccountingUser';
import ReportAbsenceUser from '../components/ReportAbsenceUser';
import ReportWorkTimeUser from '../components/ReportWorkTimeUser';
import SpecialService from '../classes/SpecialService';
import AbsenceService from '../classes/AbsenceService';
import HolidayService from '../classes/HolidayService';
import AccountingService from '../classes/AccountingService';
import ContractService from '../classes/ContractService';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CalendarHelper from '../classes/CalendarHelper.js';
import SelectControls from '../components/SelectControls';
import { config } from '../config';
import ExportButtons from '../components/ExportButtons';
import { fromTimeStringToDate, toJSONLocalDate, toDateObject } from '../helpers.js';
import UserService from '../classes/UserService';
import WorkTimeService from '../classes/WorkTimeService';
import { CSVLink } from 'react-csv';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import Grid from '@material-ui/core/Grid';
import i18n from 'i18next';
import _ from 'lodash';

const useStyles = theme => ({
    mainGrid: {
        display: 'flex',
        height: '100%',
        width: '100%',
        margin: theme.spacing(0),
        justifyContent: 'space-between',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    headline: {
        minWidth: '100%',
        maxWidth: '100%'
    },
    options: {
        height: '40px'
    },
    select: {
        minWidth: '100%',
        maxWidth: '100%'
    },
    reportContent: {
        minHeight: '100%',
        minWidth: '50%',
        maxHeight: '100%',
        maxWidth: '50%'
    },
});

/**
 * Reports My
 */
class ReportsMy extends Component {
    static contextType = AuthContext;

    state = {}

    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleChangeSelectYear = this.handleChangeSelectYear.bind( this );
        this.handleChangeSelectMonth = this.handleChangeSelectMonth.bind( this );
        this.handleOptionChange = this.handleOptionChange.bind( this );
        this.handleCsvExportClick = this.handleCsvExportClick.bind( this );
        this.handlePdfExportClick = this.handlePdfExportClick.bind( this );
        this.handleModifyWorkTime = this.handleModifyWorkTime.bind( this );
        this.handleDeleteWorkTime = this.handleDeleteWorkTime.bind( this );

        // References to call into childs
        this.csvLinkRef = React.createRef();
        this.vacationChildRef = React.createRef();
        this.accountingChildRef = React.createRef();
        this.absenceChildRef = React.createRef();
        this.worktimeChildRef = React.createRef();

        // Set initial state
        this.state = this.getInitialState( "", "" );
    }

    /**
     * STATE HANDLING
     */

    // Get the initial state variables of this component
    getInitialState() {
        return {
            selectedYear: new Date().getFullYear(),
            selectedMonth: new Date().getMonth()+1,
            selectedOption: "vacation",
            data: { // Raw loaded data from the backend
                vacationReport: null,
                accountingReport: null,
                absenceReport: null,
                specials: null,
                users: null,
                worktimes: null,
                contracts: null,
                absences: null,
                holidays: null,
                accountings: null
            },
            export: {
                csv: {
                    filename: "",
                    data: []
                }
            }
        }
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState() {
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
        this.setState( this.getInitialState() );
    }

    /**
    * HANDLERS FOR MODIFY OF WORK TIME
    */
    async handleModifyWorkTime( userid, data ) {

        // Validity checks
        if( userid == null || userid === "" || data == null ) {
            return;
        }

        /**
         * At this point three things can happen:
         * * A new worktime entry is added if it does not yet exist (no matching work time entry found)
         * * A previously existing work time entry is removed (because one with the same start_time does not exist anymore)
         * * An already existing work time entry is updated (check is based on start_time)
         * So build a list of actions which need to be executed on the database
         */

        let actions = [];

        // Get all current worktime entries for this user
        const currentWorkTimes = this.getWorkTimesForUserAtDate( userid, data.dateJson );

        // First check if existing work time entries have been deleted or modified
        for( let i = 0; i < currentWorkTimes.length; i++ ) {
            let workTimeEntryStillExists = false;

            for( let j = 0; j < data.worktimes.today.length; j++ ) {
                if( currentWorkTimes[i].start_time === data.worktimes.today[j].start_time ) {
                    // Work time entry does still exist
                    workTimeEntryStillExists = true;

                    // Check if work time entry was modified
                    if( false === _.isEqual( currentWorkTimes[i], data.worktimes.today[j] ) ) {

                        // Entry was modified
                        let action = {
                            type: "modify",
                            dateJson: data.dateJson,
                            start_time: currentWorkTimes[i].start_time,
                            modifiedData: data.worktimes.today[j]
                        }
                        actions.push( action );
                    }

                    break;
                }
            }

            if( false === workTimeEntryStillExists ) {
                let action = {
                    type: "delete",
                    dateJson: data.dateJson,
                    start_time: currentWorkTimes[i].start_time
                }
                actions.push( action );
            }
        }
        
        // Now check if new entries need to be added
        for( let i = 0; i < data.worktimes.today.length; i++ ) {
            let workTimeEntryDoesNotExist = true;

            for( let j = 0; j < currentWorkTimes.length; j++ ) {

                if( currentWorkTimes[j].start_time === data.worktimes.today[i].start_time ) {
                    workTimeEntryDoesNotExist = false;
                    break;
                }
            }

            if( true === workTimeEntryDoesNotExist ) {
                let action = {
                    type: "add",
                    dateJson: data.dateJson,
                    newData: data.worktimes.today[i]
                }
                actions.push( action );
            }
        }

        // Now execute all the actions
        for( let i = 0; i < actions.length; i++ ) {

            if( actions[i].type === "add" ) {

                let workTimeInput = {
                    userid: userid,
                    date: actions[i].dateJson,
                    start_time: actions[i].newData.start_time,
                    end_time: actions[i].newData.end_time,
                    break: actions[i].newData.break,
                }

                const { statusCode, statusText } = await WorkTimeService.createWorkTime( workTimeInput );
                let statusCodeFinal = statusCode;
                let statusTextFinal = statusText;

                let alert = {
                    severity: "info",
                    message: ""
                }
        
                if( 200 === statusCodeFinal ) {
                    alert.severity = "success";
                    alert.message = i18n.t( "pages.main.alert.success.add_worktime" );
                } else {
                    alert.severity = "error";
                    alert.message = `${statusTextFinal}: ${statusCodeFinal}`;
                }
        
                this.handleAlertChanged( alert );

            } else if( actions[i].type === "delete" ) {

                let workTimeSearch = {
                    userid: userid,
                    date: actions[i].dateJson,
                    start_time: actions[i].start_time,
                }
        
                const { statusCode, statusText } = await WorkTimeService.deleteWorkTime( workTimeSearch );
                let statusCodeFinal = statusCode;
                let statusTextFinal = statusText;

                let alert = {
                    severity: "info",
                    message: ""
                }
        
                if( 200 === statusCodeFinal ) {
                    alert.severity = "success";
                    alert.message = i18n.t( "pages.main.alert.success.modified_worktime" );
                } else {
                    alert.severity = "error";
                    alert.message = `${statusTextFinal}: ${statusCodeFinal}`;
                }
        
                this.handleAlertChanged( alert );

            } else if( actions[i].type === "modify" ) {

                let workTimeSearch = {
                    userid: userid,
                    date: actions[i].dateJson,
                    start_time: actions[i].start_time
                }

                let workTimeUpdate = {
                    start_time: actions[i].modifiedData.start_time,
                    end_time: actions[i].modifiedData.end_time,
                    break: actions[i].modifiedData.break
                }

                const { statusCode, statusText } = await WorkTimeService.updateWorkTime( workTimeSearch, workTimeUpdate );
                let statusCodeFinal = statusCode;
                let statusTextFinal = statusText;

                let alert = {
                    severity: "info",
                    message: ""
                }
        
                if( 200 === statusCodeFinal ) {
                    alert.severity = "success";
                    alert.message = i18n.t( "pages.main.alert.success.modified_worktime" );
                } else {
                    alert.severity = "error";
                    alert.message = `${statusTextFinal}: ${statusCodeFinal}`;
                }
        
                this.handleAlertChanged( alert );
            }
        }

        // Refresh worktime data
        this.handleDataLoadedChanged( false );

        let workTimeData = await this.getWorkTimeData( this.state.selectedYear, this.state.selectedMonth );

        this.handleDataLoadedChanged( true );

        // Update the elements
        let stateData = this.state.data;
        stateData.worktimes = workTimeData;

        this.setState({ data: stateData });
    }

    async handleDeleteWorkTime( userid, dateJson ) {

        // Validity checks
        if( userid == null || userid === "" || dateJson == null || dateJson === "" ) {
            return;
        }

        // Check if there are any worktimes to delete
        if( false === this.hasUserWorkTimes( userid, dateJson ) ) {
            return;
        }

        let workTimeSearch = {
            userid: userid,
            date: dateJson
        }

        const { statusCode, statusText } = await WorkTimeService.deleteWorkTime( workTimeSearch );
        let statusCodeFinal = statusCode;
        let statusTextFinal = statusText;

        let alert = {
            severity: "info",
            message: ""
        }

        if( 200 === statusCodeFinal ) {
            alert.severity = "success";
            alert.message = i18n.t( "pages.main.alert.success.modified_worktime" );
        } else {
            alert.severity = "error";
            alert.message = `${statusTextFinal}: ${statusCodeFinal}`;
        }

        this.handleAlertChanged( alert );

        // Refresh worktime data
        this.handleDataLoadedChanged( false );

        let workTimeData = await this.getWorkTimeData( this.state.selectedYear, this.state.selectedMonth );

        this.handleDataLoadedChanged( true );

        // Update the elements
        let data = this.state.data;
        data.worktimes = workTimeData;

        this.setState({ data: data });
    }

    // Get all work times for a given user at a given date
    getWorkTimesForUserAtDate( userid, dateJson ) {
        let result = [];
        for( let i = 0; i < this.state.data.worktimes.length; i++ ) {
            if( this.state.data.worktimes[i].userid === userid && this.state.data.worktimes[i].date === dateJson ) {
                result.push( _.cloneDeep( this.state.data.worktimes[i] ) );
            }
        }

        // Sort just by start_time since we are only searching on a specific date
        result.sort(function(a, b) { return fromTimeStringToDate(a.start_time) > fromTimeStringToDate(b.start_time) ? 1 : -1; });
        return result;
    }

    // Check if the user has any worktimes on the given date
    hasUserWorkTimes( userid, dateJson ) {
        for( let i = 0; i < this.state.data.worktimes.length; i++ ) {
            if( this.state.data.worktimes[i].userid === userid ) {
                if( this.state.data.worktimes[i].date === dateJson ) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * LINK TO PARENT COMPONENT FUNCTIONS
     */

    // Push data loaded changed to parent
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Push alert to parent
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Propagate information to parent component
    handleActionInProgressChanged( actionInProgress ) {
        if( this.props.actionInProgressChanged ) {
            this.props.actionInProgressChanged( actionInProgress );
        }
    }
    
    /**
     * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
     */

    // Called by react when this component has been mounted
    async componentDidMount() {
        // Refresh all data from backend
        await this.refreshData();
    }

    // Called by react before this component unmounts
    componentWillUnmount() {
        this.resetState();
    }

    /**
     * HANDLERS 
     */

    // Called when the user selects a new year
    async handleChangeSelectYear( value ) {
        this.handleDataLoadedChanged( false );

        // Refresh worktime data
        let workTimeData = await this.getWorkTimeData( value, this.state.selectedMonth );
        let absenceData = await this.getAbsenceData( value, this.state.selectedMonth );
        let holidayData = await this.getHolidayData( value, this.state.selectedMonth );
        let accountingData = await this.getAccountingData( value, this.state.selectedMonth );

        this.handleDataLoadedChanged( true );

        // Update the elements
        let data = this.state.data;
        data.worktimes = workTimeData;
        data.absences = absenceData;
        data.accountings = accountingData;
        data.holidays = holidayData;

        this.setState({ selectedYear: value, data: data });
    }

    // Called when the user selects a new month
    async handleChangeSelectMonth( value ) {
        this.handleDataLoadedChanged( false );

        // Refresh worktime data
        let workTimeData = await this.getWorkTimeData( this.state.selectedYear, value );
        let absenceData = await this.getAbsenceData( this.state.selectedYear, value );
        let holidayData = await this.getHolidayData( this.state.selectedYear, value );
        let accountingData = await this.getAccountingData( this.state.selectedYear, value );

        this.handleDataLoadedChanged( true );

        // Update the elements
        let data = this.state.data;
        data.worktimes = workTimeData;
        data.absences = absenceData;
        data.accountings = accountingData;
        data.holidays = holidayData;

        this.setState({ selectedMonth: value, data: data });
    }

    // Called when the user selects a report option
    handleOptionChange( event, newAlignment ) {
        if( null !== newAlignment ) {
            this.setState({ selectedOption: newAlignment });
        }
    }

    /**
     * LOAD AND PROCESS BACKEND DATA
     */

    // Gather all data from the backend
    async refreshData() {

        // Gather vacation report data
        let vacationReportData = null;
        {
            const { statusCode, statusText, resData } = await ReportService.getVacationReport( { userid: this.props.userid } );
            if( 200 === statusCode && null != resData ) {
                vacationReportData = resData.getVacationReport;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather absence report data
        let absenceReportData = null;
        {
            const { statusCode, statusText, resData } = await ReportService.getAbsenceReport( { userid: this.props.userid } );
            if( 200 === statusCode && null != resData ) {
                absenceReportData = resData.getAbsenceReport;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather accounting report data
        let accountingReportData = null;
        {
            const { statusCode, statusText, resData } = await ReportService.getAccountingReport( { userid: this.props.userid } );
            if( 200 === statusCode && null != resData ) {
                accountingReportData = resData.getAccountingReport;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather special data
        let specialData = null;
        {
            const { statusCode, statusText, resData } = await SpecialService.getSpecials( {} );
            if( 200 === statusCode && null != resData ) {
                specialData = resData.getSpecials;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather user data
        let userData = null;
        {
            const { statusCode, statusText, resData } = await UserService.getUsers( { userid: this.props.userid } );
            if( 200 === statusCode && null != resData ) {
                userData = resData.getUsers;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather contract data
        let contractData = null;
        {
            const { statusCode, statusText, resData } = await ContractService.getContracts( { userid: this.props.userid } );
            if( 200 === statusCode && null != resData ) {
                contractData = resData.getContracts;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather worktime data
        let workTimeData = await this.getWorkTimeData( this.state.selectedYear, this.state.selectedMonth );

        // Gather absence data
        let absenceData = await this.getAbsenceData( this.state.selectedYear, this.state.selectedMonth );

        // Gather holiday data
        let holidayData = await this.getHolidayData( this.state.selectedYear, this.state.selectedMonth );

        // Gather accounting data
        let accountingData = await this.getAccountingData( this.state.selectedYear, this.state.selectedMonth );

        this.setState({
            data: {
                vacationReport: vacationReportData,
                accountingReport: accountingReportData,
                absenceReport: absenceReportData,
                specials: specialData,
                users: userData,
                worktimes: workTimeData,
                contracts: contractData,
                absences: absenceData,
                accountings: accountingData,
                holidays: holidayData
            }
        })

        this.handleDataLoadedChanged( true );
    }

    // Gather all data from the backend
    async getWorkTimeData( selectedYear, selectedMonth ) {
        let workTimeData = null;
        {
            const lastDateInMonth = new Date( selectedYear, selectedMonth, 0 );
            const lastDateInPreviousMonth = new Date( selectedYear, selectedMonth-1, 0 );

            // Get the worktimes from the last day of the previous month to properly calculate the nightly rest period of the first day of the month
            let minDate = toJSONLocalDate( lastDateInPreviousMonth );
            let maxDate = toJSONLocalDate( lastDateInMonth );

            const { statusCode, statusText, resData } = await WorkTimeService.getWorkTimesRange( { userid: this.props.userid, minDate: minDate, maxDate: maxDate } );
            if( 200 === statusCode && null != resData ) {
                workTimeData = resData.getWorkTimesRange;

                workTimeData.sort( 
                    function(a, b) {
                        if( a.date === b.date ) {
                            // We are on the same date, sort by time
                            return fromTimeStringToDate(a.start_time) > fromTimeStringToDate(b.start_time) ? 1 : -1;
                        } else {
                            // We are not on the same date, sort by date
                            return toDateObject(a.date) > toDateObject(b.date) ? 1 : -1;
                        }
                    }
                );

            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }
        return workTimeData;
    }

    // Gather all data from backend
    async getAbsenceData( selectedYear, selectedMonth ) {
        let absenceData = null;
        {
            const lastDateInMonth = new Date( selectedYear, selectedMonth, 0 );
            const lastDateInPreviousMonth = new Date( selectedYear, selectedMonth-1, 0 );

            // Get the worktimes from the last day of the previous month to properly calculate the nightly rest period of the first day of the month
            let minDate = toJSONLocalDate( lastDateInPreviousMonth );
            let maxDate = toJSONLocalDate( lastDateInMonth );

            const { statusCode, statusText, resData } = await AbsenceService.getAbsencesRange( { userid: this.props.userid, minDate: minDate, maxDate: maxDate } );
            if( 200 === statusCode && null != resData ) {
                absenceData = resData.getAbsencesRange;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }
        return absenceData;
    }

    // Gather all data from backend
    async getAccountingData( selectedYear, selectedMonth ) {
        let accountingData = null;
        {
            const lastDateInMonth = new Date( selectedYear, selectedMonth, 0 );
            const lastDateInPreviousMonth = new Date( selectedYear, selectedMonth-1, 0 );

            // Get the worktimes from the last day of the previous month to properly calculate the nightly rest period of the first day of the month
            let minDate = toJSONLocalDate( lastDateInPreviousMonth );
            let maxDate = toJSONLocalDate( lastDateInMonth );

            const { statusCode, statusText, resData } = await AccountingService.getAccountingsRange( { userid: this.props.userid, minDate: minDate, maxDate: maxDate } );
            if( 200 === statusCode && null != resData ) {
                accountingData = resData.getAccountingsRange;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }
        return accountingData;
    }

    // Gather all data from backend
    async getHolidayData( selectedYear, selectedMonth ) {
        let holidayData = null;
        {
            const lastDateInMonth = new Date( selectedYear, selectedMonth, 0 );
            const lastDateInPreviousMonth = new Date( selectedYear, selectedMonth-1, 0 );

            // Get the worktimes from the last day of the previous month to properly calculate the nightly rest period of the first day of the month
            let minDate = toJSONLocalDate( lastDateInPreviousMonth );
            let maxDate = toJSONLocalDate( lastDateInMonth );

            const { statusCode, statusText, resData } = await HolidayService.getHolidaysRange( { minDate: minDate, maxDate: maxDate } );
            if( 200 === statusCode && null != resData ) {
                holidayData = resData.getHolidaysRange;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }
        return holidayData;
    }

    /**
     * EXPORT FUNCTIONS
     */

    // Export to CSV
    handleCsvExportClick() {

        // Gather information about the selected user and pass it onto the export function
        let selectedUsers = [];
        for( let i = 0; i < this.state.data.users.length; i++ ) {
            if( this.state.data.users[i].userid === this.props.userid ) {
                selectedUsers.push( this.state.data.users[i] );
                break;
            }
        }

        let reports = [];

        // Make the call to the child component
        let finalFilename = "";
        let finalData = "";
        if( "vacation" === this.state.selectedOption ) {
            reports.push( this.state.data.vacationReport );
            const { csvFilename, csvData } = this.vacationChildRef.current.onCsvExport( selectedUsers, reports );
            finalFilename = csvFilename;
            finalData = csvData;
        } else if( "accounting" === this.state.selectedOption ) {
            reports.push( this.state.data.accountingReport );
            const { csvFilename, csvData } = this.accountingChildRef.current.onCsvExport( selectedUsers, reports );
            finalFilename = csvFilename;
            finalData = csvData;
        } else if( "absence" === this.state.selectedOption ) {
            reports.push( this.state.data.absenceReport );
            const { csvFilename, csvData } = this.absenceChildRef.current.onCsvExport( selectedUsers, reports );
            finalFilename = csvFilename;
            finalData = csvData;
        } else if( "worktime" === this.state.selectedOption ) {

            // There are two features in the accounting which lock certain cells
            // - Rolling auto accounting window (if configured)
            // - Specials which auto account
            // The only users who shall ever bypass these features are global admins and admin_accountings
            let accountingAutoLock = false;
            let accountingAutoLockPeriodOfDays = 0;
            let specialAutoAccountingLock = true;

            // Fill in settings from config for rolling auto account window
            if( null != this.context.config ) {
                if( null != this.context.config.accounting.auto_lock ) {
                    accountingAutoLock = this.context.config.accounting.auto_lock;
                }
                if( null != this.context.config.accounting.auto_lock_period_in_days ) {
                    accountingAutoLockPeriodOfDays = this.context.config.accounting.auto_lock_period_in_days;
                }
            }

            // Allow bypass for global admin and admin_accountings
            if( true === this.props.adminData.admin.global || true === this.props.adminData.admin.accountings ) {
                accountingAutoLock = false;
                specialAutoAccountingLock = false;
            }


            const { csvFilename, csvData } = this.worktimeChildRef.current.onCsvExport( this.state.selectedYear.toString(), this.state.selectedMonth.toString(), 
                selectedUsers, this.state.data.worktimes, this.state.data.contracts, this.state.data.absences, this.state.data.holidays, this.state.data.specials,
                this.state.data.accountings, accountingAutoLock, accountingAutoLockPeriodOfDays, specialAutoAccountingLock );

            finalFilename = csvFilename;
            finalData = csvData;
        }

        // Set the state of the data and automatically click the download link
        this.setState({ 
            export: { 
                csv: {
                    filename: finalFilename,
                    data: finalData
                }
            }},
            () => {
                setTimeout(() => {
                    this.csvLinkRef.current.link.click();
                })
            }
        );
    }

    // Export to PDF
    async handlePdfExportClick() {

        // Gather information about the selected user and pass it onto the export function
        let selectedUsers = [];
        for( let i = 0; i < this.state.data.users.length; i++ ) {
            if( this.state.data.users[i].userid === this.props.userid ) {
                selectedUsers.push( this.state.data.users[i] );
                break;
            }
        }

        let reports = [];

        // Make the call to the child component
        let finalFilename = "";
        let finalData = "";
        if( "vacation" === this.state.selectedOption ) {
            reports.push( this.state.data.vacationReport );
            const { pdfFilename, pdfData } = this.vacationChildRef.current.onPdfExport( selectedUsers, reports );
            finalFilename = pdfFilename;
            finalData = pdfData;
        } else if( "accounting" === this.state.selectedOption ) {
            reports.push( this.state.data.accountingReport );
            const { pdfFilename, pdfData } = this.accountingChildRef.current.onPdfExport( selectedUsers, reports );
            finalFilename = pdfFilename;
            finalData = pdfData;
        } else if( "absence" === this.state.selectedOption ) {
            reports.push( this.state.data.absenceReport );
            const { pdfFilename, pdfData } = this.absenceChildRef.current.onPdfExport( selectedUsers, reports );
            finalFilename = pdfFilename;
            finalData = pdfData;
        }

        // Generate PDF and prompt the user to download it
        const blob = await pdf(finalData).toBlob();
        saveAs( blob, finalFilename );
    }

    /**
	 * RENDER FUNCTIONS
	 */

    // Render select year menu
    renderSelectYear( vacationReport, selectedYear ) {

        // Gather list of selectable years
        let selectableYears = [];

        if( null != vacationReport ) {
            for( let i = 0; i < vacationReport.years.length; i++ ) {
                selectableYears.push( {
                    id: vacationReport.years[i].year,
                    value: vacationReport.years[i].year.toString()
                });
            }
        }

        return (
            <SelectControls
                selectedEntry={selectedYear}
                label={i18n.t("pages.reports.select_year.label")}
                helperText={i18n.t("pages.reports.select_year.subtext")}
                noneValueName={i18n.t("pages.reports.select_year.all_value")}
                items={selectableYears}
                onChange={this.handleChangeSelectYear}
                showButtons={false}
            />
        )
    }

    // Render select month menu
	renderSelectMonth( selectedMonth ) {

		// Gather list of selectable months
		let selectableMonths = [];
        const monthNames = CalendarHelper.getMonthNames( this.props.language, "long" );

        for( let i = 0; i < monthNames.length; i++ ) {
            selectableMonths.push( {
                id: (i+1),
                value: monthNames[i]
            })
        }

		return (
			<SelectControls
				selectedEntry={selectedMonth}
				label={i18n.t("pages.reports.select_month.label")}
				helperText={i18n.t("pages.reports.select_month.subtext")}
                showNoneValue={false}
				items={selectableMonths}
				onChange={this.handleChangeSelectMonth}
				showButtons={false}
			/>
		)
	}

    // Render option buttons
    renderOption( selectedOption ) {
        return (
            <ToggleButtonGroup 
                size="small" 
                value={selectedOption}
                exclusive
                className={this.props.classes.options}
                onChange={(event, alignment) => this.handleOptionChange(event, alignment)}
            >
                <ToggleButton value="vacation">{i18n.t("pages.reports.options.vacation.title")}</ToggleButton>
                <ToggleButton value="accounting">{i18n.t("pages.reports.options.accounting.title")}</ToggleButton>
                <ToggleButton value="absence">{i18n.t("pages.reports.options.absence.title")}</ToggleButton>
                <ToggleButton value="worktime">{i18n.t("pages.reports.options.worktime.title")}</ToggleButton>
            </ToggleButtonGroup>
        )
    }

    render() {
        /**
         * INPUTS
         * adminData, language, userid, dataLoadedChanged, alertChanged, actionInProgressChanged, currentUser
         */

        let selectYear = "";
        let selectMonth = "";
        let selectOption = "";
        let reportContent = "";

        if( null != this.state.data.vacationReport && null != this.state.data.accountingReport && null != this.state.data.absenceReport && null != this.state.data.specials ) {
            selectYear = this.renderSelectYear( this.state.data.vacationReport, this.state.selectedYear );
            selectOption = this.renderOption( this.state.selectedOption );

            if( "vacation" === this.state.selectedOption ) {
                reportContent = (
                    <ReportVacationUser 
                        ref={this.vacationChildRef}
                        language={this.props.language}
                        year={this.state.selectedYear.toString()}
                        vacationReport={this.state.data.vacationReport}
                    />
                )
            } else if( "accounting" === this.state.selectedOption ) {
                reportContent = (
                    <ReportAccountingUser 
                        ref={this.accountingChildRef}
                        language={this.props.language}
                        year={this.state.selectedYear.toString()}
                        accountingReport={this.state.data.accountingReport}
                    />
                )
            } else if( "absence" === this.state.selectedOption ) {
                reportContent = (
                    <ReportAbsenceUser 
                        ref={this.absenceChildRef}
                        language={this.props.language}
                        year={this.state.selectedYear.toString()}
                        absenceReport={this.state.data.absenceReport}
                        specials={this.state.data.specials}
                    />
                )
            } else if( "worktime" === this.state.selectedOption ) {
                selectMonth = this.renderSelectMonth( this.state.selectedMonth );

                // There are two features in the accounting which lock certain cells
                // - Rolling auto accounting window (if configured)
                // - Specials which auto account
                // The only users who shall ever bypass these features are global admins and admin_accountings
                let accountingAutoLock = false;
                let accountingAutoLockPeriodOfDays = 0;
                let specialAutoAccountingLock = true;

                // Fill in settings from config for rolling auto account window
                if( null != this.context.config ) {
                    if( null != this.context.config.accounting.auto_lock ) {
                        accountingAutoLock = this.context.config.accounting.auto_lock;
                    }
                    if( null != this.context.config.accounting.auto_lock_period_in_days ) {
                        accountingAutoLockPeriodOfDays = this.context.config.accounting.auto_lock_period_in_days;
                    }
                }

                let defaultWorkStartTime = config.workTimes.defaultWorkStartTime;
                for( let i = 0; i < this.state.data.users.length; i++ ) {
                    if( this.state.data.users[i].userid === this.props.userid && this.state.data.users[i].default_work_start_time != null ) {
                        defaultWorkStartTime = this.state.data.users[i].default_work_start_time;
                    }
                }

                reportContent = (
                    <ReportWorkTimeUser 
                        ref={this.worktimeChildRef}
                        language={this.props.language}
                        userid={this.props.userid}
                        year={this.state.selectedYear.toString()}
                        month={this.state.selectedMonth.toString()}
                        worktimes={this.state.data.worktimes}
                        contracts={this.state.data.contracts}
                        absences={this.state.data.absences}
                        holidays={this.state.data.holidays}
                        specials={this.state.data.specials}
                        accountings={this.state.data.accountings}
                        accountingAutoLock={accountingAutoLock}
                        accountingAutoLockPeriodOfDays={accountingAutoLockPeriodOfDays}
                        specialAutoAccountingLock={specialAutoAccountingLock}
                        defaultWorkStartTime={defaultWorkStartTime}
                        onModifyWorkTime={(userid, data) => this.handleModifyWorkTime( userid, data )}
                        onDeleteWorkTime={(userid, dateJson) => this.handleDeleteWorkTime( userid, dateJson )}
                    />
                )
            }
        }

        // Export CSV button, tooltip changes depending on selected option
        let buttonTooltip = null;
        if( "vacation" === this.state.selectedOption ) {
            buttonTooltip = i18n.t("pages.reports.my_reports.buttons.csv_all_years");
        } else if( "accounting" === this.state.selectedOption ) {
            buttonTooltip = i18n.t("pages.reports.my_reports.buttons.csv_all_years");
        } else if( "absence" === this.state.selectedOption ) {
            buttonTooltip = i18n.t("pages.reports.my_reports.buttons.csv_all_years");
        } else if( "worktime" === this.state.selectedOption ) {
            buttonTooltip = i18n.t("pages.reports.my_reports.buttons.csv_selected_month");
        }
        
        let exportCsvButtons = [];
        exportCsvButtons.push({
            showIcon: true,
            showText: false,
            tooltip: buttonTooltip
        })

        return (
            <div>
                <Grid container className={this.props.classes.mainGrid} justify="flex-start" alignItems="flex-start" spacing={1}>
                    <Grid item container xs={12} className={this.props.classes.select} spacing={1} wrap='nowrap'>
                        <Grid item container xs={11} spacing={1}>
                            <Grid item>
                                {selectYear}
                            </Grid>
                            <Grid item>
                                {selectOption}
                            </Grid>
                            <Grid item>
                                {selectMonth}
                            </Grid>
                        </Grid>
                        <Grid item container xs={1} justify="flex-end">
                            <CSVLink
                                ref={this.csvLinkRef}
                                data={this.state.export.csv.data}
                                filename={this.state.export.csv.filename}
                                className="hidden"
                                target="_blank"
                            />
                            <ExportButtons 
                                csvButtons={exportCsvButtons}
                                onCsvClick={(customData) => this.handleCsvExportClick()}
                            />
                        </Grid>
                    </Grid>
                    {reportContent}
                </Grid>
            </div>
        );
    }
};

export default withStyles(useStyles)(ReportsMy);