import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CalendarHelper from '../classes/CalendarHelper.js';
import Chart from "react-apexcharts";
import i18n from 'i18next';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const useStyles = theme => ({});

/**
 * Chart Absence
 */
class ChartAbsence extends Component {
    state = {
        chart: ""
    }

    /// Get x-axis labels
    getLabels( year, month ) {
        let labels = [];

        // Get amount of days in month
        const maxDayInMonth = CalendarHelper.getDaysInMonth( year, month-1 );

        for( let day = 1; day <= maxDayInMonth; day++ ) {
            labels.push( day );
        }

        return labels;
    }

    /// Get series: for active contracts
    getSeriesActiveContracts( year, month, contracts ) {
        let series = {
            name: i18n.t("pages.reports.options.absence.chart.series.employees.name"),
            type: 'line',
            data: [],
        }

        if( null != contracts ) {
            // Determine amount of employees in given month and year with an active contract on the current date
            const maxDayInMonth = CalendarHelper.getDaysInMonth( year, month-1 );

            for( let day = 1; day <= maxDayInMonth; day++ ) {
                let activeContracts = 0;

                const searchDate = moment(new Date( year, month-1, day ));

                for( let i = 0; i < contracts.length; i++ ) {

                    const startDate = moment(contracts[i].date_start, 'YYYY-MM-DD');
                    let endDate = moment( "2999-12-31", 'YYYY-MM-DD' );
                    if( 'date_end' in contracts[i] && null !== contracts[i].date_end ) {
                        endDate = moment(contracts[i].date_end, 'YYYY-MM-DD');
                    }
                    const contractDateRange = moment.range( startDate, endDate );

                    if( true === contractDateRange.contains( searchDate ) ) {
                        activeContracts += 1;
                    }
                }

                series.data.push( activeContracts );
            }
        }

        return series;
    }

    /// Get series: one series per available absence type
    getSeriesAbsences( year, month, absences, specials ) {
        let chartSeries = [];

        // Add an empty series per special
        for( let i = 0; i < specials.length; i++ ) {
            let series = {
                name: specials[i].name,
                id: specials[i].specialid,
                type: 'column',
                color: "#"+specials[i].color_code,
                data: []
            }
            chartSeries.push( series );
        }

        // Go through all days of the month
        const maxDayInMonth = CalendarHelper.getDaysInMonth( year, month-1 );

        let yearString = year;
        let monthString = month;
        if( month < 10 ) {
            monthString = `0${month}`
        }

        for( let day = 1; day <= maxDayInMonth; day++ ) {
            let dayString = day;
            if( day < 10 ) {
                dayString = `0${day}`
            }
            let dateString = `${yearString}-${monthString}-${dayString}`

            // Go through each special
            for( let i = 0; i < specials.length; i++ ) {
                let daysAbsent = 0;

                // Now check each absence
                for( let j = 0; j < absences.length; j++ ) {
                    if( absences[j].date === dateString && specials[i].specialid === absences[j].specialid ) {
                        daysAbsent += 1;
                    }
                }

                // Add to series
                for( let l = 0; l < chartSeries.length; l++ ) {
                    if( chartSeries[l].id === specials[i].specialid ) {
                        chartSeries[l].data.push( daysAbsent );
                    }
                }
            }

        }

        return chartSeries;
    }

    // Called by react when this component has been mounted
    componentDidMount() {
        // Pre-render chart since it will not change
        if( null != this.props.absences && null != this.props.specials && null != this.props.year && null != this.props.month && null != this.props.contracts ) {
            let absenceChart = this.renderChart( this.props.absences, this.props.specials, this.props.year, this.props.month, this.props.contracts );
            this.setState({ chart: absenceChart });
        }
    }

    // Called by react when this component did update
    componentDidUpdate( prevProps ) {
        if( this.props.year !== prevProps.year || this.props.month !== prevProps.month || this.props.userid !== prevProps.userid ) {
            // Pre-render chart since it will not change
            if( null != this.props.absences && null != this.props.specials && null != this.props.year && null != this.props.month && null != this.props.contracts ) {
                let absenceChart = this.renderChart( this.props.absences, this.props.specials, this.props.year, this.props.month, this.props.contracts );
                this.setState({ chart: absenceChart });
            }
        }
    }

    // Render chart
    renderChart( absences, specials, year, month, contracts ) {

        const chartLabels = this.getLabels( year, month );

        // Get a series per absence type
        let seriesActiveContracts = this.getSeriesActiveContracts( year, month, contracts );
        let seriesAbsences = this.getSeriesAbsences( year, month, absences, specials );

        let chartSeries = seriesAbsences;
        chartSeries.push( seriesActiveContracts );

        const chartOptions = {
            chart: {
                animations: {
                    enabled: false
                },
                type: 'line',
                id: 'absence-chart',
                toolbar: {
                    tools: {
                        download: false,
                        pan: false
                    }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                }
            },
            annotations: {
                yaxis: [],
                xaxis: [],
                points: []
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: 'true',
                width: 2,
            },
            grid: {},
            title: {},
            labels: chartLabels,
            xaxis: {},
            yaxis: {
                min: 0,
                axisBorder: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0
                },
                title: {
                    text: i18n.t("pages.reports.options.absence.chart.yaxis.label_employees"),
                    rotate: -90,
                    style: {
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: '12px',
                        fontWeight: 100
                    }
                },
                labels: {
                    formatter: (value) => { return Math.round(value) },
                }
            },
            tooltip: {
                shared: true
            }
        }

        return (
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="line"
            />
        )
    }

    render() {
        return (
            <div>
                {this.state.chart}
            </div>
        );
    }
};

export default withStyles(useStyles)(ChartAbsence);