/**
 * Messages of the day
 */
 export const messageoftheday = [
    {
        id: "20240715-1325",
        date: "2024-Jul-15",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "1.09"
    },
    {
        id: "20240517-1325",
        date: "2024-May-17",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "1.08"
    },
    {
        id: "20231121-2300",
        date: "2023-Nov-21",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "1.07"
    },
    {
        id: "20231108-2200",
        date: "2023-Nov-08",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "1.06"
    },
    {
        id: "20230701-1200",
        date: "2023-Jul-01",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "1.05"
    },
    {
        id: "20230330-1200",
        date: "2023-Mar-30",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "1.04"
    },
    {
        id: "20230304-1200",
        date: "2023-Mar-04",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "1.03"
    },
    {
        id: "20221128-1200",
        date: "2022-Nov-28",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "1.02"
    },
    {
        id: "20221105-1201",
        date: "2022-Nov-05",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "1.01"
    },
    {
        id: "20221105-1200",
        date: "2022-Nov-05",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "1.0"
    },
    {
        id: "20221102-1200",
        date: "2022-Nov-02",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "0.99"
    },
    {
        id: "20220801-1200",
        date: "2022-Aug-08",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "0.98"
    },
    {
        id: "20220517-1200",
        date: "2021-May-17",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "0.97"
    },
    {
        id: "20211116-1200",
        date: "2021-Nov-15",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "0.96"
    },
    {
        id: "20211109-1316",
        date: "2021-Nov-09",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "0.95"
    },
    {
        id: "20211027-1326",
        date: "2021-Oct-27",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "0.94"
    },
    {
        id: "20211014-1813",
        date: "2021-Oct-14",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "0.92"
    },
    {
        id: "20210915-2028",
        date: "2021-Sep-15",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "0.9"
    },
    {
        id: "20210801-1600",
        date: "2021-Aug-01",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "0.8"
    },
    {
        id: "20210703-1801",
        date: "2021-Jul-03",
        msg: "Welcome."
    },
    {
        id: "20210626-1800",
        date: "2021-Jun-26",
        msg: "A new version has been installed. Please check the changelog below for further information.",
        showChangeLogOfVersion: "0.7"
    }
]
