import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CalendarHelper from '../classes/CalendarHelper.js';
import Chart from "react-apexcharts";
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Chart Vacation
 */
class ChartVacation extends Component {
    state = {
        chart: ""
    }

    /// Get x-axis labels
    getLabels( vacationReport ) {
        let labels = [];

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "short" );

        if( null != vacationReport ) {
            for( let i = 0; i < vacationReport.years.length; i++ ) {

                // Only return years based on selection
                if( this.props.year === "" || this.props.year === vacationReport.years[i].year.toString() ) {
                    const yearString = " '" + vacationReport.years[i].year.toString().substring( 2, 4 )
                    for( let j = 0; j < vacationReport.years[i].months.length; j++ ) {

                        // Add label for start of the month
                        labels.push( monthNames[vacationReport.years[i].months[j].month-1] + yearString );
                    }
                }
            }
        }

        return labels;
    }

    /// Get series: available vacation days
    getSeriesAvailableDays( vacationReport ) {
        let series = {
            name: i18n.t("pages.reports.options.vacation.chart.series.available.name"),
            type: 'line',
            data: [],
        }

        if( null != vacationReport ) {
            let daysAvailable = 0;

            for( let i = 0; i < vacationReport.years.length; i++ ) {
                daysAvailable += vacationReport.years[i].days_available_in_year;
                
                // Only return years based on selection
                if( this.props.year === "" || this.props.year === vacationReport.years[i].year.toString() ) {
                    series.data.push( daysAvailable ); // At the year tick (1st Jan)
                }

                // Only go till november, the final tick for available days on the year label will come with the next set
                for( let j = 0; j < vacationReport.years[i].months.length; j++ ) {

                    // Retracting days booked in january leads to the february tick label
                    daysAvailable -= vacationReport.years[i].months[j].days_booked_in_month;
                    daysAvailable += vacationReport.years[i].months[j].days_adjusted_in_month;

                    // Add new days available at the mid of the month
                    // Only return years based on selection
                    if( this.props.year === "" || this.props.year === vacationReport.years[i].year.toString() ) {
                        if( j !== vacationReport.years[i].months.length-1 ) {
                            series.data.push( daysAvailable );
                        }
                    }
                }
            }
        }

        return series;
    }

    /// Get series: booked vacation days
    getSeriesBookedDays( vacationReport ) {
        let series = {
            name: i18n.t("pages.reports.options.vacation.chart.series.booked.name"),
            type: 'column',
            data: []
        }

        if( null != vacationReport ) {
            for( let i = 0; i < vacationReport.years.length; i++ ) {
                // Only return years based on selection
                if( this.props.year === "" || this.props.year === vacationReport.years[i].year.toString() ) {
                    for( let j = 0; j < vacationReport.years[i].months.length; j++ ) {

                        // Add booked in month at the mid of the month
                        if( 0 !== vacationReport.years[i].months[j].days_booked_in_month ) {
                            series.data.push( vacationReport.years[i].months[j].days_booked_in_month );
                        } else {
                            series.data.push( 0 );
                        }
                    }
                }
            }
        }

        return series;
    }

    /// Get series: adjusted vacation days
    getSeriesAdjustedDays( vacationReport ) {
        let series = {
            name: i18n.t("pages.reports.options.vacation.chart.series.adjusted.name"),
            type: 'column',
            data: []
        }

        if( null != vacationReport ) {
            for( let i = 0; i < vacationReport.years.length; i++ ) {
                // Only return years based on selection
                if( this.props.year === "" || this.props.year === vacationReport.years[i].year.toString() ) {
                    for( let j = 0; j < vacationReport.years[i].months.length; j++ ) {

                        // Add booked in month at the mid of the month
                        if( 0 !== vacationReport.years[i].months[j].days_adjusted_in_month ) {
                            series.data.push( vacationReport.years[i].months[j].days_adjusted_in_month );
                        } else {
                            series.data.push( 0 );
                        }
                    }
                }
            }
        }

        return series;
    }

    /// Generate the year start annotations
    getYearAnnotations( vacationReport ) {
        let annotations = [];

        // If we selected a specific year, do not add annotations
        if( this.props.year !== "" ) {
            return annotations;
        }

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "short" );

        if( null != vacationReport ) {
            for( let i = 0; i < vacationReport.years.length; i++ ) {
                const year = vacationReport.years[i].year;
                const yearCut = year.toString().substring(2, 4);
                const januaryLabel = monthNames[0] + " '" + yearCut;

                annotations.push(
                    {
                        x: januaryLabel,
                        borderColor: '#FF4560',
                        label: {
                            style: {
                                color: '#000',
                            },
                            orientation: 'vertical',
                            text: year.toString()
                        }
                    }
                )
            }
        }

        return annotations;
    }

    // Called by react when this component has been mounted
    componentDidMount() {

        // Pre-render chart since it will not change
        if( null != this.props.vacationReport && null != this.props.year ) {
            let absenceChart = this.renderChart( this.props.vacationReport );
            this.setState({ chart: absenceChart });
        }
    }

    // Called by react when this component did update
    componentDidUpdate( prevProps ) {

        if( this.props.year !== prevProps.year || this.props.userid !== prevProps.userid ) {
            // Pre-render chart since it will not change
            if( null != this.props.vacationReport && null != this.props.year ) {
                let absenceChart = this.renderChart( this.props.vacationReport );
                this.setState({ chart: absenceChart });
            }
        }
    }

    // Render
    // Takes props: language, vacationReport
    renderChart( vacationReport ) {

        const chartLabels = this.getLabels( vacationReport );
        const seriesAvailableDays = this.getSeriesAvailableDays( vacationReport );
        const seriesBookedDays = this.getSeriesBookedDays( vacationReport );
        const seriesAdjustedDays = this.getSeriesAdjustedDays( vacationReport );
        const yearAnnotations = this.getYearAnnotations( vacationReport );

        let chartSeries = [];
        chartSeries.push( seriesAvailableDays );
        chartSeries.push( seriesBookedDays );
        chartSeries.push( seriesAdjustedDays );

        const chartOptions = {
            chart: {
                animations: {
                    enabled: false
                },
                type: 'line',
                id: 'vacation-chart',
                toolbar: {
                    tools: {
                        download: false,
                        pan: false
                    }
                }
            },
            annotations: {
                yaxis: [],
                xaxis: yearAnnotations,
                points: []
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: 'true',
                width: 2,
            },
            grid: {},
            title: {},
            labels: chartLabels,
            xaxis: {},
            yaxis: {
                axisBorder: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0
                },
                title: {
                    text: i18n.t("pages.reports.options.vacation.chart.yaxis.label"),
                    rotate: -90,
                    style: {
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: '12px',
                        fontWeight: 100
                    }
                }
            },
            tooltip: {
                shared: true
            }
        }

        return (
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="line"
            />
        )
    }

    render() {
        return (
            <div>
                {this.state.chart}
            </div>
        );
    }
};

export default withStyles(useStyles)(ChartVacation);