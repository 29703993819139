import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ChartAccounting from '../components/ChartAccounting';
import TableAccounting from '../components/TableAccounting';
import Grid from '@material-ui/core/Grid';
import CalendarHelper from '../classes/CalendarHelper.js';
import { toJSONLocalDate } from '../helpers.js';
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Report Accounting User
 */
class ReportAccountingUser extends Component {
    state = {}

    constructor(props) {
        super(props);

        // References to call into child
        this.tableChildRef = React.createRef();
    }

    /**
     * EXPORTS
     */
    
    // Export to CSV
    onCsvExport( users, reports ) {

        // ### If users array contains only one single user:
        // Header: 
        // Report Accountings,<lastname>,<firstname>,<email address>,<date>,<time>
        // Year,Hours transfered from previous year,Total adjusted hours in year,Overtime accumulated in previous months in year,Overtime total,[per Month: <M> Target Hours,<M> Accounted Hours,<M> Overtime Hours,<M> Adjusted Hours]
        // Then one line per year containing the given information

        // ### If users array contains multiple users:
        // Report Accountings,<date>,<time>
        // Lastname,Firstname,Email Address,Year,Hours transfered from previous year,Total adjusted hours in year,Overtime accumulated in previous months in year,Overtime total,[per Month: <M> Target Hours,<M> Accounted Hours,<M> Overtime Hours,<M> Adjusted Hours]
        // Then one line per user and year containing the given information

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "short" );
        const today = new Date();
        const dateString = toJSONLocalDate( today );
        const timeString = today.getHours().toString() + ":" + today.getMinutes().toString() + ":" + today.getSeconds().toString();

        // Determine the file name, differs if only one user is given or if multiple users are given
        let csvFilename = "";

        if( users.length === 1 ) {
            csvFilename = i18n.t("pages.reports.options.accounting.export.filename", {
                firstname: users[0].firstname,
                lastname: users[0].lastname
            });
        } else if( users.length > 1 ) {
            csvFilename = i18n.t("pages.reports.options.accounting.export.filename_all");
        }



        let csvData = [];
        if( users.length === 1 ) {
            csvData.push( [ i18n.t("pages.reports.options.accounting.export.headline"), users[0].lastname, users[0].firstname, users[0].email, dateString, timeString ] );
        } else if( users.length > 1 ) {
            csvData.push( [ i18n.t("pages.reports.options.accounting.export.headline"), dateString, timeString ] );
        }

        let headerSecond = [];
        if( users.length > 1 ) {
            headerSecond.push( i18n.t("pages.reports.options.accounting.export.last_name") );
            headerSecond.push( i18n.t("pages.reports.options.accounting.export.first_name") );
            headerSecond.push( i18n.t("pages.reports.options.accounting.export.email") );
        }
        headerSecond.push( i18n.t("pages.reports.options.accounting.export.year") );
        headerSecond.push( i18n.t("pages.reports.options.accounting.export.from_last_year") );
        headerSecond.push( i18n.t("pages.reports.options.accounting.export.hours_adjusted") );
        headerSecond.push( i18n.t("pages.reports.options.accounting.export.overtime_this_year") );
        headerSecond.push( i18n.t("pages.reports.options.accounting.export.total") );
        for( let i = 0; i < monthNames.length; i++ ) {
            headerSecond.push( i18n.t("pages.reports.options.accounting.export.month_target_hours", { month: monthNames[i] }) );
            headerSecond.push( i18n.t("pages.reports.options.accounting.export.month_accounted_hours", { month: monthNames[i] }) );
            headerSecond.push( i18n.t("pages.reports.options.accounting.export.month_adjusted_hours", { month: monthNames[i] }) );
            headerSecond.push( i18n.t("pages.reports.options.accounting.export.month_overtime_hours", { month: monthNames[i] }) );
        }
        csvData.push( headerSecond );


        // Get summary from child table for each user and year
        for( let i = 0; i < users.length; i++ ) {

            // Retrieve the proper vacation report for this user
            let userReport = null;
            for( let j = 0; j < reports.length; j++ ) {
                if( reports[j].userid === users[i].userid ) {
                    userReport = reports[j];
                    break;
                }
            }

            if( null != userReport ) {

                // Grab all years from the user report
                let years = [];
                for( let j = 0; j < userReport.years.length; j++ ) {
                    years.push( userReport.years[j].year )
                }

                // Get summary from child table for each year
                for( let j = 0; j < years.length; j++ ) {
                    const year = years[j];
                    const summary = this.tableChildRef.current.getSummary( userReport, year.toString() );
                    const seriesTargetHours = this.tableChildRef.current.getSeriesTargetHours( userReport, year.toString() );
                    const seriesAccountedHours = this.tableChildRef.current.getSeriesAccountedHours( userReport, year.toString() );
                    const seriesOvertimeHours = this.tableChildRef.current.getSeriesOvertimeHours( userReport, year.toString() );
                    const seriesAdjustedHours = this.tableChildRef.current.getSeriesAdjustedHours( userReport, year.toString() );

                    // Convert summary into csv
                    let entry = [];

                    if( users.length > 1 ) {
                        entry.push( users[i].lastname );
                        entry.push( users[i].firstname );
                        entry.push( users[i].email );
                    }

                    entry.push( year );
                    entry.push( summary.overtimeFromLastYear );
                    entry.push( summary.hoursAdjusted );
                    entry.push( summary.overtimeThisYear );
                    entry.push( summary.overtimeFromLastYear + summary.overtimeThisYear );

                    for( let k = 0; k < monthNames.length; k++ ) {
                        let value = "";
                        if( seriesTargetHours.length > k ) { value = seriesTargetHours[k]; }
                        entry.push( value );
                        value = "";
                        if( seriesAccountedHours.length > k ) { value = seriesAccountedHours[k]; }
                        entry.push( value );
                        value = "";
                        if( seriesAdjustedHours.length > k ) { value = seriesAdjustedHours[k]; }
                        entry.push( value );
                        value = "";
                        if( seriesOvertimeHours.length > k ) { value = seriesOvertimeHours[k]; }
                        entry.push( value );
                    }

                    csvData.push( entry );
                }
            }
        }
        
        return { csvFilename, csvData };
    }

    /**
	 * RENDER FUNCTIONS
	 */

    render() {
        // INPUTS: language, year, accountingReport, userid
        let accountingChart = "";
        let accountingTable = "";

        if( null != this.props.accountingReport ) {
            accountingChart = (
                <ChartAccounting 
                    language={this.props.language}
                    year={this.props.year}
                    accountingReport={this.props.accountingReport}
                    userid={this.props.userid}
                />
            )
            accountingTable = (
                <TableAccounting
                    ref={this.tableChildRef}
                    language={this.props.language}
                    year={this.props.year}
                    accountingReport={this.props.accountingReport}
                />
            )
        }

        return (
            <Grid item container justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={6}>
                    {accountingChart}
                </Grid>
                <Grid item xs={6}>
                    {accountingTable}
                </Grid>
            </Grid>
        );
    }
};

export default withStyles(useStyles)(ReportAccountingUser);