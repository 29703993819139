import { getHolidays } from 'feiertagejs';
import i18n from 'i18next';

/**
 * Holiday generator
 */
class HolidayGenerator {

    // Generate holidays
    generateHolidays( country, region, year ) {

        // Check data validity
        if( null == country || null == region || null == year ) {
            return [];
        }

        let result = [];

        if( "de" === country ) {
            result = getHolidays( year, region );
        }

        return result;
    }

    // Get array of supported countries
    getSupportedCountries() {
        return [
            { id: "de", name: i18n.t("holidays.de.name") } 
        ];
    }

    // Get array of supported regions based on country { id, name }
    getSupportedRegions( country ) {
        let regions = [];

        if( null != country ) {
            if( "de" === country ) {
                regions = [
                    { id: "BW", name: i18n.t("holidays.de.regions.bw") }, 
                    { id: "BY", name: i18n.t("holidays.de.regions.by") },
                    { id: "BE", name: i18n.t("holidays.de.regions.be") },
                    { id: "BB", name: i18n.t("holidays.de.regions.bb") }, 
                    { id: "HB", name: i18n.t("holidays.de.regions.hb") }, 
                    { id: "HE", name: i18n.t("holidays.de.regions.he") }, 
                    { id: "HH", name: i18n.t("holidays.de.regions.hh") }, 
                    { id: "MV", name: i18n.t("holidays.de.regions.mv") }, 
                    { id: "NI", name: i18n.t("holidays.de.regions.ni") }, 
                    { id: "NW", name: i18n.t("holidays.de.regions.nw") }, 
                    { id: "RP", name: i18n.t("holidays.de.regions.rp") }, 
                    { id: "SL", name: i18n.t("holidays.de.regions.sl") }, 
                    { id: "SN", name: i18n.t("holidays.de.regions.sn") }, 
                    { id: "ST", name: i18n.t("holidays.de.regions.st") }, 
                    { id: "SH", name: i18n.t("holidays.de.regions.sh") }, 
                    { id: "TH", name: i18n.t("holidays.de.regions.th") }, 
                    { id: "BUND", name: i18n.t("holidays.de.regions.bund") }, 
                    { id: "AUGSBURG", name: i18n.t("holidays.de.regions.augsburg") }, 
                    { id: "ALL", name: i18n.t("holidays.de.regions.all") }
                ];
            }
        }

        regions.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
        return regions;
    }
}

export default new HolidayGenerator();