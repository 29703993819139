import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableWorkTime from '../components/TableWorkTime';
import Grid from '@material-ui/core/Grid';
import CalendarHelper from '../classes/CalendarHelper.js';
import { toJSONLocalDate, toDateObject } from '../helpers.js';
import { config } from '../config';
import i18n from 'i18next';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const useStyles = theme => ({});

/**
 * Report WorkTime User
 */
class ReportWorkTimeUser extends Component {
    state = {}

    constructor(props) {
        super(props);

        // References to call into child
        this.tableChildRef = React.createRef();
        this.handleModifyWorkTime = this.handleModifyWorkTime.bind( this );
        this.handleDeleteWorkTime = this.handleDeleteWorkTime.bind( this );
    }

    /**
     * HANDLERS FOR MODIFY OF WORK TIME
     */
    handleModifyWorkTime( userid, data ) {
        // Push modification call
        if( null != this.props.onModifyWorkTime ) {
            this.props.onModifyWorkTime( userid, data );
        }
    }

    handleDeleteWorkTime( userid, dateJson ) {
        // Push deleted worktimes
        if( null != this.props.onDeleteWorkTime ) {
            this.props.onDeleteWorkTime( userid, dateJson );
        }
    }

    /**
     * EXPORTS
     */

    // Export to CSV
    onCsvExport( year, month, users, worktimes, contracts, absences, holidays, specials, accountings, accountingAutoLock, accountingAutoLockPeriodOfDays, specialAutoAccountingLock ) {

        // ### If users array contains only one single user:
        // Header: 
        // Report Worktimes,<lastname>,<firstname>,<email address>,<date>,<time>
        // Date,Is Weekend,Is Workday,Is Holiday,Is Absent,Start Time,End Time,Break,Sum of Time at Work,Sum of Breaks,Time at Work no Breaks,Nightly Rest Period
        // Multiple entries might appear per date if multiple starttime-endtime pairs have been entered. The summary fields only appear in the first line of each date

        // ### If users array contains multiple users:
        // Report Worktimes,<date>,<time>
        // Lastname,Firstname,Email Address,Date,Is Weekend,Is Workday,Is Holiday,Is Absent,Start Time,End Time,Break,Sum of Time at Work,Sum of Breaks,Time at Work no Breaks,Nightly Rest Period
        // Then one line per user and year containing the given information
        const monthNames = CalendarHelper.getMonthNames( this.props.language, "short" );
        const today = new Date();
        const dateString = toJSONLocalDate( today );
        const timeString = today.getHours().toString() + ":" + today.getMinutes().toString() + ":" + today.getSeconds().toString();

        // Determine the file name, differs if only one user is given or if multiple users are given
        let csvFilename = "";

        if( users.length === 1 ) {
            csvFilename = i18n.t("pages.reports.options.worktime.export.csv.filename", {
                firstname: users[0].firstname,
                lastname: users[0].lastname,
                year: year,
                month: monthNames[month-1]
            });
        } else if( users.length > 1 ) {
            csvFilename = i18n.t("pages.reports.options.worktime.export.csv.filename_all", {
                year: year,
                month: monthNames[month-1]
            });
        }

        let csvData = [];
        if( users.length === 1 ) {
            csvData.push( [ i18n.t("pages.reports.options.worktime.export.headline"), users[0].lastname, users[0].firstname, users[0].email, dateString, timeString ] );
        } else if( users.length > 1 ) {
            csvData.push( [ i18n.t("pages.reports.options.worktime.export.headline"), dateString, timeString ] );
        }

        let headerSecond = [];
        if( users.length > 1 ) {
            headerSecond.push( i18n.t("pages.reports.options.worktime.export.last_name") );
            headerSecond.push( i18n.t("pages.reports.options.worktime.export.first_name") );
            headerSecond.push( i18n.t("pages.reports.options.worktime.export.email") );
        }
        headerSecond.push( i18n.t("pages.reports.options.worktime.export.date") );
        headerSecond.push( i18n.t("pages.reports.options.worktime.export.is_weekend") );
        headerSecond.push( i18n.t("pages.reports.options.worktime.export.is_workday") );
        headerSecond.push( i18n.t("pages.reports.options.worktime.export.is_holiday") );
        headerSecond.push( i18n.t("pages.reports.options.worktime.export.is_absent") );
        headerSecond.push( i18n.t("pages.reports.options.worktime.export.start_time") );
        headerSecond.push( i18n.t("pages.reports.options.worktime.export.end_time") );
        headerSecond.push( i18n.t("pages.reports.options.worktime.export.break") );
        headerSecond.push( i18n.t("pages.reports.options.worktime.export.sum_time_at_work") );
        headerSecond.push( i18n.t("pages.reports.options.worktime.export.sum_breaks") );
        headerSecond.push( i18n.t("pages.reports.options.worktime.export.time_at_work_no_breaks") );
        headerSecond.push( i18n.t("pages.reports.options.worktime.export.nightly_rest_period") );

        csvData.push( headerSecond );

        // We start at the first day of the given month and run till the last day
        // First day might change if we are in the month when config tells us that we started the worktime tracking
        let firstDayInMonth = new Date(year, month-1, 1);
        let lastDayInMonth = new Date(year, month, 0);

        const trackWorkTimeStartDate = toDateObject( config.workTimes.trackStartDate );
        const momentTrackWorkTimeStartDate = moment( trackWorkTimeStartDate );

        // Adjust the first day in the month if we started tracking later
        const monthRange = moment.range( firstDayInMonth, lastDayInMonth );
        if( true === monthRange.contains( momentTrackWorkTimeStartDate ) ) {
            firstDayInMonth = trackWorkTimeStartDate;
        }

        // Get summary from child table for each user and year
        for( let i = 0; i < users.length; i++ ) {

            // Per user grab the value series
            const userid = users[i].userid;
            let defaultWorkStartTime = config.workTimes.defaultWorkStartTime;
            if( users[i].default_work_start_time != null ) {
                defaultWorkStartTime = users[i].default_work_start_time;
            }

            let firstDayInMonthClone = new Date( firstDayInMonth );
            let lasDayInMonthClone = new Date( lastDayInMonth );

            const { seriesEditors, seriesDays, seriesStartTime, seriesEndTime, 
                seriesBreak, seriesSumOfTimeAtWork, seriesSumOfBreaks, seriesWorkTime, 
                seriesRestPeriod, seriesRowProps } = this.tableChildRef.current.getAllSeries( 
                    userid, firstDayInMonthClone, lasDayInMonthClone, worktimes, contracts, 
                    absences, holidays, specials, accountings, accountingAutoLock, 
                    accountingAutoLockPeriodOfDays, specialAutoAccountingLock, defaultWorkStartTime );

            /* Series row props per row
                dateJson: <date in json format>
                isToday: [true|false],
                isWeekEnd: [true|false],
                isWorkDay: [true|false],
                holiday: {
                    state: [true|false],
                    name: <name of holiday>
                },
                absent: {
                    state: [true|false],
                    specialid: <id of special>
                }
            */

            for( let j = 0; j < seriesDays.length; j++ ) {
                // Convert summary into csv
                let entry = [];

                if( users.length > 1 ) {
                    entry.push( users[i].lastname );
                    entry.push( users[i].firstname );
                    entry.push( users[i].email );
                }

                entry.push( seriesRowProps[j].dateJson );
                if( true === seriesRowProps[j].isWeekEnd ) {
                    entry.push( "true" );
                } else {
                    entry.push( "false" );
                }
                if( true === seriesRowProps[j].isWorkDay ) {
                    entry.push( "true" );
                } else {
                    entry.push( "false" );
                }
                if( true === seriesRowProps[j].holiday.state ) {
                    entry.push( "true" );
                } else {
                    entry.push( "false" );
                }

                if( false === seriesRowProps[j].absent.state ) {
                    entry.push( "false" );
                } else {
                    let specialName = "Unknown";
                    for( let k = 0; k < specials.length; k++ ) {
                        if( specials[k].specialid === seriesRowProps[j].absent.specialid ) {
                            specialName = specials[k].name;
                            break;
                        }
                    }
                    entry.push( specialName );
                }

                entry.push( seriesStartTime[j] );
                entry.push( seriesEndTime[j] );
                entry.push( seriesBreak[j] );
                entry.push( seriesSumOfTimeAtWork[j] );
                entry.push( seriesSumOfBreaks[j] );
                entry.push( seriesWorkTime[j] );
                entry.push( seriesRestPeriod[j] );

                csvData.push( entry );
            }
        }
        
        return { csvFilename, csvData };
    }

    /**
	 * RENDER FUNCTIONS
	 */

    render() {
        // INPUTS: language, userid, year, month, worktimes, contracts, absences, holidays, specialslanguage, userid, year, month, worktimes, contracts, absences, holidays, specials,
        // accountings, accountingAutoLock, accountingAutoLockPeriodOfDays, specialAutoAccountingLock, defaultWorkStartTime
        let workTimeTable = "";

        if( null != this.props.worktimes ) {
            workTimeTable = (
                <TableWorkTime
                    ref={this.tableChildRef}
                    language={this.props.language}
                    userid={this.props.userid}
                    year={this.props.year}
                    month={this.props.month}
                    worktimes={this.props.worktimes}
                    contracts={this.props.contracts}
                    absences={this.props.absences}
                    holidays={this.props.holidays}
                    specials={this.props.specials}
                    accountings={this.props.accountings}
                    accountingAutoLock={this.props.accountingAutoLock}
                    accountingAutoLockPeriodOfDays={this.props.accountingAutoLockPeriodOfDays}
                    specialAutoAccountingLock={this.props.specialAutoAccountingLock}
                    defaultWorkStartTime={this.props.defaultWorkStartTime}
                    onModifyWorkTime={(userid, data) => this.handleModifyWorkTime( userid, data )}
                    onDeleteWorkTime={(userid, dateJson) => this.handleDeleteWorkTime( userid, dateJson )}
                />
            )
        }

        return (
            <Grid item container justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12}>
                    {workTimeTable}
                </Grid>
            </Grid>
        );
    }
};

export default withStyles(useStyles)(ReportWorkTimeUser);