import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const StyledTable = withStyles((theme) => ({
}))(Table);

const StyledTableCellSmall = withStyles((theme) => ({
    root: {
        borderWidth: 0,
        paddingLeft: 3,
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 2
    },
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: "bold",
        borderStyle: "solid",
        paddingLeft: "5px"
    }
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
    root: {
        borderWidth: 0,
    },
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: "bold",
        borderStyle: "solid",
    }
}))(TableCell);

const StyledTableCellBold = withStyles((theme) => ({
    root: {
        borderWidth: 0,
    },
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: "bold",
        borderStyle: "solid",
    },
    body: {
        fontWeight: "bold"
    }
}))(TableCell);

const StyledTableCellRed = withStyles((theme) => ({
    root: {
        borderWidth: 0,
    },
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: "bold",
        borderStyle: "solid",
    },
    body: {
        color: "#ff0000"
    }
}))(TableCell);

const StyledTableCellRedBold = withStyles((theme) => ({
    root: {
        borderWidth: 0,
    },
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: "bold",
        borderStyle: "solid",
    },
    body: {
        color: "#ff0000",
        fontWeight: "bold"
    }
}))(TableCell);

const StyledTableRowHover = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.table.row.primary
        },
        "&:hover": {
            backgroundColor: theme.palette.table.row.hover
        },
        "&.Mui-selected": {
            backgroundColor: theme.palette.table.row.selected.primary,
            "& > .MuiTableCell-root": {
                color: theme.palette.table.row.selected.text.primary,
            }
        },
        "&.Mui-selected:hover": {
            backgroundColor: theme.palette.table.row.selected.hover,
            "& > .MuiTableCell-root": {
                color: theme.palette.table.row.selected.text.hover,
            }
        }
    },
}))(TableRow);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.table.row.primary
        },
    },
}))(TableRow);

const StyledTableRowNoAlternate = withStyles((theme) => ({
    root: {},
}))(TableRow);

const StyledTableHead = withStyles((theme) => ({
    root: {
    },
}))(TableHead);

const StyledTableSortLabel = withStyles((theme) => ({
    root: {
		color: theme.palette.common.white,
        "&:hover": {
            color: theme.palette.common.white,
        },
		"&:focus": {
            color: theme.palette.common.white,
        },
		'&$active': {
			color: theme.palette.common.white,
		}
    },
	active: {},
	icon: {
		color: 'inherit !important'
	}
}))(TableSortLabel);

// Render a simple styled data table with the given data
// Expects props: tableKey, tableHeader [{ id, width, name }], tableData [{ key, values[] }]
function StyledSortableDataTable( props ) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    // Assemble header if data is given
    let contentHeaderRow = "";
    if( null != props.tableHeader ) {
        contentHeaderRow = (
            <StyledTableHead key={props.tableKey+"-head"}>
                <StyledTableRow key={props.tableKey+"-row-head"}>
                    {props.tableHeader.map((col) => (
                        <StyledTableCell 
                            key={props.tableKey+"-"+col.id} 
                            width={col.width}
                            sortDirection={orderBy === col.id ? order : false}
                        >
                            <StyledTableSortLabel
                                active={orderBy === col.id}
                                hideSortIcon={false}
                                direction={orderBy === col.id ? order : 'asc'}
                                onClick={createSortHandler(col.id)}
                            >
                                {col.name}
                            </StyledTableSortLabel>
                        </StyledTableCell>
                    ))}
                </StyledTableRow>
            </StyledTableHead>
        )
    }

    // Generate table body if data is given
    let contentTableBody = "";
    if( null != props.tableData ) {
        contentTableBody = (
            <TableBody>
                {stableSort(props.tableData, getComparator(order, orderBy)).map((row, i) => (
                    <StyledTableRow key={props.tableKey+"-row-data-"+i}>
                        <StyledTableCell key={props.tableKey+"-row-data-"+i+"-name"} component="th" scope="row">{row.name}</StyledTableCell>
                        <StyledTableCell key={props.tableKey+"-row-data-"+i+"-value"}>{row.value}</StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        )
    }

    return (
        <TableContainer component={Paper}>
            <StyledTable>
                {contentHeaderRow}
                {contentTableBody}
            </StyledTable>
        </TableContainer>
    );
}

// Render a simple styled data table with the given data
// Expects props: tableKey, tableHeader [{ id, width, name }], tableData [{ key, values[], bold(optional, true or false), red(optional) }]
function StyledDataTable( props ) {

    // Assemble header if data is given
    let contentHeaderRow = "";
    if( null != props.tableHeader ) {
        contentHeaderRow = (
            <StyledTableHead>
                <StyledTableRow key={props.tableKey}>
                    {props.tableHeader.map((col, index) => (
                        <StyledTableCell key={props.tableKey+"-"+index} width={col.width}>{col.name}</StyledTableCell>
                    ))}
                </StyledTableRow>
            </StyledTableHead>
        )
    }

    // Generate table body if data is given
    let contentTableBody = "";
    if( null != props.tableData ) {
        contentTableBody = (
            <TableBody>
                {props.tableData.map((row, i) => {
                    if( row.bold ) {
                        if( row.red ) {
                            return (
                                <StyledTableRow key={props.tableKey+"-row-"+i}>
                                    <StyledTableCellRedBold component="th" scope="row">{row.key}</StyledTableCellRedBold>
                                    {row.values.map((col, j) => (
                                        <StyledTableCellRedBold key={props.tableKey+"-row-"+i+"-col-"+j}>{col}</StyledTableCellRedBold>
                                    ))}
                                </StyledTableRow>
                            )
                        } else {
                            return (
                                <StyledTableRow key={props.tableKey+"-row-"+i}>
                                    <StyledTableCellBold component="th" scope="row">{row.key}</StyledTableCellBold>
                                    {row.values.map((col, j) => (
                                        <StyledTableCellBold key={props.tableKey+"-row-"+i+"-col-"+j}>{col}</StyledTableCellBold>
                                    ))}
                                </StyledTableRow>
                            )
                        }
                    } else {
                        if( row.red ) {
                            return (
                                <StyledTableRow key={props.tableKey+"-row-"+i}>
                                    <StyledTableCellRed component="th" scope="row">{row.key}</StyledTableCellRed>
                                    {row.values.map((col, j) => (
                                        <StyledTableCellRed key={props.tableKey+"-row-"+i+"-col-"+j}>{col}</StyledTableCellRed>
                                    ))}
                                </StyledTableRow>
                            )
                        } else {
                            return (
                                <StyledTableRow key={props.tableKey+"-row-"+i}>
                                    <StyledTableCell component="th" scope="row">{row.key}</StyledTableCell>
                                    {row.values.map((col, j) => (
                                        <StyledTableCell key={props.tableKey+"-row-"+i+"-col-"+j}>{col}</StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            )
                        }
                    }
                })}
            </TableBody>
        )
    }

    return (
        <TableContainer component={Paper}>
            <StyledTable>
                {contentHeaderRow}
                {contentTableBody}
            </StyledTable>
        </TableContainer>
    );
}

export {
    StyledTable,
    StyledTableHead,
    StyledTableCell,
    StyledTableCellSmall,
    StyledTableCellBold,
    StyledTableRow,
    StyledTableRowHover,
    StyledTableRowNoAlternate,
	StyledSortableDataTable,
    StyledDataTable,
	StyledTableSortLabel,
    descendingComparator,
    getComparator,
    stableSort
}