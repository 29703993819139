import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Divider } from '@material-ui/core';
import { config } from '../../config.js';
import Toolbar from '@material-ui/core/Toolbar';
import AccountMenu from './AccountMenu';
import AboutMenu from './AboutMenu';
import MainMenu from './MainMenu';
import AdminMenu from './AdminMenu';

const useStyles = theme => ({
    root: {
        display: 'flex'
    },
    drawer: {
        width: config.mainDrawerWidth,
        flexShrink: 0,
        zIndex: theme.zIndex.appBar - 100,
        whiteSpace: 'nowrap'
    },
    drawerContainer: {
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    drawerOpen: {
        width: config.mainDrawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        background: theme.mainDrawer.background,
        zIndex: theme.zIndex.appBar - 10
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
        background: theme.mainDrawer.background,
        zIndex: theme.zIndex.appBar - 10
    },
    divider: {
        background: theme.mainDrawer.dividerColor
    },
    menu: {
        color: theme.mainDrawer.subMenuText
    }
});

/**
 * Main navigation drawer
 */
class MainDrawer extends Component {

    // Main render function
    render() {
        const { classes } = this.props;

        return (
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                [classes.drawerOpen]: this.props.open,
                [classes.drawerClose]: !this.props.open,
                })}
                classes={{
                paper: clsx({
                    [classes.drawerOpen]: this.props.open,
                    [classes.drawerClose]: !this.props.open,
                }),
                }}
            >
                <Toolbar />
                <div className={classes.drawerContainer}>
                    <MainMenu className={classes.menu} />
                    <Divider className={classes.divider} />
                    <AdminMenu className={classes.menu} dividerClassName={classes.divider} />
                    <AccountMenu className={classes.menu} />
                    <Divider className={classes.divider} />
                    <AboutMenu className={classes.menu} />
                </div>
            </Drawer>
        );
    }
}

export default withStyles(useStyles)(MainDrawer);