import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ChartAbsence from './ChartAbsence';
import TableAbsence from './TableAbsence';
import Grid from '@material-ui/core/Grid';
import CalendarHelper from '../classes/CalendarHelper.js';
import { toJSONLocalDate } from '../helpers.js';
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Report Absence User
 */
class ReportAbsenceUser extends Component {
    state = {}

    constructor(props) {
        super(props);

        // References to call into child
        this.tableChildRef = React.createRef();
    }

    /**
     * EXPORTS
     */

    // Export to CSV
    onCsvExport( users, reports ) {

        // ### If users array contains only one single user:
        // Header: 
        // Report Absences,<lastname>,<firstname>,<email address>,<date>,<time>
        // Year,[per Absence Reason: <R> days in year],[per Month and Reason: <M> <R> days]
        // Then one line per year containing the given information

        // ### If users array contains multiple users:
        // Header: 
        // Report Absences,<lastname>,<firstname>,<email address>,<date>,<time>
        // Lastname,Firstname,Email Address,Year,[per Absence Reason: <R> days in year],[per Month and Reason: <M> <R> days]
        // Then one line per user and year containing the given information

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "short" );
        const today = new Date();
        const dateString = toJSONLocalDate( today );
        const timeString = today.getHours().toString() + ":" + today.getMinutes().toString() + ":" + today.getSeconds().toString();

        // Determine the file name, differs if only one user is given or if multiple users are given
        let csvFilename = "";

        if( users.length === 1 ) {
            csvFilename = i18n.t("pages.reports.options.absence.export.filename", {
                firstname: users[0].firstname,
                lastname: users[0].lastname
            });
        } else if( users.length > 1 ) {
            csvFilename = i18n.t("pages.reports.options.absence.export.filename_all");
        }

        let csvData = [];
        if( users.length === 1 ) {
            csvData.push( [ i18n.t("pages.reports.options.absence.export.headline"), users[0].lastname, users[0].firstname, users[0].email, dateString, timeString ] );
        } else if( users.length > 1 ) {
            csvData.push( [ i18n.t("pages.reports.options.absence.export.headline"), dateString, timeString ] );
        }

        let headerSecond = [];
        if( users.length > 1 ) {
            headerSecond.push( i18n.t("pages.reports.options.absence.export.last_name") );
            headerSecond.push( i18n.t("pages.reports.options.absence.export.first_name") );
            headerSecond.push( i18n.t("pages.reports.options.absence.export.email") );
        }
        headerSecond.push( i18n.t("pages.reports.options.absence.export.year") );

        this.props.specials.sort(function(a, b) { return a.name > b.name ? 1 : -1; });

        for( let i = 0; i < this.props.specials.length; i++ ) {
            headerSecond.push( i18n.t("pages.reports.options.absence.export.booked", { name: this.props.specials[i].name } ) );
        }

        for( let i = 0; i < monthNames.length; i++ ) {
            for( let j = 0; j < this.props.specials.length; j++ ) {
                headerSecond.push( i18n.t("pages.reports.options.absence.export.month_booked", { month: monthNames[i], name: this.props.specials[j].name } ) );
            }
        }
        csvData.push( headerSecond );

        // Get summary from child table for each user and year
        for( let i = 0; i < users.length; i++ ) {

            // Retrieve the proper vacation report for this user
            let userReport = null;
            for( let j = 0; j < reports.length; j++ ) {
                if( reports[j].userid === users[i].userid ) {
                    userReport = reports[j];
                    break;
                }
            }

            if( null != userReport ) {

                // Grab all years from the user report
                let years = [];
                for( let j = 0; j < userReport.years.length; j++ ) {
                    years.push( userReport.years[j].year )
                }

                const summary = this.tableChildRef.current.getSummary( userReport, this.props.specials );

                // Get summary from child table for each year
                for( let j = 0; j < summary.length; j++ ) {
                    const year = summary[j].year;
        
                    // Convert summary into csv
                    let entry = [];

                    if( users.length > 1 ) {
                        entry.push( users[i].lastname );
                        entry.push( users[i].firstname );
                        entry.push( users[i].email );
                    }
                    entry.push( year );
        
                    for( let k = 0; k < this.props.specials.length; k++ ) {
                        let days_absent_in_year = 0;
        
                        for( let l = 0; l < summary[j].months.length; l++ ) {
                            for( let m = 0; m < summary[j].months[l].absences.length; m++ ) {
                                if( summary[j].months[l].absences[m].special.specialid === this.props.specials[k].specialid ) {
                                    days_absent_in_year += summary[j].months[l].absences[m].days_absent_in_month;
                                }
                            }
                        }
        
                        entry.push( days_absent_in_year );
                    }
        
                    for( let k = 0; k < summary[j].months.length; k++ ) {
                        summary[j].months[k].absences.sort(function(a, b) { return a.special.name > b.special.name ? 1 : -1; });
                        for( let l = 0; l < summary[j].months[k].absences.length; l++ ) {
                            entry.push( summary[j].months[k].absences[l].days_absent_in_month );
                        }
                    }
                    
                    csvData.push( entry );
                }
            }
        }
        
        return { csvFilename, csvData };
    }

    /**
	 * RENDER FUNCTIONS
	 */

    render() {
        // INPUTS: language, year, absenceReport, userid
        let absenceChart = "";
        let absenceTable = "";

        if( null != this.props.absenceReport && null != this.props.specials ) {
            absenceChart = (
                <ChartAbsence 
                    language={this.props.language}
                    year={this.props.year}
                    absenceReport={this.props.absenceReport}
                    specials={this.props.specials}
                    userid={this.props.userid}
                />
            )
            absenceTable = (
                <TableAbsence
                    ref={this.tableChildRef}
                    language={this.props.language}
                    year={this.props.year}
                    specials={this.props.specials}
                    absenceReport={this.props.absenceReport}
                />
            )
        }

        return (
            <Grid item container justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={6}>
                    {absenceChart}
                </Grid>
                <Grid item xs={6}>
                    {absenceTable}
                </Grid>
            </Grid>
        );
    }
};

export default withStyles(useStyles)(ReportAbsenceUser);