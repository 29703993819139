import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ChartVacation from './ChartVacation';
import TableVacation from './TableVacation';
import Grid from '@material-ui/core/Grid';
import CalendarHelper from '../classes/CalendarHelper.js';
import { toJSONLocalDate } from '../helpers.js';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import PdfTableVacation from './pdf/PdfTableVacation.js';
import i18n from 'i18next';

const useStyles = theme => ({});

const pdfStyles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF'
    },
    titleSection: {
        textAlign: 'center',
        color: '#000000',
        margin: 5,
        padding: 0,
        flexGrow: 1,
        maxHeight: 100
    },
    titleHeaderText: {
        margin: 10,
        fontSize: 20,
        backgroundColor: "#248EDB",
        color: "#ffffff"
    },
    titleSubText: {
        margin: 5,
        fontSize: 12
    },
    titleTimestampText: {
        margin: 5,
        fontSize: 10
    },
    summarySection: {
        textAlign: 'center',
        margin: 0,
        padding: 0,
        border: 5
    },
    detailSection: {
        textAlign: 'center',
        color: '#000000',
        margin: 10,
        padding: 1,
        flexGrow: 1
    }
});

/**
 * Report Vacation User
 */
class ReportVacationUser extends Component {
    state = {}

    constructor(props) {
        super(props);

        // References to call into child
        this.tableChildRef = React.createRef();
    }

    /**
     * EXPORTS
     */
    
    // Export to CSV
    onCsvExport( users, reports ) {

        // ### If users array contains only one single user:
        // Header: 
        // Report Vacations,<lastname>,<firstname>,<email address>,<date>,<time>
        // Year,Days transfered from previous year,Days eligible in year,Days adjusted in year,Days total in year,Days already booked in year,Days still available in year,[per Month: <M> booked Days,<M> adjusted Days]
        // Then one line per year containing the given information

        // ### If users array contains multiple users:
        // Header: 
        // Report Vacations,<date>,<time>
        // Lastname,Firstname,Email Address,Year,Days transfered from previous year,Days eligible in year,Days adjusted in year,Days total in year,Days already booked in year,Days still available in year,Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec
        // Then one line per user and year containing the given information

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "short" );
        const today = new Date();
        const dateString = toJSONLocalDate( today );
        const timeString = today.getHours().toString() + ":" + today.getMinutes().toString() + ":" + today.getSeconds().toString();

        // Determine the file name, differs if only one user is given or if multiple users are given
        let csvFilename = "";

        if( users.length === 1 ) {
            csvFilename = i18n.t("pages.reports.options.vacation.export.csv.filename", {
                firstname: users[0].firstname,
                lastname: users[0].lastname
            });
        } else if( users.length > 1 ) {
            csvFilename = i18n.t("pages.reports.options.vacation.export.csv.filename_all");
        }

        let csvData = [];
        if( users.length === 1 ) {
            csvData.push( [ i18n.t("pages.reports.options.vacation.export.headline"), users[0].lastname, users[0].firstname, users[0].email, dateString, timeString ] );
        } else if( users.length > 1 ) {
            csvData.push( [ i18n.t("pages.reports.options.vacation.export.headline"), dateString, timeString ] );
        }

        let headerSecond = [];
        if( users.length > 1 ) {
            headerSecond.push( i18n.t("pages.reports.options.vacation.export.last_name") );
            headerSecond.push( i18n.t("pages.reports.options.vacation.export.first_name") );
            headerSecond.push( i18n.t("pages.reports.options.vacation.export.email") );
        }
        headerSecond.push( i18n.t("pages.reports.options.vacation.export.year") );
        headerSecond.push( i18n.t("pages.reports.options.vacation.export.from_last_year") );
        headerSecond.push( i18n.t("pages.reports.options.vacation.export.from_contracts") );
        headerSecond.push( i18n.t("pages.reports.options.vacation.export.adjusted") );
        headerSecond.push( i18n.t("pages.reports.options.vacation.export.total") );
        headerSecond.push( i18n.t("pages.reports.options.vacation.export.booked") );
        headerSecond.push( i18n.t("pages.reports.options.vacation.export.available") );
        for( let i = 0; i < monthNames.length; i++ ) {
            headerSecond.push( i18n.t("pages.reports.options.vacation.export.month_booked_days", { month: monthNames[i] }) );
            headerSecond.push( i18n.t("pages.reports.options.vacation.export.month_adjusted_days", { month: monthNames[i] }) );
        }

        csvData.push( headerSecond );

        // Get summary from child table for each user and year
        for( let i = 0; i < users.length; i++ ) {

            // Retrieve the proper vacation report for this user
            let userReport = null;
            for( let j = 0; j < reports.length; j++ ) {
                if( reports[j].userid === users[i].userid ) {
                    userReport = reports[j];
                    break;
                }
            }

            if( null != userReport ) {

                // Grab all years from the user report
                let years = [];
                for( let j = 0; j < userReport.years.length; j++ ) {
                    years.push( userReport.years[j].year )
                }

                for( let j = 0; j < years.length; j++ ) {

                    const year = years[j];
                    const summary = this.tableChildRef.current.getSummary( userReport, year.toString() );
                    const seriesBookedDays = this.tableChildRef.current.getSeriesBookedDays( userReport, year.toString() );
                    const seriesAdjustedDays = this.tableChildRef.current.getSeriesAdjustedDays( userReport, year.toString() );
        
                    // Convert summary into csv
                    let entry = [];

                    if( users.length > 1 ) {
                        entry.push( users[i].lastname );
                        entry.push( users[i].firstname );
                        entry.push( users[i].email );
                    }

                    entry.push( year );
                    entry.push( summary.daysFromLastYear );
                    entry.push( summary.daysFromContract );
                    entry.push( summary.daysAdjusted );
                    entry.push( summary.daysTotal );
                    entry.push( summary.daysBooked );
                    entry.push( summary.daysAvailable );

                    for( let k = 0; k < monthNames.length; k++ ) {
                        let value = "";
                        if( seriesBookedDays.length > k ) { value = seriesBookedDays[k]; }
                        entry.push( value );
                        value = "";
                        if( seriesAdjustedDays.length > k ) { value = seriesAdjustedDays[k]; }
                        entry.push( value );
                    }
        
                    csvData.push( entry );
                }
            }
        }
        
        return { csvFilename, csvData };
    }

    // Export to PDF
    onPdfExport( users, reports ) {
        const today = new Date();
        const dateString = toJSONLocalDate( today );
        const timeString = today.getHours().toString() + ":" + today.getMinutes().toString() + ":" + today.getSeconds().toString();

        // Determine the file name, differs if only one user is given or if multiple users are given
        let pdfFilename = "";

        if( users.length === 1 ) {
            pdfFilename = i18n.t("pages.reports.options.vacation.export.pdf.filename", {
                firstname: users[0].firstname,
                lastname: users[0].lastname
            });
        } else if( users.length > 1 ) {
            pdfFilename = i18n.t("pages.reports.options.vacation.export.pdf.filename_all");
        }

        let pdfData = (
            <Document title={i18n.t("pages.reports.options.vacation.export.pdf.title")}>
                <Page key="page-main" size="A4" orientation="portrait" style={pdfStyles.page}>
                    <View style={pdfStyles.titleSection}>
                        <Text style={pdfStyles.titleHeaderText}>{i18n.t("pages.reports.options.vacation.export.pdf.title")}</Text>
                        <Text style={pdfStyles.titleSubText}>{users[0].firstname} {users[0].lastname}</Text>
                        <Text style={pdfStyles.titleTimestampText}>{i18n.t("pages.reports.options.vacation.export.pdf.generated", { date: dateString, time: timeString })}</Text>
                    </View>
                </Page>
                {reports[0].years.map((reportYear, i) => {
                    return (
                        <Page key={"page-"+i} size="A4" orientation="portrait" style={pdfStyles.page}>
                            <View style={pdfStyles.summarySection}>
                                <PdfTableVacation
                                    key={"table-vacation-"+reportYear.year}
                                    language={this.props.language}
                                    vacationReport={reports[0]}
                                    year={reportYear.year}
                                />
                            </View>
                        </Page>
                    )
                })}
            </Document>
        );
        return { pdfFilename, pdfData };
    }

    /**
	 * RENDER FUNCTIONS
	 */

    render() {
        // INPUTS: language, year, vacationReport, userid
        let vacationChart = "";
        let vacationTable = "";

        if( null != this.props.vacationReport ) {
            vacationChart = (
                <ChartVacation 
                    language={this.props.language}
                    year={this.props.year}
                    vacationReport={this.props.vacationReport}
                    userid={this.props.userid}
                />
            )
            vacationTable = (
                <TableVacation
                    ref={this.tableChildRef}
                    language={this.props.language}
                    year={this.props.year}
                    vacationReport={this.props.vacationReport}
                />
            )
        }

        return (
            <Grid item container justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={6}>
                    {vacationChart}
                </Grid>
                <Grid item xs={6}>
                    {vacationTable}
                </Grid>
            </Grid>
        );
    }
};

export default withStyles(useStyles)(ReportVacationUser);