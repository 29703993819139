import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 */
class AdjustmentService {

    /**
     * Get teams
     * @param {*} adjustmentSearch { adjustmentid, work_hours, vacation_days, comment, userid, date }
     */
    async getAdjustments( adjustmentSearch ) {
        var request = {
            query: `
            query getAdjustments($adjustmentSearch: AdjustmentSearch!) {
                getAdjustments(adjustmentSearch: $adjustmentSearch) {
                    adjustmentid
                    work_hours
                    vacation_days
                    comment
                    userid
                    date
                }
            }
            `,
            variables: {
                adjustmentSearch: adjustmentSearch
            }
        };

        return await ApiService.doPost( request );
    }


    /**
     * Create a new adjustment
     * @param {*} adjustmentInput { vacation_days, work_hours, comment, userid, date }
     */
    async createAdjustment( adjustmentInput ) {
        var request = {
            query: `
            mutation createAdjustment($adjustmentInput: AdjustmentInput) {
                createAdjustment(adjustmentInput: $adjustmentInput) {
                    adjustmentid
                    work_hours
                    vacation_days
                    comment
                    userid
                    date
                }
            }
            `,
            variables: {
                adjustmentInput: adjustmentInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update a adjustment
     * @param {*} adjustmentSearch { adjustmentid, vacation_days, work_hours, comment, userid, date }
     * @param {*} adjustmentUpdate { vacation_days, comment, date }
     */
    async updateAdjustment( adjustmentSearch, adjustmentUpdate ) {
        var request = {
            query: `
            mutation updateAdjustment($adjustmentSearch: AdjustmentSearch!, $adjustmentUpdate: AdjustmentUpdate!) {
                updateAdjustment(adjustmentSearch: $adjustmentSearch, adjustmentUpdate: $adjustmentUpdate) {
                    adjustmentid
                    work_hours
                    vacation_days
                    comment
                    userid
                    date
                }
            }
            `,
            variables: {
                adjustmentSearch: adjustmentSearch,
                adjustmentUpdate: adjustmentUpdate
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete a adjustment
     * @param {*} adjustmentSearch { adjustmentid, vacation_days, work_hours, comment, userid, date }
     */
    async deleteAdjustment( adjustmentSearch ) {
        var request = {
            query: `
            mutation deleteAdjustment($adjustmentSearch: AdjustmentSearch!) {
                deleteAdjustment(adjustmentSearch: $adjustmentSearch) {
                    result
                }
            }
            `,
            variables: {
                adjustmentSearch: adjustmentSearch
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new AdjustmentService();
