import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ChartProjectOverview from '../components/ChartProjectOverview';
import ChartProjectTaskDetail from './ChartProjectTaskDetail';
import TableProjectTaskDetail from './TableProjectTaskDetail';
import Grid from '@material-ui/core/Grid';

const useStyles = theme => ({});

/**
 * Report Project - Task Detail
 */
class ReportProjectTaskDetail extends Component {
    state = {}

    constructor(props) {
        super(props);

        // References to call into child
        this.tableChildRef = React.createRef();
    }

    /**
     * EXPORTS
     */
    
    /**
	 * RENDER FUNCTIONS
	 */

    render() {
        // INPUTS: language, dateMin, dateMax, projectReport, taskid
        let projectChart = "";
        let projectTable = "";

        if( null != this.props.projectReport ) {
            if( this.props.taskid && this.props.taskid !== "" ) {
                projectChart = (
                    <ChartProjectTaskDetail
                        language={this.props.language}
                        dateMin={this.props.dateMin}
                        dateMax={this.props.dateMax}
                        projectReport={this.props.projectReport}
                        taskid={this.props.taskid}
                    />
                )
            } else {
                projectChart = (
                    <ChartProjectOverview
                        language={this.props.language}
                        dateMin={this.props.dateMin}
                        dateMax={this.props.dateMax}
                        projectReport={this.props.projectReport}
                    />
                )
            }
            projectTable = (
                <TableProjectTaskDetail
                    ref={this.tableChildRef}
                    language={this.props.language}
                    dateMin={this.props.dateMin}
                    dateMax={this.props.dateMax}
                    projectReport={this.props.projectReport}
                    taskid={this.props.taskid}
                />
            )
        }

        return (
            <Grid item container justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={7}>
                    {projectChart}
                </Grid>
                <Grid item xs={5}>
                    {projectTable}
                </Grid>
            </Grid>
        );
    }
};

export default withStyles(useStyles)(ReportProjectTaskDetail);