import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CalendarHeader from './CalendarHeader'
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { StyledTableCell, StyledTableRow, StyledTableHead, StyledTable, StyledTableSortLabel, stableSort, getComparator } from './StyledTable.js'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import ModalDialog from './ModalDialog';
import Grid from '@material-ui/core/Grid';
import CustomTextField from './CustomTextField'
import CustomDatePicker from './CustomDatePicker'
import AuthContext from '../contexts/AuthContext';
import { config } from '../config';
import i18n from 'i18next';

const useStyles = theme => ({
    tableCellName: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1.0),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
    },
    tableCellDate: {
    },
    tablePaginationGrid: {
        height: '60px',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tablePagination: {
        '& .MuiTablePagination-toolbar': {
            height: '60px',
            minHeight: '60px',
        },
    },
});

/**
 * Holidays List View
 */
class HolidaysList extends Component {
    static contextType = AuthContext;

    state = {
        holidays: [],
        currentYear: null,
        tablePage: 0,
        tableRowsPerPage: 10,
        order: "asc",
        orderBy: 0,
        deleteDialog: {
            open: false,
            date: null,
            name: null
        }
    }

    constructor(props) {
        super(props);
        
        this.handleYearChanged = this.handleYearChanged.bind( this );
        this.handleChangeTablePage = this.handleChangeTablePage.bind( this );
        this.handleChangeTableRowsPerPage = this.handleChangeTableRowsPerPage.bind( this );
        this.handleTableRowDeleteClick = this.handleTableRowDeleteClick.bind( this );
        this.verifyNameFieldContent = this.verifyNameFieldContent.bind( this );
        this.handleNameFieldChange = this.handleNameFieldChange.bind( this );
        this.verifyDateFieldContent = this.verifyDateFieldContent.bind( this );
        this.handleDateFieldChange = this.handleDateFieldChange.bind( this );
        this.handleTableRowDeleteDialog_Ok = this.handleTableRowDeleteDialog_Ok.bind( this );
        this.handleTableRowDeleteDialog_Cancel = this.handleTableRowDeleteDialog_Cancel.bind( this );
        this.handleRequestSort = this.handleRequestSort.bind( this );
        this.sortTableData = this.sortTableData.bind( this );
    }

    /**
     * TABLE PAGINATION EVENT HANDLERS
     */

    // Called if page changes on the table display occur
    handleChangeTablePage = (event, newPage) => {
        this.setState({tablePage: newPage});
    };
    
    // Called if the user selects a different amount of shown rows
    handleChangeTableRowsPerPage = (event) => {
        this.setState({tablePage: 0, tableRowsPerPage: +event.target.value});
    };

    /**
     * DATA HANDLING
     */

    // Called to refresh the holidays
    refreshHolidays( currentYear ) {
        let holidays = [];
    
        if( null != this.props.getHolidays ) {
            holidays = this.props.getHolidays( currentYear );
        }

        this.setState({ holidays: holidays, currentYear: currentYear });
    }

    // Called when the user clicked on the delete button of a row
    handleTableRowDeleteClick( event, date, name ) {
        event.stopPropagation();
        this.openTableRowDeleteDialog( date, name );
    }

    /**
     * CHANGE HANDLERS
     */

    // Called when the displayed year changed
    handleYearChanged( currentYear ) {
        this.refreshHolidays( currentYear );

        if( null != this.props.onYearChanged ) {
            this.props.onYearChanged( currentYear );
        }
    }

    // Verify the entered new name in a row
    verifyNameFieldContent( name ) {
        if( null != this.props.verifyName ) {
            return this.props.verifyName( name );
        }
        return false;
    }

    // Update the name on a row
    handleNameFieldChange( event, date, name, newName ) {
        event.stopPropagation();

        if( null != this.props.onUpdateName ) {
            this.props.onUpdateName( date, name, newName );
        }
    }

    // Verify the entered new date in a row
    verifyDateFieldContent( date ) {
        if( null != this.props.verifyDate ) {
            return this.props.verifyDate( date );
        }
        return false;
    }

    // Update the date on a row
    handleDateFieldChange( event, date, name, newDate ) {
        event.stopPropagation();

        if( null != this.props.onUpdateDate ) {
            this.props.onUpdateDate( date, name, newDate );
        }
    }

    /**
     * ALERT DIALOG <DELETE>
     */

    // Called to open the delete dialog
    openTableRowDeleteDialog( date, name ) {
        let deleteDialog = {
            open: true,
            date: date,
            name: name
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    // Called when the user clicked "Ok" on the confirm row delete dialog
    handleTableRowDeleteDialog_Ok( date ) {
        this.closeTableRowDeleteDialog();

        if( null != this.props.onDelete ) {
            this.props.onDelete( date );
        }
    }

    // Called when the user clicked "Cancel" or close on the confirm row delete dialog
    async handleTableRowDeleteDialog_Cancel() {
        this.closeTableRowDeleteDialog();
    }

    // Close the delete alert dialog
    closeTableRowDeleteDialog() {
        let deleteDialog = {
            open: false,
            specialid: this.state.deleteDialog.specialid,
            name: this.state.deleteDialog.name
        }
        this.setState({ deleteDialog: deleteDialog });
    }

    // Called by react when this component has been mounted
    componentDidMount() {
        let currentYear = new Date().getFullYear();
        if( null != this.props.year ) {
            currentYear = this.props.year;
        }
        this.refreshHolidays( currentYear );
    }

    /**
     * DATA SORT
     */

    // Handle table sort
    handleRequestSort( event, property ) {

        // Check if clicked on same property
        if( this.state.orderBy === property ) {

            // User clicked on same property
            // If already sorted in ascending order, switch to descending order
            if( this.state.order === 'asc' ) {
                this.setState({ order: 'desc', orderBy: property });
            } else {

                // If already sorted in descending order, switch to no order
                this.setState({ order: 'asc', orderBy: 0 });
            }

        } else {
            // User clicked on new property, sort new property in ascending order
            this.setState({ order: 'asc', orderBy: property });
        }
    };

    // Sort the given table data
    sortTableData( holidays ) {
        // Handle case where no ordering is selected
        if( this.state.orderBy === 0 ) {
            return holidays.sort(function(a, b) { return a.date > b.date ? 1 : -1; });
        }
        return stableSort( holidays, getComparator(this.state.order, this.state.orderBy) );
    }

    render() {
        const { classes } = this.props;

        // Ensure that we use the holiday data, since data might get filtered afterwards
        let holidays = this.state.holidays;
        let allHolidays = holidays;
        if( null != this.props.getHolidays ) {
            holidays = this.props.getHolidays( this.state.currentYear );
            allHolidays = this.props.getHolidays();
        }

        let contentTable = "";

        if( null != holidays ) {
            // Assemble table content
            const tableHeader = [
                { key: "name", span: 1, align: "left", sortable: true, style: {}, text: i18n.t("pages.admin.holidays.table.header.name") },
                { key: "date", span: 1, align: "left", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.holidays.table.header.date") },
                { key: "controls", span: 2, align: "center", sortable: false, style: { borderStyle: "solid", borderLeftWidth: 1 }, text: i18n.t("pages.admin.holidays.table.header.controls") }
            ];

            // Sort table entries by given properties
            holidays = this.sortTableData( holidays );

            contentTable = (
                <div>
                    <TableContainer style={{ minHeight: "406px" }} component={Paper}>
                        <StyledTable size="small" >
                            <StyledTableHead>
                                <StyledTableRow key={"calendar-list-header"}>
                                    {tableHeader.map((col) => (
                                        <StyledTableCell key={col.key} colSpan={col.span} align={col.align} style={col.style}>
                                            <StyledTableSortLabel
                                                active={this.state.orderBy === col.key}
                                                hideSortIcon={false}
                                                direction={this.state.orderBy === col.key ? this.state.order : 'asc'}
                                                onClick={(event) => this.handleRequestSort(event, col.key)}
                                                disabled={!(col.sortable)}
                                            >
                                                {col.text}
                                            </StyledTableSortLabel>    
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </StyledTableHead>
                            <TableBody>
                                {holidays.slice(this.state.tablePage * this.state.tableRowsPerPage, this.state.tablePage * this.state.tableRowsPerPage + this.state.tableRowsPerPage).map((holiday) => {
                                    return (
                                        <StyledTableRow key={holiday.date} date={holiday.date}>
                                            <StyledTableCell className={classes.tableCellName} key="name" width="40%">
                                                <CustomTextField
                                                    maxLength={config.maxTextLengths.holiday.name}
                                                    textFieldValue={holiday.name} 
                                                    verifyContent={(name) => this.verifyNameFieldContent(name)} 
                                                    onApply={(event, newValue) => this.handleNameFieldChange(event, holiday.date, holiday.name, newValue)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.tableCellDate} key="date" width="40%" style={{ borderStyle: "solid", borderLeftWidth: 1 }}>
                                                <CustomDatePicker 
                                                    dateValue={holiday.date} 
                                                    verifyContent={(date) => this.verifyDateFieldContent(date)}
                                                    blockedDates={(allHolidays)}
                                                    allowedDate={holiday.date}
                                                    onApply={(event, newDate) => this.handleDateFieldChange(event, holiday.date, holiday.name, newDate)}
                                                    language={this.context.language}
                                                    title={i18n.t("pages.admin.holidays.modify.dialog.title")}
                                                    descText={i18n.t("pages.admin.holidays.modify.dialog.description")}
                                                    buttonLeft={i18n.t("pages.admin.holidays.modify.dialog.button_left")}
                                                    buttonRight={i18n.t("pages.admin.holidays.modify.dialog.button_right")}
                                                    disableToolbar={true}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell key="delete" align="center" padding="checkbox" width="20%" style={{ borderStyle: "solid", borderLeftWidth: 1 }} onClick={(event) => event.stopPropagation()}>
                                                <Tooltip title={i18n.t("pages.admin.holidays.table.tooltips.delete")}>
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.clearButton}
                                                            onClick={(event) => this.handleTableRowDeleteClick(event, holiday.date, holiday.name)}
                                                            style={{ height: "30px", width: "45px" }}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                    <Grid container direction="row" justify="flex-end" className={classes.tablePaginationGrid} spacing={1} wrap='nowrap'>
                        <Grid item>
                            <TablePagination
                                className={classes.tablePagination}
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={holidays.length}
                                rowsPerPage={this.state.tableRowsPerPage}
                                labelRowsPerPage={i18n.t("table.pagination.rows_per_page")}
                                page={this.state.tablePage}
                                onChangePage={this.handleChangeTablePage}
                                onChangeRowsPerPage={this.handleChangeTableRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                    <ModalDialog 
                        open={this.state.deleteDialog.open}
                        title={i18n.t("pages.admin.holidays.delete.dialog.title")}
                        descText={i18n.t("pages.admin.holidays.delete.dialog.description", { name: this.state.deleteDialog.name })}
                        buttonLeft={i18n.t("pages.admin.holidays.delete.dialog.button_left")}
                        buttonRight={i18n.t("pages.admin.holidays.delete.dialog.button_right")}
                        handleClickClose={() => this.handleTableRowDeleteDialog_Cancel()}
                        handleClickLeft={() => this.handleTableRowDeleteDialog_Ok( this.state.deleteDialog.date )}
                        handleClickRight={() => this.handleTableRowDeleteDialog_Cancel()}
                    />
                </div>
            )
        }

        return (
            <div>
                <CalendarHeader
                    year={this.props.year}
                    onYearChanged={this.handleYearChanged}
                />
                {contentTable}
            </div>
        );
    }
};

export default withStyles(useStyles)(HolidaysList);