import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BallotIcon from '@material-ui/icons/Ballot';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AuthContext from '../../contexts/AuthContext';
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Main menu
 */
class MainMenu extends Component {
    static contextType = AuthContext;

    state = {
        showFlags : {
            projects: false,
            tasks: false
        }
    }

    // Will mount after login
    async componentDidMount() {
        // Get the admin data for the users
        let showFlags = this.state.showFlags;

        const { statusCode, userAdminData } = await this.context.services.admin.getAdminData( this.context.userid );
        if( 200 === statusCode && null != userAdminData ) {
            
            // Gather which items to show from data
            if( true === userAdminData.admin.global ) {
                showFlags = {
                    projects: true,
                    tasks: true
                }
            } else {

                if( 0 < userAdminData.projectlead.length ) {
                    showFlags = {
                        projects: true,
                        tasks: true
                    }
                }
            }
        }

        // Set the new state of the component
        this.setState({ showFlags: showFlags });
    }

    // Will unmount on logout
    componentWillUnmount() {
        // Clear all status data since that only happens on logout
        this.setState({ 
            showFlags: {
                projects: false,
                tasks: false
            }
        });
    }

    // Main render function
    render() {
        return (
            <div id="main-menu">
                <ListItem button component={Link} to="/main" className={this.props.className}>
                    <ListItemIcon><AccessTimeIcon className={this.props.className}/></ListItemIcon>
                    <ListItemText primary={i18n.t("menus.main")} className={this.props.className}/>
                </ListItem>
                <ListItem button component={Link} to="/absence" className={this.props.className}>
                    <ListItemIcon><DirectionsRunIcon className={this.props.className}/></ListItemIcon>
                    <ListItemText primary={i18n.t("menus.absence")} className={this.props.className}/>
                </ListItem>
                <ListItem button component={Link} to="/reports" className={this.props.className}>
                    <ListItemIcon><AssessmentIcon className={this.props.className}/></ListItemIcon>
                    <ListItemText primary={i18n.t("menus.reports")} className={this.props.className}/>
                </ListItem>
                {this.state.showFlags.projects && (
                    <ListItem button component={Link} to="/projects" className={this.props.className}>
                        <ListItemIcon><BallotIcon className={this.props.className}/></ListItemIcon>
                        <ListItemText primary={i18n.t("menus.projects")} className={this.props.className}/>
                    </ListItem>
                )}
                {this.state.showFlags.tasks && (
                    <ListItem button component={Link} to="/tasks" className={this.props.className}>
                        <ListItemIcon><FormatListBulletedIcon className={this.props.className}/></ListItemIcon>
                        <ListItemText primary={i18n.t("menus.tasks")} className={this.props.className}/>
                    </ListItem>
                )}
            </div>
        );
    }
};

export default withStyles(useStyles)(MainMenu);