import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 * Also acts as provider for the UserContext
 */
class AccountingService {

    /**
     * Get accountings
     * @param {*} accountingSearch { accountingid, hours, comment, userid, taskid, specialid, date }
     */
    async getAccountings( accountingSearch ) {
        var request = {
            query: `
            query getAccountings($accountingSearch: AccountingSearch!) {
                getAccountings(accountingSearch: $accountingSearch) {
                    accountingid
                    hours
                    comment
                    userid
                    taskid
                    specialid
                    date
                }
            }
            `,
            variables: {
                accountingSearch: accountingSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Get accountings within date range
     * @param {*} accountingRangeSearch { minDate, maxDate, userid, taskid, specialid }
     */
    async getAccountingsRange( accountingRangeSearch ) {
        var request = {
            query: `
            query getAccountingsRange($accountingRangeSearch: AccountingRangeSearch!) {
                getAccountingsRange(accountingRangeSearch: $accountingRangeSearch) {
                    accountingid
                    hours
                    comment
                    userid
                    taskid
                    specialid
                    date
                }
            }
            `,
            variables: {
                accountingRangeSearch: accountingRangeSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Get accountings within date range for project
     * @param {*} accountingProjectSearch { minDate, maxDate, projectid, taskid, userid }
     */
    async getAccountingsForProject( accountingProjectSearch ) {
        var request = {
            query: `
            query getAccountingsForProject($accountingProjectSearch: AccountingProjectSearch!) {
                getAccountingsForProject(accountingProjectSearch: $accountingProjectSearch) {
                    accountingid
                    hours
                    comment
                    userid
                    taskid
                    specialid
                    date
                }
            }
            `,
            variables: {
                accountingProjectSearch: accountingProjectSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Create a new accounting
     * @param {*} accountingInput { hours, comment, userid, taskid, specialid, date }
     */
    async createAccounting( accountingInput ) {
        var request = {
            query: `
            mutation createAccounting($accountingInput: AccountingInput) {
                createAccounting(accountingInput: $accountingInput) {
                    accountingid
                    hours
                    comment
                    userid
                    taskid
                    specialid
                    date
                }
            }
            `,
            variables: {
                accountingInput: accountingInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Create a new or update an existing accounting
     * @param {*} accountingSearch { minDate, maxDate, userid, taskid, specialid }
     * @param {*} accountingInput { hours, comment, userid, taskid, specialid, date }
     */
    async createOrUpdateAccounting( accountingSearch, accountingInput ) {
        var request = {
            query: `
            mutation createOrUpdateAccounting($accountingSearch: AccountingSearch!, $accountingInput: AccountingInput) {
                createOrUpdateAccounting(accountingSearch: $accountingSearch, accountingInput: $accountingInput) {
                    accountingid
                    hours
                    comment
                    userid
                    taskid
                    specialid
                    date
                }
            }
            `,
            variables: {
                accountingSearch: accountingSearch,
                accountingInput: accountingInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update an accounting
     * @param {*} accountingSearch { accountingid, hours, comment, userid, taskid, specialid, date }
     * @param {*} accountingUpdate { hours, comment, taskid, specialid, date }
     */
    async updateAccounting( accountingSearch, accountingUpdate ) {
        var request = {
            query: `
            mutation updateAccounting($accountingSearch: AccountingSearch!, $accountingUpdate: AccountingUpdate!) {
                updateAccounting(accountingSearch: $accountingSearch, accountingUpdate: $accountingUpdate) {
                    date
                    userid
                    specialid
                }
            }
            `,
            variables: {
                accountingSearch: accountingSearch,
                accountingUpdate: accountingUpdate
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete an accounting
     * @param {*} accountingSearch { accountingid, hours, comment, userid, taskid, specialid, date }
     */
    async deleteAccounting( accountingSearch ) {
        var request = {
            query: `
            mutation deleteAccounting($accountingSearch: AccountingSearch!) {
                deleteAccounting(accountingSearch: $accountingSearch) {
                    result
                }
            }
            `,
            variables: {
                accountingSearch: accountingSearch
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new AccountingService();
