import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { jsonEqual } from '../helpers.js';
import ChipAutoComplete from './ChipAutoComplete'

const useStyles = theme => ({
    content: {
        flexGrow: 1,
    }
});

/**
 * Modal Dialog Chip Input Component
 */
class ModalDialogChipInput extends Component {
    state = {
        initialSelectedOptions: [],
        currentSelectedOptions: [],
        allOptions: []
    };

    constructor(props) {
        super(props);
        this.handleListDataChanged = this.handleListDataChanged.bind( this );
        this.getDataChanged = this.getDataChanged.bind( this );
    }

    // Determine the button state for the parent dialog
    getButtonState( initialSelectedOptions, currentSelectedOptions ) {
        // If currentSelectedOptions is different to initialSelectedOptions, enable the left and right buttons
        if( true !== jsonEqual( initialSelectedOptions, currentSelectedOptions ) ) {
            return { left: true, right: true };
        } else {
            return { left: false, right: true };
        }
    }

    // General push of new data to parent
    updateControlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent ) {
        if( null != this.props.controlButtonsAndPushContent ) {
            this.props.controlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent );
        }
    }

    // To check if the data has changed compared to the initial data
    getDataChanged() {
        return !( jsonEqual( this.state.initialSelectedOptions, this.state.currentSelectedOptions ) );
    }

    // Called when the lists changed
    handleListDataChanged( dataUnselected, dataSelected ) {

        // Sort given unselected list, we do not sort the selected list as this might look weird to the user with items suddenly changing place
        if( null != this.props.sortOptions ) {
            dataUnselected = this.props.sortOptions( dataUnselected );
        }

        const { left, right } = this.getButtonState( this.state.initialSelectedOptions, dataSelected );
        this.updateControlButtonsAndPushContent( left, right, { unselectedOptions: dataUnselected, selectedOptions: dataSelected } );
        this.setState({ currentSelectedOptions: dataSelected });
    }

    // Set all initial data
    updateInitialData() {
        let allOptions = [];
        let initialSelectedOptions = [];

        // Gather full list of options
        if( null != this.props.getAllOptions ) {
            allOptions = this.props.getAllOptions();
        }

        // Gather list selected options
        if( null != this.props.getSelectedOptions ) {
            initialSelectedOptions = this.props.getSelectedOptions();
        }

        // From both lists, build a list of all non-selected options
        let initialUnselectedOptions = [];
        if( null != allOptions && null != initialSelectedOptions ) {
            for( let i = 0; i < allOptions.length; i++ ) {

                let optionSelected = false;
                for( let j = 0; j < initialSelectedOptions.length; j++ ) {
                    if( true === jsonEqual( initialSelectedOptions[j], allOptions[i] ) ) {
                        optionSelected = true;
                        break;
                    }
                }

                if( false === optionSelected ) {
                    initialUnselectedOptions.push( allOptions[i] );
                }
            }
        }

        // Set states
        const { left, right } = this.getButtonState( initialSelectedOptions, initialSelectedOptions );
        this.updateControlButtonsAndPushContent( left, right, { unselectedOptions: initialUnselectedOptions, selectedOptions: initialSelectedOptions } );
        this.setState({
            initialSelectedOptions: initialSelectedOptions, 
            currentSelectedOptions: initialSelectedOptions,
            allOptions: allOptions
        });
    }

    // Called by react when this component has been mounted
    componentDidMount() {
        this.updateInitialData();
    }

    render() {
        // Extract locally used props and leave others
        // None used here, forward all
        var { controlButtonsAndPushContent, getAllOptions, getSelectedOptions, sortOptions, ...otherProps } = this.props;

        return (
            <div className={this.props.classes.content}>
                <ChipAutoComplete
                    dataSelected={this.state.currentSelectedOptions}
                    dataAll={this.state.allOptions}
                    dataChanged={this.handleListDataChanged}
                    getDataChanged={this.getDataChanged}
                    { ...otherProps }
                />
            </div>
        );
    }
};

export default withStyles(useStyles)(ModalDialogChipInput);