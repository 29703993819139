import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CalendarHeader from './CalendarHeader'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import HolidayGenerator from '../classes/HolidayGenerator';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TransferList from './TransferList';
import { config } from '../config';
import i18n from 'i18next';

const useStyles = theme => ({
    stepper: {
        width: "100%",
        minWidth: "525px"
    },
    stepContent: {
        padding: theme.spacing(2),
    },
    page: {
        width: "100%"
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    grid: {
        display: 'flex',
        width: '100%',
        minWidth: '500px',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 225,
    },
});

/**
 * Holidays Generate View
 */
class HolidaysGenerate extends Component {
    state = {}

    constructor(props) {
        super(props);

        this.handleYearChanged = this.handleYearChanged.bind( this );
        this.handleBackClick = this.handleBackClick.bind( this );
        this.handleNextClick = this.handleNextClick.bind( this );
        this.handleFinishClick = this.handleFinishClick.bind( this );

        this.handleChangeCountry = this.handleChangeCountry.bind( this );
        this.handleChangeRegion = this.handleChangeRegion.bind( this );
        this.handleListDataChanged = this.handleListDataChanged.bind( this );
        this.handleClearAllChanged = this.handleClearAllChanged.bind( this );
        this.handleOverwriteChanged = this.handleOverwriteChanged.bind( this );

        this.getStepDescription = this.getStepDescription.bind( this );
        this.getStepActions = this.getStepActions.bind( this );

        this.renderStepper = this.renderStepper.bind( this );
        this.renderPage = this.renderPage.bind( this );
        this.renderPage_Empty = this.renderPage_Empty.bind( this );
        this.renderPage_CountryRegion = this.renderPage_CountryRegion.bind( this );
        this.renderPage_Review = this.renderPage_Review.bind( this );
        this.renderPage_Import = this.renderPage_Import.bind( this );

        // Set initial state
        this.state = this.getInitialState( false, false );
    }

    // Get the initial state
    getInitialState( checkClearAll, checkOverwrite ) {
        let initialState = {
            steps: [
                i18n.t("pages.admin.holidays.generate.steps.1.label"), 
                i18n.t("pages.admin.holidays.generate.steps.2.label"), 
                i18n.t("pages.admin.holidays.generate.steps.3.label"), 
                i18n.t("pages.admin.holidays.generate.steps.4.label")
            ],
            activeStep: 0,
            country: config.generateHolidaysDefault.country,
            region: config.generateHolidaysDefault.region,
            importHolidaysNotSelected: null,
            importHolidaysSelected: null,
            checkClearAll: checkClearAll,
            checkOverwrite: checkOverwrite
        }

        return initialState;
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState() {
        this.setState( this.getInitialState( this.state.checkClearAll, this.state.checkOverwrite ) );
    }

    /**
     * HANDLERS
     */

    // Called when the displayed year changed
    handleYearChanged( currentYear ) {
        if( null != this.props.onYearChanged ) {
            this.props.onYearChanged( currentYear );
        }
        this.resetState();
    }

    // Back clicked
    handleBackClick( stepIndex ) {
        this.setState({ activeStep: stepIndex-1 });
    }

    // Next clicked
    handleNextClick( stepIndex ) {
        this.setState({ activeStep: stepIndex+1 });
    }

    // Finish clicked
    async handleFinishClick() {
        // Perform the import
        if( null != this.props.onImport ) {
            const success = await this.props.onImport( this.props.year, this.state.importHolidaysSelected, this.state.checkClearAll, this.state.checkOverwrite );
            if( true === success ) {
                this.resetState();
            }
        }
    }

    // Country is changing
    handleChangeCountry(event) {
        this.setState({ country: event.target.value, region: "", importHolidaysNotSelected: null, importHolidaysSelected: null });
    }

    // Region is changing
    handleChangeRegion(event) {
        this.setState({ region: event.target.value, importHolidaysNotSelected: null, importHolidaysSelected: null });
    }

    // List data changed
    handleListDataChanged( newLeftListData, newRightListData ) {
        this.setState({ importHolidaysNotSelected: newLeftListData, importHolidaysSelected: newRightListData });
    }

    // Checkbox clear all changed
    handleClearAllChanged(event) {
        let clearAllEnabled = event.target.checked;
        let overwriteEnabled = this.state.checkOverwrite;
        if( true === clearAllEnabled ) {
            overwriteEnabled = false;
        }

        this.setState({ checkClearAll: clearAllEnabled, checkOverwrite: overwriteEnabled });
    }

    // Checkbox overwrite changed
    handleOverwriteChanged(event) {
        let clearAllEnabled = this.state.checkClearAll;
        let overwriteEnabled = event.target.checked;
        if( true === overwriteEnabled ) {
            clearAllEnabled = false;
        }

        this.setState({ checkClearAll: clearAllEnabled, checkOverwrite: overwriteEnabled });
    }

    /**
     * STEPPER
     */

    // Get description text for step
    getStepDescription( stepIndex ) {
        switch( stepIndex ) {
            case 0:
                return i18n.t("pages.admin.holidays.generate.steps.1.desc");
            case 1:
                return i18n.t("pages.admin.holidays.generate.steps.2.desc");
            case 2:
                return i18n.t("pages.admin.holidays.generate.steps.3.desc");
            case 3:
                return i18n.t("pages.admin.holidays.generate.steps.4.desc");
            default:
                return 'Unknown Step'
        }
    }

    // Get actions for step
    getStepActions( stepIndex ) {
        const { classes } = this.props;

        let contentBackButton = (
            <Button
                variant="contained"
                color="primary"
                onClick={(event) => this.handleBackClick(stepIndex)}
                className={classes.button}
            >
                {i18n.t("pages.admin.holidays.generate.buttons.back")}
            </Button>
        )
        let contentNextButton = (
            <Button
                variant="contained"
                color="primary"
                onClick={(event) => this.handleNextClick(stepIndex)}
                className={classes.button}
            >
                {i18n.t("pages.admin.holidays.generate.buttons.next")}
            </Button>
        )
        let contentFinishButton = (
            <Button
                variant="contained"
                color="primary"
                onClick={(event) => this.handleFinishClick(stepIndex)}
                className={classes.button}
            >
                {i18n.t("pages.admin.holidays.generate.buttons.finish")}
            </Button>
        )

        if( stepIndex === 0 ) {
            return (
                <div>
                    {contentNextButton}
                </div>
            )

        } else if( stepIndex === 1 ) {
            return (
                <div>
                    {contentBackButton}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(event) => this.handleNextClick(stepIndex)}
                        className={classes.button}
                        disabled={!(this.state.country !== "" && this.state.region !== "")}
                    >
                        {i18n.t("pages.admin.holidays.generate.buttons.next")}
                    </Button>
                </div>
            )

        } else if( stepIndex === 2 ) {
            return (
                <div>
                    {contentBackButton}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(event) => this.handleNextClick(stepIndex)}
                        className={classes.button}
                        disabled={!(this.state.importHolidaysSelected != null && this.state.importHolidaysSelected.length > 0)}
                    >
                        {i18n.t("pages.admin.holidays.generate.buttons.next")}
                    </Button>
                </div>
            )

        } else if( stepIndex === 3 ) {
            return (
                <div>
                    {contentBackButton}
                    {contentFinishButton}
                </div>
            )

        } else {
            return (
                'Unknown Step'
            )
        }
    }

    /**
     * SPECIFIC RENDER FUNCTIONS
     */

    // Render the left hand side stepper
    renderStepper() {
        const { classes } = this.props;
        return (
            <div className={classes.stepper}>
                <Stepper activeStep={this.state.activeStep} orientation="vertical">
                    {this.state.steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                <div className={classes.stepContent}>
                                    <Typography>{this.getStepDescription(index)}</Typography>
                                    <div className={classes.actionsContainer}>
                                        {this.getStepActions(index)}
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </div>
        )
    }

    // Render the right hand side content
    renderPage() {
        let content = "";

        if( 0 === this.state.activeStep ) {
            content = this.renderPage_Empty();
        } else if( 1 === this.state.activeStep ) {
            content = this.renderPage_CountryRegion();
        } else if( 2 === this.state.activeStep ) {
            content = this.renderPage_Review();
        } else if( 3 === this.state.activeStep ) {
            content = this.renderPage_Import();
        }

        return (
            <div className={this.props.classes.page}>
                {content}
            </div>
        )
    }

    // Render page: Empty
    renderPage_Empty() {
        return (
            <div>
                <Typography>{i18n.t("pages.admin.holidays.generate.steps.1.page.text")}</Typography>
            </div>
        )
    }

    // Render page: Country and Region selection
    renderPage_CountryRegion() {

        const listOfCountries = HolidayGenerator.getSupportedCountries();
        const listOfRegions = HolidayGenerator.getSupportedRegions( this.state.country );

        let countryError = false;
        let countryHelperText = "";
        if( this.state.country === "" ) {
            countryError = true;
            countryHelperText = i18n.t("pages.admin.holidays.generate.alert.empty")
        }

        let regionError = false;
        let regionHelperText = "";
        if( false === countryError ) {
            if( this.state.region === "" ) {
                regionError = true;
                regionHelperText = i18n.t("pages.admin.holidays.generate.alert.empty")
            }
        }

        return ( 
            <div>
                <Grid container direction="row" className={this.props.classes.grid} spacing={2} wrap='nowrap' alignItems="baseline" justify="flex-start">
                    <Grid item>
                        <FormControl required className={this.props.classes.formControl} error={countryError}>
                            <InputLabel>{i18n.t("pages.admin.holidays.generate.steps.2.page.labels.country")}</InputLabel>
                            <Select
                                id="generate.country"
                                value={this.state.country}
                                onChange={this.handleChangeCountry}
                                name="generate.country"
                            >
                                <MenuItem value=""><em>{i18n.t("values.none")}</em></MenuItem>
                                {listOfCountries.map((entry) => (
                                    <MenuItem key={entry.id} value={entry.id}>{entry.name}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{countryHelperText}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl required className={this.props.classes.formControl} error={regionError}>
                            <InputLabel>{i18n.t("pages.admin.holidays.generate.steps.2.page.labels.region")}</InputLabel>
                            <Select
                                id="generate.region"
                                value={this.state.region}
                                onChange={this.handleChangeRegion}
                                name="generate.region"
                                disabled={"" === this.state.country}
                            >
                                <MenuItem value=""><em>{i18n.t("values.none")}</em></MenuItem>
                                {listOfRegions.map((entry) => (
                                    <MenuItem key={entry.id} value={entry.id}>{entry.name}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{regionHelperText}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </div> 
        );
    }

    // Render page contents for review of generated holidays
    renderPage_Review() {

        // Gather data for lists to display
        let leftListData = [];
        let rightListData = [];

        if( null != this.state.importHolidaysNotSelected ) {
            leftListData = this.state.importHolidaysNotSelected;
        } else {

            // Generate the holidays
            const holidays = HolidayGenerator.generateHolidays( this.state.country, this.state.region, this.props.year );
            for( let i = 0; i < holidays.length; i++ ) {
                leftListData.push({ id: holidays[i].name, date: holidays[i].dateString, name: holidays[i].translate('de') });
            }
        }

        if( null != this.state.importHolidaysSelected ) {
            rightListData = this.state.importHolidaysSelected;
        }

        leftListData.sort(function(a, b) { return a.date > b.date ? 1 : -1; });
        rightListData.sort(function(a, b) { return a.date > b.date ? 1 : -1; });
        
        return ( 
            <div>
                <TransferList
                    leftListHeader={i18n.t("pages.admin.holidays.generate.steps.3.page.headers.left")}
                    leftListData={leftListData}
                    rightListHeader={i18n.t("pages.admin.holidays.generate.steps.3.page.headers.right")}
                    rightListData={rightListData}
                    listDataChanged={this.handleListDataChanged}
                />
            </div> 
        );
    }

    // Render page for import options
    renderPage_Import() {

        return ( 
            <div>
                <Grid container direction="column" className={this.props.classes.grid} spacing={2} wrap='nowrap' alignItems="baseline" justify="flex-start">
                    <Grid item>
                        <Typography>
                            {i18n.t("pages.admin.holidays.generate.steps.4.page.text", { amount: this.state.importHolidaysSelected.length, year: this.props.year })}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.checkClearAll}
                                    onChange={this.handleClearAllChanged}
                                    color="primary"
                                />
                            }
                            label={i18n.t("pages.admin.holidays.generate.steps.4.page.options.clear", { year: this.props.year })}
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.checkOverwrite}
                                    onChange={this.handleOverwriteChanged}
                                    color="primary"
                                />
                            }
                            label={i18n.t("pages.admin.holidays.generate.steps.4.page.options.overwrite", { year: this.props.year })}
                        />
                    </Grid>
                </Grid>
            </div> 
        );
    }

    /**
     * GENERAL RENDER FUNCTION
     */

    render() {
        let contentStepper = this.renderStepper();
        let contentPage = this.renderPage();

        return (
            <div>
                <CalendarHeader
                    year={this.props.year}
                    onYearChanged={this.handleYearChanged}
                />
                <Paper className={this.props.classes.paper}>
                    <Grid container direction="row" className={this.props.classes.grid} spacing={0} wrap='nowrap' alignItems="baseline" justify="flex-start">
                        <Grid item>
                            {contentStepper}
                        </Grid>
                        <Grid item>
                            {contentPage}
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
};

export default withStyles(useStyles)(HolidaysGenerate);