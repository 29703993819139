import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { config } from '../config';
import { StyledTableRow } from '../components/StyledTable.js'
import { invertColorMoreContrast } from '../helpers.js';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import i18n from 'i18next';

const useStyles = theme => ({
    tableGridContainer: {
        display: 'flex',
        width: "100%"
    },
    tableGridItem: {
        width: "inherit"
    },
    tableContainer: {
        width: "100%",
        marginTop: theme.spacing(1),
        padding: theme.spacing(1)
    },
    table: {
        tableLayout: "fixed"
    },
    rowSpecial: {
        borderColor: "#e1e1e1",
        borderStyle: "solid",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderTopWidth: "1px",
        borderBottomWidth: "1px",
    },
    cellShortName: {
        width: "40px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    cellFullName: {
        fontWeight: 500,
        fontSize: "12px",
        width: "100px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    boxAbsent: {
        width: "inherit",
        fontWeight: 500,
        fontSize: "12px",
    }
})

// Draw a special legend to be displayed to the user
const SpecialLegend = React.memo((props) => {

    // Distribute special data to tables
    const maxAmountOfTableRows = 5;

    let tablesData = [];
    let tableId = 0;
    if( props.specials.length > 0 ) {
        tablesData.push( { id: tableId, data: [] } );

        // Sort the given specials by short_name
        let specials = props.specials;
        specials.sort(function(a, b) { return a.short_name > b.short_name ? 1 : -1; });

        for( let i = 0; i < specials.length; i++ ) {
            if( tablesData[tableId].data.length < maxAmountOfTableRows ) {
                tablesData[tableId].data.push( specials[i] );
            } else {
                tableId = tableId+1;
                tablesData.push( { id: tableId, data: [] } );
                tablesData[tableId].data.push( specials[i] );
            }
        }

        // At the end of the table we add the special "hidden" absence which shows up if the user has no access to see the absence
        let hiddenSpecial = config.specialIfHidden;
        hiddenSpecial.name = i18n.t("values.hidden");
        tablesData[tableId].data.push( hiddenSpecial );
    }

    return (
        <Grid container direction="row" className={props.classes.tableGridContainer} spacing={2} wrap='nowrap' justify="flex-start" alignItems="flex-start">
            {tablesData.map((table, tableIndex) => {
                return (
                    <Grid item className={props.classes.tableGridItem} key={"speciallegend-"+tableIndex+"-griditem"}>
                        <TableContainer key={"speciallegend-"+tableIndex+"-container"} component={Paper} className={props.classes.tableContainer}>
                            <Table key={"speciallegend-"+tableIndex+"-table"} className={props.classes.table}>
                                <TableBody key={"speciallegend-"+tableIndex+"-body"}>
                                    {table.data.map((special, dataIndex) => {
                                        const textColor = invertColorMoreContrast( special.color_code, true );

                                        return (
                                            <StyledTableRow key={"speciallegend-"+tableIndex+"-"+dataIndex} >
                                                <TableCell key={"speciallegend-"+tableIndex+"-"+dataIndex+"-shortname"} align="center" className={props.classes.cellShortName}>
                                                    <Paper
                                                        key={"speciallegend-"+tableIndex+"-"+dataIndex+"-paper"}
                                                        elevation={3}
                                                        square={true} 
                                                        className={props.classes.boxAbsent} 
                                                        style={{ backgroundColor: `#${special.color_code}`, color: `#${textColor}` }}
                                                    >
                                                        {special.short_name}
                                                    </Paper>
                                                </TableCell>
                                                <TableCell key={"speciallegend-"+tableIndex+"-"+dataIndex+"-name"} align="left" className={props.classes.cellFullName}>
                                                    {special.name}
                                                </TableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )
            })}
        </Grid>
    )
});

export default withStyles(useStyles)(SpecialLegend);