import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { getDateLocale } from '../config.js';
import { toJSONLocalDate, removeTimeFromDate, toDateObject, createDateOnly } from '../helpers.js';

const useStyles = theme => ({
    datePickerPaper: {
        margin: "0px",
        padding: "0px"
    },
    helperTextBox: {
        marginLeft: "14px",
        minHeight: "18px"
    },
    helperText: {
        color: "#ff0000",
        fontSize: "0.75rem"
    },
    specialDateWrapperNotSelected: {
        background: "#88bbdf",
        borderRadius: "10%",
        marginLeft: "2px",
        marginRight: "2px",
        marginTop: "0px",
        marginBottom: "0px",
        width: "36px",
        height: "36px",
    },
    specialDateWrapperSelected: {
        background: theme.palette.primary.main,
        borderRadius: "10%",
        marginLeft: "2px",
        marginRight: "2px",
        marginTop: "0px",
        marginBottom: "0px",
        width: "36px",
        height: "36px",
    },
    specialDateButtonNotSelected: {
        width: "36px",
        height: "36px",
        fontWeight: 500,
        fontSize: "0.875rem",
        color: "#ffffff",
        borderRadius: "10%",
    },
    invalidDateWrapperNotSelected: {
        background: "#ff0000",
        borderRadius: "10%",
        marginLeft: "2px",
        marginRight: "2px",
        marginTop: "0px",
        marginBottom: "0px",
        width: "36px",
        height: "36px",
    },
    invalidDateWrapperSelected: {
        background: "#b00000",
        borderRadius: "10%",
        marginLeft: "2px",
        marginRight: "2px",
        marginTop: "0px",
        marginBottom: "0px",
        width: "36px",
        height: "36px",
    },
    dateButton: {
        width: "36px",
        height: "36px",
        fontWeight: 500,
        fontSize: "0.875rem",
        color: "#ffffff",
        borderRadius: "10%",
    }
});

/**
 * Modal Dialog Date Picker Component
 */
class ModalDialogDatePicker extends Component {
    state = {
        value: removeTimeFromDate( new Date() ),
        contentVerified: false,
        helperText: ""
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind( this );
        this.renderDay = this.renderDay.bind( this );
        this.verifyDate = this.verifyDate.bind( this );

        // Remember the initial value
        if( null != this.props.value ) {
            this.state.initialValue = this.props.value;
        }
    }

    // Determine the button state
    getButtonState( date, contentVerified ) {
        if( date !== null && true === contentVerified ) {
            return true;
        } else {
            return false;
        }
    }

    // Verify date object
    verifyDate( date ) {
        let dateString = toJSONLocalDate( date );

        // If we got a special allowed date from the parent, check against that first
        if( null != this.props.allowedDate ) {
            if( dateString === this.props.allowedDate ) {
                return { state: true, msg: "" };
            }
        }

        if( null != this.props.verifyContent ) {
            return this.props.verifyContent({ date: dateString });
        }

        return { state: true, msg: "" };
    }

    // General push of new data to parent
    updateControlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent ) {
        if( null != this.props.controlButtonsAndPushContent ) {
            this.props.controlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent );
        }
    }

    // Name text field changed
    handleChange( date ) {

        // Given date has current time, we do not care about that
        let dateOnly = removeTimeFromDate( date );

        // Verify the new date
        const { state, msg } = this.verifyDate( dateOnly );

        const buttonState = this.getButtonState( dateOnly, state );
        this.updateControlButtonsAndPushContent( buttonState, true, { value: toJSONLocalDate( dateOnly ) } );
        this.setState({ value: dateOnly, contentVerified: state, helperText: msg });
    }

    // Check if a day is in the blocker list
    isBlocked( date ) {
        let result = false;

        if( null != this.props.blockedDates ) {
            let dateString = toJSONLocalDate( date );

            // Go through the list of blocked dates
            for( let blockedDate of this.props.blockedDates ) {
                if( blockedDate.date === dateString ) {
                    result = true;
                    break;
                }
            }
        }

        return result;
    }

    // Custom day renderer
    renderDay( day, selectedDate, isInCurrentMonth, dayComponent ) {

        // Color the date red if the date to be rendered is the selectedDate and its not valid
        let colorDay = false;
        let divClassName = "";
        let dayClassName = "";

        let dateIsAlreadyBlocked = false;
        if( day.getTime() === selectedDate.getTime() && selectedDate.getTime() === this.state.value.getTime() && false === this.state.contentVerified ) {
            colorDay = true;
            divClassName = this.props.classes.invalidDateWrapperSelected;
            dayClassName = this.props.classes.dateButton;
            dateIsAlreadyBlocked = true;
        } else if( true === this.isBlocked( day ) ) {
            colorDay = true;
            divClassName = this.props.classes.invalidDateWrapperNotSelected;
            dayClassName = this.props.classes.dateButton;
            dateIsAlreadyBlocked = true;
        }

        // Check if the selected day is the special allowed date, if yes do not mark it as red
        if( null != this.props.allowedDate ) {
            let dateString = toJSONLocalDate( day );
            if( dateString === this.props.allowedDate ) {
                if( day.getTime() === selectedDate.getTime() ) {
                    colorDay = true;
                    divClassName = this.props.classes.specialDateWrapperSelected;
                    dayClassName = this.props.classes.dateButton;
                } else {
                    colorDay = true;
                    divClassName = this.props.classes.specialDateWrapperNotSelected;
                    dayClassName = this.props.classes.specialDateButtonNotSelected;
                }
            }
        }

        // Check if we have any range of blocked dates before a certain point
        if( false === dateIsAlreadyBlocked && null != this.props.blockBeforeDate ) {
            const blockBeforeDateTime = createDateOnly( this.props.blockBeforeDate ).getTime();
            if( null != this.props.blockBeforeDateInclusive && true === this.props.blockBeforeDateInclusive ) {
                if( day.getTime() <= blockBeforeDateTime ) {
                    if( day.getTime() === selectedDate.getTime() ) {
                        colorDay = true;
                        divClassName = this.props.classes.invalidDateWrapperSelected;
                        dayClassName = this.props.classes.dateButton;
                    } else {
                        colorDay = true;
                        divClassName = this.props.classes.invalidDateWrapperNotSelected;
                        dayClassName = this.props.classes.dateButton;
                    }
                }
            } else {
                if( day.getTime() < blockBeforeDateTime ) {
                    if( day.getTime() === selectedDate.getTime() ) {
                        colorDay = true;
                        divClassName = this.props.classes.invalidDateWrapperSelected;
                        dayClassName = this.props.classes.dateButton;
                    } else {
                        colorDay = true;
                        divClassName = this.props.classes.invalidDateWrapperNotSelected;
                        dayClassName = this.props.classes.dateButton;
                    }
                }
            }
        }

        // Check if we have any range of blocked dates after a certain point
        if( false === dateIsAlreadyBlocked && null != this.props.blockAfterDate ) {
            const blockAfterDate = createDateOnly( this.props.blockAfterDate ).getTime();
            if( null != this.props.blockAfterDateInclusive && true === this.props.blockAfterDateInclusive ) {
                if( day.getTime() >= blockAfterDate ) {
                    if( day.getTime() === selectedDate.getTime() ) {
                        colorDay = true;
                        divClassName = this.props.classes.invalidDateWrapperSelected;
                        dayClassName = this.props.classes.dateButton;
                    } else {
                        colorDay = true;
                        divClassName = this.props.classes.invalidDateWrapperNotSelected;
                        dayClassName = this.props.classes.dateButton;
                    }
                }
            } else {
                if( day.getTime() > blockAfterDate ) {
                    if( day.getTime() === selectedDate.getTime() ) {
                        colorDay = true;
                        divClassName = this.props.classes.invalidDateWrapperSelected;
                        dayClassName = this.props.classes.dateButton;
                    } else {
                        colorDay = true;
                        divClassName = this.props.classes.invalidDateWrapperNotSelected;
                        dayClassName = this.props.classes.dateButton;
                    }
                }
            }
        }

        // Check if we did not apply any custom color at this point
        if( day.getTime() === selectedDate.getTime() && false === colorDay && true === isInCurrentMonth ) {
            colorDay = true;
            divClassName = this.props.classes.specialDateWrapperSelected;
            dayClassName = this.props.classes.dateButton;
        }

        if( true === colorDay && true === isInCurrentMonth ) {
            return( 
                <div className={divClassName}>
                    <IconButton className={dayClassName}>
                        <span> {day.getDate()} </span>
                    </IconButton>
                </div>
            )
        }

        return dayComponent;
    }

    // Called by react when this component has been mounted
    componentDidMount() {
        let date = removeTimeFromDate( new Date() );

        // Check for an incoming date to use, it will have the format yyyy-mm-dd
        if( null != this.props.value ) {
            date = toDateObject( this.props.value );
        }

        const { state, msg } = this.verifyDate( date );
        const buttonState = this.getButtonState( date, state );
        this.updateControlButtonsAndPushContent( buttonState, true, { value: toJSONLocalDate( date ) } );
        this.setState({ value: date, contentVerified: state, helperText: msg });
    }

    // React render
    render() {
        // Used this.props
        // setLeftButtonState(bool), setRightButtonState(bool), setContent(json), verifyContent(json), label, maxLength, limitToCurrentYear
        let locale = null;
        if( null != this.props.language ) {
            locale = getDateLocale( this.props.language );
        }

        let minDate = new Date( "1900-01-01" );
        let maxDate = new Date( "2100-01-01" );
        if( null !== this.props.limitToCurrentYear && true === this.props.limitToCurrentYear ) {
            let year = this.state.value.getFullYear();
            minDate = new Date( year + "-01-01" );
            maxDate = new Date( year + "-12-31" );
        }

        return (
            <React.Fragment>
                <Paper className={this.props.classes.datePickerPaper}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                        <DatePicker
                            autoOk
                            orientation="landscape"
                            variant="static"
                            openTo="date"
                            disableToolbar={this.props.disableToolbar}
                            value={this.state.value}
                            onChange={this.handleChange}
                            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => this.renderDay(day, selectedDate, isInCurrentMonth, dayComponent)}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                    </MuiPickersUtilsProvider>
                </Paper>
                <Box m={1} className={this.props.classes.helperTextBox}>
                    <Typography className={this.props.classes.helperText} align="left">
                        {this.state.helperText}
                    </Typography>
                </Box>
            </React.Fragment>
        );
    }
};

export default withStyles(useStyles)(ModalDialogDatePicker);