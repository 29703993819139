import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CalendarHelper from '../classes/CalendarHelper.js';
import Chart from "react-apexcharts";
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Chart Project Accounting
 */
class ChartProjectOverview extends Component {
    state = {
        chart: ""
    }

    /// Get x-axis labels
    getLabels( projectReport ) {
        let labels = [];

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "short" );

        if( null != projectReport ) {
            for( let i = 0; i < projectReport.resultProject.years.length; i++ ) {

                const yearString = " '" + projectReport.resultProject.years[i].year.toString().substring( 2, 4 )
                for( let j = 0; j < projectReport.resultProject.years[i].months.length; j++ ) {

                    // Add label for the month
                    labels.push( monthNames[projectReport.resultProject.years[i].months[j].month-1] + yearString );
                }
            }
        }

        return labels;
    }

    /// Get series: accounted hours in month
    getSeriesAccountedHours( projectReport ) {
        let chartSeries = [];

        if( null != projectReport ) {

            let series = {
                name: i18n.t("pages.reports.options.project.chart_overview.series.sum_in_month.name"),
                id: 0,
                type: 'line',
                data: []
            }
            chartSeries.push( series );

            // Add an empty series per task
            for( let i = 0; i < projectReport.resultTasks.length; i++ ) {
                let series = {
                    name: projectReport.resultTasks[i].name,
                    id: projectReport.resultTasks[i].taskid,
                    type: 'column',
                    data: []
                }
                chartSeries.push( series );
            }

            const thisYear = new Date().getFullYear();
            const thisMonth = new Date().getMonth();

            // Handle the general summary of accounted hours series
            let seriesIndex = 0;
            for( let i = 0; i < projectReport.resultProject.years.length; i++ ) {

                for( let j = 0; j < projectReport.resultProject.years[i].months.length; j++ ) {
                    let accountedHours = projectReport.resultProject.years[i].months[j].hours_accounted_in_month;

                    // Only add if the current year and month is not past today's year and the past month
                    // in that case only add an empty string, else that looks awkward into the future
                    if( thisYear > projectReport.resultProject.years[i].year || ( thisYear === projectReport.resultProject.years[i].year && thisMonth >= j )) {
                        chartSeries[seriesIndex].data.push( accountedHours );
                    } else {
                        chartSeries[seriesIndex].data.push( 0 );
                    }
                }
            }
            seriesIndex++;

            // Handle the series for each task
            for( let i = 0; i < projectReport.resultTasks.length; i++ ) {
                // Go through all years for this task
                for( let j = 0; j < projectReport.resultTasks[i].years.length; j++ ) {

                    for( let k = 0; k < projectReport.resultTasks[i].years[j].months.length; k++ ) {
                        let accountedHours = projectReport.resultTasks[i].years[j].months[k].hours_accounted_in_month;

                        // Only add if the current year and month is not past today's year and the past month
                        // in that case only add an empty string, else that looks awkward into the future
                        if( thisYear > projectReport.resultTasks[i].years[j].year || ( thisYear === projectReport.resultTasks[i].years[j].year && thisMonth >= j )) {
                            chartSeries[seriesIndex].data.push( accountedHours );
                        } else {
                            chartSeries[seriesIndex].data.push( 0 );
                        }
                    }
                }
                seriesIndex++;
            }
        }

        return chartSeries;
    }

    /// Generate the year start annotations
    getYearAnnotations( projectReport ) {
        let annotations = [];

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "short" );

        if( null != projectReport ) {
            for( let i = 0; i < projectReport.resultProject.years.length; i++ ) {
                const year = projectReport.resultProject.years[i].year;
                const yearCut = year.toString().substring(2, 4);
                const januaryLabel = monthNames[0] + " '" + yearCut;

                annotations.push(
                    {
                        x: januaryLabel,
                        borderColor: '#FF4560',
                        label: {
                            style: {
                                color: '#000',
                            },
                            orientation: 'vertical',
                            text: year.toString()
                        }
                    }
                )
            }
        }

        return annotations;
    }

    // Called by react when this component has been mounted
    componentDidMount() {

        // Pre-render chart since it will not change
        if( null !== this.props.projectReport && null !== this.props.dateMin && null !== this.props.dateMax ) {
            let accountingChart = this.renderAccountingChart( this.props.projectReport );
            this.setState({ chart: accountingChart });
        }
    }

    // Called by react when this component did update
    componentDidUpdate( prevProps ) {

        if( this.props.dateMin !== prevProps.dateMin || this.props.dateMax !== prevProps.dateMax ) {
            // Pre-render chart since it will not change
            if( null !== this.props.projectReport && null !== this.props.dateMin && null !== this.props.dateMax ) {
                let accountingChart = this.renderAccountingChart( this.props.projectReport );
                this.setState({ chart: accountingChart });
            }
        }
    }

    // Render
    renderAccountingChart( projectReport ) {

        const chartLabels = this.getLabels( projectReport );
        let chartSeries = this.getSeriesAccountedHours( projectReport );
        const yearAnnotations = this.getYearAnnotations( projectReport );

        const chartOptions = {
            chart: {
                animations: {
                    enabled: false
                },
                type: 'line',
                id: 'absence-chart',
                toolbar: {
                    tools: {
                        download: false,
                        pan: false
                    }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                }
            },
            annotations: {
                yaxis: [],
                xaxis: yearAnnotations,
                points: []
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: 'true',
                width: 2,
            },
            grid: {},
            title: {},
            labels: chartLabels,
            xaxis: {},
            yaxis: {
                min: 0,
                axisBorder: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0
                },
                title: {
                    text: i18n.t("pages.reports.options.project.chart_overview.yaxis.label"),
                    rotate: -90,
                    style: {
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: '12px',
                        fontWeight: 100
                    }
                }
            },
            tooltip: {
                shared: true
            }
        }

        return (
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="line"
            />
        )
    }

    render() {
        return (
            <div>
                {this.state.chart}
            </div>
        );
    }
};

export default withStyles(useStyles)(ChartProjectOverview);