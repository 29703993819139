import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CalendarHelper from '../classes/CalendarHelper.js';
import { StyledDataTable } from '../components/StyledTable.js'
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Table Accounting
 */
class TableAccounting extends Component {
    state = {}

    // Add to table data array
    addTableData( name, value ) {
        return { name, value };
    }

    // Get user summary data for given year
    getSummary( accountingReport, year ) {

        // Oldest year is at the beginning of the array
        let overtimeFromLastYear = 0;
        let overtimeThisYear = 0;
        let hoursAdjusted = 0;

        if( null != accountingReport ) {
            const thisYear = new Date().getFullYear();
            const thisMonth = new Date().getMonth()

            for( let i = 0; i < accountingReport.years.length; i++ ) {
                if( year > accountingReport.years[i].year.toString() ) {
                    overtimeFromLastYear += -1.0*accountingReport.years[i].hours_remaining_in_year;

                } else if( year === accountingReport.years[i].year.toString() ) {
                    hoursAdjusted = accountingReport.years[i].hours_adjusted_in_year;

                    for( let j = 0; j < accountingReport.years[i].months.length; j++ ) {
                        // Only calculate past months here in this year
                        if( thisYear > accountingReport.years[i].year || ( thisYear === accountingReport.years[i].year && thisMonth > j ) ) {
                            overtimeThisYear += -1.0*accountingReport.years[i].months[j].hours_remaining_in_month;
                        }
                    }
                    break;
                }
            }
        }
        
        return {
            overtimeFromLastYear: overtimeFromLastYear,
            overtimeThisYear: overtimeThisYear,
            hoursAdjusted: hoursAdjusted
        }
    }

    getSeriesTargetHours( accountingReport, year ) {
        let series = [];

        if( null != accountingReport ) {
            for( let i = 0; i < accountingReport.years.length; i++ ) {
                // Only return years based on selection
                if( year === "" || year === accountingReport.years[i].year.toString() ) {
                    for( let j = 0; j < accountingReport.years[i].months.length; j++ ) {
                        series.push( accountingReport.years[i].months[j].hours_target_in_month );
                    }
                }
            }
        }

        return series;
    }

    getSeriesAccountedHours( accountingReport, year ) {
        let series = [];

        if( null != accountingReport ) {           
            for( let i = 0; i < accountingReport.years.length; i++ ) {
                // Only return years based on selection
                if( year === "" || year === accountingReport.years[i].year.toString() ) {
                    for( let j = 0; j < accountingReport.years[i].months.length; j++ ) {
                        series.push( accountingReport.years[i].months[j].hours_accounted_in_month );
                    }
                }
            }
        }

        return series;
    }

    getSeriesOvertimeHours( accountingReport, year ) {
        let series = [];

        if( null != accountingReport ) {
            const thisYear = new Date().getFullYear();
            const thisMonth = new Date().getMonth();

            for( let i = 0; i < accountingReport.years.length; i++ ) {
                // Only return years based on selection
                if( year === "" || year === accountingReport.years[i].year.toString() ) {
                    for( let j = 0; j < accountingReport.years[i].months.length; j++ ) {

                        // Only add if the current year and month is not past today's year and the past month
                        // in that case only add an empty string, else that looks awkward into the future
                        if( thisYear > accountingReport.years[i].year || ( thisYear === accountingReport.years[i].year && thisMonth > j )) {
                            series.push( -1.0*accountingReport.years[i].months[j].hours_remaining_in_month );
                        } else {
                            series.push( "" );
                        }
                    }
                }
            }
        }

        return series;
    }

    getSeriesAdjustedHours( accountingReport, year ) {
        let series = [];

        if( null != accountingReport ) {
            for( let i = 0; i < accountingReport.years.length; i++ ) {
                // Only return years based on selection
                if( year === "" || year === accountingReport.years[i].year.toString() ) {
                    for( let j = 0; j < accountingReport.years[i].months.length; j++ ) {
                        series.push( accountingReport.years[i].months[j].hours_adjusted_in_month );
                    }
                }
            }
        }

        return series;
    }

    // Render accounting summary
    renderAccountingSummary( accountingReport, year ) {

        // If not specific year is selected, show a warning in this table since it does not make sense
        let tableHeader = [];
        let tableData = [];

        const thisYear = new Date().getFullYear();
        if( year !== "" && year <= thisYear.toString() ) {
            tableHeader = [
                { id: 1, width: "50%", name: i18n.t("pages.reports.options.accounting.summary.headline_summary") },
                { id: 2, width: "50%", name: i18n.t("pages.reports.options.accounting.summary.headline_hours") }
            ];

            const summary = this.getSummary( accountingReport, year );

            tableData.push({ key: i18n.t("pages.reports.options.accounting.summary.from_last_year", { year: this.props.year-1 }), values: [ summary.overtimeFromLastYear ] });
            tableData.push({ key: i18n.t("pages.reports.options.accounting.summary.hours_adjusted", { year: this.props.year }), values: [ summary.hoursAdjusted ] });

            if( year === new Date().getFullYear().toString() ) {
                tableData.push({ key: i18n.t("pages.reports.options.accounting.summary.overtime_this_year", { year: this.props.year }), values: [ summary.overtimeThisYear ] });
                tableData.push({ key: i18n.t("pages.reports.options.accounting.summary.total", { year: this.props.year }), values: [ summary.overtimeFromLastYear + summary.overtimeThisYear ] });
            } else {
                tableData.push({ key: i18n.t("pages.reports.options.accounting.summary.overtime_end_of_year", { year: this.props.year }), values: [ summary.overtimeThisYear ] });
            }

        } else if( year !== "" && year > thisYear.toString() ) {
            tableHeader = [
                { id: 1, width: "50%", name: i18n.t("pages.reports.options.accounting.summary.headline_summary") },
                { id: 2, width: "50%", name: "" }
            ];
            tableData.push({ key: i18n.t("pages.reports.options.accounting.summary.warning_future_year"), values: [ "" ] });
        } else {
            tableHeader = [
                { id: 1, width: "50%", name: i18n.t("pages.reports.options.accounting.summary.headline_summary") },
                { id: 2, width: "50%", name: "" }
            ];
            tableData.push({ key: i18n.t("pages.reports.options.accounting.summary.warning_select_year"), values: [ "" ] });
        }

        let table = (
            <StyledDataTable 
                tableKey="accounting-table-summary" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // Render accounting table
    renderAccountingTable( accountingReport, year ) {

        // If no specific year is given, the table makes no sence
        const thisYear = new Date().getFullYear();
        if( year === "" || year > thisYear ) {
            return "";
        }

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "long" );
        const seriesTargetHours = this.getSeriesTargetHours( accountingReport, year );
        const seriesAccountedHours = this.getSeriesAccountedHours( accountingReport, year );
        const seriesOvertimeHours = this.getSeriesOvertimeHours( accountingReport, year );
        const seriesAdjustedHours = this.getSeriesAdjustedHours( accountingReport, year );

        // Assemble table content
        const tableHeader = [
            { id: 1, width: "20%", name: i18n.t("pages.reports.options.accounting.table.month") },
            { id: 2, width: "20%", name: i18n.t("pages.reports.options.accounting.table.target_hours") },
            { id: 3, width: "20%", name: i18n.t("pages.reports.options.accounting.table.accounted_hours") },
            { id: 4, width: "20%", name: i18n.t("pages.reports.options.accounting.table.adjusted_hours") },
            { id: 5, width: "20%", name: i18n.t("pages.reports.options.accounting.table.overtime_hours") }
        ];

        let tableData = [];

        for( let i = 0; i < monthNames.length; i++ ) {
            let tableRow = {
                key: monthNames[i],
                values: [
                    seriesTargetHours[i],
                    seriesAccountedHours[i],
                    seriesAdjustedHours[i],
                    seriesOvertimeHours[i]
                ]
            }
            tableData.push(tableRow);
        }

        let table = (
            <StyledDataTable 
                tableKey="accounting-table-detail" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // Takes props: language, accountingReport
    render() {
        let accountingSummary = "";
        let accountingTable = "";
        if( null != this.props.accountingReport && null != this.props.year ) {
            accountingSummary = this.renderAccountingSummary( this.props.accountingReport, this.props.year );
            accountingTable = this.renderAccountingTable( this.props.accountingReport, this.props.year );
        }

        return (
            <div>
                {accountingSummary}
                <br />
                {accountingTable}
            </div>
        );
    }
};

export default withStyles(useStyles)(TableAccounting);