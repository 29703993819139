import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppMain from './AppMain';
import AuthService from './classes/AuthService';

/**
 * First level App component which gets rendered
 */
class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <React.Fragment>
                    <AuthService>
                        <AppMain />
                    </AuthService>
                </React.Fragment>
            </BrowserRouter>
        );
    }
}

export default App;