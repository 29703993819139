import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import i18n from 'i18next';
import clsx from 'clsx';

const WeekDayTypography = styled(Typography)`
    font-size: 11px;
    color: #248EDB;
    font-weight: bold;
`;

const useStyles = theme => ({
    mainGrid: {
        display: 'flex',
        height: '40px',
        margin: "0",
        padding: "0",
        width: "140px"
    },
    dayGrid: {
        display: 'flex',
        width: '20px',
        margin: "0",
    },
    checkbox: {
        margin: "0",
        padding: "0"
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.table.row.primary,
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            background: theme.palette.table.row.primary,
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.primary.main,
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.primary.main
        },
        'input:disabled ~ &': {
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            background: theme.palette.primary.main,
        },
    },
});

/**
 * Select and display work week input
 */
class SelectWorkWeek extends Component {
    state = {
        nameDays: [],
        checkedDays: [],
        disabledDays: []
    }

    constructor(props) {
        super(props);
        this.handleCheckBoxClick = this.handleCheckBoxClick.bind( this );

        // Default settings
        this.state.nameDays = [
            i18n.t("days.tiny.monday"),
            i18n.t("days.tiny.tuesday"),
            i18n.t("days.tiny.wednesday"),
            i18n.t("days.tiny.thursday"),
            i18n.t("days.tiny.friday"),
            i18n.t("days.tiny.saturday"),
            i18n.t("days.tiny.sunday")
        ]
        this.state.checkedDays = [ false, false, false, false, false, false, false ];
        this.state.disabledDays = [ false, false, false, false, false, false, false ];

        // Grab from input props
        if( null != this.props.size ) {
            this.state.nameDays = [
                i18n.t(`days.${this.props.size}.monday`),
                i18n.t(`days.${this.props.size}.tuesday`),
                i18n.t(`days.${this.props.size}.wednesday`),
                i18n.t(`days.${this.props.size}.thursday`),
                i18n.t(`days.${this.props.size}.friday`),
                i18n.t(`days.${this.props.size}.saturday`),
                i18n.t(`days.${this.props.size}.sunday`),
            ]
        }

        if( null != this.props.checkedDays ) {
            for( let i = 0; i < this.props.checkedDays.length; i++ ) {
                this.state.checkedDays[i] = this.props.checkedDays[i];
            }
        }

        if( null != this.props.checkedDaysAsString ) {
            for( let i = 0; i < this.props.checkedDaysAsString.length; i++ ) {
                if( this.props.checkedDaysAsString[i] === "1" ) {
                    this.state.checkedDays[i] = true;
                }
            }
        }

        if( null != this.props.disabledDays ) {
            for( let i = 0; i < this.props.disabledDays.length; i++ ) {
                this.state.disabledDays[i] = this.props.disabledDays[i];
            }
        }

        if( null != this.props.allDaysDisabled ) {
            if( true === this.props.allDaysDisabled ) {
                this.state.disabledDays = [ true, true, true, true, true, true, true ];
            }
        }
    }

    // Called when non-read-only checkbox is clicked
    handleCheckBoxClick( event, index ) {
        let checkedDays = this.state.checkedDays;
        checkedDays[index] = event.target.checked;

        // Also create a string representation
        let checkedDaysAsString = "";
        for( let i = 0; i < checkedDays.length; i++ ) {
            if( true === checkedDays[i] ) {
                checkedDaysAsString += "1";
            } else {
                checkedDaysAsString += "0";
            }
        }
        
        if( null != this.props.onChange ) {
            this.props.onChange( index, checkedDays, checkedDaysAsString );
        }

        this.setState({ checkedDays: checkedDays });
    }

    // Main render function
    render() {
        const { classes } = this.props;

        return (
            <Grid container direction="row" className={classes.mainGrid} spacing={0} wrap='nowrap' justify="flex-start" alignItems="flex-start">
                {this.state.nameDays.map((day, i) => (
                    <Grid item container key={"weekday-grid-"+i} className={classes.dayGrid} direction="column" justify="flex-start" alignItems="center" spacing={0}>
                        <Grid item>
                            <WeekDayTypography key={"weekday-name-"+i} align="center">
                                {day}
                            </WeekDayTypography>
                        </Grid>
                        <Grid item>
                            <Checkbox
                                className={classes.checkbox}
                                size="small"
                                color="primary"
                                checked={this.state.checkedDays[i]}
                                disabled={this.state.disabledDays[i]}
                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                icon={<span className={classes.icon} />}
                                onChange={(event) => this.handleCheckBoxClick(event, i)}
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        )
    }
};

export default withStyles(useStyles)(SelectWorkWeek);