import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { invertColorMoreContrast } from '../helpers.js';
import CalendarHelper from '../classes/CalendarHelper.js';
import { config } from '../config';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import clsx from  'clsx';
import i18n from 'i18next';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const useStyles = theme => ({
    container: {
        padding: theme.spacing(1)
    },
    table: {
        width: "100%",
        tableLayout: "fixed"
    },
    cellMonth: {
        fontWeight: 500,
        fontSize: "20px",
        width: "60px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    cellDayBase: {
        verticalAlign: "top",
        height: "45px",
        width: "44px",
        color: "#656565",
        borderColor: "#e1e1e1",
        borderStyle: "solid",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderTopWidth: "1px",
        borderBottomWidth: "1px",
        padding: "0px",
        paddingTop: "4px",
        margin: "0px",
        userSelect: "none"
    },
    cellTodayWeekDay: {
        color: "#ff0000",
        borderColor: "#ff0000",
        borderStyle: "solid",
        borderLeftWidth: "2px",
        borderRightWidth: "2px",
        borderTopWidth: "2px",
        borderBottomWidth: "2px"
    },
    cellTodayWeekEnd: {
        color: "#ff0000",
        borderColor: "#ff0000",
        borderStyle: "solid",
        borderLeftWidth: "2px",
        borderRightWidth: "2px",
        borderTopWidth: "2px",
        borderBottomWidth: "2px"
    },
    cellWeekEnd: {
        backgroundColor: "#d9d9d9",
    },
    cellWeekDay: {
        backgroundColor: "#ffffff",
    },
    cellTodayHoliday: {
        color: "#656565",
        backgroundColor: "#fde1fc",
        borderColor: "#ff0000",
        borderStyle: "solid",
        borderLeftWidth: "2px",
        borderRightWidth: "2px",
        borderTopWidth: "2px",
        borderBottomWidth: "2px"
    },
    cellHoliday: {
        color: "#656565",
        backgroundColor: "#fde1fc",
    },
    cellNoWorkDayWeekEnd: {
        backgroundColor: "#d9d9d9",
    },
    cellNoWorkDayWeekDay: {
        color: "#ffffff",
        backgroundColor: "#a0a0a0",
    },
    cellSelected: {
        backgroundColor: "#FACC2E",
    },
    boxAbsent: {
        width: "inherit",
        height: "15px",
        fontSize: "11px",
        fontWeight: 900
    },
    selectionTooltip: {
        fontWeight: 500,
        fontSize: "12px",
        padding: theme.spacing(1),
        backgroundColor: "#616161",
        color: "#ffffff"
    }
});

/**
 * Absence My Calendar View
 */
class AbsenceMyCalendar extends Component {
    state = {
        months: [],
        mouseIsDown: false,
        selectedDateRange: {
            hasSelection: false,
            start: null,
            end: null,
            prevDate: null,
            hasAbsence: false,
            amountOfSelectedWorkDays: 0
        }
    }

    constructor(props) {
        super(props);

        // Bind functions
        this.renderTableCell = this.renderTableCell.bind( this );
        this.renderCalendar = this.renderCalendar.bind( this );
        this.handleMouseEnter = this.handleMouseEnter.bind( this );
        this.handleMouseDown = this.handleMouseDown.bind( this );
        this.handleMouseUp = this.handleMouseUp.bind( this );
        this.getSpecialDetails = this.getSpecialDetails.bind( this );
        this.isDateSelected = this.isDateSelected.bind( this );
        this.startDateSelection = this.startDateSelection.bind( this );
        this.continueDateSelection = this.continueDateSelection.bind( this );
        this.clearDateSelection = this.clearDateSelection.bind( this );

        // Populate array of month names
        this.state.months = CalendarHelper.getMonthNames( this.props.language, "short" );
    }

    // Get the amount of work days within the given date range
    getAmountOfWorkDays( startDate, endDate ) {
        if( null == this.props.contracts ) {
            return 0;
        }

        const dateRange = moment.range( startDate, endDate );

        // Iterate through date range
        let amountOfWorkDays = 0;
        
        for( let day of dateRange.by('day') ) {
            const dateObject = day.toDate();
            const holiday = CalendarHelper.isHoliday( this.props.holidays, dateObject );
            const isWorkDay = CalendarHelper.isWorkDay( this.props.contracts, null, dateObject );
            if( false === holiday.state && true === isWorkDay ) {
                amountOfWorkDays++;
            }
        }

        return amountOfWorkDays;
    }

    // Get details for a special
    getSpecialDetails( specialid ) {
        if( null == this.props.specials ) {
            return { name: null, short_name: null, color_code: null };
        }

        // Handle special case for specialid zero which occurs if the user is not allowed to see the respective absence
        if( 0 === specialid ) {
            let hiddenSpecial = config.specialIfHidden;
            hiddenSpecial.name = i18n.t("values.hidden");
            return hiddenSpecial;
        }

        for( let i = 0; i < this.props.specials.length; i++ ) {
            if( this.props.specials[i].specialid === specialid ) {
                return { name: this.props.specials[i].name, short_name: this.props.specials[i].short_name, color_code: this.props.specials[i].color_code };
            }
        }

        return { name: null, short_name: null, color_code: null };
    }

    // Check if given date is selected
    isDateSelected( date ) {
    
        if( true === this.state.selectedDateRange.hasSelection ) {
            if( date >= this.state.selectedDateRange.start && date <= this.state.selectedDateRange.end ) {
                return true;
            }
        }
        return false;
    }

    // Start a date selection
    startDateSelection( month, day, mouseIsDown, hasAbsence ) {
        const date = new Date( this.props.year, month-1, day ).getTime();

        let selectedDateRange = {
            hasSelection: true,
            start: date,
            end: date,
            prevDate: date,
            hasAbsence: hasAbsence,
            amountOfSelectedWorkDays: this.getAmountOfWorkDays( date, date )
        }

        this.setState({ mouseIsDown: mouseIsDown, selectedDateRange: selectedDateRange });
    }

    // Continue the started date selection
    continueDateSelection( month, day, hasAbsence ) {
        // Make sure start date is always lower than end date in the struct
        if( true === this.state.selectedDateRange.hasSelection ) {
            let newStart = this.state.selectedDateRange.start;
            let newEnd = this.state.selectedDateRange.end;

            let newDate = new Date( this.props.year, month-1, day ).getTime();
            if( newDate > newEnd ) {
                newEnd = newDate;
            } else if( newDate < newStart ) {
                newStart = newDate;
            } else {
                // New date is inbetween existing start and end, adjust the end or the start
                // depending on where the user dragged from
                if( this.state.selectedDateRange.prevDate > newDate ) {
                    // User dragged from the right to the left
                    newEnd = newDate;
                } else if( this.state.selectedDateRange.prevDate < newDate ) {
                    // User dragged from the left to the right
                    newStart = newDate;
                }
            }

            const hasAnyAbsence = this.state.selectedDateRange.hasAbsence || hasAbsence ;

            let selectedDateRange = {
                hasSelection: true,
                start: newStart,
                end: newEnd,
                prevDate: newDate,
                hasAbsence: hasAnyAbsence,
                amountOfSelectedWorkDays: this.getAmountOfWorkDays( newStart, newEnd )
            }
            this.setState({ selectedDateRange: selectedDateRange });
        }
    }

    // Clear date selection
    clearDateSelection() {
        let selectedDateRange = {
            hasSelection: false,
            start: null,
            end: null,
            prevDate: null,
            hasAbsence: false,
            amountOfSelectedWorkDays: 0
        }
        this.setState({ mouseIsDown: false, selectedDateRange: selectedDateRange });
    }

    // Mouse hold down on cell
    handleMouseDown( event, month, day, hasAbsence ) {
        event.stopPropagation();
        this.startDateSelection( month, day, true, hasAbsence );
    }

    // Mouse release on cell
    handleMouseUp( event, month, day, hasAbsence ) {
        event.stopPropagation();
        if( true === this.state.selectedDateRange.hasSelection && null != this.props.onModifyAbsence ) {
            this.props.onModifyAbsence( 
                this.state.selectedDateRange.start, 
                this.state.selectedDateRange.end, 
                this.state.selectedDateRange.hasAbsence
            );
        }
        this.clearDateSelection();
    }

    // Mouse entering a cell
    handleMouseEnter( event, month, day, hasAbsence ) {
        event.stopPropagation();
        if( true === this.state.mouseIsDown ) {
            this.continueDateSelection( month, day, hasAbsence );
        }
    }

    // Render a table day cell
    renderTableCell( monthIndex, dayIndex, isWeekEnd, isToday, content ) {

        // Check if day is selected
        const dateObject = new Date( this.props.year, monthIndex, dayIndex+1 );
        const date = dateObject.getTime();

        const isDateSelected = this.isDateSelected( date );
        const holiday = CalendarHelper.isHoliday( this.props.holidays, dateObject );
        const absent = CalendarHelper.isAbsent( this.props.absences, null, dateObject );
        const isWorkDay = CalendarHelper.isWorkDay( this.props.contracts, null, dateObject );

        let className = "";
        let cellTooltip = "";

        if( true === isToday ) {
            cellTooltip = this.props.labelToday;
        }

        if( true === holiday.state ) {
            if( cellTooltip.length > 0 ) {
                cellTooltip += " - ";
            }
            cellTooltip += holiday.name;
            if( true === isToday ) {
                className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayHoliday);
            } else {
                className = clsx(this.props.classes.cellDayBase, this.props.classes.cellHoliday);
            }
        } else {
            if( true === isWeekEnd ) {
                if( cellTooltip.length > 0 ) {
                    cellTooltip += " - ";
                }
                cellTooltip += this.props.labelWeekend;
                if( true === isToday ) {

                    if( true === isWorkDay ) {                    
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekEnd);
                    } else {
                        if( cellTooltip.length > 0 ) {
                            cellTooltip += " - ";
                        }
                        cellTooltip += this.props.labelNoWorkDay;
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekEnd, this.props.classes.cellNoWorkDayWeekEnd);
                    }
                    
                } else {
                    if( true === isWorkDay ) {
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellWeekEnd);
                    } else {
                        if( cellTooltip.length > 0 ) {
                            cellTooltip += " - ";
                        }
                        cellTooltip += this.props.labelNoWorkDay;
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellNoWorkDayWeekEnd);
                    }
                }
            } else {
                if( true === isToday ) {
                    if( true === isWorkDay ) {
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekDay);
                    } else {
                        if( cellTooltip.length > 0 ) {
                            cellTooltip += " - ";
                        }
                        cellTooltip += this.props.labelNoWorkDay;
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekDay, this.props.classes.cellNoWorkDayWeekDay);
                    }
                    
                } else {
                    if( true === isWorkDay ) {
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellWeekDay);
                    } else {
                        if( cellTooltip.length > 0 ) {
                            cellTooltip += " - ";
                        }
                        cellTooltip += this.props.labelNoWorkDay;
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellNoWorkDayWeekDay);
                    }
                }
            }
        }

        // Change background color of cell depending on selection
        if( true === isDateSelected && false === holiday.state && true === isWorkDay ) {
            className = clsx(this.props.classes.cellDayBase, this.props.classes.cellSelected);
        }

        let absenceBox = "";
        if( true === absent.state ) {
            const specialDetails = this.getSpecialDetails( absent.specialid );
            const textColor = invertColorMoreContrast( specialDetails.color_code, true );
            absenceBox = (
                <Paper 
                    elevation={3}
                    square={true} 
                    className={this.props.classes.boxAbsent} 
                    style={{ backgroundColor: `#${specialDetails.color_code}`, color: `#${textColor}` }}
                >
                    {specialDetails.short_name}
                </Paper>
            )
        }

        let tableCellContent = "";

        if( true === isWorkDay && false === holiday.state ) {
            tableCellContent = (
                <TableCell
                    align="center"
                    key={"cell-date-"+monthIndex+"-"+dayIndex}
                    className={className}
                    onMouseDown={(event) => this.handleMouseDown(event, monthIndex+1, dayIndex+1, absent.state)}
                    onMouseUp={(event) => this.handleMouseUp(event, monthIndex+1, dayIndex+1, absent.state)}
                    onMouseEnter={(event) => this.handleMouseEnter(event, monthIndex+1, dayIndex+1, absent.state)}
                >
                    <div style={{ width: "100%" }}>
                        {content}
                        {absenceBox}
                    </div>
                </TableCell>
            )
        } else {
            tableCellContent = (
                <TableCell
                    align="center"
                    key={"cell-date-"+monthIndex+"-"+dayIndex}
                    className={className}
                    onMouseDown={(event) => this.handleMouseDown(event, monthIndex+1, dayIndex+1, absent.state)}
                    onMouseUp={(event) => this.handleMouseUp(event, monthIndex+1, dayIndex+1, absent.state)}
                    onMouseEnter={(event) => this.handleMouseEnter(event, monthIndex+1, dayIndex+1, absent.state)}
                >
                    <div style={{ width: "100%" }}>
                        {content}
                        {absenceBox}
                    </div>
                </TableCell>
            )
        }

        if( cellTooltip !== "" ) {
            return (
                <Tooltip key={"tooltip-date-"+monthIndex+"-"+dayIndex} title={cellTooltip}>
                    {tableCellContent}
                </Tooltip>
            )
        } else {
            return tableCellContent;
        }
    }

    // Render calendar for year
    renderCalendar( year ) {

        // Get todays' date
        const todayMonth = new Date().getMonth();
        const todayDate = new Date().getDate();
        const todayYear = new Date().getFullYear();

        return (
            <TableContainer component={Paper} className={this.props.classes.container}>
                <Table className={this.props.classes.table}>
                    <TableBody>
                        {this.state.months.map((month, monthIndex) => {

                            // Gather month data
                            const weekDaysInMonth = CalendarHelper.getWeekDaysForMonth( this.props.language, "short", year, monthIndex );

                            return (
                                <TableRow key={monthIndex}>
                                    <TableCell align="left" className={this.props.classes.cellMonth}>
                                        {month}
                                    </TableCell>
                                    {weekDaysInMonth.map((weekDay, dayIndex) => {
                                        let isToday = false;
                                        if( todayYear === year && todayMonth === monthIndex && todayDate === (dayIndex+1) ) {
                                            isToday = true;
                                        }

                                        let isWeekEnd = false;
                                        if( weekDay.index === 0 || weekDay.index === 6 ) {
                                            isWeekEnd = true;
                                        }

                                        return this.renderTableCell( monthIndex, dayIndex, isWeekEnd, isToday, dayIndex+1 );
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    render() {
        // Render the calendar for the given year
        const calendar = this.renderCalendar( this.props.year );

        return (
            <div>
                <MouseTooltip
                    visible={this.state.selectedDateRange.hasSelection}
                    offsetX={15}
                    offsetY={10}
                >
                    <Paper className={this.props.classes.selectionTooltip}>
                        {i18n.t("values.days_selected", { days: this.state.selectedDateRange.amountOfSelectedWorkDays })}
                    </Paper>
                </MouseTooltip>
                {calendar}
                {this.props.children}
            </div>
        );
    }
};

export default withStyles(useStyles)(AbsenceMyCalendar);