import React from 'react';

/**
 * Main auth context object
 * The AuthProvider of this object is the AuthService component
 */
export default React.createContext({
    services: null,
    userid: null,
    language: null,
    email: null,
    accessTokenExpiresIn: null,
    refreshTokenExpiresIn: null,
    passwordChangeOnNextLogin: null,
    config: null,
    errorMessage: '',
    successMessage: '',
    forgotPassword: (email) => {},
    resetPassword: (token, password) => {},
    updatePassword: (password) => {},
    login: (email, password) => {},
    refresh: () => {},
    refreshIfNeeded: () => {},
    logout: () => {},
    authSuccessOccured: (successMessage) => {},
    authErrorOccured: (errorMessage) => {},
    isAccessTokenStillValid: () => {},
    isRefreshTokenStillValid: () => {},
    isAuthenticated: () => {},
    setLanguage: (language) => {}
});