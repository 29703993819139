import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CalendarHelper from '../classes/CalendarHelper.js';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import BallotIcon from '@material-ui/icons/Ballot';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import InputBase from '@material-ui/core/InputBase';
import ModalDialog from './ModalDialog';
import ModalDialogTextField from './ModalDialogTextField';
import ModalDialogWorkTime from './ModalDialogWorkTime';
import BlockIcon from '@material-ui/icons/Block';
import { invertColorMoreContrast, toJSONLocalDate, toDateObject, toDateOnlyString, removeSecondsFromTimeString, getWorkTimeSummary, getWorkTimeWarnings, getAccountingsForDate, isSpecialAutoAccounting } from '../helpers.js';
import clsx from  'clsx';
import { config } from '../config';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WorkTimeButton from './WorkTimeButton';
import i18n from 'i18next';
import _ from 'lodash'

const moment = extendMoment(Moment);

const useStyles = theme => ({
    container: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(3)
    },
    table: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: "0px"
    },
    cellDay: {
        fontWeight: 500,
        fontSize: "12px",
        width: "40px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    cellDayToday: {
        fontWeight: 500,
        fontSize: "12px",
        color: "#ff0000",
        width: "40px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottom: "2px solid #ff0000",
        userSelect: "none"
    },
    cellDaySumAboveThreshold: {
        fontWeight: 500,
        fontSize: "12px",
        width: "40px",
        color: "#ff0000",
        userSelect: "none"
    },
    cellDaySumBelowThreshold: {
        fontWeight: 500,
        fontSize: "12px",
        width: "40px",
        userSelect: "none"
    },
    cellDaySumAboveThresholdToday: {
        fontWeight: 500,
        fontSize: "12px",
        width: "40px",
        color: "#ff0000",
        borderLeft: "2px solid #ff0000",
        borderRight: "2px solid #ff0000",
        userSelect: "none"
    },
    cellDaySumBelowThresholdToday: {
        fontWeight: 500,
        fontSize: "12px",
        width: "40px",
        borderLeft: "2px solid #ff0000",
        borderRight: "2px solid #ff0000",
        userSelect: "none"
    },
    cellIcon: {
        fontWeight: 500,
        fontSize: "12px",
        minWidth: "30px",
        width: "30px",
        maxWidth: "30px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none",
        paddingLeft: theme.spacing(1),
    },
    cellSumHeader: {
        fontWeight: 500,
        fontSize: "12px",
        minWidth: "45px",
        width: "45px",
        maxWidth: "45px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
	cellSumBody: {
        fontWeight: 500,
        fontSize: "12px",
        minWidth: "45px",
        width: "45px",
        maxWidth: "45px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    cellProjectTaskNameHeader: {
        fontWeight: 500,
        fontSize: "12px",
        minWidth: "200px",
        width: "200px",
        maxWidth: "200px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    cellProjectNameBody: {
        fontWeight: 500,
        fontSize: "12px",
        minWidth: "200px",
        width: "200px",
        maxWidth: "200px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    cellTaskNameBody: {
        fontWeight: 500,
        fontSize: "12px",
        minWidth: "200px",
        width: "200px",
        maxWidth: "200px",
        paddingLeft: "20px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    cellSpecialTitleBody: {
        fontWeight: 500,
        fontSize: "12px",
        minWidth: "200px",
        width: "200px",
        maxWidth: "200px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    cellSpecialNameBody: {
        fontWeight: 500,
        fontSize: "12px",
        minWidth: "200px",
        width: "200px",
        maxWidth: "200px",
        paddingLeft: "20px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    icon: {
        color: theme.palette.primary.main
    },
    statusIcon: {
        color: theme.palette.primary.main,
        width: "12px",
        height: "12px",
        marginBottom: "2px"
    },
    taskName: {
        marginLeft: "20px"
    },
    cellEmpty: {
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px"
    },
    cellDayBase: {
        verticalAlign: "top",
        height: "25px",
        minWidth: "45px",
        color: "#656565",
        borderColor: "#e1e1e1",
        borderStyle: "solid",
        borderLeftWidth: "1px",
        borderRightWidth: "0px",
        borderTopWidth: "1px",
        borderBottomWidth: "0px",
        padding: "0px",
        paddingTop: "4px",
        margin: "0px",
        userSelect: "none",
        fontWeight: 500,
        fontSize: "12px",
    },
    cellTodayWeekDay: {
        color: "#000000",
        borderTop: "1px solid #e1e1e1",
        borderLeft: "2px solid #ff0000",
        borderRight: "2px solid #ff0000",
        borderBottomWidth: "0px"
    },
    cellTodayWeekEnd: {
        color: "#000000",
        borderTop: "1px solid #e1e1e1",
        borderLeft: "2px solid #ff0000",
        borderRight: "2px solid #ff0000",
        borderBottomWidth: "0px"
    },
    cellWeekEnd: {
        backgroundColor: "#d9d9d9",
    },
    cellWeekDay: {
        backgroundColor: "#ffffff",
    },
    cellHoliday: {
        color: "#656565",
        backgroundColor: "#fde1fc",
    },
    cellNoWorkDayWeekEnd: {
        backgroundColor: "#d9d9d9",
    },
    cellNoWorkDayWeekDay: {
        color: "#ffffff",
        backgroundColor: "#a0a0a0",
    },
    cellSelected: {
        backgroundColor: "#FACC2E",
    },
    inputHours: {
        fontWeight: 500,
        fontSize: "12px",
        marginLeft: "5px",
        marginRight: "5px"
    },
    cellAutoLockDisabled: {
        borderColor: "transparent"
    },
    cellAutoLockDayUnlocked: {
        borderBottom: "2px solid #00ff00"
    },
    cellAutoLockDayLocked: {
        borderBottom: "2px solid #ff0000"
    },
    cellStickyHead: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.white,
        minWidth: "275px",
        width: "275px",
        maxWidth: "275px",
        left: 0,
        position: "sticky",
        zIndex: theme.zIndex.appBar + 2,
        userSelect: "none",
        paddingTop: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        borderTopWidth: "0px"
    },
    cellStickyBodyNoBorders: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.white,
        minWidth: "275px",
        width: "275px",
        maxWidth: "275px",
        left: 0,
        position: "sticky",
        zIndex: theme.zIndex.appBar + 2,
        userSelect: "none",
        paddingTop: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        borderColor: "#e1e1e1",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
    },
    cellDividerDayFirstProject: {
        height: "2px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTop: "0px",
        borderBottomWidth: "0px",
        left: 0,
        position: "sticky",
        userSelect: "none",
        backgroundColor: theme.palette.common.white
    },
    cellDividerDayTodayFirstProject: {
        height: "2px",
        borderTop: "0px",
        borderBottomWidth: "0px",
        left: 0,
        position: "sticky",
        userSelect: "none",
        borderLeft: "2px solid rgb(255,0,0)",
        borderRight: "2px solid rgb(255,0,0)",
        backgroundColor: theme.palette.common.white
    },
    cellDividerDay: {
        height: "2px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTop: "1px solid #e1e1e1",
        borderBottomWidth: "0px",
        left: 0,
        position: "sticky",
        userSelect: "none",
        backgroundColor: theme.palette.common.white
    },
    cellDividerDayToday: {
        height: "2px",
        borderTop: "1px solid #e1e1e1",
        borderBottomWidth: "0px",
        left: 0,
        position: "sticky",
        userSelect: "none",
        borderLeft: "2px solid rgb(255,0,0)",
        borderRight: "2px solid rgb(255,0,0)",
        backgroundColor: theme.palette.common.white
    },
    cellDividerBottomDayToday: {
        height: "2px",
        borderTop: "2px solid #ff0000",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        left: 0,
        userSelect: "none",
        backgroundColor: theme.palette.common.white,
        zIndex: theme.zIndex.appBar + 10,
    },
    cellDividerBottomDay: {
        height: "2px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTop: "1px solid #e1e1e1",
        borderBottomWidth: "0px",
        left: 0,
        userSelect: "none",
        backgroundColor: theme.palette.common.white,
        zIndex: theme.zIndex.appBar + 10,
    },
    cellDividerBottomName: {
        height: "2px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        left: 0,
        position: "sticky",
        userSelect: "none",
        borderTop: "0px solid rgb(0,0,0)",
        backgroundColor: theme.palette.common.white,
        zIndex: theme.zIndex.appBar + 10,
    },
    cellDividerName: {
        height: "2px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        left: 0,
        position: "sticky",
        userSelect: "none",
        borderBottom: "0px solid rgb(224,224,224)",
        backgroundColor: theme.palette.common.white,
    }
});

/**
 * Accountings User
 */
class AccountingUser extends Component {
    state = {
        modifyDialog: {
            open: false,
            comment: ""
        },
        workTimeDialog: {
            open: false,
            data: {
                userid: null,
                dateJson: "",
                workTimes: {
                    today: [],
                    yesterday: []
                },
                accountings: []
            }
        },
        workTimeNoAccountingsDialog: {
            open: false,
            dateJson: null
        }
    }

    constructor(props) {
        super(props);

        // Bind functions
        this.isDateSelected = this.isDateSelected.bind( this );
        this.handleAccountingBlur = this.handleAccountingBlur.bind( this );
        this.validateAndConvertHours = this.validateAndConvertHours.bind( this );
        this.getAccountingsEntry = this.getAccountingsEntry.bind( this );
        this.getAccountingsContent = this.getAccountingsContent.bind( this );
        this.modifyAccountingsContent = this.modifyAccountingsContent.bind( this );
        this.handleMouseDoubleClick = this.handleMouseDoubleClick.bind( this );
        this.handleModifyDialog_Ok = this.handleModifyDialog_Ok.bind( this );
        this.handleModifyDialog_Cancel = this.handleModifyDialog_Cancel.bind( this );
        this.handleModifyDialog_Delete = this.handleModifyDialog_Delete.bind( this );
        this.verifyModifyDialog_Text = this.verifyModifyDialog_Text.bind( this );
        this.calculateSumForDaysInMonth = this.calculateSumForDaysInMonth.bind( this );
		this.calculateSumForTaskInMonth = this.calculateSumForTaskInMonth.bind( this );
		this.calculateSumForSpecialInMonth = this.calculateSumForSpecialInMonth.bind( this );
        this.showProject = this.showProject.bind( this );
        this.showTask = this.showTask.bind( this );
        this.allowEditOfTaskHours = this.allowEditOfTaskHours.bind( this );
        this.isDayLocked = this.isDayLocked.bind( this );
        this.getAutoLockDayClassName = this.getAutoLockDayClassName.bind( this );
        this.handleWorkTimeClick = this.handleWorkTimeClick.bind( this );
        this.workEntriesToString = this.workEntriesToString.bind( this );
        this.handleWorkTimeDialog_Ok = this.handleWorkTimeDialog_Ok.bind( this );
        this.handleWorkTimeDialog_Cancel = this.handleWorkTimeDialog_Cancel.bind( this );
        this.handleWorkTimeDialog_Delete = this.handleWorkTimeDialog_Delete.bind( this );
        this.handleWorkTimeNoAccountingsDialog_Cancel = this.handleWorkTimeNoAccountingsDialog_Cancel.bind( this );
    }

    /**
     * HANDLERS FOR COMMENT DIALOG
     */

    // Verify modify dialog text, max text length is already handled by the text field itself
    verifyModifyDialog_Text( content, entry ) {
        if( content == null || content.value == null ) {
            return { state: false, msg: "" };
        }
        
        return { state: true, msg: "" };
    }

    // Close modify dialog, apply changes
    handleModifyDialog_Ok( contents, entry ) {

        // Verify data
        if( null == entry || null == contents || null == contents.value ) {
            return;
        }

        // Store new comment in entry
        entry.comment = contents.value;

        // Push modified comment
        if( null != this.props.onModifyAccounting ) {
            this.props.onModifyAccounting( entry.userid, entry );
        }

        this.setState({ modifyDialog: { open: false, comment: null } });
    }

    // Close modify dialog, do not apply changes
    handleModifyDialog_Cancel() {
        this.setState({ modifyDialog: { open: false, comment: null } });
    }

    // Delete the modify from the entry
    handleModifyDialog_Delete( entry ) {

        // Verify data
        if( null == entry ) {
            return;
        }

        // Store new comment in entry
        entry.comment = "";

        // Push modified comment
        if( null != this.props.onModifyAccounting ) {
            this.props.onModifyAccounting( entry.userid, entry );
        }

        this.setState({ modifyDialog: { open: false, coment: null } });
    }

    /**
     * HANDLERS FOR WORKTIME DIALOG
     */
    closeWorkTimeDialog() {
        let emptyData = {
            userid: null,
            dateJson: "",
            workTimes: {
                today: [],
                yesterday: []
            },
            accountings: []
        }

        this.setState({ workTimeDialog: { open: false, data: emptyData } });
    }

    // Close worktime dialog, apply changes
    handleWorkTimeDialog_Ok( dateJson, contents ) {

        // Verify data
        if( null == contents || null == contents.data ) {
            return;
        }

        let userid = contents.data.userid;

        // Push modification call
        if( null != this.props.onModifyWorkTime ) {
            this.props.onModifyWorkTime( userid, contents.data );
        }

        this.closeWorkTimeDialog();
    }

    // Close worktime dialog, do not apply changes
    handleWorkTimeDialog_Cancel() {
        this.closeWorkTimeDialog();
    }

    // Delete the worktime from the entry
    handleWorkTimeDialog_Delete( dateJson, contents ) {

        let userid = contents.data.userid;

        // Push deleted worktimes
        if( null != this.props.onDeleteWorkTime ) {
            this.props.onDeleteWorkTime( userid, dateJson );
        }

        this.closeWorkTimeDialog();
    }

    // Close worktime_noaccountings dialog
    handleWorkTimeNoAccountingsDialog_Cancel() {
        this.setState({ workTimeNoAccountingsDialog: { open: false, dateJson: null } });
    }

    /**
     * OTHER HELPERS
     */

    // Calculate the sum of accounted hours for each day in the month
    calculateSumForDaysInMonth( weekDaysInMonth, dateJsonBase ) {
        if( null == weekDaysInMonth || null == dateJsonBase ) {
            return;
        }

        let sumForDaysInMonth = [];

        // Determine the specialid for accounting corrections if one has yet been defined
        let specialIdForAccountingCorrections = null;
        for( let i = 0; i < this.props.specials.length; i++ ) {
            if( this.props.specials[i].type === config.specialTypeLinkedToAccountingCorrections ) {
                specialIdForAccountingCorrections = this.props.specials[i].specialid;
                break;
            }
        }

        // Prefil array
        for( let i = 0; i < weekDaysInMonth.length; i++ ) {
            let dateJson = `${dateJsonBase}-`
            if( i+1 < 10 ) {
                dateJson += `0${i+1}`
            } else {
                dateJson += `${i+1}`
            }

            let sumForDay = 0;

            const accountingsForDate = getAccountingsForDate( dateJson, this.props.accountings, null );
            for( let j = 0; j < accountingsForDate.length; j++ ) {
                // Omit manual accounting correction entries and do not count entries without any hours
                if( null != accountingsForDate[j].specialid && specialIdForAccountingCorrections === accountingsForDate[j].specialid ) {
                    continue;
                }
                if( null != accountingsForDate[j].hours ) {
                    sumForDay += accountingsForDate[j].hours;
                }
            }

            sumForDaysInMonth.push( sumForDay );
        }

        return sumForDaysInMonth;
    }

	// Calculate sum of accounted hours in month for task
	calculateSumForTaskInMonth( taskid ) {
		if( null == taskid ) {
			return 0;
		}

		let sum = 0;

		for( let i = 0; i < this.props.accountings.length; i++ ) {
			if( null != this.props.accountings[i].taskid && taskid === this.props.accountings[i].taskid ) {
				sum += this.props.accountings[i].hours;
			}
		}

		return sum;
	}

	// Calculate sum of accounted hours in month for special
	calculateSumForSpecialInMonth( specialid ) {
		if( null == specialid ) {
			return 0;
		}

		let sum = 0;

		for( let i = 0; i < this.props.accountings.length; i++ ) {
			if( null != this.props.accountings[i].specialid && specialid === this.props.accountings[i].specialid ) {
				sum += this.props.accountings[i].hours;
			}
		}

		return sum;
	}

    // Check if project shall be shown
    // true if user has booked hours on any task within this project no matter of the task or project state
    // false otherwise
    showProject( tasksForProject ) {

        for( let i = 0; i < tasksForProject.length; i++ ) {
            if( true === this.showTask( tasksForProject[i] ) ) {
                return true;
            }
        }

        return false;
    }

    // Check if task shall be shown
    // true if task has state open
    // true if user has booked hours on this task in this month no matter of the task state
    // false otherwise
    showTask( task ) {
        
        if( config.enums.taskStatus[0].id === task.status ) {
            return true;
        }

        for( let i = 0; i < this.props.accountings.length; i++ ) {
			if( null != this.props.accountings[i].taskid && task.taskid === this.props.accountings[i].taskid ) {
				if( this.props.accountings[i].hours > 0 ) {
                    return true;
                }
			}
		}

        return false;
    }

    // Check if task hours shall be editable by the user
    // true if the task has the state open
    // false otherwise
    allowEditOfTaskHours( task ) {

        if( config.enums.taskStatus[0].id === task.status ) {
            return true;
        }

        return false;
    }

    // Check if this day is locked due to:
    // * the rolling auto accounting window 
    // * the special on this day is auto accounting
    // * the day is outside of the user's contract
    isDayLocked( dateJson ) {

        // Determine the final day lock state
        let dayIsLocked = false;

        // Check rolling accounting window if feature is enabled
        if( null != this.props.accountingAutoLock && true === this.props.accountingAutoLock ) {
            if( null != this.props.accountingAutoLockPeriodOfDays && 0 < this.props.accountingAutoLockPeriodOfDays ) {

                // We have a lock period and the rolling lock feature is enabled
                var todayDate = new Date();
                var accountingDate = new Date( dateJson );

                var diffInTime = todayDate.getTime() - accountingDate.getTime();
                var diffInDays = diffInTime / ( 1000 * 3600 * 24 );

                if( diffInDays > this.props.accountingAutoLockPeriodOfDays ) {
                    // Day is locked, we surpassed the auto lock period
                    dayIsLocked = true;
                }
            }
        }

        // If day is already locked we can bypass any further checks
        if( true === dayIsLocked ) {
            return true;
        }

        // Check if special on day is auto accounting and day is locked due to that
        if( null != this.props.specialAutoAccountingLock && true === this.props.specialAutoAccountingLock ) {
            const accountingsForDate = getAccountingsForDate( dateJson, this.props.accountings, null );
            for( let j = 0; j < accountingsForDate.length; j++ ) {
                
                // Check if there is an accounting on this date pointing to an auto-accounting specialid
                if( null != accountingsForDate[j].specialid ) {
                    if( true === isSpecialAutoAccounting( accountingsForDate[j].specialid, this.props.specials ) ) {
                        dayIsLocked = true;
                        break;
                    }
                }
            }
        }

        // If day is already locked we can bypass any further checks
        if( true === dayIsLocked ) {
            return true;
        }

        // Check if day is outside of the users contract
        let dateObject = toDateObject( dateJson );
        if( false === CalendarHelper.isWithinContract( this.props.contracts, this.props.currentUser.userid, dateObject ) ) {
            dayIsLocked = true;
        }

        return dayIsLocked;
    }

    // Get the color of the given date for the autolock feature
    getAutoLockDayClassName( isDayLocked ) {
        if( true === isDayLocked ) {
            return this.props.classes.cellAutoLockDayLocked;
        }

        return this.props.classes.cellAutoLockDayUnlocked;
    }

    // Check if a worktime entry is available for the given date
    hasWorkTimeEntry( date, userid ) {

        if( null == date || null == userid ) {
            return false;
        }

        const dateString = toJSONLocalDate( date );

        for( let i = 0; i < this.props.worktimes.length; i++ ) {
            if( this.props.worktimes[i].userid === userid ) {
                if( this.props.worktimes[i].date === dateString ) {
                    return true;
                }
            }
        }

        return false;
    }

    // Get worktime entries for date
    getWorkTimeEntries( dateJson, userid ) {

        if( null == dateJson || "" === dateJson || null == userid ) {
            return { state: false, worktimes: [] };
        }

        let worktimes = [];

        for( let i = 0; i < this.props.worktimes.length; i++ ) {
            if( this.props.worktimes[i].userid === userid ) {
                if( this.props.worktimes[i].date === dateJson ) {
                    worktimes.push( this.props.worktimes[i] );
                }
            }
        }

        let state = false;
        if( worktimes.length > 0 ) {
            state = true;
        }

        return { state: state, worktimes: worktimes };
    }

    /**
     * HELPERS TO GET ACCOUNTING ENTRIES
     */

    // Get original accountings entry
    getAccountingsEntry( dateJson, taskid, specialid ) {
        if( null == this.props.accountings || 0 === this.props.accountings.length ) {
            return null;
        }

        for( let i = 0; i < this.props.accountings.length; i++ ) {
            if( this.props.accountings[i].date === dateJson ) {
                if( null != taskid && this.props.accountings[i].taskid === taskid ) {
                    return this.props.accountings[i];
                }
                if( null != specialid && this.props.accountings[i].specialid === specialid ) {
                    return this.props.accountings[i];
                }
            }
        }

        return null;
    }

    // Get accountings content
    getAccountingsContent( dateJson, taskid, specialid ) {
        const entry = this.getAccountingsEntry( dateJson, taskid, specialid );

        if( null != entry ) {
            if( 0 === entry.hours ) {
                return { hours: "", comment: entry.comment }
            } else {
                return { hours: entry.hours, comment: entry.comment }
            }
        }

        return { hours: "", comment: "" };
    }

    // Modify an accountings content (hours are given here as string to account for floating point entries like '0.')
    modifyAccountingsContent( dateJson, hours, comment, taskid, specialid, userid ) {

        // Get the original accountings entry if one is already present
        const originalEntry = this.getAccountingsEntry( dateJson, taskid, specialid );

        if( null != originalEntry ) {

            // Entry found, modify it
            let clonedOriginalEntry = _.cloneDeep( originalEntry );
            if( null != hours ) {
                clonedOriginalEntry.hours = hours;
            } else if( null != clonedOriginalEntry.hours ) {
                delete clonedOriginalEntry.hours;
            }
            if( null != comment ) {
                clonedOriginalEntry.comment = comment;
            } else if( null != clonedOriginalEntry.comment ) {
                delete clonedOriginalEntry.comment;
            }

            return clonedOriginalEntry;

        } else {

            // Entry not found, create a new one
            let newAccounting = {
                userid: userid,
                date: dateJson
            }

            // Only add field when necessary
            if( null != hours ) { newAccounting.hours = hours; } else { newAccounting.hours = 0; }
            if( null != comment ) { newAccounting.comment = comment; } else { newAccounting.comment = ""; }
            if( null != taskid ) { newAccounting.taskid = taskid; }
            if( null != specialid ) { newAccounting.specialid = specialid; }

            return newAccounting;
        }
    }

    // No cell selection taking place here
    isDateSelected( userid, date ) {
        return false;
    }

    // Get details for a special
    getSpecialDetails( specialid ) {
        if( null == this.props.specials ) {
            return { name: null, short_name: null, color_code: null };
        }

        // Handle special case for specialid zero which occurs if the user is not allowed to see the respective absence
        if( 0 === specialid ) {
            let hiddenSpecial = config.specialIfHidden;
            hiddenSpecial.name = i18n.t("values.hidden");
            return hiddenSpecial;
        }

        for( let i = 0; i < this.props.specials.length; i++ ) {
            if( this.props.specials[i].specialid === specialid ) {
                return { name: this.props.specials[i].name, short_name: this.props.specials[i].short_name, color_code: this.props.specials[i].color_code };
            }
        }

        return { name: null, short_name: null, color_code: null };
    }

    // Get work time entry of given day for given user as a string
    workEntriesToString( worktimes ) {
        
        if( null == worktimes || worktimes.length === 0 ) {
            let workEntryAsString = i18n.t("pages.main.tooltip.no_work_time_entered");
            return workEntryAsString
        }

        let workEntryAsString = "";
        for( let i = 0; i < worktimes.length; i++ ) {
            workEntryAsString += removeSecondsFromTimeString( worktimes[i].start_time ) + " - " + removeSecondsFromTimeString( worktimes[i].end_time ) + " (break: " + worktimes[i].break + ")";
            if( (i+1) < worktimes.length ) {
                workEntryAsString += ", ";
            }
        }

        return workEntryAsString;
    }

    /**
     * ACCOUNTING CHANGES HANDLERS
     */

    // Check string for a valid float or integer, also allowed are empty strings or a partial float (for example "5.")
    // Also converts the string to a proper float
    validateAndConvertHours( value ) {
        // Handle empty string
        if( value === "" ) {
            return { state: true, value: 0 };
        }

        // Get max value of hours per day
        // This is not the configured maxHoursPerDay value which causes the sum of hours on the day to get a red indication
        let maxHoursPerDay = 24;

        // Rounding of hours
        let hoursPrecision = 0.25;
        if( null != this.props.hoursPrecision ) {
            hoursPrecision = this.props.hoursPrecision;
        }

        // Check if value is a full integer or a full float
        if( false === isNaN(parseInt( value )) || false === isNaN(parseFloat( value )) ) {

            // parseFloat only understands "." as a separator for float values, let's also support ',' for convenience
            let floatValue = parseFloat( value.replace(',', '.') );

            // Make sure to round to precision if necessary
            floatValue = Math.ceil( (1/hoursPrecision) * floatValue ) / (1/hoursPrecision);

            if( floatValue < 0 ) {
                return { state: false, value: null }
            }
            if( floatValue > maxHoursPerDay ) {
                floatValue = maxHoursPerDay;
            }
            return { state: true, value: floatValue };
        }

        // Check for a partial float (for example 5.) since the user is still writing
        if( /^(-|\+)?([0-9]+(\.){1})$/.test(value) ) {
            value += "0"
            // parseFloat only understands "." as a separator for float values, let's also support ',' for convenience
            let floatValue = parseFloat( value.replace(',', '.') );

            if( floatValue < 0 ) {
                return { state: false, value: null }
            }
            if( floatValue > maxHoursPerDay ) {
                floatValue = maxHoursPerDay;
            }
            return { state: true, value: floatValue };
        }

        return { state: false, value: null };
    }

    // Handle double click on input field
    handleMouseDoubleClick( event, taskid, specialid, dateJson, userid ) {

        // Open comment dialog for given field
        let entry = this.getAccountingsEntry( dateJson, taskid, specialid );
        let comment = "";

        // In case entry is null no entry exists here yet, so let's create a new one
        if( null == entry ) {
            entry = {
                userid: userid,
                date: dateJson
            }

            // Only add field when necessary
            if( null != taskid ) { entry.taskid = taskid; }
            if( null != specialid ) { entry.specialid = specialid; }
        } else {
            comment = entry.comment;
        }

        this.setState({ modifyDialog: { open: true, comment: comment, entry: entry } });
    }

    // Handle mouse click on worktime icon
    handleWorkTimeClick( event, userid, dateJson, defaultWorkStartTime, dayLocked, workTimeEntriesToday, workTimeEntriesYesterday, accountingsToday ) {

        // Do not do anything if day is locked
        if( true === dayLocked ) {
            return;
        }

        // Check if there are no accountings on this date yet, if there are none open a warning dialog
        if( null != accountingsToday && accountingsToday.length === 0 ) {
            this.setState({ workTimeNoAccountingsDialog: { open: true, dateJson: dateJson } });
            return;
        }

        let data = {
            userid: userid,
            dateJson: dateJson,
            defaultWorkStartTime: defaultWorkStartTime,
            worktimes: {
                today: workTimeEntriesToday,
                yesterday: workTimeEntriesYesterday
            },
            accountings: accountingsToday
        }

        // Open work time dialog
        this.setState({ workTimeDialog: { open: true, data: data } });
    }

    // Handle blur on accounting field
    handleAccountingBlur( event, taskid, specialid, dateJson, userid ) {
        // Grab the new value from the text field
        let textFieldValue = event.target.value;

        // Verify contents of text field and convert them to a proper float value which we can store
        const { state, value } = this.validateAndConvertHours( textFieldValue );

        if( false === state ) {

            // Restore the original default value and reset it to the textfield
            let entry = this.getAccountingsEntry( dateJson, taskid, specialid );
            if( null != entry && null != entry.hours ) {
                event.target.value = entry.hours;
            } else {
                event.target.value = "";
            }
            return;
        }

        // Store converted number in text field
        if( value !== 0 ) {
            event.target.value = value;
        } else {
            event.target.value = "";
        }

        // Apply the new accounting if necessary
        let newAccountingEntry = this.modifyAccountingsContent( dateJson, value, null, taskid, specialid, userid );
        if( null == newAccountingEntry ) {
            return;
        }

        if( null != this.props.onModifyAccounting ) {
            this.props.onModifyAccounting( userid, newAccountingEntry );
        }
    }

    /**
     * RENDERERS
     */

    // Render the cell for the work times
    renderWorkTimeCell( year, monthIndex, dayIndex, isToday, userid, defaultWorkStartTime, trackWorkTimeStartDate ) {

        // Get info about this day
        const dateObject = new Date( this.props.year, monthIndex, dayIndex+1 );
        const dateObjectYesterday = moment(dateObject).subtract(1, 'days').toDate();
        const dateJson = toJSONLocalDate( dateObject );

        const absent = CalendarHelper.isAbsent( this.props.absences, userid, dateObject ); // state, specialid
        const hasWorkTimeEntry = this.hasWorkTimeEntry( dateObject, userid );
        const dayLocked = this.isDayLocked( dateJson );
        const accountingsToday = getAccountingsForDate( dateJson, this.props.accountings, null );

        let workTimeEntriesToday = [];
        let workTimeEntriesYesterday = [];
        let workTimeWarnings = [];

        // Determine if we want to show the worktime icon on the given day and if we show it determine its color
        let showButton = true;

        // Do not show icon if current day is before worktime tracking start according to config file
        if( dateObject < trackWorkTimeStartDate ) {
            showButton = false;
        }

        // Do not show icon if current day has an absence reason which is auto accounting
        if( true === isSpecialAutoAccounting( absent.specialid, this.props.specials ) ) {
            showButton = false;
        }

        // Do not show icon if there are no accountings on this day yet
        if( accountingsToday.length === 0 ) {
            showButton = false;
        }

        if( true === hasWorkTimeEntry ) {

            // We have worktimes for this day, add work entry details to tooltip
            const state = this.getWorkTimeEntries( dateJson, userid );

            // Also check if the entered work times have a warning
            const stateYesterday = this.getWorkTimeEntries( toJSONLocalDate( dateObjectYesterday ), userid );

            let workTimeData = {
                userid: userid,
                dateJson: dateJson,
                defaultWorkStartTime: defaultWorkStartTime,
                worktimes: {
                    today: state.worktimes,
                    yesterday: stateYesterday.worktimes
                },
                accountings: accountingsToday
            }

            const workTimeSummary = getWorkTimeSummary( workTimeData );
            workTimeWarnings = getWorkTimeWarnings( workTimeData, workTimeSummary );

            workTimeEntriesToday = state.worktimes;
        }

        // If we show the button also gather the work time entries for yesterday since that is necessary
        // to display warnings in case of insufficient nightly rest period, we also need the accountings for today
        if( true === showButton ) {

            const state = this.getWorkTimeEntries( toJSONLocalDate( dateObjectYesterday ), userid );
            if( true === state.state ) {
                workTimeEntriesYesterday = state.worktimes;
            }
        }

        // Generate the button if necessary
        let buttonContent = "";
        if( true === showButton ) {
            buttonContent = (
                <WorkTimeButton
                    date={dateObject}
                    isDayLocked={dayLocked}
                    hasWorkTimeEntryToday={hasWorkTimeEntry}
                    workTimeWarnings={workTimeWarnings}
                    onClick={(event) => this.handleWorkTimeClick( event, userid, dateJson, defaultWorkStartTime, dayLocked, workTimeEntriesToday, workTimeEntriesYesterday, accountingsToday )}
                />
            )
        }

        if( true === isToday ) {
            return (
                <TableCell key={"companyaccountings-worktime-row-divider-cell-"+dayIndex} align="center" className={this.props.classes.cellDividerDayToday}>
                    {buttonContent}
                </TableCell>
            )
        } else {
            return (
                <TableCell key={"companyaccountings-worktime-row-divider-cell-"+dayIndex} align="center" className={this.props.classes.cellDividerDay}>
                    {buttonContent}
                </TableCell>
            ) 
        }
    }

    // Render a table day cell
    renderTableCell( year, monthIndex, dayIndex, isWeekEnd, isToday, userid, taskid, specialid, content, allowEdit ) {

        // Check if day is selected
        const dateObject = new Date( this.props.year, monthIndex, dayIndex+1 );
        const date = dateObject.getTime();
        const dateJson = toJSONLocalDate( dateObject );

        const isDateSelected = this.isDateSelected( userid, date );
        const holiday = CalendarHelper.isHoliday( this.props.holidays, dateObject );
        const absent = CalendarHelper.isAbsent( this.props.absences, userid, dateObject );
        const isWorkDay = CalendarHelper.isWorkDay( this.props.contracts, userid, dateObject );

        let className = "";
        let cellTooltip = "";

        if( true === isToday ) {
            cellTooltip = this.props.labelToday;
        }

        if( true === holiday.state ) {
            if( cellTooltip.length > 0 ) {
                cellTooltip += " - ";
            }
            cellTooltip += holiday.name;

            if( true === isToday ) {
                if( true === isWeekEnd ) {
                    className = clsx(this.props.classes.cellDayBase, this.props.classes.cellHoliday, this.props.classes.cellTodayWeekEnd);
                } else {
                    className = clsx(this.props.classes.cellDayBase, this.props.classes.cellHoliday, this.props.classes.cellTodayWeekDay);
                }
                
            } else {
                className = clsx(this.props.classes.cellDayBase, this.props.classes.cellHoliday);
            }
            
        } else {
            if( true === isWeekEnd ) {
                if( cellTooltip.length > 0 ) {
                    cellTooltip += " - ";
                }
                cellTooltip += this.props.labelWeekend;

                if( true === isToday ) {

                    if( true === isWorkDay ) {                    
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekEnd);
                    } else {
                        if( cellTooltip.length > 0 ) {
                            cellTooltip += " - ";
                        }
                        cellTooltip += this.props.labelNoWorkDay;
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekEnd, this.props.classes.cellNoWorkDayWeekEnd);
                    }


                } else {
                    if( true === isWorkDay ) {
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellWeekEnd);
                    } else {
                        if( cellTooltip.length > 0 ) {
                            cellTooltip += " - ";
                        }
                        cellTooltip += this.props.labelNoWorkDay;

                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellNoWorkDayWeekEnd);
                    }
                }
            } else {
                if( true === isToday ) {
                    if( true === isWorkDay ) {
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekDay);
                    } else {
                        if( cellTooltip.length > 0 ) {
                            cellTooltip += " - ";
                        }
                        cellTooltip += this.props.labelNoWorkDay;
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellTodayWeekDay, this.props.classes.cellNoWorkDayWeekDay);
                    }

                } else {
                    if( true === isWorkDay ) {
                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellWeekDay);
                    } else {
                        if( cellTooltip.length > 0 ) {
                            cellTooltip += " - ";
                        }
                        cellTooltip += this.props.labelNoWorkDay;

                        className = clsx(this.props.classes.cellDayBase, this.props.classes.cellNoWorkDayWeekDay);
                    }
                }
            }
        }

        // Change background color of cell depending on selection
        if( true === isDateSelected && false === holiday.state && true === isWorkDay ) {
            className = clsx(this.props.classes.cellDayBase, this.props.classes.cellSelected);
        }

        let absentBackgroundColor = null;
        let absentTextColor = null;
        if( true === absent.state ) {
            const specialDetails = this.getSpecialDetails( absent.specialid );
            absentBackgroundColor = specialDetails.color_code;
            absentTextColor = invertColorMoreContrast( specialDetails.color_code, true );
            cellTooltip = specialDetails.name;
        }

		// Add the contentTooltip if one was given
		if( null != content.comment && content.comment !== "" ) {
			if( cellTooltip !== "" ) {
				cellTooltip += ": ";
			}
			cellTooltip += content.comment;
		}

		let tableCellContent = "";
		let contentHours = "";
		if( null != content && null != content.hours ) {
			contentHours = content.hours;
		}

		if( true === absent.state ) {
            if( true === allowEdit ) {
                tableCellContent = (
                    <TableCell
                        align="center"
                        key={"cell-date-"+userid+"-"+year+"-"+monthIndex+"-"+dayIndex}
                        className={className}
                        style={{ backgroundColor: `#${absentBackgroundColor}`, color: `#${absentTextColor}` }}
                    >
                        <div style={{ width: "100%" }}>
                            <InputBase 
                                className={this.props.classes.inputHours} 
                                inputProps={{ style: { textAlign: "center", color: `#${absentTextColor}` }}}
                                defaultValue={contentHours}
                                onDoubleClick={(event) => this.handleMouseDoubleClick(event, taskid, specialid, dateJson, userid )}
                                onBlur={(event) => this.handleAccountingBlur( event, taskid, specialid, dateJson, userid )}
                            />
                        </div>
                    </TableCell>
                )
            } else {
                tableCellContent = (
                    <TableCell
                        align="center"
                        key={"cell-date-"+userid+"-"+year+"-"+monthIndex+"-"+dayIndex}
                        className={className}
                        style={{ backgroundColor: `#${absentBackgroundColor}`, color: `#${absentTextColor}` }}
                    >
                        <div style={{ width: "100%", height: "100%", marginTop: "2px" }}>
                            {contentHours}
                        </div>
                    </TableCell>
                )
            }
		} else {
            if( true === allowEdit ) {
                tableCellContent = (
                    <TableCell
                        align="center"
                        key={"cell-date-"+userid+"-"+year+"-"+monthIndex+"-"+dayIndex}
                        className={className}
                    >
                        <div style={{ width: "100%" }}>
                            <InputBase 
                                className={this.props.classes.inputHours} 
                                inputProps={{ style: { textAlign: "center" }}}
                                defaultValue={contentHours}
                                onDoubleClick={(event) => this.handleMouseDoubleClick(event, taskid, specialid, dateJson, userid )}
                                onBlur={(event) => this.handleAccountingBlur( event, taskid, specialid, dateJson, userid )}
                            />
                        </div>
                    </TableCell>
                )
            } else {
                tableCellContent = (
                    <TableCell
                        align="center"
                        key={"cell-date-"+userid+"-"+year+"-"+monthIndex+"-"+dayIndex}
                        className={className}
                    >
                        <div style={{ width: "100%", height: "100%", marginTop: "2px" }}>
                            {contentHours}
                        </div>
                    </TableCell>
                )
            }
		}

        if( cellTooltip !== "" ) {
            return (
                <Tooltip key={"tooltip-date-"+userid+"-"+year+"-"+monthIndex+"-"+dayIndex} title={cellTooltip}>
                    {tableCellContent}
                </Tooltip>
            )
        } else {
            return tableCellContent;
        }
    }

    // Render accountings for year and month
    renderAccountings( year, month ) {

        // Get some parameters for rendering
        const todayDateObject = new Date();
        const todayMonth = todayDateObject.getMonth();
        const todayDate = todayDateObject.getDate();
        const todayYear = todayDateObject.getFullYear();

        const weekDaysInMonth = CalendarHelper.getWeekDaysForMonth( this.props.language, "short", year, month );

        // Create list of specials to show which allow accounting
        let specials = [];
        if( null != this.props.specials ) {
            for( let i = 0; i < this.props.specials.length; i++ ) {
                if( true === this.props.specials[i].allow_accounting ) {
                    specials.push( this.props.specials[i]);
                }
            }
        }

        // Start string of json date
        let dateJsonBase = `${year}-`;
        if( (month+1) < 10 ) {
            dateJsonBase += `0${month+1}`;
        } else {
            dateJsonBase += `${month+1}`;
        }

        // Get the start day from which we track work times
        const trackWorkTimeStartDate = toDateObject( config.workTimes.trackStartDate );

        // Calculate sum of accounted hours per day
        const sumForDaysInMonth = this.calculateSumForDaysInMonth( weekDaysInMonth, dateJsonBase );

        return (
            <TableContainer component={Paper} className={this.props.classes.container}>
                <Table className={this.props.classes.table}>
                    <TableHead key={"companyaccountings-header"}>
                        <TableRow key={"companyaccountings-header-row"}>
                            <TableCell className={this.props.classes.cellStickyHead}>
                                <TableCell key={"companyaccountings-header-row-col-icon"} align="left" className={this.props.classes.cellIcon} />
                                <TableCell key={"companyaccountings-header-row-col-name"} align="center" className={this.props.classes.cellProjectTaskNameHeader} />
                                <TableCell key={"companyaccountings-header-row-col-sum"} align="center" className={this.props.classes.cellSumHeader} />
                            </TableCell>
                            {weekDaysInMonth.map((weekDay, dayIndex) => {
                                let isToday = false;
                                if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                    isToday = true;
                                }

                                if( true === isToday ) {
                                    return (
                                        <TableCell 
                                            key={"companyaccountings-header-row-col-"+dayIndex} 
                                            align="center"
                                            className={this.props.classes.cellDayToday}
                                        >
                                            {dayIndex+1}<br/>{weekDay.name}
                                        </TableCell>
                                    )
                                } else {
                                    return (
                                        <TableCell 
                                            key={"companyaccountings-header-row-col-"+dayIndex} 
                                            align="center"
                                            className={this.props.classes.cellDay}
                                        >
                                            {dayIndex+1}<br/>{weekDay.name}
                                        </TableCell>
                                    )
                                }
                            })}
                        </TableRow>
                        <TableRow key={"companyaccountings-day-summary-row"}>
                            <TableCell className={this.props.classes.cellStickyHead}>
                                <TableCell key={"companyaccountings-day-summary-col-icon"} align="left" className={this.props.classes.cellIcon} />
                                <TableCell key={"companyaccountings-day-summary-col-name"} align="left" className={this.props.classes.cellProjectTaskNameHeader} />
                                <TableCell key={"companyaccountings-day-summary-col-sum"} align="center" className={this.props.classes.cellSumHeader}>
                                    &sum;
                                </TableCell>
                            </TableCell>
                            {sumForDaysInMonth.map((daySum, dayIndex) => {
                                let isToday = false;
                                if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                    isToday = true;
                                }

                                // Check if hours on this day shall be editable because auto lock of accountings might be enabled given a certain period of days
                                let dateJson = `${dateJsonBase}-`
                                if( dayIndex+1 < 10 ) {
                                    dateJson += `0${dayIndex+1}`
                                } else {
                                    dateJson += `${dayIndex+1}`
                                }

                                // Check if day is unlocked, a locked day might appear due to the accounting sliding lock window or an auto accounting special
                                const dayLocked = this.isDayLocked( dateJson );
                                let dayClassName = this.getAutoLockDayClassName( dayLocked );

                                // Get max value of working hours per day if configured
                                let maxHoursPerDay = -1;
                                if( null != this.props.maxHoursPerDay ) {
                                    maxHoursPerDay = this.props.maxHoursPerDay;
                                }
                                
                                if( maxHoursPerDay !== -1 && daySum > maxHoursPerDay ) {
                                    let cellClassName = clsx(this.props.classes.cellDaySumAboveThreshold, dayClassName);
                                    if( true === isToday ) {
                                        cellClassName = clsx(this.props.classes.cellDaySumAboveThresholdToday, dayClassName);
                                    }
                                    return (
                                        <Tooltip key={"companyaccountings-day-summary-col-"+dayIndex+"-abovethreshold"} title={i18n.t("pages.main.accounting_user.daily_hours_above_threshold", { hours: maxHoursPerDay })}>
                                            <TableCell 
                                                key={"companyaccountings-day-summary-col-"+dayIndex} 
                                                align="center"
                                                className={cellClassName}
                                            >
                                                {daySum}
                                            </TableCell>
                                        </Tooltip>
                                    )
                                } else {
                                    let cellClassName = clsx(this.props.classes.cellDaySumBelowThreshold, dayClassName);
                                    if( true === isToday ) {
                                        cellClassName = clsx(this.props.classes.cellDaySumBelowThresholdToday, dayClassName);
                                    }
                                    return (
                                        <TableCell 
                                        key={"companyaccountings-day-summary-col-"+dayIndex} 
                                        align="center"
                                        className={cellClassName}
                                        >
                                            {daySum}
                                        </TableCell>
                                    )
                                }
                            })}
                        </TableRow>
                        </TableHead>
                    <TableBody>
                        <TableRow key={"companyaccountings-worktime-row"}>
                            <TableCell className={this.props.classes.cellStickyBodyNoBorders}>
                                <TableCell key={"companyaccountings-worktime-row-cell-icon"} align="left" className={this.props.classes.cellIcon}>
                                    <AccessTimeIcon className={this.props.classes.icon}/>
                                </TableCell>
                                <TableCell key={"companyaccountings-worktime-row-cell-name"} align="left" className={this.props.classes.cellSpecialTitleBody}>
                                    {i18n.t("pages.main.values.worktimes")}
                                </TableCell>
                                <TableCell key={"companyaccountings-worktime-row-cell-sum"} align="center" className={this.props.classes.cellSumBody} />
                            </TableCell>
                            {weekDaysInMonth.map((weekDay, dayIndex) => {
                                let isToday = false;
                                if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                    isToday = true;
                                }

                                return this.renderWorkTimeCell( year, month, dayIndex, isToday, this.props.currentUser.userid, this.props.currentUser.default_work_start_time, trackWorkTimeStartDate );
                            })}
                        </TableRow>
                        {this.props.projects.map((project, projectIndex) => {

                            // Get tasks for project
                            let tasksForProject = []
                            for( let i = 0; i < this.props.tasks.length; i++ ) {
                                if( this.props.tasks[i].projectid === project.projectid ) {
                                    tasksForProject.push( this.props.tasks[i] );
                                }
                            }

                            // Assemble project name string
                            let projectName = project.name;
                            if( project.internal_id !== "" ) {
                                projectName = `${project.internal_id} - ${project.name}`;
                            }

                            // Check if project shall be displayed
                            const showProject = this.showProject( tasksForProject );
                            if( false === showProject ) {
                                return ("");
                            }

                            return (
                                <React.Fragment key={"fragment-"+project.projectid}>
                                    <TableRow key={"companyaccountings-project-row-"+project.projectid}>
                                        <TableCell className={this.props.classes.cellStickyBodyNoBorders}>
                                            <TableCell key={"companyaccountings-project-row-"+project.projectid+"-cell-icon"} align="left" className={this.props.classes.cellIcon}>
                                                <BallotIcon className={this.props.classes.icon}/>
                                            </TableCell>
                                            <TableCell key={"companyaccountings-project-row-"+project.projectid+"-cell-name"} align="left" className={this.props.classes.cellProjectNameBody}>
                                                {projectName}
                                            </TableCell>
                                            <TableCell key={"companyaccountings-project-row-"+project.projectid+"-cell-col-sum"} align="center" className={this.props.classes.cellSumBody} />
                                        </TableCell>
                                        {weekDaysInMonth.map((weekDay, dayIndex) => {
                                            let isToday = false;
                                            if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                                isToday = true;
                                            }

                                            if( true === isToday ) {
                                                if( projectIndex === 0 ) {
                                                    return (
                                                        <TableCell key={"companyaccountings-project-row-"+project.projectid+"-divider-cell-"+dayIndex} align="left" className={this.props.classes.cellDividerDayTodayFirstProject} />
                                                    )
                                                } else {
                                                    return (
                                                        <TableCell key={"companyaccountings-project-row-"+project.projectid+"-divider-cell-"+dayIndex} align="left" className={this.props.classes.cellDividerDayToday} />
                                                    )
                                                }
                                                
                                            } else {
                                                if( projectIndex === 0 ) {
                                                    return (
                                                        <TableCell key={"companyaccountings-project-row-"+project.projectid+"-divider-cell-"+dayIndex} align="left" className={this.props.classes.cellDividerDayFirstProject} />
                                                    )
                                                } else {
                                                    return (
                                                        <TableCell key={"companyaccountings-project-row-"+project.projectid+"-divider-cell-"+dayIndex} align="left" className={this.props.classes.cellDividerDay} />
                                                    )
                                                }
                                            }
                                        })}
                                    </TableRow>
                                    {tasksForProject.map((task) => {

                                        // Check if task shall be displayed
                                        const showTask = this.showTask( task );
                                        if( false === showTask ) {
                                            return ("");
                                        }

                                        // Check if hours on task shall be editable because task may be closed
                                        const allowEditOfTask = this.allowEditOfTaskHours( task );
                                        let taskStatusIcon = "";
                                        if( false === allowEditOfTask ) {
                                            taskStatusIcon = (
                                                <Tooltip key={"companyaccountings-task-row-"+task.taskid+"-taskclosed"} title={i18n.t("pages.main.tooltip.task_closed")}>
                                                    <BlockIcon className={this.props.classes.statusIcon} />
                                                </Tooltip>
                                            )
                                        }

										const sumForTaskInMonth = this.calculateSumForTaskInMonth( task.taskid );

                                        return (
                                            <TableRow key={"companyaccountings-task-row-"+task.taskid}>
                                                <TableCell className={this.props.classes.cellStickyBodyNoBorders}>
                                                    <TableCell key={"companyaccountings-task-row-"+task.taskid+"-cell-icon"} align="left" className={this.props.classes.cellIcon} />
                                                    <TableCell key={"companyaccountings-task-row-"+task.taskid+"-cell-name"} align="left" className={this.props.classes.cellTaskNameBody}>
                                                        {task.name} {taskStatusIcon}
                                                    </TableCell>
                                                    <TableCell key={"companyaccountings-task-row-"+task.taskid+"-cell-sum"} align="center" className={this.props.classes.cellSumBody}>
                                                        {sumForTaskInMonth}
                                                    </TableCell>
                                                </TableCell>
                                                {weekDaysInMonth.map((weekDay, dayIndex) => {
                                                    let isToday = false;
                                                    if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                                        isToday = true;
                                                    }

                                                    let isWeekEnd = false;
                                                    if( weekDay.index === 0 || weekDay.index === 6 ) {
                                                        isWeekEnd = true;
                                                    }

                                                    // Gather content of this cell
                                                    let dateJson = `${dateJsonBase}-`
                                                    if( dayIndex+1 < 10 ) {
                                                        dateJson += `0${dayIndex+1}`
                                                    } else {
                                                        dateJson += `${dayIndex+1}`
                                                    }

                                                    // Check if hours on this day shall be editable because auto lock of accountings might be enabled given a certain period of days
                                                    let content = this.getAccountingsContent( dateJson, task.taskid, null );

                                                    // Determine final lock stage of this cell
                                                    const dayLocked = this.isDayLocked( dateJson );

                                                    let allowEditOfHours = true;
                                                    if( false === allowEditOfTask || true === dayLocked ) {
                                                        allowEditOfHours = false;
                                                    }

                                                    return this.renderTableCell( year, month, dayIndex, isWeekEnd, isToday, this.props.currentUser.userid, task.taskid, null, content, allowEditOfHours );
                                                })}
                                            </TableRow>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })}
                        <TableRow key={"companyaccountings-specials-row"}>
                            <TableCell className={this.props.classes.cellStickyBodyNoBorders}>
                                <TableCell key={"companyaccountings-special-row-cell-icon"} align="left" className={this.props.classes.cellIcon}>
                                    <DirectionsRunIcon className={this.props.classes.icon}/>
                                </TableCell>
                                <TableCell key={"companyaccountings-special-row-cell-name"} align="left" className={this.props.classes.cellSpecialTitleBody} colSpan={weekDaysInMonth.length}>
                                    {i18n.t("pages.main.values.absences")}
                                </TableCell>
                                <TableCell key={"companyaccountings-special-row-cell-sum"} align="center" className={this.props.classes.cellSumBody} />
                            </TableCell>
                            {weekDaysInMonth.map((weekDay, dayIndex) => {
                                let isToday = false;
                                if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                    isToday = true;
                                }

                                if( true === isToday ) {
                                    return (
                                        <TableCell key={"companyaccountings-special-row-divider-cell-"+dayIndex} align="left" className={this.props.classes.cellDividerDayToday} />
                                    )
                                } else {
                                    return (
                                        <TableCell key={"companyaccountings-special-row-divider-cell-"+dayIndex} align="left" className={this.props.classes.cellDividerDay} />
                                    )
                                }
                            })}
                        </TableRow>
                        {specials.map((special) => {
							const sumForSpecialInMonth = this.calculateSumForSpecialInMonth( special.specialid );
                            const specialAutoAccounting = isSpecialAutoAccounting( special.specialid, this.props.specials );

                            let specialStatusIcon = "";
                            if( true === specialAutoAccounting && true === this.props.specialAutoAccountingLock ) {
                                specialStatusIcon = (
                                    <Tooltip key={"companyaccountings-special-row-"+special.specialid+"-specialautoaccounting"} title={i18n.t("pages.main.tooltip.special_auto_accounting")}>
                                        <BlockIcon className={this.props.classes.statusIcon} />
                                    </Tooltip>
                                )
                            }

                            return (
                                <TableRow key={"companyaccountings-special-row-"+special.specialid}>
                                    <TableCell className={this.props.classes.cellStickyBodyNoBorders}>
                                        <TableCell key={"companyaccountings-special-row-"+special.specialid+"-cell-icon"} align="left" className={this.props.classes.cellIcon}>
                                        </TableCell>
                                        <TableCell key={"companyaccountings-special-row-"+special.specialid+"-cell-name"} align="left" className={this.props.classes.cellSpecialNameBody}>
                                            {special.name} {specialStatusIcon}
                                        </TableCell>
                                        <TableCell key={"companyaccountings-special-row-"+special.specialid+"-cell-sum"} align="center" className={this.props.classes.cellSumBody}>
                                            {sumForSpecialInMonth}
                                        </TableCell>
                                    </TableCell>
                                    {weekDaysInMonth.map((weekDay, dayIndex) => {
                                        let isToday = false;
                                        if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                            isToday = true;
                                        }

                                        let isWeekEnd = false;
                                        if( weekDay.index === 0 || weekDay.index === 6 ) {
                                            isWeekEnd = true;
                                        }

                                        // Gather content of this cell
                                        let dateJson = `${dateJsonBase}-`
                                        if( dayIndex+1 < 10 ) {
                                            dateJson += `0${dayIndex+1}`
                                        } else {
                                            dateJson += `${dayIndex+1}`
                                        }

                                        // Check if hours on this day shall be editable because auto lock of accountings might be enabled given a certain period of days
                                        const dayLocked = this.isDayLocked( dateJson );

                                        // Determine final lock stage of this cell
                                        let allowEditOfHours = true;
                                        if( ( true === specialAutoAccounting && true === this.props.specialAutoAccountingLock ) || true === dayLocked ) {
                                            allowEditOfHours = false;
                                        }

                                        let content = this.getAccountingsContent( dateJson, null, special.specialid );

                                        return this.renderTableCell( year, month, dayIndex, isWeekEnd, isToday, this.props.currentUser.userid, null, special.specialid, content, allowEditOfHours );
                                    })}
                                </TableRow>
                            )
                        })}
                        <TableRow key={"companyaccountings-bottom-row-divider"}>
                            <TableCell key={"companyaccountings-bottom-row-divider-cell-name"} align="left" className={this.props.classes.cellDividerBottomName} />
                            {weekDaysInMonth.map((weekDay, dayIndex) => {
                                let isToday = false;
                                if( todayYear === year && todayMonth === month && todayDate === (dayIndex+1) ) {
                                    isToday = true;
                                }
                                if( true === isToday ) {
                                    return (
                                        <TableCell key={"companyaccountings-bottom-row-divider-cell-"+dayIndex} align="left" className={this.props.classes.cellDividerBottomDayToday} />
                                    )
                                } else {
                                    return (
                                        <TableCell key={"companyaccountings-bottom-row-divider-cell-"+dayIndex} align="left" className={this.props.classes.cellDividerBottomDay} />
                                    )
                                }
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    render() {
        // Render the accountings for the given year and month
        const accountings = this.renderAccountings( this.props.year, this.props.month );

        return (
            <div>
                {accountings}
                {this.props.children}
                <ModalDialog 
                    open={this.state.modifyDialog.open}
                    title={i18n.t("pages.main.modify.dialog.title")}
                    descText={i18n.t("pages.main.modify.dialog.description")}
                    buttonLeft={i18n.t("pages.main.modify.dialog.button_left")}
                    buttonRight={i18n.t("pages.main.modify.dialog.button_right")}
                    handleClickClose={() => this.handleModifyDialog_Cancel()}
                    handleClickLeft={(contents) => this.handleModifyDialog_Ok(contents, this.state.modifyDialog.entry)}
                    handleClickRight={() => this.handleModifyDialog_Cancel()}
                    handleClickDelete={() => this.handleModifyDialog_Delete(this.state.modifyDialog.entry)}
                    showDeleteButton={true}
                    disableDeleteButton={false}
                    buttonDeleteTooltip={i18n.t("pages.main.modify.dialog.button_delete_tooltip")}
                >
                    <ModalDialogTextField
                        key="accounting-comment"
                        value={this.state.modifyDialog.comment}
                        label={i18n.t("pages.main.modify.dialog.labels.comment")}
                        verifyContent={(content) => this.verifyModifyDialog_Text(content, this.state.modifyDialog.entry)} 
                        multiline={true}
                        rows={12}
                        rowsMax={12}
                        margin="none"
                        maxLength={config.maxTextLengths.accounting.comment}
                        required={false}
                        allowEmpty={true}
                        autoFocus={true}
                    />
                </ModalDialog>
                <ModalDialog 
                    open={this.state.workTimeDialog.open}
                    title={i18n.t("pages.main.worktime.dialog.title", { date: toDateOnlyString( this.state.workTimeDialog.data.dateJson ) })}
                    descText={i18n.t("pages.main.worktime.dialog.description")}
                    buttonLeft={i18n.t("pages.main.worktime.dialog.button_left")}
                    buttonRight={i18n.t("pages.main.worktime.dialog.button_right")}
                    handleClickClose={() => this.handleWorkTimeDialog_Cancel()}
                    handleClickLeft={(contents) => this.handleWorkTimeDialog_Ok(this.state.workTimeDialog.data.dateJson, contents)}
                    handleClickRight={() => this.handleWorkTimeDialog_Cancel()}
                    handleClickDelete={(contents) => this.handleWorkTimeDialog_Delete(this.state.workTimeDialog.data.dateJson, contents)}
                    showDeleteButton={true}
                    disableDeleteButton={false}
                    buttonDeleteTooltip={i18n.t("pages.main.worktime.dialog.button_delete_tooltip")}
                    minWidth="500px"
                >
                    <ModalDialogWorkTime
                        key="worktime-dialog"
                        data={this.state.workTimeDialog.data}
                    />
                </ModalDialog>
                <ModalDialog 
                    open={this.state.workTimeNoAccountingsDialog.open}
                    title={i18n.t("pages.main.worktime.dialog_noaccountings.title", { date: toDateOnlyString( this.state.workTimeNoAccountingsDialog.dateJson ) })}
                    descText={i18n.t("pages.main.worktime.dialog_noaccountings.description")}
                    buttonRight={i18n.t("pages.main.worktime.dialog_noaccountings.button_right")}
                    handleClickClose={() => this.handleWorkTimeNoAccountingsDialog_Cancel()}
                    handleClickRight={() => this.handleWorkTimeNoAccountingsDialog_Cancel()}
                    showButtonLeft={false}
                    showDeleteButton={false}
                />                
            </div>
        );
    }
};

export default withStyles(useStyles)(AccountingUser);