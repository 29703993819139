import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Grid, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import HelpIcon from '@material-ui/icons/Help';
import BugReportIcon from '@material-ui/icons/BugReport';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountMenu from './AccountMenu';
import AboutMenu from './AboutMenu';
import { StyledMenu } from '../StyledMenu.js'
import AlertMessage from '../../components/AlertMessage'
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import i18n from 'i18next';

const useStyles = theme => ({
    root: {
        display: 'flex'
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        margin: theme.spacing(1),
        color: theme.mainBar.title
    },
    bugButton: {
        float: theme.right,
        margin: theme.spacing(1),
        color: "#ffffff",
        '&:hover': {
            color: '#ffffff'
        },
        '&:focus': {
            color: '#ffffff'
        }
    },
    helpButton: {
        float: theme.right,
        margin: theme.spacing(1)
    },
    accountButton: {
        float: theme.right,
        margin: theme.spacing(1),
    },
    drawerButton: {
        float: theme.left,
    },
    popupMenu: {
        "& .MultiListItem-root": {
            width: 150
        }
    },
    mainBar: {
        zIndex: theme.zIndex.appBar
    },
    menu: {
        color: theme.mainBar.subMenuText,
        marginRight: "5px"
    },
    sideGrid: {
        width: "33%"
    },
    alertGrid: {
        width: "33%",
        maxHeight: "60px",
        marginTop: "0px",
        marginBottom: "0px"
    },
    alertMessage: {
        height: "60px",
        marginTop: "0px",
        marginBottom: "0px"
    },
});

/**
 * Top level navigation bar
 */
class MainBar extends Component {
    state = {
        openAccount: false,
        openHelp: false,
        anchorAccountMenu: null,
        anchorHelpMenu: null
    }

    constructor(props) {
        super(props);
        this.handleToggleDrawer = this.handleToggleDrawer.bind( this );
        this.handleClickAccount = this.handleClickAccount.bind( this );
        this.handleClickHelp = this.handleClickHelp.bind( this );
    }

    handleToggleDrawer(event) {
        event.preventDefault();
        if( this.props.onToggleDrawer ) {
            this.props.onToggleDrawer();
        }
    }

    handleClickAccount(event) {
        event.preventDefault();
        this.setState( { openAccount: !this.state.openAccount, openHelp: false, anchorAccountMenu: event.currentTarget } );
    }

    handleClickHelp(event) {
        event.preventDefault();
        this.setState( { openAccount: false, openHelp: !this.state.openHelp, anchorHelpMenu: event.currentTarget } );
    }

    render() {
        const { classes } = this.props;

        // Check if we have an active alert of the subpage
        let contentAlert = "";
        if( null != this.props.alert ) {
            if( this.props.alert.message !== "" ) {
                contentAlert = (
                    <Grid container justify="center" spacing={0} className={classes.alertGrid}>
                        <AlertMessage className={classes.alertMessage} alert={this.props.alert} />
                    </Grid>
                )
            }
        }

        return (
            <AppBar position="fixed" className={classes.mainBar}>
                <Toolbar>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item container justify="flex-start" alignItems="center" className={classes.sideGrid}>
                            <Grid item>
                                <Tooltip title={i18n.t("tooltips.button_drawer")}>
                                    <IconButton edge="start" className={classes.drawerButton} color="inherit" onClick={this.handleToggleDrawer}>
                                        <MenuIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" className={classes.title}>
                                    {this.props.pageTitle}
                                </Typography>
                            </Grid>
                        </Grid>
                        {contentAlert}
                        <Grid item container justify="flex-end" alignItems="center" className={classes.sideGrid}>
                            <Grid item>
                                <Tooltip title={i18n.t("tooltips.button_bugreport")}>
                                    <IconButton edge="start" className={classes.bugButton} component={Link} color="inherit" target="_top" rel="noopener noreferrer" href={`mailto:thomas.woellert@atmosphere.aero`}>
                                        <BugReportIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={i18n.t("tooltips.button_profile")}>
                                    <IconButton edge="start" className={classes.accountButton} color="inherit" onClick={this.handleClickAccount}>
                                        <AccountCircleIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={i18n.t("tooltips.button_about")}>
                                    <IconButton edge="start" className={classes.helpButton} color="inherit" onClick={this.handleClickHelp}>
                                        <HelpIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    <StyledMenu anchorEl={this.state.anchorAccountMenu} keepMounted open={this.state.openAccount} onClick={this.handleClickAccount} onClose={this.handleClickAccount}>
                        <AccountMenu className={classes.menu}/>
                    </StyledMenu>
                    <StyledMenu anchorEl={this.state.anchorHelpMenu} keepMounted open={this.state.openHelp} onClick={this.handleClickHelp} onClose={this.handleClickHelp}>
                        <AboutMenu className={classes.menu}/>
                    </StyledMenu>
                </Toolbar>
            </AppBar>
        );
    }
}

export default withStyles(useStyles)(MainBar);
