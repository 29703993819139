import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import i18n from 'i18next';

const useStyles = theme => ({
    mainGridContainer: {
        display: 'flex',
        height: '80px',
        width: '100%',
        justifyContent: 'space-between',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    subGridItemLeft: {
        marginRight: '10px'
    },
    subGridItemRight: {
        marginLeft: '10px'
    },
    formControl: {
        marginBottom: theme.spacing(1.5),
        minWidth: '40ch'
    }
});

/**
 * Double select field
 */
class SelectDouble extends Component {
    state = {}

    constructor(props) {
        super(props);

        this.handleChangeLeftSelectField = this.handleChangeLeftSelectField.bind( this );
        this.handleChangeRightSelectField = this.handleChangeRightSelectField.bind( this );
    }

    // Called when new selection in the left select field
    handleChangeLeftSelectField( event ) {
        event.preventDefault();
        if( null != this.props.leftSelectProps.onChange) {
            this.props.leftSelectProps.onChange( event.target.value );
        }
    }

    // Called when new selection in the right select field
    handleChangeRightSelectField( event ) {
        event.preventDefault();
        if( null != this.props.rightSelectProps.onChange) {
            this.props.rightSelectProps.onChange( event.target.value );
        }
    }

    render() {
        const { classes } = this.props;

        let selectedEntry = {
            left: "",
            right: ""
        }
        let label = {
            left: "",
            right: "",
        }
        let helperText = {
            left: "",
            right: "",
        }
        let items = {
            left: [],
            right: []
        }
        let noSelectionLabel = {
            left: i18n.t("values.none"),
            right: i18n.t("values.none")
        }
        if( null != this.props.leftSelectProps ) { 
            selectedEntry.left = this.props.leftSelectProps.selectedEntry;
            label.left = this.props.leftSelectProps.label;
            helperText.left = this.props.leftSelectProps.helperText;
            items.left = this.props.leftSelectProps.items;
            noSelectionLabel.left = this.props.leftSelectProps.noSelectionLabel;
        }
        if( null != this.props.rightSelectProps ) { 
            selectedEntry.right = this.props.rightSelectProps.selectedEntry;
            label.right = this.props.rightSelectProps.label;
            helperText.right = this.props.rightSelectProps.helperText;
            items.right = this.props.rightSelectProps.items;
            noSelectionLabel.right = this.props.rightSelectProps.noSelectionLabel;
        }

        return (
            <Grid container direction="row" className={classes.mainGridContainer} spacing={0} wrap='nowrap'>
                <Grid item container>
                    <Grid item className={classes.subGridItemLeft}>
                        <FormControl variant="outlined" size="small" className={classes.formControl}>
                            <InputLabel>
                                {label.left}
                            </InputLabel>
                            <Select
                                displayEmpty
                                value={selectedEntry.left}
                                onChange={this.handleChangeLeftSelectField}
                                label={label.left}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                <MenuItem value="">
                                    <em>{noSelectionLabel.left}</em>
                                </MenuItem>
                                {items.left.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{helperText.left}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item className={classes.subGridItemRight}>
                        <FormControl variant="outlined" size="small" className={classes.formControl}>
                            <InputLabel>
                                {label.right}
                            </InputLabel>
                            <Select
                                displayEmpty
                                value={selectedEntry.right}
                                onChange={this.handleChangeRightSelectField}
                                label={label.right}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                <MenuItem value="">
                                    <em>{noSelectionLabel.right}</em>
                                </MenuItem>
                                {items.right.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{helperText.right}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
};

export default withStyles(useStyles)(SelectDouble);