import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import PersonIcon from '@material-ui/icons/Person';
import TuneIcon from '@material-ui/icons/Tune';
import GroupIcon from '@material-ui/icons/Group';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import BallotIcon from '@material-ui/icons/Ballot';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DescriptionIcon from '@material-ui/icons/Description';
import i18n from 'i18next';
import AuthContext from '../../contexts/AuthContext';

const useStyles = theme => ({
    adminNested: {
        paddingLeft: theme.spacing(2),
    },
});

function AdminMenuEntry( props ) {
    return (
        <ListItem button component={Link} to={props.toLink} className={props.classes.adminNested}>
            <ListItemIcon>
                {props.children}
            </ListItemIcon>
            <ListItemText primary={props.text} className={props.className}/>
        </ListItem>
    )
}

/**
 * Admin menu
 */
class AdminMenu extends Component {
    static contextType = AuthContext;

    state = {
        isMenuExpanded: false,
        showFlags: {
            header: false,
            users: false,
            roles: false,
            teams: false,
            projects: false,
            tasks: false,
            holidays: false,
            specials: false,
            contracts: false,
            accountings: false
        }
    }

    constructor(props) {
        super(props);
        this.handleAdminEntryClick = this.handleAdminEntryClick.bind( this );
    }

    // Handle click on admin menu entry
    handleAdminEntryClick(event) {
        event.preventDefault();
        this.setState( { isMenuExpanded: !this.state.isMenuExpanded } );
    }

    // Will mount after login
    async componentDidMount() {
        // Get the admin data for the users
        let showFlags = this.state.showFlags;

        const { statusCode, userAdminData } = await this.context.services.admin.getAdminData( this.context.userid );
        if( 200 === statusCode && null != userAdminData ) {
            
            // Gather which items to show from data
            if( true === userAdminData.admin.global ) {
                showFlags = {
                    header: true,
                    users: true,
                    roles: true,
                    teams: true,
                    projects: true,
                    tasks: true,
                    holidays: true,
                    specials: true,
                    contracts: true,
                    accountings: true
                }
            } else {

                showFlags.users = userAdminData.admin.users;
                showFlags.roles = userAdminData.admin.roles;
                showFlags.teams = userAdminData.admin.teams;
                showFlags.projects = userAdminData.admin.projects;
                showFlags.tasks = userAdminData.admin.tasks;
                showFlags.holidays = userAdminData.admin.holidays;
                showFlags.specials = userAdminData.admin.specials;
                showFlags.contracts = userAdminData.admin.contracts;
                showFlags.accountings = userAdminData.admin.accountings;

                // If any of these is true, we also need to show the header item
                showFlags.header = showFlags.users || showFlags.roles || showFlags.teams || showFlags.tasks
                    || showFlags.projects || showFlags.holidays || showFlags.specials || showFlags.contracts || showFlags.accountings;
            }
        }

        // Set the new state of the component
        this.setState({ showFlags: showFlags });
    }

    // Will unmount on logout
    componentWillUnmount() {
        // Clear all status data since that only happens on logout
        this.setState({ 
            showFlags: {
                header: false,
                users: false,
                roles: false,
                teams: false,
                projects: false,
                tasks: false,
                holidays: false,
                specials: false,
                contracts: false,
                accountings: false
            } 
        });
    }

    // Main render function
    render() {
        const { classes } = this.props;

        // Assemble content admin data
        let contentHeader = "";
        let contentMenu = "";
        let contentDivider = "";

        if( true === this.state.showFlags.header ) {
            contentHeader = (
                <ListItem key="header" button onClick={this.handleAdminEntryClick} className={this.props.className}>
                    <ListItemIcon><TuneIcon className={this.props.className}/></ListItemIcon>
                    <ListItemText primary={i18n.t("menus.admin")} />
                    {this.state.isMenuExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            );

            let menuEntries = [];
            if( true === this.state.showFlags.users ) {
                menuEntries.push(
                    <AdminMenuEntry key="accountings" toLink={"/admin?accountings"} text={i18n.t("menus.accountings")} classes={this.props.classes} className={this.props.className}>
                        <AccessTimeIcon className={this.props.className}/>
                    </AdminMenuEntry>
                )
                menuEntries.push(
                    <AdminMenuEntry key="users" toLink={"/admin?users"} text={i18n.t("menus.users")} classes={this.props.classes} className={this.props.className}>
                        <PersonIcon className={this.props.className}/>
                    </AdminMenuEntry>
                )
                menuEntries.push(
                    <AdminMenuEntry key="usertypes" toLink={"/admin?usertypes"} text={i18n.t("menus.usertypes")} classes={this.props.classes} className={this.props.className}>
                        <GroupWorkIcon className={this.props.className}/>
                    </AdminMenuEntry>
                )
            }
            if( true === this.state.showFlags.contracts ) {
                menuEntries.push(
                    <AdminMenuEntry key="contracts" toLink={"/admin?contracts"} text={i18n.t("menus.contracts")} classes={this.props.classes} className={this.props.className}>
                        <DescriptionIcon className={this.props.className}/>
                    </AdminMenuEntry>
                );
            }
            if( true === this.state.showFlags.roles ) {
                menuEntries.push(
                    <AdminMenuEntry key="roles" toLink={"/admin?roles"} text={i18n.t("menus.roles")} classes={this.props.classes} className={this.props.className}>
                        <AssignmentIndIcon className={this.props.className}/>
                    </AdminMenuEntry>
                );
            }
            if( true === this.state.showFlags.teams ) {
                menuEntries.push(
                    <AdminMenuEntry key="teams" toLink={"/admin?teams"} text={i18n.t("menus.teams")} classes={this.props.classes} className={this.props.className}>
                        <GroupIcon className={this.props.className}/>
                    </AdminMenuEntry>
                );
            }
            if( true === this.state.showFlags.projects ) {
                menuEntries.push(
                    <AdminMenuEntry key="projects" toLink={"/admin?projects"} text={i18n.t("menus.projects")} classes={this.props.classes} className={this.props.className}>
                        <BallotIcon className={this.props.className}/>
                    </AdminMenuEntry>
                );
            }
            if( true === this.state.showFlags.tasks ) {
                menuEntries.push(
                    <AdminMenuEntry key="tasks" toLink={"/admin?tasks"} text={i18n.t("menus.tasks")} classes={this.props.classes} className={this.props.className}>
                        <FormatListBulletedIcon className={this.props.className}/>
                    </AdminMenuEntry>
                );
            }
            if( true === this.state.showFlags.holidays ) {
                menuEntries.push(
                    <AdminMenuEntry key="holidays" toLink={"/admin?holidays"} text={i18n.t("menus.holidays")} classes={this.props.classes} className={this.props.className}>
                        <FreeBreakfastIcon className={this.props.className}/>
                    </AdminMenuEntry>
                );
            }
            if( true === this.state.showFlags.specials ) {
                menuEntries.push(
                    <AdminMenuEntry key="specials" toLink={"/admin?specials"} text={i18n.t("menus.absences")} classes={this.props.classes} className={this.props.className}>
                        <DirectionsRunIcon className={this.props.className}/>
                    </AdminMenuEntry>
                );
            }

            // Assemble all admin menu content
            contentMenu = (
                <Collapse in={this.state.isMenuExpanded} timeout="auto" unmountOnExit>
                    <List className={classes.list}>
                        {menuEntries}
                    </List>
                </Collapse>
            );
                    
            contentDivider = (
                <Divider className={this.props.dividerClassName}/>
            );
        }

        return (
            <div id="admin-menu">
                {contentHeader}
                {contentMenu}
                {contentDivider}
            </div>
        );
    }
};

export default withStyles(useStyles)(AdminMenu);