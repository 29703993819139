import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { StyledTableCell, StyledTableRow, StyledTableHead } from './StyledTable.js'
import Paper from '@material-ui/core/Paper';
import i18n from 'i18next';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    tableContainer: {
        width: "100%",
        marginTop: theme.spacing(1),
        padding: theme.spacing(1)
    },
    table: {
        tableLayout: "fixed"
    },
    cellTitle: {
        fontWeight: 500,
        fontSize: "12px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    },
    cellValue: {
        fontWeight: 500,
        fontSize: "12px",
        width: "100px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        userSelect: "none"
    }
})

/**
 * UserAbsenceSummary to be displayed with the absence calendar
 */
class UserAbsenceSummary extends Component {

    // Main render function
    render() {
        /**
         * Input data: 
         * this.props.year = current year
         * this.props.data = { daysFromLastYear, daysFromContract, daysTotal, daysBooked, daysAvailable, hoursOvertime }
         */

        // Create the table data array
        let tableData = [];
        if( null != this.props.data ) {
            tableData.push( { title: i18n.t("pages.absence.summary.from_last_year", { year: this.props.year-1 }), value: this.props.data.daysFromLastYear, align: "left", style: null } );
            tableData.push( { title: i18n.t("pages.absence.summary.from_contracts", { year: this.props.year }), value: this.props.data.daysFromContract, align: "left", style: null } );
            tableData.push( { title: i18n.t("pages.absence.summary.adjusted", { year: this.props.year }), value: this.props.data.daysAdjusted, align: "left", style: null } );
            tableData.push( { title: i18n.t("pages.absence.summary.total", { year: this.props.year }), value: this.props.data.daysTotal, align: "left", style: null } );
            tableData.push( { title: i18n.t("pages.absence.summary.booked", { year: this.props.year }), value: this.props.data.daysBooked, align: "left", style: null } );
            tableData.push( { title: i18n.t("pages.absence.summary.available", { year: this.props.year }), value: this.props.data.daysAvailable, align: "left", style: null } );
            tableData.push( { title: i18n.t("pages.absence.summary.overtime_hours"), value: this.props.data.hoursOvertime, align: "center", style: { borderStyle: "solid", borderLeftWidth: 1 } } );
        }

        return (
            <TableContainer key={"usersummary-container"} component={Paper} className={this.props.classes.tableContainer}>
                <Table key={"usersummary-table"} className={this.props.classes.table}>
                    <StyledTableHead key={"usersummary-header"}>
                        <StyledTableRow key={"usersummary-header-row-1"}>
                            <StyledTableCell key={"usersummary-header-row-1-col-1"} colSpan={tableData.length-1} align="center">{i18n.t("pages.absence.summary.headline", { year: this.props.year })}</StyledTableCell>
                            <StyledTableCell key={"usersummary-header-row-1-col-2"} align="center" style={{ borderStyle: "solid", borderLeftWidth: 1 }} />
                        </StyledTableRow>
                    </StyledTableHead>
                    <TableBody key={"usersummary-body"}>
                        <StyledTableRow key={"usersummary-header-row-1"}>
                            {tableData.map((row, index) => {
                                return (
                                    <StyledTableCell key={"usersummary-body-row-1-col-"+index} colSpan={1} align={row.align} className={this.props.classes.cellTitle} style={row.style}>
                                        {row.title}
                                    </StyledTableCell>
                                )
                            })}
                        </StyledTableRow>
                        <StyledTableRow key={"usersummary-body-row-2"} >
                            {tableData.map((row, index) => {
                                return (
                                    <StyledTableCell key={"usersummary-body-row-2-col-"+index} align={row.align} className={this.props.classes.cellValue} style={row.style}>
                                        {row.value}
                                    </StyledTableCell>
                                )
                            })}
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
};

export default withStyles(useStyles)(UserAbsenceSummary);