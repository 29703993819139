import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import i18n from 'i18next';

const useStyles = theme => ({
    buttonGrey: {
        color: "#b8b8b8"
    },
    buttonBlue: {
        color: theme.palette.primary.main
    },
    buttonRed: {
        color: theme.palette.error.main
    },
    buttonGreen: {
        color: theme.palette.success.main
    },
    buttonYellow: {
        color: theme.palette.warning.main
    }
});

/**
 * WorkTime Button
 */
class WorkTimeButton extends Component {
    state = {
        searchFieldEntry: ""
    }

    constructor(props) {
        super(props);
        this.handleButtonClick = this.handleButtonClick.bind( this );
    }

    // Called on button click
    handleButtonClick(event) {
        event.stopPropagation();
        if( null != this.props.onClick) {
            this.props.onClick(event);
        }
    }

    // Generate the props
    generateProps() {

        /**
         * Populate the given props if they have not been yet specified
         * * Props: date, isDayLocked, hasWorkTimeEntryToday, workTimeWarnings
         */

        let tooltip = null;
        let className = null;
        let disable = false;

        // Make sanity check on necessary props
        if( null == this.props.date || null == this.props.isDayLocked || 
            null == this.props.hasWorkTimeEntryToday || null == this.props.workTimeWarnings ) {
                return { tooltip, className, disable };
        }

        // Set the defaults
        tooltip = null;
        className = this.props.classes.buttonBlue;
        disable = false;

        let dateToday = new Date();
        dateToday.setHours(0,0,0,0);

        // Make the color of the button grey if NO worktime has been entered for this day AND:
        // * The current day is a holiday OR
        // * The current day is not a work day for the user
        // Make the color of the button red if NO worktime has been entered for this day but it is a regular work day AND the day is past today
        // Make the color of the button green if worktime has been entered for this day
        if( false === this.props.hasWorkTimeEntryToday ) {
            tooltip = i18n.t("pages.main.tooltip.no_work_time_entered");
            // Regular work day, check if the day is in the past
            if( this.props.date.getTime() < dateToday ) {
                tooltip += i18n.t("pages.main.tooltip.should_have_work_time");
                className = this.props.classes.buttonRed;
            }
        } else {
            // We have worktimes for this day, add work entry details to tooltip
            className = this.props.classes.buttonGreen;

            if( this.props.workTimeWarnings.length > 0 ) {
                className = this.props.classes.buttonYellow;
                tooltip = i18n.t("pages.main.tooltip.worktime_warnings");
            } else {
                tooltip = i18n.t("pages.main.tooltip.worktime_ok");
            }
        }

        // Lastly overrule any colors with grey in case the auto lock period has already passed
        if( true === this.props.isDayLocked ) {
            tooltip += i18n.t("pages.main.tooltip.day_locked");
            className = this.props.classes.buttonGrey;
            disable = true;
        }

        return { tooltip, className, disable };
    }

    /**
     * RENDERER
     */
    render() {
        /**
         * Props:
         * * tooltip := Optional, if null auto generated
         * * className := Optional, if null auto generated
         * * disable := Optional, if null auto generated
         * 
         * Necessary props for auto generation:
         * * date, isHoliday, isWorkDay, isDayLocked, hasWorkTimeEntryToday, workTimeWarnings
         */
        let tooltip = null;
        if( null != this.props.tooltip ) {
            tooltip = this.props.tooltip;
        }

        let className = null;
        if( null != this.props.className ) {
            className = this.props.className;
        }

        let disable = false;
        if( null != this.props.disable ) {
            disable = this.props.disable;
        }

        // Auto generate the necessary props if they have not been given by the parent component
        const generatedProps = this.generateProps();

        if( null == tooltip ) { tooltip = generatedProps.tooltip; }
        if( null == className ) { className = generatedProps.className; }
        if( null == disable ) { disable = generatedProps.disable; }

        // Render everything
        if( null != tooltip ) {
            return (
                <Tooltip title={tooltip}>
                    <div>
                        <AccessTimeIcon 
                            className={className}
                            disabled={disable}
                            onClick={(event) => this.handleButtonClick(event)}
                        />
                    </div>
                </Tooltip>
            )
        } else {
            return (
                <AccessTimeIcon 
                    className={className}
                    disabled={disable}
                    onClick={(event) => this.handleButtonClick(event)}
                />
            )
        }
    }
};

export default withStyles(useStyles)(WorkTimeButton);
