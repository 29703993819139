import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 */
class RoleService {

    /**
     * Get roles
     * @param {*} roleSearch { roleid, name }
     */
    async getRoles( roleSearch ) {
        var request = {
            query: `
            query getRoles($roleSearch: RoleSearch!) {
                getRoles(roleSearch: $roleSearch) {
                    roleid
                    name
                    admin_users
                    admin_teams
                    admin_projects
                    admin_roles
                    admin_specials
                    admin_holidays
                    admin_absences
                    admin_tasks
                    admin_accountings
                    admin_contracts
                    has_users
                }
            }
            `,
            variables: {
                roleSearch: roleSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Create a new role
     * @param {*} roleInput { name, admin_users, admin_teams, admin_projects, admin_roles, admin_specials, admin_holidays, admin_absences, admin_tasks, admin_accountings, admin_contracts }
     */
    async createRole( roleInput ) {
        var request = {
            query: `
            mutation createRole($roleInput: RoleInput) {
                createRole(roleInput: $roleInput) {
                    roleid
                    name
                    admin_users
                    admin_teams
                    admin_projects
                    admin_roles
                    admin_specials
                    admin_holidays
                    admin_absences
                    admin_tasks
                    admin_accountings
                    admin_contracts
                    has_users
                }
            }
            `,
            variables: {
                roleInput: roleInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete a role
     * @param {*} roleSearch { roleid, name }
     */
    async deleteRole( roleSearch ) {
        var request = {
            query: `
            mutation deleteRole($roleSearch: RoleSearch!) {
                deleteRole(roleSearch: $roleSearch) {
                    result
                }
            }
            `,
            variables: {
                roleSearch: roleSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update a role
     * @param {*} roleSearch { roleid, name }
     * @param {*} roleUpdate { name, admin_users, admin_teams, admin_projects, admin_roles, admin_specials, admin_holidays, admin_absences, admin_tasks, admin_accountings, admin_contracts }
     */
    async updateRole( roleSearch, roleUpdate ) {
        var request = {
            query: `
            mutation updateRole($roleSearch: RoleSearch!, $roleUpdate: RoleUpdate!) {
                updateRole(roleSearch: $roleSearch, roleUpdate: $roleUpdate) {
                    roleid
                    name
                    admin_users
                    admin_teams
                    admin_projects
                    admin_roles
                    admin_specials
                    admin_holidays
                    admin_absences
                    admin_tasks
                    admin_accountings
                    admin_contracts
                    has_users
                }
            }
            `,
            variables: {
                roleSearch: roleSearch,
                roleUpdate: roleUpdate
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new RoleService();
