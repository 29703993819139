import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { StyledDataTable } from '../components/StyledTable.js'
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Table Project Overview
 */
class TableProjectOverview extends Component {
    state = {}

    // Render project details
    renderProjectDetails( projectReport ) {

        // Fill in table header
        let tableHeader = [];
        tableHeader.push({ id: 1, width: "50%", name: i18n.t("pages.reports.options.project.table_details.details") });
        tableHeader.push({ id: 2, width: "50%", name: i18n.t("pages.reports.options.project.table_details.value") });

        // Fill in table data
        let tableData = [];

        // Show all details, convert dates to proper format
        {
            let valueString = i18n.t("values.none");
            if( null != projectReport.resultProject.create_date && projectReport.resultProject.create_date !== "" ) {
                valueString = new Intl.DateTimeFormat(this.props.language, {
                    year: "numeric", 
                    month: "short", 
                    day: "2-digit" 
                }).format( new Date( projectReport.resultProject.create_date ) );
            }
            tableData.push({ key: i18n.t("pages.reports.options.project.table_details.create_date"), values: [ valueString ]});
        }
        {
            let valueString = i18n.t("values.none");
            if( null != projectReport.resultProject.status_change_date && projectReport.resultProject.status_change_date !== "" ) {
                valueString = new Intl.DateTimeFormat(this.props.language, {
                    year: "numeric", 
                    month: "short", 
                    day: "2-digit" 
                }).format( new Date( projectReport.resultProject.status_change_date ) );
            }
            tableData.push({ key: i18n.t("pages.reports.options.project.table_details.status_change_date"), values: [ valueString ]});
        }

        let table = (
            <StyledDataTable 
                tableKey="project-details" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // Render task summary
    renderTaskSummary( projectReport ) {

        // Fill in table header
        let tableHeader = [];
        tableHeader.push({ id: 1, width: "50%", name: i18n.t("pages.reports.options.project.table_overview_tasks.task") });
        tableHeader.push({ id: 2, width: "50%", name: i18n.t("pages.reports.options.project.table_overview_tasks.accounted_hours") });

        // Fill in table data
        let tableData = [];

        // Sort by task name
        projectReport.resultTasks.sort(function(a, b) { 
            if( a.name > b.name ) return 1;
            if( a.name < b.name ) return -1;
            return 0;
        });

        // Show all accounted hours per task
        for( let i = 0; i < projectReport.resultTasks.length; i++ ) {
            if( 0 < projectReport.resultTasks[i].hours_accounted_total ) {
                tableData.push({ key: projectReport.resultTasks[i].name, values: [ projectReport.resultTasks[i].hours_accounted_total ]});
            }
        }

        // Add a line at the bottom showing the total number of accounted hours on the project
        tableData.push({ bold: true, key: i18n.t("pages.reports.options.project.table_overview_tasks.total_on_project"), values: [ projectReport.resultProject.hours_accounted_total ] });

        let table = (
            <StyledDataTable 
                tableKey="project-task-summary" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // Render user summary
    renderUserSummary( projectReport ) {

        // Fill in table header
        let tableHeader = [];
        tableHeader.push({ id: 1, width: "50%", name: i18n.t("pages.reports.options.project.table_overview_users.user") });
        tableHeader.push({ id: 2, width: "50%", name: i18n.t("pages.reports.options.project.table_overview_users.accounted_hours") });

        // Fill in table data
        let tableData = [];

        // Sort user data
        projectReport.resultUsers.sort(function(a, b) { 
            // Sort by user type first, then by user lastname
            if( a.type > b.type ) return 1;
            if( a.type < b.type ) return -1;
            if( a.lastname > b.lastname ) return 1;
            if( a.lastname < b.lastname ) return -1;
            return 0;
        });

        // Show all accounted hours per task
        for( let i = 0; i < projectReport.resultUsers.length; i++ ) {
            if( 0 < projectReport.resultUsers[i].hours_accounted_total ) {
                let userName = "";
                if( "" !== projectReport.resultUsers[i].type ) {
                    userName = "(" + projectReport.resultUsers[i].type + ") ";
                }
                userName = userName + "" + projectReport.resultUsers[i].lastname + ", " + projectReport.resultUsers[i].firstname;

                tableData.push({ key: userName, values: [ projectReport.resultUsers[i].hours_accounted_total ]});
            }
        }

        let table = (
            <StyledDataTable 
                tableKey="project-user-summary" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // Takes props: language, projectReport, dateMin, dateMax
    render() {
        let projectDetails = "";
        let taskSummary = "";
        let userSummary = "";
        if( null != this.props.projectReport ) {
            projectDetails = this.renderProjectDetails( this.props.projectReport );
            taskSummary = this.renderTaskSummary( this.props.projectReport );
            userSummary = this.renderUserSummary( this.props.projectReport );
        }

        return (
            <div>
                {projectDetails}
                <br />
                {taskSummary}
                <br />
                {userSummary}
            </div>
        );
    }
};

export default withStyles(useStyles)(TableProjectOverview);