import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = theme => ({});

/**
 * Modal Dialog Text Field Component
 */
class ModalDialogTextField extends Component {
    state = {
        value: "",
        contentVerified: false,
        helperText: ""
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind( this );
    }

    // Determine the button state
    getButtonState( value, contentVerified ) {
        let required = true;
        if( null != this.props.required ) {
            required = this.props.required;
        }

        if( ( !required || value !== "" ) && true === contentVerified ) {
            return true;
        } else {
            return false;
        }
    }

    // General push of new data to parent
    updateControlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent ) {
        if( null != this.props.controlButtonsAndPushContent ) {
            this.props.controlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent );
        }
    }

    // Name text field changed
    handleChange(event) {
        let value = event.target.value;

        let forceLowerCase = false;
        if( null != this.props.forceLowerCase ) {
            forceLowerCase = this.props.forceLowerCase;
        }
        if( true === forceLowerCase ) {
            value = value.toLowerCase();
        }

        if( null != this.props.maxLength ) {
            if( value.length > this.props.maxLength ) {
                return;
            }
        }

        // Obey numbers only
        if( null != this.props.numbersOnly && true === this.props.numbersOnly && value !== "" ) {

            // Check if we are also allowing negative digits
            if( ( null != this.props.allowNegativeNumbers && true === this.props.allowNegativeNumbers ) || ( null != this.props.minValue && 0 > this.props.minValue ) ) {

                if( null != this.props.allowFractions && true === this.props.allowFractions ) {
                    // Check for negative or positive fractional digits
                    if( false === /^-?\d+(\.\d*)?$/.test(value) && value !== "-" ) {
                        return;
                    }
                } else {
                    // Check for negative or positive integer digits
                    if( false === /^-?\d+$/.test(value) && value !== "-" ) {
                        return;
                    }
                }
            } else {
                if( null != this.props.allowFractions && true === this.props.allowFractions ) {
                    // Check for positive fractional digits
                    if( false === /^\d+(\.\d*)?$/.test(value) ) {
                        return;
                    }
                } else {
                    // Check for positive integer digits
                    if( false === /^\d+$/.test(value) ) {
                        return;
                    }
                }
            }

            // Also check for max and min values if configured
            if( null != this.props.minValue ) {
                if( value < this.props.minValue ) {
                    return;
                }
            }
            if( null != this.props.maxValue ) {
                if( value > this.props.maxValue ) {
                    return;
                }
            }
        }

        // Verify the new value
        const { state, msg } = this.props.verifyContent({ value: value });

        const buttonState = this.getButtonState( value, state );
        this.updateControlButtonsAndPushContent( buttonState, true, { value: value } );
        this.setState({ value: value, contentVerified: state, helperText: msg });
    }

    // Called by react when this component has been mounted
    componentDidMount() {

        // Check for an incoming value from the parent
        let value = "";
        if( null != this.props.value ) {
            value = this.props.value;
        }

        const { state, msg } = this.props.verifyContent({ value: value });
        const buttonState = this.getButtonState( value, state );
        this.updateControlButtonsAndPushContent( buttonState, true, { value: value } );
        this.setState({ value: value, contentVerified: state, helperText: msg });
    }

    // React render
    render() {
        // Used this.props
        // setLeftButtonState(bool), setRightButtonState(bool), setContent(json), verifyContent(json), label, maxLength, forceLowerCase

        let margin = "normal";
        if( null != this.props.margin ) {
            margin = this.props.margin;
        }

        let required = true;
        if( null != this.props.required ) {
            required = this.props.required;
        }

        // Min height is different for one row or multiple rows
        let style = {
            minHeight: "5rem",
            minWidth: "25rem",
            marginBottom: "0"
        }
        if( null != this.props.rows ) {
            const height = 1.60*this.props.rows;
            style.minHeight = `${height}rem`
        }

        let textFieldContent = "";
        if( null != this.props.allowEmpty && true === this.props.allowEmpty ) {
            textFieldContent = (
                <TextField
                    value={this.state.value}
                    variant="outlined"
                    margin={margin}
                    required={required}
                    id="name"
                    label={this.props.label}
                    fullWidth
                    name="name"
                    onChange={this.handleChange}
                    autoFocus={this.props.autoFocus}
                    error={false === this.state.contentVerified}
                    helperText={this.state.helperText}
                    style={style}
                    multiline={this.props.multiline}
                    rows={this.props.rows}
                    rowsMax={this.props.rowsMax}
                />
            )
        } else {
            textFieldContent = (
                <TextField
                    value={this.state.value}
                    variant="outlined"
                    margin={margin}
                    required={required}
                    id="name"
                    label={this.props.label}
                    fullWidth
                    name="name"
                    onChange={this.handleChange}
                    autoFocus={this.props.autoFocus}
                    error={this.state.value === "" || false === this.state.contentVerified}
                    helperText={this.state.helperText}
                    style={style}
                    multiline={this.props.multiline}
                    rows={this.props.rows}
                    rowsMax={this.props.rowsMax}
                />
            )
        }
    
        return (
            <React.Fragment>
                {textFieldContent}
            </React.Fragment>
        );
    }
};

export default withStyles(useStyles)(ModalDialogTextField);