import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = theme => ({
    dialog: {
        zIndex: theme.zIndex.modal
    },
    dialogTitle: {
        color: "#ffffff",
        backgroundColor: theme.palette.primary.main,
        width: "100%"
    }
});

/**
 * Modal Dialog
 */
class ModalDialog extends Component {
    state = {
        enableLeftButtons: [],
        enableRightButtons: [],
        childContents: [],
        enableLeftButtonResult: true,
        enableRightButtonResult: true
    };

    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleLeftButtonClick = this.handleLeftButtonClick.bind( this );
        this.handleRightButtonClick = this.handleRightButtonClick.bind( this );
        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind( this );
        this.controlButtonsAndPushContent = this.controlButtonsAndPushContent.bind( this );
    }

    // Left button clicked
    handleLeftButtonClick() {
        if( this.props.handleClickLeft != null ) {
            if( 1 === this.state.childContents.length ) {
                this.props.handleClickLeft( this.state.childContents[0] ) 
            } else {
                this.props.handleClickLeft( this.state.childContents );
            }
        }
    }

    // Right button clicked
    handleRightButtonClick() {
        if( this.props.handleClickRight != null ) {
            if( 1 === this.state.childContents.length ) {
                this.props.handleClickRight( this.state.childContents[0] ) 
            } else {
                this.props.handleClickRight( this.state.childContents );
            }
        }
    }

    // Delete button clicked
    handleDeleteButtonClick() {
        if( this.props.handleClickDelete != null ) {
            if( 1 === this.state.childContents.length ) {
                this.props.handleClickDelete( this.state.childContents[0] ) 
            } else {
                this.props.handleClickDelete( this.state.childContents );
            }
        }
    }

    // General control function called by the child to enable/disable buttons and store its content
    controlButtonsAndPushContent( index, enableLeftButton, enableRightButton, childContent ) {

        // Store input in new arrays
        let enableLeftButtons = this.state.enableLeftButtons;
        enableLeftButtons[index] = enableLeftButton;
        
        let enableRightButtons = this.state.enableRightButtons;
        enableRightButtons[index] = enableRightButton;
        
        let childContents = this.state.childContents;
        childContents[index] = childContent;

        // Calculate new result button states
        let enableLeftButtonResult = true;
        for( let i = 0; i < enableLeftButtons.length; i++ ) {
            if( false === enableLeftButtons[i] ) {
                enableLeftButtonResult = false;
                break;
            }
        }

        let enableRightButtonResult = true;
        for( let i = 0; i < enableRightButtons.length; i++ ) {
            if( false === enableRightButtons[i] ) {
                enableRightButtonResult = false;
                break;
            }
        }

        // Set new states
        this.setState({ 
            enableLeftButtons: enableLeftButtons, 
            enableRightButtons: enableRightButtons, 
            childContents: childContents,
            enableLeftButtonResult: enableLeftButtonResult,
            enableRightButtonResult: enableRightButtonResult
        });
    }

    render() {
        // Used this.props
        // open, title, descText, descComponent, buttonLeft, buttonRight, handleClickClose, handleClickLeft, handleClickRight

        // Allow the childs to modify the OK button state
        const childrenWithProps = React.Children.map(this.props.children, (child, index) => {
            // checking isValidElement is the safe way and avoids a typescript error too
            if (React.isValidElement(child)) {
                return React.cloneElement( child, 
                        { 
                            controlButtonsAndPushContent: (enableLeftButton, enableRightButton, childContent) => this.controlButtonsAndPushContent(index, enableLeftButton, enableRightButton, childContent),
                        } 
                    );
            }
            return child;
        });

        // Check if we got a custom delete icon
        let deleteIcon = ( <DeleteIcon /> );
        if( null != this.props.deleteIcon ) {
            deleteIcon = this.props.deleteIcon;
        }

        let showButtonLeft = true;
        if( null != this.props.showButtonLeft ) {
            showButtonLeft = this.props.showButtonLeft;
        }
        let showButtonRight = true;
        if( null != this.props.showButtonRight ) {
            showButtonRight = this.props.showButtonRight;
        }

        let content = (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClickClose}
                maxWidth={this.props.maxWidth}
                className={this.props.classes.dialog}
            >
                <DialogTitle className={this.props.classes.dialogTitle}>
                    {this.props.title}
                </DialogTitle>
                <DialogContent>
                    { this.props.descText != null && this.props.descText !== "" && (
                        <DialogContentText>
                            {this.props.descText}
                        </DialogContentText>
                    )}
                    { null != childrenWithProps && childrenWithProps.map((child, i) => {
                        return ( 
                            <div key={"dialog-child-" + i}>
                                {child}
                            </div>
                        )
                    })}
                    <DialogActions>
                        {this.props.showDeleteButton && (
                            <Tooltip title={this.props.buttonDeleteTooltip}>
                                <div>
                                    <Button variant="contained" onClick={this.handleDeleteButtonClick} color="primary" disabled={this.props.disableDeleteButton}>
                                        {deleteIcon}
                                    </Button>
                                </div>
                            </Tooltip>
                        )}
                        {showButtonLeft && ( 
                            <Button onClick={this.handleLeftButtonClick} variant="contained" disabled={!this.state.enableLeftButtonResult} color="primary">
                                {this.props.buttonLeft}
                            </Button>
                        )}
                        {showButtonRight && ( 
                            <Button onClick={this.handleRightButtonClick} variant="contained" disabled={!this.state.enableRightButtonResult} color="primary">
                                {this.props.buttonRight}
                            </Button>
                        )}
                    </DialogActions>
                </DialogContent>
            </Dialog>
        );

        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        );
    }
};

export default withStyles(useStyles)(ModalDialog);