import ApiService from './ApiService';

/**
 * Utility service for access to data on the API
 * All functions return { statusCode, statusText, resData }
 * All functions are tested by the API for authorization, so they might or
 * might not work depending on the users' access rights
 */
class ProjectService {

    /**
     * Get projects
     * @param {*} projectSearch { projectid, internal_id, name, description, status }
     */
    async getProjects( projectSearch ) {
        var request = {
            query: `
            query getProjects($projectSearch: ProjectSearch!) {
                getProjects(projectSearch: $projectSearch) {
                    projectid
                    internal_id
                    name
                    description
                    status
                    has_tasks
                    has_users
                    create_date
                    status_change_date
                }
            }
            `,
            variables: {
                projectSearch: projectSearch
            }
        };

        return await ApiService.doPost( request );
    }
    
    /**
     * Create a new project
     * @param {*} projectInput { internal_id, name, description, status }
     */
    async createProject( projectInput ) {
        var request = {
            query: `
            mutation createProject($projectInput: ProjectInput) {
                createProject(projectInput: $projectInput) {
                    projectid
                    internal_id
                    name
                    description
                    status
                    has_tasks
                    has_users
                    create_date
                    status_change_date
                }
            }
            `,
            variables: {
                projectInput: projectInput
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Delete a project
     * @param {*} projectSearch { projectid, internal_id, name, description, status }
     */
    async deleteProject( projectSearch ) {
        var request = {
            query: `
            mutation deleteProject($projectSearch: ProjectSearch!) {
                deleteProject(projectSearch: $projectSearch) {
                    result
                }
            }
            `,
            variables: {
                projectSearch: projectSearch
            }
        };

        return await ApiService.doPost( request );
    }

    /**
     * Update a project
     * @param {*} projectSearch { projectid, internal_id, name, description, status }
     * @param {*} projectUpdate { name, admin_users, admin_teams, admin_projects, admin_roles, admin_specials, admin_holidays, admin_absences, admin_tasks, admin_accountings, admin_contracts }
     */
    async updateProject( projectSearch, projectUpdate ) {
        var request = {
            query: `
            mutation updateProject($projectSearch: ProjectSearch!, $projectUpdate: ProjectUpdate!) {
                updateProject(projectSearch: $projectSearch, projectUpdate: $projectUpdate) {
                    projectid
                    internal_id
                    name
                    description
                    status
                    has_tasks
                    has_users
                    create_date
                    status_change_date
                }
            }
            `,
            variables: {
                projectSearch: projectSearch,
                projectUpdate: projectUpdate
            }
        };

        return await ApiService.doPost( request );
    }
}

export default new ProjectService();
