import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = theme => ({
    container: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    listBox: {
        paddingLeft: theme.spacing(3)
    },
    versionTypo: {
        fontSize: "20px",
        color: "#248EDB",
        flexBasis: "10%",
        flexShrink: 0
    },
    dateTypo: {
        fontSize: "16px",
        color: "#000000",
        paddingTop: "5px"
    },
    headerTypo: {
        fontSize: "16px",
        color: "#898989",
        fontWeight: "bold"
    },
    listTypo: {
        fontSize: "14px",
        color: "#000000",
    }
});

/**
 * ChangeLog
 */
class ChangeLog extends Component {

    // Rendering
    render() {

        // Grab the changelog if given
        let changelog = [];
        if( null != this.props.changelog ) {
            changelog = this.props.changelog;
        }

        // Assemble the content
        let contents = [];

        // Automatically expand latest version
        let defaultExpanded = true;

        let maxIteration = changelog.length;
        if( null != this.props.showOnlyLatest && true === this.props.showOnlyLatest ) {
            if( changelog.length > 0 ) {
                maxIteration = 1;
            }
        }

        for( let i = 0; i < maxIteration; i++ ) {
            const change = changelog[i];

            // Check if we should only show a certain version
            if( null != this.props.showOnlyVersion && this.props.showOnlyVersion !== "" ) {
                if( change.version !== this.props.showOnlyVersion ) {
                    continue;
                }
            }

            let contentVersion = (
                <Typography key={"changelog-version-"+i} align="left" className={this.props.classes.versionTypo} component={'span'}>
                    Version {change.version}
                </Typography>
            )

            let contentDate = (
                <Typography key={"changelog-date-"+i} align="left" className={this.props.classes.dateTypo} component={'span'}>
                    {change.date}
                </Typography>
            )

            let addMargin = "";

            let contentAdded = "";
            if( change.changed.length > 0 || change.fixed.length > 0 ) {
                addMargin = (
                    <br />
                )
            }
            if( change.added.length > 0 ) {
                contentAdded = (
                    <div>
                        <Typography key={"changelog-added-"+i} align="left" className={this.props.classes.headerTypo} component={'span'}>
                            Added
                        </Typography>
                        <Typography key={"changelog-added-list-"+i} align="left" className={this.props.classes.listTypo} component={'span'}>
                            <Box className={this.props.classes.listBox}>
                                <ul>
                                    {change.added.map((item, j) => (
                                        <li key={"changelog-added-list-"+i+"-"+j}>{item.text}</li>
                                    ))}
                                </ul>
                            </Box>
                        </Typography>
                        {addMargin}
                    </div>
                )
            }

            addMargin = "";

            let contentChanged = "";
            if( change.fixed.length > 0 ) {
                addMargin = (
                    <br />
                )
            }
            if( change.changed.length > 0 ) {
                contentChanged = (
                    <div>
                        <Typography key={"changelog-changed-"+i} align="left" className={this.props.classes.headerTypo} component={'span'}>
                            Changed
                        </Typography>
                        <Typography key={"changelog-changed-list-"+i} align="left" className={this.props.classes.listTypo} component={'span'}>
                            <Box className={this.props.classes.listBox}>
                                <ul>
                                    {change.changed.map((item, j) => (
                                        <li key={"changelog-changed-list-"+i+"-"+j}>{item.text}</li>
                                    ))}
                                </ul>
                            </Box>
                        </Typography>
                        {addMargin}
                    </div>
                )
            }

            let contentFixed = "";
            if( change.fixed.length > 0 ) {
                contentFixed = (
                    <div>
                        <Typography key={"changelog-fixed-"+i} align="left" className={this.props.classes.headerTypo} component={'span'}>
                            Fixed
                        </Typography>
                        <Typography key={"changelog-fixed-list-"+i} align="left" className={this.props.classes.listTypo} component={'span'}>
                            <Box className={this.props.classes.listBox}>
                                <ul>
                                    {change.fixed.map((item, j) => (
                                        <li key={"changelog-fixed-list-"+i+"-"+j}>{item.text}</li>
                                    ))}
                                </ul>
                            </Box>
                        </Typography>
                    </div>
                )
            }

            // Full content of this entry
            let content = "";
            
            let useAccordionStyle = true;
            if( null != this.props.useAccordionStyle ) {
                useAccordionStyle = this.props.useAccordionStyle;
            }

            if( true === useAccordionStyle ) {
                content = (
                    <Accordion defaultExpanded={defaultExpanded}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id={"changelog-accordion-"+i}
                        >
                            {contentVersion}
                            {contentDate}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {contentAdded}
                                {contentChanged}
                                {contentFixed}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )
            } else {
                content = (
                    <span>
                        {contentVersion} ({contentDate})
                        {contentAdded}
                        {contentChanged}
                        {contentFixed}
                    </span>
                )
            }

            defaultExpanded = false;
            contents.push( content );
        }


        return (
            <Paper className={this.props.classes.container}>
                {contents.map((content, i) => {
                    return ( 
                        <div key={"changelog-main-"+i}>
                            {content}
                        </div>
                    )
                })}
            </Paper>
        );
    }
};

export default withStyles(useStyles)(ChangeLog);