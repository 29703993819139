import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { StyledTableRow, StyledTableHead, StyledTable, StyledDataTable, StyledTableCellSmall } from './StyledTable.js'
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { config } from '../config';
import Paper from '@material-ui/core/Paper';
import CustomTimePicker from './CustomTimePicker.js';
import CustomBreakPicker from './CustomBreakPicker.js';
import { fromDateToTimeString, fromTimeStringToDate, removeSecondsFromTimeString, getWorkTimeSummary, getWorkTimeWarnings } from '../helpers.js';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import i18n from 'i18next';
import _ from 'lodash';

const moment = extendMoment(Moment);

const useStyles = theme => ({
    mainDiv: {},
    tableDiv: {
        paddingBottom: theme.spacing(2)
    },
    unsavedDiv: {
        paddingBottom: theme.spacing(1)
    },
    timePickerDiv: {
        paddingBottom: theme.spacing(0.5)
    },
    iconButton: {
        maxWidth: "20px",
        maxHeight: "20px"
    }
});

/**
 * Modal Dialog WorkTime Component
 */
class ModalDialogWorkTime extends Component {
    state = {
        data: null,
        dataModified: null,
        summary: {
            workTime: 0,
            breaks: 0,
            workTimeNoBreaks: 0,
            accountedTime: 0
        },
        warnings: [],
        unsavedChanges: false
    };

    constructor(props) {
        super(props);
        this.verifyContent = this.verifyContent.bind( this );
        this.handleStartTimeChanged = this.handleStartTimeChanged.bind( this );
        this.handleEndTimeChanged = this.handleEndTimeChanged.bind( this );
        this.handleBreakChanged = this.handleBreakChanged.bind( this );
        this.handleAddEntryClicked = this.handleAddEntryClicked.bind( this );
        this.handleRemoveEntryClicked = this.handleRemoveEntryClicked.bind( this );
    }

    // Verify the content
    verifyContent( data, dataModified, warnings ) {

        // Disable the OK button if there are warnings present
        if( warnings.length > 0 ) {
            return false;
        }

        // Disable the OK button if no changes have yet occured
        if( true === _.isEqual( data.worktimes.today, dataModified.worktimes.today ) ) {
            return false;
        }

        return true;
    }

    // General push of new data to parent
    updateControlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent ) {
        if( null != this.props.controlButtonsAndPushContent ) {
            this.props.controlButtonsAndPushContent( enableLeftButton, enableRightButton, childContent );
        }
    }

    // Initially populate the data
    populateData( data ) {
        let finalData = _.cloneDeep( data );

        // Check if we have any work times for today, if not generate some based on the accountings and the default work start time of the user
        if( 0 === data.worktimes.today.length ) {
            // No work times for today, generate them

            let defaultWorkStartTime = data.defaultWorkStartTime;
            if( null == defaultWorkStartTime ) {
                // Grab the default work start time from the config file.
                defaultWorkStartTime = config.workTimes.defaultWorkStartTime;

                // Also remember that in the populated data
                finalData.defaultWorkStartTime = defaultWorkStartTime;
            }

            // Generate the sum of accounted hours
            let sumOfAccountings = 0;
            for( let i = 0; i < finalData.accountings.length; i++ ) {
                sumOfAccountings += finalData.accountings[i].hours;
            }

            let momentStartTime = moment( fromTimeStringToDate( defaultWorkStartTime ) );
            let momentEndTime = momentStartTime;
            momentEndTime.add( sumOfAccountings, 'hours' );

            let breakDuration = 0;
            if( sumOfAccountings >= 6 && sumOfAccountings <= 9 ) {
                breakDuration = config.workTimes.break_short;
            } else if( sumOfAccountings > 9 ) {
                breakDuration = config.workTimes.break_long;
            }

            // Add the breaks
            momentEndTime.add( breakDuration, 'hours' );

            let dateEndTime = momentEndTime.toDate();

            let workTimeEntry = {
                start_time: defaultWorkStartTime,
                end_time: fromDateToTimeString( dateEndTime ),
                break: breakDuration
            }

            finalData.worktimes.today = [ workTimeEntry ];
        }
        
        return finalData;
    }

    // If the user edited the start time
    handleStartTimeChanged(event, id, value) {
        let dataModified = this.state.dataModified;

        if( dataModified.worktimes.today.length > id ) {
            // Quality of life feature: Check if we should automatically update the end time according to the change of the start_time
            let start_time_orig_elements = dataModified.worktimes.today[id].start_time.split(':');
            let start_time_modified_elements = value.split(':');
            let end_time_elements = dataModified.worktimes.today[id].end_time.split(':');

            // Convert time values from string to int
            for (var i = 0; i < 2; i++) {
                start_time_orig_elements[i] = isNaN(parseInt(start_time_orig_elements[i])) ? 0 : parseInt(start_time_orig_elements[i])
                start_time_modified_elements[i] = isNaN(parseInt(start_time_modified_elements[i])) ? 0 : parseInt(start_time_modified_elements[i])
                end_time_elements[i] = isNaN(parseInt(end_time_elements[i])) ? 0 : parseInt(end_time_elements[i])
            }

            dataModified.worktimes.today[id].start_time = value;

            let start_time_diff_hours = start_time_orig_elements[0] - start_time_modified_elements[0];
            let start_time_diff_minutes = start_time_orig_elements[1] - start_time_modified_elements[1];

            end_time_elements[0] = end_time_elements[0] - start_time_diff_hours;
            end_time_elements[1] = end_time_elements[1] - start_time_diff_minutes;
            if( end_time_elements[1] >= 60 ) {
                end_time_elements[1] = end_time_elements[1] - 60;
                end_time_elements[0] = end_time_elements[0] + 1;
            } else if( end_time_elements[1] < 0 ) {
                end_time_elements[1] = end_time_elements[1] + 60;
                end_time_elements[0] = end_time_elements[0] - 1;
            }
            if( end_time_elements[0] >= 24 ) {
                end_time_elements[0] = end_time_elements[0] - 24;
            }

            let end_time_hours = end_time_elements[0].toString();
            let end_time_minutes = end_time_elements[1].toString();
            if( end_time_elements[1] < 10 ) {
                end_time_minutes = "0" + end_time_minutes;
            }
            dataModified.worktimes.today[id].end_time = end_time_hours + ":" + end_time_minutes;
        }

        this.updateData( dataModified );
    }

    // If the user edited the end time
    handleEndTimeChanged(event, id, value) {
        let dataModified = this.state.dataModified;

        if( dataModified.worktimes.today.length > id ) {
            dataModified.worktimes.today[id].end_time = value;
        }

        this.updateData( dataModified );
    }

    // If the user edited the break time
    handleBreakChanged(event, id, value) {
        let dataModified = this.state.dataModified;

        if( dataModified.worktimes.today.length > id ) {
            dataModified.worktimes.today[id].break = parseFloat( value );
        }

        this.updateData( dataModified );
    }

    // If the user clicked on add entry
    handleAddEntryClicked(id) {
        let dataModified = _.cloneDeep( this.state.dataModified );

        // Add a new entry. Since there is always one entry present at least
        // we set the StartTime of the new Entry to the time of the EndTime of the previous entry
        if( dataModified.worktimes.today.length >= 1 ) {
            const lastEntry = dataModified.worktimes.today[dataModified.worktimes.today.length-1];
            
            const momentLastEntryEndTime = moment( fromTimeStringToDate( lastEntry.end_time ) );
            let momentNewEntryEndTime = moment( fromTimeStringToDate( lastEntry.end_time ) );
            momentNewEntryEndTime.add( 1, 'hours' );

            // Check if endtime of entry would be after 00:00 of this day, in this case refuse to add a new entry
            if( true === momentLastEntryEndTime.isSame( momentNewEntryEndTime, 'day' ) ) {
                let newEntry = {
                    start_time: lastEntry.end_time,
                    end_time: fromDateToTimeString( momentNewEntryEndTime.toDate() ),
                    break: 0
                }
    
                dataModified.worktimes.today.push( newEntry );
                this.updateData( dataModified );
            }
        }
    }

    // If the user clicked on remove entry
    handleRemoveEntryClicked(id) {
        let dataModified = this.state.dataModified;

        // Remove the entry if it exists
        if( dataModified.worktimes.today.length > id ) {
            dataModified.worktimes.today.splice( id, 1 );
        }

        this.updateData( dataModified );
    }

    // Update everything after a change
    updateData( dataModified ) {
        let summary = getWorkTimeSummary( dataModified );
        let warnings = getWorkTimeWarnings( dataModified, summary );
        const state = this.verifyContent( this.state.data, dataModified, warnings );

        if( true === state ) {
            warnings.push( i18n.t("pages.main.worktime.dialog.warnings.unsaved_changes") );
        }

        this.updateControlButtonsAndPushContent( state, true, { data: dataModified } );
        this.setState({ dataModified: dataModified, summary: summary, warnings: warnings, unsavedChanges: state });
    }

    // Called by react when this component has been mounted
    componentDidMount() {

        // Initially populate data in case parts of data are missing because no worktimes have yet been entered
        let data = this.populateData( this.props.data );
        let summary = getWorkTimeSummary( data );
        let warnings = getWorkTimeWarnings( data, summary );

        const state = this.verifyContent( this.props.data, data, warnings );

        if( true === state ) {
            warnings.push( i18n.t("pages.main.worktime.dialog.warnings.unsaved_changes") );
        }

        this.updateControlButtonsAndPushContent( state, true, { data: data } );
        this.setState({ data: this.props.data, dataModified: data, summary: summary, warnings: warnings, unsavedChanges: state });
    }

    /**
     * RENDERERS
     */

    // Render work times table
    renderWorkTimes() {
        const tableHeader = [
            { key: "start_time", width: "35%", align: "left", text: i18n.t("pages.main.worktime.dialog.worktimes.start_time") },
            { key: "end_time", width: "35%", align: "left", text: i18n.t("pages.main.worktime.dialog.worktimes.end_time") },
            { key: "break", width: "20%", align: "left", text: i18n.t("pages.main.worktime.dialog.worktimes.break") },
            { key: "add_row", width: "5%", align: "left", text: "" },
            { key: "remove_row", width: "5%", align: "left", text: "" }
        ];

        let tableData = [];
        if( null != this.state.dataModified ) {
            for( let i = 0; i < this.state.dataModified.worktimes.today.length; i++ ) {

                // Remove the seconds from the string
                tableData.push({
                    id: i,
                    start_time: removeSecondsFromTimeString( this.state.dataModified.worktimes.today[i].start_time ),
                    end_time: removeSecondsFromTimeString( this.state.dataModified.worktimes.today[i].end_time ),
                    break: this.state.dataModified.worktimes.today[i].break,
                });
            }
        }

        let table = (
            <TableContainer component={Paper}>
                <StyledTable size="small">
                    <StyledTableHead>
                        <StyledTableRow key={"worktime-dialog-worktimes"}>
                            {tableHeader.map((col) => (
                                <StyledTableCellSmall 
                                    key={col.key} 
                                    width={col.width}
                                    align={col.align}
                                >
                                    {col.text}
                                </StyledTableCellSmall>
                            ))}
                        </StyledTableRow>
                    </StyledTableHead>
                    <TableBody>
                        {tableData.map((row) => {
                            
                            // Always only show the add button on the last line
                            let showAddButton = false;
                            if( null != this.state.dataModified && this.state.dataModified.worktimes.today.length === (row.id+1) ) {
                                showAddButton = true;
                            }
                            
                            // Do not show the remove button if there is only one line left
                            let showRemoveButton = true;
                            if( null != this.state.dataModified && this.state.dataModified.worktimes.today.length === 1 ) {
                                showRemoveButton = false;
                            }
                            
                            return (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCellSmall key="start_time" component="th" scope="row">
                                        <div className={this.props.classes.timePickerDiv}>
                                            <CustomTimePicker
                                                textFieldValue={row.start_time} 
                                                minSteps={15}
                                                onApply={(event, newValue) => this.handleStartTimeChanged(event, row.id, newValue)}
                                            />
                                        </div>
                                    </StyledTableCellSmall>
                                    <StyledTableCellSmall key="end_time" component="th" scope="row">
                                        <div className={this.props.classes.timePickerDiv}>
                                            <CustomTimePicker
                                                textFieldValue={row.end_time} 
                                                minSteps={15}
                                                onApply={(event, newValue) => this.handleEndTimeChanged(event, row.id, newValue)}
                                            />
                                        </div>
                                    </StyledTableCellSmall>
                                    <StyledTableCellSmall key="break" component="th" scope="row">
                                        <div className={this.props.classes.timePickerDiv}>
                                            <CustomBreakPicker
                                                textFieldValue={row.break} 
                                                hourSteps={0.25}
                                                onApply={(event, newValue) => this.handleBreakChanged(event, row.id, newValue)}
                                            />
                                        </div>
                                    </StyledTableCellSmall>
                                    <StyledTableCellSmall key="add_row" component="th" scope="row">
                                        {showAddButton && ( 
                                            <Tooltip title={i18n.t("pages.main.worktime.dialog.worktimes.add_row")}>
                                                <IconButton 
                                                    className={this.props.classes.iconButton}
                                                    onClick={() => this.handleAddEntryClicked(row.id)}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </StyledTableCellSmall>
                                    <StyledTableCellSmall key="remove_row" component="th" scope="row">
                                        {showRemoveButton && (
                                            <Tooltip title={i18n.t("pages.main.worktime.dialog.worktimes.remove_row")}>
                                                <div>
                                                    <IconButton 
                                                        className={this.props.classes.iconButton}
                                                        onClick={() => this.handleRemoveEntryClicked(row.id)}
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        )}
                                    </StyledTableCellSmall>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </StyledTable>
            </TableContainer>
        )
        return table;
    }

    // Render summary table
    renderSummary() {
        let tableHeader = [
            { id: 1, width: "50%", name: i18n.t("pages.main.worktime.dialog.summary.headline") },
            { id: 2, width: "50%", name: "" }
        ];

        let tableData = [];

        let workTime = this.state.summary.workTime + " " + i18n.t("pages.main.worktime.dialog.summary.hours");
        let breaks = this.state.summary.breaks + " " + i18n.t("pages.main.worktime.dialog.summary.hours");
        let workTimeNoBreaks = this.state.summary.workTimeNoBreaks + " " + i18n.t("pages.main.worktime.dialog.summary.hours");
        let accountedTime = this.state.summary.accountedTime + " " + i18n.t("pages.main.worktime.dialog.summary.hours");

        tableData.push({ key: i18n.t("pages.main.worktime.dialog.summary.work_time"), values: [ workTime ] });
        tableData.push({ key: i18n.t("pages.main.worktime.dialog.summary.breaks"), values: [ breaks ] });
        tableData.push({ key: i18n.t("pages.main.worktime.dialog.summary.work_time_no_breaks"), values: [ workTimeNoBreaks ] });
        tableData.push({ key: i18n.t("pages.main.worktime.dialog.summary.accounted_time"), values: [ accountedTime ] });

        let table = (
            <StyledDataTable
                tableKey="worktime-dialog-summary" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // Render warnings table
    renderWarnings() {
        let tableHeader = [
            { id: 1, width: "100%", name: i18n.t("pages.main.worktime.dialog.warnings.headline") }
        ];
        
        let tableData = [];
        for( let i = 0; i < this.state.warnings.length; i++ ) {
            tableData.push({ key: this.state.warnings[i], values: [], red: true });
        }

        if( tableData.length === 0 ) {
            tableData.push({ key: i18n.t("pages.main.worktime.dialog.warnings.none"), values: [] });
        }

        let table = (
            <StyledDataTable 
                tableKey="worktime-dialog-warnings" 
                tableHeader={tableHeader} 
                tableData={tableData}
            />
        )
        return table;
    }

    // React render
    render() {
        // this.props.data
        /* let data = {
            userid: userid,
            dateJson: dateJson,
            defaultWorkStartTime: defaultWorkStartTime,
            worktimes: {
                today: workTimeEntriesToday,
                yesterday: workTimeEntriesYesterday
            },
            accountings: accountingsToday
        }*/

        let workTimes = this.renderWorkTimes();
        let summary = this.renderSummary();
        let warnings = this.renderWarnings();
    
        return (
            <React.Fragment>
                <div className={this.props.classes.mainDiv}>
                    <div className={this.props.classes.tableDiv}>{workTimes}</div>
                    <div className={this.props.classes.tableDiv}>{summary}</div>
                    <div className={this.props.classes.tableDiv}>{warnings}</div>
                </div>
            </React.Fragment>
        )
    }
};

export default withStyles(useStyles)(ModalDialogWorkTime);