import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AuthContext from '../contexts/AuthContext';
import { config } from '../config';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { StyledTableCell, StyledTableRow, StyledTableHead, StyledTable } from '../components/StyledTable.js'
import UserService from '../classes/UserService';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ApplyResetButtons from '../components/ApplyResetButtons'
import PageTitle from '../components/PageTitle';
import Paper from '@material-ui/core/Paper';
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { fromDateToTimeString, fromTimeStringToDate } from '../helpers.js';
import DateFnsUtils from '@date-io/date-fns';
import { getDateLocale } from '../config.js';
import i18n from 'i18next';

const useStyles = theme => ({
    applyResetButtons: {
        '& > *': {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(1)
        },
    }
});

const passwordPlaceholder = "placeholder";

/**
 * Account Page
 */
class AccountPage extends Component {
    static contextType = AuthContext;
    
    state = {
        rawData: null,
        processedData: {
            userid: null,
            language: null,
            password: passwordPlaceholder,
            defaultWorkStartTime: null
        },
        modifiedData: {
            language: null,
            passwordNew: passwordPlaceholder,
            passwordConfirm: passwordPlaceholder,
            defaultWorkStartTime: null
        },
    }

    constructor(props) {
        super(props);
        this.handleApply = this.handleApply.bind( this );
        this.handleReset = this.handleReset.bind( this );
        this.handleChange = this.handleChange.bind( this );
        this.handleDefaultWorkStartTimeChange = this.handleDefaultWorkStartTimeChange.bind( this );
    }

    // Process the retrieved data
    processData( inRawData ) {

        if( null != inRawData ) {
            let processedData = {
                userid: null,
                language: null,
                password: passwordPlaceholder,
                defaultWorkStartTime: null
            }

            let rawData = inRawData[0];

            processedData.userid = rawData.userid;
            processedData.language = rawData.language;
            processedData.defaultWorkStartTime = new Date();
            processedData.defaultWorkStartTime.setHours(0,0,0,0);

            if( "default_work_start_time" in rawData ) {
                if( rawData.default_work_start_time !== null && rawData.default_work_start_time !== "" ) {
                    processedData.defaultWorkStartTime = fromTimeStringToDate( rawData.default_work_start_time );
                }
            }
            
            let modifiedData = {
                language: null,
                passwordNew: null,
                passwordConfirm: null,
                defaultWorkStartTime: null
            }
    
            modifiedData.language = rawData.language;
            modifiedData.passwordNew = passwordPlaceholder;
            modifiedData.passwordConfirm = passwordPlaceholder;
            modifiedData.defaultWorkStartTime = new Date();
            modifiedData.defaultWorkStartTime.setHours(0,0,0,0);

            if( "default_work_start_time" in rawData ) {
                if( rawData.default_work_start_time !== null && rawData.default_work_start_time !== "" ) {
                    modifiedData.defaultWorkStartTime = fromTimeStringToDate( rawData.default_work_start_time );
                }
            }

            // Apply the new state data
            this.setState({ processedData: processedData, modifiedData: modifiedData, rawData: inRawData });

            // Also tell the auth context to update its data
            this.context.setLanguage( rawData.language );
        } else {
            // Clear data
            this.setState({ rawData: null });
        }
    }

    // Refresh data from backend
    async refreshDataFromBackend() {
        const { statusCode, statusText, resData } = await UserService.getUsers( { userid: this.context.userid } );

        // Set the proper error message
        let alert = {
            severity: "info",
            message: ""
        }
        if( 200 !== statusCode || null == resData || null == resData.getUsers ) {
            alert.severity = "error";
            alert.message = `${statusText}: ${statusCode}`;
        }
        this.handleAlertChanged( alert );

        // Update other states
        if( 200 === statusCode && null != resData && null != resData.getUsers ) {
            this.processData( resData.getUsers );
        } else {
            this.processData( null );
        }
    }

    // Forward data loaded state to parent
    handleDataLoadedChanged( dataLoaded ) {
        if( this.props.dataLoadedChanged ) {
            this.props.dataLoadedChanged( dataLoaded );
        }
    }

    // Forward alert state to parent
    handleAlertChanged( alert ) {
        if( this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    /**
     * BUTTON CLICKS
     */
    
    // Handle click of the apply button
    async handleApply(event) {
        event.preventDefault();
        
        // Apply new language and/or password settings
        let updateUserData = {}

        if( this.state.processedData.language !== this.state.modifiedData.language ) {
            updateUserData["language"] = this.state.modifiedData.language;
        }
        if( this.state.modifiedData.passwordNew !== passwordPlaceholder ) {
            updateUserData["password"] = this.state.modifiedData.passwordNew;
        }
        if( this.state.processedData.defaultWorkStartTime.getTime() !== this.state.modifiedData.defaultWorkStartTime.getTime() ) {

            // Convert time format to something the backend can understand
            updateUserData["default_work_start_time"] = fromDateToTimeString( this.state.modifiedData.defaultWorkStartTime );
        }

        if( Object.keys( updateUserData ).length > 0 ) {
            const { statusCode, statusText, resData } = await UserService.updateUser( { userid: this.state.processedData.userid }, updateUserData );

            let alert = {
                severity: "info",
                message: ""
            }

            if( 200 === statusCode && null != resData ) {
                alert.severity = "success";
                alert.message = i18n.t("pages.account.alert.save_success");
                await this.refreshDataFromBackend();
            } else {
                alert.severity = "error";
                alert.message = `${statusText}: ${statusCode}`;
            }

            this.handleAlertChanged( alert );
        }
    }

    // Handle click on the reset button
    handleReset(event) {
        event.preventDefault();

        let modifiedData = {
            language: null,
            passwordNew: null,
            passwordConfirm: null,
            defaultWorkStartTime: null
        }

        modifiedData.language = this.state.rawData[0].language;
        modifiedData.passwordNew = passwordPlaceholder;
        modifiedData.passwordConfirm = passwordPlaceholder;
        modifiedData.defaultWorkStartTime = new Date();
        modifiedData.defaultWorkStartTime.setHours(0,0,0,0);

        if( "default_work_start_time" in this.state.rawData[0] ) {
            if( this.state.rawData[0].default_work_start_time !== null && this.state.rawData[0].default_work_start_time !== "" ) {
                modifiedData.defaultWorkStartTime = fromTimeStringToDate( this.state.rawData[0].default_work_start_time );
            }
        }

        this.setState({ modifiedData: modifiedData });
    }

    // Handle changes in text and selection fields
    handleChange(event) {
        let modifiedData = this.state.modifiedData;

        if( event.target.name === "modifiedData.language" ) {
            modifiedData.language = event.target.value;
        }
        if( event.target.name === "modifiedData.passwordNew" ) {
            modifiedData.passwordNew = event.target.value;
        }
        if( event.target.name === "modifiedData.passwordConfirm" ) {
            modifiedData.passwordConfirm = event.target.value;
        }
        this.setState({ modifiedData: modifiedData });

        // Clear any alert
        let alert = {
            severity: "info",
            message: ""
        }
        this.handleAlertChanged( alert );
    }

    // Handle changes in time picker field
    handleDefaultWorkStartTimeChange( date ) {
        let modifiedData = this.state.modifiedData;

        modifiedData.defaultWorkStartTime = date;
        this.setState({ modifiedData: modifiedData });

        // Clear any alert
        let alert = {
            severity: "info",
            message: ""
        }
        this.handleAlertChanged( alert );
    }

    // Check if the apply button is to be enabled
    isApplyButtonEnabled() {
        let enableButton = false;

        // Check for new language
        if( this.state.processedData.language !== this.state.modifiedData.language ) {
            enableButton = true;
        }

        // Check for modified password
        if( this.state.modifiedData.passwordNew !== "" && this.state.modifiedData.passwordConfirm !== "" ) {
            if( this.state.processedData.password !== this.state.modifiedData.passwordNew || this.state.processedData.password !== this.state.modifiedData.passwordConfirm ) {

                // Also check that the new password, matches the confirm password
                if( this.state.modifiedData.passwordNew === this.state.modifiedData.passwordConfirm ) {

                    // And verify the minimum password length requirements are met
                    if( this.state.modifiedData.passwordNew.length >= config.userPassword.minLength ) {
                        enableButton = true;
                    }
                }
            }
        }

        // Check for modified default work start time
        if( this.state.modifiedData.defaultWorkStartTime.getTime() !== "" && this.state.modifiedData.defaultWorkStartTime.getTime() !== this.state.processedData.defaultWorkStartTime.getTime() ) {
            enableButton = true;
        }

        return enableButton;
    }

    // Check if the reset button is to be enabled
    isResetButtonEnabled() {
        let enableButton = false;

        // Check for new language
        if( this.state.processedData.language !== this.state.modifiedData.language ) {
            enableButton = true;
        }

        // Check for modified password
        if( this.state.modifiedData.passwordNew !== passwordPlaceholder || this.state.modifiedData.passwordConfirm !== passwordPlaceholder ) {
            enableButton = true;
        }

        // Check for modified default work start time
        if( this.state.processedData.defaultWorkStartTime.getTime() !== this.state.modifiedData.defaultWorkStartTime.getTime() ) {
            enableButton = true;
        }

        return enableButton;
    }

    // Get the helper text of the password field depending on what has been entered there
    getNewPasswordHelperText() {
        if( this.state.modifiedData.passwordNew === "" ) {
            return i18n.t("pages.account.errors.new_password_empty");
        }
        if( this.state.modifiedData.passwordNew !== this.state.modifiedData.passwordConfirm ) {
            return i18n.t("pages.account.errors.passwords_no_match");
        }
        if( this.state.modifiedData.passwordNew.length < config.userPassword.minLength ) {
            return i18n.t("pages.account.errors.password_min_length", { minLength: config.userPassword.minLength });
        }
        return " ";
    }

    // Get the helper text of the password field depending on what has been entered there
    getConfirmPasswordHelperText() {
        if( this.state.modifiedData.passwordConfirm === "" ) {
            return i18n.t("pages.account.errors.confirm_password_empty");
        }
        if( this.state.modifiedData.passwordNew !== this.state.modifiedData.passwordConfirm ) {
            return i18n.t("pages.account.errors.passwords_no_match");
        }
        if( this.state.modifiedData.passwordConfirm.length < config.userPassword.minLength ) {
            return i18n.t("pages.account.errors.password_min_length", { minLength: config.userPassword.minLength });
        }
        return " ";
    }

    /**
     * REACT FUNCTIONS
     */

    // Called when the component is mounted
    async componentDidMount() {
        await this.refreshDataFromBackend();
        this.handleDataLoadedChanged( true );
    }

    // Called when the component will unmount
    componentWillUnmount() {
        // Clear all states
        this.handleAlertChanged({ severity: "info", message: "" });
        this.handleDataLoadedChanged( false );
    }

    // Main render
    render() {
        const { classes } = this.props;

        let locale = null;
        if( null != this.props.language ) {
            locale = getDateLocale( this.props.language );
        }

        // Only display everything if data has already been loaded
        let content = "";

        if( null != this.state.rawData ) {

            // Create table data
            const header = [
                { id: 1, name: i18n.t("pages.account.table.header.parameter") },
                { id: 2, name: i18n.t("pages.account.table.header.value") }
            ]

            content = (
                <div>
                    <TableContainer component={Paper}>
                        <StyledTable>
                            <StyledTableHead>
                                <StyledTableRow key="header">
                                    {header.map((col) => (
                                        <StyledTableCell key={col.id}>{col.name}</StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </StyledTableHead>
                            <TableBody>
                                <StyledTableRow key="modifiedData.language">
                                    <StyledTableCell component="th" scope="row">{i18n.t("pages.account.table.parameter.language")}</StyledTableCell>
                                    <StyledTableCell>
                                        <Select
                                            id="modifiedData.language"
                                            value={this.state.modifiedData.language}
                                            onChange={this.handleChange}
                                            name="modifiedData.language"
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                            }}
                                        >
                                            {config.frontendLanguages.map((entry) => (
                                                <MenuItem key={entry.id} value={entry.id}>{entry.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key="modifiedData.passwordNew">
                                    <StyledTableCell component="th" scope="row">{i18n.t("pages.account.table.parameter.new_password")}</StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            value={this.state.modifiedData.passwordNew}
                                            variant="standard"
                                            margin="normal"
                                            type="password"
                                            required
                                            fullWidth
                                            id="modifiedData.passwordNew"
                                            label=""
                                            name="modifiedData.passwordNew"
                                            onChange={this.handleChange}
                                            error={this.state.modifiedData.passwordNew === "" || this.state.modifiedData.passwordNew !== this.state.modifiedData.passwordConfirm || this.state.modifiedData.passwordNew.length < config.userPassword.minLength}
                                            helperText={this.getNewPasswordHelperText()}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key="modifiedData.passwordConfirm">
                                    <StyledTableCell component="th" scope="row">{i18n.t("pages.account.table.parameter.confirm_password")}</StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            value={this.state.modifiedData.passwordConfirm}
                                            variant="standard"
                                            margin="normal"
                                            type="password"
                                            required
                                            fullWidth
                                            id="modifiedData.passwordConfirm"
                                            label=""
                                            name="modifiedData.passwordConfirm"
                                            onChange={this.handleChange}
                                            error={this.state.modifiedData.passwordConfirm === "" || this.state.modifiedData.passwordNew !== this.state.modifiedData.passwordConfirm || this.state.modifiedData.passwordConfirm.length < config.userPassword.minLength}
                                            helperText={this.getConfirmPasswordHelperText()}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key="modifiedData.defaultWorkStartTime">
                                    <StyledTableCell component="th" scope="row">{i18n.t("pages.account.table.parameter.default_work_start_time")}</StyledTableCell>
                                    <StyledTableCell>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                                            <TimePicker
                                                id="modifiedData.defaultWorkStartTime"
                                                name="modifiedData.defaultWorkStartTime"
                                                margin="normal"
                                                ampm={false}
                                                minutesStep={15}
                                                label=""
                                                value={this.state.modifiedData.defaultWorkStartTime}
                                                onChange={this.handleDefaultWorkStartTimeChange}
                                                KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                    <ApplyResetButtons 
                        className={classes.applyResetButtons} 
                        apply={this.handleApply} 
                        reset={this.handleReset} 
                        applyButtonEnabled={this.isApplyButtonEnabled()} 
                        resetButtonEnabled={this.isResetButtonEnabled()} 
                    />
                </div>
            );
        }

        return (
            <React.Fragment>
                <PageTitle title={i18n.t("pages.account.title")} />
                <p>{i18n.t("pages.account.desc")}</p>
                {content}
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(AccountPage);