import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = theme => ({
    textFieldDiv: {
        flexGrow: 1,
        width: '100%',
        height: '25px',
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(1.5),
            minWidth: '17ch',
        },
    },
    textField: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
        '& .MuiOutlinedInput-root': {
            fontSize: "15px",
            height: '30px',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-disabled fieldset': {
                borderColor: 'transparent',
            },
            // Do not set color here, that breaks the error color if the value is not verified
            /*'&:hover fieldset': {
                borderColor: '#a6a6a6',
            },*/
            /*'&.Mui-focused fieldset': {
                borderWidth: "1px",
                borderColor: theme.palette.primary.main,
            },*/
        },
    },
    editIcon: {
        width: 30,
    }
});

// Proper SVG based edit icon
function EditIcon(props) {
    return (
        <SvgIcon viewBox="0 0 122.88 122.88" {...props}>
            <path d="M61.44 0c33.93 0 61.44 27.51 61.44 61.44 0 33.93-27.51 61.44-61.44 61.44S0 95.37 0 61.44C0 27.51 27.51 0 61.44 0zm-8.88 84c-1.82.61-3.68 1.17-5.5 1.77-1.82.61-3.64 1.21-5.5 1.82-4.34 1.4-6.71 2.19-7.23 2.33-.51.14-.19-1.86.89-6.06l3.45-13.19 26.01-27.04 13.85 13.33L52.56 84zm25.92-50.16c-.65-.61-1.4-.93-2.24-.89-.84 0-1.59.33-2.19.98l-4.94 5.13 13.85 13.38 4.99-5.22c.61-.61.84-1.4.84-2.24 0-.84-.33-1.63-.93-2.19l-9.38-8.95z" fillRule="evenodd" clipRule="evenodd"/>
        </SvgIcon>
    );
}

/**
 * Custom text field
 */
class CustomBreakPicker extends Component {
    state = {
        editing: false,
        textFieldValue: "",
        initialTextFieldValue: "",
        contentVerified: false,
        enableOkButton: false,
    }

    constructor(props) {
        super(props);

        if( null != this.props.textFieldValue ) {
            this.state.textFieldValue = this.props.textFieldValue;
            this.state.initialTextFieldValue = this.props.textFieldValue;
        }

        this.handleTextClick = this.handleTextClick.bind( this );
        this.handleTextChange = this.handleTextChange.bind( this );
        this.handleTextOnFocus = this.handleTextOnFocus.bind( this );
        this.handleTextOnBlur = this.handleTextOnBlur.bind( this );

        this.handleButtonEditClick = this.handleButtonEditClick.bind( this );
        this.handleButtonApplyClick = this.handleButtonApplyClick.bind( this );
        this.handleButtonCancelClick = this.handleButtonCancelClick.bind( this );

        this.verifyBreakValue = this.verifyBreakValue.bind( this );

        this.textFieldInput = React.createRef();
    }

    // Verify the break value
    verifyBreakValue( value ) {
        let result = false;

        // Check for break duration format x.x
        if( value !== "" ) {

            // Check for positive fractional digits
            if( true === /^\d+(\.\d*)?$/.test(value) ) {
                result = true;
            }
        }

        return result;
    }

    // Text has been clicked
    handleTextClick(event) {
        event.stopPropagation();
        if( true === this.props.disabled ) {
            return;
        }
        this.setState({ editing: true });
    }

    // Text has been changed
    handleTextChange(event) {
        //event.stopPropagation();

        let textFieldValue = event.target.value;

        let contentVerified = true;
        let enableOkButton = true;

        // Do not allow empty values
        if( textFieldValue === "" ) {
            enableOkButton = false;
            contentVerified = false;
        }

        // If the value is equal to the initial value, no need to enable the OK button
        if( textFieldValue === this.state.initialTextFieldValue ) {
            enableOkButton = false;
        }

        // Verify the style of the entered value without actually modifying it
        contentVerified = this.verifyBreakValue(textFieldValue);

        this.setState({ textFieldValue: textFieldValue, contentVerified: contentVerified, enableOkButton: enableOkButton });
    }

    // User clicked away from the text field (defocused it)
    handleTextOnBlur(event) {
        // If blurred check if we are in editing state and made any unapplied changes
        // If there are no unapplied changes go out of editing mode as if the cancel button was clicked
        if( true === this.state.editing && this.state.textFieldValue === this.state.initialTextFieldValue ) {
            this.setState({ editing: false, contentVerified: true, textFieldValue: this.state.initialTextFieldValue });
        }
    }

    // Received focus on the text edit field
    handleTextOnFocus(event) {
        event.stopPropagation();
        this.setState({ editing: true });
    }

    // Clicked the edit button
    handleButtonEditClick(event) {
        event.stopPropagation();
        this.textFieldInput.current.focus();
        this.setState({ editing: true });
    }

    // Clicked the Apply button
    handleButtonApplyClick(event) {
        event.stopPropagation();
        this.textFieldInput.current.blur();

        let textFieldValue = this.textFieldInput.current.value;

        // Obey times only
        if( false === this.verifyBreakValue( textFieldValue ) ) {
            this.setState({ editing: false, textFieldValue: this.state.initialTextFieldValue });
            return;
        }

        // Adjust minute steps if necessary
        if( null != this.props.hourSteps && "" !== this.props.hourSteps ) {
            const fraction = 1 / this.props.hourSteps;
            textFieldValue = (Math.ceil(textFieldValue * fraction) / fraction).toFixed(2);
        }

        // Transmit final text if its different
        if( null != this.props.onApply && textFieldValue !== this.state.initialTextFieldValue ) {
            this.props.onApply( event, textFieldValue );
        }

        this.setState({ editing: false, textFieldValue: textFieldValue, initialTextFieldValue: textFieldValue });
    }

    // Clicked the cancel button
    handleButtonCancelClick(event) {
        event.stopPropagation();

        // Same as deselect or cancel, reset the text
        this.setState({ editing: false, contentVerified: true, textFieldValue: this.state.initialTextFieldValue });
    }

    // Called by react when this component has been mounted
    componentDidMount() {
        // Verify the new value
        let contentVerified = this.verifyBreakValue(this.state.textFieldValue);

        this.setState({ contentVerified: contentVerified });
    }

    // Called when the component receives props, before rendering
    componentWillReceiveProps(props) {
        // Check if the given textFieldValue is different to our initialTextFieldValue
        if( props.textFieldValue !== this.state.initialTextFieldValue ) {
            // Update our text field value
            this.setState({editing: false, contentVerified: true, textFieldValue: props.textFieldValue, initialTextFieldValue: props.textFieldValue})
        }
    }

    // Render me
    render() {
        // Used this props
        // onApply(event, value), minSteps (optional)
        const { classes } = this.props;

        // Gather the currently shown edit buttons
        let buttonProps = {
            shrink: "true",
        }

        if( false === this.state.editing ) {
            buttonProps.endAdornment = (
                <InputAdornment position='end'>
                    <IconButton
                        className={classes.editIcon}
                        onClick={(event) => this.handleButtonEditClick(event)}
                        color="primary"
                        edge="end"
                        disableRipple={true}
                        size="small"
                        style={{ visibility: "visible" }}
                        disabled={this.props.disabled}
                    >
                        <EditIcon fontSize="small"/>
                    </IconButton>
                </InputAdornment>
            );
        } else if( true === this.state.editing ) {
            buttonProps.endAdornment = (
                <InputAdornment position='end'>
                    <IconButton
                        onClick={(event) => this.handleButtonApplyClick(event)}
                        color="primary"
                        edge="end"
                        disableRipple={true}
                        size="small"
                        style={{ visibility: "visible" }}
                        disabled={!(this.state.contentVerified && this.state.enableOkButton)}
                    >
                        <CheckCircleRoundedIcon />
                    </IconButton>
                    <IconButton
                        onClick={(event) => this.handleButtonCancelClick(event)}
                        color="primary"
                        edge="end"
                        disableRipple={true}
                        size="small"
                        style={{ visibility: "visible" }}
                    >
                        <CancelRoundedIcon />
                    </IconButton>
                </InputAdornment>
            );
        }

        // Render the rest
        return (
            <div className={classes.textFieldDiv}>
                <TextField
                    label=""
                    value={this.state.textFieldValue}
                    type="text"
                    variant="outlined"
                    size="small"
                    helperText=""
                    fullWidth
                    className={classes.textField}
                    InputProps={buttonProps}
                    inputRef={this.textFieldInput}
                    onClick={(event) => this.handleTextClick(event)}
                    onChange={(event) => this.handleTextChange(event)}
                    onFocus={(event) => this.handleTextOnFocus(event)}
                    onBlur={(event) => this.handleTextOnBlur(event)}
                    onKeyPress={(event) => {
                        if( event.key === "Enter" && true === this.state.contentVerified ) {
                            this.handleButtonApplyClick(event);
                        }
                    }}
                    error={false === this.state.contentVerified}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
};

export default withStyles(useStyles)(CustomBreakPicker);