import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { config } from '../config.js';
import MainPage from './MainPage';
import ProfilePage from './ProfilePage';
import AccountPage from './AccountPage';
import LogoutPage from './LogoutPage';
import AboutPage from './AboutPage';
import AbsencePage from './AbsencePage';
import ProjectsPage from './ProjectsPage';
import ReportsPage from './ReportsPage';
import TasksPage from './TasksPage';
import AdminPage from './AdminPage';
import InvalidPage from './InvalidPage';
import InProgress from '../components/InProgress'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AuthContext from '../contexts/AuthContext';
import AdminNoAccess from './admin/AdminNoAccess';

const drawerWidth = config.mainDrawerWidth;

const useStyles = theme => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: theme.spacing(7) + 1,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
    },
    containerContent: {
        marginTop: "10px",
        marginLeft: "20px",
        marginRight: "20px"
    },
    pageContent: {},
    pageBottom: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

/**
 * Container Page
 */
class ContainerPage extends Component {
    static contextType = AuthContext;

    state = {
        subpage: {
            alert: {
                severity: "info",
                message: ""
            },
            dataLoaded: false,
            actionInProgress: false
        },
        adminFlagsLoaded: false,
        adminFlags: {
            projects: false,
            tasks: false
        }
    }

    constructor(props) {
        super(props);
        this.subpageAlertChanged = this.subpageAlertChanged.bind( this );
        this.subpageDataLoadedChanged = this.subpageDataLoadedChanged.bind( this );
        this.subpageActionInProgressChanged = this.subpageActionInProgressChanged.bind( this );
    }

    // Subpage notification: alert changed
    subpageAlertChanged( alert ) {
        let subpage = this.state.subpage;
        subpage.alert = alert;
        this.setState({ subpage: subpage });

        // Forward to parent
        if( null != this.props.alertChanged ) {
            this.props.alertChanged( alert );
        }
    }

    // Subpage notification: data loaded changed
    subpageDataLoadedChanged( dataLoaded ) {
        // Only set state if really changed
        if( dataLoaded !== this.state.subpage.dataLoaded ) {
            let subpage = this.state.subpage;
            subpage.dataLoaded = dataLoaded;
            this.setState({ subpage: subpage });
        }
    }

    // Subpage notification: action in progress changed
    subpageActionInProgressChanged( actionInProgress ) {
        // Only set state if really changed
        if( actionInProgress !== this.state.subpage.actionInProgress ) {
            let subpage = this.state.subpage;
            subpage.actionInProgress = actionInProgress;
            this.setState({ subpage: subpage });
        }
    }

    // Refresh the authentication
    async refreshAuth() {
        await window.AuthService.refreshIfNeeded();
    }

    // Called when component mounted
    async componentDidMount() {
        // Make sure to refresh authentication in intervals
        this.interval = setInterval(() => this.refreshAuth(), config.tokenRefreshCheckInterval);

        // Get the admin data for the users
        let adminFlags = this.state.adminFlags;

        const { statusCode, userAdminData } = await this.context.services.admin.getAdminData( this.context.userid );
        if( 200 === statusCode && null != userAdminData ) {
            
            // Gather which items to show from data
            if( true === userAdminData.admin.global ) {
                adminFlags = {
                    projects: true,
                    tasks: true
                }
            } else {

                if( 0 < userAdminData.projectlead.length ) {
                    adminFlags = {
                        projects: true,
                        tasks: true
                    }
                }
            }
        }

        // Set the new state of the component
        this.setState({ adminFlagsLoaded: true, adminFlags: adminFlags });
    }

    // Called when component unmounts
    componentWillUnmount() {
        // Do not refresh the authentication anymore
        clearInterval( this.interval );
        this.setState({
            adminFlagsLoaded: false,
            adminFlags: {
                projects: false,
                tasks: false            
            }
        });
    }

    // React render
    render() {
        const { classes } = this.props;

        // Display the proper subpage based on the pathname
        const pathname = this.props.location.pathname;

        // Assemble content
        let contentSubpage = "";
        let contentLoading = "";

        if( true === this.state.adminFlagsLoaded ) {
            if( pathname === "/main" ) { contentSubpage = ( <MainPage args={this.props.location.search} dataLoadedChanged={this.subpageDataLoadedChanged} alertChanged={this.subpageAlertChanged} actionInProgressChanged={this.subpageActionInProgressChanged} /> ) }
            else if( pathname === "/profile" ) { contentSubpage = ( <ProfilePage args={this.props.location.search} dataLoadedChanged={this.subpageDataLoadedChanged} alertChanged={this.subpageAlertChanged} actionInProgressChanged={this.subpageActionInProgressChanged} /> ) }
            else if( pathname === "/account" ) { contentSubpage = ( <AccountPage args={this.props.location.search} dataLoadedChanged={this.subpageDataLoadedChanged} alertChanged={this.subpageAlertChanged} actionInProgressChanged={this.subpageActionInProgressChanged} /> ) }
            else if( pathname === "/logout" ) { contentSubpage = ( <LogoutPage args={this.props.location.search} dataLoadedChanged={this.subpageDataLoadedChanged} alertChanged={this.subpageAlertChanged} actionInProgressChanged={this.subpageActionInProgressChanged} /> ) }
            else if( pathname === "/about" ) { contentSubpage = ( <AboutPage args={this.props.location.search} dataLoadedChanged={this.subpageDataLoadedChanged} alertChanged={this.subpageAlertChanged} actionInProgressChanged={this.subpageActionInProgressChanged} /> ) }
            else if( pathname === "/absence" ) { contentSubpage = ( <AbsencePage args={this.props.location.search} dataLoadedChanged={this.subpageDataLoadedChanged} alertChanged={this.subpageAlertChanged} actionInProgressChanged={this.subpageActionInProgressChanged} /> ) }
            else if( pathname === "/projects" && true === this.state.adminFlags.projects ) { contentSubpage = ( <ProjectsPage args={this.props.location.search} dataLoadedChanged={this.subpageDataLoadedChanged} alertChanged={this.subpageAlertChanged} actionInProgressChanged={this.subpageActionInProgressChanged} /> ) }
            else if( pathname === "/tasks" && true === this.state.adminFlags.tasks ) { contentSubpage = ( <TasksPage args={this.props.location.search} dataLoadedChanged={this.subpageDataLoadedChanged} alertChanged={this.subpageAlertChanged} actionInProgressChanged={this.subpageActionInProgressChanged} /> ) }
            else if( pathname === "/reports" ) { contentSubpage = ( <ReportsPage args={this.props.location.search} dataLoadedChanged={this.subpageDataLoadedChanged} alertChanged={this.subpageAlertChanged} actionInProgressChanged={this.subpageActionInProgressChanged} /> ) }
            else if( pathname === "/admin" ) { contentSubpage = ( <AdminPage args={this.props.location.search} dataLoadedChanged={this.subpageDataLoadedChanged} alertChanged={this.subpageAlertChanged} actionInProgressChanged={this.subpageActionInProgressChanged} /> ) }
            else {

                // Check if invalid or no access page should be shown
                if( pathname === "/projects" && false === this.state.adminFlags.projects ) {
                    contentSubpage = ( <AdminNoAccess dataLoadedChanged={this.subpageDataLoadedChanged} alertChanged={this.subpageAlertChanged} /> );

                } else if( pathname === "/tasks" && false === this.state.adminFlags.projects ) {
                    contentSubpage = ( <AdminNoAccess dataLoadedChanged={this.subpageDataLoadedChanged} alertChanged={this.subpageAlertChanged} /> );

                } else {
                    // Invalid page
                    contentSubpage = ( <InvalidPage dataLoadedChanged={this.subpageDataLoadedChanged} alertChanged={this.subpageAlertChanged} /> );
                }
            }
        }

        // Display a loading indicator if the sub page has not yet loaded its data and has no active error message
        if( false === this.state.subpage.dataLoaded && this.state.subpage.alert.message === "" ) {
            contentLoading = (
                <div>
                    <InProgress />
                </div>
            )
        }

        return (
            <React.Fragment>
                <div className={clsx(classes.content, { [classes.contentShift]: this.props.isDrawerOpen, })}>
                    <div className="container-main">
                        <div className={classes.containerContent}>
                            <div className={classes.pageContent}>
                                {contentSubpage}
                                {contentLoading}
                            </div>
                            <div>
                                <Backdrop className={classes.backdrop} open={this.state.subpage.actionInProgress}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(useStyles)(ContainerPage);