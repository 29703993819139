import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import SvgIcon from '@material-ui/core/SvgIcon';
import ModalDialog from './ModalDialog';
import ModalDialogDatePicker from './ModalDialogDatePicker';
import { toDateOnlyString, toDateOnlyStringNoYear } from '../helpers';
import i18n from 'i18next';

const useStyles = theme => ({
    textFieldDiv: {
        flexGrow: 1,
        width: '100%',
        height: '25px',
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(1.5),
            minWidth: '20ch',
        },
    },
    textField: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
        '& .MuiOutlinedInput-root': {
            height: '30px',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-disabled fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    textFieldBlurred: {
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
        '& .MuiOutlinedInput-root': {
            height: '30px',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    editIcon: {
        width: 30,
    }
});

// Proper SVG based edit icon
function EditIcon(props) {
    return (
        <SvgIcon viewBox="0 0 122.88 122.88" {...props}>
            <path d="M61.44 0c33.93 0 61.44 27.51 61.44 61.44 0 33.93-27.51 61.44-61.44 61.44S0 95.37 0 61.44C0 27.51 27.51 0 61.44 0zm-8.88 84c-1.82.61-3.68 1.17-5.5 1.77-1.82.61-3.64 1.21-5.5 1.82-4.34 1.4-6.71 2.19-7.23 2.33-.51.14-.19-1.86.89-6.06l3.45-13.19 26.01-27.04 13.85 13.33L52.56 84zm25.92-50.16c-.65-.61-1.4-.93-2.24-.89-.84 0-1.59.33-2.19.98l-4.94 5.13 13.85 13.38 4.99-5.22c.61-.61.84-1.4.84-2.24 0-.84-.33-1.63-.93-2.19l-9.38-8.95z" fillRule="evenodd" clipRule="evenodd"/>
        </SvgIcon>
    );
}

/**
 * Custom date picker
 */
class CustomDatePicker extends Component {
    state = {
        editing: false,
        dateValue: null,
        initialDateValue: null,
        contentVerified: true,
        enableApplyButton: false,
        modifyDialog: {
            open: false,
            date: null,
            name: ""
        },
        dialogJustClosed: false,
        blurred: false
    }

    constructor(props) {
        super(props);

        if( null != this.props.dateValue ) {
            this.state.dateValue = this.props.dateValue;
            this.state.initialDateValue = this.props.dateValue;
        }

        this.handleTextClick = this.handleTextClick.bind( this );
        this.handleTextOnFocus = this.handleTextOnFocus.bind( this );
        this.handleTextOnBlur = this.handleTextOnBlur.bind( this );

        this.handleButtonEditClick = this.handleButtonEditClick.bind( this );
        this.handleButtonApplyClick = this.handleButtonApplyClick.bind( this );
        this.handleButtonCancelClick = this.handleButtonCancelClick.bind( this );

        this.handleModifyDialog_Ok = this.handleModifyDialog_Ok.bind( this );
        this.handleModifyDialog_Cancel = this.handleModifyDialog_Cancel.bind( this );
        this.handleModifyDialog_Delete = this.handleModifyDialog_Delete.bind( this );
        this.verifyDialog_Date = this.verifyDialog_Date.bind( this );

        this.textFieldInput = React.createRef();
        this.editButtonInput = React.createRef();
    }

    /**
     * DIALOG
     */

    // Called to open the modify dialog
    openModifyDialog( date ) {
        let modifyDialog = {
            open: true,
            date: date
        }
        this.setState({ modifyDialog: modifyDialog });
    }

    // Called when the user clicked "Ok" on the modify dialog
    handleModifyDialog_Ok( initialDate, contents ) {
        this.closeModifyDialog();

        if( null != contents && null != contents.value ) {
            let enableApplyButton = true;
            if( contents.value === this.state.initialDateValue ) {
                enableApplyButton = false;
            }
            this.setState({ dateValue: contents.value, contentVerified: true, enableApplyButton: enableApplyButton });
        }
    }

    // Called when the user clicked "Cancel" or close on the modify dialog
    handleModifyDialog_Cancel() {
        this.closeModifyDialog();
    }

    // Called when the user clicks the delete button
    handleModifyDialog_Delete() {
        this.closeModifyDialog();
        this.setState({ dateValue: null, contentVerified: true, enableApplyButton: true });
        if( null != this.props.onDelete ) {
            return this.props.onDelete();
        }
    }

    // Called by the dialog to verify its date content
    verifyDialog_Date( content ) {
        if( null != this.props.verifyContent && null != content ) {
            return this.props.verifyContent( content.date );
        }
        return { state: true, msg: "" };
    }

    // Close the modify alert dialog
    closeModifyDialog() {
        let modifyDialog = {
            open: false,
            date: null,
            name: null
        }
        this.setState({ modifyDialog: modifyDialog, dialogJustClosed: true });
    }

    /**
     * TEXTFIELD
     */

    // Text has been clicked
    handleTextClick(event) {
        event.stopPropagation();
        if( true === this.props.disabled ) {
            return;
        }
        this.openModifyDialog( this.state.dateValue );
        this.setState({ editing: true });
    }

    // User clicked away from the text field (defocused it)
    handleTextOnBlur(event) {

        // Ignore blur if modify dialog is open
        if( true === this.state.modifyDialog.open ) {
            return;
        }

        // If blurred check if we are in editing state and made any unapplied changes
        // If there are no unapplied changes go out of editing mode as if the cancel button was clicked
        if( true === this.state.editing && this.state.dateValue === this.state.initialDateValue ) {
            this.setState({ editing: false, contentVerified: true, dateValue: this.state.initialDateValue });
        }
        this.setState({ blurred: true });
    }

    // Received focus on the text edit field
    handleTextOnFocus(event) {
        event.stopPropagation();

        if( false === this.state.dialogJustClosed ) {
            this.setState({ editing: true, blurred: false });

        } else {
            this.setState({ dialogJustClosed: false, blurred: false });

            // If blurred check if we are in editing state and made any unapplied changes
            // If there are no unapplied changes go out of editing mode as if the cancel button was clicked
            if( true === this.state.editing && this.state.dateValue === this.state.initialDateValue ) {
                this.setState({ editing: false, contentVerified: true, dateValue: this.state.initialDateValue });
            }
            this.textFieldInput.current.blur();
        }
    }

    /**
     * BUTTONS
     */

    // Clicked the edit button
    handleButtonEditClick(event) {
        event.stopPropagation();
        this.textFieldInput.current.focus();
        this.openModifyDialog( this.state.dateValue, [] );
        this.setState({ editing: true, blurred: false });
    }

    // Clicked the Apply button
    handleButtonApplyClick(event) {
        event.stopPropagation();
        this.textFieldInput.current.blur();

        // Transmit final text if its different
        if( null != this.props.onApply && this.state.dateValue !== this.state.initialDateValue ) {
            this.props.onApply( event, this.state.dateValue );
        }

        this.setState({ editing: false, initialDateValue: this.state.dateValue });
    }

    // Clicked the cancel button
    handleButtonCancelClick(event) {
        event.stopPropagation();

        // Same as deselect or cancel, reset the text
        this.setState({ editing: false, contentVerified: true, dateValue: this.state.initialDateValue });
    }

    // Render me
    render() {
        // Used this props
        // onApply(event, value), verifyContent(value)
        const { classes } = this.props;

        // Gather the currently shown edit buttons
        let buttonProps = {
            shrink: "true",
            readOnly: true
        }

        if( false === this.state.editing ) {
            buttonProps.endAdornment = (
                <InputAdornment position='end'>
                    <IconButton
                        className={classes.editIcon}
                        onClick={(event) => this.handleButtonEditClick(event)}
                        color="primary"
                        edge="end"
                        disableRipple={true}
                        size="small"
                        style={{ visibility: "visible" }}
                        disabled={this.props.disabled}
                    >
                        <EditIcon fontSize="small"/>
                    </IconButton>
                </InputAdornment>
            );
        } else if( true === this.state.editing ) {
            buttonProps.endAdornment = (
                <InputAdornment position='end'>
                    <IconButton
                        onClick={(event) => this.handleButtonApplyClick(event)}
                        color="primary"
                        edge="end"
                        disableRipple={true}
                        size="small"
                        style={{ visibility: "visible" }}
                        disabled={!(this.state.contentVerified && this.state.enableApplyButton)}
                        ref={this.editButtonInput}
                    >
                        <CheckCircleRoundedIcon />
                    </IconButton>
                    <IconButton
                        onClick={(event) => this.handleButtonCancelClick(event)}
                        color="primary"
                        edge="end"
                        disableRipple={true}
                        size="small"
                        style={{ visibility: "visible" }}
                    >
                        <CancelRoundedIcon />
                    </IconButton>
                </InputAdornment>
            );
        }

        // Set different text field classes depending on if we have been blurred
        // to not show a border around the text field even if we have already been blurred
        let textFieldClass = classes.textField;
        if( true === this.state.blurred ) {
            textFieldClass = classes.textFieldBlurred;
        }

        // Show the date value properly readable
        let dateString = toDateOnlyString( this.state.dateValue, this.props.language );

        // Check if we should cut away the year
        if( null != this.props.noYear && true === this.props.noYear ) {
            dateString = toDateOnlyStringNoYear( this.state.dateValue, this.props.language );
        }

        if( dateString === "" ) {
            dateString = i18n.t("values.none");
        }

        // Render the rest
        return (
            <div className={classes.textFieldDiv}>
                <TextField
                    label=""
                    value={dateString}
                    type="text"
                    variant="outlined"
                    size="small"
                    helperText=""
                    fullWidth
                    className={textFieldClass}
                    InputProps={buttonProps}
                    inputRef={this.textFieldInput}
                    onClick={(event) => this.handleTextClick(event)}
                    onFocus={(event) => this.handleTextOnFocus(event)}
                    onBlur={(event) => this.handleTextOnBlur(event)}
                    onKeyPress={(event) => {
                        if( event.key === "Enter" && true === this.state.contentVerified ) {
                            this.handleButtonApplyClick(event);
                        }
                    }}
                    error={false === this.state.contentVerified}
                    disabled={this.props.disabled}
                />
                <ModalDialog 
                    open={this.state.modifyDialog.open}
                    title={this.props.title}
                    descText={this.props.descText}
                    buttonLeft={this.props.buttonLeft}
                    buttonRight={this.props.buttonRight}
                    handleClickClose={() => this.handleModifyDialog_Cancel()}
                    handleClickLeft={(contents) => this.handleModifyDialog_Ok(this.state.modifyDialog.date, contents)}
                    handleClickRight={() => this.handleModifyDialog_Cancel()}
                    handleClickDelete={() => this.handleModifyDialog_Delete()}
                    showDeleteButton={this.props.showDeleteButton}
                    disableDeleteButton={this.props.disableDeleteButton}
                    buttonDeleteTooltip={this.props.buttonDeleteTooltip}
                    deleteIcon={this.props.deleteIcon}
                >
                    <ModalDialogDatePicker 
                        language={this.context.language}
                        verifyContent={(content) => this.verifyDialog_Date(content)}
                        value={this.state.modifyDialog.date}
                        blockedDates={this.props.blockedDates}
                        allowedDate={this.props.allowedDate}
                        disableToolbar={this.props.disableToolbar}
                        blockBeforeDate={this.props.blockBeforeDate}
                        blockBeforeDateInclusive={this.props.blockBeforeDateInclusive}
                        blockAfterDate={this.props.blockAfterDate}
                        blockAfterDateInclusive={this.props.blockAfterDateInclusive}
                        limitToCurrentYear={this.props.limitToCurrentYear}
                    />
                </ModalDialog>
            </div>
        );
    }
};

export default withStyles(useStyles)(CustomDatePicker);