import React, {Fragment} from 'react';
import { Text } from '@react-pdf/renderer';

const PdfTableFooter = ({data}) => {
    return (
        <Fragment>
            <Text style={data.style}>{data.value}</Text>
        </Fragment>
    )
};

export default PdfTableFooter
