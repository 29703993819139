import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

/**
 * Theme for this App
 * Includes general elements which are used in multiple classes
 * Styles which are only used in a specific component can be found within that respective component
 */
const colorWhite = "#FFFFFF";
const colorPrimary = "#248EDB";

const Theme = createMuiTheme({
    palette: {
        primary: {
            main: colorPrimary
        },
        error: {
            main: red.A400
        },
        background: {
            default: colorWhite
        },
        table: {
            row: {
                primary: '#d9d9d9',
                hover: '#CDCDCD !important',
                selected: {
                    primary: '#24B6DB',
                    hover: '#24B6DB !important',
                    text: {
                        primary: colorWhite,
                        hover: colorWhite
                    }
                }
            }
        },
        type: "light"
    },
    mainBar: {
        title: colorWhite,
        subMenuText: '#656565',
        subMenuBorder: '1px solid #d3d4d5',
    },
    mainDrawer: {
        background: '#02243C',
        subMenuText: colorWhite,
        dividerColor: colorWhite
    },
    overrides: {
        MuiTableContainer: {
            root: {
                overflowY: "hidden"
            }
        },
        MuiTableCell: {
            root: {
                padding: '5px 5px 5px 5px'
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "12px"
            }
        },
    },
});

export default Theme;