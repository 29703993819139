import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import { getAvailableLanguages, getFallbackLanguage, getLanguageResources } from './config';

const availableLanguages = getAvailableLanguages();
const fallbackLanguage = getFallbackLanguage();
const localesResources = getLanguageResources();

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        resources: localesResources,
        fallbackLanguage,
        detection: {
            checkWhitelist: true
        },
        debug: false,
        whitelist: availableLanguages,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
