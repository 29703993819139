import { Component } from 'react';
import RoleService from './RoleService';
import UsersToRolesService from './UsersToRolesService';
import UsersToProjectsService from './UsersToProjectsService';
import UsersToTeamsService from './UsersToTeamsService';
import UserService from './UserService';

/**
 * Utility admin flags service
 */
class AdminService extends Component {

    // adminData is a json struct of userAdminData objects
    // userAdminData = [] of { 
    //    userid, 
    //    admin { global, users, teams, projects, roles, specials, holidays, absences, tasks, accountings, contracts },
    //    projectlead [<projectids>]
    //    teamlead [<teamids>]
    // }
    m_adminData = [];

    // Retrieve the admin data for the given userid
    async getAdminData( userid ) {

        // The result struct
        let result = {
            statusCode: 200,
            statusText: "",
            userAdminData: null
        }

        // Check if we have the userid available
        let userAdminData = this.m_adminData.find( dataEntry => dataEntry.userid === userid );

        if( null == userAdminData ) {
            const { statusCode, statusText, userAdminData } = await this.refreshAdminData( userid );
            result.statusCode = statusCode;
            result.statusText = statusText;
            result.userAdminData = userAdminData;

        } else {

            // We already had the admin data stored
            result.userAdminData = userAdminData;
        }

        return result;
    }

    // Refresh the admin data for the given userid
    async refreshAdminData( userid ) {

        // Collected raw data
        let userRawData = null;
        let roleRawData = null;
        let userToRolesRawData = null;
        let userToTeamsRawData = null;
        let userToProjectsRawData = null;

        // Query user data
        {
            const { statusCode, statusText, resData } = await UserService.getUsers( { userid: userid } );
            if( 200 === statusCode && null != resData ) {
                userRawData = resData.getUsers;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    userAdminData: null 
                }
            }
        }

        // Query role data
        {
            const { statusCode, statusText, resData } = await RoleService.getRoles( {} );
            if( 200 === statusCode && null != resData ) {
                roleRawData = resData.getRoles;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    userAdminData: null 
                }
            }
        }

        // Query for role specific data on this user
        {
            const { statusCode, statusText, resData } = await UsersToRolesService.getUsersToRoles( { userid: userid } );
            if( 200 === statusCode && null != resData ) {
                userToRolesRawData = resData.getUsersToRoles;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    userAdminData: null 
                }
            }
        }

        // Query for team specific data on this user
        {
            const { statusCode, statusText, resData } = await UsersToTeamsService.getUsersToTeams( { userid: userid } );
            if( 200 === statusCode && null != resData ) {
                userToTeamsRawData = resData.getUsersToTeams;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    userAdminData: null 
                }
            }
        }

        // Query for project specific data on this user
        {
            const { statusCode, statusText, resData } = await UsersToProjectsService.getUsersToProjects( { userid: userid } );
            if( 200 === statusCode && null != resData ) {
                userToProjectsRawData = resData.getUsersToProjects;
            } else {
                return { 
                    statusCode: statusCode,
                    statusText: statusText,
                    userAdminData: null 
                }
            }
        }

        // Create userAdminData structure
        // userAdminData = { 
        //    userid, 
        //    admin { global, users, teams, projects, roles, specials, holidays, absences, tasks, accountings, contracts },
        //    projectlead [<projectids>]
        //    teamlead [<teamids>]
        // }
        let userAdminData = {
            userid: userid,
            admin: {
                global: false,
                users: false,
                teams: false,
                projects: false,
                roles: false,
                specials: false,
                holidays: false,
                absences: false,
                tasks: false,
                accountings: false,
                contracts: false
            },
            projectlead: [],
            teamlead: []
        }

        let userData = userRawData.find( userEntry => userEntry.userid === userid );
        if( null != userData ) {
            userAdminData.admin.global = userData.is_admin;
        }

        for (let dataEntry of userToRolesRawData ) {
            let roleData = roleRawData.find( roleEntry => roleEntry.roleid === dataEntry.roleid );
            if( roleData !== null ) {
                if( roleData.admin_users === true ) { userAdminData.admin.users = true; }
                if( roleData.admin_teams === true ) { userAdminData.admin.teams = true; }
                if( roleData.admin_projects === true ) { userAdminData.admin.projects = true; }
                if( roleData.admin_roles === true ) { userAdminData.admin.roles = true; }
                if( roleData.admin_specials === true ) { userAdminData.admin.specials = true; }
                if( roleData.admin_holidays === true ) { userAdminData.admin.holidays = true; }
                if( roleData.admin_absences === true ) { userAdminData.admin.absences = true; }
                if( roleData.admin_tasks === true ) { userAdminData.admin.tasks = true; }
                if( roleData.admin_accountings === true ) { userAdminData.admin.accountings = true; }
                if( roleData.admin_contracts === true ) { userAdminData.admin.contracts = true; }
            }
        }

        for (let dataEntry of userToProjectsRawData ) {
            if( dataEntry.userid === userid && dataEntry.is_project_lead === true ) {
                userAdminData.projectlead = userAdminData.projectlead.concat( dataEntry.projectid );
            }
        }
        for (let dataEntry of userToTeamsRawData ) {
            if( dataEntry.userid === userid && dataEntry.is_team_lead === true ) {
                userAdminData.teamlead = userAdminData.teamlead.concat( dataEntry.teamid );
            }
        }

        // Push the result to the state or replace it if it already exists
        let adminData = this.m_adminData;

        const dataIndex = adminData.findIndex( dataEntry => dataEntry.userid === userid );
        if( dataIndex !== -1 ) {
            adminData.splice( dataIndex, 1 );
        }
        adminData.push( userAdminData );
        this.m_adminData = adminData;

        return { statusCode: 200, statusText: "", userAdminData: userAdminData };
    }
}

export default new AdminService();