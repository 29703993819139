import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CalendarHelper from '../classes/CalendarHelper.js';
import Chart from "react-apexcharts";
import i18n from 'i18next';

const useStyles = theme => ({});

/**
 * Chart Accounting
 */
class ChartAccounting extends Component {
    state = {
        chart: ""
    }

    /// Get x-axis labels
    getLabels( vacationReport ) {
        let labels = [];

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "short" );

        if( null != vacationReport ) {
            for( let i = 0; i < vacationReport.years.length; i++ ) {

                // Only return years based on selection
                if( this.props.year === "" || this.props.year === vacationReport.years[i].year.toString() ) {
                    const yearString = " '" + vacationReport.years[i].year.toString().substring( 2, 4 )
                    for( let j = 0; j < vacationReport.years[i].months.length; j++ ) {

                        // Add label for the month
                        labels.push( monthNames[vacationReport.years[i].months[j].month-1] + yearString );
                    }
                }
            }
        }

        return labels;
    }

    /// Get series: accounted hours in month
    getSeriesAccountedHours( accountingReport ) {
        let series = {
            name: i18n.t("pages.reports.options.accounting.chart.series.accounted.name"),
            type: 'line',
            markers: {
                size: 1,
                strokeColors: '#fff',
                strokeWidth: 2,
                shape: "circle",
                radius: 2
            },
            data: [],
        }

        if( null != accountingReport ) {
            const thisYear = new Date().getFullYear();
            const thisMonth = new Date().getMonth();

            for( let i = 0; i < accountingReport.years.length; i++ ) {
                // Only return years based on selection
                if( this.props.year === "" || this.props.year === accountingReport.years[i].year.toString() ) {
                    for( let j = 0; j < accountingReport.years[i].months.length; j++ ) {

                        let accountedHours = accountingReport.years[i].months[j].hours_accounted_in_month;

                        // Only add if the current year and month is not past today's year and the past month
                        // in that case only add an empty string, else that looks awkward into the future
                        if( thisYear > accountingReport.years[i].year || ( thisYear === accountingReport.years[i].year && thisMonth >= j )) {
                            series.data.push( accountedHours );
                        } else {
                            series.data.push( null );
                        }
                    }
                }
            }
        }

        return series;
    }

    /// Get series: accumulated overtime hours
    getSeriesOvertimeHours( accountingReport ) {
        let series = {
            name: i18n.t("pages.reports.options.accounting.chart.series.overtime.name"),
            type: 'line',
            markers: {
                size: 1,
                strokeColors: '#fff',
                strokeWidth: 2,
                shape: "circle",
                radius: 2
            },
            data: []
        }

        if( null != accountingReport ) {
            const thisYear = new Date().getFullYear();
            const thisMonth = new Date().getMonth()
            let overtimeHours = 0;

            for( let i = 0; i < accountingReport.years.length; i++ ) {

                // Only go till november, the final tick for available days on the year label will come with the next set
                for( let j = 0; j < accountingReport.years[i].months.length; j++ ) {

                    // Retracting days booked in january leads to the february tick label
                    overtimeHours += -1.0*accountingReport.years[i].months[j].hours_remaining_in_month;

                    // Add new overtime hours
                    // Only return years based on selection
                    if( this.props.year === "" || this.props.year === accountingReport.years[i].year.toString() ) {
                        // Only add if the current year and month is not past today's year and the past month
                        // in that case only add an empty string, else that looks awkward into the future
                        if( thisYear > accountingReport.years[i].year || ( thisYear === accountingReport.years[i].year && thisMonth > j )) {
                            series.data.push( overtimeHours );
                        } else {
                            series.data.push( null );
                        }
                    }
                }
            }
        }

        return series;
    }

    /// Get series: accounting corrections
    getSeriesAdjustedHours( accountingReport ) {
        let series = {
            name: i18n.t("pages.reports.options.accounting.chart.series.adjusted.name"),
            type: 'column',
            data: []
        }

        if( null != accountingReport ) {
            for( let i = 0; i < accountingReport.years.length; i++ ) {
                // Only return years based on selection
                if( this.props.year === "" || this.props.year === accountingReport.years[i].year.toString() ) {
                    for( let j = 0; j < accountingReport.years[i].months.length; j++ ) {

                        // Add booked in month at the mid of the month
                        if( 0 !== accountingReport.years[i].months[j].hours_adjusted_in_month ) {
                            series.data.push( accountingReport.years[i].months[j].hours_adjusted_in_month );
                        } else {
                            series.data.push( 0 );
                        }
                    }
                }
            }
        }

        return series;
    }

    /// Generate the year start annotations
    getYearAnnotations( accountingReport ) {
        let annotations = [];

        // If we selected a specific year, do not add annotations
        if( this.props.year !== "" ) {
            return annotations;
        }

        const monthNames = CalendarHelper.getMonthNames( this.props.language, "short" );

        if( null != accountingReport ) {
            for( let i = 0; i < accountingReport.years.length; i++ ) {
                const year = accountingReport.years[i].year;
                const yearCut = year.toString().substring(2, 4);
                const januaryLabel = monthNames[0] + " '" + yearCut;

                annotations.push(
                    {
                        x: januaryLabel,
                        borderColor: '#FF4560',
                        label: {
                            style: {
                                color: '#000',
                            },
                            orientation: 'vertical',
                            text: year.toString()
                        }
                    }
                )
            }
        }

        return annotations;
    }

    // Called by react when this component has been mounted
    componentDidMount() {

        // Pre-render chart since it will not change
        if( null !== this.props.accountingReport && null !== this.props.year ) {
            let accountingChart = this.renderAccountingChart( this.props.accountingReport );
            this.setState({ chart: accountingChart });
        }
    }

    // Called by react when this component did update
    componentDidUpdate( prevProps ) {

        if( this.props.year !== prevProps.year || this.props.userid !== prevProps.userid ) {
            // Pre-render chart since it will not change
            if( null != this.props.accountingReport && null != this.props.year ) {
                let accountingChart = this.renderAccountingChart( this.props.accountingReport );
                this.setState({ chart: accountingChart });
            }
        }
    }

    // Render
    // Takes props: language, vacationReport
    renderAccountingChart( accountingReport ) {

        const chartLabels = this.getLabels( accountingReport );
        const seriesAccountedHours = this.getSeriesAccountedHours( accountingReport );
        const seriesOvertimeHours = this.getSeriesOvertimeHours( accountingReport );
        const yearAnnotations = this.getYearAnnotations( accountingReport );
        const seriesAdjustedHours = this.getSeriesAdjustedHours( accountingReport );

        let chartSeries = [];
        chartSeries.push( seriesAccountedHours );
        chartSeries.push( seriesOvertimeHours );
        chartSeries.push( seriesAdjustedHours );

        const chartOptions = {
            chart: {
                animations: {
                    enabled: false
                },
                type: 'line',
                id: 'accounting-chart',
                toolbar: {
                    tools: {
                        download: false,
                        pan: false
                    }
                }
            },
            annotations: {
                yaxis: [],
                xaxis: yearAnnotations,
                points: []
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
            },
            markers: {
                size: 4
            },
            grid: {},
            title: {},
            labels: chartLabels,
            xaxis: {},
            yaxis: {
                axisBorder: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0
                },
                title: {
                    text: i18n.t("pages.reports.options.accounting.chart.yaxis.label"),
                    rotate: -90,
                    style: {
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: '12px',
                        fontWeight: 100
                    }
                }
            },
            tooltip: {
                shared: true
            }
        }

        return (
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="line"
            />
        )
    }

    render() {
        return (
            <div>
                {this.state.chart}
            </div>
        );
    }
};

export default withStyles(useStyles)(ChartAccounting);